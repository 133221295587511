import "../styles.css";
import Button from "./Button";
import React, { useContext, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { Transition, config } from "../config";
// import { selectuserProfile } from "../Reducers/userProfileSlice";
import { SocketContext } from "../context/socket";
import NumberFormat from "react-number-format";
import { actionPopup } from "../Reducers/popupSlice";
import AlertMessage from "../Components/Dialog/AlertMessage";
const BgClose = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  background-color: transparent;
  z-index: 20;
  left: 0%;
  top: 0%;
  position: fixed;
`;

const BGPopup = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  position: fixed;
  left: 7%;
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 70;
  align-items: center;
  justify-content: center;
`;

const Popupcomplete = styled.div`
  user-select: none;
  // background-image: url("/images/popup_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;
  // theme ==================
  /* background: #192756; */
  /* box-shadow: inset 0vw 0vw 0.8vw 0.2vw #5DD8FF, inset 0px 0px 4vw rgba(66, 255, 232, 0.63); */
  /* border: solid 0.2vw #BDEFFF; */
  // theme ==================
  border-radius: 1.5vw;
  position: relative;
  z-index: 100;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  // เพิ่ม padding ด้านข้างกรณี title ทะลุ start below ========
  padding: 0 2vw;
`;

const Pigcomplete = styled.img`
  user-select: none;
  width: 10vw;
  margin-top: -4vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 20vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          width: 18vw;
        `}
`;

const Complete = styled.div`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  color: #bdb9d1;
  font-size: 1.8vw;
  text-align: center;
  /* top: -2vw; */
`;

const TextError = styled.div`
  user-select: none;
  position: relative;
  color: #bdb9d1;
  font-size: 1.6vw;
  top: 1.2vw;
  text-align: center;
  left: -1vw;
  width: 31vw;
`;

const TextErrorcode = styled.div`
  user-select: none;
`;

const BoxMoney = styled.div`
  color: #ffc700;
  font-size: 1.8vw;
  margin-top: 4%;
  margin-left: 7.5vw;
  position: absolute;
  font-weight: 400;
`;

const Bgblack = styled.div`
  user-select: none;
  height: 4vw;
  width: 20vw;
  background-color: #000;
  border-radius: 0.5vw;
  position: relative;
  margin-top: 1vw;
  margin-left: 5vw;
`;

function WithdrawalFail(props) {
  const { t } = useTranslation();
  // const profile = useSelector(selectuserProfile);
  const [bonus, setBonus] = useState({});
  const [emitError, setEmitError] = useState({ status: false, message: "" });
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      socket.emit("getTurnProWithDraw", {}, (data) => {
        console.log("getTurnProWithDraw", data);
        if (typeof data != "undefined") {
          if (typeof data.results != "undefined") {
            if (data.status) {
              setBonus(data.results.data);
            } else if (data.status === false) {
              setEmitError({ status: true, message: data.errorCode });

              setTimeout(() => {
                setEmitError({ status: false });
              }, 3000);
            }
          }
        }
      });
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  }, []);

  return (
    <>
      <BGPopup>
        <BgClose onClick={props.closeClick} />
        <>
          <Popupcomplete
            style={{
              width:
                window.innerWidth / window.appHeight() > 2.15 ? "28vw" : "28vw",
              height:
                window.innerWidth / window.appHeight() > 2.15 ? "24vw" : "24vw",
            }}
          >
            <Pigcomplete src={"../" + config.theme + "/icon_deposit_fail.png"} />
            <Complete>{t("Failed Withdrawal")}</Complete>
            <TextErrorcode>
              <TextError>
                {/* {t("432")} */}
                {/* {t("433")} */}
                {/* {t(`${props.message}`)} */}
                <Bgblack>
                  <BoxMoney>
                    <NumberFormat
                      value={bonus.turnSlot_target}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    ></NumberFormat>
                  </BoxMoney>
                </Bgblack>
              </TextError>
            </TextErrorcode>
          </Popupcomplete>

          <Button
            bClass="rbGreen"
            label={t("got it for drawal")}
            width="13vw"
            fontSize="1.3vw"
            style={{
              marginTop: "24vw",
              position: "relative",
              zIndex: 110,
              right: "23vw",
            }}
            onClick={() => {
              dispatch(actionPopup({ withdrawaltopup: false }));
              dispatch(actionPopup({ withdrawalError: false }));
              dispatch(actionPopup({ withdrawal: false }));
            }}
          ></Button>
        </>
      </BGPopup>
      <Transition showPop={emitError.status} type="alert">
        <AlertMessage
          message={t(emitError.message)}
          onClick={() => {
            setEmitError({ status: false });
          }}
        />
      </Transition>
    </>
  );
}
export default WithdrawalFail;
