import React, { useState, useContext, useEffect } from "react";
import styled, { css } from "styled-components";
import Popup from "../Popup";
import Pig from "../NormalDeposit/Pig";
// import TitlePig from "../NormalDeposit/TitlePig";
import Blackbox from "../NormalDeposit/BlackBox";
import "./style.css";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import AlertMessage from "../Dialog/AlertMessage";
import { Transition, copyToClipboardOnTemp } from "../../config";
import { SocketContext } from "../../context/socket";
import UploadSlip from "../DepositWithdrawal/UploadSlip";
import Confirm from "../Dialog/Confirm";
import { selectTopupSlice } from "../../Reducers/TopupSlice";
import breakpoints from "../../LandingPageComponents/breakpoints";
import {
  selectuserProfile,
  actionClickSound,
} from "../../Reducers/userProfileSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  selectselectedBankSlice,
  selectfloatingBankSlice,
} from "../../Reducers/getLastBankSlice";
import {
  actionLastBankSlice,
  actionaccountlSlice,
  actionfloatinglSlice,
  actionselectedBanklSlice,
} from "../../Reducers/getLastBankSlice";
import { data } from "jquery";
import { config } from "../../config";

let moment = require("moment");
let momentCountdown = require("moment-countdown");
const BGPopup = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 30;
  align-items: center;
  justify-content: center;
`;

const Box = styled.div`
  user-select: none;
  position: fixed;
  width: 100%;
  height: 100vh; //fix
  align-items: center;
  justify-content: center;
  text-align: -webkit-center;
  display: flex;
  z-index: 50;
  background: rgba(0, 0, 0, 0.7);
  left: 0;
  top: 0;
  @media ${breakpoints.device.line} {
    width: 150vw;
    height: 130%;
    left: -42%;
    top: -6vw;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          flex-direction: column;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          /* left: 15vw; */
        `}
`;

const WarningBox = styled.div`
  position: absolute;
  width: 18%;
  height: 11.5vw;
  left: 66%;
  top: 49%;
  background: #302e3c;
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;
  // theme ==================
  /* background: #192756;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #5DD8FF, inset 0px 0px 4vw rgba(66, 255, 232, 0.63);
  border: solid 0.2vw #BDEFFF; */
  // theme ==================

  border-radius: 2vw;
  @media ${breakpoints.device.line} {
    left: 91vw;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          position: relative;
          width: 47vw;
          height: fit-content;
          left: 0vw;
          top: 10vw;
          left: 0 auto;
          top: 0 auto;
          padding-bottom: 1vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 30vw;
          height: 17.5vw;
          left: -44vw;
          top: 38vw;
          position: relative;
          left: 0 auto;
          top: 0 auto;
        `}
`;

const Numberic = styled.div`
  user-select: none;
  font-size: 2.5vw;
  text-align: center;
  font-weight: 400;
  color: white;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-weight: 700;
          font-size: 5.5vw;
          margin-top: 2vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 4.5vw;
        `}
`;
const Row = styled.div`
  user-select: none;
  width: 100%;
  margin: 0 auto;
  position: relative;
  /* background-color: blue; */
  h2 {
    // margin: 0%;
    color: #bdb9d1;
    font-size: 1vw;
  }
  p {
    color: #bdb9d1;
    font-size: 0.9vw;
  }
  h3 {
    color: #bdb9d1;
    font-size: 0.9vw;
  }

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          h2 {
            // margin: 0%;
            color: #ffffff;
            font-size: 2.6vw;
          }
          p {
            color: #ffffff;
            font-size: 3.5vw;
          }
        `
      : window.appHeight() > window.innerWidth &&
        css`
          /* font-size: 4.5vw; */
        `}
`;

const System = styled.div`
  color: #bdb9d1;
  font-size: 1vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 3.5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          /* font-size: 4.5vw; */
        `}
`;

const BankDetail = styled.div`
  user-select: none;
  float: left;
  text-align: left;
  position: relative;
  color: white;
  display: flex;
  width: 26vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 100vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 84vw;
        `}
`;

const BankUsername = styled.div`
  user-select: none;
  float: left;
  font-size: 1.2vw;
  /* margin-left: -0.5vw; */
  width: ${(props) =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? "80vw"
      : window.appHeight() < window.innerWidth
      ? "0 auto"
      : props.TotalLength < 45
      ? `65%;`
      : `57%;`};
  font-size: ${(props) => (props.TotalLength < 45 ? `1.45vw;` : `0.9vw;`)};
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 55vw;
          font-size: 2.5vw;
          margin-top: 2vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 38%;
          font-size: 1.5vw;
        `}
`;
const BankNumber = styled.div`
  user-select: none;
  float: left;
  font-size: 1.15vw;
  font-weight: 400;
  width: 65%;
  position: relative;
  display: flex;
  white-space: nowrap;
  color: white;
  top: -1.5vw;
  left: 5vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          top: -7vw;
          left: 14vw;
          font-size: 3.5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 2.15vw;
          top: -3vw;
          left: 9vw;
        `}
`;

const CopyBtn = styled.div`
  user-select: none;
  left: 29vw;
  width: 7vw;
  height: 4vw;
  /* padding: 1vw; */
  /* margin-right: 2vw; */
  float: right;
  position: absolute;
  background: url("../${config.theme}/btn_copy.png") no-repeat;
  background-size: 7vw;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  p {
    position: absolute;
    color: white;
    text-align: center;
    font-size: 0.9vw;
    margin-left: 30%;
    width: 60%;
  }

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          left: 66vw;
          width: 19.8vw;
          height: 10.8vw;
          background-size: 18vw;
          margin-top: 1.75vw;

          p {
            font-size: 3vw;
          }
        `
      : window.appHeight() > window.innerWidth &&
        css`
          left: 42vw;
          width: 10vw;
          height: 6vw;
          background-size: 10vw;
          margin-top: 1vw;
          p {
            font-size: 2vw;
          }
        `}
`;

const Clear = styled.div`
  user-select: none;
  clear: both;
`;

const BG = styled.div`
  user-select: none;
  align-self: center;
`;

const Recmoney = styled.div`
  user-select: none;
  height: 4vw;
  width: 23vw;
  background-color: #000;
  border-radius: 1vw;
  //   margin-left: 16%;
  position: relative;
  margin-top: 1%;
  margin-right: 1vw;
  color: #fff;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          height: 12vw;
          width: 58%;
          margin-top: 0;
          margin-right: 2.5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          height: 6vw;
          width: 27vw;
        `}
`;

const BankLogo = styled.img`
  user-select: none;
  float: left;
  /* width: 4.8vw; */
  width: 3vw;
  margin-right: 1vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 10vw;
          margin-right: 0vw;
          padding: 2vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 6vw;
        `}
`;

const BankNumber1 = styled.div`
  user-select: none;
  float: left;
  /* font-size: 1.8vw; */
  font-size: 1.5vw;
  font-weight: 400;
  width: 69%;
  position: relative;
  display: flex;
  white-space: nowrap;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 4vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 2.5vw;
        `}
`;

const Money = styled.div`
  user-select: none;
  color: #ffffff;
  font-size: 1.2vw;
  margin-top: 0.5vw;
  position: relative;
  text-align: center;
  white-space: nowrap;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin-top: -7.5vw;
          margin-bottom: 3vw;
          font-size: 3.5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          margin-top: -5.5vw;
          font-size: 2vw;
        `}
`;

const Icontimer = styled.img`
  user-select: none;
  width: 2vw;
  height: 1vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 5vw;
          height: 3vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 4vw;
          height: 2vw;
        `}
`;

const BankLogodown = styled.img`
  user-select: none;
  float: left;
  width: 4vw;
  /* width: 3.8vw; */
  margin-right: 1vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 10vw;
          margin-right: 0vw;
          padding: 2vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 8vw;
        `}
`;

const Input = styled.input`
  user-select: none;
  padding-left: 1vw;
  position: absolute;
  width: 40vw;
  height: 5vw;
  color: #fff;
  font-size: 1.5vw;
  top: 12.5vw;
  left: 3vw;
  background: #000000;
  border: none;
  border-radius: 01vw;

  :focus-visible {
    outline: none;
  }
`;

const RedBtn = styled.div`
  // position: absolute;
  width: 7.5vw;
  height: 3.8vw;
  margin-top: 0.25vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #e13b00 38.02%, #900000 100%);
  box-shadow: inset 0px 0px 4px 2px #ff814d;
  border-radius: 4px;
  cursor: pointer;
  p {
    color: white;
    font-size: 1vw;
    padding: 0.4vw;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 27.5vw;
          height: 12vw;
          border-radius: 2vw;
          p {
            font-size: 3.5vw;
          }
        `
      : window.appHeight() > window.innerWidth && css``}
`;

const ShowAmount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin-bottom: 4vw;
        `
      : window.appHeight() > window.innerWidth && css``}
`;

const Text = styled.div`
  color: white;
  font-size: ${(props) => (props.lang == "th" ? "0.9vw" : "0.8vw")};
  // font-weight: 400;
  width: 80%;
  margin-left: 2.8vw;
  margin-top: -0.4vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2.2vw;
          width: 0 auto;
          margin-left: 0vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 1.5vw;
          width: 88%;
          margin-left: 0vw;
        `}
`;
const ButtonWarning = styled.div`
  cursor: pointer;
  position: absolute;
  width: 68%;
  height: 27%;
  left: 23%;
  top: 68%;
  background: linear-gradient(180deg, #ffc700 38.02%, #ff691f 100%);
  box-shadow: inset 0px 0px 4px 2px #ffc700;
  border-radius: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    color: #731907;
    font-size: 1.3vw;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          left: 0vw;
          top: 0vw;
          position: relative;
          height: 6vw;
          p {
            font-size: 2vw;
          }
        `
      : window.appHeight() > window.innerWidth &&
        css`
          left: 17%;
          p {
            font-size: 1.5vw;
          }
        `}
`;

const Imgwarning = styled.img`
  width: 42%;
  margin-top: -20%;
  margin-left: 11%;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 13vw;
          margin-top: -20%;
          margin-left: 1vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 10vw;
          margin-left: 1vw;
        `}
`;

const TransferBox = styled.div`
  img {
    display: none;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 90%;
          display: flex;
          align-items: center;
          margin: 3vw auto;
          img {
            display: inline-block;
            width: 10%;
            margin: 0 3vw;
          }
        `
      : window.appHeight() > window.innerWidth && css``}
`;

const TransferDetail = styled.div`
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          text-align: left;
        `
      : window.appHeight() > window.innerWidth && css``}
`;

function DecimalDepo(props) {
  const { t, i18n } = useTranslation();
  const [alertCopy, setAlertCopy] = useState(false);
  const slectBank = useSelector(selectselectedBankSlice);
  //const = useSelector
  const Topup = useSelector(selectTopupSlice);
  const floating = useSelector(selectfloatingBankSlice);
  const profile = useSelector(selectuserProfile);
  const [bankInfo, setBankInfo] = useState({
    bank_short_name: "",
    account_name: "",
    bank_account: "",
  });
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const [alert, setAlert] = useState(false);
  const [emitError, setEmitError] = useState({ status: false, message: "" });
  const [popupUploadSlip, setPopupUploadSlip] = useState(false);
  const [[m, s], setTime] = useState([3, 0]);
  const [countDown, setCountDown] = useState({ min: 0, sec: 0 });
  const [cancelPop, setCancelPop] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [timeOver, setTimeOver] = useState(false);

  const cancelTime = () => {
    socket.emit("cancelpromptpay", {}, (data) => {
      // console.log("cancelpromtpay", data)
      if (typeof data != "undefined") {
        if (typeof data.results != "undefined") {
          if (data.status) {
            setBankInfo(data.results.data);
            // setConfirmPopup(true)
          } else if (data.status === false) {
            if (data.errorCode === "s0085") {
              setTimeOver(true);
              setTimeout(() => {
                setTimeOver(false);
              }, 3000);
            } else {
              setEmitError({ status: true, message: data.errorCode });
              setTimeout(() => {
                setEmitError({ status: false });
              }, 3000);
            }
          }
        }
      }
    });
  };
  // useEffect(() => {
  //     let timerID;
  //     if (message == "system via register") {
  //         timerID = setInterval(() => tick(), 1000);
  //     }
  //     return () => {
  //         clearInterval(timerID);
  //     };
  // }, [message, m, s]);
  let fnCountDown;
  useEffect(() => {
    if (typeof props.time != "undefined") {
      fnCountDown = setInterval(() => {
        // console.log("propsprops", props)
        var a = new Date(); //now
        var b = moment(props.time);
        // console.log(a.diff(b, 'seconds')) // 44700

        let time = b.diff(a, "seconds");
        if (time <= 0) {
          time = 0;
          clearInterval(fnCountDown);
          cancelTime();
          setTimeOver(true);
          setTimeout(() => {
            setTimeOver(false);
            props.closeDecimal();
          }, 2000);
        }
        let min = Math.floor(time / 60);
        let sec = Math.floor(time % 60);
        setCountDown({
          min: min,
          sec: sec,
        });
      }, 1000);
    }
  }, []);

  const tick = () => {
    if (!(m === 0 && s === 0)) {
      if (s == 0) {
        setTime([m - 1, 59]);
      } else {
        setTime([m, s - 1]);
      }
    } else {
      // setReqNewOtp(true);
    }
  };

  // const reset = () => {
  //     setTime([parseInt(3), parseInt(0)]);
  //     setReqNewOtp(false);
  // };

  function copyToClipboard(e) {
    copyToClipboardOnTemp(e);
    dispatch(actionClickSound());
    setAlertCopy(true);
    setTimeout(() => {
      setAlertCopy(false);
    }, 2000);
  }

  useEffect(() => {
    socket.emit("getBankAgent", {}, (data) => {
      console.log("getBankAgent", data);
      if (typeof data != "undefined") {
        if (typeof data.results != "undefined") {
          if (data.status) {
            setBankInfo(data.results.data);
          } else if (data.status === false) {
            setEmitError({ status: true, message: data.errorCode });
            setTimeout(() => {
              setEmitError({ status: false });
              props.closeClick();
            }, 3000);
          }
        }
      }
    });
    console.log("data", data);
  }, []);

  return (
    <>
      <Box>
        <Popup
          width={
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "90vw"
              : window.appHeight() > window.innerWidth
              ? "55vw"
              : window.innerWidth / window.appHeight() > 2.15
              ? "38vw"
              : "38vw"
          }
          height={
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "108vw"
              : window.appHeight() > window.innerWidth
              ? "44vw"
              : window.innerWidth / window.appHeight() > 2.15
              ? "30vw"
              : "30vw"
          }
          // width="38vw"
          // height="28.5vw"
          title={t("decimal")}
          closebuttonRight="-2vw"
          closebuttonTop="-2vw"
          closeClick={props.closeClick}
        >
          {/* <Pig style={{ top: "-28.5%" }} className='Pig' img='../"+config.theme+"/icon_decimal_deposit.png' /> */}
          <Pig
            top="-3vw"
            img={"../" + config.theme + "/icon_decimal_deposit.png"}
          />
          {/* <TitlePig marginTop="-9.7vw" title={t("decimal")} /> */}
          <Money>
            {t("transfer amount")}
            <Icontimer src={"../" + config.theme + "/icon_timer.png"} />

            <font color="#FF7777">
              {countDown.sec < 10
                ? "0" + countDown.min + ":0" + countDown.sec
                : "0" + countDown.min + ":" + countDown.sec}
              &nbsp;{t("min")}
              {/* {t("min")} */}
            </font>
          </Money>

          <ShowAmount>
            <Recmoney>
              <Numberic>
                <NumberFormat
                  value={props.amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </Numberic>
            </Recmoney>

            <RedBtn
              onClick={() => {
                setConfirmPopup(true);
              }}
            >
              <p>{t("cancelDecimalPopup")}</p>
            </RedBtn>
          </ShowAmount>

          <Row>
            <p>{t("account deposit")}</p>
          </Row>

          <Blackbox
            width={
              window.appHeight() / window.innerWidth > config.verticalSizeiphone
                ? "90%"
                : window.appHeight() > window.innerWidth
                ? "46vw"
                : window.innerWidth / window.appHeight() > 2.15
                ? "68%"
                : "68%"
            }
            style={{
              marginLeft:
                window.appHeight() / window.innerWidth >
                config.verticalSizeiphone
                  ? "0 auto"
                  : window.appHeight() > window.innerWidth
                  ? "0 auto"
                  : window.innerWidth / window.appHeight() > 2.15
                  ? "6.5vw"
                  : "0 auto",
            }}
          >
            <BankDetail style={{ display: "flex", alignItems: "center" }}>
              <BankLogo
                src={
                  profile.bankShortName === ""
                    ? ""
                    : "../" +
                      config.theme +
                      "/bicon_" +
                      profile.bankShortName +
                      ".png"
                }
              />
              <BankNumber1>
                <div style={{ textTransform: "uppercase" }}>
                  {profile.bankShortName}
                </div>
                &nbsp;|&nbsp;
                {typeof profile.bankAccount !== "undefined"
                  ? profile.bankAccount.length === 10
                    ? profile.bankAccount.slice(0, 3) +
                      "-" +
                      profile.bankAccount.slice(3, 4) +
                      "-" +
                      profile.bankAccount.slice(4, 9) +
                      "-" +
                      profile.bankAccount.slice(9, 10)
                    : profile.bankAccount
                  : ""}
              </BankNumber1>
            </BankDetail>
            <Clear />
          </Blackbox>

          <TransferBox>
            <img src={"../" + config.theme + "/newasset/img_arrow.png"} />
            <TransferDetail>
              <System>
                <h3>{t("transfer")}</h3>
              </System>
              <Row>
                <p>{t("check")}</p>
              </Row>
            </TransferDetail>
          </TransferBox>

          <Blackbox
            width="90%"
            height={
              window.appHeight() / window.innerWidth > config.verticalSizeiphone
                ? "14vw"
                : window.appHeight() > window.innerWidth
                ? "8vw"
                : window.innerWidth / window.appHeight() > 2.15
                ? "4vw"
                : "4vw"
            }
          >
            <BankDetail>
              <BankLogodown
                src={
                  typeof props.bankInfo.short_name != "undefined"
                    ? "../" +
                      config.theme +
                      "/bicon_" +
                      props.bankInfo.short_name +
                      ".png"
                    : ""
                }
              />
              <BankUsername
                TotalLength={
                  typeof props.bankInfo.account_name !== "undefined"
                    ? props.bankInfo.account_name.length
                    : ""
                }
              >
                {props.bankInfo.account_name}
              </BankUsername>
            </BankDetail>
            <BankNumber>
              <div style={{ textTransform: "uppercase" }}>
                {props.bankInfo.short_name}
              </div>
              &nbsp;&nbsp;
              <div style={{ display: "none" }}>
                <Input value={props.bankInfo.bank_account} readOnly />
              </div>
              |&nbsp;
              {typeof props.bankInfo.bank_account !== "undefined"
                ? props.bankInfo.bank_account.length === 10
                  ? props.bankInfo.bank_account.slice(0, 3) +
                    "-" +
                    props.bankInfo.bank_account.slice(3, 4) +
                    "-" +
                    props.bankInfo.bank_account.slice(4, 9) +
                    "-" +
                    props.bankInfo.bank_account.slice(9, 10)
                  : props.bankInfo.bank_account
                : ""}
            </BankNumber>
            <CopyBtn
              id={profile.brands_id + "-btn-copy-truewallet"}
              onClick={() => {
                copyToClipboard(props.bankInfo.bank_account);
                try {
                  socket.emit("copyDeposit", {}, (body) => {
                    if (body.status) {
                      console.log(body);
                      console.log("copyDeposit Success");
                    } else {
                      console.log("copyDeposit Error");
                    }
                  });
                } catch (err) {
                  console.log("copyDeposit Error");
                }
              }}
            >
              <p>{t("copy")}</p>
            </CopyBtn>
            <Clear />
          </Blackbox>

          {/* <System>{t("system")}</System> */}

          <Clear />
        </Popup>

        {props.bankInfo.qrCodeStatus ? (
          <WarningBox>
            <Imgwarning src={"../" + config.theme + "/warning_icon.png"} />
            <Text lang={i18n.language}>{t("incase bank error")}</Text>
            <ButtonWarning
              onClick={() => {
                dispatch(actionselectedBanklSlice("floating"));
                setPopupUploadSlip(true);
              }}
            >
              <p>{t("Upload slip here")}</p>
            </ButtonWarning>
          </WarningBox>
        ) : (
          ""
        )}

        <Transition showPop={popupUploadSlip} type="alert">
          <UploadSlip
            closeClick={() => {
              setPopupUploadSlip(false);
            }}
          />
        </Transition>

        <Transition showPop={alertCopy} type="alert">
          <AlertMessage
            message={t("alert message copy")}
            onClick={() => {
              setAlertCopy(false);
            }}
          />
        </Transition>

        <Transition showPop={alert} type="alert">
          <AlertMessage
            message={t("ทำรายการสำเร็จ")}
            onClick={() => {
              setAlert(false);
            }}
          />
        </Transition>

        <Transition showPop={timeOver} type="alert">
          <AlertMessage
            message={t("s0085")}
            onClick={() => {
              setTimeOver(false);
            }}
          />
        </Transition>

        <Transition showPop={emitError.status} type="alert">
          <AlertMessage
            message={t(emitError.message)}
            onClick={() => {
              setEmitError({ status: false });
              props.closeClick();
            }}
          />
        </Transition>

        <Transition showPop={confirmPopup} type="st2b">
          <Confirm
            cancelClick={() => {
              setConfirmPopup(false);
              // setCancelPop(false)
            }}
            confirmClick={() => {
              // setConfirmPopup(true)
              cancelTime();
              setEmitError({ status: false });
              props.closeDecimal();
            }}
          >
            {t("cancelDecimal")}
          </Confirm>
        </Transition>
      </Box>
    </>
  );
}

export default DecimalDepo;
