import React, { useEffect, useContext, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  selectuserProfile,
  actionClickSound,
} from "../../../Reducers/userProfileSlice";
import { SocketContext } from "../../../context/socket";
import Popup from "../../Popup";
import FinalNoticePop from "./FinalNoticePop";
import { config } from "../../../config";

const Box = styled.div`
  position: fixed;
  width: 100vw;
  height: 100%; //fix
  top: 0%;
  left: 0%;
  text-align: -webkit-center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 70;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BG = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 20;
  align-items: center;
  justify-content: center;
  margin: 0%;
`;

const ContentBox = styled.div`
  width: 95%;
  height: 76%;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  margin-top: 2%;
  /* .detailImg {
    width: 45%;
    object-fit: contain;
  } */
  ::-webkit-scrollbar {
    width: 1vw;
    height: 0vw;
    z-index: 10;
  }
  ::-webkit-scrollbar-thumb {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 10;
    position: absolute;
    width: 11px;
    height: 80px;
    background: linear-gradient(180deg, #e13b00 38.02%, #900000 100%);
    box-shadow: inset 0px 0px 4px 2px #ff814d;
    border-radius: 10px;
  }

  @media (orientation: portrait) {
    flex-direction: column;
    height: 90%;
    /* .detailImg {
      width: 100%;
      max-height: 50%;
    } */
  }
`;

const Detail = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  white-space: pre-line;
  color: white;
  font-size: 1.5vw;

  @media (orientation: portrait) {
    width: 100%;
    font-size: 3vw;
    height: 50%;
  }
`;

const DetailText = styled.div`
  width: 100%;
  height: 75%;
  background: #17161d;
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden scroll;
  ::-webkit-scrollbar {
    width: 1vw;
    height: 0vw;
    z-index: 10;
  }
  ::-webkit-scrollbar-thumb {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 10;
    position: absolute;
    width: 11px;
    height: 80px;
    background: linear-gradient(180deg, #e13b00 38.02%, #900000 100%);
    box-shadow: inset 0px 0px 4px 2px #ff814d;
    border-radius: 10px;
  }
`;

const TextHead = styled.div`
  color: #ff691f;
  margin-left: 5%;
  text-align: start;
`;

const TextInfo = styled.div`
  color: #fff;
  margin-left: 5%;
  text-align: start;
`;

const ButtonContainer = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: space-between;
`;

const CancelButton = styled.div`
  width: 42.02%;
  height: 100%;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #e13b00 38.02%, #900000 100%);
  box-shadow: inset 0vw 0vw 0.6vw 0.2vw #ff814d;
  border-radius: 1vw;
`;

const SelectButton = styled.div`
  width: 55%;
  height: 100%;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #62d706 38.02%, #3b9604 100%);
  box-shadow: inset 0px 0px 0.6vw 0.2vw #4dff80;
  border-radius: 1vw;
`;

const AlreadySelected = styled.div`
  width: 55%;
  height: 100%;
  color: #4dff80;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2e2c3a;
  border-radius: 1vw;
  img {
    width: 25%;
    object-fit: contain;
  }
  @media (orientation: portrait) {
    font-size: 4vw;
    border-radius: 2vw;
  }
`;

const BannerBox = styled.div`
  width: 45%;
  height: 100%;
  position: relative;
  border-radius: 1vw;
  overflow: hidden scroll;
  display: block;
  .detailImg {
    width: 100%;
    border-radius: 1vw;
  }
  ::-webkit-scrollbar {
    width: 1vw;
    height: 0vw;
    z-index: 10;
  }
  ::-webkit-scrollbar-thumb {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 10;
    position: absolute;
    width: 11px;
    height: 80px;
    background: linear-gradient(180deg, #e13b00 38.02%, #900000 100%);
    box-shadow: inset 0px 0px 4px 2px #ff814d;
    border-radius: 10px;
  }
  @media (orientation: portrait) {
    width: 100%;
    max-height: 50%;
  }
`;

function PromotionDetail(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector(selectuserProfile);
  const socket = useContext(SocketContext);
  const [finalPop, setFinalPop] = useState(false);
  const DataDetail =
    profile.language === "TH" ? props.info.detail : props.info.detail_en;

  useEffect(() => {
    console.log("pro props", props);
  }, []);

  return (
    <Box>
      <BG onClick={props.closeClick} />
      <Popup
        title={t("pro detail")}
        TitleSize={
          window.appHeight() > window.innerWidth && window.innerWidth <= 480
            ? "2.67vw"
            : window.appHeight() > window.innerWidth
            ? "4vw"
            : "2vw"
        }
        bgClass="Profile"
        width={
          window.appHeight() > window.innerWidth && window.innerWidth <= 480
            ? "95vw"
            : window.appHeight() > window.innerWidth
            ? "90vw"
            : "85vw"
        }
        height={
          window.appHeight() > window.innerWidth && window.innerWidth <= 480
            ? "149vw"
            : window.appHeight() > window.innerWidth
            ? "90vw"
            : "36vw"
        }
        closeClick={props.closeClick}
        display="none"
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ContentBox>
            <BannerBox>
              <img
                className="detailImg"
                src={
                  profile.language === "TH"
                    ? props.info.image
                    : props.info.image_en
                }
              />
            </BannerBox>
            <Detail>
              <DetailText>
                <TextHead style={{ marginTop: "3%" }}>{t("pro name")}</TextHead>
                <TextInfo>
                  {profile.language === "TH"
                    ? props.info.name
                    : props.info.name_en}
                </TextInfo>
                <br />
                <TextHead>{t("terms and conds")}</TextHead>
                <TextInfo dangerouslySetInnerHTML={{ __html: DataDetail }} />
                <br />
                <TextHead>{t("remark")}</TextHead>
                <TextInfo>{t("remark detail")}</TextInfo>
              </DetailText>
              <ButtonContainer>
                <CancelButton
                  onClick={() => {
                    props.closeClick();
                  }}
                >
                  {t("cancel")}
                </CancelButton>
                {props.selected ? (
                  <AlreadySelected>
                    <img src={"../" + config.theme + "/ico_promo_check.png"} />
                    {t("activated")}
                  </AlreadySelected>
                ) : (
                  <SelectButton
                    onClick={() => {
                      if (props?.setting?.updatePro) {
                        props.confirmClick();
                      } else {
                        setFinalPop(true);
                      }
                    }}
                  >
                    {t("choose")}
                  </SelectButton>
                )}
              </ButtonContainer>
            </Detail>
          </ContentBox>
        </div>
      </Popup>
      {finalPop ? (
        <FinalNoticePop
          closeClick={() => {
            setFinalPop(false);
          }}
          confirmClick={() => {
            props.confirmClick();
          }}
        />
      ) : (
        ""
      )}
    </Box>
  );
}
export default PromotionDetail;
