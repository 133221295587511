import styled, { css } from "styled-components";
import { config } from "../../../config";
import { useTranslation } from "react-i18next";

const BT = styled.button`
  user-select: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  margin: ${(props) => (props.navbar ? "0 0.8vw" : "0vw")};
  display: flex;
  outline: 0;
  position: relative;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  min-width: 0px;
  min-height: 0px;
  padding: 0px;
  color: white;
  font-size: 2vw;
  text-transform: none;

  @media only screen and (max-width: 480px) and (orientation: portrait) {
    margin: ${(props) => (props.label !== undefined ? "0 1.5vw" : "0vw")};
  }

  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin: ${(props) => (props.label !== undefined ? "0 1.5vw" : "0vw")};
        `
      : window.appHeight() > window.innerWidth && css``} */
`;

const Icon = styled.img`
  user-select: none;
  width: 5.0063vw;

  @media (orientation: portrait) {
    width: 8.5vw;
  }

  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    width: 6vw;
  }

  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 8.5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 6vw;
        `} */
`;

const RedDot = styled.img`
  user-select: none;
  width: 2.5vw;
  position: absolute;
  top: 20%;
  z-index: 10;
  right: 7.5%;

  @media (orientation: portrait) {
    width: 3.5vw;
    top: 0vw;
    z-index: 10;
    right: 0vw;
  }

  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    width: 3.5vw;
  }

  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 3.5vw;
          top: 0vw;
          z-index: 10;
          right: 0vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 3.5vw;
        `} */
`;

const TextInner = styled.p`
  position: absolute;
  font-size: 1.1vw;
  width: 140%;
  bottom: -0.3vw;
  line-height: 1.1vw;
  text-shadow: 0 0.3vw 0.7vw black;

  @media (orientation: portrait) {
    width: 120%;
    font-size: 2.2vw;
    bottom: -10%;
    line-height: 2.1vw;
  }

  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    width: 110%;
    font-size: 1.4vw;
    bottom: -5%;
    line-height: 1.2vw;
  }

  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 120%;
          font-size: 2.2vw;
          bottom: -10%;
          line-height: 2.1vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 110%;
          font-size: 1.4vw;
          bottom: -5%;
          line-height: 1.2vw;
        `} */
`;

const TextOuter = styled.p`
  position: absolute;
  font-size: 1.1vw;
  width: 140%;
  bottom: -0.5vw;
  line-height: 1.1vw;
  // -webkit-text-stroke-width: 0.5vw;
  // -webkit-text-stroke-color: #2f2e3a;
  // border-radius: 1vw;
  // background: rgba(0,0,0,0.5);
  // padding:
  color: rgba(0, 0, 0, 0);

  @media (orientation: portrait) {
    -webkit-text-stroke-width: 0.5vw;
    -webkit-text-stroke-color: #2f2e3a;
    width: 120%;
    font-size: 2.2vw;
    bottom: -10%;
    line-height: 2.1vw;
  }

  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    -webkit-text-stroke-width: 0.5vw;
    -webkit-text-stroke-color: #2f2e3a;
    width: 110%;
    font-size: 1.4vw;
    bottom: -5%;
    line-height: 1.2vw;
  }

  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          -webkit-text-stroke-width: 0.5vw;
          -webkit-text-stroke-color: #2f2e3a;
          width: 120%;
          font-size: 2.2vw;
          bottom: -10%;
          line-height: 2.1vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          -webkit-text-stroke-width: 0.5vw;
          -webkit-text-stroke-color: #2f2e3a;
          width: 110%;
          font-size: 1.4vw;
          bottom: -5%;
          line-height: 1.2vw;
        `} */
`;

export default function PopupMenuItem(props) {
  const { t } = useTranslation();

  return (
    <BT {...props}>
      {props.children}

      <Icon src={"../" + config.theme + "/" + props.icon} />
      {props.label ? (
        <>
          <TextOuter>{t(props.label)}</TextOuter>
          <TextInner>{t(props.label)}</TextInner>
        </>
      ) : (
        ""
      )}
      {props.active === true ? (
        <RedDot src={"../" + config.theme + "/notice_dot.png"} />
      ) : (
        ""
      )}
    </BT>
  );
}
