import React from "react";
import styled, { css } from "styled-components";
import { config } from "../../config";

const Box = styled.div`
  user-select: none;
  background-color: black;
  border-radius: 0.85vw;
  padding: 0.5vw;
  margin: 0.5vw auto;

  ${(props) => css`
    width: ${props.width};
    height: ${props.height};
  `}
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          border-radius: 1.5vw;
          margin: 4vw auto;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          border-radius: 1.5vw;
        `}
`;

function BlackBox(props) {
  return <Box {...props}>{props.children}</Box>;
}

export default BlackBox;
