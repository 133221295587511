import React from "react";
import styled, { css, keyframes } from "styled-components";
import { config } from "../../config";

const badge = keyframes`
  0%{ filter: brightness(90%);}
  100%{ filter: brightness(120%);}
`;
const Badge = styled.div`
  width: 6.5vw;
  height: 19vw;
  // background: red;
  position: absolute;
  z-index: 1;
  background: url("../${config.theme}/winrate/gold.png") center 15% no-repeat;
  background-size: contain;
  margin-left: -1vw;
  pointer-events: none;
  // transform: rotate(-10deg);
  // animation-name: ${badge};
  // animation-duration: 0.4s;
  // animation-timing-function: ease-in-out;
  // animation-direction: alternate;
  // animation-iteration-count: infinite;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 13vw;
          margin-left: -2vw;
          margin-top: 2vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 10vw;
          margin-left: -2vw;
          margin-top: 1vw;
        `}
`;
const TextPercent = styled.div`
  font-size: 2vw;
  width: fit-content;
  display: flex;
  align-items: flex-end;
  // background: red;
  margin: 0 auto;
  line-height: 2vw;
  position: relative;
  padding-top: 3vw;
  // font-weight: 600;
  color: #572a00;
  text-shadow: 0vw -0.1vw 0.1vw #fff500, 0vw 0.1vw 0.3vw #ffffff;
  p {
    font-size: 1.2vw;
    line-height: 1.3vw;
    color: #572a00;
    text-shadow: 0vw -0.1vw 0.1vw #fff500, 0vw 0.1vw 0.3vw #ffffff;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 4vw;
          line-height: 3vw;
          padding-top: 4vw;
          p {
            font-size: 2.7vw;
            line-height: 2.7vw;
          }
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 3vw;
          padding-top: 3.3vw;
          p {
            font-size: 2.2vw;
            line-height: 2.2vw;
          }
        `}
`;
const TextWin = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1.3vw;
  line-height: 1vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2vw;
          line-height: 3.3vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 1.8vw;
          line-height: 2.5vw;
        `}
`;

function WinrateBadge(props) {
  return (
    <Badge>
      <TextPercent>
        {props.win}
        <p>%</p>
      </TextPercent>
      <TextWin>WINS</TextWin>
    </Badge>
  );
}
export default WinrateBadge;
