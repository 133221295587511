import styled from "styled-components";
import NumberFormat from "react-number-format";
import { config } from "../../../config";
const BG = styled.button`
  user-select: none;
  background-color: black;
  border-radius: 10vw;
  /* width: 16.03298vw; */
  width: 15vw;
  left: 2.5vw;
  height: calc(100vw * 0.03048);
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  min-width: 0px;
  min-height: 0px;
  color: white;
  font-size: 1.5vw;
  text-transform: none;
  /* margin-right: 0.5vw; */
  // padding-left: 1vw;
  padding-right: 1vw;
  margin: 0.3vw 0;
  //Portrait
  @media (orientation: portrait) {
    height: 5.5vw;
    font-size: 2.5vw;
    width: 23vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    height: 4.5vw;
    font-size: 1.5vw;
    width: 19vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? `
 height: 5.5vw;
 font-size: 2.5vw;
 width: 23vw;
 `
      : window.appHeight() > window.innerWidth &&
        `
 height: 4.5vw;
    font-size: 1.5vw;
    width: 19vw;
 `} */
`;

const Icon = styled.img`
  user-select: none;
  height: calc(100vw * 0.02249);

  @media (orientation: portrait) {
    height: 4vw;
  }

  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    height: 2.5vw;
  }

  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? `
     height: 4vw;
 `
      : window.appHeight() > window.innerWidth &&
        `
 height: 2.5vw;
 `} */
`;

export default function CurrencyItem(props) {
  return (
    <BG {...props}>
      {props.children}
      <Icon src={"../" + config.theme + "/" + props.icon} />
      <NumberFormat
        value={props.amount}
        displayType={"text"}
        thousandSeparator={true}
        fixedDecimalScale={true}
        decimalScale={props.isCoin === true ? 2 : 0}
      />
    </BG>
  );
}
