import PopupLogin from "../PopupLogin";
import Button from "../Button";
import styled from "styled-components";
import { useState } from "react";
import { useSelector } from "react-redux";
import WarningMessage from "../Dialog/WarningMessage";
import breakpoint from "../breakpoints";
import {
  config,
  Transition,
  getBackUrl,
  getFullUrl,
} from "../../config";
import { useTranslation } from "react-i18next";
// import useWindowSize from '../useWindowSize';
// import { useDispatch } from "react-redux";
import { selectDimensionsLP } from "../../Reducers/dimensionsLP";
// import { base64decode as decode } from 'nodejs-base64';
import ForgetPassword from "./ForgetPassword";
import Loading from "../../Components/Loading";
// import short from "short-uuid";
var axios = require("axios");

const LoginContainer = styled.div`
  position: relative;
`;

const FormContainer = styled.div`
  color: #e5e5e5;
  p {
    font-size: 1vw;
    line-height: 0;
    margin-top: 1vw;
  }
  h6 {
    color: #797fa1;
    font-size: 0.67vw;
    font-weight: normal;
    margin-block-start: 0.2vw;
    margin-block-end: 1vw;
  }
  a {
    color: #c5c9ec;
    font-size: 0.85vw;
  }
  @media ${breakpoint.device.mobile} {
    p {
      font-size: 3.96vw; //16px
      line-height: 2;
      margin-top: 3.5vw;
    }
    h6 {
      font-size: 2.97vw;
      margin-block-start: 3.2vw;
      margin-block-end: 4vw;
    }
    a {
      font-size: 3.47vw;
    }
  }
`;

const Input = styled.input`
  width: 20.83vw;
  height: 2.6vw;
  color: #fff;
  font-size: 1.2vw;
  background-color: #000000;
  border: none;
  border-radius: 0.5vw;
  outline: transparent;
  display: flex;
  padding: 0 1vw;
  margin-top: 1vw;
  margin-bottom: 1vw;
  ::placeholder {
    color: white;
    font-size: 1.2vw;
    align-items: center;
  }
  // :-webkit-autofill,
  // :-webkit-autofill:hover,
  // :-webkit-autofill:focus {
  //   color: #fff;
  //   -webkit-text-fill-color: #fff!notimportant;
  //   -webkit-box-shadow: 0 0 0 7vw #000 inset;
  // }
  @media ${breakpoint.device.mobile} {
    width: 82.92vw;
    height: 12.37vw;
    font-size: 4.95vw; // 20px
    border-radius: 2vw;
  }
`;

const ContainerBottom = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.5vw;
  @media ${breakpoint.device.mobile} {
    margin-top: 2.5vw;
  }
`;

const DialogContainer = styled.div`
  user-select: none;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 50;
`;

// const Background = styled.div`
//     user-select: none;
//     position: fixed;
//     width: 100vw;
//     height: 100vh;
//     background: rgba(0,0,0,0.5);
//     z-index: 50;
// `

const Box = styled.div`
  user-select: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0%;
  left: 0%;
  // text-align: -webkit-center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 200;
`;

const BG = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  // text-align: -webkit-center;
  display: flex;
`;

const WarningContainer = styled.div`
  user-select: none;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  z-index: 50;
`;

function Login(props) {
  let passwordLocal = "";
  let usernameLocal = "";
  try {
    passwordLocal = localStorage.getItem("password");
    usernameLocal = localStorage.getItem("username");
  } catch (error) { }

  const [password, setPassword] = useState("" || passwordLocal);
  // const [password, setPassword] = useState("");
  const [username, setUsername] = useState("" || usernameLocal);
  // const [dialog, setDialog] = useState(false);
  const [forget, setForget] = useState(false);
  const [emitError, setEmitError] = useState({ status: false, message: "" });
  // const size = useWindowSize();
  const { t } = useTranslation();
  // const dispatch = useDispatch();
  const dimensionsLP = useSelector(selectDimensionsLP);
  const [isLoading, setIsLoading] = useState(false);
  // const [uid, setUid] = useState(short.generate());

  // let reqPhone = new RegExp(/^[0-9\b]+$/);

  const onSubmit = () => {
    setIsLoading(true);
    var data = JSON.stringify({
      account_api: username,
      password: password,
      ...getBackUrl(),
    });

    axios({
      method: "post",
      url: config.socketEndpoint + "login",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then(function (data) {
        setIsLoading(false);
        // console.log("login", data)
        if (
          typeof data.data === "undefined" ||
          typeof data.data.results === "undefined"
        ) {
        } else {
          if (data.data.status === true) {
            // alert(getFullUrl(data.data.results.jwtToken))

            window.location = getFullUrl(data.data.results.jwtToken);
            // redirectAfterLoginWithToken(data.data.results.jwtToken);
            // dispatch(actionSetprofile(data.data.results.data));
            // dispatch(actionSetIslogin(true));
            // window.isLandingPage = false;
            // setTimeout(() => {
            //   try {
            //     window.recheck();
            //   } catch {}
            // }, 100);

          } else if (data.data.status === false) {
            setEmitError({ status: true, message: data.data.errorCode });
          }
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        // console.log("Error", error);
      });
  };

  const HandleKeydown = (e) => {
    if (e.keyCode === 13) {
      // console.log('You pressed the Enter key!')
      onSubmit();
    }
  };

  // console.log(typeof (localStorage.getItem("password")))

  return (
    <Box>
      {isLoading ? <Loading /> : ""}

      <BG>
        <LoginContainer>
          <PopupLogin display="none" closeClick={props.onClose}>
            <FormContainer>
              <p>{t("mobile login")}</p>
              <Input
                type="text"
                name="username"
                value={username || ""}
                placeholder=""
                // maxLength={10}
                // onChange={(v) => setUsername(v.target.value)}
                onChange={(v) => {
                  // if (
                  //   v.target.value === "" ||
                  //   reqPhone.test(v.target.value)
                  // ) {
                  setUsername(v.target.value);

                  // }
                }}
                onKeyDown={HandleKeydown}
              />

              <p>{t("login pincode")}</p>
              <Input
                type="password"
                name="password"
                value={password || ""}
                placeholder=""
                // maxLength={4}
                onChange={(v) => {
                  // if (
                  //   v.target.value === "" ||
                  //   reqPhone.test(v.target.value)
                  // ) {
                  setPassword(v.target.value);

                  // }
                }}
                // onChange={(v) => setPassword(v.target.value)}
                onKeyDown={HandleKeydown}
              />
              {/* <h6>{t("pincode login")}</h6> */}

              <Button
                bClass="rtGreen"
                label={t("sing in")}
                width={
                  dimensionsLP.width <= breakpoint.deviceSize
                    ? "85vw"
                    : "22.83vw"
                }
                fontSize={
                  dimensionsLP.width <= breakpoint.deviceSize ? "4vw" : ""
                }
                onClick={onSubmit}
              />
              <ContainerBottom>
                {/* <Button
                  bClass="rtRed"
                  label={t("forgot password lp")}
                  width={
                    dimensionsLP.width <= breakpoint.deviceSize
                      ? "85vw"
                      : "22.83vw"
                  }
                  height={
                    dimensionsLP.width <= breakpoint.deviceSize
                      ? "12.5vw"
                      : "3.13vw"
                  }
                  fontSize={
                    dimensionsLP.width <= breakpoint.deviceSize ? "4vw" : ""
                  }
                  onClick={() => setForget(true)}
                /> */}
                {/* <Button
                  bClass="rtOrange"
                  label={t("play now Guest")}
                  width={
                    dimensionsLP.width <= breakpoint.deviceSize
                      ? "40.48vw"
                      : "11vw"
                  }
                  height={
                    dimensionsLP.width <= breakpoint.deviceSize
                      ? "12.5vw"
                      : "3.13vw"
                  }
                  fontSize={
                    dimensionsLP.width <= breakpoint.deviceSize ? "4vw" : ""
                  }
                  // onClick={() => setForget(true)}
                  onClick={() => {
                    redirectToGuest(uid);
                  }}
                /> */}
              </ContainerBottom>
              {/* <Footer>
                <a onClick={() => setForget(true)}>{t("forgetpassword")}</a>
                
              </Footer> */}
            </FormContainer>
          </PopupLogin>
        </LoginContainer>
      </BG>

      {/* <Transition
                in={dialog}
                timeout={300}
                classNames="alert"
                unmountOnExit
            >
                <DialogContainer>
                    <Dialog message="invalid ac/pw" bClass="rtRed" btnLabel="try again" onClick={() => setDialog(false)} />
                </DialogContainer>
            </Transition> */}

      <Transition in={forget} timeout={300} classNames="alert" unmountOnExit>
        <DialogContainer>
          <ForgetPassword
            cancelClick={() => setForget(false)}
            closeLoginPopup={props.onClose}
          />
        </DialogContainer>
      </Transition>

      <Transition showPop={emitError.status} type="alert">
        <WarningContainer>
          <WarningMessage
            errCode={emitError.message}
            btnLabel={t("try again")}
            onClick={() => {
              setEmitError({ ...emitError, status: false });
            }}
          />
        </WarningContainer>
      </Transition>
    </Box>
  );
}

export default Login;
