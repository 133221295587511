import { configureStore } from "@reduxjs/toolkit";
import dailyRewardReducer from "../Reducers/dailyRewardSlice";
import settingReducer from "../Reducers/settingSlice";
import gamePlayReducer from "../Reducers/gamePlaySlice";
import userProfileReducer from "../Reducers/userProfileSlice";
import providerReducer from "../Reducers/providerSlice";
import countDownReducer from "../Reducers/countDown";
import dimensionsReducer from "../Reducers/dimensions";
import dimensionsLPReducer from "../Reducers/dimensionsLP";
import promotionReducer from "../Reducers/promotionSlice";
import HowtouseReducer from "../Reducers/HowtouseSlice";
import counterLPReducer from "../Reducers/counterLPSlice";
import gameCategoryReducer from "../Reducers/gameCategorySlice";
import SupportReducer from "../Reducers/SupportSlice";
import LoadingReducer from "../Reducers/loadingSlice";
import AlertReducer from "../Reducers/alertSlice";
import afterLogin from "../Reducers/afterLogin";
import JackpotReducer from "../Reducers/jackpotSlice";
import PopupReducer from "../Reducers/popupSlice";
import RewardPopupReducer from "../Reducers/rewardPopupSlice";
import HomeContentLPReducer from "../Reducers/homeContentLPSlice";
import SlotContentLPReducer from "../Reducers/slotContentLPSlice";
import MissionReducer from "../Reducers/missionSlice";
import LastBankReducer from "../Reducers/getLastBankSlice";
import ConfirmPopupReducer from "../Reducers/confirmPopup";
import TopupReducer from "../Reducers/TopupSlice";
import FloatingInputReducer from "../Reducers/floatingInputSlice";
import InviteFirendReducer from "../Reducers/inviteFriendSlice";
import GameDemoReducer from "../Reducers/gameDemoSlice";
import GameListReducer from "../Reducers/gameListSlice";
import CheckFTUEPopupReducer from "../Reducers/checkFTUEPopupSlice";
import CheckDailyDepositReducer from "../Reducers/checkDailyDepositSlice";
import SpinixShopMenuReducer from "../Reducers/spinixShopMenuSlice";
import WinRateReducer from "../Reducers/winRateSlice";
import MiniGameReducer from "../Reducers/miniGameSlice";
import FestivalBannerReducer from "../Reducers/festivalBannerSlice";
import MinigameSlotReducer from "../Reducers/minigameslotSlice";
import RankingReducer from "../Reducers/rankingSlice";
import FlashDealReducer from "../Reducers/flashDealSlice";
import TimeLimitPromotionReducer from "../Reducers/timeLimitProSlice";
import ShopReducer from "../Reducers/shopSlice";

export default configureStore({
  reducer: {
    afterLogin: afterLogin,
    countDown: countDownReducer,
    dailyReward: dailyRewardReducer,
    setting: settingReducer,
    gamePlay: gamePlayReducer,
    userprofile: userProfileReducer,
    provider: providerReducer,
    dimensions: dimensionsReducer,
    dimensionsLP: dimensionsLPReducer,
    promotion: promotionReducer,
    howtouse: HowtouseReducer,
    counterLP: counterLPReducer,
    gameCategory: gameCategoryReducer,
    supports: SupportReducer,
    loading: LoadingReducer,
    alert: AlertReducer,
    jackpot: JackpotReducer,
    popup: PopupReducer,
    rewardPopup: RewardPopupReducer,
    homeContentLP: HomeContentLPReducer,
    slotContentLP: SlotContentLPReducer,
    mission: MissionReducer,
    lastBankSlice: LastBankReducer,
    confirmPopup: ConfirmPopupReducer,
    topup: TopupReducer,
    floatingInput: FloatingInputReducer,
    inviteFriend: InviteFirendReducer,
    gameDemo: GameDemoReducer,
    gameList: GameListReducer,
    checkFTUEPopup: CheckFTUEPopupReducer,
    checkDailyDeposit: CheckDailyDepositReducer,
    spinixShopMenu: SpinixShopMenuReducer,
    winRate: WinRateReducer,
    minigame: MiniGameReducer,
    festivalBanner: FestivalBannerReducer,
    gameslot: MinigameSlotReducer,
    ranking: RankingReducer,
    flashDeal: FlashDealReducer,
    timeLimitPromotion: TimeLimitPromotionReducer,
    shop: ShopReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});
