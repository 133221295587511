// import { useState, useLayoutEffect } from "react";
import styled from "styled-components";
import Button from "../Button";
import AlertMessage from "./AlertMessage"
import breakpoint from "../breakpoints";
// import useWindowSize from "../useWindowSize";
import { useTranslation } from "react-i18next";

const DialogContainer = styled.div`
    width: max-content;
    margin: 0 auto;
    position: relative;
    z-index: 20;
`

const Btn = styled(Button)`
    margin: -2vw auto 0 auto;
    @media ${breakpoint.device.mobile} {
        margin: -5vw auto 0 auto;
    }
`

function Dialog(props) {
    // const size = useWindowSize();
    const { t } = useTranslation();

    return (
        <DialogContainer>
            <AlertMessage>{t(`${props.message}`)}</AlertMessage>
            {props.btnLabel !== "" ?
                <Btn
                    bClass={props.bClass}
                    label={t(`${props.btnLabel}`)}
                    width={window.innerWidth <= breakpoint.deviceSize ? "40.48vw" : "9.4vw"}
                    height={window.innerWidth <= breakpoint.deviceSize ? "13.5vw" : "3.13vw"}
                    fontSize={window.innerWidth <= breakpoint.deviceSize ? "4.83vw" : ""}
                    onClick={props.onClick}
                /> : ""
            }

        </DialogContainer>
    )
}

export default Dialog