import { useContext } from "react";
import styled, { css, keyframes } from "styled-components";
// import Logo from "./logo";
// import Currency from "./Currency";
// import Popup from "./PopupMenu";
// import Login from "../Login/index";
import { Transition } from "../../config";
import {
  selectuserProfile,
  actionClickSound,
} from "../../Reducers/userProfileSlice";
import { useSelector, useDispatch } from "react-redux";
// import HeaderBG from "./headerBG";
import { useTranslation } from "react-i18next";
import { SocketContext } from "../../context/socket";
// import JackpotCount from "../Jackpot/jackpotCounter";
// import axios from "axios";
import { config, redirect2Page } from "../../config";
// import { actionCounterLP } from "../../Reducers/counterLPSlice";
import { selectPopup, actionPopup } from "../../Reducers/popupSlice";
import { rubberBand, pulse } from "react-animations";
// import GuestDetect from "../Register/GuestDetect";
import BankAccount from "../Register/BankAccount";
// import breakpoints from "../../LandingPageComponents/breakpoints";

// const UserProfile = lazy(() => import("../UserProfile"));
// const CurrencyPop = lazy(() => import("./Currency"));
// const DepositWithdrawal = lazy(() => import("../DepositWithdrawal/Deposit"));
const TutorialAnimation = keyframes`${rubberBand}`;
const RedeemAnimation = keyframes`${pulse}`;

const DepositBox = styled.div`
  user-select: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 20;
  // background: blue;
  pointer-events: none;
  //Portrait
  @media (orientation: portrait) {
    width: 21vw;
    height: 12.5vw;
    top: -1.85vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    width: 18vw;
    height: 12vw;
    top: -0.8vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 21vw;
          height: 12.5vw;
          top: -1.85vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 18vw;
          height: 12vw;
          top: -0.8vw;
        `} */
`;

const TutorialArrow = styled.img`
  position: absolute;
  width: 9vw;
  top: 75%;
  z-index: 10;
  left: 3vw;
  animation: ${TutorialAnimation} 1.5s linear infinite;
  //Portrait
  @media (orientation: portrait) {
    top: -33%;
    left: 6vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    top: -38%;
  }

  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          top: -33%;
          left: 6vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          top: -38%;
        `} */
`;

const tutorialinfo = keyframes`
    0% { border-left: solid 0.4vw #FF691F; }
    100% {border-left: solid 0.4vw yellow;}
`;
const tutorialinfomobile = keyframes`
    0% { border-left: solid 1vw #FF691F; }
    100% {border-left: solid 1vw yellow;}
`;
const TutorialInfo = styled.div`
  background-color: red;
  // max-width: 15vw;
  // min-width: 4vw;
  max-width: 140%;
  min-width: 89%;
  color: white;
  border-radius: 1vw;
  position: absolute;
  padding: 1vw 1.6vw;
  line-height: 1.4vw;
  bottom: -13vw;
  z-index: 1;
  box-shadow: 0px 0px 50px 20px rgba(0, 0, 0, 0.8);
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;
  // theme ==================
  /* background: #192756; */
  /* box-shadow: inset 0vw 0vw 0.8vw 0.2vw #5DD8FF, inset 0px 0px 4vw rgba(66, 255, 232, 0.63); */
  /* border: solid 0.2vw #BDEFFF; */
  // theme ==================
  h1 {
    font-size: 1.5vw;
    font-weight: 400;
    border-left: solid 0.4vw #ff691f;
    padding: 0vw 0vw 0.5vw 0.5vw;
    line-height: 1.9vw;
    animation-name: ${tutorialinfo};
    animation-duration: 0.2s;
    animation-timing-function: linear;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    // background: red;
  }
  p {
    font-size: 1.2vw;
    color: rgba(255, 255, 255, 0.7);
  }
  //Portrait
  @media (orientation: portrait) {
    bottom: 125%;
    min-width: 120%;
    h1 {
      font-size: 3vw;
      line-height: 3.5vw;
      animation-name: ${tutorialinfomobile};
    }
    p {
      line-height: 3vw;
      font-size: 2vw;
    }
    right: 0%;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    max-width: 140%;
    min-width: 94%;
    bottom: 131%;
    h1 {
      font-size: 3vw;
      line-height: 3vw;
      animation-name: ${tutorialinfomobile};
    }
    p {
      line-height: 2.5vw;
      font-size: 1.8vw;
    }
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          bottom: 125%;
          min-width: 120%;
          h1 {
            font-size: 3vw;
            line-height: 3.5vw;
            animation-name: ${tutorialinfomobile};
          }
          p {
            line-height: 3vw;
            font-size: 2vw;
          }
          right: 0%;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          max-width: 140%;
          min-width: 94%;
          bottom: 131%;
          h1 {
            font-size: 3vw;
            line-height: 3vw;
            animation-name: ${tutorialinfomobile};
          }
          p {
            line-height: 2.5vw;
            font-size: 1.8vw;
          }
        `} */
`;

const TutorialBox = styled.div`
  pointer-events: none;
  width: 97%;
  height: 65%;
  position: absolute;
  bottom: 12%;
  font-size: 24px;
  border: 0.5vw solid;
  resize: both;
  box-shadow: 0px 0px 50px 20px rgba(0, 0, 0, 0.8);
  left: -1.7vw;
  border-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cstyle%3Epath%7Banimation:stroke 1.5s infinite linear%3B%7D%40keyframes stroke%7Bto%7Bstroke-dashoffset:776%3B%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='180%25' y1='0%25' x2='80%25' y2='100%25'%3E%3Cstop offset='0%25' stop-color='%23d02d07' /%3E%3Cstop offset='25%25' stop-color='%23ff5400' /%3E%3Cstop offset='50%25' stop-color='%23ff7e00' /%3E%3Cstop offset='100%25' stop-color='%23ffea00' /%3E%3C/linearGradient%3E %3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='20' stroke-dasharray='193.6'/%3E %3C/svg%3E")
    1;
  //Portrait
  @media (orientation: portrait) {
    height: 53%;
    bottom: 20%;
    left: 0vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    height: 50%;
    bottom: 28.5%;
    left: -0.1vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          height: 53%;
          bottom: 20%;
          left: 0vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          height: 50%;
          bottom: 28.5%;
          left: -0.1vw;
        `} */
`;

const DepositAnims = styled.div`
  user-select: none;
  // background: red;
  cursor: pointer;
  display: flex;
  width: 17vw;
  height: 8.5vw;
  position: absolute;
  right: 20vw;
  z-index: 11;
  justify-content: center;
  align-items: center;
  //Portrait
  @media (orientation: portrait) {
    transform: scale(1.3);
    right: 5vw;
    top: 3vw;
    z-index: 1;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    z-index: 1;
    transform: scale(1.3);
    right: 9vw;
    top: 9vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          transform: scale(1.3);
          right: 5vw;
          top: 3vw;
          z-index: 1;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          z-index: 1;
          transform: scale(1.3);
          right: 9vw;
          top: 9vw;
        `} */
`;

const DepoBase = styled.div`
  background-image: url(../../${config.theme}/newasset/deposit_btn.png);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 17vw;
  height: 8vw;
  /* top: 0.2vw; */
  position: absolute;
  right: 1.5vw;
  /* background-color: red; */
  //Portrait
  @media (orientation: portrait) {
    idth: 22vw;
    height: 12vw;
    right: auto;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    idth: 18vw;
    height: 12vw;
    right: auto;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 22vw;
          height: 12vw;
          right: auto;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 18vw;
          height: 12vw;
          right: auto;
        `} */
`;

const TidBubble = styled.div`
  background-image: url(../../images/tid_pro/bubble.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 4vw;
  height: 3.3vw;
  z-index: 5;
  position: absolute;
  right: 0vw;
  top: 0.5vw;
`;
const Detail = styled.div`
  user-select: none;
  flex-direction: column;
  display: flex;
  margin: 1.4vw 0vw 0vw 3vw;
  color: #7f7b95;
  font-size: 0.95vw;
  width: 10vw;
  text-align: right;
  //Portrait
  @media (orientation: portrait) {
    font-size: 2.2vw;
    margin: 3vw 0vw 0vw 3vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 2.2vw;
    margin: 4.5vw 0vw 0vw 1vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2.2vw;
          margin: 3vw 0vw 0vw 3vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 2.2vw;
          margin: 4.5vw 0vw 0vw 1vw;
        `} */
`;

const DetailList = styled.div`
  user-select: none;
  flex-direction: column;
  justify-content: center;
  display: flex;
  align-items: center;
  position: relative;
  color: #fff;
  font-size: 1vw;
  text-align: right;
  width: 8vw;
  margin: 0.2vw 0 0 -2.5vw;
  top: 0.5vw;
  text-shadow: 0 0 0.8vw black;
  //Portrait
  @media (orientation: portrait) {
    width: 13vw;
    font-size: 1.8vw;
    top: 0vw;
    margin: 0 0 0 -2vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 1.2vw;
    width: 9vw;
    top: 0vw;
    margin: 0 0 0 0vw;
    left: -0.5vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 13vw;
          font-size: 1.8vw;
          top: 0vw;
          margin: 0 0 0 -2vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 1.2vw;
          width: 9vw;
          top: 0vw;
          margin: 0 0 0 0vw;
          left: -0.5vw;
        `} */
`;
const Bardeposit = styled.div`
  user-select: none;
  position: relative;
  background-color: #fcf062;
  width: 5.7vw;
  height: 0.2vw;
  /* margin-top: 0.2vw; */
  left: -1.2vw;
  z-index: 2;
  top: 0.7vw;
  //Portrait
  @media (orientation: portrait) {
    width: 8.7vw;
    height: 0.2vw;
    top: 0vw;
    left: -0.2vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    width: 6.3vw;
    height: 0.2vw;
    top: 0vw;
    left: 0.8vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 8.7vw;
          height: 0.2vw;
          top: 0vw;
          left: -0.2vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 6.3vw;
          height: 0.2vw;
          top: 0vw;
          left: 0.8vw;
        `} */
`;
const piganims = keyframes`
  0% {transform: scaleX(1.2) scaleY(0.9);}
  20% {transform: scaleX(1);}
  80% {transform: scaleX(1);}
  100% {transform: scaleX(1.2) scaleY(0.9);}
`;
const PigContainer = styled.div`
  // transform: scaleY(0.6);
  transform-origin: center bottom;
  bottom: 0;
  position: absolute;
  // background-color: red;
  width: 7vw;
  height: 7vw;
  right: 1vw;
  top: 2vw;
  animation-name: ${piganims};
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-direction: normal;
  animation-iteration-count: infinite;
  z-index: 1;
  //Portrait
  @media (orientation: portrait) {
    top: 3vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          top: 3vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          top: 3vw;
        `} */
`;

const DepoPigmask = styled.div`
  background-image: url(../../${config.theme}/newasset/pig-mask.png);
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  width: 7vw;
  height: 7vw;
  // right: 1vw;
  // top: 1.5vw;
  position: absolute;
  z-index: 3;
`;
const coinfly = keyframes`
  0% {top: 0.5vw;}
  15% {top: -1vw;}
  40% {top: -2.3vw; transform: rotateX(0deg) rotateZ(0deg);}
  50% {top: -2vw; transform: rotateX(180deg) rotateZ(180deg);}
  60% {top: -2.3vw; transform: rotateX(0deg) rotateZ(0deg);}
  85% {top: -1vw;}
  100% {top: 0.5vw;}
`;
const DepoCoinFly = styled.div`
  background-image: url(../../${config.theme}/newasset/coin.png);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 1.7vw;
  height: 1.7vw;
  z-index: 2;
  right: 3vw;
  // top: -1vw;
  position: absolute;
  animation-name: ${coinfly};
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
`;
const coinprop = keyframes`
  0% {top: -0.5vw;}
  100% {top: 0.5vw;}
`;
const DepoPig = styled.div`
  background-image: url(../../${config.theme}/newasset/pig.png);
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  width: 7vw;
  height: 7vw;
  // right: 1vw;
  // top: 1.5vw;
  position: absolute;
  z-index: 1;
`;
const PigCoinProp = styled.div`
  background-image: url(../../${config.theme}/newasset/pigcoin_prop.png);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 11vw;
  height: 8vw;
  z-index: 0;
  right: -1.6vw;
  position: absolute;
  animation-name: ${coinprop};
  animation-duration: 0.7s;
  // animation-timing-function: cubic-bezier(.73,-0.33,.39,1.29);
  animation-timing-function: linear;
  animation-direction: alternate-reverse;
  animation-iteration-count: infinite;
  //Portrait
  @media (orientation: portrait) {
    margin-top: 2vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin-top: 2vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          margin-top: 2vw;
        `} */
`;

const TidPro = styled.div`
  position: absolute;
  z-index: 0;
  margin: 0 auto;
  right: 37.5vw;
  top: 3vw;
  //Portrait
  @media (orientation: portrait) {
    right: 25.5vw;
    top: -5vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    right: 28vw;
    top: 1.5vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          right: 25.5vw;
          top: -5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          right: 28vw;
          top: 1.5vw;
        `} */
`;

const TidBadge = styled.div`
  background-image: url(../../images/tid_pro/badge.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
  width: 15vw;
  height: 5vw;
  text-align: center;
  margin: 0 auto;
  position: absolute;
  // bottom: 0!important;
  top: 2vw;
  z-index: 0 !important;
  p {
    width: 15vw;
    position: absolute;
    bottom: 0.3vw;
    text-align: center;
    color: black;
    font-size: 1vw;
  }
  //Portrait
  @media (orientation: portrait) {
    width: 24vw;
    height: 6vw;
    p {
      width: 18vw;
      position: absolute;
      /* bottom: 0.3vw; */
      /* bottom: 1.5vw; */
      text-align: center;
      color: initial;
      font-size: 1.8vw;
      left: 3vw;
    }
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    width: 21vw;
    height: 6vw;
    p {
      width: 15vw;
      position: absolute;
      /* bottom: 0.3vw; */
      bottom: 0.5vw;
      text-align: center;
      color: initial;
      font-size: 1.5vw;
      left: 2.8vw;
    }
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 24vw;
          height: 6vw;
          p {
            width: 18vw;
            position: absolute;
            text-align: center;
            color: initial;
            font-size: 1.8vw;
            left: 3vw;
          }
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 21vw;
          height: 6vw;
          p {
            width: 15vw;
            position: absolute;
            bottom: 0.5vw;
            text-align: center;
            color: initial;
            font-size: 1.5vw;
            left: 2.8vw;
          }
        `} */

  ${(props) =>
    props.lang === "th" &&
    window.appHeight() > window.innerWidth &&
    css`
      p {
        bottom: 1.5vw;
      }
    `}
`;

// const TidBubble = styled.div`
//   background-image: url(../../images/tid_pro/bubble.png);
//   background-size: contain;
//   background-repeat: no-repeat;
//   width: 4vw;
//   height: 3.3vw;
//   z-index: 5;
//   position: absolute;
//   right: 0vw;
//   top: 0.5vw;
// `;

const RedeemBox = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 9vw;
  height: 1.8vw;
  background: linear-gradient(180deg, #fffe37 0%, #dc7a13 100%);
  border: 0.8px solid #ffc700;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5), inset 0px 0px 3px #ffff00;
  border-radius: 2vw;
  top: 8%;
  right: 5%;
  z-index: 5;
  font-size: 1vw;
  font-weight: 600;
  color: #230000;
  animation: ${RedeemAnimation} 0.75s linear infinite;
`;

const RedeemBoxIcon = styled.div`
  width: 17%;
  height: 85%;
  background-image: url(../../${config.theme}/icon_gift_box.png);
  background-size: contain;
`;

function DepoWithdra(props) {
  const profile = useSelector(selectuserProfile);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  let guestuser = useSelector(selectuserProfile);
  const popupStatus = useSelector(selectPopup);

  // console.log("profile", profile)
  return (
    <>
      <DepositAnims
        onClick={() => {
          if (guestuser.isTelGuest === true && guestuser.isguest === true) {
            dispatch(actionClickSound());
            // dispatch(actionPopup({ registBankAccount: true }));
            redirect2Page("createbankaccount");
          } else if (
            guestuser.isTelGuest === false &&
            guestuser.isguest === true
          ) {
            dispatch(actionClickSound());
            dispatch(actionPopup({ guestDetect: true }));
          } else {
            try {
              socket.emit(
                "getbilog",
                {
                  biname: "deposit_open",
                  action_from: "Deposit",
                },
                (data) => {
                  // console.log(data);
                }
              );
            } catch (error) {
              console.log(error);
            }
            dispatch(actionClickSound());
            dispatch(actionPopup({ deposit: true }));
            // setDeposit(true);
          }
        }}
      >
        <DepositBox>
          {profile.tutorial === 10 ? (
            <>
              <TutorialArrow
                src={
                  window.appHeight() > window.innerWidth
                    ? "../" + config.theme + "/ftue_arrow.png"
                    : "../" + config.theme + "/ftue_arrow_up.png"
                }
              />
              <TutorialBox />
              <TutorialInfo>
                <h1>{t("FTUE bank title")}</h1>
                <p>{t("FTUE bank info")}</p>
              </TutorialInfo>
            </>
          ) : (
            ""
          )}
        </DepositBox>
        <DepoBase>
          {profile.promotion_status ? <TidBubble /> : ""}

          <Detail>
            <DetailList>{t("deposit")}</DetailList>
            <Bardeposit />
            <DetailList>{t("withdrawal")}</DetailList>
          </Detail>
          <PigContainer>
            <DepoPigmask />
            <DepoCoinFly />
            <DepoPig />
          </PigContainer>
          <PigCoinProp />
        </DepoBase>
        {profile?.proRedeem === 1 ? (
          <RedeemBox>
            <RedeemBoxIcon />
            {t("Redeem Code")}
          </RedeemBox>
        ) : (
          ""
        )}
      </DepositAnims>
      {profile.promotion_status ? (
        <TidPro>
          <TidBadge lang={i18n.language}>
            <p>{t("tidpro")}</p>
          </TidBadge>
        </TidPro>
      ) : (
        ""
      )}

      <Transition showPop={popupStatus.registBankAccount} type="st2b">
        <BankAccount
          closeClick={() => {
            dispatch(actionPopup({ registBankAccount: false }));
          }}
        />
      </Transition>
      {/* <Transition showPop={userProfile} type="st2b">
        <UserProfile
          closeClick={() => {
            setUserProfile(false);
          }}
        />
      </Transition> */}

      {/* <Transition showPop={popupStatus.deposit} type="st2b">
        <DepositWithdrawal
          closeClick={() => {
            setDeposit(false);
            dispatch(actionPopup({ deposit: false }));
          }}
        />
      </Transition> */}
      {/* 
      <Transition showPop={guestDetect || popupStatus.guestDetect} type="st2b">
        <GuestDetect
          closeClick={() => {
            setGuestDetect(false);
            dispatch(actionPopup({ guestDetect: false }));
          }}
        />
      </Transition> */}
    </>
  );
}
export default DepoWithdra;
