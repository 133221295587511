import React from 'react'
import styled from 'styled-components';
import Aboutitem from './Aboutitem';
import { useTranslation } from 'react-i18next';
import breakpoint from "../breakpoints";

const Background = styled.div`
    width:100%;
    height:29vw;
    bottom:0;
    text-align: -webkit-center;
    background-image:url("../../lpimages/bg_content2.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    @media ${breakpoint.device.mobile} {
         // height:51.21vw;
         background-image:url("../../lpimages/bg_content.png");
         background-size: 130%;
        background-position-x: center;
    }
  
`;

const Title = styled.div`
    justify-content:center;
    margin-top: -8vw;
    padding-bottom: 2.5vw;
    background-color:black;
    text-align: -webkit-center;
    -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  text-align: -webkit-center; 
    justify-content: center;
    display: flex;
    @media ${breakpoint.device.mobile} {
        margin-top: -15vw;
    }
  
`;

const Box = styled.div`
    width: 50%;
    // background-color:black;
    display: flex;
    /* position: relative; */
    justify-content:center;
    
    @media ${breakpoint.device.ipad} {
        width: 62%;
    }
    @media ${breakpoint.device.mobile} {
        width: 100%;
        display:inline-block;
        margin-top: 15vw;
    }
`;
const WhyBanner = styled.img`
width: 60vw;

 @media ${breakpoint.device.mobile} {
    width: 85vw; 
    }
`;



function Index() {
    const { t } = useTranslation();

    return (
        <>
            <Background>
                <WhyBanner src="../lpimages/why_content.png" />

            </Background>
            <Title>
                <Box>
                    <Aboutitem
                        img="item1"
                        title={t("Tangiility")}
                        detail={t("TangiilityDes")}
                    />
                    <Aboutitem
                        img="item2"
                        title={t("Credibility")}
                        detail={t("CredibilityDes")}
                    />
                    <Aboutitem
                        img="item3"
                        title={t("Entertainment")}
                        detail={t("EntertainmentDes")}
                    />
                    <Aboutitem
                        img="item4"
                        title={t("Diversity")}
                        detail={t("DiversityDes")}
                    />
                </Box>
            </Title>

        </>
    );
}
export default Index;