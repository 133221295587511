import React from 'react';
import styled from 'styled-components';
import breakpoint from "../breakpoints";


const Frame = styled.button`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  text-decoration: none;
  min-width: 0px;
  min-height: 0px;
  @media ${breakpoint.device.mobile} {
    padding: 0vw 2vw;
    background-color:#101119;
    border: 2px;
  }
`;

const Icon = styled.img`
  width: 43.1%;
  height: 50%;
  margin-left: 7.5%;
  @media ${breakpoint.device.mobile} {
    width: initial;
    height: 6.04vw;
    margin-left: initial;
  }
`;

const Text = styled.div`
  padding-left:1vw;
  padding:0.5vw;
  color: white;
  text-transform: none;
  font-size: 1vw;
  // transition: 750ms;
  @media ${breakpoint.device.mobile} {
    font-size: 4.83vw;
    padding-top: 1.5vw;
    padding-left: 3vw;
  }
`;
function LanguageItem(props) {
  // console.log("th", props.lang)

  return (
    <Frame onClick={() => {
      // i18next.changeLanguage(props.lang);
    }}
      {...props}>
      <Icon src={"../../lpimages/" + props.icon} />
      <Text>{props.label}</Text>
    </Frame>
  );
}
export default LanguageItem;