import styled from "styled-components";
import Button from "./Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router";
import breakpoint from "./breakpoints";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectDimensionsLP } from "../Reducers/dimensionsLP";
import { actionLoading } from "../Reducers/loadingSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

const ContentContainer = styled.div`
  width: 58vw;
  margin: 5vw auto 10vw auto;
  text-align: center;
  padding: 4vw 0 2vw 0;
  > hr {
    width: 95%;
    height: 0.1vw;
    border: none;
    background-color: #4e5476;
    margin-top: 1vw;
  }
  > p {
    font-size: 0.94vw;
    color: #c5c9ec;
    text-align: start;
    white-space: break-spaces;
  }
  a {
    color: #ff7000;
  }
  @media ${breakpoint.device.mobile} {
    width: 92vw;
    margin: 19vw auto 10vw auto;
    padding: 4vw 2vw 2vw 2vw;
    > p {
      font-size: 3.38vw;
      white-space: break-spaces;
    }
    > hr {
      display: none;
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > h1 {
    font-size: 1.56vw;
    color: #ffffff;
    text-align: left;
    margin-right: 1vw;
  }
  @media ${breakpoint.device.mobile} {
    > h1 {
      font-size: 4.2vw;
    }
  }
`;

const Image = styled.img`
  height: calc(100vw * 0.26);
  background-color: #c4c4c4;
  margin-top: 1vw;
  @media ${breakpoint.device.mobile} {
    height: calc(100vw * 0.5);
  }
`;

const FontIcon = styled(FontAwesomeIcon)`
  color: #fff;
  padding-right: 0.5vw;
  @media ${breakpoint.device.mobile} {
    padding-right: 1vw;
  }
`;

function Content(props) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const dimensionsLP = useSelector(selectDimensionsLP);

  const ScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  };

  useEffect(() => {
    dispatch(actionLoading(true));
    ScrollToTop();
  }, []);

  // console.log("content props", props)

  return (
    <ContentContainer>
      <Header>
        {props.howtouse === "howtouse" ? (
          <h1>{props.header}</h1>
        ) : props.promotion === "promotion" ? (
          <h1>{props.name}</h1>
        ) : props.article === "article" ? (
          i18n.language === "en" ? (
            <h1>{props.title_en}</h1>
          ) : (
            <h1>{props.title_th}</h1>
          )
        ) : (
          ""
        )}
        <Button
          bClass="rtRed"
          label={[
            <FontIcon key="fontIcon" icon={faArrowLeft} />,
            <p key="label">{t("Back")}</p>,
          ]}
          width={
            dimensionsLP.width <= breakpoint.deviceSize ? "24.15vw" : "6.8vw"
          }
          height={
            dimensionsLP.width <= breakpoint.deviceSize ? "9.66vw" : "2.6vw"
          }
          fontSize={
            dimensionsLP.width <= breakpoint.deviceSize ? "4.83vw" : "1vw"
          }
          onClick={() => history.goBack()}
        />
      </Header>
      <hr />

      {props.howtouse === "howtouse" ? (
        <>
          <Image src={props.image_url} />
          <p dangerouslySetInnerHTML={{ __html: props.description }} />
        </>
      ) : props.promotion === "promotion" ? (
        <>
          <Image src={props.image} />
          <p dangerouslySetInnerHTML={{ __html: props.detail }} />
        </>
      ) : props.article === "article" ? (
        <>
          <Image src={props.img} />
          {i18n.language === "en" ? (
            <p dangerouslySetInnerHTML={{ __html: props.detail_en }} />
          ) : (
            <p dangerouslySetInnerHTML={{ __html: props.detail_th }} />
          )}
        </>
      ) : (
        ""
      )}
    </ContentContainer>
  );
}

export default Content;
