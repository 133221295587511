import React from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { config } from "../../config";
import { selectuserProfile } from "../../Reducers/userProfileSlice";
import { selectCountDownTime } from "../../Reducers/flashDealSlice";
import { useTranslation } from "react-i18next";

const BannerContainer = styled.div`
  user-select: none;
  width: 7vw;
  height: 7vw;
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 20;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 20vw;
          height: 20vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          width: 12vw;
          height: 12vw;
        `}
`;

const IMG = styled.img`
  width: 100%;
  cursor: pointer;
`;

const Timer = styled.div`
  pointer-events: none;
  font-size: 1.1vw;
  font-weight: 400;
  color: #ffc700;
  position: absolute;
  white-space: nowrap;
  bottom: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 0px 0px 0.3vw #000, 0px 0px 0.3vw #000, 0px 0px 0.3vw #000,
    0px 0px 0.3vw #000;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2.8vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          font-size: 1.8vw;
        `}
`;

const TimeLeftText = styled.div`
  pointer-events: none;
  font-size: 1vw;
  font-weight: 400;
  color: #ffc700;
  position: absolute;
  white-space: nowrap;
  bottom: 22%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 0px 0px 0.3vw #000, 0px 0px 0.3vw #000, 0px 0px 0.3vw #000,
    0px 0px 0.3vw #000;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2.5vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          font-size: 1.5vw;
        `}
`;

function FlashDealBanner(props) {
  const profile = useSelector(selectuserProfile);
  const countDownTime = useSelector(selectCountDownTime);
  const { t } = useTranslation();
  return (
    <BannerContainer>
      <IMG
        src={
          profile.language === "TH"
            ? "../images/promotion_banner_new/flashdeal_ico_th.png"
            : "../images/promotion_banner_new/flashdeal_ico_en.png"
        }
        onClick={() => {
          props.setFlashDealPop(true);
        }}
      />
      <TimeLeftText>{t("Flash Deal Time Left")}</TimeLeftText>
      <br />
      <Timer>
        {profile.language === "TH"
          ? (isNaN(countDownTime.hour) ? "0" : countDownTime.hour) +
          "ชม." +
          (isNaN(countDownTime.minute) ? "0" : countDownTime.minute) +
          "น." +
          (isNaN(countDownTime.second) ? "0" : countDownTime.second) +
          "วิ."
          : (isNaN(countDownTime.hour) ? "0" : countDownTime.hour) +
          "h " +
          (isNaN(countDownTime.minute) ? "0" : countDownTime.minute) +
          "m " +
          (isNaN(countDownTime.second) ? "0" : countDownTime.second) +
          "s"}
      </Timer>
    </BannerContainer>
  );
}

export default FlashDealBanner;
