import styled, { css, keyframes } from "styled-components";
import { tada } from "react-animations";
import { config } from "../../config";

const NoticeAnimation = keyframes`${tada}`;

const Dot = styled.img`
  user-select: none;
  width: 2.5vw;
  position: absolute;
  animation: ${NoticeAnimation} 1.5s linear infinite;
  //Portrait
  @media (orientation: portrait){
    width: 3.5vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 3.5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 3.5vw;
        `} */
`;

export default function RedDot(props) {
  return (
    <>
      {props.active === true ? (
        <Dot src={"../" + config.theme + "/notice_dot.png"} {...props} />
      ) : (
        ""
      )}
    </>
  );
}
