import { createSlice } from "@reduxjs/toolkit";
import { Howl } from "howler";

const clickSound = new Howl({
  src: ["Sound/Click.mp3"],
  loop: false,
  volume: 0.5,
});

const switchSound = new Howl({
  src: ["Sound/ToggleOnOff.mp3"],
  loop: false,
  volume: 0.5,
});

const closeSound = new Howl({
  src: ["Sound/WindowClose.mp3"],
  loop: false,
  volume: 0.5,
});

const winPopSound = new Howl({
  src: ["Sound/WindowPopUp.mp3"],
  loop: false,
  volume: 0.5,
});

const getRewardSound = new Howl({
  src: ["Sound/RewardShow.mp3"],
  loop: false,
  volume: 0.5,
});

const pigSound = new Howl({
  src: ["Sound/Pig.mp3"],
  loop: false,
  volume: 0.5,
});

// const WheelBGM = new Howl({
//   src: ["Sound/BGM_03.mp3"],
//   loop: true,
//   volume: 0.5,
// });

// const TreasureBGM = new Howl({
//   src: ["Sound/BGM_04.mp3"],
//   loop: true,
//   volume: 0.5,
// });

// const FesMinigameBGM = new Howl({
//   src: ["Sound/VLTbgm_In-game.mp3"],
//   loop: true,
//   volume: 0.5,
// });

const GetRewardSoundAddOn = new Howl({
  src: ["Sound/2_Pop-up_Diamonds_Reward.mp3"],
  loop: false,
  volume: 0.3,
});

// const MinislotBGM = new Howl({
//   src: ["Sound/minislot/bgm_game_base.mp3"],
//   loop: true,
//   volume: 0.5,
// });

// const MinislotSpecialBGM = new Howl({
//   src: ["Sound/minislot/bgm_game_special.mp3"],
//   loop: true,
//   volume: 0.5,
// });

export const userProfileSilce = createSlice({
  name: "userprofile",
  initialState: {
    profile: {
      avatar: 0,
      balance: 0,
      bankAccount: "",
      bankShortName: "",
      dailyreward: 0,
      diamond: 0,
      email: "",
      expiredAt: 0,
      getreward1: "",
      getreward2: "",
      getreward3: "",
      id: "",
      level: 0,
      mobile: "",
      parentid: "",
      agent_id: "",
      setbonus: "",
      seteffect: "C",
      setsound: "C",
      spinticket: 0,
      timeevent: "",
      treasureticket: 0,
      userId: "",
      username: "",
      bonusinvfriend: 0,
      notiemail: "",
      notipromotion: "",
      notiranking: "",
      status: "",
      experience: 0,
      expmin: 0,
      expmax: 0,
      nextLevelExp: 0,
      totalExp: 0,
      refercode: "",
      password: "",
      brands_id: "",
      first_login: false,
      first_withdraw: true,
      banner_pro_popup: false,
      account_api: "",
      discount: 0,
      line_id: "",
      redirect: "F",
      language: "TH",
      tutorial: 0,
      tutorial_status: [],
      isguest: false,
      mission_complete: [],
      currency_use: "",
      currency_use_type: "c1",
      itemgroup: "",
      isTelGuest: false,
      refercodeby: "",
      freeSpinUrl: "",
      isplayminigame: false,
      affiliate: 0,
      cashback: 0,
      commission: 0,
      cashpoint: 0,
      wheelBGMStatus: false,
      treasureBGMStatus: false,
      diamondBGMStatus: false,
      FesBGMStatus: false,
      promotion_name: "",
      promotion_status: false,
      currentTime: Date.now(),
      ftueRegister: 0,
      popup: [],
      cny: { angpao: [], is_select: 0, selected: [] },
      playfishing: 0,
      valentine: {
        choice: [],
        selected: [],
        is_select: 0,
        day: 1,
      },
      levelup: 0,
      bank_name_th: "",
      latestGame: [],
      skf: { stage: [] },
      jackpot: [],
      specialOffer: {},
      minislotBGMStatus: false,
      cmurl: "",
      minislotSpecialBGMStatus: false,
      // minislotBGM: MinislotBGM,
      // minislotSpecialBGM: MinislotSpecialBGM,
      timeLimit: [{}],
      suspendState: false,
    },
  },
  reducers: {
    actionSetprofile: (state, action) => {
      // console.log("state", action.payload);
      state.profile = { ...state.profile, ...action.payload };
    },
    actionClickSound: (state) => {
      if (state.profile.seteffect === "O") {
        clickSound.play();
      }
    },
    actionSwitchSound: (state) => {
      if (state.profile.seteffect === "O") {
        switchSound.play();
      }
    },
    actionCloseSound: (state) => {
      if (state.profile.seteffect === "O") {
        closeSound.play();
      }
    },
    actionWinPopSound: (state) => {
      if (state.profile.seteffect === "O") {
        winPopSound.play();
      }
    },
    actionGetRewardSound: (state) => {
      if (state.profile.seteffect === "O") {
        getRewardSound.play();
        GetRewardSoundAddOn.play();
      }
    },
    actionPigSound: (state) => {
      if (state.profile.seteffect === "O") {
        pigSound.play();
      }
    },
    // actionWheelBGM: (state, action) => {
    //   if (state.profile.seteffect === "O") {
    //     if (action.payload) {
    //       WheelBGM.play();
    //     } else {
    //       WheelBGM.stop();
    //     }
    //   } else {
    //     WheelBGM.stop();
    //   }
    // },
    // actionTreasureBGM: (state, action) => {
    //   if (state.profile.seteffect === "O") {
    //     if (action.payload) {
    //       TreasureBGM.play();
    //     } else {
    //       TreasureBGM.stop();
    //     }
    //   } else {
    //     TreasureBGM.stop();
    //   }
    // },
    // actionDiamondCrackerBGM: (state, action) => {
    //   if (state.profile.seteffect === "O") {
    //     if (action.payload) {
    //       DiamondCrackerBGM.play();
    //     } else {
    //       DiamondCrackerBGM.stop();
    //     }
    //   } else {
    //     DiamondCrackerBGM.stop();
    //   }
    // },
    // actionFesMinigameBGM: (state, action) => {
    //   if (state.profile.setsound === "O" && action.payload) {
    //     // FesMinigameBGM.play();
    //     WheelBGM.play();
    //   } else {
    //     WheelBGM.stop();
    //     // FesMinigameBGM.stop();
    //   }
    // },
    // actionMinislotBGM: (state, action) => {
    //   if (state.profile.setsound === "O") {
    //     if (action.payload) {
    //       MinislotBGM.play();
    //     } else {
    //       MinislotBGM.stop();
    //     }
    //   } else {
    //     MinislotBGM.stop();
    //   }
    // },
    // actionMinislotSpecialBGM: (state, action) => {
    //   if (state.profile.setsound === "O") {
    //     if (action.payload) {
    //       MinislotSpecialBGM.play();
    //     } else {
    //       MinislotSpecialBGM.stop();
    //     }
    //   } else {
    //     MinislotSpecialBGM.stop();
    //   }
    // },
  },
});
export const {
  actionSetprofile,
  actionClickSound,
  actionSwitchSound,
  actionCloseSound,
  actionWinPopSound,
  actionGetRewardSound,
  actionPigSound,
  // actionWheelBGM,
  // actionTreasureBGM,
  // actionDiamondCrackerBGM,
  actionFesMinigameBGM,
  // actionMinislotBGM,
  // actionMinislotSpecialBGM,
} = userProfileSilce.actions;
export const selectuserProfile = (state) => state.userprofile.profile;

export default userProfileSilce.reducer;
