import { createSlice } from "@reduxjs/toolkit";
export const dimensionLPSlice = createSlice({
  name: "dimensionsLP",
  initialState: {
    dimension: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
  },
  reducers: {
    setDimensionLP: (state, action) => {
      state.dimension = action.payload;
    },
  },
});

export const { setDimensionLP } = dimensionLPSlice.actions;
export const selectDimensionsLP = (state) => state.dimensionsLP.dimension;
export default dimensionLPSlice.reducer;
