import React from "react";
import styled, { css } from "styled-components";
import ScaleFrame from "../ScaleFrame";
import Menu from "./Menu";
import { useDispatch, useSelector } from "react-redux";
import { config } from "../../config";
import {
  actionSetprofile,
  selectuserProfile,
} from "../../Reducers/userProfileSlice";
// import { SocketContext } from "../../context/socket";
import {
  actionPopup,
  actionUpdatePopup,
  selectPopupList,
} from "../../Reducers/popupSlice";

const Container = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 70;
  align-items: center;
  justify-content: center;
`;

const BgClose = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  background-color: transparent;
  z-index: 20;
  left: 0%;
  top: 0%;
  position: fixed;
`;

const Popup = styled.div`
  user-select: none;
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;
  border-radius: 2vw;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  z-index: 70;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseBtn = styled.img`
  user-select: none;
  position: absolute;
  width: 4vw;
  height: 4vw;
  right: -2.3%;
  top: -1.5vw;
  z-index: 10;
  cursor: pointer;
  ${() =>
    window.appHeight() > window.innerWidth &&
    css`
      width: 7vw;
      height: 7vw;
      top: -3.5vw;
      right: -3vw;
    `}
  ${() =>
    window.appHeight() / window.innerWidth > 1.8 &&
    css`
      width: 7vw;
      height: 7vw;
      top: -3.5vw;
      right: -3vw;
    `}
`;

function EventPopupBanner(props) {
  const dispatch = useDispatch();
  const popupList = useSelector(selectPopupList);
  const profile = useSelector(selectuserProfile);

  // console.log("profile.popup", profile.popup);

  const setEventPopup = () => {
    if (profile.popup.filter((a) => a.no === 1).length > 0) {
      dispatch(actionUpdatePopup(""));
    }
    dispatch(actionSetprofile({ popup: popupList }));
    dispatch(actionPopup({ eventBanner: false }));
    // dispatch(actionCloseSound());
  };

  return (
    <Container>
      <ScaleFrame>
        <BgClose
          onClick={() => {
            setEventPopup();
          }}
        />
        <Popup
          style={{
            width:
              window.appHeight() / window.innerWidth > config.verticalSizeiphone
                ? "93vw"
                : window.appHeight() > window.innerWidth
                  ? "85.07vw"
                  : window.innerWidth / window.appHeight() > 2.15
                    ? "70vw"
                    : "70vw",
            height:
              window.appHeight() / window.innerWidth > config.verticalSizeiphone
                ? "152.07vw"
                : window.appHeight() > window.innerWidth
                  ? "92.07vw"
                  : window.innerWidth / window.appHeight() > 2.15
                    ? "42vw"
                    : "44vw",
          }}
        >
          <CloseBtn
            src={"../" + config.theme + "/btn_close.png"}
            onClick={() => {
              setEventPopup();
            }}
          />
          <Menu />
        </Popup>
      </ScaleFrame>
    </Container>
  );
}

export default EventPopupBanner;
