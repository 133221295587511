import { useState, useEffect } from "react";
import styled, { css } from "styled-components";
// import PageOtp from "./SetOtp";
import Popup from "../Popup";
import Button from "../Button";
import Dialog from "../Dialog/AlertMessage";
import { useTranslation } from "react-i18next";
import { Transition, config } from "../../config";
import { socket } from "../../context/socket";
import AlertMessage from "../Dialog/AlertMessage";
import ConfirmPopup from "../Dialog/ConfirmPopup";
import { selectPopup, actionPopup } from "../../Reducers/popupSlice";
import Loading from "../Loading";
import SetPinCode from "./SetPinCode";
import { useDispatch, useSelector } from "react-redux";
import {
  setFloatingValue,
  setFloatingStatus,
  setFloatingLabel,
} from "../../Reducers/floatingInputSlice";
import ReCAPTCHA from "react-google-recaptcha";
import { RGCaptcha, verify } from "react-geetest-captcha";
// import { selectuserProfile } from "../../Reducers/userProfileSlice";

const BG = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0);
  display: flex;
  z-index: 75;
  align-items: center;
  justify-content: center;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone &&
    css`
      background: rgba(0, 0, 0, 0.4);
    `}
`;

const BGpopUp = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  z-index: 20;
  align-items: center;
  justify-content: center;
`;

const ForgetPasswordContainer = styled.div`
  width: max-content;
  margin: 1.5vw auto 0vw auto;
  position: relative;
  z-index: 20;
  color: #fff;
  /* margin-top: 1.5vw; */
  /* margin-left: 2.3vw; */

  h4 {
    font-size: 1.25vw; //24px
    color: #fff;
    ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
            font-size: 2vw;
          `
      : window.appHeight() > window.innerWidth &&
      css`
            font-size: 2vw;
          `}
  }
  p {
    font-size: 1.2vw;
    display: flex;
    justify-content: center;
    ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
            font-size: 2vw;
          `
      : window.appHeight() > window.innerWidth &&
      css`
            font-size: 2vw;
          `}
  }
  h6 {
    width: 20.83vw;
    color: #797fa1;
    font-size: 0.8vw; //12px
    font-weight: normal;
    display: flex;
    margin-top: 2vw;
    margin-block-start: 0.2vw;
    font-size: 1vw;
    margin-block-end: 1vw;
    ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
            font-size: 1.9vw;
          `
      : window.appHeight() > window.innerWidth &&
      css`
            font-size: 1.5vw;
          `}
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          /* margin-left: auto; */
          margin: 3.5vw auto 0vw auto;
        `
      : window.appHeight() > window.innerWidth && css``}
`;

const Input = styled.input`
  width: 18vw;
  height: 4vw;
  color: #fff;
  font-size: 1.2vw;
  background-color: #000000;
  border: none;
  border-radius: 0.5vw;
  outline: transparent;
  /* display: flex; */
  padding: 0 1vw;

  ::placeholder {
    color: rgba(255, 255, 255, 0.3);
    font-size: 1.04vw;
    align-items: center;
  }

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 57vw;
          height: 10.5vw;
          border-radius: 1.5vw;
          font-size: 4vw;
          ::placeholder {
            font-size: 3.3vw;
            align-items: center;
          }
        `
      : window.appHeight() > window.innerWidth &&
      css`
          width: 41vw;
          font-size: 2.5vw;
          background-color: #000000;
          border: none;
          border-radius: 0.5vw;
          height: 5.2vw;
          ::placeholder {
            color: rgba(255, 255, 255, 0.3);
            font-size: 2.04vw;
            align-items: center;
          }
        `}
`;


const Content = styled.div`
  font-size: 1.5vw;
  color: #fff;
  display: flex;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 3.5vw;
          margin-left: 1vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          font-size: 2.5vw;
        `}
`;

const PhoneContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PhoneBox = styled.div`
  display: flex;
  align-items: center;
`;

const TextPopUp = styled.div`
  font-size: 1vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 3vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          font-size: 2vw;
        `}
`;

const OtpButton = styled.button`
  width: 10vw;
  height: 4vw;
  border-radius: 0.5vw;
  border: none;
  background: #ff691f;
  color: #fff;
  font-size: 1vw;
  white-space: nowrap;
  cursor: pointer;
  margin-left: 1vw;
  p {
    display: flex;
    /* width: 1vw; */
    line-height: 2vw;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          height: 9.5vw;
          /* margin-left: 55.5vw; */
          font-size: 2vw;
          width: 20vw;
          p {
            width: 17.5vw;
            font-size: 3vw;
          }
        `
      : window.appHeight() > window.innerWidth &&
      css`
          width: 11vw;
          height: 5vw;
          /* margin-left: 41vw; */
          /* margin-left: 42vw; */
          font-size: 1.5vw;
        `}
`;

const OtpButtonGray = styled.button`
  width: 10vw;
  height: 4vw;
  border-radius: 0.5vw;
  border: none;
  background: #686678;
  color: #fff;
  font-size: 1vw;
  /* white-space: nowrap; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-left: 1vw;
  p {
    height: 5vw;
    width: 15vw;
    display: flex;
    align-items: center;
    line-height: 2vw;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          height: 9.5vw;
          /* margin-left: 55.5vw; */
          font-size: 2vw;
          width: 20vw;
          p {
            font-size: 3vw;
          }
        `
      : window.appHeight() > window.innerWidth &&
      css`
          width: 12vw;
          height: 5vw;
          /* margin-left: 41vw; */
          /* margin-left: 43.5vw; */
          font-size: 1.5vw;
        `}
`;

const OTPBox = styled.div`
  display: flex;
  align-items: center;
`;

const InputOTP = styled.input`
  width: 29vw;
  height: 4vw;
  color: #fff;
  font-size: 1.2vw;
  background-color: #000000;
  border: none;
  border-radius: 0.5vw;
  outline: transparent;
  padding: 0 1vw;
  ::placeholder {
    color: rgba(255, 255, 255, 0.3);
    font-size: 1.04vw;
    align-items: center;
  }

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 4vw;
          width: 78vw;
          height: 10.3vw;
          border-radius: 1.5vw;
          ::placeholder {
            font-size: 3.2vw;
            align-items: center;
          }
        `
      : window.appHeight() > window.innerWidth &&
      css`
          width: 54vw;
          font-size: 2.5vw;
          background-color: #000000;
          border: none;
          border-radius: 0.5vw;
          height: 5.2vw;
          ::placeholder {
            color: rgba(255, 255, 255, 0.3);
            font-size: 2.04vw;
            align-items: center;
          }
        `}
`;

const ContainerError = styled.div`
  display: flex;
  flex-direction: row;
  /* position: absolute; */
`;

const ContainerCountOtp = styled.div`
  /* position: absolute; */
  width: 19.5vw;
  display: flex;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 35vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          width: 30vw;
        `}
`;

const Textotp = styled.div`
  user-select: none;
  color: #e13b00;
  display: flex;
  font-size: 1vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          font-size: 1.8vw;
        `}
`;

const Timer = styled.div`
  user-select: none;
  color: #e13b00;
  font-size: 1vw;
  width: 3.5vw;
  position: relative;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2vw;
          margin-left: 0.5vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          font-size: 1.8vw;
        `}
`;

const Mintext = styled.div`
  user-select: none;
  color: #e13b00;
  font-size: 1vw;
  position: relative;
  width: 4.5vw;
  text-align: left;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2vw;
          margin-left: 2.5vw;
          width: 3.5vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          font-size: 1.8vw;
          margin-left: 1.6vw;
        `}
`;

const ContainerOTP = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  top: 2vw;
  position: relative;
  /* width: 22.75vw; */
  /* margin-left: 3.3vw; */
  /* position: absolute; */
  /* bottom: 7%; */
  /* margin-bottom: -7%; */
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          top: 4vw;
        `
      : window.appHeight() > window.innerWidth && css``}
`;

const ReCAPTCHACustom = styled(ReCAPTCHA)`
  width: max-content;
  max-height: 6vw;
  margin: 1vw auto;

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          max-height: 21vw;
          > div > div {
            transform: scale(0.85);
            /* width:20vw ; */
          }
        `
      : window.innerWidth / window.appHeight() > 2.15
        ? css`
          max-height: 10vw;
          > div > div {
            transform: scale(0.75);
          }
        `
        : window.appHeight() > window.innerWidth &&
        css`
          /* width: 71%; */
          max-height: 10vw;
          margin: 2vw auto;
        `}
`;

const Textsupport = styled.div`
  user-select: none;
  color: #ffffff;
  text-align: left;
  display: flex;
  line-height: 2.6vw;
  font-size: ${(props) => (props.lang === "en" ? "0.75vw" : "1vw")};
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 80vw;
          margin-top: 1.5vw;
          font-size: 3vw;
          line-height: 3vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          /* width: 53vw; */
          margin-top: 1.5vw;
          font-size: 1.8vw;
        `}
`;

const Spinixtext = styled.div`
  user-select: none;
  color: #00c300;
  font-size: 0.9vw;
  top: 7.5vw;
  cursor: pointer;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2.8vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          font-size: 1.3vw;
        `}
`;

const Lineimg = styled.img`
  user-select: none;
  width: 2.5vw;
  height: 2.5vw;
  position: relative;
  cursor: pointer;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 5vw;
          height: 5vw;
          top: -1.5vw;
          margin-bottom: -2.5vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          width: 4vw;
          height: 4vw;
          top: -1vw;
          margin-bottom: -2vw;
        `}
`;

const PopupCustom = styled(Popup)`
  /* height: auto; */
  > div > div {
    position: initial;
    top: initial;
  }
`;

function Forgotpassword(props) {
  const { t, i18n } = useTranslation();
  const [tel, setTel] = useState("");
  const [phone, setPhone] = useState("");
  const [load, setLoad] = useState(false);
  const [complete, setComplete] = useState(false);
  const [message, setMessage] = useState("");
  const [popUpCheckPhone, setPopUpCheckPhone] = useState("");
  const [popUpCheckOtp, setPopUpCheckOtp] = useState(false);
  const [timeleft, setTimeleft] = useState(0);
  const dispatch = useDispatch();
  const popupStatus = useSelector(selectPopup);
  const [emitError, setEmitError] = useState({
    status: false,
    message: "",
    errorCode: "",
  });
  const [countDownTime, setCountDownTime] = useState({ min: 0, sec: 0 });
  const [countDown, setCountDown] = useState({ min: 0, sec: 0 });
  const [timeleftCount, setTimeleftCount] = useState(0);
  const [Otp, setOtp] = useState("");
  const [dataOtp, setDataOtp] = useState("");
  const [captcha, setCaptcha] = useState(null);
  // const [reqNewOtp, setReqNewOtp] = useState(false);
  const [step, setStep] = useState(1);
  // const profile = useSelector(selectuserProfile);
  const [arrayUrl, setArrayUrl] = useState("");
  const [lineId, setLineId] = useState([{}]);
  const [reCaptchaState, setReCaptchaState] = useState({
    status: 0,
    captcha_type: 0,
    site_key: "",
  });

  // console.log("reCaptcha:", reCaptchaState?.status);

  useEffect(() => {
    try {
      socket.emit("getbrandcaptcha", {}, (data) => {
        console.log("Recaptcha", data);
        setReCaptchaState(data.results);
      });
    } catch (error) { }
  }, []);

  // Captcha
  const CAPNAME = "captchaRegister";
  const [token, setToken] = useState(null);

  useEffect(() => {
    console.log("reCaptchaState", reCaptchaState);
    if (reCaptchaState?.captcha_type === 1 && reCaptchaState?.status === 1) {
      socket.emit("getGeetestToken", {}, (body) => {
        console.log("getGeetestToken", body);
        setToken(body);
      });
    }
  }, [reCaptchaState]);

  let reqExpPhone = new RegExp(/^[0-9\b]+$/);

  function validTelPhone(tel = "", debug = false) {
    if (typeof tel !== "string") {
      if (debug) {
        // console.log("Type of MissMatch String");
      }
      return false;
    }
    if (tel.length < 0) {
      if (debug) {
        // console.log("String is Zero lenght!");
      }
      return false;
    }

    let cleaned_tel = ("" + tel).replace(/\D/g, "");
    if (debug) {
      // console.log(cleaned_tel);
    }
    let tmp_obj = {
      v1: "",
      v2: "",
      vCheck1: ["0"],
      vCheck2: ["6", "8", "9"],
      result: [false, false],
      sumResult: true,
    };
    tmp_obj.v1 = cleaned_tel.substring(0, 1);
    tmp_obj.v2 = cleaned_tel.substring(1, 2);
    tmp_obj.result[0] = tmp_obj.vCheck1.includes(tmp_obj.v1);
    tmp_obj.result[1] = tmp_obj.vCheck2.includes(tmp_obj.v2);
    for (const value of tmp_obj.result) {
      if (debug) {
        // console.log(value);
      }
      if (value === false) {
        tmp_obj.sumResult = value;
      }
    }
    if (debug) {
      // console.log("result =>", tmp_obj);
    }
    return tmp_obj.sumResult;
  }

  const handleTel = (data) => {
    console.log("handleTel data", data, captcha);
    try {
      socket.emit(
        "changepassword_getotp",
        {
          tel: tel,
          token: captcha,
          ...data,
        },
        (body) => {
          setLoad(false);
          console.log("changepassword_getotp", body, captcha);
          if (typeof body !== "undefined") {
            if (body.status === true) {
              setTimeleftCount(180);
              setComplete(true);
              setTimeout(() => {
                setComplete(false);
                setPhone(tel);
                setStep(2);
                // dispatch(actionPopup({ setOTPPage: true }));
              }, 3000);

              setMessage("system via register");
            } else if (body.status === false) {
              if (body.errorCode === "s0063") {
                setPopUpCheckPhone(true);
              } else if (body.errorCode === "303") {
                setPopUpCheckPhone(true);
              } else if (body.errorCode === "s0032") {
                setTimeleft(parseInt(body.results.timeleft));
                setStep(2);
                setPhone(tel);
                dispatch(actionPopup({ setOTPPage: true }));

                setEmitError({ status: true, message: body.errorCode });
                setTimeout(() => {
                  setEmitError({ status: false });
                }, 3000);
              } else {
                setEmitError({ status: true, message: body.errorCode });
                setTimeout(() => {
                  setEmitError({ status: false });
                }, 3000);
              }
            }
          }
        }
      );
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  };

  const handleOTP = () => {
    try {
      socket.emit(
        "changepassword_checkotp",
        {
          tel: phone,
          otp: Otp,
        },
        (body) => {
          setLoad(false);
          console.log("changepassword_checkotp", body);
          if (typeof body !== "undefined") {
            if (body.status === true) {
              dispatch(actionPopup({ setPinCodePage: true }));
              setDataOtp(Otp);
            } else if (body.status === false) {
              if (body.errorCode === "s0031") {
                // setCheckOtpPopup(true);
                setPopUpCheckOtp(true);
                setEmitError({ message: body.errorCode });
                setDataOtp(Otp);
              } else {
                setEmitError({ status: true, message: body.errorCode });
                setTimeout(() => {
                  setEmitError({ status: false });
                }, 3000);
              }
            }
          }
        }
      );
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  };

  const reset = () => {
    if (timeleftCount !== 0) {
      let time = timeleftCount;
      setCountDownTime(fnTimeleftCount(time));
      ChangeCountdownTime = setInterval(() => {
        if (time <= 0) {
          time = 0;
          clearInterval(ChangeCountdownTime);
        }
        setCountDownTime(fnTimeleftCount(time));
        time = time - 1;
      }, 1000);
    }
    // setReqNewOtp(false);
  };

  let ChangeCountdownTime;

  const fnTimeleftCount = (time) => {
    let min = Math.floor(time / 60);
    let sec = Math.floor(time % 60);
    return {
      min: min,
      sec: sec,
    };
  };

  useEffect(() => {
    if (timeleftCount !== 0) {
      let time = timeleftCount;
      setCountDownTime(fnTimeleftCount(time));
      ChangeCountdownTime = setInterval(() => {
        if (time <= 0) {
          time = 0;
          clearInterval(ChangeCountdownTime);
        }
        setCountDownTime(fnTimeleftCount(time));
        time = time - 1;
      }, 1000);
    }
    // setTimeleftCount(180);
  }, [timeleftCount]);

  const onChangeCaptcha = (value) => {
    console.log("Captcha value:", value);
    setCaptcha(value);
  };

  let ChangeCountdown;

  const fnTimeleft = (time) => {
    let min = Math.floor(time / 60);
    let sec = Math.floor(time % 60);
    return {
      min: min,
      sec: sec,
    };
  };

  useEffect(() => {
    if (timeleft !== 0) {
      let time = timeleft;
      setCountDown(fnTimeleft(time));
      ChangeCountdown = setInterval(() => {
        if (time <= 0) {
          time = 0;
          clearInterval(ChangeCountdown);
          // handleTel();
        }
        setCountDown(fnTimeleft(time));
        time = time - 1;
      }, 1000);
    }
  }, [timeleft]);

  useEffect(() => {
    try {
      // dispatch(actionWinPopSound());
      socket.emit("getLineID", {}, (body) => {
        if (typeof body !== "undefined") {
          if (typeof body.results !== "undefined") {
            if (typeof body.results.data !== "undefined") {
              if (body.status) {
                // console.log(body.results.data.line_id);
                if (
                  body.results.data.line_id &&
                  typeof body.results.data.line_id !== undefined
                ) {
                  setLineId(body.results.data.line_id);
                  let arrayLine = body.results.data.line_id.split("/");
                  // console.log(arrayLine,"arrayLine")
                  setArrayUrl(arrayLine[arrayLine.length - 1]);
                  //  console.log(arrayUrl);
                }
              } else if (body.status === false) {
                setEmitError({ status: true, message: body.errorCode });
                setTimeout(() => {
                  setEmitError({ status: false });
                }, 3000);
              }
            }
          }
        }
      });
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  }, []);

  const openInNewTab = (url) => {
    try {
      const newWindow = window.open(url, "_blank");
      if (newWindow) newWindow.opener = null;
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  };

  const sendOTP = (data) => {
    // setLoad(true);
    handleTel(data);
    // reset();
  };

  const getOTPBtn = () => {
    // console.log("reCaptchaState?.status", reCaptchaState?.status);
    if (reCaptchaState?.captcha_type === 1 && reCaptchaState?.status === 1) {
      verify(CAPNAME);
    } else {
      sendOTP();
    }
  };

  return (
    <BG>
      {load === true ? <Loading /> : ""}
      <PopupCustom
        title={t("forgotpasswordpopup")}
        width={
          reCaptchaState?.status === 1 && reCaptchaState?.captcha_type === "0"
            ? window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "84vw"
              : window.appHeight() > window.innerWidth
                ? "61vw"
                : window.innerWidth / window.appHeight() > 2.15
                  ? "37vw"
                  : "38vw"
            : window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "92vw"
              : window.appHeight() > window.innerWidth
                ? "61vw"
                : window.innerWidth / window.appHeight() > 2.15
                  ? "36vw"
                  : "36vw"
        }
        // height={
        //   reCaptchaState?.status == 1 && reCaptchaState?.captcha_type == "0"
        //     ? window.appHeight() / window.innerWidth > config.verticalSizeiphone
        //       ? "82vw"
        //       : window.appHeight() > window.innerWidth
        //       ? "48vw"
        //       : window.innerWidth / window.appHeight() > 2.15
        //       ? "37vw"
        //       : "33vw"
        //     : window.appHeight() / window.innerWidth > config.verticalSizeiphone
        //     ? "60.542vw"
        //     : window.appHeight() > window.innerWidth
        //     ? "36vw"
        //     : window.innerWidth / window.appHeight() > 2.15
        //     ? "26vw"
        //     : "28vw"
        // }
        hight={"auto"}
        display="none"
        style={{ zIndex: "75" }}
      >
        {reCaptchaState?.status === 1 &&
          reCaptchaState?.captcha_type === 0 &&
          typeof reCaptchaState?.site_key !== "undefined" ? (
          <ReCAPTCHACustom
            sitekey={reCaptchaState?.site_key || "ABC"}
            onChange={onChangeCaptcha}
            theme="dark"
          />
        ) : (
          ""
        )}

        {token ? (
          <RGCaptcha
            name={CAPNAME}
            product="bind"
            lang="en"
            onSuccess={(oSuccess) => {
              sendOTP(oSuccess);
              // console.log("oSuccess", oSuccess);
              //send otp here
            }}
            data={token}
          />
        ) : null}

        <ForgetPasswordContainer>
          <Content>{t("phone register")} </Content>
          <PhoneContainer>
            <PhoneBox>
              <Input
                onFocus={(e) => {
                  window.isEdit = true;
                  window.onKeyBoard = true;
                  dispatch(setFloatingLabel(t("your phone")));
                  dispatch(setFloatingStatus(true));
                  dispatch(setFloatingValue(e.target.value));
                }}
                onBlur={() => {
                  window.isEdit = false;
                  window.onKeyBoard = false;
                  dispatch(setFloatingStatus(false));
                }}
                type="text"
                name="phone"
                placeholder={t("your phone")}
                value={tel}
                maxLength={10}
                onChange={(v) => {
                  if (
                    v.target.value === "" ||
                    reqExpPhone.test(v.target.value)
                  ) {
                    setTel(v.target.value);
                    dispatch(setFloatingValue(v.target.value));
                  }
                }}
              />
              {tel.length === 10 && validTelPhone(tel) ? (
                <img
                  src={"../" + config.theme + "/dailylogin_correct.png"}
                  alt="Correct"
                  style={{
                    // position: "absolute",
                    width:
                      window.appHeight() / window.innerWidth >
                        config.verticalSizeiphone
                        ? "7vw"
                        : window.appHeight() > window.innerWidth
                          ? "3vw"
                          : window.innerWidth / window.appHeight() > 2.15
                            ? "2vw"
                            : "3vw",
                    // marginTop:
                    //   window.appHeight() / window.innerWidth >
                    //   config.verticalSizeiphone
                    //     ? "1.6vw"
                    //     : window.appHeight() > window.innerWidth
                    //     ? "0.6vw"
                    //     : window.innerWidth / window.appHeight() > 2.15
                    //     ? "0.6vw"
                    //     : "0.6vw",
                    marginLeft:
                      window.appHeight() / window.innerWidth >
                        config.verticalSizeiphone
                        ? "-7vw"
                        : window.appHeight() > window.innerWidth
                          ? "-4vw"
                          : window.innerWidth / window.appHeight() > 2.15
                            ? "-3vw"
                            : "-3vw",
                  }}
                />
              ) : null}
            </PhoneBox>

            {step === 1 ? (
              reCaptchaState?.status === 1 &&
                reCaptchaState?.captcha_type === 0 &&
                captcha !== null &&
                tel.length === 10 ? (
                <OtpButton
                  onClick={() => {
                    console.log("step1");
                    setLoad(true);
                    getOTPBtn();
                    // onSubmit();
                    reset();
                  }}
                >
                  <p>{t("request otp")}</p>
                </OtpButton>
              ) : reCaptchaState?.status === 1 &&
                reCaptchaState?.captcha_type === 1 &&
                tel.length === 10 ? (
                <OtpButton
                  onClick={() => {
                    console.log("step1");
                    setLoad(true);
                    getOTPBtn();
                    // onSubmit();
                    reset();
                  }}
                >
                  <p>{t("request otp")}</p>
                </OtpButton>
              ) : reCaptchaState?.status !== 1 && tel.length === 10 ? (
                <OtpButton
                  onClick={() => {
                    setLoad(true);
                    getOTPBtn();
                    // onSubmit();
                    reset();
                  }}
                >
                  <p>{t("request otp")}</p>
                </OtpButton>
              ) : (
                <OtpButtonGray>
                  <p>{t("request otp")}</p>
                </OtpButtonGray>
              )
            ) : countDownTime.min === 0 &&
              countDownTime.sec === 0 &&
              countDown.min === 0 &&
              countDown.sec === 0 &&
              tel.length === 10 &&
              reCaptchaState?.status === 1 &&
              reCaptchaState?.captcha_type === 0 &&
              captcha !== null ? (
              <OtpButton
                onClick={() => {
                  getOTPBtn();
                  reset();
                  setLoad(true);
                }}
              >
                <p>{t("request otp")}</p>
              </OtpButton>
            ) : countDownTime.min === 0 &&
              countDownTime.sec === 0 &&
              countDown.min === 0 &&
              countDown.sec === 0 &&
              tel.length === 10 &&
              reCaptchaState?.status === 1 &&
              reCaptchaState?.captcha_type === 1 ? (
              <OtpButton
                onClick={() => {
                  getOTPBtn();
                  reset();
                  setLoad(true);
                }}
              >
                <p>{t("request otp")}</p>
              </OtpButton>
            ) : countDownTime.min === 0 &&
              countDownTime.sec === 0 &&
              countDown.min === 0 &&
              countDown.sec === 0 &&
              reCaptchaState?.status !== 1 &&
              tel.length === 10 ? (
              <OtpButton
                onClick={() => {
                  getOTPBtn();
                  reset();
                  setLoad(true);
                }}
              >
                <p>{t("request otp")}</p>
              </OtpButton>
            ) : (
              <OtpButtonGray>
                <p>{t("request otp")}</p>
              </OtpButtonGray>
            )}
          </PhoneContainer>

          {arrayUrl === "" ? (
            <></>
          ) : (
            <Textsupport lang={i18n.language}>
              {t("text line spinix")}
              <Lineimg
                onClick={() => {
                  try {
                    socket.emit(
                      "getbilog",
                      {
                        biname: "line_contact",
                      },
                      (data) => {
                        console.log(data);
                      }
                    );
                  } catch (error) {
                    console.log(error);
                  }
                  openInNewTab(lineId);
                }}
                src={"../" + config.theme + "/iconsocial_line.png"}
              />

              <Spinixtext
                onClick={() => {
                  try {
                    socket.emit(
                      "getbilog",
                      {
                        biname: "line_contact",
                      },
                      (data) => {
                        console.log(data);
                      }
                    );
                  } catch (error) {
                    console.log(error);
                  }
                  openInNewTab(lineId);
                }}
              >
                {arrayUrl}
              </Spinixtext>
            </Textsupport>
          )}
          {/* <h6 style={{ whiteSpace: "nowrap" }}>
            {t("the system will send you OTP")}
          </h6> */}

          <Content>OTP</Content>
          <OTPBox>
            <InputOTP
              onFocus={(e) => {
                window.isEdit = true;
                window.onKeyBoard = true;
                dispatch(setFloatingLabel(t("input otp")));
                dispatch(setFloatingStatus(true));
                dispatch(setFloatingValue(e.target.value));
              }}
              onBlur={() => {
                window.isEdit = false;
                window.onKeyBoard = false;
                dispatch(setFloatingStatus(false));
              }}
              type="text"
              name="otp"
              placeholder={t("input otp")}
              value={Otp}
              maxLength="6"
              onChange={(v) => {
                if (v.target.value === "" || reqExpPhone.test(v.target.value)) {
                  setOtp(v.target.value);
                  dispatch(setFloatingValue(v.target.value));
                }
              }}
            />
            {Otp.length === 6 ? (
              <img
                src={"../" + config.theme + "/dailylogin_correct.png"}
                alt="Correct"
                style={{
                  // position: "absolute",
                  width:
                    window.appHeight() / window.innerWidth >
                      config.verticalSizeiphone
                      ? "7vw"
                      : window.appHeight() > window.innerWidth
                        ? "3vw"
                        : window.innerWidth / window.appHeight() > 2.15
                          ? "2vw"
                          : "3vw",
                  // marginTop:
                  //   window.appHeight() / window.innerWidth >
                  //   config.verticalSizeiphone
                  //     ? "1.6vw"
                  //     : window.appHeight() > window.innerWidth
                  //     ? "0.6vw"
                  //     : window.innerWidth / window.appHeight() > 2.15
                  //     ? "0.6vw"
                  //     : "0.6vw",
                  marginLeft:
                    window.appHeight() / window.innerWidth >
                      config.verticalSizeiphone
                      ? "-7vw"
                      : window.appHeight() > window.innerWidth
                        ? "-4vw"
                        : window.innerWidth / window.appHeight() > 2.15
                          ? "-3vw"
                          : "-3vw",
                }}
              />
            ) : null}
          </OTPBox>

          {step === 2 ? (
            <ContainerError>
              {countDown.min === 0 && countDown.sec === 0 ? (
                <>
                  <ContainerCountOtp>
                    <Textotp>{t("otp expired")}</Textotp>
                    <Timer>
                      {countDownTime.sec < 10
                        ? "0" + countDownTime.min + ":0" + countDownTime.sec
                        : "0" + countDownTime.min + ":" + countDownTime.sec}
                    </Timer>
                    <Mintext>{t("min")}</Mintext>
                  </ContainerCountOtp>
                </>
              ) : (
                <>
                  <ContainerCountOtp>
                    <Textotp>{t("otp expired")}</Textotp>
                    <Timer>
                      {countDown.sec < 10
                        ? "0" + countDown.min + ":0" + countDown.sec
                        : "0" + countDown.min + ":" + countDown.sec}
                    </Timer>
                    <Mintext>{t("min")}</Mintext>
                  </ContainerCountOtp>
                </>
              )}
            </ContainerError>
          ) : (
            ""
          )}

          {/* <ContainerOTP>
            <Button
              bClass="rbRed"
              label={t("cancel")}
              width={
                window.appHeight() / window.innerWidth >
                config.verticalSizeiphone
                  ? "25vw"
                  : window.appHeight() > window.innerWidth
                  ? "20.5vw"
                  : window.innerWidth / window.appHeight() > 2.15
                  ? "15vw"
                  : "16vw"
              }
              fontSize={
                window.appHeight() / window.innerWidth >
                config.verticalSizeiphone
                  ? "2.4vw"
                  : window.appHeight() > window.innerWidth
                  ? "1.8vw"
                  : window.innerWidth / window.appHeight() > 2.15
                  ? "1vw"
                  : "1.2vw"
              }
              onClick={() => {
                props.closeClick();
              }}
            />
            <Button
              bClass="rbGreen"
              label={t("change pin code")}
              width={
                window.appHeight() / window.innerWidth >
                config.verticalSizeiphone
                  ? "25vw"
                  : window.appHeight() > window.innerWidth
                  ? "20.5vw"
                  : window.innerWidth / window.appHeight() > 2.15
                  ? "15vw"
                  : "16vw"
              }
              fontSize={
                window.appHeight() / window.innerWidth >
                config.verticalSizeiphone
                  ? "2.4vw"
                  : window.appHeight() > window.innerWidth
                  ? "1.8vw"
                  : window.innerWidth / window.appHeight() > 2.15
                  ? "1vw"
                  : "1.2vw"
              }
              onClick={() => {
                handleOTP();
                setLoad(true);
              }}
              cursor={Otp.length < 6 ? "initial" : "pointer"}
            />
          </ContainerOTP> */}

          <Transition
            in={complete}
            timeout={3000}
            classNames="alert"
            unmountOnExit
          >
            <Dialog
              message={t(`${message}`)}
              bClass="rtNext"
              btnLabel="confirm"
              onClick={() => {
                if (message === "password complete") {
                  props.closeClick("password complete");
                } else if (message === "system via register") {
                  // setStep(2);
                  dispatch(actionPopup({ setOTPPage: true }));
                }
              }}
            />
          </Transition>

          {popUpCheckPhone === true ? (
            <BGpopUp style={{ justifyContent: "center" }}>
              <ConfirmPopup
                messageBottom={t("try")}
                onClick={() => {
                  setPopUpCheckPhone(false);
                }}
              >
                <TextPopUp> {t("invalid phone")}</TextPopUp>
              </ConfirmPopup>
            </BGpopUp>
          ) : null}

          {popUpCheckOtp === true ? (
            <BGpopUp style={{ justifyContent: "center" }}>
              <ConfirmPopup
                messageBottom={t("try again")}
                onClick={() => {
                  setPopUpCheckOtp(false);
                }}
              >
                <TextPopUp>{t(emitError.message)}</TextPopUp>
              </ConfirmPopup>
            </BGpopUp>
          ) : null}

          {/* {checkOtpPopup === true ? (
            <BGpopUp style={{ justifyContent: "center" }}>
              <ConfirmPopup
                messageBottom={t("try")}
                onClick={() => {
                  setCheckOtpPopup(false);
                }}
              >
                <TextPopUp> {t("s0031")}</TextPopUp>
              </ConfirmPopup>
            </BGpopUp>
          ) : (
            ""
          )} */}
        </ForgetPasswordContainer>

        <ContainerOTP>
          <Button
            bClass="rbRed"
            label={t("cancel")}
            width={
              window.appHeight() / window.innerWidth > config.verticalSizeiphone
                ? "28vw"
                : window.appHeight() > window.innerWidth
                  ? "20.5vw"
                  : window.innerWidth / window.appHeight() > 2.15
                    ? "15vw"
                    : "16vw"
            }
            fontSize={
              window.appHeight() / window.innerWidth > config.verticalSizeiphone
                ? "3vw"
                : window.appHeight() > window.innerWidth
                  ? "1.8vw"
                  : window.innerWidth / window.appHeight() > 2.15
                    ? "1vw"
                    : "1.2vw"
            }
            onClick={() => {
              props.closeClick("Button Cancel");
            }}
          />
          <Button
            style={{
              cursor: tel.length < 10 || Otp.length < 6 ? "initial" : "pointer",
            }}
            bClass={tel.length < 10 || Otp.length < 6 ? "rbGray" : "rbGreen"}
            disabled={tel.length < 10 || Otp.length < 6 ? true : false}
            label={t("change pin code")}
            width={
              window.appHeight() / window.innerWidth > config.verticalSizeiphone
                ? "28vw"
                : window.appHeight() > window.innerWidth
                  ? "20.5vw"
                  : window.innerWidth / window.appHeight() > 2.15
                    ? "15vw"
                    : "16vw"
            }
            fontSize={
              window.appHeight() / window.innerWidth > config.verticalSizeiphone
                ? "3vw"
                : window.appHeight() > window.innerWidth
                  ? "1.8vw"
                  : window.innerWidth / window.appHeight() > 2.15
                    ? "1vw"
                    : "1.2vw"
            }
            onClick={() => {
              handleOTP();
              setLoad(true);
            }}
          />
        </ContainerOTP>

        {/* <Transition showPop={popupStatus.setOTPPage}>
          <BG style={{ justifyContent: "center" }}>
            <PageOtp
              phone={phone}
              // setToPinCode={() => setToCreateAccount(false)}
              closeClick={() => {
                dispatch(actionPopup({ setOTPPage: false }));
                props.closeClick();
              }}
            />
          </BG>
        </Transition> */}
        <Transition showPop={emitError.status} type="alert">
          <AlertMessage
            message={t(emitError.message)}
            onClick={() => {
              setEmitError({ status: false });
            }}
          />
        </Transition>

        <Transition showPop={popupStatus.setPinCodePage}>
          <BG style={{ justifyContent: "center" }}>
            <SetPinCode
              phone={phone}
              dataOtp={dataOtp}
              closeClick={() => {
                dispatch(actionPopup({ setPinCodePage: false }));
                props.closeClick("SetPinCode");
              }}
            />
          </BG>
        </Transition>
      </PopupCustom>
    </BG>
  );
}

export default Forgotpassword;
