import React, { useState, useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import Popup from "../Popup";
import Button from "../Button";
import { useTranslation } from "react-i18next";
import ConfirmPopup from "../Dialog/ConfirmPopup";
import { Transition } from "../../config";
import { SocketContext } from "../../context/socket";
import { getFullUrl, redirectToLogin } from "../../config";
import { selectuserProfile } from "../../Reducers/userProfileSlice";
import {
  setFloatingValue,
  setFloatingStatus,
  setFloatingLabel,
} from "../../Reducers/floatingInputSlice";
import Loading from "../Loading";
import AlertMessage from "../Dialog/AlertMessage";
import ConfirmationRegister from "./ConfirmationRegister";
import { selectCheckFTUEPopup } from "../../Reducers/checkFTUEPopupSlice";
import { config } from "../../config";
import { faCommentsDollar } from "@fortawesome/free-solid-svg-icons";
import Condition from "./Condition";

const BG = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 70;
  align-items: center;
  justify-content: center;

  h3 {
    font-size: 1.5vw;
    margin: 0.75vw 0vw;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          h3 {
            font-size: 5vw;
          }
        `
      : window.innerWidth / window.appHeight() > 2.15
      ? css`
          h3 {
            font-size: 1.5vw;
            margin: 0.5vw;
          }
        `
      : window.appHeight() > window.innerWidth
      ? css`
          h3 {
            font-size: 2.5vw;
          }
        `
      : css``}
`;

const PrefixList = styled.div`
  width: 12vw;
  margin-left: -4.1vw;
  height: ${(props) =>
    props.active
      ? "max-content"
      : window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? "6.5vw"
      : window.appHeight() > window.innerWidth
      ? "4.3vw"
      : "3.3vw"};
  overflow: ${(props) => (!props.active ? "hidden" : "")};
  background-color: #000000;
  border: none;
  border-radius: 0.5vw;
  outline: transparent;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 2;

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 31.25vw;
          margin-left: -4.1vw;
          height: 15vw;
          border-radius: 3vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 11vw;
          margin-left: -4.1vw;
          /* height: 4.3vw; */
        `}
`;

const PrefixListBlack = styled.div`
  width: 28vw;
  overflow: scroll;
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  height: ${(props) => (props.active ? "21.6vw" : "0vw")};
  overflow: ${(props) => (!props.active ? "hidden" : "scroll")};
  overflow-x: hidden;
  background-color: #000000;
  border: none;
  border-radius: 0.5vw;
  outline: transparent;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 99;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 100%;
          height: ${(props) => (props.active ? "72.6vw" : "0vw")};
          border-radius: 3vw;
          top: -15vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 28vw;
        `}
`;

const PrefixItem = styled.button`
  font-size: 1.2vw;
  line-height: 3.5vw;
  display: flex;
  color: #fff;
  background-color: transparent;
  border: none;
  outline: transparent;
  order: ${(props) => (props.isSelected ? "0" : "1")};
  /* margin-left: 0.4vw; */
  margin: 0 0 0 0.4vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 5vw;
          margin: 5.5vw 0 0 1.5vw;
          /* margin-left: 1.5vw;
          margin-top: 5.5vw; */
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 2.5vw;
        `}
`;
const Input = styled.input`
  width: 32vw;
  height: 2.2vw;
  color: #fff;
  font-size: 1.2vw;
  background-color: #000000;
  border: none;
  border-radius: 0.5vw;
  outline: transparent;
  padding: 0.5vw;
  display: flex;
  ::placeholder {
    color: #333333;
    font-size: 1.2vw;
    align-items: center;
    padding-left: 0.5vw;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 30vw;
          height: 15vw;
          font-size: 3vw;
          border-radius: 3vw;
          ::placeholder {
            color: #333333;
            font-size: 2.2vw;
            align-items: center;
            padding-left: 0.5vw;
          }
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 38vw;
          height: 3.3vw;
          font-size: 2.5vw;
          ::placeholder {
            color: #333333;
            font-size: 1.8vw;
            align-items: center;
            padding-left: 0.5vw;
          }
        `}
`;
const InputName = styled.div`
  color: #fff;
  background-color: #000000;
  border: none;
  border-radius: 0.5vw;
  outline: transparent;
  display: flex;
  margin-left: 8.5vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin-left: 11.5vw;
          border-radius: 1vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          margin-left: 7.5vw;
        `}
`;

const InputBank = styled.input`
  width: 35vw;
  height: 3vw;
  color: #fff;
  font-size: 1.2vw;
  background: transparent;
  border: none;
  border-radius: 0.5vw;
  outline: transparent;
  padding-left: 1vw;
  position: relative;

  ::placeholder {
    color: #333333;
    font-size: 1.2vw;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 100%;
          height: 6.3vw;
          font-size: 5vw;
          ::placeholder {
            font-size: 5vw;
          }
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 38vw;
          height: 3.3vw;
          font-size: 2.5vw;
          ::placeholder {
            font-size: 1.7vw;
          }
        `}
`;

const InputSurName = styled.div`
  width: 32vw;
  height: 3.3vw;
  color: #fff;
  background-color: #000000;
  border: none;
  border-radius: 0.5vw;
  outline: transparent;
  display: flex;
  margin-left: 0.5vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          border-radius: 1vw;
          width: 35vw;
          height: 6.5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 38vw;
          height: 4.3vw;
        `}
`;

const BgClose = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%;
  background-color: transparent;
  z-index: 20;
  left: 0%;
  top: 0%;
  position: fixed;
`;

const ContainerPrefixList = styled.div`
  display: flex;
  padding-left: 6vw;
  flex-direction: row;
`;

const ContentBank = styled.div`
  font-size: 1.5vw;
  /* margin-top: 1.4vw;
  margin-left: 0.3vw; */
  margin: 1.4vw 0 0 0.4vw;
  width: 26.25vw;
  color: #fff;
  display: flex;
  flex-direction: row;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 5vw;
          /* margin-left: 0.3vw; */
          margin: 1.4vw 0 0 0.3vw;
          width: 35vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 2.5vw;
          /* margin-top: -3vw; */
          margin: -3vw 0 0 0.4vw;
        `}
`;

const ContentPrefix = styled.div`
  font-size: 1.5vw;
  margin-left: 0.9vw;
  width: 15vw;
  color: #fff;
  display: flex;
  flex-direction: row;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 5vw;
          width: 22vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 2.5vw;
        `}
`;
const ContentName = styled.div`
  font-size: 1.5vw;
  width: 38vw;
  color: #fff;
  display: flex;
  flex-direction: row;
  margin-left: -0.5vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 28.5vw;
          font-size: 5vw;
          /* margin-left: -25.5vw; */
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 2.5vw;
          margin-left: -3.5vw;
          width: 45.5vw;
        `}
`;
const ContentSurName = styled.div`
  font-size: 1.5vw;
  margin-right: -0.7vw;
  width: 16.85vw;
  color: #fff;
  display: flex;
  flex-direction: row;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 5vw;
          margin-right: -25.7vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 2.5vw;
          width: 39.85vw;
          margin-right: 6.3vw;
        `}
`;

const ContainerTextPhone = styled.div`
  width: 61vw;
  /* margin-top: 2vw;
  margin-left: 1.3vw; */
  margin: 2vw 0 0 1.3vw;
  display: flex;
  justify-content: space-between;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css``
      : window.appHeight() > window.innerWidth &&
        css`
          width: auto;
        `}
`;

const ContainerWarning = styled.div`
  width: 78vw;
  margin-left: 2vw;
  display: flex;
  justify-content: space-between;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 95%;
          position: relative;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: auto;
        `}
`;

const ContentWarningName = styled.div`
  font-size: 0.7vw;
  position: absolute;
  left: 43%;
  /* margin-left: 7.5vw; */
  margin: 0 0 0 7.5vw;
  color: red;
  width: 13.9vw;
  text-align: left;
  color: #ff0000;
  display: flex;
  flex-direction: row;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          display: inline;
          font-size: 2.5vw;
          width: 48%;
          right: 0;
          text-align: right;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 1vw;
          /* margin-left: 5.5vw;
          margin-top: 0.25vw; */
          margin: 0.25vw 0 0 5.5vw;
          width: 14.9vw;
        `}
`;
const Prefix = styled.div`
  font-size: 0.7vw;
  position: absolute;
  left: 11%;
  color: red;
  width: 31.9vw;
  color: #ff0000;
  display: flex;
  flex-direction: row;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2.5vw;
          left: 10%;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 1vw;
        `}
`;

const ContentWarningSurName = styled.div`
  font-size: 0.7vw;
  position: absolute;
  right: 0;
  /* margin-left: 23.1vw; */
  margin: 0 0 0 23.1vw;
  text-align: left;
  color: red;
  width: 6.9vw;
  color: #ff0000;
  display: flex;
  flex-direction: row;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          display: inline;
          font-size: 2.5vw;
          width: 40%;
          right: 0;
          text-align: right;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 1vw;
          /* margin-left: 30.1vw;
          margin-top: 0.25vw; */
          margin: 0.25vw 0 0 30.1vw;
          width: 9.9vw;
        `}
`;

const ContentWarningSelectBank = styled.div`
  font-size: 0.7vw;
  position: absolute;
  color: red;
  width: 27.9vw;
  color: #ff0000;
  display: block;
  /* margin-top: 3.2vw; */
  margin: 3.2vw 0 0 0;
  text-align: right;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          display: inline;
          font-size: 2.5vw;
          /* margin-top: 0vw;
          margin-right: 1.5vw; */
          margin: 0 1.5vw 0 0;
          right: 0;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 1vw;
          /* margin-top: 4.4vw; */
          margin: 4.4vw 0 0 0;
        `}
`;

const BankAccountWarning = styled.div`
  position: relative;
`;

const ContentWarningBankAccount = styled.div`
  font-size: 0.7vw;
  position: absolute;
  right: 0.5vw;
  bottom: -13%;
  /* margin-left: 2vw; */
  margin: 0 0 0 2vw;
  color: red;
  width: 35.9vw;
  color: #ff0000;
  display: flex;
  flex-direction: row;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          display: inline;
          font-size: 2.5vw;
          /* margin-right: 1.5vw; */
          margin: 0 1.5vw 0 2vw;
          top: 0;
          right: 0;
          text-align: right;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 43vw;
          font-size: 1vw;
        `}
`;

const Container = styled.div`
  display: flex;
  position: relative;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          align-items: center;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          align-items: center;
        `}
`;

const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.2vw;

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin-top: 3.2vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          margin-top: 3.2vw;
        `}
`;

const ContainerBank = styled.div`
  margin-left: 2vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin-left: 0;
          position: relative;
        `
      : window.appHeight() > window.innerWidth && css``}
`;

const ContentBack = styled.div`
  color: #fff;
  /* margin-top: 1.4vw;
  margin-left: 0.3vw; */
  margin: 1.4vw 0 0 0.3vw;
  font-size: 1.5vw;
  position: absolute;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 5vw;
          /* margin-top: 2.4vw; */
          margin: 2.4vw 0 0 0.3vw;
          position: inherit;
          text-align: left;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 2.5vw;
        `}
`;

const NameItem = styled.button`
  width: max-content;
  height: 2.6vw;
  align-items: center;
  display: flex;
  font-size: 1vw;
  text-align: initial;
  color: #fff;
  background-color: transparent;
  border: none;
  outline: transparent;
  cursor: pointer;
  z-index: 90;
  order: ${(props) => (props.isSelected ? "0" : "1")};
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 3.5vw;
          margin: 4vw 2vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 1.4vw;
        `}
`;

const Select = styled.div`
  position: relative;
`;

const Dropdown = styled.div`
  display: flex;
  flex-direction: row;
`;

const DropdownContainer = styled.div`
  display: flex;
  margin-top: 0.3vw;
`;

const DropdownImage = styled.div`
  width: 2.5vw;
  height: 2.5vw;
  margin-top: 0.2vw;
`;

const DropdownName = styled.div`
  font-size: 1.2vw;
  margin-left: 0.3vw;
`;

const Selection = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #000000;
  width: 28vw;
  height: 3.3vw;
  color: #ffffff;
  border-radius: 0.5vw;
  border: none;
  position: absolute;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 100%;
          height: 15vw;
          border-radius: 3vw;
          position: unset;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 28vw;
          height: 4.3vw;
        `}
`;

const SelectImage = styled.div`
  display: flex;
  margin-top: 0.3vw;
`;

const SelectImageBank = styled.img`
  width: 2.5vw;
  height: 2.5vw;
  /* margin-left: 0.3vw;
  margin-top: 0.1vw; */
  margin: 0.1vw 0 0 0.3vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          /* margin-top: 2vw; */
          margin: 2vw 0 0 0.3vw;
          width: 9.6vw;
          height: 9.6vw;
        `
      : window.appHeight() > window.innerWidth &&
        `

 `}
`;
const SelectName = styled.div`
  display: flex;
  font-size: 1vw;
  margin-left: 0.3vw;
  align-items: center;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 3.5vw;
          margin: 2vw;
          text-align: left;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 1.4vw;
        `}
`;

const SelectText = styled.div`
  font-size: 1.2vw;
  margin-left: 0.7vw;
  color: #333333;

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 5vw;
          margin-left: 2vw;
        `
      : window.appHeight() > window.innerWidth &&
        `
 font-size: 1.7vw;
 `}
`;
const IconBank = styled.img`
  width: 0.8vw;
  height: 0.5vw;
  position: absolute;
  right: 2%;
  /* margin-top: 1.1vw; */
  margin: 1.1vw 0 0 0;

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 4vw;
          height: auto;
          right: 4%;
          top: 45%;
          /* margin-top: 0; */
          margin: 0;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 1.6vw;
          height: 0.9vw;
          /* margin-top: 1.3vw;
          margin-left: 48.9vw; */
          margin: 1.3vw 0 0 48.9vw;
        `}
`;
const Icon = styled.img`
  width: 0.8vw;
  height: 0.5vw;
  /* margin-top: 1.5vw;
  margin-left: 10.5vw; */
  margin: 1.5vw 0 0 10.5vw;
  position: absolute;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 4vw;
          height: auto;
          right: 7%;
          top: 34%;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 1.6vw;
          height: 0.9vw;
          /* margin-left: 6.5vw; */
          margin: 1.5vw 0 0 6.5vw;
        `}
`;

const Inputtabbank = styled.div`
  width: 20vw;
  color: #fff;
  background-color: #000000;
  border: none;
  border-radius: 0.5vw;
  outline: transparent;
  display: flex;
  margin-top: 3.7vw;
  align-items: center;

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          /* margin-top: 12.4vw; */
          width: 100%;
          height: 15vw;
          border-radius: 3vw;
          margin-top: 0;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 26.5vw;
          height: 4.3vw;
          margin-top: 5vw;
        `}
`;

const CheckBox = styled.div`
  display: inline-block;
  width: 1.5vw;
  height: 1.5vw;
  background-color: #797fa1;
  border: 0.45vw solid #797fa1;
  border-radius: 5vw;
  margin-right: 1vw;
  position: relative;
  cursor: pointer;
  ${(props) =>
    props.lineType == true &&
    css`
      background: linear-gradient(
        180deg,
        #06d746 24.48%,
        #4dff80 52.08%,
        #06d746 100%
      );
      border: 0.45vw solid #797fa1;
    `}
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 3vw;
          height: 3vw;
          margin-right: 2vw;
          border: 1vw solid #797fa1;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 2.5vw;
          height: 2.5vw;
          margin-right: 1vw;
        `}
`;

const ContainerCheckBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0.5vw;
  color: #fff;
  /* margin-top: 2vw; */
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin: 0;
          position: relative;
        `
      : window.appHeight() > window.innerWidth &&
        `

 `}
`;

const LineContainer = styled.div`
  width: 36.5%;
`;

const ContentLine = styled.div`
  font-size: 1.5vw;
  /* margin-top: 1.4vw;
  margin-left: 0.5vw; */
  margin: 1.4vw 0 0 0.5vw;
  width: 12vw;
  color: #fff;
  display: flex;
  flex-direction: row;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 5vw;
          /* margin-left: 0.3vw; */
          margin: 1.4vw 0 0 0.3vw;
          width: 35vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 2.5vw;
        `}
`;

const InputCheckBox = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1vw;
  > span {
    font-size: 1.2vw;
    padding-left: 0.5vw;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin: 2vw 0vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          margin-right: 2vw;
          span {
            font-size: 1.7vw;
          }
        `}
`;

const InputLine = styled.input`
  width: 17vw;
  height: 2.2vw;
  color: #fff;
  font-size: 1.2vw;
  background-color: #000000;
  border: none;
  border-radius: 0.5vw;
  outline: transparent;
  padding: 0.5vw;
  margin-left: 0.5vw;
  ::placeholder {
    color: #333333;
    font-size: 1.2vw;
    align-items: center;
    padding-left: 0.5vw;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 42vw;
          height: 15vw;
          font-size: 5vw;
          border-radius: 3vw;
          padding-left: 2vw;
          ::placeholder {
            color: #333333;
            font-size: 5vw;
          }
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 22.5vw;
          height: 3.3vw;
          font-size: 2.5vw;
          margin-left: 0vw;
          ::placeholder {
            color: #333333;
            font-size: 2.5vw;
            align-items: center;
            padding-left: 0.5vw;
          }
        `}
`;

const WarningMessage = styled.div`
  display: flex;
  margin-left: 2vw;
  /* color: #e13b00;
  font-size: 1vw;
  position: absolute; */
  color: #ff0000;
  font-size: 0.7vw;
  position: absolute;
  right: 3%;

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2.5vw;
          right: 0;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 1vw;
        `}
`;

const VerticalPopup = styled(Popup)``;

const ContentContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 88%;
  padding: 2vw 2vw 2vw 4vw;
  margin-right: 2vw;

  /* Scroll width */
  ::-webkit-scrollbar {
    width: 2vw;
  }
  /* Scroll Track */
  ::-webkit-scrollbar-track {
  }
  /* Scroll Handle */
  ::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #e13b00 38.02%, #900000 100%);
    box-shadow: inset 0px 0px 4px 2px #ff814d;
    border-radius: 10px;
  }
  /* Scroll Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(180deg, #e13b00 38.02%, #900000 100%);
  }
`;

const Row = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`;

const PrefixContainer = styled.div`
  position: relative;
`;

const PrefixVertical = styled.div`
  background-color: #000000;
  width: 28.25vw;
  height: ${(props) => (props.active ? "25vw" : "15vw")};
  border-radius: 3vw;
  position: absolute;

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          z-index: 1;
          height: ${(props) => (props.active ? "38vw" : "15vw")};
        `
      : window.appHeight() > window.innerWidth && css``}
`;

const InputNameVertical = styled.input`
  border: none;
  outline: transparent;
  background-color: #000000;
  color: #ffffff;
  width: 42vw;
  height: 15vw;
  font-size: 5vw;
  border-radius: 3vw;
  padding-left: 2vw;
  ::placeholder {
    color: #333333;
    font-size: 5vw;
    align-items: center;
  }
`;

const InputSurNameVertical = styled.input`
  border: none;
  outline: transparent;
  background-color: #000000;
  color: #ffffff;
  width: 90vw;
  height: 15vw;
  font-size: 5vw;
  border-radius: 3vw;
  padding-left: 2vw;
  ::placeholder {
    color: #333333;
    font-size: 5vw;
    align-items: center;
  }
`;

const DropdownIcon = styled.img`
  width: 4vw;
  position: absolute;
  right: 7%;
  top: 40%;
`;

const LineType = styled.div`
  width: 9.25vw;
  height: 2.25vw;
  font-size: 1.2vw;
  color: #fff;
  background-color: #000000;
  border-radius: 0.5vw;
  position: relative;
  padding: 0.5vw;
  text-align: left;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 28.25vw;
          height: 15vw;
          font-size: 5vw;
          border-radius: 3vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 8.25vw;
          height: 3.5vw;
        `}
`;

const LineTypeDropdown = styled.div`
  width: 9.25vw;
  background-color: #000000;
  height: auto;
  border-radius: 0.5vw;
  position: absolute;
  z-index: 10;
  text-align: left;
  padding: 0.5vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 28.25vw;
          border-radius: 3vw;
        `
      : window.appHeight() > window.innerWidth && css``}
`;

const IconFlip = styled.img`
  width: 0.8vw;
  height: auto;
  position: absolute;
  right: 7%;
  top: ${(props) => (props.active ? "23%" : "40%")};
  transform: ${(props) => (props.active ? "scaleY(-1)" : "scaleY(1)")};

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 4vw;
        `
      : window.appHeight() > window.innerWidth && css``}
`;

const LineTypeItem = styled.p`
  font-size: 1.2vw;
  color: #fff;
  margin: 0.25vw 0 0.5vw 1vw;
  cursor: pointer;

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 5vw;
          margin: 3.5vw 0 3.5vw 2vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 1.5vw;
          margin: 0.75vw 0 0.5vw 1vw;
        `}
`;

const HrLine = styled.hr`
  opacity: 0.1;
  border: 1px solid #ffffff;
  margin: 1.5vw 0;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin: 5vw 1vw;
        `
      : window.innerWidth / window.appHeight() > 2.15
      ? css`
          margin: 0.5vw;
        `
      : window.appHeight() > window.innerWidth && css``}
`;

const PromotionCondition = styled.div`
  color: #ffffff;
  text-align: left;
  width: 94%;
  margin: 0 auto;
  h3 {
    text-align: center;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 98%;
          h3 {
            text-align: left;
          }
        `
      : window.appHeight() > window.innerWidth &&
        css`
          margin: 5vw auto;
        `}
`;

const PromotionDetail = styled.div`
  width: 48%;
  margin-right: 2%;
  display: inline-block;
  vertical-align: top;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 100%;
          display: block;
        `
      : window.appHeight() > window.innerWidth && css``}
`;

const Paragraph = styled.div`
  font-size: 1.2vw;
  color: #ffffff;
  opacity: 0.6;
  white-space: pre-line;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 3.5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 2vw;
        `}
`;

function BankAccount(props) {
  const profile = useSelector(selectuserProfile);
  const [isActive, setIsActive] = useState(false);
  const [isActiveBack, setIsActiveBack] = useState(false);
  const [prefix, setPrefix] = useState("");
  const [toConfirm, setToConfirm] = useState(false);
  const [bank, setBank] = useState([]);
  const [emitError, setEmitError] = useState({ status: false, message: "" });
  const socket = useContext(SocketContext);
  const { t, i18n } = useTranslation();
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [bankId, setBankId] = useState(0);
  const [bankName, setBankName] = useState("");
  const [bankImg, setBankImg] = useState("");
  const [bankAccount, setBankAccount] = useState("");
  const [load, setLoad] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [userDuplicate, setUserDuplicate] = useState(false);
  const [firstNameErr, setFirstNameErr] = useState({});
  const [firstNameErrEN, setFirstNameErrEN] = useState({});
  const [lastNameErr, setLastNameErr] = useState({});
  const [lastNameErrEN, setLastNameErrEN] = useState({});
  const [preFixErr, setPreFixErr] = useState({});
  const [preFixErrEN, setPreFixErrEN] = useState({});
  const [bankNameError, setBankNameErr] = useState({});
  const [bankNameErrorEN, setBankNameErrEN] = useState({});
  const [BankAccountError, setBankAccountErr] = useState({});
  const [BankAccountErrorEN, setBankAccountErrEN] = useState({});
  const [firstNameErrLang, setFirstNameErrorLang] = useState({});
  const [firstNameErrorLangEN, setFirstNameErrorLangEN] = useState({});
  const [lastNameErrLang, setLastNameErrorLang] = useState({});
  const [lastNameErrLangEn, setLastNameErrorLangEn] = useState({});
  const [conFirmRegister, setConFirmRegister] = useState(false);
  const [inFormation, setInFormation] = useState({
    username: "",
    password: "",
    pincode: "",
  });
  const [jwtToken, setJwtToken] = useState("");
  const ref = useRef();
  const refBank = useRef();
  const dispatch = useDispatch();
  const checkFTUEPopup = useSelector(selectCheckFTUEPopup);
  const [lineType, setLineType] = useState({ lineId: true, lineTel: false });
  const [line, setLine] = useState("");
  // const [validateLineTel, setValidateLineTel] = useState(false);
  const lineTypeDropDown = ["ID", "phone number"];
  const [lineTypeSelect, setLineTypeSelect] = useState("ID");
  const [showDropdown, setShowDropdown] = useState(false);
  const [promotionStatus, setPromotionStatus] = useState({
    getPromotion: true,
    noPromotion: false,
  });
  const [conditionStatus, setConditionStatus] = useState(false);
  const [openCondition, setOpenCondition] = useState(false);
  const [showConditionAlert, setShowConditionAlert] = useState(false);

  let reqBank = new RegExp(/^[0-9\b]+$/);
  let reqName = new RegExp(/[a-zA-Zก-๙\s]+$/);
  let reqPhone = new RegExp(/^[0-9\b]+$/);
  let reqLineId = new RegExp(/^[a-z0-9._-]+$/);

  useEffect(() => {
    try {
      socket.emit(
        "getbilog",
        {
          biname: "bankRegister_open",
        },
        (data) => {
          console.log(data);
        }
      );
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    socket.emit("getAllBank", {}, (data) => {
      // console.log(data.results.data);
      if (
        typeof data == "undefined" ||
        typeof data.results == "undefined" ||
        typeof data.results.data == "undefined"
      ) {
      } else {
        if (data.status) {
          setBank(data.results.data);
          setBankId(data.results.data.bankID);
        } else if (data.status === false) {
          setEmitError({ status: true, message: data.errorCode });
          setTimeout(() => {
            setEmitError({ status: false });
          }, 3000);
        }
      }
    });
  }, [i18n.language]);

  const onSubmit = () => {
    setLoad(true);
    // console.log("bankId1", bankId);
    const isValid = formValidation();
    if (isValid == false) {
      // console.log("check Form");
      setLoad(false);
      setShowAlert(true);
      return;
    }

    if (lineType.lineTel == true && line.length > 0) {
      const isValidLineTel = validTelPhone(line);
      // console.log("check LineTel", isValidLineTel);
      if (isValidLineTel == false) {
        // console.log("isValidLineTel == false");
        setLoad(false);
        setShowAlert(true);
        return;
      }
    }

    if (conditionStatus == false) {
      setLoad(false);
      setShowConditionAlert(true);
      setTimeout(() => {
        setShowConditionAlert(false);
      }, 3000);
      return;
    }

    // console.log("bankId2", bankId);

    try {
      socket.emit(
        "registeruser",
        {
          fname: fname.trim(),
          lname: lname.trim(),
          bank_id: bankId,
          bank_account: bankAccount,
          lineID: line,
          player_pro_status: promotionStatus.getPromotion ? 1 : 0,
        },
        (body) => {
          setLoad(false);
          console.log("onSubmit", body);
          if (typeof body != "undefined") {
            if (body.status == true) {
              setInFormation(body.results.user);
              setConFirmRegister(true);
              setJwtToken(getFullUrl(body.results.jwtToken));
              // setTimeout(() => {
              //   setToConfirm(false);
              //   // redirectToLogin(getFullUrl(body.results.jwtToken));
              //   // props.closeClick();
              // }, 1000);
            } else if (body.status === false) {
              // console.log(body.errorCode);

              if (body.errorCode == "s0068") {
                setShowAlert(true);
              }
              // else if (body.errorCode == "s0033") {
              //   setUserDuplicate(true);
              // }
              else if (body.errorCode == "s0088") {
                setShowAlert(true);
              } else if (body.errorCode == "s0066") {
                setShowAlert(true);
              }
              // else if (body.errorCode == "s0025") {
              //   setShowAlert(true);
              // }
              else if (body.errorCode == "s0076") {
                setShowAlert(true);
              } else if (body.errorCode == "s0074") {
                setShowAlert(true);
              } else {
                setEmitError({ status: true, message: body.errorCode });
                setTimeout(() => {
                  setEmitError({ status: false });
                }, 3000);
              }
            }
          }
        }
      );
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  };

  // useEffect(() => {
  //   if (checkFTUEPopup == 1) socket.emit("ftueRegister", {}, (body) => { });
  // }, [checkFTUEPopup]);

  const formValidation = () => {
    const checkThai = /^[ก-๙^0-9\s]+$/;
    const checkEng = /^[a-zA-Z^0-9\s]+$/;
    const firstNameErr = {};
    const lastNameErr = {};
    const lastNameErrEN = {};
    const preFixErr = {};
    const bankNameError = {};
    const bankNameErrorEN = {};
    const BankAccountError = {};
    const firstNameErrLang = {};
    const lastNameErrLang = {};
    const lastNameErrLangEn = {};
    const firstNameErrorLangEN = {};
    const preFixErrEN = {};
    const firstNameErrEN = {};
    const BankAccountErrorEN = {};
    let isValid = true;

    if (fname == "") {
      firstNameErr.firstName = "ข้อมูลไม่ถูกต้อง";
      firstNameErrEN.firstNameErrEN = "Invalid information";
      isValid = false;
    } else if (!checkThai.test(fname) && !checkEng.test(fname)) {
      firstNameErrLang.firstName =
        "โปรดใส่ชื่อ-นามสกุลเป็นภาษาไทยหรืออังกฤษเพียงอย่างเดียวเท่านั้น";
      firstNameErrorLangEN.firstName = "Name must be in English";
      isValid = false;
    }

    if (lname == "") {
      lastNameErr.lastName = "ข้อมูลไม่ถูกต้อง";
      lastNameErrEN.lastNameErrEN = "Invalid information";
      isValid = false;
    } else if (!checkThai.test(lname) && !checkEng.test(lname)) {
      lastNameErrLang.firstName =
        "โปรดใส่ชื่อ-นามสกุลเป็นภาษาไทยหรืออังกฤษเพียงอย่างเดียวเท่านั้น";
      lastNameErrLangEn.lastNameErrLangEn = "Name must be in English";
      isValid = false;
    }

    if (prefix == "") {
      preFixErr.preFix = "ข้อมูลไม่ถูกต้อง";
      preFixErrEN.preFixEN = "Invalid information";

      isValid = false;
    }

    if (bankName == "") {
      bankNameError.bankNameWording = "ข้อมูลไม่ถูกต้อง";
      bankNameErrorEN.bankNameErrorEN = "Invalid information";
      isValid = false;
    }
    if (bankAccount == "") {
      BankAccountError.bankAccountWording = "ข้อมูลไม่ถูกต้อง";
      BankAccountErrorEN.BankAccountErrorEN = "Invalid information";
      isValid = false;
    } else if (bankAccount.length < 10) {
      BankAccountError.bankAccountWording = "ข้อมูลไม่ถูกต้อง";
      BankAccountErrorEN.BankAccountErrorEN = "Invalid information";
      isValid = false;
    }

    setFirstNameErr(firstNameErr);
    setFirstNameErrEN(firstNameErrEN);
    setLastNameErr(lastNameErr);
    setLastNameErrEN(lastNameErrEN);
    setPreFixErr(preFixErr);
    setPreFixErrEN(preFixErrEN);
    setBankNameErr(bankNameError);
    setBankAccountErr(BankAccountError);
    setFirstNameErrorLang(firstNameErrLang);
    setLastNameErrorLang(lastNameErrLang);
    setFirstNameErrorLangEN(firstNameErrorLangEN);
    setBankNameErrEN(bankNameErrorEN);
    setBankAccountErrEN(BankAccountErrorEN);
    setLastNameErrorLangEn(lastNameErrLangEn);

    return isValid;
  };

  const validTelPhone = (tel = "", debug = false) => {
    if (typeof tel != "string") {
      if (debug) {
        // console.log("Type of MissMatch String");
      }
      return false;
    }
    if (tel.length < 0) {
      if (debug) {
        // console.log("String is Zero lenght!");
      }
      return false;
    }

    if (tel.length < 10) {
      return false;
    }

    let cleaned_tel = ("" + tel).replace(/\D/g, "");
    if (debug) {
      // console.log(cleaned_tel);
    }
    let tmp_obj = {
      v1: "",
      v2: "",
      vCheck1: ["0"],
      vCheck2: ["6", "8", "9"],
      result: [false, false],
      sumResult: true,
    };
    tmp_obj.v1 = cleaned_tel.substring(0, 1);
    tmp_obj.v2 = cleaned_tel.substring(1, 2);
    tmp_obj.result[0] = tmp_obj.vCheck1.includes(tmp_obj.v1);
    tmp_obj.result[1] = tmp_obj.vCheck2.includes(tmp_obj.v2);
    for (const value of tmp_obj.result) {
      if (debug) {
        // console.log(value);
      }
      if (value == false) {
        tmp_obj.sumResult = value;
      }
    }
    if (debug) {
      // console.log("result =>", tmp_obj);
    }
    return tmp_obj.sumResult;
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isActive && ref.current && !ref.current.contains(e.target)) {
        setIsActive(false);
      }
      if (
        isActiveBack &&
        refBank.current &&
        !refBank.current.contains(e.target)
      ) {
        setIsActiveBack(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isActive, isActiveBack]);

  const conditionCheckComponent = () => {
    return (
      <PromotionCondition>
        <HrLine />
        <h3> {t("promotion status")}</h3>
        <PromotionDetail>
          <InputCheckBox>
            <CheckBox
              onClick={() => {
                setPromotionStatus({
                  getPromotion: true,
                  noPromotion: false,
                });
              }}
              lineType={promotionStatus.getPromotion}
            />
            <h3> {t("recieve promotion")}</h3>
          </InputCheckBox>

          <Paragraph>{t("recieve promotion detail")}</Paragraph>
        </PromotionDetail>
        <PromotionDetail>
          <InputCheckBox>
            <CheckBox
              onClick={() => {
                setPromotionStatus({
                  getPromotion: false,
                  noPromotion: true,
                });
              }}
              lineType={promotionStatus.noPromotion}
            />
            <h3>{t("no recieve promotion")}</h3>
          </InputCheckBox>

          <Paragraph>{t("no recieve promotion detail")}</Paragraph>
        </PromotionDetail>

        <HrLine />
        <InputCheckBox>
          <CheckBox
            onClick={() => {
              setConditionStatus(!conditionStatus);
            }}
            lineType={conditionStatus}
          />
          <h3>
            {t("i agree")}
            <span
              onClick={() => {
                setOpenCondition(true);
              }}
              style={{
                color: "#FF691F",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {t("condition")}
            </span>
          </h3>
        </InputCheckBox>
      </PromotionCondition>
    );
  };

  const lineTypeComponent = () => {
    return (
      <>
        <ContainerCheckBox>
          <LineType
            onClick={() => {
              setShowDropdown(true);
            }}
          >
            <LineTypeItem>{t(lineTypeSelect)}</LineTypeItem>
            <IconFlip src={"../" + config.theme + "/icons.png"} />
          </LineType>
          {showDropdown ? (
            <LineTypeDropdown>
              {lineTypeDropDown.map((item, index) => (
                <LineTypeItem
                  key={index}
                  onClick={() => {
                    setLine("");
                    setLineTypeSelect(item);
                    setShowDropdown(false);
                  }}
                >
                  {t(item)}
                </LineTypeItem>
              ))}
              <IconFlip
                active={showDropdown}
                src={"../" + config.theme + "/icons.png"}
              />
            </LineTypeDropdown>
          ) : (
            ""
          )}
          <div>
            <InputLine
              type="text"
              name="line"
              value={line}
              maxLength={lineTypeSelect == "phone number" ? 10 : null}
              placeholder={
                lineTypeSelect == "ID" ? t("enter id") : t("enter phone")
              }
              onFocus={(e) => {
                window.isEdit = true;
                window.onKeyBoard = true;
                if (lineTypeSelect == "ID") {
                  dispatch(setFloatingLabel(t("enter id")));
                } else {
                  dispatch(setFloatingLabel(t("enter phone")));
                }
                dispatch(setFloatingStatus(true));
                dispatch(setFloatingValue(e.target.value));
              }}
              onBlur={() => {
                window.isEdit = false;
                window.onKeyBoard = false;
                dispatch(setFloatingStatus(false));
              }}
              onChange={(v) => {
                if (lineTypeSelect == "phone number") {
                  if (v.target.value === "" || reqPhone.test(v.target.value)) {
                    setLine(v.target.value);
                    dispatch(setFloatingValue(v.target.value));
                  }
                } else {
                  if ((v.target.value == "") | reqLineId.test(v.target.value)) {
                    setLine(v.target.value);
                    dispatch(setFloatingValue(v.target.value));
                  }
                }
              }}
            />
          </div>
        </ContainerCheckBox>
        <ContainerWarning>
          {lineTypeSelect == "phone number" && line.length > 0 ? (
            !validTelPhone(line) ? (
              <WarningMessage>
                {i18n.language == "en"
                  ? "Invalid information"
                  : "ข้อมูลไม่ถูกต้อง"}
              </WarningMessage>
            ) : null
          ) : null}
        </ContainerWarning>
      </>
    );
  };

  return (
    <BG>
      {load == true ? <Loading /> : ""}
      <BgClose
        onClick={() => {
          // props.closeClick();
        }}
      />

      {window.appHeight() / window.innerWidth > config.verticalSizeiphone ? (
        <VerticalPopup
          title={t("link bank account")}
          width="87vw"
          height={`${0.9 * window.appHeight()}px`}
          display="none"
          style={{ zIndex: 70 }}
        >
          <ContentContainer>
            <ContainerTextPhone>
              <ContentPrefix>{t("name title")}</ContentPrefix>
              <ContentName>{t("name createbanlk account")}</ContentName>
            </ContainerTextPhone>

            {/* <Container> */}
            <Row ref={ref}>
              {isActive == true ? (
                <PrefixContainer>
                  <PrefixVertical
                    active={isActive}
                    onClick={() => setIsActive(!isActive)}
                  >
                    <DropdownIcon src={"../" + config.theme + "/icons.png"} />
                    <PrefixItem isSelected={true}> </PrefixItem>
                    <PrefixItem
                      isSelected={false}
                      onClick={() => setPrefix(t("mr"))}
                    >
                      {t("mr")}
                    </PrefixItem>
                    <PrefixItem
                      isSelected={false}
                      onClick={() => setPrefix(t("mrs"))}
                    >
                      {t("mrs")}
                    </PrefixItem>
                    <PrefixItem
                      isSelected={false}
                      onClick={() => setPrefix(t("miss"))}
                    >
                      {t("miss")}
                    </PrefixItem>
                  </PrefixVertical>
                </PrefixContainer>
              ) : (
                <PrefixContainer>
                  <PrefixVertical
                    active={isActive}
                    onClick={() => setIsActive(!isActive)}
                  >
                    <DropdownIcon src={"../" + config.theme + "/icons.png"} />
                    <PrefixItem isSelected={true}>{prefix}</PrefixItem>
                  </PrefixVertical>
                </PrefixContainer>
              )}

              {/* <InputName> */}
              <InputNameVertical
                type="text"
                name="name"
                value={fname}
                maxLength={20}
                placeholder={t("name createbanlk account")}
                onFocus={(e) => {
                  window.isEdit = true;
                  window.onKeyBoard = true;
                  dispatch(setFloatingLabel(t("name createbanlk account")));
                  dispatch(setFloatingStatus(true));
                  dispatch(setFloatingValue(e.target.value));
                }}
                onBlur={() => {
                  window.isEdit = false;
                  window.onKeyBoard = false;
                  dispatch(setFloatingStatus(false));
                }}
                onChange={(v) => {
                  if (v.target.value === "" || reqName.test(v.target.value)) {
                    setFname(v.target.value);
                    dispatch(setFloatingValue(v.target.value));
                  }
                }}
              />
              {/* </InputName> */}
            </Row>
            {/* </Container> */}

            {/* <Container>
              <ContainerPrefixList>
                
              </ContainerPrefixList>
            </Container> */}

            <ContainerWarning>
              {i18n.language == "th" ? (
                <>
                  {Object.keys(preFixErr).map((key) => {
                    return <Prefix>{preFixErr[key]}</Prefix>;
                  })}
                </>
              ) : (
                <>
                  {Object.keys(preFixErrEN).map((key) => {
                    return <Prefix>{preFixErrEN[key]}</Prefix>;
                  })}
                </>
              )}
              {i18n.language == "th" ? (
                <>
                  {Object.keys(firstNameErr).map((key) => {
                    return (
                      <ContentWarningName>
                        {firstNameErr[key]}
                      </ContentWarningName>
                    );
                  })}
                </>
              ) : (
                <>
                  <>
                    {Object.keys(firstNameErrEN).map((key) => {
                      return (
                        <ContentWarningName>
                          {firstNameErrEN[key]}
                        </ContentWarningName>
                      );
                    })}
                  </>
                </>
              )}

              {i18n.language == "th" ? (
                <>
                  {Object.keys(firstNameErrLang).map((key) => {
                    return (
                      <ContentWarningName>
                        {firstNameErrLang[key]}
                      </ContentWarningName>
                    );
                  })}
                </>
              ) : (
                <>
                  {Object.keys(firstNameErrorLangEN).map((key) => {
                    return (
                      <ContentWarningName>
                        {firstNameErrorLangEN[key]}
                      </ContentWarningName>
                    );
                  })}
                </>
              )}
            </ContainerWarning>

            <ContainerTextPhone>
              <ContentSurName>{t("last name")}</ContentSurName>
            </ContainerTextPhone>

            <Row>
              <InputSurNameVertical
                type="text"
                name="lname"
                value={lname}
                maxLength={20}
                placeholder={t("last name")}
                onFocus={(e) => {
                  window.isEdit = true;
                  window.onKeyBoard = true;
                  dispatch(setFloatingLabel(t("last name")));
                  dispatch(setFloatingStatus(true));
                  dispatch(setFloatingValue(e.target.value));
                }}
                onBlur={() => {
                  window.isEdit = false;
                  window.onKeyBoard = false;
                  dispatch(setFloatingStatus(false));
                }}
                onChange={(v) => {
                  if (v.target.value === "" || reqName.test(v.target.value)) {
                    setLname(v.target.value);
                    dispatch(setFloatingValue(v.target.value));
                  }
                }}
              />
            </Row>

            <ContainerWarning>
              {i18n.language == "th" ? (
                <>
                  {Object.keys(lastNameErr).map((key) => {
                    return (
                      <ContentWarningSurName>
                        {lastNameErr[key]}
                      </ContentWarningSurName>
                    );
                  })}
                </>
              ) : (
                <>
                  {Object.keys(lastNameErrEN).map((key) => {
                    return (
                      <ContentWarningSurName>
                        {lastNameErrEN[key]}
                      </ContentWarningSurName>
                    );
                  })}
                </>
              )}
              {i18n.language == "th" ? (
                <>
                  {Object.keys(lastNameErrLang).map((key) => {
                    return (
                      <ContentWarningSurName>
                        {lastNameErrLang[key]}
                      </ContentWarningSurName>
                    );
                  })}
                </>
              ) : (
                <>
                  {Object.keys(lastNameErrLangEn).map((key) => {
                    return (
                      <ContentWarningSurName>
                        {lastNameErrLangEn[key]}
                      </ContentWarningSurName>
                    );
                  })}
                </>
              )}
            </ContainerWarning>

            <ContainerBank>
              <ContentBank>{t("bank")}</ContentBank>
              <div ref={refBank}>
                {/* {isActiveBack == false ? ( */}
                <Select onClick={() => setIsActiveBack(true)}>
                  <Selection>
                    <SelectImage>
                      {bankImg != "" ? (
                        <SelectImageBank
                          src={
                            bankImg === ""
                              ? ""
                              : "../" +
                                config.theme +
                                "/bicon_" +
                                bankImg +
                                ".png"
                          }
                        />
                      ) : null}
                      <IconBank src={"../" + config.theme + "/icons.png"} />{" "}
                    </SelectImage>
                    <SelectName>
                      {bankName == "" ? (
                        <SelectText>{t("select bank account")}</SelectText>
                      ) : (
                        bankName
                      )}
                    </SelectName>
                  </Selection>
                </Select>
                {/* ) : (
                   <></>
                 )} */}

                <Select onClick={() => setIsActiveBack(!isActiveBack)}>
                  <PrefixListBlack active={isActiveBack}>
                    {/* <IconBank src={"../" + config.theme + "/icons.png"} /> */}
                    <PrefixItem isSelected={true}>
                      {isActiveBack ? (
                        <></>
                      ) : (
                        <>
                          <Dropdown>
                            <DropdownContainer>
                              <DropdownImage
                                src={
                                  bankImg === ""
                                    ? ""
                                    : "../" +
                                      config.theme +
                                      "/bicon_" +
                                      bankImg +
                                      ".png"
                                }
                              />{" "}
                            </DropdownContainer>
                            <DropdownName>{bankName}</DropdownName>
                          </Dropdown>
                        </>
                      )}
                    </PrefixItem>

                    {bank.map((item, index) => (
                      <Container key={index}>
                        <img
                          style={{
                            width: "7.5vw",
                            height: "7vw",
                            margin: "0.3vw 0 0 0.3vw",
                          }}
                          src={
                            item.bankShortName === ""
                              ? ""
                              : "../" +
                                config.theme +
                                "/bicon_" +
                                item.bankShortName +
                                ".png"
                          }
                        />

                        <NameItem
                          isSelected={false}
                          onClick={() => {
                            setBankId(item.bankID);
                            setBankName(
                              i18n.language == "th"
                                ? item.bankName
                                : item.bankName_en
                            );
                            setBankImg(item.bankShortName);
                          }}
                          key={index}
                        >
                          {i18n.language == "th" ? (
                            <> {item.bankName}</>
                          ) : (
                            <>{item.bankName_en}</>
                          )}
                        </NameItem>
                      </Container>
                    ))}
                  </PrefixListBlack>
                </Select>

                {i18n.language == "th" ? (
                  <>
                    {Object.keys(bankNameError).map((key) => {
                      return (
                        <ContentWarningSelectBank>
                          {bankNameError[key]}
                        </ContentWarningSelectBank>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {Object.keys(bankNameErrorEN).map((key) => {
                      return (
                        <ContentWarningSelectBank>
                          {bankNameErrorEN[key]}
                        </ContentWarningSelectBank>
                      );
                    })}
                  </>
                )}
              </div>
            </ContainerBank>

            <ContainerBank>
              <ContentBack>{t("account")}</ContentBack>

              <Inputtabbank>
                <InputBank
                  type="text"
                  name="account_number"
                  value={bankAccount}
                  maxLength={15}
                  placeholder={t("enter account number")}
                  onFocus={(e) => {
                    window.isEdit = true;
                    window.onKeyBoard = true;
                    dispatch(setFloatingLabel(t("enter account number")));
                    dispatch(setFloatingStatus(true));
                    dispatch(setFloatingValue(e.target.value));
                  }}
                  onBlur={() => {
                    window.isEdit = false;
                    window.onKeyBoard = false;
                    dispatch(setFloatingStatus(false));
                  }}
                  onChange={(v) => {
                    if (v.target.value === "" || reqBank.test(v.target.value)) {
                      setBankAccount(v.target.value);
                      dispatch(setFloatingValue(v.target.value));
                    }
                  }}
                />
              </Inputtabbank>
            </ContainerBank>

            <BankAccountWarning>
              {i18n.language == "th" ? (
                <>
                  {Object.keys(BankAccountError).map((key) => {
                    return (
                      <ContentWarningBankAccount>
                        {BankAccountError[key]}
                      </ContentWarningBankAccount>
                    );
                  })}
                </>
              ) : (
                <>
                  {Object.keys(BankAccountErrorEN).map((key) => {
                    return (
                      <ContentWarningBankAccount>
                        {BankAccountErrorEN[key]}
                      </ContentWarningBankAccount>
                    );
                  })}
                </>
              )}
            </BankAccountWarning>

            {/* LINE */}

            <ContentLine>{t("line account")}</ContentLine>
            {lineTypeComponent()}
            {conditionCheckComponent()}
          </ContentContainer>

          <ContainerButton>
            <Button
              style={{ margin: "0 0vw" }}
              className="button"
              bClass="rbRed"
              width="21vw"
              height="5.54vw"
              fontSize="2.5vw"
              onClick={() => {
                try {
                  socket.emit(
                    "getbilog",
                    {
                      biname: "bankRegister_cancel",
                    },
                    (data) => {
                      // console.log(data);
                    }
                  );
                } catch (error) {
                  console.log(error);
                }
                props.closeClick();
              }}
              label={t("cancel")}
            />
            <>
              {prefix == "" ||
              fname == "" ||
              lname == "" ||
              bankAccount == "" ||
              bankName == " " ? (
                <Button
                  id={profile.brands_id + "btn-next-step-info"}
                  className="button"
                  bClass={"rbGreen"}
                  width="21vw"
                  height="5.54vw"
                  fontSize="2.5vw"
                  label={t("confirmBank")}
                  onClick={() => {
                    if (prefix == "") {
                      formValidation();
                      setShowAlert(true);
                    } else {
                      onSubmit();
                    }
                  }}
                />
              ) : (
                <Button
                  className="button"
                  bClass={"rbGreen"}
                  id={profile.brands_id + "btn-next-step-info"}
                  width="21vw"
                  height="5.54vw"
                  fontSize="2.5vw"
                  label={t("confirmBank")}
                  onClick={() => {
                    onSubmit();
                  }}
                />
              )}
            </>
          </ContainerButton>

          {showAlert === true ? (
            <BG style={{ justifyContent: "center" }}>
              <ConfirmPopup
                messageBottom={t("okay")}
                onClick={() => setShowAlert(false)}
              >
                {t("information is invalid")}
              </ConfirmPopup>
            </BG>
          ) : null}

          {userDuplicate === true ? (
            <BG style={{ justifyContent: "center" }}>
              <ConfirmPopup
                messageBottom={t("okay")}
                onClick={() => setUserDuplicate(false)}
              >
                {t("there are users duplicate")}
              </ConfirmPopup>
            </BG>
          ) : null}
        </VerticalPopup>
      ) : (
        <Popup
          title={t("link bank account")}
          width={
            window.appHeight() > window.innerWidth
              ? "94vw"
              : window.innerWidth / window.appHeight() > 2.15
              ? "82vw"
              : "82vw"
          }
          height={
            window.appHeight() > window.innerWidth
              ? "68vw"
              : window.innerWidth / window.appHeight() > 2.15
              ? "42vw"
              : "45vw"
          }
          display="none"
          style={{ zIndex: 70 }}
        >
          <ContainerTextPhone>
            <ContentPrefix>{t("name title")}</ContentPrefix>
            <ContentName>{t("name createbanlk account")}</ContentName>
            <ContentSurName>{t("last name")}</ContentSurName>
          </ContainerTextPhone>

          <Container>
            <ContainerPrefixList ref={ref}>
              {isActive == true ? (
                <>
                  <PrefixList
                    active={isActive}
                    onClick={() => setIsActive(!isActive)}
                  >
                    <Icon src={"../" + config.theme + "/icons.png"} />
                    <PrefixItem isSelected={true}> </PrefixItem>
                    <PrefixItem
                      isSelected={false}
                      onClick={() => setPrefix(t("mr"))}
                    >
                      {t("mr")}
                    </PrefixItem>
                    <PrefixItem
                      isSelected={false}
                      onClick={() => setPrefix(t("mrs"))}
                    >
                      {t("mrs")}
                    </PrefixItem>
                    <PrefixItem
                      isSelected={false}
                      onClick={() => setPrefix(t("miss"))}
                    >
                      {t("miss")}
                    </PrefixItem>
                  </PrefixList>
                </>
              ) : (
                <PrefixList
                  active={isActive}
                  onClick={() => setIsActive(!isActive)}
                >
                  <Icon src={"../" + config.theme + "/icons.png"} />
                  <PrefixItem isSelected={true}>{prefix}</PrefixItem>
                </PrefixList>
              )}
            </ContainerPrefixList>{" "}
          </Container>

          <Container>
            <ContainerPrefixList>
              <InputName>
                <Input
                  type="text"
                  name="name"
                  value={fname}
                  maxLength={20}
                  placeholder={t("name createbanlk account")}
                  onFocus={(e) => {
                    window.isEdit = true;
                    window.onKeyBoard = true;
                    dispatch(setFloatingLabel(t("name createbanlk account")));
                    dispatch(setFloatingStatus(true));
                    dispatch(setFloatingValue(e.target.value));
                  }}
                  onBlur={() => {
                    window.isEdit = false;
                    window.onKeyBoard = false;
                    dispatch(setFloatingStatus(false));
                  }}
                  onChange={(v) => {
                    if (v.target.value === "" || reqName.test(v.target.value)) {
                      setFname(v.target.value);
                      dispatch(setFloatingValue(v.target.value));
                    }
                  }}
                />
              </InputName>

              <InputSurName>
                <Input
                  type="text"
                  name="lname"
                  value={lname}
                  maxLength={20}
                  placeholder={t("last name")}
                  onFocus={(e) => {
                    window.isEdit = true;
                    window.onKeyBoard = true;
                    dispatch(setFloatingLabel(t("last name")));
                    dispatch(setFloatingStatus(true));
                    dispatch(setFloatingValue(e.target.value));
                  }}
                  onBlur={() => {
                    window.isEdit = false;
                    window.onKeyBoard = false;
                    dispatch(setFloatingStatus(false));
                  }}
                  onChange={(v) => {
                    if (v.target.value === "" || reqName.test(v.target.value)) {
                      setLname(v.target.value);
                      dispatch(setFloatingValue(v.target.value));
                    }
                  }}
                />
              </InputSurName>
            </ContainerPrefixList>
          </Container>

          <ContainerWarning>
            {i18n.language == "th" ? (
              <>
                {Object.keys(preFixErr).map((key) => {
                  return <Prefix>{preFixErr[key]}</Prefix>;
                })}
              </>
            ) : (
              <>
                {Object.keys(preFixErrEN).map((key) => {
                  return <Prefix>{preFixErrEN[key]}</Prefix>;
                })}
              </>
            )}
            {i18n.language == "th" ? (
              <>
                {Object.keys(firstNameErr).map((key) => {
                  return (
                    <ContentWarningName>{firstNameErr[key]}</ContentWarningName>
                  );
                })}
              </>
            ) : (
              <>
                <>
                  {Object.keys(firstNameErrEN).map((key) => {
                    return (
                      <ContentWarningName>
                        {firstNameErrEN[key]}
                      </ContentWarningName>
                    );
                  })}
                </>
              </>
            )}

            {i18n.language == "th" ? (
              <>
                {Object.keys(firstNameErrLang).map((key) => {
                  return (
                    <ContentWarningName>
                      {firstNameErrLang[key]}
                    </ContentWarningName>
                  );
                })}
              </>
            ) : (
              <>
                {Object.keys(firstNameErrorLangEN).map((key) => {
                  return (
                    <ContentWarningName>
                      {firstNameErrorLangEN[key]}
                    </ContentWarningName>
                  );
                })}
              </>
            )}

            {i18n.language == "th" ? (
              <>
                {Object.keys(lastNameErr).map((key) => {
                  return (
                    <ContentWarningSurName>
                      {lastNameErr[key]}
                    </ContentWarningSurName>
                  );
                })}
              </>
            ) : (
              <>
                {Object.keys(lastNameErrEN).map((key) => {
                  return (
                    <ContentWarningSurName>
                      {lastNameErrEN[key]}
                    </ContentWarningSurName>
                  );
                })}
              </>
            )}
            {i18n.language == "th" ? (
              <>
                {Object.keys(lastNameErrLang).map((key) => {
                  return (
                    <ContentWarningSurName>
                      {lastNameErrLang[key]}
                    </ContentWarningSurName>
                  );
                })}
              </>
            ) : (
              <>
                {Object.keys(lastNameErrLangEn).map((key) => {
                  return (
                    <ContentWarningSurName>
                      {lastNameErrLangEn[key]}
                    </ContentWarningSurName>
                  );
                })}
              </>
            )}
          </ContainerWarning>

          <Container>
            <ContainerBank>
              <ContentBank>{t("bank")}</ContentBank>
              <div ref={refBank}>
                {isActiveBack == false ? (
                  <Select onClick={() => setIsActiveBack(true)}>
                    <Selection>
                      <SelectImage>
                        {bankImg != "" ? (
                          <SelectImageBank
                            src={
                              bankImg === ""
                                ? ""
                                : "../" +
                                  config.theme +
                                  "/bicon_" +
                                  bankImg +
                                  ".png"
                            }
                          />
                        ) : null}
                        <IconBank src={"../" + config.theme + "/icons.png"} />{" "}
                      </SelectImage>
                      <SelectName>
                        {bankName == "" ? (
                          <SelectText>{t("select bank account")}</SelectText>
                        ) : (
                          bankName
                        )}
                      </SelectName>
                    </Selection>
                  </Select>
                ) : (
                  <></>
                )}

                <PrefixListBlack
                  active={isActiveBack}
                  onClick={() => setIsActiveBack(!isActiveBack)}
                >
                  <IconBank src={"../" + config.theme + "/icons.png"} />
                  <PrefixItem isSelected={true}>
                    {isActiveBack ? (
                      <></>
                    ) : (
                      <>
                        <Dropdown>
                          <DropdownContainer>
                            <DropdownImage
                              src={
                                bankImg === ""
                                  ? ""
                                  : "../" +
                                    config.theme +
                                    "/bicon_" +
                                    bankImg +
                                    ".png"
                              }
                            />{" "}
                          </DropdownContainer>
                          <DropdownName>{bankName}</DropdownName>
                        </Dropdown>
                      </>
                    )}
                  </PrefixItem>

                  {bank.map((item, index) => (
                    <Container key={index}>
                      <img
                        style={{
                          width:
                            window.appHeight() > window.innerWidth
                              ? "5vw"
                              : window.innerWidth / window.appHeight() > 2.15
                              ? "2.5vw"
                              : "2.5vw",
                          height:
                            window.appHeight() > window.innerWidth
                              ? "4.5vw"
                              : window.innerWidth / window.appHeight() > 2.15
                              ? "2.5vw"
                              : "2.5vw",
                          margin: "0.3vw 0 0 0.3vw",
                        }}
                        src={
                          item.bankShortName === ""
                            ? ""
                            : "../" +
                              config.theme +
                              "/bicon_" +
                              item.bankShortName +
                              ".png"
                        }
                      />

                      <NameItem
                        isSelected={false}
                        onClick={() => {
                          setBankId(item.bankID);
                          setBankName(
                            i18n.language == "th"
                              ? item.bankName
                              : item.bankName_en
                          );
                          setBankImg(item.bankShortName);
                        }}
                        key={index}
                      >
                        {i18n.language == "th" ? (
                          <> {item.bankName}</>
                        ) : (
                          <>{item.bankName_en}</>
                        )}
                      </NameItem>
                    </Container>
                  ))}
                </PrefixListBlack>
                {i18n.language == "th" ? (
                  <>
                    {Object.keys(bankNameError).map((key) => {
                      return (
                        <ContentWarningSelectBank>
                          {bankNameError[key]}
                        </ContentWarningSelectBank>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {Object.keys(bankNameErrorEN).map((key) => {
                      return (
                        <ContentWarningSelectBank>
                          {bankNameErrorEN[key]}
                        </ContentWarningSelectBank>
                      );
                    })}
                  </>
                )}
              </div>
            </ContainerBank>

            <ContainerBank>
              <ContentBack>{t("account")}</ContentBack>

              <Inputtabbank>
                <InputBank
                  type="text"
                  name="account_number"
                  value={bankAccount}
                  maxLength={15}
                  placeholder={t("enter account number")}
                  onFocus={(e) => {
                    window.isEdit = true;
                    window.onKeyBoard = true;
                    dispatch(setFloatingLabel(t("enter account number")));
                    dispatch(setFloatingStatus(true));
                    dispatch(setFloatingValue(e.target.value));
                  }}
                  onBlur={() => {
                    window.isEdit = false;
                    window.onKeyBoard = false;
                    dispatch(setFloatingStatus(false));
                  }}
                  onChange={(v) => {
                    if (v.target.value === "" || reqBank.test(v.target.value)) {
                      setBankAccount(v.target.value);
                      dispatch(setFloatingValue(v.target.value));
                    }
                  }}
                />
              </Inputtabbank>
            </ContainerBank>
            {i18n.language == "th" ? (
              <>
                {Object.keys(BankAccountError).map((key) => {
                  return (
                    <ContentWarningBankAccount>
                      {BankAccountError[key]}
                    </ContentWarningBankAccount>
                  );
                })}
              </>
            ) : (
              <>
                {Object.keys(BankAccountErrorEN).map((key) => {
                  return (
                    <ContentWarningBankAccount>
                      {BankAccountErrorEN[key]}
                    </ContentWarningBankAccount>
                  );
                })}
              </>
            )}

            {/* LINE */}

            <LineContainer>
              <ContentLine>{t("line account")}</ContentLine>
              {lineTypeComponent()}
            </LineContainer>
          </Container>

          {conditionCheckComponent()}

          <ContainerButton>
            <Button
              style={{ margin: "0 0vw" }}
              className="button"
              bClass="rbRed"
              width={
                window.appHeight() > window.innerWidth
                  ? "16vw"
                  : window.innerWidth / window.appHeight() > 2.15
                  ? "12vw"
                  : "12vw"
              }
              height="5.54vw"
              fontSize={
                window.appHeight() > window.innerWidth
                  ? "1.7vw"
                  : window.innerWidth / window.appHeight() > 2.15
                  ? "1.15vw"
                  : "1.15vw"
              }
              onClick={() => {
                try {
                  socket.emit(
                    "getbilog",
                    {
                      biname: "bankRegister_cancel",
                    },
                    (data) => {
                      // console.log(data);
                    }
                  );
                } catch (error) {
                  console.log(error);
                }
                props.closeClick();
              }}
              label={t("cancel")}
            />
            <>
              {prefix == "" ||
              fname == "" ||
              lname == "" ||
              bankAccount == "" ||
              bankName == " " ? (
                <Button
                  id={profile.brands_id + "btn-next-step-info"}
                  className="button"
                  bClass={"rbGreen"}
                  width={
                    window.appHeight() > window.innerWidth
                      ? "16vw"
                      : window.innerWidth / window.appHeight() > 2.15
                      ? "12vw"
                      : "12vw"
                  }
                  height="5.54vw"
                  fontSize={
                    window.appHeight() > window.innerWidth
                      ? "1.7vw"
                      : window.innerWidth / window.appHeight() > 2.15
                      ? "1.15vw"
                      : "1.15vw"
                  }
                  label={t("confirmBank")}
                  onClick={() => {
                    if (prefix == "") {
                      formValidation();
                      setShowAlert(true);
                    } else {
                      onSubmit();
                    }
                  }}
                />
              ) : (
                <Button
                  className="button"
                  bClass={"rbGreen"}
                  id={profile.brands_id + "btn-next-step-info"}
                  width={
                    window.appHeight() > window.innerWidth
                      ? "16vw"
                      : window.innerWidth / window.appHeight() > 2.15
                      ? "12vw"
                      : "12vw"
                  }
                  height="5.54vw"
                  fontSize={
                    window.appHeight() > window.innerWidth
                      ? "1.7vw"
                      : window.innerWidth / window.appHeight() > 2.15
                      ? "1.15vw"
                      : "1.15vw"
                  }
                  label={t("confirmBank")}
                  onClick={() => {
                    onSubmit();
                  }}
                />
              )}
            </>
          </ContainerButton>

          {showAlert === true ? (
            <BG style={{ justifyContent: "center" }}>
              <ConfirmPopup
                messageBottom={t("okay")}
                onClick={() => setShowAlert(false)}
              >
                {t("information is invalid")}
              </ConfirmPopup>
            </BG>
          ) : null}

          {userDuplicate === true ? (
            <BG style={{ justifyContent: "center" }}>
              <ConfirmPopup
                messageBottom={t("okay")}
                onClick={() => setUserDuplicate(false)}
              >
                {t("there are users duplicate")}
              </ConfirmPopup>
            </BG>
          ) : null}
        </Popup>
      )}

      <Transition showPop={showConditionAlert}>
        <BG style={{ justifyContent: "center" }}>
          <AlertMessage message={t("โปรดกดยอมรับเงื่อนไขและข้อตกลง")} />
        </BG>
      </Transition>

      <Transition showPop={toConfirm}>
        <BG style={{ justifyContent: "center" }}>
          <AlertMessage message={t("bank account registration successfully")} />
        </BG>
      </Transition>

      <Transition showPop={conFirmRegister}>
        <ConfirmationRegister
          username={inFormation.username}
          password={inFormation.password}
          pin={inFormation.pincode}
          jwtToken={jwtToken}
          closeClick={() => {
            props.closeClick();
            redirectToLogin(jwtToken);
          }}
        />
      </Transition>

      <Transition showPop={emitError.status} type="alert">
        <AlertMessage
          message={t(emitError.message)}
          onClick={() => {
            setEmitError({ status: false });
          }}
        />
      </Transition>

      <Transition showPop={openCondition} type="alert">
        <Condition closeClick={() => setOpenCondition(false)} />
      </Transition>
    </BG>
  );
}

export default BankAccount;
