import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { config, getFullUrl, getJWT, getLang } from "../../config";
import {
  actionClickSound,
  selectuserProfile,
} from "../../Reducers/userProfileSlice";
import { socket } from "../../context/socket";
import { actionGamePlay } from "../../Reducers/gamePlaySlice";
import { useHistory } from "react-router-dom";
import { Fragment } from "react";
import ConFirmPopUp from "../Dialog/ConfirmPopup";
import { useTranslation } from "react-i18next";

const BannerContainer = styled.div`
  user-select: none;
  width: 7vw;
  height: 7vw;
  position: relative;
  display: ${(props) => (props.open ? "flex" : "none")};
  justify-content: center;
  z-index: 20;
  @keyframes floating {
    from {
      transform: translate(0, 0px);
    }
    65% {
      transform: translate(0, 15%);
    }
    to {
      transform: translate(0, -0px);
    }
  }
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 20vw;
          height: 20vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 12vw;
          height: 12vw;
        `}
`;

const IMG = styled.img`
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export default function MysteryBoxBanner(props) {
  const profile = useSelector(selectuserProfile);
  const [open, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const [tempPop, setTempPop] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    socket.emit("get-season-mysterybox", {}, (body) => {
      console.log("get-season-mysterybox", body);
      if (body.status) {
        if (body?.results?.isOpen) {
          setOpen(true);
        }
      }
    });
    // if (/Android|webOS|iPhone|iPad|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    if (/Android|webOS|iPhone|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setIsMobile(1);
    } else {
      setIsMobile(0);
    }
  }, []);

  const MyteryBoxOpen = () => {
    socket.emit(
      "getGameUrl",
      {
        gameid: "654463c8ccf3620ed10acb49",
        platform: isMobile,
        url: /Android|webOS|iPhone|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
          ? window.location.protocol + "//" + window.location.host
          : window.location.protocol +
            "//" +
            window.location.host +
            "/back.html?token=" +
            getJWT(),
      },
      (body) => {
        console.log("Mytery Box's URL", body);

        if (body.status) {
          if (
            /Android|webOS|iPhone|IEMobile|Opera Mini/i.test(
              navigator.userAgent
            )
          ) {
            window.location.href = `${
              body?.results?.data?.urlMobile
            }&account_api=${profile.account_api}&lang=${getLang()}`;
          } else {
            dispatch(
              actionGamePlay({
                isPlay: true,
                gameUrl: `${body?.results?.data?.urlDesktop}&account_api=${
                  profile.account_api
                }&lang=${getLang()}`,
              })
            );
            history.push("/gameplay?token=" + getJWT());
          }
        }
      }
    );
  };

  return (
    <Fragment>
      <BannerContainer open={open}>
        <IMG
          src="/images/newasset/logo_mysterybox.png"
          onClick={() => {
            MyteryBoxOpen();
            dispatch(actionClickSound());
            // setTempPop(true);
          }}
        />
      </BannerContainer>
      {tempPop && (
        <ConFirmPopUp
          messageBottom={t("okay")}
          cancelClick={() => {
            setTempPop(false);
          }}
        >
          {t(
            "ขณะนี้กล่องสุ่มถูกเปิดครบทุกกล่องแล้ว ขอบคุณที่ให้ความสนใจกับกิจกรรมของเรา โปรดร่วมกิจกรรมใหม่อีกครั้งในซีซั่น 2 เร็วๆนี้"
          )}
        </ConFirmPopUp>
      )}
    </Fragment>
  );
}
