import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Popup from "../Popup";
// import Button from "../Button";
import Button from "../../Components/Button";
import Dialog from "../Dialog";
import WarningMessage from "../Dialog/WarningMessage";
import breakpoint from "../breakpoints";
// import useWindowSize from "../useWindowSize";
import { selectDimensionsLP } from "../../Reducers/dimensionsLP";
import { useTranslation } from "react-i18next";

import { config, Transition } from "../../config";
import axios from "axios";
import PinInput from "react-pin-input";

// let moment = require("moment");
// let momentCountdown = require("moment-countdown");

const ForgetPasswordContainer = styled.div`
  width: max-content;
  margin: 0 auto;
  position: relative;
  z-index: 20;
  color: #fff;
  h4 {
    font-size: 1.25vw; //24px
    color: #ff7000;
  }
  p {
    font-size: 1vw;
  }
  p.pincode {
    text-align: center;
  }
  h6 {
    width: 20.83vw;
    color: #797fa1;
    font-size: 0.67vw; //12px
    font-weight: normal;
    margin-block-start: 0.2vw;
    margin-block-end: 1vw;
  }

  @media ${breakpoint.device.mobile} {
    h4 {
      font-size: 5.79vw;
    }
    p {
      font-size: 3.96vw; //16px
      line-height: 2;
      margin-top: 3.5vw;
    }
    h6 {
      width: 80vw;
      font-size: 2.97vw;
      margin-block-start: 3.2vw;
      margin-block-end: 4vw;
    }
  }
`;

const PinContainer = styled.div`
  width: 23vw;
  .pincode-input-container {
    margin: 1vw auto;
    width: 77%;
  }
  .pincode-input-text {
    background-color: #000000 !important;
    border: transparent !important;
    border-radius: 60%;
    width: 4vw !important;
    height: 4vw !important;
    color: #ff7000;
    font-size: 2vw;
    margin: 0.2vw !important;
  }
  @media ${breakpoint.device.mobile} {
    width: 85vw;
    .pincode-input-container {
      width: 80%;
    }
    .pincode-input-text {
      width: 15vw !important;
      height: 15vw !important;
      font-size: 10vw;
      margin: 1vw !important;
    }
  }
`;

const Input = styled.input`
  width: 20.83vw;
  height: 2.6vw;
  color: #fff;
  font-size: 1.2vw;
  background-color: #000000;
  border: none;
  border-radius: 0.5vw;
  outline: transparent;
  display: flex;
  padding: 0 1vw;
  margin-top: 0.5vw;
  ::placeholder {
    color: rgba(255, 255, 255, 0.3);
    font-size: 1.04vw;
    align-items: center;
  }

  @media ${breakpoint.device.mobile} {
    width: 80.92vw;
    height: 12.37vw;
    font-size: 4.83vw; // 20px
    border-radius: 2vw;
    padding: 0 2vw;
    ::placeholder {
      font-size: 4.83vw; // 20px
    }
  }
`;

const OTPBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5vw;
`;

const ButtonNewOTP = styled.button`
  // start clear defualt button style
  border: none;
  background-color: transparent;
  &:focus {
    outline: none;
  }
  // end clear
  color: #ffffff;
  border-radius: 0.5vw;
  background-color: ${(props) => props.bClass};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  font-size: ${(props) => props.fontSize};
  cursor: ${(props) => props.cursor};
`;

const InputOTP = styled.input`
  width: 11.83vw;
  height: 2.6vw;
  color: #fff;
  font-size: 1.2vw;
  background-color: #000000;
  border: none;
  border-radius: 0.5vw;
  outline: transparent;
  display: flex;
  padding: 0 1vw;
  margin-right: 0.5vw;
  ::placeholder {
    color: rgba(255, 255, 255, 0.3);
    font-size: 1.04vw;
    align-items: center;
  }

  @media ${breakpoint.device.mobile} {
    width: 43.92vw;
    height: 12.37vw;
    font-size: 4.83vw; // 20px
    border-radius: 2vw;
    margin-right: 2.5vw;
    padding: 0 2vw;
    ::placeholder {
      font-size: 4.83vw; // 20px
    }
  }
`;



const Btn = styled.div`
  display: flex;
  justify-content: space-between;
  width: 22.75vw;
  position: absolute;
  @media ${breakpoint.device.mobile} {
    width: 100%;
  }
`;

const DialogContainer = styled.div`
  user-select: none;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
`;

const WarningContainer = styled.div`
  user-select: none;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
`;

function ForgetPassword(props) {
  // const size = useWindowSize();
  const dimensionsLP = useSelector(selectDimensionsLP);
  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [Otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  // const [isSave, setIsSave] = useState(false);
  const [step, setStep] = useState(1);
  const [complete, setComplete] = useState(false);
  const [receivedOtp, setReceivedOtp] = useState(false);
  const [reqNewOtp, setReqNewOtp] = useState(false);
  const [[m, s], setTime] = useState([3, 0]);
  const [message, setMessage] = useState("");
  const [countDown, setCountDown] = useState({ min: 0, sec: 0 });
  // const [showTime, setShowTime] = useState(false);
  const [timeleft, setTimeleft] = useState(0);
  // const { initialMinute = 3, initialSeconds = 0 } = props;
  // const [minutes, setMinutes] = useState(initialMinute);
  // const [seconds, setSeconds] = useState(initialSeconds);
  const [emitError, setEmitError] = useState({
    status: false,
    message: "",
    errorCode: "",
  });

  let reqExpOtp = new RegExp(/(\d+){1,6}/);
  let reqExpTel = new RegExp(/^[0-9\b]+$/);
  // let regExpNewPass = new RegExp(/(\d+){6,12}/);

  // useEffect(() => {
  //     let timerID;
  //     if (step == 2) {
  //         timerID = setInterval(() => tick(), 1000);
  //     }
  //     return () => {
  //         clearInterval(timerID);
  //     }
  // }, [step, m, s]);

  useEffect(() => {
    let timerID;
    if (message === "system via register") {
      timerID = setInterval(() => tick(), 1000);
    }
    return () => {
      clearInterval(timerID);
    };
  }, [message, m, s]);

  const tick = () => {
    if (!(m === 0 && s === 0)) {
      if (s === 0) {
        setTime([m - 1, 59]);
      } else {
        setTime([m, s - 1]);
      }
    } else {
      setReqNewOtp(true);
    }
  };

  const reset = () => {
    setTime([parseInt(3), parseInt(0)]);
    setReqNewOtp(false);
  };

  let ChangeCountdown;

  const fnTimeleft = (time) => {
    let min = Math.floor(time / 60);
    let sec = Math.floor(time % 60);
    return {
      min: min,
      sec: sec,
    };
  };

  useEffect(() => {
    if (timeleft !== 0) {
      let time = timeleft;
      setCountDown(fnTimeleft(time));
      ChangeCountdown = setInterval(() => {
        if (time <= 0) {
          time = 0;
          clearInterval(ChangeCountdown);
          // handleTel();
          // handleUsername();
          // setStep(1);
          setReqNewOtp(true);
        }
        setCountDown(fnTimeleft(time));

        time = time - 1;
      }, 1000);
    }
  }, [timeleft]);

  const handleUsername = () => {
    let data = JSON.stringify({
      username: username,
    });

    axios({
      method: "post",
      url: config.socketEndpoint + "getOTPLandingPage",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then(function (response) {
        if (
          typeof response.data === "undefined" ||
          typeof response.data.results === "undefined"
        ) {
        } else {
          if (response.data.status === true) {
            // console.log("response", response.data);
            setTimeleft(180);
            // setComplete(true);
            setMessage("system via register");
            setReceivedOtp(true);
            setStep(2);
            setTimeout(() => {
              setReceivedOtp(false);
            }, 3000);
            // setStep(2);
          } else if (response.data.errorCode === "s0032") {
            console.log("data", response.data);
            setTimeleft(parseInt(response.data.results.timeleft));
            setStep(2);
            setEmitError({ status: true, message: response.data.errorCode });
          } else {
            console.log("data", response.data);
            setEmitError({ status: true, message: response.data.errorCode });
            // setTimeout(() => {
            //   setEmitError({ status: false });
            // }, 3000);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleOTP = () => {
    let data = JSON.stringify({
      username: username,
      otp: Otp,
    });
    axios({
      method: "post",
      url: config.socketEndpoint + "checkOTPLandingPage",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then(function (response) {
        if (
          typeof response.data === "undefined" ||
          typeof response.data.results === "undefined"
        ) {
        } else {
          if (response.data.status === true) {
            console.log(JSON.stringify(response.data));
            setStep(3);
          } else if (response.data.status === false) {
            console.log(JSON.stringify(response.data));
            setEmitError({ status: true, message: response.data.errorCode });
            // setTimeout(() => {
            //     setEmitError({ status: false })
            // }, 3000)
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onSubmit = () => {
    // let fields = document.querySelectorAll('input[type="password"]');
    // if (isSave) {
    //     fields.autocomplete = "on";
    // } else {
    //     fields.autocomplete = "off";
    // }
    let data = JSON.stringify({
      username: username,
      otp: Otp,
      new_password: newPassword,
    });

    axios({
      method: "post",
      url: config.socketEndpoint + "setPasswordLandingPage",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then(function (response) {
        console.log("forget password response", response.data);
        if (
          typeof response.data === "undefined" ||
          typeof response.data.results === "undefined"
        ) {
        } else {
          if (response.data.status === true) {
            setMessage("password complete");
            setComplete(true);
            try {
              // localStorage.setItem("password", isSave ? newPassword : "");
              // localStorage.setItem("username", isSave ? username : "");
            } catch (error) { }

            console.log(JSON.stringify(response.data));
          } else if (response.data.status === false) {
            console.log(JSON.stringify(response.data));
            setEmitError({ status: true, message: response.data.errorCode });
            // setTimeout(() => {
            //     setEmitError({ status: false })
            // }, 3000);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <Popup forget>
      <ForgetPasswordContainer>
        <h4>{step !== 3 ? t("forgotpasswordpopup") : t("change pincode")}</h4>
        {step === 1 ? (
          <>
            {/* Step 1 */}
            <p>{t("mobile login")}</p>
            <Input
              type="text"
              name="username"
              placeholder="0XXXXXXXXX"
              maxLength={10}
              value={username}
              onChange={(v) => {
                if (v.target.value === "" || reqExpTel.test(v.target.value)) {
                  setUsername(v.target.value);
                }
              }}
            />
            <h6 style={{ whiteSpace: "nowrap" }}>
              {t("the system will send you OTP")}
            </h6>
            <Btn>
              <Button
                bClass="rbRed"
                label={t("cancel")}
                width={
                  dimensionsLP.width <= breakpoint.deviceSize
                    ? "40.48vw"
                    : "11vw"
                }
                height={
                  dimensionsLP.width <= breakpoint.deviceSize
                    ? "12.5vw"
                    : "3.13vw"
                }
                fontSize={
                  dimensionsLP.width <= breakpoint.deviceSize ? "4.83vw" : "1vw"
                }
                onClick={props.cancelClick}
              />
              <Button
                bClass={username.length < 10 ? "rbGray" : "rbGreen"}
                label={t("otp next")}
                width={
                  dimensionsLP.width <= breakpoint.deviceSize
                    ? "40.48vw"
                    : "11vw"
                }
                height={
                  dimensionsLP.width <= breakpoint.deviceSize
                    ? "12.5vw"
                    : "3.13vw"
                }
                fontSize={
                  dimensionsLP.width <= breakpoint.deviceSize ? "4.83vw" : "1vw"
                }
                onClick={handleUsername}
                disabled={username.length < 1 ? true : false}
                cursor={username.length < 1 ? "initial" : "pointer"}
              />
            </Btn>
          </>
        ) : step === 2 ? (
          <>
            {/* Step 2 */}
            <p>OTP</p>
            <OTPBox>
              <InputOTP
                type="text"
                name="otp"
                placeholder={t("input otp")}
                value={Otp}
                maxLength="6"
                onChange={(v) => {
                  // setOtp(v.target.value);
                  // reqExpOtp.test(Otp);
                  if (v.target.value === "" || reqExpOtp.test(v.target.value)) {
                    setOtp(v.target.value);
                  }
                }}
              />
              <ButtonNewOTP
                bClass={reqNewOtp ? "#FF691F" : "#484E6B"}
                label={t("request otp")}
                width={
                  dimensionsLP.width <= breakpoint.deviceSize
                    ? "34.48vw"
                    : "8.18vw"
                }
                height={
                  dimensionsLP.width <= breakpoint.deviceSize
                    ? "12.5vw"
                    : "2.6vw"
                }
                fontSize={
                  dimensionsLP.width <= breakpoint.deviceSize ? "4.83vw" : "1vw"
                }
                onClick={() => {
                  handleUsername();
                  reset();
                }}
                disabled={!reqNewOtp ? true : false}
                cursor={!reqNewOtp ? "initial" : "pointer"}
              >
                {reqNewOtp ? t("request new otp") : t("request otp")}
              </ButtonNewOTP>
            </OTPBox>

            <h6>
              {t("otp expired")}&nbsp;
              {countDown.sec < 10
                ? "0" + countDown.min + ":0" + countDown.sec
                : "0" + countDown.min + ":" + countDown.sec}
              &nbsp;
              {t("min")}
            </h6>
            <Btn>
              <Button
                bClass="rbRed"
                label={t("cancel")}
                width={
                  dimensionsLP.width <= breakpoint.deviceSize
                    ? "40.48vw"
                    : "11vw"
                }
                height={
                  dimensionsLP.width <= breakpoint.deviceSize
                    ? "12.5vw"
                    : "3.13vw"
                }
                fontSize={
                  dimensionsLP.width <= breakpoint.deviceSize ? "4.83vw" : "1vw"
                }
                onClick={props.cancelClick}
              />
              <Button
                // bClass={Otp.length < 6 ? "rbGray" : "rbGreen"}
                bClass={"rbGreen"}
                label={t("change pin code")}
                width={
                  dimensionsLP.width <= breakpoint.deviceSize
                    ? "40.48vw"
                    : "11vw"
                }
                height={
                  dimensionsLP.width <= breakpoint.deviceSize
                    ? "12.5vw"
                    : "3.13vw"
                }
                fontSize={
                  dimensionsLP.width <= breakpoint.deviceSize ? "4.83vw" : "1vw"
                }
                onClick={handleOTP}
                // disabled={Otp.length < 6 ? true : false}
                cursor={Otp.length < 6 ? "initial" : "pointer"}
              />
            </Btn>
          </>
        ) : (
          <>
            {/* Step 3 */}
            {/* <p>{t("new password")}</p> */}
            <p className="pincode">{t("create pincode for login")}</p>
            {/* <Input
              type="password"
              name="password"
              // autocomplete="off"
              placeholder={t("input new password")}
              value={newPassword}
              maxLength="4"
              onChange={(v) => {
                setNewPassword(v.target.value);
              }}
            /> */}
            <PinContainer>
              <PinInput
                onFocus={() => {
                  window.isEdit = true;
                }}
                onBlur={() => {
                  window.isEdit = false;
                }}
                length={4}
                type="numeric"
                value={newPassword}
                onChange={(value) => {
                  setNewPassword(value);
                }}
              />
            </PinContainer>

            {/* <h6>{t("make sure")}</h6> */}
            {/* <h6>{t("pincode login")}</h6> */}
            {/* <InputCheckBox>
              <CheckBox onClick={() => setIsSave(!isSave)} isSave={isSave} />
              <span>{t("save new password")}</span>
            </InputCheckBox> */}
            <Btn>
              <Button
                bClass="rbRed"
                label={t("cancel")}
                width={
                  dimensionsLP.width <= breakpoint.deviceSize
                    ? "40.48vw"
                    : "11vw"
                }
                height={
                  dimensionsLP.width <= breakpoint.deviceSize
                    ? "12.5vw"
                    : "3.13vw"
                }
                fontSize={
                  dimensionsLP.width <= breakpoint.deviceSize ? "4.83vw" : "1vw"
                }
                onClick={props.cancelClick}
              />
              <Button
                bClass={newPassword.length < 4 ? "rtGray" : "rbGreen"}
                label={t("confirm")}
                width={
                  dimensionsLP.width <= breakpoint.deviceSize
                    ? "40.48vw"
                    : "11vw"
                }
                height={
                  dimensionsLP.width <= breakpoint.deviceSize
                    ? "12.5vw"
                    : "3.13vw"
                }
                fontSize={
                  dimensionsLP.width <= breakpoint.deviceSize ? "4.83vw" : "1vw"
                }
                onClick={onSubmit}
                disabled={newPassword.length < 4 ? true : false}
                cursor={newPassword.length < 4 ? "initial" : "pointer"}
              />
            </Btn>
          </>
        )}

        {/* Step 4 */}
        {/* <Transition
          in={complete}
          timeout={300}
          classNames="alert"
          unmountOnExit
        > */}
        <Transition showPop={complete} type="alert">
          <DialogContainer>
            <Dialog
              message={t(`${message}`)}
              bClass="rbGreen"
              btnLabel="okay"
              onClick={() => {
                // if (message == "password complete") {
                props.cancelClick();
                props.closeLoginPopup();
                // } else if (message == "system via register") {
                //   console.log("system via register")
                //   setStep(2);
                //   setComplete(false);
                // }
              }}
            />
          </DialogContainer>
        </Transition>
        {/* </Transition> */}

        <Transition showPop={receivedOtp} type="alert">
          <DialogContainer>
            <Dialog message={t(`${message}`)} bClass="rbGreen" btnLabel="" />
          </DialogContainer>
        </Transition>

        <Transition showPop={emitError.status} type="alert">
          <WarningContainer>
            <WarningMessage
              errCode={emitError.message}
              btnLabel={t("try again")}
              onClick={() => {
                setEmitError({ ...emitError, status: false });
                if (emitError.message === "s0030") {
                  props.closeLoginPopup();
                }
              }}
            />
          </WarningContainer>
        </Transition>
      </ForgetPasswordContainer>
    </Popup>
  );
}

export default ForgetPassword;
