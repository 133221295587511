import { createSlice } from "@reduxjs/toolkit";
export const topupSlice = createSlice({
    name: "topup",
    initialState: {
        amount: 0,
    },
    reducers: {
        // actionTopup: (state, action) => {
        //     state.topups = { ...state.topups, ...action.payload };
        // },
        actionTopup: (state, action) => {
            state.amount = action.payload
        },
    },
})

export const { actionTopup } = topupSlice.actions;
export const selectTopupSlice = (state) => state.topup.amount;
export default topupSlice.reducer;