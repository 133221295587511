import React, { useContext, useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { config, getFullUrl, getJWT, getLang } from "../../config";
import {
  actionSetprofile,
  selectuserProfile,
} from "../../Reducers/userProfileSlice";
import Button from "../Button";
import { useSelector, useDispatch } from "react-redux";
import {
  actionPopup,
  actionUpdatePopup,
  selectPopupList,
} from "../../Reducers/popupSlice";
import { actionFestivalBanner } from "../../Reducers/festivalBannerSlice";
import { actionPromoRedirect } from "../../Reducers/promotionSlice";
import { SocketContext } from "../../context/socket";
import { useHistory } from "react-router-dom";
import { actionGamePlay } from "../../Reducers/gamePlaySlice";
import MysteryBoxBanner from "../MysteryBoxNew/MysteryBoxBanner";

const EventBannerContainer = styled.div`
  user-select: none;
  width: 96%;
  height: 90%;
  background-color: #000;
  border-radius: 1.5vw;
  margin-left: 1vw;
  margin-right: 1vw;
  text-align: center;
  position: relative;
  padding: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          height: 50%;
          top: -41vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          margin: 0;
          width: 91%;
          height: 67%;
        `}
`;

const Footer = styled.div`
  display: flex;
  height: 10%;
  width: 100%;
  align-items: center;
  position: relative;
  justify-content: space-between;
  bottom: 0;
  ${() =>
    window.appHeight() > window.innerWidth &&
    css`
      width: 99%;
    `}
`;

const Banner = styled.img`
  width: 100%;
  position: relative;
`;

const PreButton = styled.div`
  cursor: pointer;
  width: 10%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
  ${() =>
    window.innerWidth / window.appHeight() > 2.15 &&
    css`
      width: 8%;
    `}
`;

const NextButton = styled.div`
  cursor: pointer;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  width: 10%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
  ${() =>
    window.innerWidth / window.appHeight() > 2.15 &&
    css`
      width: 8%;
    `}
`;

const BannerBox = styled.div`
  width: 99%;
  height: 87%;
  position: relative;
  border-radius: 1vw;
  overflow: hidden scroll;
  ::-webkit-scrollbar {
    width: 1vw;
    height: 0vw;
    z-index: 10;
  }
  ::-webkit-scrollbar-thumb {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 10;
    position: absolute;
    width: 11px;
    height: 80px;
    background: linear-gradient(180deg, #e13b00 38.02%, #900000 100%);
    box-shadow: inset 0px 0px 4px 2px #ff814d;
    border-radius: 10px;
  }
`;

function EventBanner(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector(selectuserProfile);
  const popupList = useSelector(selectPopupList);
  const socket = useContext(SocketContext);
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(0);

  useEffect(() => {
    if (/Android|webOS|iPhone|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setIsMobile(1);
    } else {
      setIsMobile(0);
    }
  }, []);

  const MysteryBoxOpen = () => {
    socket.emit(
      "getGameUrl",
      {
        gameid: "654463c8ccf3620ed10acb49",
        platform: isMobile,
        url: /Android|webOS|iPhone|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
          ? window.location.protocol + "//" + window.location.host
          : window.location.protocol +
            "//" +
            window.location.host +
            "/back.html?token=" +
            getJWT(),
      },
      (body) => {
        console.log("Mytery Box's URL", body);

        if (body.status) {
          if (
            /Android|webOS|iPhone|IEMobile|Opera Mini/i.test(
              navigator.userAgent
            )
          ) {
            window.location.href = `${
              body?.results?.data?.urlMobile
            }&account_api=${profile.account_api}&lang=${getLang()}`;
          } else {
            dispatch(
              actionGamePlay({
                isPlay: true,
                gameUrl: `${body?.results?.data?.urlDesktop}&account_api=${
                  profile.account_api
                }&lang=${getLang()}`,
              })
            );
            history.push("/gameplay?token=" + getJWT());
          }
        }
      }
    );
  };

  //   console.log("props", props);
  return (
    <>
      <EventBannerContainer>
        <BannerBox>
          <Banner
            src={profile.language === "TH" ? props.item.img : props.item.img_en}
          />
        </BannerBox>
        <Footer>
          <PreButton
            onClick={() => {
              props.moveMenu(
                props.index !== 0 ? props.index - 1 : props.total - 1
              );
              if (props.index !== 0) {
                props.setSelectedIndex(props.index - 1);
              } else {
                props.setSelectedIndex(props.total - 1);
              }
            }}
          >
            <img src="../../images/popup_navigate_arrow.png" />
          </PreButton>
          {props.item.displayType === "N" ? (
            ""
          ) : (
            <Button
              style={{ margin: "0 auto", width: "35%" }}
              onClick={() => {
                if (profile.popup.filter((a) => a.no === 1).length > 0) {
                  dispatch(actionUpdatePopup(""));
                }
                // dispatch(actionPopupList(""));
                dispatch(actionSetprofile({ popup: popupList }));
                dispatch(actionPopup({ eventBanner: false }));
                if (props.item.displayType === "M") {
                  //   setEventPopup(false);
                  dispatch(actionPopup({ mission: true }));
                  if (typeof props.item.mission_group !== "undefined") {
                    dispatch(actionFestivalBanner(props.item.mission_group));
                  }
                } else if (props.item.displayType === "B") {
                  //   setEventPopup(false);
                  dispatch(actionPopup({ depositWithdrawal: true }));
                } else if (props.item.displayType === "R") {
                  dispatch(actionPopup({ promotion: true }));
                  dispatch(actionPromoRedirect(true));
                } else if (props.item.displayType === "E") {
                  dispatch(actionPopup({ mission: true }));
                  dispatch(actionFestivalBanner(99));
                } else if (props.item.displayType === "I") {
                  socket.emit("updatestatusanniversaries", {}, (data) => {
                    console.log(data);
                  });
                  dispatch(
                    actionSetprofile({
                      popup: popupList.filter((a) => a.no !== 2),
                    })
                  );
                  dispatch(actionPopup({ email: true }));
                  dispatch(actionFestivalBanner(99));
                } else if (props.item.displayType === "MG") {
                  MysteryBoxOpen();
                }
              }}
              bClass="rbGreen"
              label={
                props.item.displayType === "M"
                  ? t("go to mission")
                  : props.item.displayType === "B"
                  ? t("get bonus now")
                  : props.item.displayType === "N"
                  ? t("ok")
                  : props.item.displayType === "R"
                  ? t("read more")
                  : props.item.displayType === "E"
                  ? t("go to event")
                  : props.item.displayType === "I"
                  ? t("go to inbox")
                  : props.item.displayType === "MG"
                  ? t("play minigame")
                  : ""
              }
              width={
                window.appHeight() / window.innerWidth >
                config.verticalSizeiphone
                  ? "25vw"
                  : window.appHeight() > window.innerWidth
                  ? "21vw"
                  : "17vw"
              }
              fontSize={
                window.appHeight() / window.innerWidth >
                config.verticalSizeiphone
                  ? "2.75vw"
                  : window.appHeight() > window.innerWidth
                  ? "1.75vw"
                  : "1.75vw"
              }
              // fontSize="1.75vw"
            />
          )}
          <NextButton
            onClick={() => {
              props.moveMenu(
                props.index < props.total - 1 ? props.index + 1 : 0
              );
              if (props.index < props.total - 1) {
                props.setSelectedIndex(props.index + 1);
              } else {
                props.setSelectedIndex(0);
              }
            }}
          >
            <img src="../../images/popup_navigate_arrow.png" />
          </NextButton>
        </Footer>
      </EventBannerContainer>
    </>
  );
}

export default EventBanner;
