import { createSlice } from "@reduxjs/toolkit";
export const winRateSlice = createSlice({
  name: "winRate",
  initialState: {
    // data: [
    //   { game_Id: "618391bc7e010000af0049a2", winrate: 50 },
    //   { game_Id: "61fc8c1f7b2700004f004082", winrate: 60 },
    //   { game_Id: "61dd4a88b31c00006600672d", winrate: 70 },
    // ],

    data: {},
  },
  reducers: {
    actionWinRate: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
  },
});

export const { actionWinRate } = winRateSlice.actions;
export const selectWinRateSlice = (state) => state.winRate.data;
export default winRateSlice.reducer;
