import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { useDispatch } from "react-redux";
import {
  actionCloseSound,
  actionWinPopSound,
} from "../../Reducers/userProfileSlice";
import { config } from "../../config";
const Background = styled.div`
  user-select: none;
  width: 100vw;
  height: calc(100% - 8.4vw);
  position: fixed;
  left: 0%;
  bottom: -2%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 20;
  align-items: center;
  justify-content: center;
  /* transform: scale(0.8); */
`;

const PopupFes = styled.div`
  transform: scale(${(props) => (props.tranform ? props.tranform : 0.8)});
  position: absolute;
  background: #f87d7d;
  box-shadow: inset 0px 0px 3px 1px #ffc3c3, inset 0px 0px 20px #ffdedc;
  border-radius: 2vw;
  /* ${(props) => css`
    width: url(${props.width});
    height: ${props.height};
  `} */
`;

const TitleaccessLeft = styled.div`
  ${(props) => css`
    background-image: url(${props.imgleft});
    right: ${props.right};
  `}
  background-size: contain;
  width: 10vw;
  height: 8vw;
  background-repeat: no-repeat;
  position: absolute;
  /* right: 9vw; */
`;

const TitleaccessRight = styled.div`
  position: relative;
  ${(props) => css`
    background-image: url(${props.imgright});
    left: ${props.left};
  `}
  background-size: contain;
  width: 10vw;
  height: 8vw;
  background-repeat: no-repeat;
  position: absolute;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  top: 0vw;
`;

const Title = styled.div`
  user-select: none;
  /* top: -50vw;  */
  /* left: auto;
    display: inline-block; */
  width: ${(props) =>
    props.header === "header"
      ? `null`
      : ` max-content;
  `};
  /* display: inline-block; */
  /* position: absolute; */
  margin: ${(props) =>
    props.header === "header"
      ? `0 6vw;`
      : ` 0 auto;
  `};
  color: white;
  /* height: 10vw; */
  /* height: 5vw; */
  font-size: 2vw;
  z-index: 0;
  font-weight: 400;
  text-align: center;
  line-height: 5.1vw;
  padding: 0 9vw;
  background-clip: border-box, border-box, content-box;
  background-size: ${(props) =>
    props.size === "size"
      ? `70vw`
      : ` 8vw,
            8.17vw,
      1.52vw;
  `};
  background-position: top 0 left 4vw, top 0 right 4vw, top -0vw right 0;
  background-repeat: no-repeat, no-repeat, repeat-x;
  // background-image:
  //     url('../${config.theme}/popup_title_bg_left.png'),
  //     url('../${config.theme}/popup_title_bg_right.png'),
  //     url('../${config.theme}/popup_title_bg_center.png');

  background-image: ${(props) =>
    props.header === "header"
      ? // `url('../${config.theme}/diamond_shop_title_bg.png')
      // `
      `none`
      : `url('../images/event_val/popup_title_bg_left.png'),
      url('../images/event_val/popup_title_bg_right.png'),
      url('../../images/event_val/popup_title_bg_center.png');
  `};
`;
const Header = styled.div`
  width: 100%;
  position: absolute;
  top: -2.5vw;
  right: ${(props) =>
    props.width / props.height > 2.15
      ? "-0.75vw"
      : props.width / props.height > 1.8
        ? "0"
        : "-0.75vw"};
  font-size: 1.8vw;
`;

const Close = styled.img`
  user-select: none;
  position: absolute;
  width: 4.9vw;
  right: 2.5%;
  top: -4%;
  cursor: pointer;
`;

const Box = styled.div`
  position: relative;
  text-align: center;
  margin: 0 auto;
  top: 1vw;
  ${(props) => css`
    width: ${props.widthContent};
    height: ${props.heightContent};
  `}
  background: #FFFBD8;
  box-shadow: inset 0px 0px 3px 1px #ffffff,
    inset 0px 0px 20px rgba(236, 152, 106, 0.63);
  border-radius: 2vw;
  padding: 1vw 0vw;
`;

const Content = styled.div`
  user-select: none;
  position: absolute;
  width: 100%;
  height: 100%;
`;

function Popup(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionWinPopSound());
  }, []);

  return (
    <Background>
      <PopupFes
        tranform={props.tranform}
        style={{
          width: props.width,
          height: props.height,
          marginTop: props.marginTop,
          borderRadius: props.borderRadius,
        }}
      >
        <TitleaccessLeft
          imgleft={props.imgleft}
          style={{
            right: props.right,
            marginLeft: props.ImgMarginLeft,
            marginTop: props.ImgMarginTopLeft,
          }}
        />

        <Title
          header={props.header}
          size={props.size}
          TitleLeft={props.marginLeft}
          style={{
            fontSize: props.TitleSize,
          }}
        >
          {props.header === "header" ? (
            <>
              <img
                alt=""
                src={"../" + config.theme + "/diamond_shop_title_bg.png"}
                style={{
                  width: "67vw",
                  top: "-4vw",
                  marginLeft: "-11vw",
                  position: "relative",
                }}
              />
              <Header width={window.innerWidth} height={window.appHeight()}>
                {props.title}
              </Header>
            </>
          ) : (
            props.title
          )}
        </Title>
        <TitleaccessRight
          imgright={props.imgright}
          style={{
            left: props.left,
            marginLeft: props.ImgMarginRight,
            marginTop: props.ImgMarginTopRight,
          }}
        />
        <Close
          src={"../" + config.theme + "/btn_close.png"}
          style={{
            right: props.closebuttonRight,
            top: props.closebuttonTop,
            display: props.display,
            width: props.closebuttonWidth,
            height: props.closebuttonHeight,
          }}
          onClick={() => {
            props.closeClick();
            dispatch(actionCloseSound());
          }}
        />
        <Box style={{ width: props.widthContent, height: props.heightContent }}>
          <Content>{props.children}</Content>
        </Box>
      </PopupFes>
    </Background>
  );
}
export default Popup;
