import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled, { css, keyframes } from "styled-components";
import ConfirmPopup from "../Dialog/ConfirmPopup";
import CreateAccount from "../Register/CreateAccount";
import { useTranslation } from "react-i18next";
import {
  Transition,
  getBackUrl,
  setToken,
  redirectToLogin,
  removeAllSession,
  redirect2Page,
} from "../../config";
import Forgotpassword from "./Forgotpassword";
import { socket } from "../../context/socket";
import AlertMessage from "../Dialog/AlertMessage";
import { selectPopup, actionPopup } from "../../Reducers/popupSlice";
import { rubberBand } from "react-animations";
import { selectuserProfile } from "../../Reducers/userProfileSlice";
import Loading from "../Loading";
import {
  setFloatingValue,
  setFloatingStatus,
  setFloatingLabel,
} from "../../Reducers/floatingInputSlice";
import { config } from "../../config";
// import breakpoints from "../../LandingPageComponents/breakpoints";
const TutorialAnimation = keyframes`${rubberBand}`;

const BG = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 70;
  align-items: center;
  justify-content: center;
`;

const Popup = styled.div`
  user-select: none;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;
  border-radius: 1.5vw;
  position: relative;
  z-index: 60;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  width: 26.5vw;
  height: 21vw;
  flex-direction: column;

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 75.5vw;
          height: 79vw;
          border-radius: 3.5vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          width: 52vw;
          height: 42vw;
        `}
`;

const CloseBtn = styled.img`
  user-select: none;
  position: absolute;
  width: 3.5vw;
  height: 3.5vw;
  right: -6.5%;
  top: -1.5vw;
  cursor: pointer;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          right: -4.5%;
          top: -2.5vw;
          width: 7.5vw;
          height: 7.5vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          right: -5.5%;
          top: -2.5vw;

          width: 7.5vw;
          height: 7.5vw;
        `}
`;

const FormContainer = styled.div`
  color: #e5e5e5;
  padding: 1vw 1vw 0vw 1.8vw;
  display: flex;
  flex-direction: column;
  p {
    font-size: 1vw;
    line-height: 0;
    margin-top: 1vw;
  }
  a {
    color: #c5c9ec;
    font-size: 0.85vw;
  }

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          padding: 2vw 5vw 0vw 6vw !important ;
          margin-top: 2vw;
          p {
            font-size: 3.5vw;
            line-height: 1vw;
            margin-bottom: -1vw;
            display: contents;
            position: relative;
          }
        `
      : window.appHeight() > window.innerWidth &&
      css`
          margin-top: 2vw;
          padding: 2vw 4vw 0vw 3vw !important;
          p {
            font-size: 2.2vw;
            line-height: 0;
            margin-top: 2vw;
          }
        `}
`;

const Input = styled.input`
  width: 21vw;
  height: 3vw;
  color: #fff;
  font-size: 1.2vw;
  background-color: #000000;
  border: none;
  border-radius: 0.8vw;
  outline: transparent;
  display: flex;
  padding: 0 1vw;
  margin-top: 1vw;
  ::placeholder {
    color: #333333;
    font-size: 1.2vw;
    align-items: center;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 63vw;
          height: 12vw;
          margin-top: 2vw;
          font-size: 7vw;
          position: relative;
          border-radius: 1.8vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          width: 44vw;
          height: 6vw;
          margin-top: 2.5vw;
          font-size: 2.8vw;
          position: relative;
        `}
`;

const BtnLetsPlay = styled.div`
  background: linear-gradient(180deg, #5fde1a 38.02%, #46a905 100%);
  box-shadow: inset 0px 0px 0.5vw 0.5vw #50f96d;
  color: #fff;
  width: 23.5vw;
  height: 3.5vw;
  border-radius: 0.8vw;
  outline: none;
  border: none;
  font-size: 1.4vw;
  cursor: pointer;
  margin-top: 1vw;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 1.6vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 4vw;
          height: 12vw;
          top: 2vw;
          display: flex;
          justify-content: center;
          align-items: center;
          left: 6vw !important;
          width: 65vw;
          border-radius: 1.8vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          width: 45.9vw;

          height: 5.5vw;
          font-size: 2.5vw;
          display: flex;
          justify-content: center;
          align-items: center;
          left: 2.9vw !important;
        `}
`;

const Btnforgotpassword = styled.div`
  background: linear-gradient(180deg, #ea5119 20%, #a40f01 100%);
  /* box-shadow: inset 0px 0px 2px 3px #bbb7cf; */
  box-shadow: inset 0px 0px 0.5vw 0.5vw #e05b35;
  color: #fff;
  width: 11.6vw;
  height: 3.3vw;
  border-radius: 0.8vw;
  outline: none;
  border: none;
  font-size: 1vw;
  cursor: pointer;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.2vw;
  /* margin: 0vw 1vw 0vw 0vw; */
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 3.8vw;
          height: 11.8vw;
          width: 32vw;
          line-height: 2vw;
          border-radius: 1.8vw;
          display: flex;
          justify-content: center;
          align-items: center;
          /* margin: 0vw 0.8vw 0vw 0vw; */
        `
      : window.appHeight() > window.innerWidth &&
      css`
          margin: 0.2vw 1vw 1vw 0vw;
          font-size: 2.2vw;
          height: 5.35vw;
          width: 22.5vw;
          line-height: 1.6vw;
          line-height: 2vw;
          display: flex;
          justify-content: center;
          align-items: center;
          /* margin: 0vw 1vw 0vw 0vw; */
        `}
`;

const BtnRegister = styled.div`
  background: linear-gradient(180deg, #fea909 15%, #ff691f 85%);
  box-shadow: inset 0px 0px 0.5vw 0.5vw #ffaf08;
  color: #fff;
  width: 11.6vw;
  height: 3.3vw;
  border-radius: 0.8vw;
  outline: none;
  border: none;
  font-size: 1vw;
  cursor: pointer;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.2vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin: 0.2vw -1vw 1vw 1vw;
          font-size: 3.8vw;
          height: 11.8vw;
          width: 32vw;
          border-radius: 1.8vw;
          display: flex;
          justify-content: center;
          align-items: center;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          width: 22.5vw;
          height: 5.35vw;
          display: flex;

          justify-content: center;
          align-items: center;
          font-size: 2.2vw;
        `}
`;

const BoxButton = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  top: 0.5vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          top: 3vw;
          justify-content: center;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          top: 1vw;
          justify-content: center;
        `}
`;

// const WarningContainer = styled.div`
//   user-select: none;
//   width: 100vw;
//   height: 100vh;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background: rgba(0, 0, 0, 0.5);
//   position: fixed;
//   top: 0;
//   z-index: 50;
// `;

const TutorialArrow = styled.img`
  position: absolute;
  width: 6vw;
  top: -100%;
  left: 23%;
  z-index: 100;
  animation: ${TutorialAnimation} 1.5s linear infinite;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 13vw;
          left: 29%;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          width: 11vw;
          top: -100%;
          left: 6vw;
        `}
`;

const TutorialBox = styled.div`
  pointer-events: none;
  width: 91%;
  height: 69%;
  position: absolute;
  bottom: 0%;
  left: 0%;
  font-size: 24px;
  border: 0.5vw solid;
  resize: both;
  box-shadow: 0px 0px 50px 20px rgba(0, 0, 0, 0.8);
  //background: #ffd73e33;
  border-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cstyle%3Epath%7Banimation:stroke 1.5s infinite linear%3B%7D%40keyframes stroke%7Bto%7Bstroke-dashoffset:776%3B%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='180%25' y1='0%25' x2='80%25' y2='100%25'%3E%3Cstop offset='0%25' stop-color='%23d02d07' /%3E%3Cstop offset='25%25' stop-color='%23ff5400' /%3E%3Cstop offset='50%25' stop-color='%23ff7e00' /%3E%3Cstop offset='100%25' stop-color='%23ffea00' /%3E%3C/linearGradient%3E %3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='20' stroke-dasharray='193.6'/%3E %3C/svg%3E")
    1;
  ${() =>
    window.appHeight() > window.innerWidth &&
    css`  
      width: 97%;
    height: 92%;
  }
    `}
  ${() => window.appHeight() / window.innerWidth > 1.8 && css``}
`;

function Login(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const popupStatus = useSelector(selectPopup);
  const profile = useSelector(selectuserProfile);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  // const [toregister, setToRegister] = useState(false);
  const [showPopUpError, setShowPopUpError] = useState(false);
  const [error, setError] = useState(false);
  const [emitError, setEmitError] = useState({ status: false, message: "" });
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState("");

  useEffect(() => {
    try {
      socket.emit(
        "getbilog",
        {
          biname: "register_open",
        },
        (data) => {
          console.log(data);
        }
      );
    } catch (error) {
      console.log(error);
    }
  }, []);

  const onSubmit = () => {
    // console.log("profile", profile);
    try {
      socket.emit(
        "login",
        {
          account_api: username.trim(),
          password: password,
          brand_id: profile.brands_id,

          ...getBackUrl(),
        },
        (body) => {
          removeAllSession();
          setLoading(false);
          // console.log("Login", body);
          if (typeof body !== "undefined") {
            if (body.status === true) {
              setToken(body.results.jwtToken);
              setConfirm(true);
              setTimeout(() => {
                redirectToLogin("/?token=" + body.results.jwtToken);
              }, 3000);
            } else if (body.status === false) {
              if (body.errorCode === "s0020") {
                setShowPopUpError(true);
                setError(true);
              } else if (body.errorCode === "s0002") {
                setShowPopUpError(true);
                setError(false);
              } else {
                setEmitError({ status: true, message: body.errorCode });
                setTimeout(() => {
                  setEmitError({ status: false });
                }, 3000);
              }
            }
          }
        }
      );
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  };

  return (
    <BG>
      <Popup>
        {props.hideRegister === true ? (
          ""
        ) : (
          <CloseBtn
            src={"../" + config.theme + "/btn_close.png"}
            onClick={() => {
              window.location.reload(false);
              props.closeClick();
            }}
          />
        )}

        <FormContainer>
          <p>{t("title login")}</p>
          <Input
            // value={"username"}
            type="text"
            name="phone"
            value={username || ""}
            placeholder=""
            autoComplete="off"
            // maxLength={10}
            onFocus={(e) => {
              window.isEdit = true;
              window.onKeyBoard = true;
              dispatch(setFloatingLabel(t("mobile login")));
              dispatch(setFloatingStatus(true));
              dispatch(setFloatingValue(e.target.value));
            }}
            onBlur={() => {
              window.isEdit = false;
              window.onKeyBoard = false;
              dispatch(setFloatingStatus(false));
            }}
            onChange={(v) => {
              setUsername(v.target.value);
              dispatch(setFloatingValue(v.target.value));
            }}
          />

          <p>{t("login pincode")}</p>
          <Input
            type="password"
            name="password"
            placeholder=""
            autoComplete="off"
            value={password || ""}
            // maxLength={4}
            onFocus={(e) => {
              window.isEdit = true;
              window.onKeyBoard = true;
              dispatch(setFloatingLabel(t("login pincode")));
              dispatch(setFloatingStatus(true));
              dispatch(setFloatingValue(e.target.value));
            }}
            onBlur={() => {
              window.isEdit = false;
              window.onKeyBoard = false;
              dispatch(setFloatingStatus(false));
            }}
            onChange={(v) => {
              setPassword(v.target.value);
              dispatch(setFloatingValue(v.target.value));
            }}
          />
        </FormContainer>
        <BtnLetsPlay
          id={profile.brands_id + "-btn-login"}
          onClick={() => {
            onSubmit();
            setLoading(true);
          }}
        >
          {t("sing in")}
        </BtnLetsPlay>
        {props.hideRegister === true ? (
          <Btnforgotpassword
            onClick={() => props.closeClick("cancel")}
            style={{
              width: window.appHeight() > window.innerWidth ? "32vw" : "23vw",
              right: "auto",
            }}
          >
            {t("cancel")}
          </Btnforgotpassword>
        ) : (
          <BoxButton>
            <Btnforgotpassword
              onClick={() => dispatch(actionPopup({ forgotPassword: true }))}
            >
              {t("login forgotpassword")}
            </Btnforgotpassword>

            <BtnRegister
              id={profile.brands_id + "-btn-register"}
              onClick={() =>
                // dispatch(
                //   actionPopup({
                //     createAccount: true,
                //     login: false,
                //     register: false,
                //   })
                // )
                redirect2Page("register")
              }
            >
              {t("loginregister")}
              {profile.tutorial === 2 ? (
                <>
                  <TutorialArrow
                    src={"../" + config.theme + "/ftue_arrow.png"}
                  />
                  <TutorialBox />
                </>
              ) : (
                ""
              )}
            </BtnRegister>
          </BoxButton>
        )}
      </Popup>
      {confirm === true ? (
        <AlertMessage
          message={t("login completeed")}
          onClick={() => {
            setConfirm(false);
          }}
        />
      ) : (
        ""
      )}

      {loading ? <Loading /> : ""}
      <Transition showPop={popupStatus.forgotPassword}>
        {/* <BG style={{ justifyContent: "center" }}> */}
        <Forgotpassword
          // setToForgotpassword={() => setToForgotpassword(false)}
          closeClick={() => {
            props.closeClick();
            dispatch(actionPopup({ forgotPassword: false }));
          }}
        />
        {/* </BG> */}
      </Transition>

      <Transition showPop={popupStatus.createAccount}>
        <BG style={{ justifyContent: "center" }}>
          <CreateAccount
            // setToRegister={() => setToRegister(false)}
            closeClick={() => {
              props.closeClick();
              dispatch(actionPopup({ createAccount: false }));
            }}
          />
        </BG>
      </Transition>

      {showPopUpError === true ? (
        <BG style={{ justifyContent: "center" }}>
          <ConfirmPopup
            messageBottom={t("try again")}
            onClick={() => setShowPopUpError(false)}
          >
            {error === true ? <>{t("s0020")} </> : <>{t("s0002")}</>}
          </ConfirmPopup>
        </BG>
      ) : null}

      <Transition showPop={emitError.status} type="alert">
        <AlertMessage
          message={t(emitError.message)}
          onClick={() => {
            setEmitError({ status: false });
          }}
        />
      </Transition>
    </BG>
  );
}

export default Login;
