import { createSlice } from "@reduxjs/toolkit";

export const timeLimitProSlice = createSlice({
  name: "timeLimitPromotion",
  initialState: {
    countDownTime: {
      hour: 0,
      minute: 0,
      second: 0,
      barWidth: 0,
    },
  },
  reducers: {
    actionCountDownTimeLimitPro: (state, action) => {
      state.countDownTime = action.payload;
    },
  },
});

export const { actionCountDownTimeLimitPro } = timeLimitProSlice.actions;
export const selectCountDownTimeLimitPro = (state) =>
  state.timeLimitPromotion.countDownTime;
export default timeLimitProSlice.reducer;
