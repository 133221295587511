import React from "react";
import { useSelector } from "react-redux";
import styled, { css, keyframes } from "styled-components";
import { tada } from "react-animations";
import { config } from "../../config";
import { selectuserProfile } from "../../Reducers/userProfileSlice";

const BannerContainer = styled.div`
  user-select: none;
  width: 7vw;
  height: 7vw;
  position: relative;
  z-index: 20;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 20vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 12vw;
        `}
`;

const NoticeAnimation = keyframes`${tada}`;

const Dot = styled.img`
  user-select: none;
  width: 2.5vw;
  position: absolute;
  top: -16%;
  z-index: 2;
  animation: ${NoticeAnimation} 1.5s linear infinite;
  pointer-events: none;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 5vw;
          top: 20%;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 3.5vw;
        `}
`;

const IMG = styled.img`
  width: 100%;
  /* transform: rotate(20deg); */
  cursor: pointer;
`;

function FestivalBanner(props) {
  const profile = useSelector(selectuserProfile);
  return (
    <BannerContainer>
      {profile.jackpot.filter((data) => data.type === 1)[0]?.credit > 0 ? (
        <Dot src={"../" + config.theme + "/notice_dot.png"} />
      ) : (
        ""
      )}
      <IMG
        src={"../images/jackpot_icon.png"}
        onClick={() => {
          props.setShowJackpot(true);
        }}
      />
    </BannerContainer>
  );
}

export default FestivalBanner;
