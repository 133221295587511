import React, { useState, useEffect } from "react";
import styled from "styled-components";
import breakpoint from "../breakpoints";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectHomeContentLP } from "../../Reducers/homeContentLPSlice";
import { selectDimensionsLP } from "../../Reducers/dimensionsLP";

import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation } from "swiper/core";
import ArticleItem from "./ArticleItem";
// import MoreButton from "../MoreButton";
// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

const ArticlesContainer = styled.section`
  background-image: url("../../lpimages/bg_content2.png");
  width: 100%;
  background-size: 102%;
  background-position-x: center;
  background-repeat: no-repeat;
  padding-bottom: 3vw;
  background-color: #000;
  @media ${breakpoint.device.mobile} {
    background-position-x: center;
    text-align: start;
    background-size: 130%;
  }
`;

const Image = styled.img`
  width: 2.5vw;
  height: auto;
  padding-right: 0.5vw;
  @media ${breakpoint.device.mobile} {
    width: 5.5vw;
    height: auto;
    padding-right: 1.5vw;
  }
`;

const Header = styled.div`
  width: 53%;
  color: white;
  padding-top: 1vw;
  white-space: pre-wrap;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  h1 {
    font-size: 3vw;
  }
  p {
    color: #8a8ca3;
    font-size: 1.5vw;
    margin: 0;
    padding-left: 1vw;
    display: block;
    text-align: start;
    white-space: pre-wrap;
  }
  @media ${breakpoint.device.mobile} {
    padding: 5vw 3.5vw;
    align-items: start;
    justify-content: space-between;
    h1 {
      font-size: 6.5vw;
      display: inline-block;
    }
    p {
      font-size: 3.38vw;
      padding-left: 7vw;
    }
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  @media ${breakpoint.device.mobile} {
    display: block;
  }
`;

const ArticleSlide = styled.div`
  user-select: none;
  width: 60vw;
  margin: 1vw auto;
  position: relative;

  @media ${breakpoint.device.ipad} {
    width: 70vw;
    /* margin: 4vw 2%; */
  }
  @media ${breakpoint.device.mobile} {
    width: 96%;
    margin: 4vw 2%;
  }
`;

const NavigationButton = styled.div`
  width: 60vw;
  display: ${(props) => props.display};
  justify-content: space-between;
  position: absolute;
  top: 30%;
  z-index: 10;
  pointer-events: none;
  .swiper-button-disabled {
    opacity: 0.75;
  }

  @media ${breakpoint.device.ipad} {
    width: 70vw;
  }
  @media ${breakpoint.device.mobile} {
    width: 100%;
  }
`;

const SwiperPrevButton = styled.img`
  width: 2vw;
  cursor: pointer;
  pointer-events: all;
  @media ${breakpoint.device.mobile} {
    width: 6vw;
  }
`;

const SwiperNextButton = styled.img`
  width: 2vw;
  cursor: pointer;
  pointer-events: all;
  transform: scaleX(-1);
  @media ${breakpoint.device.mobile} {
    width: 6vw;
  }
`;

const SwiperFix = styled(Swiper)`
  overflow: hidden;
  width: 85%;
  margin: 0 auto;
  .swiper-wrapper {
    display: flex;
  }
  .swiper-slide {
    /* position: relative; */
  }
  @media ${breakpoint.device.mobile} {
    width: 95%;
  }
`;

const Bullets = styled.div`
  width: max-content;
  display: flex;
  margin: 0vw auto;
  .swiper-pagination-bullet {
    background: rgba(255, 112, 0, 0.3);
    width: 2.14vw;
    height: 0.2vw;
    margin: 0.3vw;
    cursor: pointer;
  }
  .swiper-pagination-bullet-active {
    background: #ff7000;
  }

  @media ${breakpoint.device.mobile} {
    width: 60%;
    margin: 3vw auto;
    .swiper-pagination-bullet {
      width: 9.9vw;
      height: 0.96vw;
      margin: 0.75vw;
    }
  }
`;

function Articles() {
  const { t } = useTranslation();
  // const history = useHistory();
  const homeContent = useSelector(selectHomeContentLP);
  const [items, setItems] = useState([]);
  const dimensionsLP = useSelector(selectDimensionsLP);

  useEffect(() => {
    if (homeContent.length !== 0) {
      setItems(homeContent.find((i) => i.location === "article").contents);
    }
  }, [homeContent]);

  // console.log("items",items)

  return (
    <>
      <ArticlesContainer>
        <Header>
          <Title>
            <Image src="../../lpimages/bullet.png" />
            <h1>{t("Articles")}</h1>
            {/* <p>{t("articles")}</p> */}
            {/* <p>{t("Articles Description")}</p> */}
          </Title>
          {/* <MoreButton label="Articles" /> */}
        </Header>

        <ArticleSlide>
          <NavigationButton
            display={
              dimensionsLP.width <= breakpoint.deviceSize ? "none" : "flex"
            }
          >
            <SwiperPrevButton
              className="swiper-button-prev"
              src="../../lpimages/promo_slide_arrow.png"
            />
            <SwiperNextButton
              className="swiper-button-next"
              src="../../lpimages/promo_slide_arrow.png"
            />
          </NavigationButton>

          <SwiperFix
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            pagination={{
              clickable: true,
              el: ".bullets",
              renderBullet: function (index, className) {
                return '<div class="' + className + '"></div>';
              },
            }}
            spaceBetween={dimensionsLP.width <= breakpoint.deviceSize ? 15 : 25}
            slidesPerView={"auto"}
          >
            {items.map((item, index) => (
              <SwiperSlide key={"home-banner-" + index}>
                <ArticleItem {...item} />
              </SwiperSlide>
            ))}
            <Bullets className="bullets"></Bullets>
          </SwiperFix>
        </ArticleSlide>
      </ArticlesContainer>
    </>
  );
}

export default Articles;
