import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import NumberFormat from "react-number-format";
import CountUp from 'react-countup';
import breakpoint from "../breakpoints";
import { useTranslation } from "react-i18next";
import { selectCounterLP } from "../../Reducers/counterLPSlice";

const CounterContainer = styled.div`
  background-image: url("../../lpimages/featuregame_text_bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 27.14vw;
  margin: 0 auto;
  padding: 1vw 0;
  p {
    font-size: 1.25vw; // 24px
    color: #ff7000;
  }
  h5 {
    position: relative;
  }
  h5 > span {
    display: inline-block;
    font-size: 2.5vw; // 48px
    font-weight: 400;
    color: #fff;
    line-height: 1;
    margin-bottom: 1.25vw;
  }
  h5 > p {
    /* display: inline-block; */
    position: absolute;
    bottom: 1.25vw;
    right: 0;
    font-size: 1.25vw;
    color: #484e6b;
  }
  @media ${breakpoint.device.mobile} {
    width: 89.85vw;
    height: 42.51vw;
    p {
      font-size: 5.07vw; // 14px
    }
    h5 > span {
      font-size: 9.66vw; // 24px
    }
    h5 > p {
      font-size: 4vw;
    }
  }

  /* ${props => (props.ctOnlineLength > 7 || props.ctTotalLength > 7) && `
    h5 > span {
      font-size: 2.2vw;
      margin-bottom: 1.25vw;
    }
    @media ${breakpoint.device.mobile} {
      h5 > span {
        font-size: 4.5vw;
        margin-top: 3.5vw;
      }
    }
  `}

${props => (props.ctOnlineLength > 10 || props.ctTotalLength > 10) && `
    h5 > span {
      font-size: 2vw;
      margin-top: 1.5vw;
    }
    h5 > p {
      font-size: 1vw;
    }
    @media ${breakpoint.device.mobile} {
      h5 > span {
        font-size: 4.5vw;
        margin-top: 3.5vw;
      }
      h5 > p {
        font-size: 2.5vw;
        margin-bottom: 0.5vw;
      }
    }
  `} */
`;

const TotalWin = styled.div`
  width: 90%;
  display: inline-block;
  text-align: right;
  h5 > span {
    padding-right: 3vw ;
  }
  @media ${breakpoint.device.mobile} {
    padding-right: 4vw;
    h5 > span {
    padding-right: 10vw ;
  }
  }
`;

const TotalUser = styled.div`
  width: 90%;
  display: inline-block;
  text-align: right;
  @media ${breakpoint.device.mobile} {
    padding-right: 4vw;
  }
`;



function JackpotCounter() {
  const { t } = useTranslation();
  const counter = useSelector(selectCounterLP);
  const [counterStart, setCounterStart] = useState(counter.totalWin);

  // console.log("counterStart", counterStart);
  // console.log("counter.totalWin", counter.totalWin);

  // use useEffect(fixed Warning error)
  useEffect(() => {
    return () => {
      setCounterStart(0);
    }
  }, [])

  setTimeout(() => {
    setCounterStart(counter.totalWin);
  }, 5000);

  return (
    <CounterContainer
      ctOnlineLength={counter.online.toString().length}
      ctTotalLength={counter.totalWin.toString().length}
    >
      <TotalWin>
        <p>{t("Total winnings")}</p>
        <h5>
          <CountUp
            start={parseInt(counterStart)} // add parseInt(fixed Warning error)
            end={parseInt(counter.totalWin)} // add parseInt(fixed Warning error)
            duration={1}
            // delay={5}
            separator=","
          />
          <p>THB</p>
        </h5>
      </TotalWin>
      <TotalUser>
        <p>{t("online players")}</p>
        <h5>
          <NumberFormat
            value={counter.online}
            displayType={"text"}
            thousandSeparator={true}
            fixedDecimalScale={true}
          />
        </h5>
      </TotalUser>

    </CounterContainer>
  );
}

export default JackpotCounter;
