import { createSlice } from "@reduxjs/toolkit";
export const gamePlaySlice = createSlice({
  name: "gamePlay",
  initialState: {
    game: {
      isPlay: false,
      gameUrl: "",
    },
  },
  reducers: {
    actionGamePlay: (state, action) => {
      // state.game = action.payload;
      state.game = { ...state.game, ...action.payload };
    },
  },
});

export const { actionGamePlay } = gamePlaySlice.actions;
export const selectGamePlay = (state) => state.gamePlay.game;
export default gamePlaySlice.reducer;
