import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
// import NumberFormat from "react-number-format";
import CountUp from "react-countup";
import { useSelector } from "react-redux";
import AlertMessage from "../Dialog/AlertMessage";
import { Transition } from "../../config";
import {
  actionCounterLP,
  selectCounterLP,
} from "../../Reducers/counterLPSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { config } from "../../config";
// import breakpoints from "../../LandingPageComponents/breakpoints";

const Box = styled.div`
  display: grid;
  text-align: center;
  margin-right: 2vw;
  // background: red;
  @media (orientation: portrait) {
    width: 40vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 40vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 40vw;
        `} */
`;

const Number = styled.div`
  font-size: 2.9vw;
  color: #572a00;
  z-index: 1;
  font-weight: 400;
  margin-top: 2.4vw;
  // margin-left: 42.6vw;
  /* margin: 2.4vw auto; */
  margin-bottom: 0;
  text-shadow: 0vw 0.2vw 0.2vw #ffffff, 0vw -0.2vw 0.2vw yellow;
  font-family: Prompt;
  position: fixed;
  width: 30%;
  // background: red;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
  @media (orientation: portrait) {
    font-size: 4.9vw;
    margin-top: 5vw;
  }

  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 3.9vw;
    margin-top: 3.4vw;
  }

  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 4.9vw;
          margin-top: 5vw;
          // margin-left:37.6vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 3.9vw;
          margin-top: 3.4vw;
          // margin-left: 39.6vw;
        `} */
`;
const Text = styled.div`
  font-size: 1.8vw;
  color: #572a00;
  z-index: 1;
  font-weight: 400;
  text-shadow: 0px 0.2vw 0.2vw #ffffff, 0px -0.2vw 0.2vw yellow;
  font-family: Prompt;
  line-height: 1.1vw;
  margin-top: 6.5vw;
  // margin-left: -11vw;
  left: 50%;
  transform: translate(-50%);
  position: fixed;

  @media (orientation: portrait) {
    font-size: 2.8vw;
    margin-top: 12.5vw;
  }

  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 2.8vw;
    margin-top: 9.5vw;
  }

  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2.8vw;
          margin-top: 12.5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 2.8vw;
          margin-top: 9.5vw;
        `} */
`;

function JackpotCount(props) {
  const { t } = useTranslation();
  const counter = useSelector(selectCounterLP);
  const [counterStart, setCounterStart] = useState(counter.totalWin);
  const dispatch = useDispatch();
  const [emitError, setEmitError] = useState({ status: false, message: "" });
  //  console.log("counterStart", counterStart);
  // console.log("counter.totalWin", counter.totalWin);

  // useEffect(() => {
  //   return () => {
  //     setCounterStart(0);

  //   }
  // }, [])

  setTimeout(() => {
    setCounterStart(counter.totalWin);
  }, 5000);

  useEffect(() => {
    axios({
      method: "get",
      url:
        config.socketKafkaEndpoint +
        "web-backend-platform-kafka-service/totalwin",
      headers: {},
    })
      .then(function (response) {
        // console.log("response", response.data)

        if (
          typeof response === "undefined" ||
          typeof response.data === "undefined" ||
          typeof response.data.data === "undefined"
        ) {
        } else {
          if (response.data.status === true) {
            dispatch(actionCounterLP({ totalWin: response.data.data }));
          } else if (response.data.status === false) {
            setEmitError({ status: true, message: response.data.errorCode });
            // setTimeout(() => {
            //   setEmitError({ status: false })
            // }, 3000);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    // setTimeout(()=>{
    //   console.log("3000")
    //   dispatch(actionCounterLP({ totalWin: 10000}));
    // },5000)
  }, []);

  return (
    <>
      <Box>
        <Number>
          <CountUp
            start={parseInt(counterStart)} // add parseInt(fixed Warning error)
            end={parseInt(counter.totalWin)} // add parseInt(fixed Warning error)
            duration={1}
            // delay={5}
            separator=","
          />
        </Number>
        <Text>THB</Text>
      </Box>
      <Transition showPop={emitError.status} type="alert">
        <AlertMessage
          message={t(emitError.message)}
          onClick={() => {
            setEmitError({ status: false });
          }}
        />
      </Transition>
    </>
  );
}
export default JackpotCount;
