import React, { useRef, useState, useEffect, useContext } from "react";
import styled, { css, StyleSheetManager } from "styled-components";
import { useTranslation } from "react-i18next";
import { SocketContext } from "../../context/socket";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Popup from "../Popup";
import breakpoints from "../../LandingPageComponents/breakpoints";
import {
  selectuserProfile,
  actionClickSound,
} from "../../Reducers/userProfileSlice";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import UploadSlipItem from "./UploadSlipItem";
import Button from "../Button";
import {
  actionLastBankSlice,
  actionaccountlSlice,
  actionfloatinglSlice,
} from "../../Reducers/getLastBankSlice";
import {
  selectaccountlSliceSlice,
  selectfloatingBankSlice,
  selectselectedBankSlice,
} from "../../Reducers/getLastBankSlice";
import { config } from "../../config";

const BGPopup = styled.div`
  align-items: center;
  user-select: none;
  width: 100%;
  height: 100%; //fix
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 50;
  justify-content: center;
  @media ${breakpoints.device.line} {
    width: 180%;
    left: -40%;
    height: 130%;
    top: -6vw;
  }
`;

// const Popup = styled.div`
// user-select: none;
// position: relative;
// /* top: 5vw; */
// display: block;
// margin: 0 auto;
// background-color: #2f2d3b;
// border-radius: 4vw;
// border: solid 4vw;
// border-image-slice: 81;
// border-image-source: url("../${config.theme}/popup_frame_mini.png");
// // justify-content: center;
// // text-align: center;
// align-items: center;
// color: #bcb8d0;
// font-size: 2vw;
// z-index: 20;
// width: 32vw;
// align-self: center;
// padding-bottom:1vw;
// height: 35vw;
// p {
//   position: relative;
//   margin: 0;
// }
// h2 {
//   font-size: 2.6vw;
//   margin: -1vw 0 0 0;
// }
// h3 {
//   font-size: 1.2vw;
//   font-weight: normal;
//   margin: 0;
// }
// ${(props) => css`
//   width: ${props.width};
//   height: ${props.height};
// `}

// `;

const Close = styled.div`
  user-select: none;
  background-image: url("../${config.theme}/btn_close.png");
  position: absolute;
  width: 4.5vw;
  height: 4.9vw;
  background-repeat: no-repeat;
  right: -6vw;
  top: -5.5vw;
  background-size: contain;
  cursor: pointer;
  z-index: 40;
`;

const Title = styled.div`
  margin-left: ${(props) => (props.lang == "th" ? "0vw" : "-3.7vw")};
  // margin-left: -1.7vw;
  user-select: none;
  width: max-content;
  //   margin: 0 auto;
  margin-top: -4vw;
  color: white;
  height: 8vw;
  font-size: 2vw;
  z-index: 0;
  font-weight: 400;
  text-align: center;
  line-height: 5vw;
  padding: 0 9vw;
  background-clip: border-box, border-box, content-box;
  background-size: 8vw, 8vw, 0.99vw;
  // padding: 0% inherit;
  background-position: top 0 left 3vw, top 0 right 4vw, top 0 right 0;
  background-repeat: no-repeat, no-repeat, repeat-x;
  background-image: url("../${config.theme}/popup_title_bg_left.png"),
    url("../${config.theme}/popup_title_bg_right.png"),
    url("../${config.theme}/popup_title_bg_center.png");
`;

const Text = styled.div`
  color: white;
  font-size: 1.4vw;
  position: relative;
  // margin-top: 1vw;
  margin-left: 2.5vw;
  width: 55%;
  display: flex;
  user-select: none;
  //Portrait
  @media (orientation: portrait) {
    font-size: 2.4vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 1.5vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2.4vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 1.5vw;
        `} */
`;
const NameItem = styled.button`
  width: max-content;
  /* padding-left: 2vw; */
  // height: 2.6vw;
  align-items: center;
  display: flex;
  font-size: 1vw;
  text-align: initial;
  /* line-height: 3.5; */
  color: #fff;
  background-color: transparent;
  border: none;
  outline: transparent;
  cursor: pointer;
  user-select: none;
  z-index: 90;
  order: ${(props) => (props.isSelected ? "0" : "1")};
`;

const PrefixListBlack = styled.div`
  width: 32.9vw;
  position: absolute;
  overflow: hidden;
  user-select: none;
  margin-top: 0.3vw;
  ::-webkit-scrollbar {
    width: 10px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  height: ${(props) => (props.active ? "auto" : "0vw")};
  // overflow: ${(props) => (!props.active ? "hidden" : "scroll")};
  // overflow-x: auto;
  background-color: #000000;
  border: none;
  border-radius: 0.5vw;
  outline: transparent;
  flex-direction: column;
  z-index: 99;
  //Portrait
  @media (orientation: portrait) {
    width: 65.9vw;
    height: 13.3vw;
    border-radius: 1.5vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    width: 65.9vw;
    height: 10vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 65.9vw;
          height: 13.3vw;
          border-radius: 1.5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 65.9vw;
          height: 10vw;
        `} */
`;

const ContainerBank = styled.div`
  user-select: none;
  // width: 40vw;
  // margin-left: 2vw;
`;

const FontIconBank = styled(FontAwesomeIcon)`
  color: #fff;
  margin-left: 14vw;
  position: absolute;
  user-select: none;
  margin-top: 1.5vw;
  cursor: pointer;
  //Portrait
  @media (orientation: portrait) {
    margin-left: 28vw;
    cursor: pointer;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    margin-left: 46vw;
    cursor: pointer;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin-left: 28vw;
          cursor: pointer;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          margin-left: 46vw;
          cursor: pointer;
        `} */
`;

const PrefixItem = styled.button`
  font-size: 1.2vw;
  line-height: 3.5vw;
  display: flex;
  /* margin-top: 1vw; */
  color: #fff;
  background-color: transparent;
  border: none;
  user-select: none;
  outline: transparent;
  order: ${(props) => (props.isSelected ? "0" : "1")};
  margin-left: 0.4vw;
`;

const NameBank = styled.div`
  // display: flex;
  margin-top: 0.3vw;
  align-items: center;
  user-select: none;
`;

const InputBank = styled.div`
  margin: auto;
  width: 32.9vw;
  height: 5.3vw;
  margin-top: top;
  background-color: #000000;
  border: none;
  border-radius: 0.5vw;
  outline: transparent;
  display: flex;
  user-select: none;
  position: relative;
  //Portrait
  @media (orientation: portrait) {
    width: 65.9vw;
    height: 10.3vw;
    border-radius: 1.5vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    width: 65.9vw;
    height: 8vw;
    border-radius: 1vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 65.9vw;
          height: 10.3vw;
          border-radius: 1.5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 65.9vw;
          height: 8vw;
          border-radius: 1vw;
        `} */
`;
const ItemBank = styled.div`
  flex-direction: row;
  background-color: rgb(0, 0, 0);
  width: 32.9vw;
  // height:4.3vw;
  color: #ffffff;
  // margin-left: -2vw;
  border-radius: 0.5vw;
  border: none;
  margin-top: 0.3vw;
  height: auto;
  user-select: none;
`;

const BoxUpload = styled.div`
  position: relative;
  width: 36vw;
  height: 20vw;
  margin-left: -2vw;
  margin-top: 1vw;
  background: rgba(0, 0, 0, 0.3);
  border: 1.9px dashed #7f7b95;
  border-radius: 9px;
`;

const TextBank = styled.div`
  font-size: 0.8vw;
  margin-left: 4.3vw;
  width: 25vw;
  color: white;
  display: flex;
  align-items: center;
  user-select: none;
  text-align: start;
  //Portrait
  @media (orientation: portrait) {
    font-size: 2vw;
    margin-left: 12.3vw;
    width: 50vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 1.3vw;
    margin-left: 9.3vw;
    width: 40vw;
  }
  /* position: fixed; */

  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2vw;
          margin-left: 12.3vw;
          width: 50vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 1.3vw;
          margin-left: 9.3vw;
          width: 40vw;
        `} */
`;
const TextBankAccount = styled.div`
  font-size: 1vw;
  margin-left: 4.3vw;
  // margin-top: 0.5vw;
  display: flex;
  color: white;
  align-items: center;
  user-select: none;
  //Portrait
  @media (orientation: portrait) {
    font-size: 2vw;
    margin-left: 12.3vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 1.5vw;
    margin-left: 9.3vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2vw;
          margin-left: 12.3vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 1.5vw;
          margin-left: 9.3vw;
        `} */
`;
const TextSelectBank = styled.div`
  font-size: 1.2vw;
  color: gray;
  margin-left: 0.7vw;
  margin-top: 0.7vw;
  user-select: none;
`;

const TextAcount = styled.div`
  p {
    color: white;
    font-size: 1vw;
  }
  //Portrait
  @media (orientation: portrait) {
    p {
      color: white;
      font-size: 2vw;
      position: relative;
      width: 44vw;
    }
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    p {
      color: white;
      font-size: 1.5vw;
      position: relative;
      width: 45vw;
    }
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          p {
            color: white;
            font-size: 2vw;
            position: relative;
            width: 44vw;
          }
        `
      : window.appHeight() > window.innerWidth &&
        css`
          p {
            color: white;
            font-size: 1.5vw;
            position: relative;
            width: 45vw;
          }
        `} */
`;

const ImgBank = styled.img`
  width: 3.5vw;
  height: 3.5vw;
  margin-left: -16vw;
  margin-top: 0.4vw;
  position: absolute;
  //Portrait
  @media (orientation: portrait) {
    width: 10.5vw;
    height: 9vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    width: 7.5vw;
    height: 7.5vw;
    margin-left: -16vw;
    margin-top: 0vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 10.5vw;
          height: 9vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 7.5vw;
          height: 7.5vw;
          margin-left: -16vw;
          margin-top: 0vw;
        `} */
`;

const SelectBank = styled.img`
  width: 3.5vw;
  height: 3.5vw;
  margin-top: 0.2vw;
  //Portrait
  @media (orientation: portrait) {
    width: 10.5vw;
    height: 9vw;
    margin-left: 1vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    width: 7.5vw;
    height: 7.5vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 10.5vw;
          height: 9vw;
          margin-left: 1vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 7.5vw;
          height: 7.5vw;
        `} */
`;

function UploadSlip(props) {
  const [isActiveBack, setIsActiveBack] = useState(false);
  const [bank, setBank] = useState([]);
  const account = useSelector(selectaccountlSliceSlice);
  const floating = useSelector(selectfloatingBankSlice);
  const selectbank = useSelector(selectselectedBankSlice);
  const [emitError, setEmitError] = useState({ status: false, message: "" });
  const socket = useContext(SocketContext);
  const { t, i18n } = useTranslation();
  const [bankId, setBankId] = useState("");
  const [bankName, setBankName] = useState("");
  const [objectBank, setObjectBank] = useState({});
  const [bankImg, setBankImg] = useState("");
  const [check, setCheck] = useState(false);
  const [isCheck, setIsCheck] = useState(false);

  useEffect(() => {
    // socket.emit("getPlayerBankTopup", {
    // }, (body) => {
    //   console.log("getPlayerBankTopup", body)

    //   let Bank = []
    //   for (const item of body.results[0].floating_system) {
    //     Bank.push({
    //       bankShortName: item.short_name,
    //       bankName: item.bank_name + " " + item.bank_account,
    //       bankName: item.bank_name,
    //       bankName_en: item.bank_name_en,
    //       account_name: item.account_name,
    //       bank_account: item.bank_account,
    //       bankID: item._id
    //     })
    //   }
    //   setBank(Bank)
    //   console.log("body", body);
    //   // bankShortName,bankName,bankName_en
    //   // setBank(data.results.account_system.);
    //   // setBankId(data.results.data[0].bankID);

    // });

    // socket.emit("getAllBank", {}, (data) => {
    //   // console.log(data.results.data);
    //   if (
    //     typeof data == "undefined" ||
    //     typeof data.results == "undefined" ||
    //     typeof data.results.data == "undefined"
    //   ) {
    //   } else {
    //     if (data.status) {
    //       setBank(data.results.data);
    //       setBankId(data.results.data[0].bankID);
    //     } else if (data.status === false) {
    //       setEmitError({ status: true, message: data.errorCode });
    //       setTimeout(() => {
    //         setEmitError({ status: false });
    //       }, 3000);
    //     }
    //   }
    // });

    // alert(selectbank)

    let Bank = [];
    for (const item of selectbank == "floating" ? floating : account) {
      Bank.push({
        bankShortName: item.short_name,
        bankName: item.bank_name + " " + item.bank_account,
        bankName: item.bank_name,
        bankName_en: item.bank_name_en,
        account_name: item.account_name,
        bank_account: item.bank_account,
        bankID: item._id,
      });
      setObjectBank(Bank[0]);
      setBankId(item._id);
    }

    setBank(Bank);
  }, []);

  return (
    <BGPopup>
      <Popup
        width={
          window.appHeight() / window.innerWidth > config.verticalSizeiphone
            ? "73vw"
            : window.appHeight() > window.innerWidth
            ? "71vw"
            : window.innerWidth / window.appHeight() > 2.15
            ? "38vw"
            : "38vw"
        }
        height={
          window.appHeight() / window.innerWidth > config.verticalSizeiphone
            ? "67vw"
            : window.appHeight() > window.innerWidth
            ? "61vw"
            : window.innerWidth / window.appHeight() > 2.15
            ? "38vw"
            : "38vw"
        }
        title={t("Upload file")}
        closebuttonRight="-2vw"
        closebuttonTop="-2vw"
        closeClick={props.closeClick}
      >
        {/* <Title lang={i18n.language}>{t("deposit withdrawal")}</Title> */}
        {/* <Close
          onClick={() => {
            props.closeClick();
            // dispatch(actionClickSound());
          }}
        /> */}

        <ContainerBank>
          <Text>{t("destination bank account")}</Text>
          <InputBank>
            {isActiveBack == false ? (
              <div onClick={() => setIsActiveBack(true)}>
                <ItemBank>
                  {/* <TextSelectBank>{t("select bank account")}</TextSelectBank> */}
                  <NameBank>
                    {objectBank.bankShortName != "" ? (
                      <ImgBank
                        src={
                          "../" +
                          config.theme +
                          "/bicon_" +
                          objectBank.bankShortName +
                          ".png"
                        }
                      />
                    ) : null}
                    <FontIconBank icon={faAngleDown} />{" "}
                  </NameBank>
                </ItemBank>

                {objectBank == "" ? (
                  <>
                    <ItemBank>
                      <TextSelectBank>
                        {t("select bank account")}
                      </TextSelectBank>
                      <NameBank>
                        {bankImg != "" ? (
                          <ImgBank
                            src={
                              bankImg === ""
                                ? ""
                                : "../" +
                                  config.theme +
                                  "/bicon_" +
                                  bankImg +
                                  ".png"
                            }
                          />
                        ) : null}
                        <FontIconBank icon={faAngleDown} />{" "}
                      </NameBank>
                    </ItemBank>
                  </>
                ) : (
                  <>
                    {i18n.language == "th" ? (
                      <>
                        <TextBank>{objectBank.bankName}</TextBank>
                        <TextBank>{objectBank.account_name}</TextBank>
                        <TextBankAccount>
                          {objectBank.bank_account}
                        </TextBankAccount>
                      </>
                    ) : (
                      <>
                        <TextBank>{objectBank.bankName_en}</TextBank>
                        <TextBank>{objectBank.account_name}</TextBank>
                        <TextBankAccount>
                          {objectBank.bank_account}
                        </TextBankAccount>
                      </>
                    )}
                  </>
                )}
              </div>
            ) : (
              <></>
            )}

            <PrefixListBlack
              active={isActiveBack}
              onClick={() => setIsActiveBack(!isActiveBack)}
            >
              <FontIconBank icon={faAngleDown} />

              {bank.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    marginLeft: "0.3vw",
                    cursor: "pointer",
                    marginTop: "0.2vw",
                    marginBottom: "0.5vw",
                  }}
                  onClick={() => {
                    setBankId(item.bankID);
                    setObjectBank(item);
                    setBankName(
                      i18n.language == "th" ? item.bankName : item.bankName_en
                    );
                    setBankImg(item.bankShortName);
                  }}
                >
                  <SelectBank
                    // id="30000000"
                    src={
                      item.bankShortName === ""
                        ? ""
                        : "../" +
                          config.theme +
                          "/bicon_" +
                          item.bankShortName +
                          ".png"
                    }
                  />

                  <NameItem isSelected={false} key={index}>
                    {i18n.language == "th" ? (
                      <TextAcount>
                        <p>{item.bankName}</p>
                        <p>{item.account_name}</p>
                        <p>{item.bank_account}</p>
                      </TextAcount>
                    ) : (
                      <TextAcount>
                        <p>{item.bankName_en}</p>
                        <p>{item.account_name}</p>
                        <p>{item.bank_account}</p>
                      </TextAcount>
                    )}
                  </NameItem>
                </div>
              ))}
            </PrefixListBlack>
          </InputBank>
        </ContainerBank>

        <UploadSlipItem
          setIsCheck={setIsCheck}
          bankId={bankId}
          closeClick={props.closeClick}
        />
      </Popup>
    </BGPopup>
  );
}
export default UploadSlip;
