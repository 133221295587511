import React from 'react'
import styled from 'styled-components';
import breakpoint from "../breakpoints";

const Box = styled.div`
    width:15vw;
    display:block;
    // cursor: pointer;
    position:relative;
    > img {
        width: 6vw;
    }
    
    @media ${breakpoint.device.ipad} {
        width:17vw;
    display:block;
    // cursor: pointer;
    position:relative;
        > img {
        width: 9vw;
    }
    }
@media ${breakpoint.device.mobile} {
        width: 100%; 
        /* display: flex; */
        justify-content: center;
        > img {
            width: initial;
            height: 21.74vw;
            margin: 2vw;
        }
    }
`;

const Title = styled.div`
    color:white;
    position:relative;
    font-size:1.5vw;
    @media ${breakpoint.device.mobile} {
        font-size: 4.83vw;
        line-height: 7vw;
    }
`;

const Detail = styled.div`
    width:10vw;
    color:white;
    // padding-top:0.5vw;
    font-size:0.7vw;
    
    @media ${breakpoint.device.ipad} {
        width:14vw;
        color: rgba(255,255,255,0.7);
    // padding-top:0.5vw;
    font-size:1.3vw;
    }
    @media ${breakpoint.device.mobile} {
        width:50%;
        font-size: 3vw;
        text-align: start;
        color: rgba(255,255,255,0.6);
        line-height: 4vw;
        /* margin-top: 2vw; */
        text-align: center;
    }
`;

function Aboutitem(props) {
    let img = ""


    if (props.img === "item1") {
        img = "../../lpimages/why_item_1.png";

    }
    else if (props.img === "item2") {
        img = "../../lpimages/why_item_2.png";

    }
    else if (props.img === "item3") {
        img = "../../lpimages/why_item_3_new.png";


    }
    else if (props.img === "item4") {
        img = "../../lpimages/why_item_4.png";

    }
    return (
        <Box>
            <img src={img} alt="" />

            {/* <Title>{props.title}</Title> */}
            <Detail>
                <Title>{props.title}</Title>
                <label>{props.detail}</label>
            </Detail>

        </Box>
    );
}
export default Aboutitem;