import { createSlice } from '@reduxjs/toolkit';

export const setting = createSlice({
    name: 'setting',
    initialState: {
        depositBonus: false,
        music: false,
        soundFX: false,
    },
    reducers: {
        depositTrigger: (state) => {
            state.depositBonus = !state.depositBonus
        },
        musicTrigger: (state) => {
            state.music = !state.music
        },
        soundTrigger: (state) => {
            state.soundFX = !state.soundFX
        },
        setDeposit: (state, action) => {
            state.depositBonus = action.payload
        },
        setMusic: (state, action) => {
            state.music = action.payload
        },
        setSoundFX: (state, action) => {
            state.soundFX = action.payload
        },
    }
})

export const { depositTrigger, musicTrigger, soundTrigger, setDeposit, setMusic, setSoundFX } = setting.actions;
export const depositBonus = state => state.setting.depositBonus;
export const music = state => state.setting.music;
export const soundFX = state => state.setting.soundFX;


export default setting.reducer;