import React, { useState, useContext, useEffect } from "react";
import MenuItem from "./MenuItem";
import { useSelector, useDispatch } from "react-redux";
import EventBanner from "./EventBanner";
import styled, { css, keyframes } from "styled-components";
import { useTranslation } from "react-i18next";
import { selectPopupList, actionPopupList } from "../../Reducers/popupSlice";
import { tada } from "react-animations";
import { config } from "../../config";
import { SocketContext } from "../../context/socket";

const MenuContainer = styled.div`
  width: 35%;
  height: 100%;
  position: relative;
  left: 1%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 94%;
          height: 50%;
          position: relative;
          left: 0;
          top: -11vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          flex-direction: row;
          width: 100%;
          left: 0%;
          justify-content: center;
          height: 28%;
        `}
`;

const BoxUpdate = styled.div`
  width: 100%;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css``
      : window.appHeight() > window.innerWidth &&
        css`
          width: 46.5%;
          height: 97%;
          margin-left: -1.5%;
        `}
`;

const Spinix = styled.img`
  margin-bottom: 0%;
  width: 70%;
  position: relative;

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin-bottom: -1%;
          width: 40%;
          position: relative;
          z-index: 21;
          top: 1.5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          margin-bottom: -1%;
          width: 40%;
        `}
`;

const Title = styled.div`
  width: 92.5%;
  height: 4.5vw;
  background: #ff8300;
  box-shadow: inset 0px 0px 10px 4px rgba(250, 255, 0, 0.9);
  border-radius: 1vw;
  margin: -1.3vw auto 0 auto;
  p {
    font-size: 1.5vw;
    padding: 1.2vw;
    font-weight: 700;
  }

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 95.5%;
          height: 8.5vw;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          p {
            font-size: 3.25vw;
          }
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 100%;
          height: 18%;
          p {
            font-size: 2.25vw;
            padding: 0.8vw;
          }
        `}
`;

const Box = styled.div`
  width: 92.5%;
  height: 7vw;
  background: #000000;
  border-radius: 0 0 1.5vw 1.5vw;
  color: #fff;
  padding-top: 2vw;
  margin: -1vw auto 0 auto;
  position: relative;
  z-index: -1;
  p {
    font-size: 1.1vw;
    white-space: pre-line;
    padding: 0 1vw 0 1vw;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 95.5%;
          height: 9.5vw;
          display: flex;
          align-items: center;
          justify-content: center;
          p {
            font-size: 3vw;
          }
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 100%;
          height: 34%;
          p {
            font-size: 2vw;
          }
        `}
`;

const BoxMenu = styled.div`
  width: 100%;
  height: 50%;
  text-align: right;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 1vw;
    height: 0vw;
    z-index: 10;
  }
  ::-webkit-scrollbar-thumb {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 10;
    position: absolute;
    width: 11px;
    height: 80px;
    background: linear-gradient(180deg, #e13b00 38.02%, #900000 100%);
    box-shadow: inset 0px 0px 4px 2px #ff814d;
    border-radius: 10px;
  }
  display: flex;
  /* flex-direction: ${(props) => (props.ListLength > 4 ? "row" : "column")}; */
  flex-wrap: wrap;
  justify-content: ${(props) => (props.ListLength > 4 ? "center" : "start")};
  align-items: center;
  align-content: flex-start;
  left: 1vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          top: 80vw;
          flex-wrap: wrap;
          left: 1vw;
          justify-content: flex-start;
          padding: 1vw 0vw 0vw 0vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 45%;
          height: 97%;
          justify-content: center;
        `}
`;

const NoticeAnimation = keyframes`${tada}`;

const Dot = styled.img`
  user-select: none;
  position: absolute;
  z-index: 2;
  right: 0%;
  top: -10%;
  width: 15%;
  animation: ${NoticeAnimation} 1.5s linear infinite;
  pointer-events: none;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 3.5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 3.5vw;
        `}
`;

const BoxControl = styled.div`
  user-select: none;
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          flex-direction: column;
          justify-content: center;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          flex-direction: column;
          justify-content: center;
        `}
`;

function Menu() {
  const { t, i18n } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const popupList = useSelector(selectPopupList);
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();

  const updateNoti = (i) => {
    let newPopupList = JSON.parse(JSON.stringify(popupList));
    newPopupList[i].notification = 0;
    dispatch(actionPopupList(newPopupList));
  };

  useEffect(() => {
    if (popupList.length > 0) {
      if (popupList[0].notification === 1) {
        socket.emit(
          "updatepopupnotification",
          {
            no: popupList[0].no,
          },
          (data) => {
            // console.log("Update 1st Popup Notification", data);
            updateNoti(0);
          }
        );
      }
    }
  }, [popupList]);

  const moveMenu = (i) => {
    // console.log(i, document.getElementById("menu" + i));
    document.getElementById("menu" + i).scrollIntoView();
  };

  useEffect(() => {
    if (popupList.length > 0) {
      if (popupList[selectedIndex].notification === 1 && selectedIndex !== 0) {
        socket.emit(
          "updatepopupnotification",
          {
            no: popupList[selectedIndex].no,
          },
          (data) => {
            // console.log("updatepopupnotification", data);
            updateNoti(selectedIndex);
          }
        );
      }
    }
  }, [selectedIndex]);

  return (
    <BoxControl>
      <MenuContainer>
        <BoxUpdate>
          <Spinix src="../../images/shiba_spinix_update.png" />
          <Title>
            <p>SPINIX UPDATE</p>
          </Title>
          <Box>
            <p>{t("spinix update text")}</p>
          </Box>
        </BoxUpdate>

        <BoxMenu ListLength={popupList.length}>
          {popupList.length > 0 ? (
            <>
              {popupList.map((item, index) => (
                <React.Fragment key={"item_" + index}>
                  <MenuItem
                    id={"menu" + index}
                    i={index}
                    onClick={() => {
                      setSelectedIndex(index);
                    }}
                    selectedIndex={selectedIndex}
                    label={i18n.language === "th" ? item.title : item.title_en}
                    noti={item.notification}
                  >
                    {item.notification === 1 && index !== 0 ? (
                      <Dot src={"../" + config.theme + "/notice_dot.png"} />
                    ) : (
                      ""
                    )}
                  </MenuItem>
                </React.Fragment>
              ))}
            </>
          ) : (
            ""
          )}
        </BoxMenu>
      </MenuContainer>

      {popupList.length > 0 ? (
        <EventBanner
          item={popupList[selectedIndex]}
          index={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          total={popupList.length}
          moveMenu={moveMenu}
        />
      ) : (
        ""
      )}
    </BoxControl>
  );
}

export default Menu;
