import React, { useState, useEffect, useContext, useRef } from "react";
import Popup from "../Popup";
import { useTranslation } from "react-i18next";
import { SocketContext } from "../../context/socket";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { getFullUrl, getJWT, config } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { actionGamePlay } from "../../Reducers/gamePlaySlice";
import { actionLoading } from "../../Reducers/loadingSlice";
import { actionAlert } from "../../Reducers/alertSlice";
import { useHistory } from "react-router-dom";
import { selectuserProfile } from "../../Reducers/userProfileSlice";
import { actionPopup } from "../../Reducers/popupSlice";
import {
  setFloatingValue,
  setFloatingStatus,
  setFloatingLabel,
} from "../../Reducers/floatingInputSlice";
import { selectGameList } from "../../Reducers/gameListSlice";
import ScaleFrame from "../ScaleFrame";

const SearchContainer = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%;
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 30;
  align-items: center;
  justify-content: center;
`;

const BgClose = styled.div`
  user-select: none;
  width: 100vw;
  background-color: transparent;
  z-index: 20;
  left: 0%;
  top: 0%;
  position: fixed;
`;

const SearchBox = styled.div`
  user-select: none;
  display: flex;
  justify-content: space-around;
  margin-top: 1vw;

  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            margin-top: auto;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            margin-top: 4vw;
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            margin-top: auto;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            margin-top: 4vw;
          }
        `}/* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin-top: auto;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          margin-top: 4vw;
        `} */
`;

const SearchText = styled.div`
  width: 53.07vw;
  height: 4.5vw;
  position: relative;
  i {
    font-size: 2vw;
    line-height: 4.5vw;
    position: absolute;
    top: 0;
    right: 1vw;
  }

  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            width: 50.07vw;
            height: 8vw;
            i {
              font-size: 3vw;
              top: 2vw;
            }
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            width: 36.07vw;
            height: 5.65vw;
            i {
              top: 0.7vw;
            }
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            width: 50.07vw;
            height: 8vw;
            i {
              font-size: 3vw;
              top: 2vw;
            }
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            width: 36.07vw;
            height: 5.65vw;
            i {
              top: 0.7vw;
            }
          }
        `}/* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 50.07vw;
          height: 8vw;
          i {
            font-size: 4vw;
            top: 2vw;
            right: -1vw;
          }
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 38.07vw;
          height: 5.65vw;
          i {
            top: 0.7vw;
          }
        `} */
`;

const SearchInput = styled.input`
  width: 96%;
  height: 100%;
  background-color: #000;
  color: rgba(255, 255, 255, 1);
  outline: none;
  border: none;
  border-radius: 1vw;
  font-size: 1.9vw;
  padding: 0vw 1vw;

  ${() =>
    window.deviceNotApple
      ? css`
          @media screen and (max-device-aspect-ratio: 13/9) {
            font-size: 3vw;
          }
        `
      : css`
          @media (orientation: portrait) {
            font-size: 3vw;
          }
        `}/* ${() =>
    window.appHeight() > window.innerWidth &&
    css`
      font-size: 3vw;
    `} */
`;

const DropDown = styled.div`
  width: 30.58vw;
  height: 2.79vw;
  background-color: #000000;
  color: rgba(255, 255, 255, 0.4);
  position: relative;
  border-radius: 1vw;
  font-size: 1.9vw;
  text-align: left;
  padding: 1vw;
  cursor: pointer;

  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            height: 6vw;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            height: 0.3vw;
            font-size: 2.5vw;
            padding: 2.65vw 1vw;
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            height: 6vw;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            height: 0.3vw;
            font-size: 2.5vw;
            padding: 2.65vw 1vw;
          }
        `}/* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          height: 6vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          height: 0.3vw;
          font-size: 2.5vw;
          padding: 2.65vw 1vw;
        `} */
`;

const DropDownList = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  background-color: #000000;
  width: 100%;
  height: 24vw;
  padding-bottom: 1vw;
  border-radius: 0vw 0vw 1vw 1vw;
  overflow-y: scroll;
  /* Scroll width */
  ::-webkit-scrollbar {
    width: 1vw;
  }
  /* Scroll Track */
  ::-webkit-scrollbar-track {
    margin: 0.5vw;
  }
  /* Scroll Handle */
  ::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #e13b00 38.02%, #900000 100%);
    box-shadow: inset 0px 0px 4px 2px #ff814d;
    border-radius: 10px;
  }
  /* Scroll Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(180deg, #e13b00 38.02%, #900000 100%);
  }

  ${() =>
    window.deviceNotApple
      ? css`
          @media screen and (max-device-aspect-ratio: 13/9) {
            height: 43vw;
            top: 85%;
          }
        `
      : css`
          @media (orientation: portrait) {
            height: 43vw;
            top: 85%;
          }
        `}/* ${() =>
    window.appHeight() > window.innerWidth &&
    css`
      height: 43vw;
      top: 85%;
    `} */
`;

const DropDownButton = styled.div`
  line-height: 2.79vw;
  :hover {
    color: white !important;
  }

  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            line-height: 6.79vw;
            font-size: 3vw;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            line-height: 0.79vw;
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            line-height: 6.79vw;
            font-size: 3vw;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            line-height: 0.79vw;
          }
        `}/* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? `
      line-height: 6.79vw;
      font-size:3vw;
      `
      : window.appHeight() > window.innerWidth &&
        `
      line-height: 0.79vw;
      `} */
`;

const DropDownItem = styled.div`
  margin-left: 1vw;
  :hover {
    color: #fff;
  }

  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            font-size: 3vw;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            font-size: 2vw;
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            font-size: 3vw;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            font-size: 2vw;
          }
        `}/* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 3vw;
        `
      : window.appHeight() > window.innerWidth &&
        `
 font-size: 2vw;
 `} */
`;

const FontIcon = styled(FontAwesomeIcon)`
  float: right;
  color: #fff;
  margin-top: 0.5vw;

  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            font-size: 4vw;
            margin-top: 1.25vw;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            font-size: 2.5vw;
            margin-top: -0.75vw;
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            font-size: 4vw;
            margin-top: 1.25vw;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            font-size: 2.5vw;
            margin-top: -0.75vw;
          }
        `}/* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 4vw;
          margin-top: 1.25vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 2.5vw;
          margin-top: -0.75vw;
        `} */
`;

const GameListBox = styled.div`
  user-select: none;
  width: 90vw;
  background-color: #000;
  margin: 1vw auto;
  overflow-y: scroll;
  border-radius: 1vw;
  text-align: ${(props) => (props.itemsLength > 5 ? "center" : "left")};
  position: relative;
  display: flex;
  flex-wrap: wrap;
  ::-webkit-scrollbar {
    width: calc(100vw * 0.01);
  }
  /* Scroll Track */
  ::-webkit-scrollbar-track {
    margin: 0.5vw 1vw;
    // background: red;
  }
  /* Scroll Handle */
  ::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #e13b00 38.02%, #900000 100%);
    box-shadow: inset 0px 0px 4px 2px #ff814d;
    border-radius: 10px;
    // border: solid 1vw black;
  }
  /* Scroll Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(180deg, #e13b00 38.02%, #900000 100%);
  }
  height: ${(props) =>
    props.height > props.width
      ? "85%"
      : props.width / props.height > 2.15
      ? "27vw"
      : "31vw"};

  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            width: 86vw;
            height: 82%;
            position: relative;
            flex-flow: wrap;
            display: flow-root;
            text-align: start;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            width: 74vw;
            height: 82%;
            text-align: start;
            display: flow-root;
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            width: 86vw;
            height: 82%;
            position: relative;
            flex-flow: wrap;
            display: flow-root;
            text-align: start;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            width: 74vw;
            height: 82%;
            text-align: start;
            display: flow-root;
          }
        `}/* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? `
 width: 86vw;
 height: 82%;
    position: relative;
    flex-flow: wrap;
    display: flow-root;
    text-align: start;    
 `
      : window.appHeight() > window.innerWidth &&
        `
 width: 74vw;
 height: 82%;
text-align: start;
display: flow-root;
 `} */
`;

const IMG = styled.img`
  user-select: none;
  width: 18%;
  cursor: pointer;
  margin: 0.5vw;
  height: 23vw;

  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            width: 32%;
            height: 38vw;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            width: 23%;
            height: 26vw;
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            width: 32%;
            height: 38vw;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            width: 23%;
            height: 26vw;
          }
        `}/* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 32%;
          height: 38vw;
        `
      : window.appHeight() > window.innerWidth &&
        `
 width: 23%;
 height:26vw ;
 `} */
`;

const Text = styled.p`
  width: 100%;
  text-align: center;
  color: white;
  font-size: 1.9vw;
  line-height: 15;

  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            font-size: 3vw;
            line-height: 25;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            line-height: 20;
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            font-size: 3vw;
            line-height: 25;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            line-height: 20;
          }
        `}
`;

function Search(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const socket = useContext(SocketContext);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [providerName, setProviderName] = useState("");
  const items = useSelector(selectGameList);
  const [searchField, setSearchField] = useState("");
  const [search, setSearch] = useState([]);
  const [options, setOptions] = useState([]);
  const [isMobile, setIsMobile] = useState(0);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const profile = useSelector(selectuserProfile);
  const ref = useRef();
  const inputRef = useRef(null);

  const onOptionClicked = (value, name) => () => {
    // console.log("provider name & value:", name, value);
    if (typeof name != "undefined") {
      setSelectedProvider(value);
      setProviderName(name);
    } else {
      setSelectedProvider(value);
      setProviderName("");
      // setSearchField("");
      // inputRef.current.value = "";
    }
    setIsOpen(false);
  };

  useEffect(() => {
    socket.emit("getprovidergamelist", {}, (body) => {
      setOptions(body.results);
      // console.log("getprovidergamelist", body.results);
    });

    if (/Android|webOS|iPhone|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setIsMobile(1);
    } else {
      setIsMobile(0);
    }
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen]);

  const searchGame = () => {
    // if ((providerName == "" && searchField.length >= 3) || providerName != "") {
    if (searchField.length >= 3) {
      setSearch(
        items.filter((a) => {
          return (
            a.gameName.toLowerCase().indexOf(searchField.toLowerCase()) > -1 &&
            (a.partner === selectedProvider || selectedProvider === "")
          );
        })
      );
      if (isFirstTime) {
        setIsFirstTime(false);
      }
    }
  };

  return (
    <SearchContainer>
      <ScaleFrame>
        <BgClose
          onClick={() => {
            props.closeClick();
          }}
        />
        <Popup
          title={t("search")}
          width={
            window.appHeight() > window.innerWidth && window.innerWidth <= 480
              ? "89vw"
              : window.appHeight() > window.innerWidth
              ? "78vw"
              : "94vw"
          }
          height={
            window.appHeight() > window.innerWidth && window.innerWidth <= 480
              ? "118vw"
              : window.appHeight() > window.innerWidth
              ? "93vw"
              : window.innerWidth / window.appHeight() > 2.15
              ? "40vw"
              : "45vw"
          }
          bartitleLeft="25vw"
          closebuttonRight="-2%"
          closebuttonTop={
            window.appHeight() > window.innerWidth ? "-2%" : "-4%"
          }
          closeClick={() => {
            props.closeClick();
          }}
          style={{
            zIndex: "999",
            position: "relative",
            marginTop: window.onKeyBoard && window.deviceNotApple ? "5vw" : "",
          }}
        >
          <SearchBox>
            <DropDown ref={ref}>
              <DropDownButton onClick={() => setIsOpen(!isOpen)}>
                {/* {providerName || "Spinix"} */}
                {providerName || t("all provider")}{" "}
                <FontIcon icon={isOpen ? faAngleUp : faAngleDown} />
              </DropDownButton>
              {isOpen && (
                <DropDownList>
                  <DropDownItem key={-1} onClick={onOptionClicked("")}>
                    {t("all provider")}
                  </DropDownItem>

                  {options.map((option, index) => (
                    <DropDownItem
                      key={index}
                      onClick={onOptionClicked(
                        option.partner,
                        option.fullname_en
                      )}
                    >
                      {option.fullname_en}
                    </DropDownItem>
                  ))}
                </DropDownList>
              )}
            </DropDown>
            <SearchText>
              <SearchInput
                ref={inputRef}
                // placeholder={
                //   providerName == "" ? t("enter game name") : t("search game")
                // }
                placeholder={t("enter game name")}
                onFocus={(e) => {
                  window.isEdit = true;
                  window.onKeyBoard = true;
                  dispatch(setFloatingLabel(t("search game")));
                  dispatch(setFloatingStatus(true));
                  dispatch(setFloatingValue(e.target.value));
                }}
                onBlur={() => {
                  window.isEdit = false;
                  window.onKeyBoard = false;
                  dispatch(setFloatingStatus(false));
                }}
                onChange={(e) => {
                  setSearchField(e.target.value);
                  dispatch(setFloatingValue(e.target.value));
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    searchGame();
                  }
                }}
              ></SearchInput>
              <i
                // style={{
                //   color:
                //     (providerName == "" && searchField.length >= 3) ||
                //     providerName !== ""
                //       ? "#ff4c00"
                //       : "#fff",
                // }}
                style={{
                  color:
                    searchField.length >= 3
                      ? "#ff4c00"
                      : "rgb(255 255 255 / 50%)",
                  cursor: searchField.length >= 3 ? "pointer" : "initial",
                }}
                className="icon-ico_search"
                onClick={() => {
                  searchGame();

                  // if (
                  //   (providerName == "" && searchField.length > 4) ||
                  //   providerName != ""
                  // )
                  //   setSearch(
                  //     items.filter((a) => {
                  //       return (
                  //         a.gameName
                  //           .toLowerCase()
                  //           .indexOf(searchField.toLowerCase()) > -1 &&
                  //         (a.partner === selectedProvider ||
                  //           selectedProvider === "")
                  //       );
                  //     })
                  //   );
                }}
              />
            </SearchText>
          </SearchBox>
          <GameListBox
            width={window.innerWidth}
            height={window.appHeight()}
            itemsLength={search.length}
            // itemsLength={
            //   items.filter((a) => {
            //     return (
            //       a.gameName.toLowerCase().indexOf(searchField.toLowerCase()) >
            //         -1 &&
            //       (a.partner === selectedProvider || selectedProvider === "")
            //     );
            //   }).length
            // }
          >
            {search.length > 0 ? (
              search.map((item, index) => {
                return (
                  <IMG
                    key={index}
                    src={item.img}
                    onClick={() => {
                      // if (
                      //   item.categories.indexOf("casino") > -1 &&
                      //   profile.promotion_status === true
                      // ) {
                      //   dispatch(
                      //     actionConfirmPopup({
                      //       status: true,
                      //       message: "alert live casino",
                      //     })
                      //   );
                      //   return;
                      // }

                      // if (
                      //   item.categories.indexOf("casino") > -1 &&
                      //   profile.cashback > 0
                      // ) {
                      //   // setCashbackPopup(true);
                      //   dispatch(actionPopup({ cashback: true }));
                      //   return;
                      // }

                      const setTimeCloseLoad = (time, fn) => {
                        setTimeout(() => {
                          dispatch(actionLoading(true));
                          if (fn) return fn();
                        }, time);
                      };
                      setTimeCloseLoad(5000);
                      if (
                        profile.isTelGuest === false &&
                        profile.isguest === true
                      ) {
                        dispatch(
                          actionPopup({
                            guestCheck: true,
                            message: "you have not yet subscribed",
                          })
                        );
                      } else if (
                        profile.isTelGuest === true &&
                        profile.isguest === true
                      ) {
                        dispatch(
                          actionPopup({
                            bankCheck: true,
                            message: "bank account game",
                          })
                        );
                      } else {
                        socket.emit(
                          "getGameUrl",
                          {
                            gameid: item.gameId,
                            platform: isMobile,
                            url: /Android|webOS|iPhone|IEMobile|Opera Mini/i.test(
                              navigator.userAgent
                            )
                              ? window.location.protocol + "//" + window.location.host
                              : window.location.protocol +
                                "//" +
                                window.location.host +
                                "/back.html?token=" +
                                getJWT(),
                          },
                          (body) => {
                            // console.log("body;", body);

                            if (
                              typeof body === "undefined" ||
                              typeof body.results === "undefined" ||
                              typeof body.results.data === "undefined"
                            ) {
                              console.log(body);
                            } else {
                              if (body.status) {
                                if (
                                  /Android|webOS|iPhone|IEMobile|Opera Mini/i.test(
                                    navigator.userAgent
                                  )
                                ) {
                                  window.location.href = body.results.data.url;
                                } else {
                                  dispatch(
                                    actionGamePlay({
                                      isPlay: true,
                                      gameUrl: body.results.data.url,
                                    })
                                  );

                                  setTimeCloseLoad(1000, () => {});
                                  history.push("/gameplay?token=" + getJWT());
                                }
                              } else if (body.status === false) {
                                setTimeCloseLoad(0, () => {
                                  dispatch(
                                    actionAlert({
                                      isAlert: true,
                                      errorCode: body.errorCode,
                                    })
                                  );
                                  setTimeout(() => {
                                    dispatch(
                                      actionGamePlay({
                                        gameUrl: "",
                                        isPlay: false,
                                      })
                                    );
                                    dispatch(
                                      actionAlert({
                                        isAlert: false,
                                        errorCode: "",
                                      })
                                    );
                                  }, 3000);
                                });
                              }
                            }
                          }
                        );
                      }
                    }}
                  />
                );
              })
            ) : search.length <= 0 && isFirstTime ? (
              <Text>{t("please enter game name")}</Text>
            ) : (
              <Text>{t("cannot find")}</Text>
            )}
          </GameListBox>
        </Popup>
      </ScaleFrame>
    </SearchContainer>
  );
}

export default Search;
