import { createSlice } from "@reduxjs/toolkit";
export const rewardPopupSlice = createSlice({
  name: "rewardPopup",
  initialState: {
    reward: {
      status: false,
      reward: 0,
      day: 0,
    },
  },
  reducers: {
    actionRewardPopup: (state, action) => {
      state.reward = { ...state.reward, ...action.payload };
    },
  },
});

export const { actionRewardPopup } = rewardPopupSlice.actions;
export const selectRewardPopup = (state) => state.rewardPopup.reward;
export default rewardPopupSlice.reducer;
