import React from 'react';
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import breakpoint from "./breakpoints";

const Button = styled.button`
    width: max-content;
    background-color: #13131E;
    border: 0.2vw solid #26293A;
    border-radius: 0.75vw;
    padding: 0.68vw 0.52vw;
    display: flex;
    align-items: center;
    cursor: pointer;
    /* width:15vw; */
    > p {
        font-size: 1vw !important;
        color: #E1E3ED !important;
        white-space: nowrap !important;
    }
    @media ${breakpoint.device.mobile} {
        width: auto;
        border-radius: 2vw;
        padding: 1.68vw 1.52vw;
        align-items: normal;
        > p {
            font-size: 3.14vw !important;
            white-space: nowrap !important;
            padding-left: 4vw !important;
        }
    }
`

const Icon = styled.div`
    width: max-content;
    display: grid;
    grid-template-columns: [first] 0.45vw  [line2] 0.45vw;
    grid-template-rows:[row1-start] 0.45vw [row1-end] 0.45vw;
    gap: 0.4vw 0.4vw;
    @media ${breakpoint.device.mobile} {
        gap: 2vw 2vw;
    }
`

const Box = styled.span`
    border-radius: 0.2vw;
    width: 0.65vw;
    height: 0.65vw;
    /* margin: 0.1vw; */
    :nth-child(1), :nth-child(4){
        background-color:#444963;
    }
    :nth-child(2), :nth-child(3){
        background-color:#26293A;
    }
    @media ${breakpoint.device.mobile} {
        border-radius: 0.75vw;
        width: 2.06vw;
        height: 2.06vw;
    }
`

function MoreButton(props) {
    const { t } = useTranslation();
    return (
        <Button onClick={props.onClick}>
            <Icon>
                <Box></Box>
                <Box></Box>
                <Box></Box>
                <Box></Box>
            </Icon>
            <p>{t("More")} {props.label}</p>
            {/* <p>{t("More")}</p> */}
        </Button>
    )
}

export default MoreButton
