import React, { useState, useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import ConfirmPopup from "../Dialog/ConfirmPopup";
import { Transition } from "../../config";
import { SocketContext } from "../../context/socket";
import { getFullUrl, redirectToLogin } from "../../config";
import { selectuserProfile } from "../../Reducers/userProfileSlice";
import {
  setFloatingValue,
  setFloatingStatus,
  setFloatingLabel,
} from "../../Reducers/floatingInputSlice";
import Loading from "../Loading";
import AlertMessage from "../Dialog/AlertMessage";
import ConfirmationRegister from "./ConfirmationRegister";
// import { selectCheckFTUEPopup } from "../../Reducers/checkFTUEPopupSlice";
import { config, redirect2Page } from "../../config";
import Condition from "./Condition";
import Language from "../Language";

// const winH = window.innerHeight;
// const winW = window.innerWidth;

// console.log("winH", winH);
window.isLandingPage = true;

const BG = styled.div`
  user-select: none;
  width: 100vw;
  height: ${(props) => props.uiHeight + "px"};
  left: 0%;
  top: 0%;
  background-color: #302e3c;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0 auto;
`;

// const PrefixList = styled.div`
//   width: 12vw;
//   margin-left: -4.1vw;
//   height: ${(props) =>
//     props.active
//       ? "max-content"
//       : winH / winW > config.verticalSizeiphone
//       ? "6.5vw"
//       : winH > winW
//       ? "4.3vw"
//       : "3.3vw"};
//   overflow: ${(props) => (!props.active ? "hidden" : "")};
//   background-color: #000000;
//   border: none;
//   border-radius: 0.5vw;
//   outline: transparent;
//   display: flex;
//   flex-direction: column;
//   position: absolute;
//   z-index: 2;

//   ${() =>
//     winH / winW > config.verticalSizeiphone
//       ? css`
//           width: 31.25vw;
//           margin-left: -4.1vw;
//           height: 15vw;
//           border-radius: 3vw;
//         `
//       : winH > winW &&
//         css`
//           width: 11vw;
//           margin-left: -4.1vw;
//           /* height: 4.3vw; */
//         `}
// `;

const PrefixListBlack = styled.div`
  width: 100%;
  overflow: scroll;
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  height: ${(props) => (props.active ? "min(50vw, 300px)" : "0vw")};
  overflow: ${(props) => (!props.active ? "hidden" : "scroll")};
  overflow-x: hidden;
  background-color: #000000;
  border: none;
  border-radius: min(3vw, 18px);
  outline: transparent;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 99;
`;

const PrefixItem = styled.button`
  font-size: min(5vw, 30px);
  line-height: min(6vw, 36px);
  width: 100%;
  height: min(12vw, 72px);
  border-radius: min(3vw, 18px);
  display: flex;
  padding-left: 10%;
  align-items: center;
  color: #fff;
  background-color: transparent;
  border: none;
  outline: transparent;
  order: ${(props) => (props.isSelected ? "0" : "1")};
  position: relative;
`;

// const Input = styled.input`
//   width: 32vw;
//   height: 2.2vw;
//   color: #fff;
//   font-size: 1.2vw;
//   background-color: #000000;
//   border: none;
//   border-radius: 0.5vw;
//   outline: transparent;
//   padding: 0.5vw;
//   display: flex;
//   ::placeholder {
//     color: #333333;
//     font-size: 1.2vw;
//     align-items: center;
//     padding-left: 0.5vw;
//   }
//   ${() =>
//     winH / winW > config.verticalSizeiphone
//       ? css`
//           width: 30vw;
//           height: 15vw;
//           font-size: 3vw;
//           border-radius: 3vw;
//           ::placeholder {
//             color: #333333;
//             font-size: 2.2vw;
//             align-items: center;
//             padding-left: 0.5vw;
//           }
//         `
//       : winH > winW &&
//         css`
//           width: 38vw;
//           height: 3.3vw;
//           font-size: 2.5vw;
//           ::placeholder {
//             color: #333333;
//             font-size: 1.8vw;
//             align-items: center;
//             padding-left: 0.5vw;
//           }
//         `}
// `;

// const InputName = styled.div`
//   color: #fff;
//   background-color: #000000;
//   border: none;
//   border-radius: 0.5vw;
//   outline: transparent;
//   display: flex;
//   margin-left: 8.5vw;
//   ${() =>
//     winH / winW > config.verticalSizeiphone
//       ? css`
//           margin-left: 11.5vw;
//           border-radius: 1vw;
//         `
//       : winH > winW &&
//         css`
//           margin-left: 7.5vw;
//         `}
// `;

const InputBank = styled.input`
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: min(5vw, 30px);
  background: transparent;
  border: none;
  border-radius: min(3vw, 18px);
  outline: transparent;
  position: relative;
  padding-left: 5%;
  ::placeholder {
    color: #333333;
    font-size: min(5vw, 30px);
  }
`;

// const InputSurName = styled.div`
//   width: 32vw;
//   height: 3.3vw;
//   color: #fff;
//   background-color: #000000;
//   border: none;
//   border-radius: 0.5vw;
//   outline: transparent;
//   display: flex;
//   margin-left: 0.5vw;
//   ${() =>
//     winH / winW > config.verticalSizeiphone
//       ? css`
//           border-radius: 1vw;
//           width: 35vw;
//           height: 6.5vw;
//         `
//       : winH > winW &&
//         css`
//           width: 38vw;
//           height: 4.3vw;
//         `}
// `;

// const BgClose = styled.div`
//   user-select: none;
//   width: 100vw;
//   height: 100%;
//   background-color: transparent;
//   z-index: 20;
//   left: 0%;
//   top: 0%;
//   position: fixed;
// `;

// const ContainerPrefixList = styled.div`
//   display: flex;
//   padding-left: 6vw;
//   flex-direction: row;
// `;

const ContentHead = styled.div`
  font-size: min(5vw, 30px);
  width: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5%;
`;

const ContentPrefix = styled.div`
  font-size: min(5vw, 30px);
  width: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ContentName = styled.div`
  font-size: min(5vw, 30px);
  width: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ContentSurName = styled.div`
  font-size: min(5vw, 30px);
  width: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5%;
`;

const ContainerName = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 5%;
`;

const ContainerWarning = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
`;

const ContentWarningName = styled.div`
  font-size: min(3vw, 18px);
  position: absolute;
  right: 0%;
  color: red;
  color: #ff0000;
  display: flex;
  flex-direction: row;
  width: 60%;
  text-align: right;
`;

const Prefix = styled.div`
  font-size: min(3vw, 18px);
  position: absolute;
  right: 63%;
  color: red;
  color: #ff0000;
  display: flex;
  flex-direction: row;
`;

const ContentWarningSurName = styled.div`
  font-size: min(3vw, 18px);
  position: absolute;
  right: 0%;
  color: red;
  color: #ff0000;
  display: flex;
  flex-direction: row;
  width: 60%;
  text-align: right;
`;

const ContentWarningSelectBank = styled.div`
  font-size: min(3vw, 18px);
  position: absolute;
  right: 0%;
  bottom: -35%;
  color: red;
  color: #ff0000;
  display: flex;
  flex-direction: row;
`;

const BankAccountWarning = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
`;

const ContentWarningBankAccount = styled.div`
  font-size: min(3vw, 18px);
  position: absolute;
  right: 0%;
  color: red;
  color: #ff0000;
  display: flex;
  flex-direction: row;
`;

const Container = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin-top: 2%;
`;

const ContainerButton = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin-top: min(5vw, 30px);
`;

const ContainerBank = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: min(12vw, 72px);
`;

// const ContentBack = styled.div`
//   color: #fff;
//   /* margin-top: -2.5vw;
//   margin-left: 0.3vw; */
//   margin: -2.5vw 0 0 0.3vw;
//   font-size: 1.5vw;
//   position: absolute;
//   ${() =>
//     winH / winW > config.verticalSizeiphone
//       ? css`
//           font-size: 5vw;
//           /* margin-top: 2.4vw; */
//           margin: 2.4vw 0 0 0.3vw;
//           position: inherit;
//           text-align: left;
//         `
//       : winH > winW &&
//         css`
//           font-size: 2.5vw;
//         `}
// `;

const NameItem = styled.button`
  align-items: center;
  display: flex;
  font-size: min(4vw, 24px);
  text-align: initial;
  color: #fff;
  background-color: transparent;
  border: none;
  outline: transparent;
  cursor: pointer;
  z-index: 90;
  order: ${(props) => (props.isSelected ? "0" : "1")};
`;

const Select = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

// const Dropdown = styled.div`
//   display: flex;
//   flex-direction: row;
// `;

// const DropdownContainer = styled.div`
//   display: flex;
//   margin-top: 0.3vw;
// `;

// const DropdownImage = styled.div`
//   width: 2.5vw;
//   height: 2.5vw;
//   margin-top: 0.2vw;
// `;

// const DropdownName = styled.div`
//   font-size: 1.2vw;
//   margin-left: 0.3vw;
// `;

const Selection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #000000;
  width: 100%;
  height: 100%;
  color: #ffffff;
  border-radius: min(3vw, 18px);
  border: none;
  position: relative;
`;

const SelectImage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
`;

const SelectImageBank = styled.img`
  width: 10%;
  position: relative;
  left: 2%;
`;

const SelectName = styled.div`
  display: flex;
  font-size: min(4vw, 24px);
  left: ${(props) => (props.hasBank ? "13%" : "5%")};
  position: absolute;
`;

const SelectText = styled.div`
  font-size: min(5vw, 30px);
  color: #333333;
`;

const IconBank = styled.img`
  width: 5%;
  position: absolute;
  right: 4%;
`;

// const Icon = styled.img`
//   width: 0.8vw;
//   height: 0.5vw;
//   /* margin-top: 1.5vw;
//   margin-left: 10.5vw; */
//   margin: 1.5vw 0 0 10.5vw;
//   position: absolute;
//   ${() =>
//     winH / winW > config.verticalSizeiphone
//       ? css`
//           width: 4vw;
//           height: auto;
//           right: 7%;
//           top: 34%;
//         `
//       : winH > winW &&
//         css`
//           width: 1.6vw;
//           height: 0.9vw;
//           /* margin-left: 6.5vw; */
//           margin: 1.5vw 0 0 6.5vw;
//         `}
// `;

const Inputtabbank = styled.div`
  width: 100%;
  height: 100%;
  color: #fff;
  background-color: #000000;
  border: none;
  border-radius: min(3vw, 18px);
  outline: transparent;
  display: flex;
  align-items: center;
`;

const CheckBox = styled.div`
  display: inline-block;
  width: min(3vw, 18px);
  height: min(3vw, 18px);
  background-color: #797fa1;
  border: min(1vw, 6px) solid #797fa1;
  border-radius: min(5vw, 30px);
  position: relative;
  cursor: pointer;
  margin-right: 3%;
  ${(props) =>
    props.lineType === true &&
    css`
      background: linear-gradient(
        180deg,
        #06d746 24.48%,
        #4dff80 52.08%,
        #06d746 100%
      );
      border: min(1vw, 6px) solid #797fa1;
    `}
`;

const ContainerCheckBox = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: min(12vw, 72px);
`;

// const LineContainer = styled.div`
//   width: 36.5%;
// `;

// const ContentLine = styled.div`
//   font-size: 1.5vw;
//   /* margin-top: 1.4vw;
//   margin-left: 0.5vw; */
//   margin: 1.4vw 0 0 0.5vw;
//   width: 12vw;
//   color: #fff;
//   display: flex;
//   flex-direction: row;
//   ${() =>
//     winH / winW > config.verticalSizeiphone
//       ? css`
//           font-size: 5vw;
//           /* margin-left: 0.3vw; */
//           margin: 1.4vw 0 0 0.3vw;
//           width: 35vw;
//         `
//       : winH > winW &&
//         css`
//           font-size: 2.5vw;
//         `}
// `;

const InputCheckBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: min(5vw, 30px);
`;

const InputLine = styled.input`
  width: 95%;
  height: 100%;
  color: #fff;
  font-size: min(4vw, 24px);
  background-color: #000000;
  border: none;
  border-radius: min(3vw, 18px);
  outline: transparent;
  padding: 0;
  padding-left: 5%;
  ::placeholder {
    color: #333333;
    font-size: min(4vw, 24px);
    align-items: center;
  }
`;

const WarningMessage = styled.div`
  font-size: min(3vw, 18px);
  position: absolute;
  right: 0%;
  color: red;
  color: #ff0000;
  display: flex;
  flex-direction: row;
`;

const PopupBox = styled.div`
  width: 93%;
  max-width: 600px;
  height: 100%;
  display: block;
  flex-direction: column;
`;

const Title = styled.div`
  width: 100%;
  height: min(12vw, 72px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: min(6.5vw, 39px);
  color: #fff;
  white-space: nowrap;
  margin-top: 10%;
  position: relative;
`;

// const ContentContainer = styled.div`
//   width: 40%;
//   margin: 0 auto;
//   ${() =>
//     winH / winW > config.verticalSizeiphone
//       ? css`
//           width: 90%;
//         `
//       : winH > winW && css``}
// `;

const Row = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: min(12vw, 72px);
`;

const PrefixContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: min(12vw, 72px);
`;

const PrefixVertical = styled.div`
  background-color: #000000;
  width: 100%;
  height: max-content;
  border-radius: min(3vw, 18px);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
`;

const InputNameVertical = styled.input`
  width: 94.5%;
  height: min(12vw, 72px);
  color: #fff;
  font-size: min(5vw, 30px);
  background-color: #000000;
  border: none;
  border-radius: min(3vw, 18px);
  outline: transparent;
  padding-left: 5%;

  ::placeholder {
    color: #333333;
    font-size: min(5vw, 30px);
    align-items: center;
  }
`;

const InputSurNameVertical = styled.input`
  width: 96.7%;
  height: 100%;
  color: #fff;
  font-size: min(5vw, 30px);
  background-color: #000000;
  border: none;
  border-radius: min(3vw, 18px);
  outline: transparent;
  padding-left: 5%;

  ::placeholder {
    color: #333333;
    font-size: min(5vw, 30px);
    align-items: center;
  }
`;

const DropdownIcon = styled.img`
  width: 10%;
  position: absolute;
  right: 7%;
`;

const InputLineBox = styled.div`
  width: 59.56%;
  height: 100%;
`;

const LineType = styled.div`
  width: 37.5%;
  height: 100%;
  font-size: min(4vw, 24px);
  color: #fff;
  background-color: #000000;
  border-radius: min(3vw, 18px);
  position: relative;
  display: flex;
  align-items: center;
`;

const LineTypeDropdown = styled.div`
  width: 37.5%;
  background-color: #000000;
  border-radius: min(3vw, 18px);
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 10;
  top: 100%;
`;

const IconFlip = styled.img`
  width: 12%;
  height: auto;
  position: absolute;
  right: 7%;
  transform: ${(props) => (props.active ? "scaleY(-1)" : "scaleY(1)")};
`;

const LineTypeItem = styled.p`
  font-size: min(5vw, 30px);
  color: #fff;
  cursor: pointer;
  position: relative;
  left: 15%;
`;

const HrLine = styled.hr`
  opacity: 0.1;
  border: min(0.1vw, 0.6px) solid #ffffff;
  margin-bottom: 5%;
`;

const PromotionCondition = styled.div`
  color: #ffffff;
  width: 100%;
  margin: 0 auto;
  margin-top: 5%;
  font-size: min(5vw, 30px);
`;

const PromotionDetail = styled.div`
  width: 100%;
  display: block;
`;

const Paragraph = styled.div`
  font-size: min(4vw, 24px);
  color: #ffffff;
  opacity: 0.6;
  white-space: pre-line;
`;

// const PopupLandscape = styled.div`
//   width: 100%;
// `;

const RedButton = styled.div`
  width: 37.5%;
  height: min(12vw, 72px);
  background: linear-gradient(180deg, #e13b00 38.02%, #900000 100%);
  box-shadow: inset 0px 0px 3.44311px 1.72155px #ff814d;
  border-radius: 8.60777px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: min(5vw, 30px);
  cursor: pointer;
`;

const GreenButton = styled.div`
  width: 59.43%;
  height: min(12vw, 72px);
  background: ${(props) =>
    props.color === "gray"
      ? "linear-gradient(180deg, #B3B3B3 38.02%, #565656 100%)"
      : "linear-gradient(180deg, #62d706 38.02%, #3b9604 100%)"};
  box-shadow: ${(props) =>
    props.color === "gray"
      ? "inset 0px 0px 3.44311px 1.72155px #BCBCBC"
      : "inset 0px 0px 3.44311px 1.72155px #4dff80"};
  border-radius: 8.60777px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: min(5vw, 30px);
  cursor: ${(props) => (props.color === "gray" ? "" : "pointer")};
`;

const ScrollerBox = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  ::-webkit-scrollbar {
    width: 0vw;
    height: 0vw;
    z-index: 10;
  }
  ::-webkit-scrollbar-thumb {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 10;
    position: absolute;
    width: 11px;
    height: 80px;
    background: linear-gradient(180deg, #e13b00 38.02%, #900000 100%);
    box-shadow: inset 0px 0px 4px 2px #ff814d;
    border-radius: 10px;
  }
`;

const PrefixBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 37.5%;
`;

const InputNameBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 59.56%;
`;

const LangBox = styled.div`
  width: min(15vw, 90px);
  height: min(15vw, 90px);
  position: absolute;
  top: -80%;
  right: 5%;
  cursor: pointer;
  background-image: ${(props) => `url(../${config.theme}/${props.icon})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

function BankAccount(props) {
  const profile = useSelector(selectuserProfile);
  const [isActive, setIsActive] = useState(false);
  const [isActiveBack, setIsActiveBack] = useState(false);
  const [prefix, setPrefix] = useState("");
  // const [toConfirm, setToConfirm] = useState(false);
  const [bank, setBank] = useState([]);
  const [emitError, setEmitError] = useState({ status: false, message: "" });
  const socket = useContext(SocketContext);
  const { t, i18n } = useTranslation();
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [bankId, setBankId] = useState(0);
  const [bankName, setBankName] = useState("");
  const [bankImg, setBankImg] = useState("");
  const [bankAccount, setBankAccount] = useState("");
  const [load, setLoad] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [userDuplicate, setUserDuplicate] = useState(false);
  const [firstNameErr, setFirstNameErr] = useState({});
  const [firstNameErrEN, setFirstNameErrEN] = useState({});
  const [lastNameErr, setLastNameErr] = useState({});
  const [lastNameErrEN, setLastNameErrEN] = useState({});
  const [preFixErr, setPreFixErr] = useState({});
  const [preFixErrEN, setPreFixErrEN] = useState({});
  const [bankNameError, setBankNameErr] = useState({});
  const [bankNameErrorEN, setBankNameErrEN] = useState({});
  const [BankAccountError, setBankAccountErr] = useState({});
  const [BankAccountErrorEN, setBankAccountErrEN] = useState({});
  const [firstNameErrLang, setFirstNameErrorLang] = useState({});
  const [firstNameErrorLangEN, setFirstNameErrorLangEN] = useState({});
  const [lastNameErrLang, setLastNameErrorLang] = useState({});
  const [lastNameErrLangEn, setLastNameErrorLangEn] = useState({});
  const [conFirmRegister, setConFirmRegister] = useState(false);
  const [inFormation, setInFormation] = useState({
    username: "",
    password: "",
    pincode: "",
    tel: "",
  });
  const [jwtToken, setJwtToken] = useState("");
  const ref = useRef();
  const refBank = useRef();
  const refLineType = useRef();
  const dispatch = useDispatch();
  // const checkFTUEPopup = useSelector(selectCheckFTUEPopup);
  const [lineType, setLineType] = useState({ lineId: true, lineTel: false });
  const [line, setLine] = useState("");
  // const [validateLineTel, setValidateLineTel] = useState(false);
  const lineTypeDropDown = ["ID", "phone number"];
  const [lineTypeSelect, setLineTypeSelect] = useState("ID");
  const [showDropdown, setShowDropdown] = useState(false);
  const [promotionStatus, setPromotionStatus] = useState({
    getPromotion: true,
    noPromotion: false,
  });
  // const [conditionStatus, setConditionStatus] = useState(false);
  const [openCondition, setOpenCondition] = useState(false);
  // const [showConditionAlert, setShowConditionAlert] = useState(false);
  const [uiHeight, setUiHeight] = useState(window.innerHeight);
  const [showLanguagePop, setShowLanguagePop] = useState(false);

  document.body.style.overflow = "hidden";

  let reqBank = new RegExp(/^[0-9\b]+$/);
  let reqName = new RegExp(/[a-zA-Zก-๙\s]+$/);
  let reqPhone = new RegExp(/^[0-9\b]+$/);
  let reqLineId = new RegExp(/^[a-z0-9._-]+$/);

  const handleResize = () => {
    setUiHeight(window.innerHeight);
    // alert("Height Change to " + window.innerHeight);
  };

  window.addEventListener("resize", handleResize);

  useEffect(() => {
    try {
      socket.emit(
        "getbilog",
        {
          biname: "bankRegister_open",
        },
        (data) => {
          console.log(data);
        }
      );
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    setPrefix("");
    socket.emit("getAllBank", {}, (data) => {
      // console.log(data.results.data);
      if (
        typeof data === "undefined" ||
        typeof data.results === "undefined" ||
        typeof data.results.data === "undefined"
      ) {
      } else {
        if (data.status) {
          setBank(data.results.data);
          setBankId(data.results.data.bankID);
        } else if (data.status === false) {
          setEmitError({ status: true, message: data.errorCode });
          setTimeout(() => {
            setEmitError({ status: false });
          }, 3000);
        }
      }
    });
  }, [i18n.language]);

  const onSubmit = () => {
    setLoad(true);
    // console.log("bankId1", bankId);
    const isValid = formValidation();
    if (isValid === false) {
      // console.log("check Form");
      setLoad(false);
      setShowAlert(true);
      return;
    }

    if (lineType.lineTel === true && line.length > 0) {
      const isValidLineTel = validTelPhone(line);
      // console.log("check LineTel", isValidLineTel);
      if (isValidLineTel === false) {
        // console.log("isValidLineTel == false");
        setLoad(false);
        setShowAlert(true);
        return;
      }
    }

    // if (conditionStatus == false) {
    //   setLoad(false);
    //   setShowConditionAlert(true);
    //   setTimeout(() => {
    //     setShowConditionAlert(false);
    //   }, 3000);
    //   return;
    // }

    // console.log("bankId2", bankId);

    try {
      socket.emit(
        "registeruser",
        {
          fname: fname.trim(),
          lname: lname.trim(),
          bank_id: bankId,
          bank_account: bankAccount,
          lineID: line,
          player_pro_status: promotionStatus.getPromotion ? 1 : 0,
        },
        (body) => {
          setLoad(false);
          console.log("onSubmit", body);
          if (typeof body != "undefined") {
            if (body.status === true) {
              setInFormation(body.results.user);
              setConFirmRegister(true);
              setJwtToken(getFullUrl(body.results.jwtToken));
              // setTimeout(() => {
              //   setToConfirm(false);
              //   // redirectToLogin(getFullUrl(body.results.jwtToken));
              //   // props.closeClick();
              // }, 1000);
            } else if (body.status === false) {
              // console.log(body.errorCode);

              if (body.errorCode === "s0068") {
                setShowAlert(true);
              }
              // else if (body.errorCode == "s0033") {
              //   setUserDuplicate(true);
              // }
              else if (body.errorCode === "s0088") {
                setShowAlert(true);
              } else if (body.errorCode === "s0066") {
                setShowAlert(true);
              }
              // else if (body.errorCode == "s0025") {
              //   setShowAlert(true);
              // }
              else if (body.errorCode === "s0076") {
                setShowAlert(true);
              } else if (body.errorCode === "s0074") {
                setShowAlert(true);
              } else {
                setEmitError({ status: true, message: body.errorCode });
                setTimeout(() => {
                  setEmitError({ status: false });
                }, 3000);
              }
            }
          }
        }
      );
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  };

  // useEffect(() => {
  //   if (checkFTUEPopup == 1) socket.emit("ftueRegister", {}, (body) => { });
  // }, [checkFTUEPopup]);

  const formValidation = () => {
    const checkThai = /^[ก-๙^0-9\s]+$/;
    const checkEng = /^[a-zA-Z^0-9\s]+$/;
    const firstNameErr = {};
    const lastNameErr = {};
    const lastNameErrEN = {};
    const preFixErr = {};
    const bankNameError = {};
    const bankNameErrorEN = {};
    const BankAccountError = {};
    const firstNameErrLang = {};
    const lastNameErrLang = {};
    const lastNameErrLangEn = {};
    const firstNameErrorLangEN = {};
    const preFixErrEN = {};
    const firstNameErrEN = {};
    const BankAccountErrorEN = {};
    let isValid = true;

    if (fname === "") {
      firstNameErr.firstName = "ข้อมูลไม่ถูกต้อง";
      firstNameErrEN.firstNameErrEN = "Invalid information";
      isValid = false;
    } else if (!checkThai.test(fname) && !checkEng.test(fname)) {
      firstNameErrLang.firstName =
        "โปรดใส่ชื่อ-นามสกุลเป็นภาษาไทยหรืออังกฤษเพียงอย่างเดียวเท่านั้น";
      firstNameErrorLangEN.firstName = "Name must be in English";
      isValid = false;
    }

    if (lname === "") {
      lastNameErr.lastName = "ข้อมูลไม่ถูกต้อง";
      lastNameErrEN.lastNameErrEN = "Invalid information";
      isValid = false;
    } else if (!checkThai.test(lname) && !checkEng.test(lname)) {
      lastNameErrLang.firstName =
        "โปรดใส่ชื่อ-นามสกุลเป็นภาษาไทยหรืออังกฤษเพียงอย่างเดียวเท่านั้น";
      lastNameErrLangEn.lastNameErrLangEn = "Name must be in English";
      isValid = false;
    }

    if (prefix === "") {
      preFixErr.preFix = "ข้อมูลไม่ถูกต้อง";
      preFixErrEN.preFixEN = "Invalid information";

      isValid = false;
    }

    if (bankName === "") {
      bankNameError.bankNameWording = "ข้อมูลไม่ถูกต้อง";
      bankNameErrorEN.bankNameErrorEN = "Invalid information";
      isValid = false;
    }
    if (bankAccount === "") {
      BankAccountError.bankAccountWording = "ข้อมูลไม่ถูกต้อง";
      BankAccountErrorEN.BankAccountErrorEN = "Invalid information";
      isValid = false;
    } else if (bankAccount.length < 10) {
      BankAccountError.bankAccountWording = "ข้อมูลไม่ถูกต้อง";
      BankAccountErrorEN.BankAccountErrorEN = "Invalid information";
      isValid = false;
    }

    setFirstNameErr(firstNameErr);
    setFirstNameErrEN(firstNameErrEN);
    setLastNameErr(lastNameErr);
    setLastNameErrEN(lastNameErrEN);
    setPreFixErr(preFixErr);
    setPreFixErrEN(preFixErrEN);
    setBankNameErr(bankNameError);
    setBankAccountErr(BankAccountError);
    setFirstNameErrorLang(firstNameErrLang);
    setLastNameErrorLang(lastNameErrLang);
    setFirstNameErrorLangEN(firstNameErrorLangEN);
    setBankNameErrEN(bankNameErrorEN);
    setBankAccountErrEN(BankAccountErrorEN);
    setLastNameErrorLangEn(lastNameErrLangEn);

    return isValid;
  };

  const validTelPhone = (tel = "", debug = false) => {
    if (typeof tel != "string") {
      if (debug) {
        // console.log("Type of MissMatch String");
      }
      return false;
    }
    if (tel.length < 0) {
      if (debug) {
        // console.log("String is Zero lenght!");
      }
      return false;
    }

    if (tel.length < 10) {
      return false;
    }

    let cleaned_tel = ("" + tel).replace(/\D/g, "");
    if (debug) {
      // console.log(cleaned_tel);
    }
    let tmp_obj = {
      v1: "",
      v2: "",
      vCheck1: ["0"],
      vCheck2: ["6", "8", "9"],
      result: [false, false],
      sumResult: true,
    };
    tmp_obj.v1 = cleaned_tel.substring(0, 1);
    tmp_obj.v2 = cleaned_tel.substring(1, 2);
    tmp_obj.result[0] = tmp_obj.vCheck1.includes(tmp_obj.v1);
    tmp_obj.result[1] = tmp_obj.vCheck2.includes(tmp_obj.v2);
    for (const value of tmp_obj.result) {
      if (debug) {
        // console.log(value);
      }
      if (value === false) {
        tmp_obj.sumResult = value;
      }
    }
    if (debug) {
      // console.log("result =>", tmp_obj);
    }
    return tmp_obj.sumResult;
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isActive && ref.current && !ref.current.contains(e.target)) {
        setIsActive(false);
      }
      if (
        isActiveBack &&
        refBank.current &&
        !refBank.current.contains(e.target)
      ) {
        setIsActiveBack(false);
      }
      if (
        showDropdown &&
        refLineType.current &&
        !refLineType.current.contains(e.target)
      ) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isActive, isActiveBack, showDropdown]);

  const conditionCheckComponent = () => {
    return (
      <PromotionCondition>
        <HrLine />
        {t("promotion status")}
        <PromotionDetail>
          <InputCheckBox
            onClick={() => {
              setPromotionStatus({
                getPromotion: true,
                noPromotion: false,
              });
            }}
          >
            <CheckBox lineType={promotionStatus.getPromotion} />
            {t("recieve promotion")}
          </InputCheckBox>

          <Paragraph>{t("recieve promotion detail")}</Paragraph>
        </PromotionDetail>
        <PromotionDetail>
          <InputCheckBox
            onClick={() => {
              setPromotionStatus({
                getPromotion: false,
                noPromotion: true,
              });
            }}
          >
            <CheckBox lineType={promotionStatus.noPromotion} />
            {t("no recieve promotion")}
          </InputCheckBox>

          <Paragraph>{t("no recieve promotion detail")}</Paragraph>
        </PromotionDetail>

        {/* <HrLine />
        <InputCheckBox
          onClick={() => {
            setConditionStatus(!conditionStatus);
          }}
        >
          <CheckBox lineType={conditionStatus} />
          <h3>
            {t("i agree")}
            <span
              onClick={() => {
                setOpenCondition(true);
              }}
              style={{
                color: "#FF691F",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {t("condition")}
            </span>
          </h3>
        </InputCheckBox> */}
      </PromotionCondition>
    );
  };

  const lineTypeComponent = () => {
    return (
      <>
        <ContainerCheckBox>
          <LineType
            onClick={() => {
              setShowDropdown((current) => !current);
            }}
          >
            <LineTypeItem active={showDropdown}>
              {t(lineTypeSelect)}
            </LineTypeItem>
            <IconFlip
              active={showDropdown}
              src={"../" + config.theme + "/icons.png"}
            />
          </LineType>
          {showDropdown ? (
            <LineTypeDropdown ref={refLineType}>
              {lineTypeDropDown.map((item, index) => (
                <LineTypeItem
                  key={index}
                  active={showDropdown}
                  onClick={() => {
                    setLine("");
                    setLineTypeSelect(item);
                    setShowDropdown(false);
                  }}
                >
                  {t(item)}
                </LineTypeItem>
              ))}
            </LineTypeDropdown>
          ) : (
            ""
          )}
          <InputLineBox>
            <InputLine
              type="text"
              name="line"
              value={line}
              maxLength={lineTypeSelect === "phone number" ? 10 : null}
              placeholder={
                lineTypeSelect === "ID" ? t("enter id") : t("enter phone")
              }
              onFocus={(e) => {
                window.isEdit = true;
                if (lineTypeSelect === "ID") {
                  dispatch(setFloatingLabel(t("enter id")));
                } else {
                  dispatch(setFloatingLabel(t("enter phone")));
                }
                dispatch(setFloatingStatus(true));
                dispatch(setFloatingValue(e.target.value));
              }}
              onBlur={() => {
                window.isEdit = false;
                dispatch(setFloatingStatus(false));
              }}
              onChange={(v) => {
                if (lineTypeSelect === "phone number") {
                  if (v.target.value === "" || reqPhone.test(v.target.value)) {
                    setLine(v.target.value);
                    dispatch(setFloatingValue(v.target.value));
                  }
                } else {
                  if ((v.target.value === "") | reqLineId.test(v.target.value)) {
                    setLine(v.target.value);
                    dispatch(setFloatingValue(v.target.value));
                  }
                }
              }}
            />
          </InputLineBox>
        </ContainerCheckBox>
        <ContainerWarning>
          {lineTypeSelect === "phone number" && line.length > 0 ? (
            !validTelPhone(line) ? (
              <WarningMessage>
                {i18n.language === "en"
                  ? "Invalid information"
                  : "ข้อมูลไม่ถูกต้อง"}
              </WarningMessage>
            ) : null
          ) : null}
        </ContainerWarning>
      </>
    );
  };

  return (
    <BG uiHeight={uiHeight}>
      {load === true ? <Loading /> : ""}
      <ScrollerBox>
        <PopupBox>
          <Title>
            {t("link bank account")}
            <LangBox
              onClick={() => {
                setShowLanguagePop(!showLanguagePop);
              }}
              icon={t("flag")}
            />
          </Title>
          {/* <ContentContainer> */}
          <ContainerName>
            <PrefixBox>
              <ContentPrefix>{t("name title")}</ContentPrefix>
              {isActive === true ? (
                <PrefixContainer ref={ref}>
                  <PrefixVertical
                    active={isActive}
                    onClick={() => setIsActive(!isActive)}
                  >
                    {/* <DropdownIcon src={"../" + config.theme + "/icons.png"} /> */}
                    {/* <PrefixItem isSelected={true}></PrefixItem> */}
                    <PrefixItem
                      isSelected={false}
                      onClick={() => setPrefix(t("mr"))}
                    >
                      {t("mr")}
                    </PrefixItem>
                    <PrefixItem
                      isSelected={false}
                      onClick={() => setPrefix(t("mrs"))}
                    >
                      {t("mrs")}
                    </PrefixItem>
                    <PrefixItem
                      isSelected={false}
                      onClick={() => setPrefix(t("miss"))}
                    >
                      {t("miss")}
                    </PrefixItem>
                  </PrefixVertical>
                </PrefixContainer>
              ) : (
                <PrefixContainer>
                  <PrefixVertical
                    active={isActive}
                    onClick={() => setIsActive(!isActive)}
                  >
                    <DropdownIcon src={"../" + config.theme + "/icons.png"} />
                    <PrefixItem isSelected={true}>
                      {prefix || t("choose")}
                    </PrefixItem>
                  </PrefixVertical>
                </PrefixContainer>
              )}
            </PrefixBox>
            <InputNameBox>
              <ContentName>{t("name createbanlk account")}</ContentName>
              <InputNameVertical
                type="text"
                name="name"
                value={fname}
                maxLength={20}
                placeholder={t("name createbanlk account")}
                onFocus={(e) => {
                  window.isEdit = true;
                  dispatch(setFloatingLabel(t("name createbanlk account")));
                  dispatch(setFloatingStatus(true));
                  dispatch(setFloatingValue(e.target.value));
                }}
                onBlur={() => {
                  window.isEdit = false;
                  dispatch(setFloatingStatus(false));
                }}
                onChange={(v) => {
                  if (v.target.value === "" || reqName.test(v.target.value)) {
                    setFname(v.target.value);
                    dispatch(setFloatingValue(v.target.value));
                  }
                }}
              />
            </InputNameBox>
          </ContainerName>

          <ContainerWarning>
            {i18n.language === "th" ? (
              <>
                {Object.keys(preFixErr).map((key) => {
                  return <Prefix>{preFixErr[key]}</Prefix>;
                })}
              </>
            ) : (
              <>
                {Object.keys(preFixErrEN).map((key) => {
                  return <Prefix>{preFixErrEN[key]}</Prefix>;
                })}
              </>
            )}
            {i18n.language === "th" ? (
              <>
                {Object.keys(firstNameErr).map((key) => {
                  return (
                    <ContentWarningName>{firstNameErr[key]}</ContentWarningName>
                  );
                })}
              </>
            ) : (
              <>
                <>
                  {Object.keys(firstNameErrEN).map((key) => {
                    return (
                      <ContentWarningName>
                        {firstNameErrEN[key]}
                      </ContentWarningName>
                    );
                  })}
                </>
              </>
            )}

            {i18n.language === "th" ? (
              <>
                {Object.keys(firstNameErrLang).map((key) => {
                  return (
                    <ContentWarningName>
                      {firstNameErrLang[key]}
                    </ContentWarningName>
                  );
                })}
              </>
            ) : (
              <>
                {Object.keys(firstNameErrorLangEN).map((key) => {
                  return (
                    <ContentWarningName>
                      {firstNameErrorLangEN[key]}
                    </ContentWarningName>
                  );
                })}
              </>
            )}
          </ContainerWarning>

          <ContentSurName>{t("last name")}</ContentSurName>
          <Row>
            <InputSurNameVertical
              type="text"
              name="lname"
              value={lname}
              maxLength={20}
              placeholder={t("last name")}
              onFocus={(e) => {
                window.isEdit = true;
                dispatch(setFloatingLabel(t("last name")));
                dispatch(setFloatingStatus(true));
                dispatch(setFloatingValue(e.target.value));
              }}
              onBlur={() => {
                window.isEdit = false;
                dispatch(setFloatingStatus(false));
              }}
              onChange={(v) => {
                if (v.target.value === "" || reqName.test(v.target.value)) {
                  setLname(v.target.value);
                  dispatch(setFloatingValue(v.target.value));
                }
              }}
            />
          </Row>

          <ContainerWarning>
            {i18n.language === "th" ? (
              <>
                {Object.keys(lastNameErr).map((key) => {
                  return (
                    <ContentWarningSurName>
                      {lastNameErr[key]}
                    </ContentWarningSurName>
                  );
                })}
              </>
            ) : (
              <>
                {Object.keys(lastNameErrEN).map((key) => {
                  return (
                    <ContentWarningSurName>
                      {lastNameErrEN[key]}
                    </ContentWarningSurName>
                  );
                })}
              </>
            )}
            {i18n.language === "th" ? (
              <>
                {Object.keys(lastNameErrLang).map((key) => {
                  return (
                    <ContentWarningSurName>
                      {lastNameErrLang[key]}
                    </ContentWarningSurName>
                  );
                })}
              </>
            ) : (
              <>
                {Object.keys(lastNameErrLangEn).map((key) => {
                  return (
                    <ContentWarningSurName>
                      {lastNameErrLangEn[key]}
                    </ContentWarningSurName>
                  );
                })}
              </>
            )}
          </ContainerWarning>

          <ContentHead>{t("bank")}</ContentHead>
          <ContainerBank>
            <div ref={refBank} style={{ width: "100%", height: "100%" }}>
              {/* {isActiveBack == false ? ( */}
              <Select onClick={() => setIsActiveBack((current) => !current)}>
                <Selection>
                  <SelectImage>
                    {bankImg !== "" ? (
                      <SelectImageBank
                        src={
                          bankImg === ""
                            ? ""
                            : "../" +
                            config.theme +
                            "/bicon_" +
                            bankImg +
                            ".png"
                        }
                      />
                    ) : null}
                    <IconBank src={"../" + config.theme + "/icons.png"} />{" "}
                  </SelectImage>
                  <SelectName hasBank={bankName !== ""}>
                    {bankName === "" ? (
                      <SelectText>{t("select bank account")}</SelectText>
                    ) : (
                      bankName
                    )}
                  </SelectName>
                </Selection>
              </Select>
              {/* ) : (
                   <></>
                 )} */}

              <Select onClick={() => setIsActiveBack(!isActiveBack)}>
                <PrefixListBlack active={isActiveBack}>
                  {/* <IconBank src={"../" + config.theme + "/icons.png"} /> */}
                  {/* <PrefixItem isSelected={true}>
                    {isActiveBack ? (
                      <></>
                    ) : (
                      <>
                        <Dropdown>
                          <DropdownContainer>
                            <DropdownImage
                              src={
                                bankImg === ""
                                  ? ""
                                  : "../" +
                                    config.theme +
                                    "/bicon_" +
                                    bankImg +
                                    ".png"
                              }
                            />{" "}
                          </DropdownContainer>
                          <DropdownName>{bankName}</DropdownName>
                        </Dropdown>
                      </>
                    )}
                  </PrefixItem> */}

                  {bank.map((item, index) => (
                    <Container key={index}>
                      <img
                        style={{
                          width: "10%",
                          left: "2%",
                        }}
                        src={
                          item.bankShortName === ""
                            ? ""
                            : "../" +
                            config.theme +
                            "/bicon_" +
                            item.bankShortName +
                            ".png"
                        }
                      />

                      <NameItem
                        isSelected={false}
                        onClick={() => {
                          setBankId(item.bankID);
                          setBankName(
                            i18n.language === "th"
                              ? item.bankName
                              : item.bankName_en
                          );
                          setBankImg(item.bankShortName);
                        }}
                        key={index}
                      >
                        {i18n.language === "th" ? (
                          <> {item.bankName}</>
                        ) : (
                          <>{item.bankName_en}</>
                        )}
                      </NameItem>
                    </Container>
                  ))}
                </PrefixListBlack>
              </Select>

              {i18n.language === "th" ? (
                <>
                  {Object.keys(bankNameError).map((key) => {
                    return (
                      <ContentWarningSelectBank>
                        {bankNameError[key]}
                      </ContentWarningSelectBank>
                    );
                  })}
                </>
              ) : (
                <>
                  {Object.keys(bankNameErrorEN).map((key) => {
                    return (
                      <ContentWarningSelectBank>
                        {bankNameErrorEN[key]}
                      </ContentWarningSelectBank>
                    );
                  })}
                </>
              )}
            </div>
          </ContainerBank>

          <ContentHead>{t("account")}</ContentHead>
          <ContainerBank>
            <Inputtabbank>
              <InputBank
                type="text"
                name="account_number"
                value={bankAccount}
                maxLength={15}
                placeholder={t("enter account number")}
                onFocus={(e) => {
                  window.isEdit = true;
                  dispatch(setFloatingLabel(t("enter account number")));
                  dispatch(setFloatingStatus(true));
                  dispatch(setFloatingValue(e.target.value));
                }}
                onBlur={() => {
                  window.isEdit = false;
                  dispatch(setFloatingStatus(false));
                }}
                onChange={(v) => {
                  if (v.target.value === "" || reqBank.test(v.target.value)) {
                    setBankAccount(v.target.value);
                    dispatch(setFloatingValue(v.target.value));
                  }
                }}
              />
            </Inputtabbank>
          </ContainerBank>

          <BankAccountWarning>
            {i18n.language === "th" ? (
              <>
                {Object.keys(BankAccountError).map((key) => {
                  return (
                    <ContentWarningBankAccount>
                      {BankAccountError[key]}
                    </ContentWarningBankAccount>
                  );
                })}
              </>
            ) : (
              <>
                {Object.keys(BankAccountErrorEN).map((key) => {
                  return (
                    <ContentWarningBankAccount>
                      {BankAccountErrorEN[key]}
                    </ContentWarningBankAccount>
                  );
                })}
              </>
            )}
          </BankAccountWarning>

          {/* LINE */}

          <ContentHead>{t("line account")}</ContentHead>
          {lineTypeComponent()}
          {conditionCheckComponent()}
          {/* </ContentContainer> */}

          <ContainerButton>
            <RedButton
              className="button"
              onClick={() => {
                try {
                  socket.emit(
                    "getbilog",
                    {
                      biname: "bankRegister_cancel",
                    },
                    (data) => {
                      // console.log(data);
                    }
                  );
                } catch (error) {
                  console.log(error);
                }
                // props.closeClick();
                redirect2Page("");
              }}
            >
              {t("cancel")}
            </RedButton>
            <>
              {prefix === "" ||
                fname === "" ||
                lname === "" ||
                bankAccount === "" ||
                bankName === " " ? (
                <GreenButton
                  id={profile.brands_id + "btn-next-step-info"}
                  className="button"
                  onClick={() => {
                    if (prefix === "") {
                      formValidation();
                      setShowAlert(true);
                    } else {
                      onSubmit();
                    }
                  }}
                >
                  {t("confirmBank")}
                </GreenButton>
              ) : (
                <GreenButton
                  id={profile.brands_id + "btn-next-step-info"}
                  className="button"
                  onClick={() => {
                    onSubmit();
                  }}
                >
                  {t("confirmBank")}
                </GreenButton>
              )}
            </>
          </ContainerButton>

          {showAlert === true ? (
            <BG style={{ justifyContent: "center" }}>
              <ConfirmPopup
                messageBottom={t("okay")}
                onClick={() => setShowAlert(false)}
              >
                {t("information is invalid")}
              </ConfirmPopup>
            </BG>
          ) : null}

          {userDuplicate === true ? (
            <BG style={{ justifyContent: "center" }}>
              <ConfirmPopup
                messageBottom={t("okay")}
                onClick={() => setUserDuplicate(false)}
              >
                {t("there are users duplicate")}
              </ConfirmPopup>
            </BG>
          ) : null}
        </PopupBox>
      </ScrollerBox>

      {/* <Transition showPop={showConditionAlert}>
        <BG style={{ justifyContent: "center" }}>
          <AlertMessage message={t("โปรดกดยอมรับเงื่อนไขและข้อตกลง")} />
        </BG>
      </Transition> */}

      {/* <Transition showPop={toConfirm}>
        <BG style={{ justifyContent: "center" }}>
          <AlertMessage message={t("bank account registration successfully")} />
        </BG>
      </Transition> */}

      <Transition showPop={conFirmRegister}>
        <ConfirmationRegister
          username={inFormation.username}
          password={inFormation.password}
          pin={inFormation.pincode}
          tel={inFormation.tel}
          jwtToken={jwtToken}
          closeClick={() => {
            props.closeClick();
            redirectToLogin(jwtToken);
          }}
        />
      </Transition>

      <Transition showPop={emitError.status} type="alert">
        <AlertMessage
          message={t(emitError.message)}
          onClick={() => {
            setEmitError({ status: false });
          }}
        />
      </Transition>

      <Transition showPop={openCondition} type="alert">
        <Condition closeClick={() => setOpenCondition(false)} />
      </Transition>

      {showLanguagePop ? (
        <Language setShowLanguagePop={setShowLanguagePop} />
      ) : (
        ""
      )}
    </BG>
  );
}

export default BankAccount;
