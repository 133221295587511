import React from "react";
import styled, { css, keyframes } from "styled-components";
import {
  selectuserProfile,
  actionClickSound,
} from "../../Reducers/userProfileSlice";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const BG = styled.div`
  // background: rgba(0,0,0,0.7);
  // // font-size: 30vw;
  // color: white;
  // z-index: 1;
  // position: absolute;
  // width: 100vw;
  // height: 100vh;
  user-select: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 9999;
  align-items: center;
  justify-content: center;
`;
const Container = styled.div`
  width: 50vw;
  height: 50vw;
  margin: 0 auto;
  position: relative;
  top: 5vw;
  display: flex;
  justify-content: center;
  ${() =>
    window.appHeight() > window.innerWidth
      ? css`
          transform: scale(1.5);
        `
      : window.innerWidth / window.appHeight() > 2.15 &&
        css`
          transform: scale(0.8);
        `}
`;
const ConfirmBtn = styled.div`
  cursor: pointer;
  background: linear-gradient(180deg, #7bd706 0%, #569604 74.48%);
  box-shadow: inset 0px 0px 4px 2px #91ff4d;
  border-radius: 1vw;
  text-align: center;
  color: white;
  padding: 0.2vw 3vw;
  width: fit-content;
  height: fit-content;
  font-size: 2vw;
  line-height: 5vw;
  z-index: 76;
  position: absolute;
  bottom: 27%;
`;
const Starcontainer = styled.div`
  width: fit-content;
  height: fit-content;
  display: block;
`;
const stars = css`
  background-image: url(../../images/levelup_popup/star.png);
  background-size: contain, cover;
  background-position: left top;
  background-repeat: no-repeat;
  width: 100%;
  height: 28%;
  position: absolute;
  z-index: 74;
`;
const starin = keyframes`
    0%{bottom: 25vw; opacity: 0;}
    100%{bottom: 22vw; opacity: 1;}
`;
const animsstar = keyframes`
    0% {transform: rotate(0deg) scaleX(1.05) scale(0.95);}
    100% {transform: rotate(4deg) scaleX(1.05) scale(1);}
`;
const animsstarflip = keyframes`
    0% {transform: rotate(0deg) scaleX(-1) scale(0.95);}
    100% {transform: rotate(-4deg) scaleX(-1.05) scale(1);}
`;
const StarsLeft = styled.div`
  ${stars}
  bottom: 22vw;
  left: 0vw;
  animation: ${starin} 1s 1 ease-in-out,
    ${animsstar} 1.4s ease-in-out infinite alternate-reverse;
`;
const StarsRight = styled.div`
  ${stars}
  transform: scaleX(-1);
  bottom: 22vw;
  right: 0;
  animation: ${starin} 1s 1 ease-in-out,
    ${animsstarflip} 1.4s ease-in-out infinite alternate-reverse;
`;
const ribbonstart = keyframes`
    0%{bottom: 11vw; opacity: 0;}
    100%{bottom: 13vw; opacity: 1;}
`;
const ribbonanims = keyframes`
    0% {transform: scale(1);}
    50% {transform: scale(0.95);}
    100% {transform: scale(1);}
`;
const Ribbon = styled.div`
  background-image: url(../../images/levelup_popup/ribbon.png);
  background-size: contain, cover;
  background-position: center top;
  background-repeat: no-repeat;
  width: 100%;
  height: 30%;
  position: absolute;
  z-index: 73;
  bottom: 13vw;
  animation: ${ribbonstart} 1s 1, ${ribbonanims} 2s 1s ease-in-out infinite;
  p {
    color: white;
    text-align: center;
    font-size: 5vw;
    top: 3vw;
    line-height: 8vw;
    text-shadow: 0px 1vw 1vw rgba(0, 0, 0, 0.25);
  }
`;
const circleanims = keyframes`
    0% {transform: scale(0);}
    100% {transform: scale(1);}
`;
const Circle = styled.div`
  background-image: url(../../images/levelup_popup/ellipse.png);
  background-size: contain, cover;
  background-position: center top;
  background-repeat: no-repeat;
  width: 60%;
  height: 50%;
  position: absolute;
  left: 20%;
  top: 10%;
  margin: 0 auto;
  z-index: 72;
  animation: ${circleanims} 0.7s cubic-bezier(0.68, 0.55, 0.465, 1.55);
  p {
    color: white;
    font-size: 12vw;
    width: 100%;
    text-align: center;
    top: 10%;
    position: relative;
    text-shadow: 0px 1vw 3vw rgba(197, 76, 38, 0.8);
  }
`;
const spinstart = keyframes`
    0% {transform: scale(0); opacity: 0%;}
    60% {transform: scale(1); opacity: 0%;}
    100% {transform: scale(1); opacity: 100%;}
`;
const spinidle = keyframes`
    0% {transform: rotate(300deg) scale(1); opacity: 0%;}
    50% {transform: rotate(330deg) scale(1.3);opacity: 100%;}
    100% {transform: rotate(360deg) scale(1.6); opacity: 0%;}
`;
const spinidle2 = keyframes`
    0% {transform: rotate(100deg) scale(1); opacity: 0%;}
    50% {transform: rotate(130deg) scale(1.3);opacity: 100%;}
    100% {transform: rotate(160deg) scale(1.6); opacity: 0%;}
`;
const flare = css`
  background-image: url(../../images/levelup_popup/flare.png);
  background-size: contain, cover;
  background-position: center top;
  background-repeat: no-repeat;
  // background-color:red;
  mix-blend-mode: hard-light;
  width: 80%;
  height: 80%;
  position: absolute;
  z-index: 71;
  margin: 0 auto;
`;
const Flare1 = styled.div`
  ${flare}
  top: -3vw;
  animation: ${spinstart} 1s ease-out, ${spinidle} 1.5s infinite linear;
  animation-delay: 0, 1s;
`;
const Flare2 = styled.div`
  ${flare}
  top: -3vw;
  animation: ${spinstart} 1s ease-out, ${spinidle2} 1.8s infinite linear;
  animation-delay: 0, 1s;
`;

function LevelupPopup(props) {
  const profile = useSelector(selectuserProfile);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <BG>
      <Container>
        <ConfirmBtn
          onClick={() => {
            dispatch(actionClickSound());
            props.setLevelUpPopup(false);
          }}
        >
          <p>{t("got it")}</p>
        </ConfirmBtn>
        <Starcontainer>
          <StarsLeft />
          <StarsRight />
        </Starcontainer>
        <Ribbon>
          <p>{t("level up")}</p>
        </Ribbon>
        <Circle>
          <p>{profile.level}</p>
        </Circle>
        <Flare1 />
        <Flare2 />
      </Container>
    </BG>
  );
}

export default LevelupPopup;
