import styled from "styled-components";
import { useDispatch } from "react-redux";
import {
  actionCloseSound,
} from "../Reducers/userProfileSlice";
import { config } from "../config";
const BGPopup = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 30;
  align-items: center;
  justify-content: center;
`;

const CloseBtn = styled.img`
  user-select: none;
  position: absolute;
  width: 4vw;
  height: 4vw;
  right: -2.5%;
  top: -1.5vw;
  cursor: pointer;
`;

const Popup = styled.div`
  user-select: none;
  // background-image: url("/images/popup_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  // background: #302e3c;
  // box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f, inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  // border: solid 0.2vw orange;
  border-radius: 2vw;
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;
  position: relative;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function PopupIframe(props) {
  const dispatch = useDispatch();

  return (
    <BGPopup>
      <Popup
        style={{
          width:
            window.innerWidth / window.appHeight() > 2.15 ? "95vw" : "95vw",
          height:
            window.innerWidth / window.appHeight() > 2.15 ? "58vw" : "46vw",
        }}
      >
        <CloseBtn
          src={"../" + config.theme + "/btn_close.png"}
          onClick={() => {
            props.closeClick();
            dispatch(actionCloseSound());
          }}
        />
        {/* 
        <iframe src="https://www.facebook.com/spinixthailand"></iframe> */}

        <iframe title="title" src={props.url}></iframe>
      </Popup>
    </BGPopup>
  );
}
export default PopupIframe;
