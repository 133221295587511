import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  actionCloseSound,
  selectuserProfile,
} from "../../Reducers/userProfileSlice";
import { Transition, config } from "../../config";
import NormalDeposit from "../NormalDeposit";
import { selectaccountlSliceSlice } from "../../Reducers/getLastBankSlice";
import QRDeposit from "./QR";
import QRHistory from "./QR/history";

const BGPopup = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 30;
  align-items: center;
  justify-content: center;
`;

const Popup = styled.div`
  user-select: none;
  // background-image: url("/images/popup_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;
  // theme ==================
  /* background: #192756;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #5DD8FF, inset 0px 0px 4vw rgba(66, 255, 232, 0.63);
  border: solid 0.2vw #BDEFFF; */
  // theme ==================
  border-radius: 1.5vw;
  position: relative;
  z-index: 20;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  width: 33vw;
  height: fit-content;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 63vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 42vw;
        `}
`;

const CloseBtn = styled.img`
  user-select: none;
  position: absolute;
  width: 4vw;
  height: 4vw;
  right: -6.5%;
  top: -1.5vw;
  cursor: pointer;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 7vw;
          height: 7vw;
          right: -4.5%;
          top: -3.5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 5vw;
          height: 5vw;
          right: -5.5%;
          top: -2.5vw;
        `}
`;

const Title = styled.div`
  user-select: none;
  top: 0px;
  width: max-content;
  display: flex;
  /* margin-left: 5vw; */
  color: white;
  height: 5.54vw;
  font-size: 2vw;
  line-height: 5vw;
  z-index: 0;
  font-weight: 400;
  text-align: center;
  padding: 0 5vw;
  align-items: center;
  margin: -0.2vw auto;
  background-clip: border-box, border-box, content-box;
  background-size: 8.69vw, 8.69vw, 1.07vw;
  background-position: top 0 left 0, top 0 right 0, top 0 right 0;
  background-repeat: no-repeat, no-repeat, repeat-x;
  background-image: url("../${config.theme}/popup_title_bg_left.png"),
    url("../${config.theme}/popup_title_bg_right.png"),
    url("../${config.theme}/popup_title_bg_center.png");
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          background-position: top 0 left -2px, top 0 right 0px, top 0 right 0;
          height: 8.54vw;
          background-size: 11.69vw, 11.69vw, 1.43vw;
          padding: 0px 7vw;
          font-size: 3vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          background-position: top 0 left -2px, top 0 right 0px, top 0 right 0;
          height: 6.54vw;
          background-size: 9.69vw, 9.69vw, 1.2vw;
          padding: 0px 6vw;
          font-size: 2.2vw;
        `}
`;

const BoxBank = styled.div`
  width: 90%;
  height: 22vw;
  background-color: black;
  position: relative;
  border-radius: 12px;
  margin: 1vw auto;
  overflow: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar-track {
    margin-right: 1vw;
  }

  ::-webkit-scrollbar-thumb {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 10;
    position: absolute;
    width: 11px;
    height: 80px;
    background: linear-gradient(180deg, #e13b00 38.02%, #900000 100%);
    box-shadow: inset 0px 0px 4px 2px #ff814d;
    border-radius: 10px;
  }

  ::-webkit-scrollbar {
    width: 0.5vw;
    height: 0vw;
    z-index: 10;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 59vw;
          height: 44vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 39vw;
          height: 31vw;
        `}
`;

const BankItem = styled.div`
  cursor: pointer;
  display: flex;
  width: 90%;
  margin: auto;
  padding-top: 1vw;
  padding-bottom: 1vw;
  align-items: center;
`;

const BankImages = styled.img`
  width: 5vw;
  margin-right: 1vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 10vw;
          margin-right: 1vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 7vw;
          margin-right: 1vw;
        `}
`;

const BankText = styled.div`
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.3vw;
  line-height: 1.85vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 3vw;
          line-height: 4.5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 2vw;
          line-height: 2.5vw;
        `}
`;

const BankInformation = styled.div`
  display: flex;
  flex-direction: column;
`;

const NoBank = styled.div`
  font-size: 1.5vw;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          span {
            font-size: 3vw;
          }
        `
      : window.appHeight() > window.innerWidth &&
        css`
          span {
            font-size: 2vw;
          }
        `}
`;

const Line = styled.div`
  margin: auto;
  width: 23vw;
  height: 0px;
  top: 2vw;
  border: 1px solid #595670;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 53vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 35vw;
        `}
`;

const Text = styled.div`
  color: red;
  font-size: 1vw;
  position: relative;
  display: flex;
  top: -0.7vw;
  margin: 0vw 1vw 0vw 1.5vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2.2vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 1.4vw;
        `}
`;

const HistoryButton = styled.div`
  position: absolute;
  cursor: pointer;
  right: 5%;
  width: 25%;
  height: 40%;
  background: #423f54;
  border-radius: 0.5vw;
  color: #fff;
  font-size: 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 480px) and (orientation: portrait) {
    font-size: 2vw;
  }
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 1.5vw;
  }
`;

function SelectBankNormal(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [normalDeposit, setNormalDeposit] = useState(false);
  const [bankInfo, setBankInfo] = useState({});
  const [qrPop, setQrPop] = useState(false);
  const [qrHistoetPop, setQrHistoryPop] = useState(false);
  const account = useSelector(selectaccountlSliceSlice);
  const profile = useSelector(selectuserProfile);
  useEffect(() => {
    // socket.emit("getallbankagent", {
    // }, (body) => {
    //     console.log("getallbankagent",body)
    //     setObjectBank(body.results.data)
    //     setInfoReady(true)
    // });
  }, []);
  // console.log("profile", profile);
  return (
    <>
      <BGPopup>
        <Popup style={{ transform: "scale(1.4)" }}>
          <CloseBtn
            src={"../" + config.theme + "/btn_close.png"}
            onClick={() => {
              props.closeClick();
              dispatch(actionCloseSound());
            }}
          />
          <Title>{t("select all bank title")}</Title>
          <BoxBank>
            {account.length ? (
              account.map((item, index) => (
                <div
                  key={index}
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <BankItem
                    id={
                      profile.brands_id +
                      "btn-deposit-bank-" +
                      item.bank_name_en
                    }
                    onClick={() => {
                      setBankInfo(item);
                      item.type_bank_id === 37
                        ? setQrPop(true)
                        : setNormalDeposit(true);
                      // dispatch(actionPopup({ normaldeposit: true }));
                    }}
                  >
                    <BankImages
                      src={
                        "../" +
                        config.theme +
                        "/bicon_" +
                        (item.short_name || item.bank_short_name) +
                        ".png"
                      }
                    />
                    <BankInformation>
                      <BankText>
                        {item.type_bank_id === 37
                          ? ""
                          : profile.language === "TH"
                          ? item.bank_name
                          : item.bank_name_en}
                      </BankText>
                      <BankText style={{ fontWeight: "bold" }}>
                        {item.type_bank_id === 37
                          ? "QR Promptpay"
                          : item.bank_account}
                      </BankText>
                      {item.bank_short_name === "tmw" ? (
                        ""
                      ) : (
                        <BankText>
                          {item.type_bank_id === 37
                            ? t("QR Topup")
                            : item.account_name}
                        </BankText>
                      )}
                    </BankInformation>
                  </BankItem>
                  {item.type_bank_id === 37 && (
                    <HistoryButton
                      onClick={() => {
                        setQrHistoryPop(true);
                      }}
                    >
                      {t("QR History Button")}
                    </HistoryButton>
                  )}
                  <Line />
                </div>
              ))
            ) : (
              <NoBank>
                <span>{t("nobank")}</span>
              </NoBank>
            )}
          </BoxBank>

          <Text>{t("select all bank")}</Text>
        </Popup>
      </BGPopup>

      <Transition showPop={normalDeposit} type="st2b">
        <NormalDeposit
          closeClick={() => {
            setNormalDeposit(false);
            // dispatch(actionPopup({ normalDeposit: false }));
          }}
          bankInfo={bankInfo}
        />
      </Transition>

      {qrPop && (
        <QRDeposit closeClick={() => setQrPop(false)} bankInfo={bankInfo} />
      )}
      {qrHistoetPop && <QRHistory closeClick={() => setQrHistoryPop(false)} />}
    </>
  );
}
export default SelectBankNormal;
