import React, { useState, lazy, useContext, useEffect } from "react";
import styled, { css } from "styled-components";
import Switch from "../Switch";
import "../style.css";
import { Transition, config } from "../../config";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  depositBonus,
  depositTrigger,
  setDeposit,
} from "../../Reducers/settingSlice";
import { SocketContext } from "../../context/socket";
import { selectPopup, actionPopup } from "../../Reducers/popupSlice";
import {
  actionClickSound,
  selectuserProfile,
  actionCloseSound,
  actionPigSound,
} from "../../Reducers/userProfileSlice";
import { style } from "dom-helpers";
import UploadSlip from "../DepositWithdrawal/UploadSlip";
import DepositSlice from "../DepositSlice/index";
import Withdraw from "../Withdrawal";
import NormalDeposit from "../NormalDeposit";
import DecimalDeposit from "../DecimalDeposit";
import DecimalPopup from "../DecimalDeposit/DecimalDepositPopup";
import { selectTopupSlice } from "../../Reducers/TopupSlice";
import moment from "moment";
// import { useDispatch } from "react-redux";
import {
  actionLastBankSlice,
  actionaccountlSlice,
  actionfloatinglSlice,
} from "../../Reducers/getLastBankSlice";
import ScaleFrame from "../ScaleFrame";
import breakpoints from "../../LandingPageComponents/breakpoints";
import SelectBankNormal from "../NormalDeposit/SelectBankNormal";
import SelectBankDecimal from "../DecimalDeposit/SelectBankDecimal";
import ConfirmPopUp from "../Dialog/ConfirmPopup";
import SelectNormal from "./SelectPromotion/SelectNormal";
import SelectByType from "./SelectPromotion/SelectByType";
import { selectLoading, actionLoading } from "../../Reducers/loadingSlice";

const uploadSlip = lazy(() => import("../DepositWithdrawal/UploadSlip"));

const BGPopup = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 70;
  align-items: center;
  justify-content: center;
`;

const CloseBtn = styled.img`
  user-select: none;
  position: absolute;
  width: 4vw;
  height: 4vw;
  right: -2.5%;
  top: -1.5vw;
  cursor: pointer;
  z-index: 1;
  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            width: 8.5vw;
            height: 8.5vw;
            top: -4vw;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            width: 6vw;
            height: 6vw;
            right: -3.5%;
            top: -2.5vw;
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            width: 8.5vw;
            height: 8.5vw;
            top: -4vw;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            width: 6vw;
            height: 6vw;
            right: -3.5%;
            top: -2.5vw;
          }
        `};
`;

const BgClose = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  background-color: transparent;
  z-index: 20;
  left: 0%;
  top: 0%;
  position: fixed;
`;

const Popup = styled.div`
  user-select: none;
  // background-image: url("/${config.theme}/popup_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;
  border-radius: 2vw;
  // theme ==================
  /* background: #192756;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #5DD8FF, inset 0px 0px 4vw rgba(66, 255, 232, 0.63);
  border: solid 0.2vw #BDEFFF; */
  // theme ==================
  position: relative;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  // เพิ่ม padding ด้านข้างกรณี title ทะลุ start below ========
  padding: 0 2vw;
  width: 80vw;
  height: 40vw;

  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            width: 88vw;
            height: ${(props) => (props.proRedeem ? "160vw" : "135vw")};
            display: flow-root;
            ${() =>
              window.onKeyBoard &&
              css`
                margin-bottom: 150%;
              `}
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            width: 57vw;
            height: ${(props) => (props.proRedeem ? "95vw" : "85vw")};
            display: flow-root;
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            width: 88vw;
            height: ${(props) => (props.proRedeem ? "160vw" : "135vw")};
            display: flow-root;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            width: 57vw;
            height: ${(props) => (props.proRedeem ? "95vw" : "85vw")};
            display: flow-root;
          }
        `}
`;

const Title = styled.div`
  user-select: none;
  top: -3px;
  width: 22%;
  display: flex;
  justify-content: center;
  left: 4vw;
  position: absolute;
  color: white;
  height: 5.54vw;
  font-size: 1.5vw;
  line-height: 5vw;
  z-index: 0;
  font-weight: 400;
  text-align: center;
  padding: 0 5vw;
  background-clip: border-box, border-box, content-box;
  background-size: 8.69vw, 8.69vw, 1.07vw;
  background-position: top 0 left 0, top 0 right 0, top 0 right 0;
  background-repeat: no-repeat, no-repeat, repeat-x;
  background-image: url("../${config.theme}/popup_title_bg_left.png"),
    url("../${config.theme}/popup_title_bg_right.png"),
    url("../${config.theme}/popup_title_bg_center.png");
  //Portrait

  ${() =>
    window.deviceNotApple
      ? css`
          @media screen and (max-device-aspect-ratio: 13/9) {
            background-size: 12.69vw, 12.69vw, 1.57vw;
            background-position: top 0px left 0px, top 0 right 0px,
              top 0 right 0;
            left: auto;
            width: auto;
            height: 100%;
            font-size: 4vw;
            padding: 0px 8vw;
            align-items: center;
            top: 0;
          }
        `
      : css`
          @media (orientation: portrait) {
            background-size: 12.69vw, 12.69vw, 1.57vw;
            background-position: top 0px left 0px, top 0 right 0px,
              top 0 right 0;
            left: auto;
            width: auto;
            height: 100%;
            font-size: 3.5vw;
            padding: 0px 8vw;
            align-items: center;
            top: 0;
          }
        `}
`;

const Greybar = styled.div`
  user-select: none;
  width: 0.2vw;
  height: 33vw;
  background-color: #474761;
  position: absolute;
  left: 44%;

  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            display: none;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            height: 0vw;
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            display: none;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            height: 0vw;
          }
        `}
`;

const BGpigleft = styled.div`
  user-select: none;
  background: linear-gradient(180deg, #59e93b 0%, #62d706 28.13%, #358003 100%);
  box-shadow: inset 0px 0px 4px 3px #4dff80;
  width: 47%;
  height: 40%;
  border-radius: 1.5vw;
  position: absolute;
  margin-top: 12%;
  left: 1%;
  cursor: pointer;
  //Portrait
  display: flex;
  flex-direction: column;
  align-items: center;
  ${() =>
    window.deviceNotApple
      ? css`
          @media screen and (max-device-aspect-ratio: 13/9) {
            margin-top: 2%;
            left: 0%;
            width: 49%;
            height: 19vw;
            border-radius: 2.5vw;
            display: flex;
            align-items: center;
            flex-direction: row;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            left: 4%;
            width: 45%;
            height: 10vw;
            display: flex;
            align-items: center;
          }
        `
      : css`
          @media (orientation: portrait) {
            margin-top: 2%;
            left: 0%;
            width: 49%;
            height: 19vw;
            border-radius: 2.5vw;
            display: flex;
            align-items: center;
            flex-direction: row;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            left: 4%;
            width: 45%;
            height: 10vw;
            display: flex;
            align-items: center;
          }
        `}
`;

const BGpigright = styled.div`
  cursor: pointer;
  user-select: none;
  background: linear-gradient(180deg, #59e93b 0%, #62d706 28.13%, #358003 100%);
  box-shadow: inset 0px 0px 4px 3px #4dff80;
  width: 47%;
  height: 40%;
  border-radius: 1.5vw;
  position: absolute;
  margin-top: 12%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            margin-top: 2%;
            left: 51%;
            width: 49%;
            height: 19vw;
            border-radius: 2.5vw;
            display: flex;
            align-items: center;
            flex-direction: row;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            width: 45%;
            height: 10vw;
            display: flex;
            align-items: center;
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            margin-top: 2%;
            left: 51%;
            width: 49%;
            height: 19vw;
            border-radius: 2.5vw;
            display: flex;
            align-items: center;
            flex-direction: row;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            width: 45%;
            height: 10vw;
            display: flex;
            align-items: center;
          }
        `}
`;

const Normaldepositpig = styled.img`
  user-select: none;
  width: 17vw;
  height: 14.5vw;
  margin-top: -2.5vw;
  cursor: pointer;

  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            width: 29vw;
            height: 26vw;
            margin-top: -7.5vw;
            margin-left: -2vw;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            width: 15.12vw;
            height: 13.32vw;
            margin-top: -2.5vw;
            margin-left: -0.75vw;
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            width: 29vw;
            height: 26vw;
            margin-top: -7.5vw;
            margin-left: -2vw;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            width: 15.12vw;
            height: 13.32vw;
            margin-top: -2.5vw;
            margin-left: -0.75vw;
          }
        `}
`;

const Textdeposit = styled.div`
  flex-direction: column;
  justify-content: center;
  display: flex;
  /* left: 1vw; */
  align-items: center;
  top: -0.5em;
  position: relative;
  color: #fff;
  font-size: 1.2vw;
  text-shadow: 0 0.3vw 0.7vw black;
  line-height: 100%;

  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            font-size: 4vw;
            left: -3vw;
            top: auto;
            width: 19vw;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            font-size: 2.5vw;
            left: 48%;
            top: auto;
            width: 12vw;
            position: absolute;
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            font-size: 4vw;
            left: -3vw;
            top: auto;
            width: 19vw;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            font-size: 2.5vw;
            left: 48%;
            top: auto;
            width: 12vw;
            position: absolute;
          }
        `}
`;

const Textdecimal = styled.div`
  flex-direction: column;
  justify-content: center;
  display: flex;
  /* left: 1vw; */
  top: -0.5em;
  position: relative;
  color: #fff;
  font-size: 1.2vw;
  text-align: center;
  text-shadow: 0 0.3vw 0.7vw black;
  line-height: 100%;

  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            font-size: 4vw;
            left: -3vw;
            top: auto;
            width: 19vw;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            font-size: 2.5vw;
            left: 48%;
            top: auto;
            width: 12vw;
            position: absolute;
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            font-size: 4vw;
            left: -3vw;
            top: auto;
            width: 19vw;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            font-size: 2.5vw;
            left: 48%;
            top: auto;
            width: 12vw;
            position: absolute;
          }
        `}
`;

const HR = styled.div`
  position: relative;
  width: 90%;
  height: 0.2vw;
  background-color: #ffffff26;
  top: 2vw;
  display: flex;

  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            width: 100%;
            left: 0%;
            height: 0.3vw;
            top: 60vw;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            width: 100%;
            left: 0%;
            height: 0.3vw;
            top: 34.5vw;
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            width: 100%;
            left: 0%;
            height: 0.3vw;
            top: 60vw;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            width: 100%;
            left: 0%;
            height: 0.3vw;
            top: 34.5vw;
          }
        `}
`;

const Decimaldeposit = styled.img`
  user-select: none;
  width: 17vw;
  height: 14.5vw;
  margin-top: -2.5vw;
  cursor: pointer;

  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            width: 29vw;
            height: 26vw;
            margin-top: -7.4vw;
            margin-left: -2.5vw;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            width: 15.12vw;
            height: 13.32vw;
            margin-top: -2.5vw;
            margin-left: -0.75vw;
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            width: 29vw;
            height: 26vw;
            margin-top: -7.4vw;
            margin-left: -2.5vw;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            width: 15.12vw;
            height: 13.32vw;
            margin-top: -2.5vw;
            margin-left: -0.75vw;
          }
        `}
`;

const Withdrawal = styled.div`
  user-select: none;
  display: flex;
  position: absolute;
  background-image: url("../${config.theme}/btn_withdraw.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 19.5vw;
  height: 5vw;
  color: white;
  align-self: center;
  align-items: center;
  justify-content: center;
  top: 54%;
  cursor: pointer;
  span {
    font-size: 1.5vw;
    padding-left: 1vw;
    line-height: 5vw;
    color: white;
  }
  img {
    vertical-align: middle;
    width: 4vw;
    height: 3.5vw;
  }

  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            width: 42vw;
            height: 11vw;
            top: ${(props) => (props.proRedeem ? "34%" : "41%")};
            span {
              font-size: 3vw;
              padding-left: 1vw;
              line-height: 5vw;
              color: white;
            }
            img {
              vertical-align: middle;
              width: 9vw;
              height: 8.5vw;
            }
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            width: 25vw;
            height: 7vw;
            top: ${(props) => (props.proRedeem ? "31%" : "35%")};
            span {
              font-size: 2.5vw;
              padding-left: 1vw;
              line-height: 3vw;
              color: white;
            }
            img {
              vertical-align: middle;
              width: 5vw;
              height: 4.5vw;
            }
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            width: 42vw;
            height: 11vw;
            top: ${(props) => (props.proRedeem ? "34%" : "41%")};
            span {
              font-size: 3vw;
              padding-left: 1vw;
              line-height: 5vw;
              color: white;
            }
            img {
              vertical-align: middle;
              width: 9vw;
              height: 8.5vw;
            }
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            width: 25vw;
            height: 7vw;
            top: ${(props) => (props.proRedeem ? "31%" : "35%")};
            span {
              font-size: 2.5vw;
              padding-left: 1vw;
              line-height: 3vw;
              color: white;
            }
            img {
              vertical-align: middle;
              width: 5vw;
              height: 4.5vw;
            }
          }
        `}
`;

// const BGError = styled.div`
//   user-select: none;
//   position: absolute;
//   background-color: #26242f;
//   width: 30.5vw;
//   height: 7vw;
//   border-radius: 1.5vw;
//   top: 78%;
//   left: 1.5vw;
//   img {
//     margin-top: 1vw;
//     margin-left: 0.5vw;
//     vertical-align: middle;
//     width: 5.5vw;
//     height: 5vw;
//   }
// `;

const Texterror = styled.div`
  user-select: none;
  color: white;
  font-size: 0.8vw;
  margin-top: -5.5vw;
  margin-left: 7.5vw;
  width: 23vw;
`;

const btnregister = css`
  width: 22vw;
  height: 3.5vw;
  border-radius: 0.8vw;
  outline: none;
  border: none;
  font-size: 1.2vw;
  cursor: pointer;
  margin-top: 0.2vw;
  margin-left: 7vw;
  position: absolute;
`;
const BtnSlip = styled.button`
  ${btnregister}
  background: linear-gradient(180deg, #ffc700 30%, #ff8000 83.5%);
  box-shadow: inset 0px 0px 3px 3px #ffaf08;
  color: #731907;
`;
const TakeBonus = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: space-evenly;
  align-items: center;
  background: #17161d;
  padding: 0 auto;
  border-radius: 1vw;
  width: 100%;
  margin: 0 auto;
  img {
    width: 3vw;
    height: 3vw;
  }

  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            height: 100%;
            align-items: center;
            justify-content: space-evenly;
            border-radius: 2vw;
          }
          img {
            width: 5vw;
            height: 5vw;
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            height: 100%;
            align-items: center;
            justify-content: space-evenly;
            border-radius: 2vw;
            img {
              width: 5vw;
              height: 5vw;
            }
          }
        `}
`;
const Blackbg = styled.div`
  // background: #26242F;
  border-radius: 2vw;
  text-align: right;
  color: white;
  padding: 0.5vw;
  position: absolute;
  float: right;
  position: relative;
`;

const Blacktext = styled.div`
  // background: #26242F;
  border-radius: 2vw;
  color: white;
  width: 17.5%;
  height: 13.5%;
  bottom: 5.5%;
  right: 14%;
  position: absolute;
`;

const Clear = styled.div`
  font-size: 1.5vw;
  align-self: left;
  position: relative;
  color: white;
  white-space: pre-line;
  width: 65%;
  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            font-size: 3vw;
            margin: 0;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            font-size: 1.5vw;
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            font-size: 3vw;
            margin: 0;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            font-size: 1.5vw;
          }
        `}
`;

const Text = styled.div`
  font-weight: 100;
  font-size: 1.45vw;
  color: white;
  // right: 32%;
  // bottom: 9.3%;
  top: 0;
  position: relative;
  text-align: start;
  width: 100%;

  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            font-size: 3.45vw;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            font-size: 2.45vw;
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            font-size: 3.45vw;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            font-size: 2.45vw;
          }
        `}
`;

const Receive = styled.div`
  position: absolute;
  width: 33vw;
  height: 9.5vw;
  z-index: 90;
  top: 71%;

  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            width: 100%;
            height: 18vw;
            top: ${(props) => (props.proRedeem ? "52%" : "62%")};
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            width: 100%;
            height: 10vw;
            top: ${(props) => (props.proRedeem ? "50%" : "55%")};
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            width: 100%;
            height: 18vw;
            top: ${(props) => (props.proRedeem ? "52%" : "62%")};
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            width: 100%;
            height: 10vw;
            top: ${(props) => (props.proRedeem ? "50%" : "55%")};
          }
        `}
`;

const AttentionContainer = styled.div`
  width: 100%;
  // background: blue;
  height: max-content;
  display: flex;
  left: -2vw;
  bottom: 0.3vw;
  position: absolute;

  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            width: 50%;
            position: relative;
            left: 0%;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            position: relative;
            width: 50%;
            left: 0%;
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            width: 50%;
            position: relative;
            left: 0%;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            position: relative;
            width: 50%;
            left: 0%;
          }
        `}
`;
const Attention = styled.div`
  color: #ff7777;
  font-size: 1.3vw;
  position: relative;
  line-height: 1.5vw;
  width: 25%;
  height: fit-content;
  text-align: left;
  text-shadow: 0 0.2vw 0.3vw black;
  font-weight: 400;

  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            font-size: 3vw;
            width: 27%;
            text-align: right;
            line-height: 3.5vw;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            margin-left: 0.5vw;
            font-size: 1.8vw;
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            font-size: 3vw;
            width: 27%;
            text-align: right;
            line-height: 3.5vw;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            margin-left: 0.5vw;
            font-size: 1.8vw;
          }
        `}
`;

const Attentiontext = styled.div`
  color: #ff7777;
  font-size: 1.3vw;
  position: relative;
  line-height: 1.5vw;
  width: ${(props) => (props.lang == "th" ? "58%" : "85%")};
  text-align: left;
  text-shadow: 0 0.2vw 0.3vw black;
  font-weight: 400;

  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            line-height: 100%;
            width: 95%;
            font-size: 3vw;
            text-align: left;
            left: 5%;
            white-space: pre-line;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            line-height: 100%;
            width: 95%;
            font-size: 2vw;
            text-align: left;
            left: 5%;
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            line-height: 100%;
            width: 95%;
            font-size: 3vw;
            text-align: left;
            left: 5%;
            white-space: pre-line;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            line-height: 100%;
            width: 95%;
            font-size: 2vw;
            text-align: left;
            left: 5%;
          }
        `}
`;

const DepositLeftLayout = styled.div`
  width: 43%;
  height: 85%;
  margin-top: 7%;
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 1;
  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            width: 95%;
            height: 40%;
            margin-top: auto;
            top: ${(props) => (props.proRedeem ? "44%" : "54%")};
            position: absolute;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            width: 93%;
            top: ${(props) => (props.proRedeem ? "42.5vw" : "44.5vw")};
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            width: 95%;
            height: 40%;
            margin-top: auto;
            top: ${(props) => (props.proRedeem ? "44%" : "54%")};
            position: absolute;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            width: 93%;
            top: ${(props) => (props.proRedeem ? "42.5vw" : "44.5vw")};
          }
        `}
`;

const BoxTitle = styled.div`
  /* background: blue; */
  display: flex;
  height: 10vw;
  position: relative;
  width: auto;
  justify-content: center;
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    height: 9vw;
  }
`;

const RightBox = styled.div`
  width: 57%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  pointer-events: none;
  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            width: 100%;
            align-items: center;
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            width: 100%;
            align-items: center;
          }
        `}
`;

const RedeemBox = styled.div`
  display: flex;
  align-items: flex-end;
  position: relative;
  width: 90%;
  height: 8vw;
  pointer-events: auto;

  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            position: absolute;
            flex-direction: column;
            width: 100%;
            height: 25vw;
            top: 123vw;
            left: 0%;
            align-items: center;
            justify-content: space-between;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            position: absolute;
            flex-direction: column;
            width: 100%;
            height: 14vw;
            top: 70vw;
            left: 0%;
            align-items: center;
            justify-content: space-between;
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            position: absolute;
            flex-direction: column;
            width: 100%;
            height: 25vw;
            top: 123vw;
            left: 0%;
            align-items: center;
            justify-content: space-between;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            position: absolute;
            flex-direction: column;
            width: 100%;
            height: 14vw;
            top: 70vw;
            left: 0%;
            align-items: center;
            justify-content: space-between;
          }
        `}
`;

const LineButton = styled.div`
  cursor: pointer;
  width: 28%;
  height: 45%;
  padding-left: 5%;
  background-image: url("../${config.theme}/newasset/btnLineContact.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  white-space: pre-line;
  font-size: 1.3vw;
  color: #fff;
  line-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            font-size: 3.5vw;
            width: 45%;
            height: 100%;
            margin-left: 5%;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            font-size: 2.25vw;
            width: 30%;
            height: 100%;
            margin-left: 5%;
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            font-size: 3.5vw;
            width: 45%;
            height: 100%;
            margin-left: 5%;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            font-size: 2.25vw;
            width: 30%;
            height: 100%;
            margin-left: 5%;
          }
        `}
`;

const RedeemInput = styled.input`
  width: 94%;
  height: 45%;
  border: 0vw;
  border-radius: 2vw;
  background-color: #1c1a23;
  padding: 0%;
  font-size: 1.3vw;
  color: #fff;
  padding-left: 3%;
  margin-left: 3%;

  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            margin-left: 0%;
            width: 95%;
            height: 100%;
            border-radius: 7vw;
            font-size: 3.5vw;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            margin-left: 0%;
            width: 95%;
            height: 100%;
            border-radius: 7vw;
            font-size: 2.5vw;
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            margin-left: 0%;
            width: 95%;
            height: 100%;
            border-radius: 7vw;
            font-size: 3.5vw;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            margin-left: 0%;
            width: 95%;
            height: 100%;
            border-radius: 7vw;
            font-size: 2.5vw;
          }
        `}
`;

const RedeemButton = styled.div`
  cursor: pointer;
  width: 23%;
  height: 45%;
  right: 0%;
  bottom: 0%;
  border-radius: 2vw;
  position: absolute;
  background: linear-gradient(180deg, #fffe37 0%, #dc7a13 100%);
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5), inset 0px 0px 3px #ffff00;
  white-space: pre-line;
  font-size: 1.3vw;
  color: #230000;
  line-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            font-size: 3.5vw;
            width: 35%;
            height: 100%;
            border-radius: 7vw;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            font-size: 2.25vw;
            width: 35%;
            height: 100%;
            border-radius: 7vw;
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            font-size: 3.5vw;
            width: 35%;
            height: 100%;
            border-radius: 7vw;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            font-size: 2.25vw;
            width: 35%;
            height: 100%;
            border-radius: 7vw;
          }
        `}
`;

const RedeemContent = styled.div`
  width: 67.9%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;

  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            width: 95%;
            height: 45%;
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            width: 95%;
            height: 45%;
          }
        `}
`;

const RedeemText = styled.div`
  font-size: 1.3vw;
  color: #fff;
  text-align: center;

  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            font-size: 3.5vw;
            align-self: center;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            font-size: 2.5vw;
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            font-size: 3.5vw;
            align-self: center;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            font-size: 2.5vw;
          }
        `}
`;

const VerticalLineBox = styled.div`
  width: 95%;
  height: 45%;
  position: relative;
  display: flex;
`;

const BonusBox = styled.div`
  width: 100%;
  display: flex;
  height: 65%;
  ${() =>
    window.deviceNotApple
      ? css`
          //Portrait
          @media screen and (max-device-aspect-ratio: 13/9) {
            height: 83%;
            flex-direction: row;
            justify-content: center;
            align-items: center;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (max-device-aspect-ratio: 13/9) {
            height: 100%;
          }
        `
      : css`
          //Portrait
          @media (orientation: portrait) {
            height: 83%;
            flex-direction: row;
            justify-content: center;
            align-items: center;
          }
          //iPad
          @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
            height: 100%;
          }
        `}
`;

const SelectPromotionButton = styled.div`
  width: 18%;
  height: 80%;
  background: linear-gradient(180deg, #924524 0%, #211f29 78.12%);
  box-shadow: inset 0px 0px 0.8vw 0.16vw #ff7a00;
  border-radius: 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function Deposit(props) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  // const [withdrawal, setWithdrawal] = useState(false);
  const [uploadSlip, setUploadSlip] = useState(false);
  const [normalDeposit, setNormalDeposit] = useState(false);
  const [decimalDeposit, setDecimalDeposit] = useState(false);
  const [decimalPopup, setDecimalPopup] = useState(false);
  const [withdrawal, setWithdrawal] = useState(false);
  const depositSetting = useSelector(depositBonus);
  const profile = useSelector(selectuserProfile);
  const socket = useContext(SocketContext);
  const [emitError, setEmitError] = useState({ status: false, message: "" });
  const [decimalState, setDecimalState] = useState({
    amount: 0,
    time: "",
    id: "",
  });
  const [selectBankNormal, setSelectBankNormal] = useState(false);
  const [selectBankDecimal, setSelectBankDecimal] = useState(false);
  const amount = useSelector(selectTopupSlice);
  const [objectBank, setObjectBank] = useState([]);
  const [bankInfo, setBankInfo] = useState({});
  const popupStatus = useSelector(selectPopup);
  const [redeemInput, setRedeemInput] = useState("");
  const [redeemPop, setRedeemPop] = useState(false);
  const [redeemReward, setRedeemReward] = useState({ type: "", value: 0 });
  const [lineId, setLineId] = useState([{}]);
  const [normalSelect, setNormalSelect] = useState(false);
  const [byTypeSelect, setByTypeSelect] = useState(false);
  const [promotionInfo, setPromotionInfo] = useState({});
  const [proStatusChanged, setProStatusChanged] = useState(false);
  const load = useSelector(selectLoading);

  useEffect(() => {
    try {
      // dispatch(actionWinPopSound());
      socket.emit("getLineID", {}, (body) => {
        if (typeof body != "undefined") {
          if (typeof body.results != "undefined") {
            if (typeof body.results.data != "undefined") {
              if (body.status) {
                // console.log(body.results.data.line_id);
                if (
                  body.results.data.line_id &&
                  typeof body.results.data.line_id != undefined
                ) {
                  setLineId(body.results.data.line_id);
                }
              } else if (body.status === false) {
                setEmitError({ status: true, message: body.errorCode });
                setTimeout(() => {
                  setEmitError({ status: false });
                }, 3000);
              }
            }
          }
        }
      });
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  }, []);

  useEffect(() => {
    try {
      dispatch(actionLoading(false));
      socket.emit("getpromotionplayer", {}, (data) => {
        console.log("getpromotionplayer", data);
        if (data.status) {
          dispatch(actionLoading(true));
          setPromotionInfo(data.results);
        } else if (data.status === false) {
          setEmitError({ status: true, message: data.errorCode });
          setTimeout(() => {
            setEmitError({ status: false });
          }, 3000);
        }
      });
    } catch (error) {
      console.log("getpromotionplayer error", error);
    }
  }, [proStatusChanged, profile.balance]);

  const openInNewTab = (url) => {
    try {
      const newWindow = window.open(url, "_blank");
      if (newWindow) newWindow.opener = null;
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  };

  useEffect(() => {
    socket.emit("getPlayerBankTopupV2", {}, (body) => {
      // console.log("getPlayerBankTopup Deposit", body);
      let account_system = [];
      let floating_system = [];

      if (body.status === true) {
        // setObjectBank(body.results)
        if (Array.isArray(body.results)) {
          for (const item of body.results) {
            // console.log("item.account_system", item.account_system)

            if (typeof item.account_system != "undefined") {
              if (item.account_system.length > 0)
                for (const itemAccount of item.account_system) {
                  account_system.push(itemAccount);
                }
            }
            if (typeof item.floating_system != "undefined") {
              if (item.floating_system.length > 0)
                for (const itemFloating of item.floating_system) {
                  floating_system.push(itemFloating);
                }
            }

            // floating_system.push(item.floating_system)
          }
        }
      }

      // console.log("floating_system",floating_system)
      // console.log("account_system",account_system)
      setObjectBank(floating_system);
      dispatch(actionfloatinglSlice(floating_system));
      dispatch(actionaccountlSlice(account_system));

      //   let Bank = []
      //   for (const item of body.results[0].floating_system) {
      //     Bank.push({
      //       bankShortName: item.short_name,
      //       bankName: item.bank_name + " " + item.bank_account,
      //       bankName: item.bank_name,
      //       bankName_en: item.bank_name_en,
      //       account_name: item.account_name,
      //       bank_account: item.bank_account,
      //       bankID: item._id
      //     })
      //   }
      //   setBank(Bank)

      //   console.log("body", body);
      // bankShortName,bankName,bankName_en
      // setBank(data.results.account_system.);
      // setBankId(data.results.data[0].bankID);
    });
  }, []);

  useEffect(() => {
    try {
      dispatch(actionPigSound());
      if (profile.setbonus === "O") dispatch(setDeposit(true));
      else if (profile.setbonus === "C") dispatch(setDeposit(false));
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  }, [profile.setbonus]);

  // setDecimalPopup

  useEffect(() => {
    if (amount > 0) {
      console.log("amount change", amount);
      setDecimalPopup(false);
      setDecimalDeposit(false);
      // socket.emit("cancelpromptpay", {}, (data) => {
      //     console.log("cancelpromptpay", data)
      // });

      //
    }
  }, [amount]);

  const HandleSave = (bonus, music, fx) => {
    try {
      socket.emit(
        "setFormSetting",
        {
          sound: music,
          effect: fx,
          bonus: bonus ? "O" : "C",
          action_from: "S",
        },
        (body) => {
          if (typeof body != "undefined") {
            if (body.status) {
            } else if (body.status === false) {
              setEmitError({ status: true, message: body.errorCode });
              setTimeout(() => {
                setEmitError({ status: false });
              }, 3000);
            }
          } else {
          }
        }
      );
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  };

  const RedeemSubmit = () => {
    socket.emit(
      "getredeemreward",
      {
        code: redeemInput,
      },
      (data) => {
        console.log("getredeemreward", data);
        if (data.status) {
          setRedeemPop(true);
          setRedeemReward(data.results);
        } else {
          setEmitError({
            status: true,
            message: t(data.errorCode, { value: data.value }),
          });
        }
      }
    );
  };

  return (
    <BGPopup>
      <ScaleFrame>
        <BgClose onClick={props.closeClick} />
        <Popup proRedeem={profile.proRedeem === 1}>
          <CloseBtn
            src={"../" + config.theme + "/btn_close.png"}
            onClick={() => {
              props.closeClick();
              dispatch(actionCloseSound());
            }}
          />
          {window.innerWidth > window.appHeight() ? (
            <Title>{t("deposit withdrawals")}</Title>
          ) : (
            <BoxTitle>
              <Title>{t("deposit withdrawals")}</Title>
            </BoxTitle>
          )}
          <DepositLeftLayout proRedeem={profile.proRedeem === 1}>
            <BGpigleft
              id={profile.brands_id + "-btn-deposit"}
              onClick={() => {
                setSelectBankNormal(true);
                dispatch(actionPopup({ selectnormal: true }));
                socket.emit(
                  "getbilog",
                  {
                    biname: "normaldeposit_click",
                  },
                  (data) => {
                    console.log(data);
                  }
                );
              }}
            >
              <Normaldepositpig
                src={"../" + config.theme + "/icon_deposit.png"}
                id={profile.brands_id + "-btn-deposit"}
              />
              {/* <Detail> */}
              <Textdeposit>{t("normal deposit")}</Textdeposit>
              {/* </Detail> */}
            </BGpigleft>

            <BGpigright
              id={profile.brands_id + "-btn-deposit-floating"}
              onClick={() => {
                socket.emit(
                  "getbilog",
                  {
                    biname: "decimaldeposit_click",
                  },
                  (data) => {
                    console.log(data);
                  }
                );
                socket.emit("checkfloat", {}, (data) => {
                  if (data.status == true) {
                    // console.log("checkfloat", data);
                    data.results.time = moment(data.results.time)
                      .add("minutes", 3)
                      .toString();
                    setDecimalState(data.results);
                    objectBank.map((item, index) => {
                      if (item.type_bank_id == data.results.bank_id) {
                        setBankInfo(item);
                      } else setBankInfo(objectBank[0]);
                    });
                    setDecimalPopup(true);
                  } else {
                    setSelectBankDecimal(true);
                    dispatch(actionPopup({ selectdecimal: true }));
                  }
                });
                // {amount:100.50,time:"2021-11-11T04:30:47.986Z",id:"xxx" }
                // setDecimalState({ amount: 100.50, time: moment("2021-11-11T08:56:00.986Z").add("minutes", 3).toString(), id: "xxx" })
                // setDecimalPopup(true)
              }}
            >
              <Decimaldeposit
                src={"../" + config.theme + "/icon_decimal_deposit.png"}
                id={profile.brands_id + "-btn-deposit-floating"}
              ></Decimaldeposit>
              {/* <Detail> */}
              <Textdecimal>{t("decimal")}</Textdecimal>
              {/* </Detail> */}
            </BGpigright>

            <Withdrawal
              proRedeem={profile.proRedeem === 1}
              id={profile.brands_id + "-btn-withdraw"}
              onClick={() => {
                try {
                  socket.emit(
                    "getbilog",
                    {
                      biname: "withdraw_click",
                    },
                    (data) => {
                      console.log("withdraw bilog", data);
                    }
                  );
                } catch (error) {
                  console.log(error);
                }
                setWithdrawal(true);
                dispatch(actionClickSound());
                dispatch(actionPopup({ withdrawal: true }));
              }}
            >
              <img src={"../" + config.theme + "/icon_withdraw.png"}></img>{" "}
              <span>{t("withdrawal")}</span>
            </Withdrawal>

            <Receive proRedeem={profile.proRedeem === 1}>
              {/* <Blacktext /> */}
              <Text> {t("current pro")}</Text>
              <BonusBox
                onClick={() => {
                  if (profile.statusTopupGameType === 0) {
                    setNormalSelect(true);
                  } else if (profile.statusTopupGameType === 1) {
                    setByTypeSelect(true);
                  }
                }}
              >
                <TakeBonus>
                  <img
                    src={
                      promotionInfo?.lastPromotion?.promotion_status ===
                        false &&
                      promotionInfo?.lastPromotion?.player_promotion_status ===
                        false
                        ? "../" + config.theme + "/ico_promo_nopro.png"
                        : "../" + config.theme + "/ico_promo_coin.png"
                    }
                  />
                  <Clear>
                    {promotionInfo?.lastPromotion?.promotion_status
                      ? promotionInfo?.lastPromotion?.promotion_name ===
                          "ชวนเพื่อนสมัครแจกเครดิตฟรี-RegisterFreeCredit" ||
                        promotionInfo?.lastPromotion?.promotion_name ===
                          "เครดิตเพื่อนเติมเงิน" ||
                        promotionInfo?.lastPromotion?.promotion_name ===
                          "แลกเครดิตจากกระเป๋า-Exchange"
                        ? t("cashpoint bonus wallet")
                        : promotionInfo?.lastPromotion?.promotion_name ===
                          "เครดิตชวนเพื่อนรับรายได้"
                        ? t("cashpoint freidn loss")
                        : promotionInfo?.lastPromotion?.promotion_name ===
                          "เครดิตคืนยอดเสีย"
                        ? t("cashpoint cashback")
                        : promotionInfo?.lastPromotion?.promotion_name ===
                          "คอมมิชชั่น-Commission"
                        ? t("cashpoint commssion")
                        : promotionInfo?.lastPromotion?.promotion_name ===
                          "ยอดสะสม - Cashpoint"
                        ? t("cashpoint cashpoint")
                        : promotionInfo?.lastPromotion?.promotion_name ===
                          "ขาประจำ-Daily"
                        ? t("cashpoint daily")
                        : promotionInfo?.lastPromotion?.promotion_name ===
                          "แจกโค้ดฟรีเครดิต-Redeem"
                        ? t("cashpoint redeem")
                        : promotionInfo?.lastPromotion?.promotion_name ===
                            "แลกเครดิตจากเพชร-Exchange Diamond" ||
                          promotionInfo?.lastPromotion?.promotion_name ===
                            "แลกเครดิตจากเพชร"
                        ? t("exchange diamond")
                        : promotionInfo?.lastPromotion?.promotion_name
                      : promotionInfo?.lastPromotion?.promotion_status ===
                          false &&
                        promotionInfo?.lastPromotion?.player_promotion_status
                      ? t("auto pro")
                      : promotionInfo?.lastPromotion?.promotion_status ===
                          false &&
                        promotionInfo?.lastPromotion
                          ?.player_promotion_status === false
                      ? t("deactivate pro")
                      : ""}
                  </Clear>
                  <SelectPromotionButton>
                    <img src={"../" + config.theme + "/ico_promo_change.png"} />
                  </SelectPromotionButton>
                  {/* <Blackbg>
                    <Switch
                      onLabel={t("on")}
                      offLabel={t("off")}
                      value={depositSetting}
                      click={() => {
                        dispatch(depositTrigger());
                        HandleSave(
                          !depositSetting,
                          profile.setsound,
                          profile.seteffect
                        );
                      }}
                    />
                  </Blackbg> */}
                </TakeBonus>
                {/* <AttentionContainer>
                  {window.appHeight() > window.innerWidth ? (
                    <>
                      <Attentiontext>
                        {t("attention") + t("attention text")}
                      </Attentiontext>
                    </>
                  ) : (
                    <>
                      <Attention>{t("attention")}</Attention>
                      <Attentiontext lang={i18n.language}>
                        {t("attention text")}
                      </Attentiontext>
                    </>
                  )}
                </AttentionContainer> */}
              </BonusBox>
            </Receive>
          </DepositLeftLayout>
          <RightBox>
            <DepositSlice />
            {profile.proRedeem === 1 ? (
              <>
                <HR />
                <RedeemBox>
                  {window.appHeight() > window.innerWidth ? (
                    <>
                      <VerticalLineBox>
                        <RedeemText>{t("Redeem Apply Code")}</RedeemText>
                        <LineButton
                          onClick={() => {
                            openInNewTab(lineId);
                          }}
                        >
                          {t("Redeem Contact Line")}
                        </LineButton>
                      </VerticalLineBox>
                    </>
                  ) : (
                    <LineButton
                      onClick={() => {
                        openInNewTab(lineId);
                      }}
                    >
                      {t("Redeem Contact Line")}
                    </LineButton>
                  )}
                  <RedeemContent>
                    {window.appHeight() > window.innerWidth ? (
                      ""
                    ) : (
                      <RedeemText>{t("Redeem Apply Code")}</RedeemText>
                    )}
                    <RedeemInput
                      value={redeemInput}
                      placeholder={t("Redeem Place Code")}
                      onFocus={() => {
                        window.isEdit = true;
                        window.onKeyBoard = true;
                      }}
                      onBlur={() => {
                        window.isEdit = false;
                        window.onKeyBoard = false;
                      }}
                      onChange={(e) => {
                        setRedeemInput(e.target.value);
                      }}
                    />
                    <RedeemButton
                      onClick={() => {
                        console.log("redeemInput", redeemInput);
                        RedeemSubmit();
                      }}
                    >
                      {t("Redeem Code")}
                    </RedeemButton>
                  </RedeemContent>
                </RedeemBox>
              </>
            ) : (
              ""
            )}
          </RightBox>
          <Greybar />
        </Popup>

        <Transition showPop={popupStatus.selectnormal} type="st2b">
          <SelectBankNormal
            closeClick={() => {
              setSelectBankNormal(false);
              dispatch(actionPopup({ selectnormal: false }));
            }}
          />
        </Transition>

        <Transition showPop={popupStatus.selectdecimal} type="st2b">
          <SelectBankDecimal
            closeClick={() => {
              setSelectBankDecimal(false);
              dispatch(actionPopup({ selectdecimal: false }));
            }}
          />
        </Transition>

        <Transition showPop={normalDeposit} type="st2b">
          <NormalDeposit
            closeClick={() => {
              setNormalDeposit(false);
            }}
          />
        </Transition>

        <Transition showPop={decimalDeposit} type="st2b">
          <DecimalDeposit
            closeClick={() => {
              setDecimalDeposit(false);
            }}
          />
        </Transition>

        <Transition showPop={decimalPopup} type="st2b">
          <DecimalPopup
            {...decimalState}
            closeClick={() => {
              setDecimalPopup(false);
            }}
            closeDecimal={() => {
              setDecimalPopup(false);
              setSelectBankDecimal(true);
            }}
            bankInfo={bankInfo}
          />
        </Transition>

        <Transition showPop={popupStatus.withdrawal} type="st2b">
          <Withdraw
            closeClick={() => {
              setWithdrawal(false);
              dispatch(actionPopup({ withdrawal: false }));
            }}
          />
        </Transition>

        {redeemPop ? (
          <ConfirmPopUp
            messageBottom={t("confirm OK")}
            cancelClick={() => {
              setRedeemPop(false);
              setRedeemInput("");
            }}
          >
            {redeemReward.type === "balance"
              ? t("Redeem Credit", { value: redeemReward.value })
              : redeemReward.type === "c1"
              ? t("Redeem Diamond", { value: redeemReward.value })
              : redeemReward.type === "creditFree"
              ? t("Redeem Free Credit", { value: redeemReward.value })
              : ""}
          </ConfirmPopUp>
        ) : (
          ""
        )}
        {emitError.status ? (
          <ConfirmPopUp
            messageBottom={t("confirm OK")}
            cancelClick={() => {
              setEmitError({ status: false });
              setRedeemInput("");
            }}
          >
            {t(emitError.message)}
          </ConfirmPopUp>
        ) : (
          ""
        )}
      </ScaleFrame>
      {normalSelect ? (
        <SelectNormal
          closeClick={() => setNormalSelect(false)}
          promotionInfo={promotionInfo}
          setProStatusChanged={setProStatusChanged}
        />
      ) : byTypeSelect ? (
        <SelectByType
          closeClick={() => setByTypeSelect(false)}
          promotionInfo={promotionInfo}
          setProStatusChanged={setProStatusChanged}
        />
      ) : (
        ""
      )}
    </BGPopup>
  );
}
export default Deposit;
