import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import breakpoint from "../breakpoints";
import { Link } from "react-router-dom";
// import Content from "../Content";

const ArticleItemContainer = styled.article`
  width: 13.02vw;
  cursor: pointer;
  :hover .more {
    opacity: 1;
    transition: 0.1s ease-in-out;
  }
  
  @media ${breakpoint.device.ipad} {
    width: 16.02vw;
  cursor: pointer;
  }
  @media ${breakpoint.device.mobile} {
    width: 100%;
  }
`;

const ReadMore = styled.div`
  opacity: 0;
  position: absolute;
  z-index: 5;
  background-color: rgb(255 112 0 / 80%);
  width: 13.2vw;
  height: 33.2%;
  border-radius: 0.5vw;
  p {
    background-color: #fff;
    color: #ff7000;
    width: fit-content;
    border-radius: 0.25vw;
    font-size: 0.73vw;
    text-transform: uppercase;
    padding: 0.5vw 1.5vw;
    margin: 31.5% auto;
  }
  @media ${breakpoint.device.ipad} {
    width: 15.2vw;
    height: 33.7%;
  }
  @media ${breakpoint.device.mobile} {
    width: 60.38vw;
    height: 45.6%;
    border-radius: 2vw;
    p {
      border-radius: 1vw;
      font-size: 3.22vw;
      padding: 2vw 3.5vw;
      margin: 32.5% auto;
    }
  }
`;

const ImageBox = styled.figure`
  position: relative;
`;

const Image = styled.img`
  width: 13.2vw;
  height: auto;
  border-radius: 0.5vw;
  @media ${breakpoint.device.ipad} {
    width: 15.2vw;
    height: auto;
    border-radius: 0.5vw;
  }
  @media ${breakpoint.device.mobile} {
    width: 60.38vw;
    // height: 48.3vw;
    height: auto;
    border-radius: 2vw;
  }
  `;
const ArticleDetail = styled.div`
  color: #fff;
  h3 {
    font-size: 1.25vw;
    font-weight: 400;
    overflow: hidden;
    display: -webkit-inline-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  p {
    font-size: 0.94vw;
    line-height: 1.5vw;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    text-overflow: ellipsis;   
    margin: 1vw 0;
    width: 14vw;
    height: 5.77vw;
    color: rgba(255,255,255,0.7);
  }
  a {
    color: #ff7000;
  }
  @media ${breakpoint.device.ipad} {
    h3 {
      font-size: 1.25vw;
    }
    p {
      font-size: 1.2vw;
      line-height: 1.8vw;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      text-overflow: ellipsis;   
      margin: 1vw 0;
      width: 16vw;
      height: 6.77vw;
      color: rgba(255,255,255,0.7);
    }
    
  }
  @media ${breakpoint.device.mobile} {
    h3 {
      font-size: 3.79vw;
    }
    p {
      font-size: 2.9vw;
      margin-top: 3vw;
      line-height: 4.2vw;
      width: 100%;
      height: 15.5vw;
      display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    text-overflow: ellipsis;
    text-overflow: ellipsis;   
    }
  }
  `;

function ArticleItem(props) {
  const { i18n } = useTranslation();

  // console.log("BannerItem props:", props)

  return (
    // <Link to={`articles/${props.path}`}>
    <Link to={`${props.path}`}>
      <ArticleItemContainer>
        <ReadMore className="more">
          <p>read more</p>
        </ReadMore>
        <ImageBox>
          <Image src={props.img} alt={props.seo.alt} />
        </ImageBox>
        <ArticleDetail>
          <h3>{i18n.language === "th" ? props.title_th : props.title_en}</h3>
          <p
            dangerouslySetInnerHTML={{
              __html: i18n.language === "th" ? props.detail_th : props.detail_en,
            }}
          ></p>
          <p>
            {props.created_date} By {props.created_by}
          </p>
        </ArticleDetail>
      </ArticleItemContainer>
    </Link>
  );
}

export default ArticleItem;
