import { createSlice } from "@reduxjs/toolkit";

export const flashDealSlice = createSlice({
  name: "flashDeal",
  initialState: {
    countDownTime: {
      hour: 0,
      minute: 0,
      second: 0,
      barWidth: 0,
    },
  },
  reducers: {
    actionCountDownTime: (state, action) => {
      state.countDownTime = action.payload;
    },
  },
});

export const { actionCountDownTime } = flashDealSlice.actions;
export const selectCountDownTime = (state) => state.flashDeal.countDownTime;
export default flashDealSlice.reducer;
