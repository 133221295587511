import React from "react";
import styled, { css } from "styled-components";
import NumberFormat from "react-number-format";
// import breakpoints from "../../LandingPageComponents/breakpoints";
import { config } from "../../config";

const Diamonds = styled.img`
  user-select: none;
  width: 100%;
  display: flex;
  height: 100%;
  position: relative;
  left: 0vw;
  top: 15%;
  margin: auto;
  -moz-margin-start: 0.35vw;
  z-index: 20;
  ${(props) =>
    props.isMission &&
    css`
      top: 12%;
    `}

  ${(props) =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone &&
      props.reward === "diamondResult"
      ? css`
          width: 30vw;
          height: 27vw;
          top: -3vw;
          left: -7vw;
        `
      : window.appHeight() > window.innerWidth &&
      props.reward === "diamondResult" &&
      css`
          width: 31vw;
          height: 20vw;
          margin: 0 auto;
          margin-right: 63vw;
          top: 9vw;
        `}
`;

const Ticket = styled.img`
  user-select: none;
  width: 85%;
  display: flex;
  height: 60%;
  position: relative;
  padding-top: 5vw;
  margin: auto;
  -moz-margin-start: 0.8vw;
`;
//พื้นหลังสีดำของจำนวนไอเทม
const Amount = styled.div`
  user-select: none;
  position: relative;
  width: 85%;
  height: 21%;
  top: 0.5vw;
  left: 0vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  border-radius: 5vw;
  background-size: contain;
  margin: auto;
  -moz-margin-start: 0.8vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 72%;
          height: 21%;
          align-items: center;
          padding: 0.2vw 0;
        `
      : window.appHeight() > window.innerWidth && css``}

  ${(props) =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone &&
      props.reward === "diamondResult"
      ? css`
          width: 21vw;
          height: 5vw;
          top: -11.5vw;
          left: -4vw;
        `
      : window.appHeight() > window.innerWidth &&
      props.reward === "diamondResult" &&
      css`
          width: 31vw;
          height: 20vw;
          margin: 0 auto;
          margin-right: 63vw;
          top: 9vw;
        `}
`;
const Box = styled.div`
  user-select: none;
  width: 18vw;
  height: 15vw;
  position: relative;
  text-align: -webkit-center;
  margin: 0;
  font-size: 2.5vw;
  // background: red;
  // text in black slot start below
  p {
    color: white;
    top: 0.05vw;
    /* margin-top: -1%; */
    font-weight: 400;
    position: relative;
    /* margin-right: -0.28vw; */
  }
  top: 0;
  left: 0;

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin-top: 1vw;
          p {
            font-size: 2.2vw;
            padding-top: 0.5vw;
          }
        `
      : window.appHeight() > window.innerWidth &&
      css`
          margin-top: 0.5vw;
          p {
            font-size: 2vw;
            margin-top: 0.1vw;
          }
        `}
`;

function RewardResult(props) {
  let icon = "";
  // const [showPopup, setShowPopup] = useState(false);
  if (props.amount === 0) {
    icon = "../" + config.theme + "/dshop_10000.png";
  } else if (props.amount <= 400) {
    icon = "../" + config.theme + "/dshop_200.png";
  } else if (props.amount >= 401 && props.amount <= 999) {
    icon = "../" + config.theme + "/dshop_800.png";
  } else if (props.amount >= 1000 && props.amount <= 3999) {
    icon = "../" + config.theme + "/dshop_2000.png";
  } else if (props.amount >= 4000 && props.amount <= 8999) {
    icon = "../" + config.theme + "/dshop_5000.png";
  } else if (props.amount >= 9000) {
    icon = "../" + config.theme + "/dshop_10000.png";
  }

  if (props.itemType === "T") {
    icon = "../" + config.theme + "/treasure_ticketIcon.png";
  } else if (props.itemType === "S") {
    icon = "../" + config.theme + "/luckywheel_ticketIcon.png";
  }

  return (
    <Box {...props}>
      {props.itemType === "T" || props.itemType === "S" ? (
        <Ticket src={icon} />
      ) : (
        <Diamonds src={icon} isMission={props.isMission} />
      )}
      <Amount>
        {props.amount > 0 ? (
          <p>
            +
            <NumberFormat
              value={props.amount}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={0}
              fixedDecimalScale={true}
            />
          </p>
        ) : (
          <p>???</p>
        )}
      </Amount>
    </Box>
  );
}
export default RewardResult;
