import styled from "styled-components";
import breakpoint from "./breakpoints";

const PopupContainer = styled.div`
    background-color: #222535;
    width: max-content;
    height: max-content;
    box-shadow: 0 0.3vw 0 0 #d24403 inset;
    border-radius: 1.7vw;
    padding: 1.5vw;
    margin: 0 auto;
    position: relative;
    @media ${breakpoint.device.mobile} {
        padding: 3.5vw;
        box-shadow: 0 1.5vw 0 0 #d24403 inset;
    }
`

const Close = styled.img`
    width: 2.5vw;
    cursor:pointer;
    position:absolute;
    top: -1vw;
    right: -1vw;
    @media ${breakpoint.device.mobile} {
        width: 7.5vw;
        top: -3vw;
        right: -3vw;
    }
   
`;

function Popup(props) {
    return (
        <PopupContainer>
            {props.children}
            {!props.forget ?
                <Close src="../../lpimages/btn_popup_close.png" style={{ right: props.closebuttonRight, top: props.closebuttonTop }}
                    onClick={props.closeClick} />
                :
                ""
            }

        </PopupContainer>
    )
}

export default Popup
