import React, { useState, useContext } from "react";
import styled, { css } from "styled-components";
import { config, redirect2Page } from "../config";
import { useSelector, useDispatch } from "react-redux";
import { actionPopup } from "../Reducers/popupSlice";
import { SocketContext } from "../context/socket";
import { selectuserProfile } from "../Reducers/userProfileSlice";
import { actionCheckFTUEPopup } from "../Reducers/checkFTUEPopupSlice";
import AlertMessage from "./Dialog/AlertMessage";
import { Transition } from "../config";
import { useTranslation } from "react-i18next";
const BGPopup = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%;
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 70;
  align-items: center;
  justify-content: center;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          display: flow-root;
          text-align: center;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          display: flow-root;
          text-align: center;
        `}
`;
const Content = styled.div`
  cursor: pointer;
  background-image: url("../${config.theme}/landing_select/left.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  // background-color: green;
  width: 50vw;
  height: 45vw;
  margin: 0 auto;
  justify-content: middle;
  // background-color: green;
  position: fixed;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          // width: 42vw;
          width: 70%;
          height: 40%;
          // background-size: contain;
          background-position: center center;
          margin-top: 20%;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          // width: 60vw;
          width: 55%;
          height: 50%;
          // background-size: contain;
          background-position: center center;
        `}
`;
const Content2 = styled.div`
  cursor: pointer;
  background-image: url("../${config.theme}/landing_select/right.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  // background-color: red;
  width: 50vw;
  height: 45vw;
  margin: 0 auto;
  justify-content: middle;
  // background: green;
  position: fixed;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          // width: 42vw;
          width: 70%;
          height: 40%;
          // background-size: 100%;
          // background-size: contain;
          background-position: center center;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          // width: 60vw;
          width: 55%;
          height: 50%;
          // background-size: 100%;
          background-position: center center;
        `}
`;



const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  // background: rgba(0,0,0,0.6);
`;

const Box = styled.div`
  display: flex;
  height: 100%;
  width: 50vw;
  flex-direction: column;
  position: relative;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 100vw;
          height: 50%;
          align-items: center;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          width: 100vw;
          height: 50%;
          align-items: center;
        `}
`;

function NewUserPopup(props) {
  const { t } = useTranslation();
  const [emitError, setEmitError] = useState({ status: false, message: "" });
  const profile = useSelector(selectuserProfile);
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);

  return (
    <>
      <BGPopup>
        <Container>
          <Box>
            <Content
              onClick={
                profile.isTelGuest
                  ? () => {
                    try {
                      props.setFtuePop(false);
                      socket.emit(
                        "getfirstlogin",
                        {
                          status: true,
                        },
                        (data) => {
                          console.log(data);
                        }
                      );
                    } catch (error) {
                      setEmitError({ status: true, message: error.message });
                      setTimeout(() => {
                        setEmitError({ status: false });
                      }, 3000);
                    }
                  }
                  : () => {
                    try {
                      props.setFtuePop(false);
                      socket.emit("ftueRegister", {}, (data) => {
                        console.log("ftueRegister", data);
                      });
                      if (!profile.isTelGuest) {
                        // dispatch(actionPopup({ createAccount: true }));
                        redirect2Page("register", "1");
                        dispatch(actionCheckFTUEPopup(1));
                        // alert("Quick REgist");
                      }
                    } catch (error) {
                      setEmitError({ status: true, message: error.message });
                      setTimeout(() => {
                        setEmitError({ status: false });
                      }, 3000);
                    }
                  }
              }
            >
              {/* <IMG src={`../${config.theme}/landing_select/left.png`} /> */}
            </Content>
            {/* <ButtonCustom
            onClick={
              profile.isTelGuest
                ? () => {
                  try {
                    props.setFtuePop(false);
                    socket.emit(
                      "getfirstlogin",
                      {
                        status: true,
                      },
                      (data) => {
                        console.log(data);
                      }
                    );
                  } catch (error) {
                    setEmitError({ status: true, message: error.message });
                    setTimeout(() => {
                      setEmitError({ status: false });
                    }, 3000);
                  }
                }
                : () => {
                  try {
                    props.setFtuePop(false);
                    socket.emit("ftueRegister", {}, (data) => {
                      console.log("ftueRegister", data);
                    });
                    if (!profile.isTelGuest) {
                      dispatch(actionPopup({ register: true }));
                      dispatch(actionCheckFTUEPopup(1));
                    }
                  } catch (error) {
                    setEmitError({ status: true, message: error.message });
                    setTimeout(() => {
                      setEmitError({ status: false });
                    }, 3000);
                  }
                }
            }
            bClass="rbGreen"
            label={t("reg now")}
            width={
              window.appHeight() / window.innerWidth > config.verticalSizeiphone
                ? "30vw"
                : window.appHeight() > window.innerWidth
                  ? "17vw"
                  : window.innerWidth / window.appHeight() > 2.15
                    ? "20vw"
                    : "20vw"
            }
            fontSize={
              window.appHeight() / window.innerWidth > config.verticalSizeiphone
                ? "3vw"
                : window.appHeight() > window.innerWidth
                  ? "2vw"
                  : window.innerWidth / window.appHeight() > 2.15
                    ? "2vw"
                    : "2vw"
            }
          /> */}
          </Box>
          <Box>
            <Content2
              onClick={() => {
                socket.emit(
                  "getbilog",
                  {
                    biname: "FTUE_banner",
                    button_name: "mission button",
                  },
                  (body) => {
                    // console.log("getbilog",body);
                  }
                );
                if (profile.isTelGuest) {
                  try {
                    props.setFtuePop(false);
                    profile.isguest
                      ? // dispatch(actionPopup({ registBankAccount: true }))
                      redirect2Page("createbankaccount")
                      : dispatch(actionPopup({ depositWithdrawal: true }));
                    socket.emit(
                      "getfirstlogin",
                      {
                        status: true,
                      },
                      (data) => {
                        console.log(data);
                      }
                    );
                  } catch (error) {
                    setEmitError({ status: true, message: error.message });
                    setTimeout(() => {
                      setEmitError({ status: false });
                    }, 3000);
                  }
                } else {
                  try {
                    props.setFtuePop(false);
                    dispatch(actionPopup({ mission: true }));
                  } catch (error) {
                    setEmitError({ status: true, message: error.message });
                    setTimeout(() => {
                      setEmitError({ status: false });
                    }, 3000);
                  }
                }
              }}
            >
              {/* <IMG src={`../${config.theme}/landing_select/right.png`} /> */}
            </Content2>
            {/* <ButtonCustom
            onClick={() => {
              socket.emit(
                "getbilog",
                {
                  biname: "FTUE_banner",
                  button_name: "mission button",
                },
                (body) => {
                  // console.log("getbilog",body);
                }
              );
              if (profile.isTelGuest) {
                try {
                  props.setFtuePop(false);
                  profile.isguest
                    ? dispatch(actionPopup({ registBankAccount: true }))
                    : dispatch(actionPopup({ depositWithdrawal: true }));
                  socket.emit(
                    "getfirstlogin",
                    {
                      status: true,
                    },
                    (data) => {
                      console.log(data);
                    }
                  );
                } catch (error) {
                  setEmitError({ status: true, message: error.message });
                  setTimeout(() => {
                    setEmitError({ status: false });
                  }, 3000);
                }
              } else {
                try {
                  props.setFtuePop(false);
                  dispatch(actionPopup({ mission: true }));
                } catch (error) {
                  setEmitError({ status: true, message: error.message });
                  setTimeout(() => {
                    setEmitError({ status: false });
                  }, 3000);
                }
              }
            }}
            bClass="rbGreen"
            label={t("go to mission")}
            width={
              window.appHeight() / window.innerWidth > config.verticalSizeiphone
                ? "30vw"
                : window.appHeight() > window.innerWidth
                  ? "17vw"
                  : window.innerWidth / window.appHeight() > 2.15
                    ? "20vw"
                    : "20vw"
            }
            fontSize={
              window.appHeight() / window.innerWidth > config.verticalSizeiphone
                ? "3vw"
                : window.appHeight() > window.innerWidth
                  ? "2vw"
                  : window.innerWidth / window.appHeight() > 2.15
                    ? "2vw"
                    : "2vw"
            }
          /> */}
          </Box>
        </Container>
      </BGPopup>
      <Transition showPop={emitError.status} type="alert">
        <AlertMessage
          message={t(emitError.message)}
          onClick={() => {
            setEmitError({ status: false });
          }}
        />
      </Transition>
    </>
  );
}

export default NewUserPopup;
