import React from 'react';
import styled from 'styled-components';
import { detect } from 'detect-browser';
import {config} from "../config"

const BG = styled.div`
    user-select: none;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0%;
    top: 0%;
    display: flex;
    flex-direction: column;
    z-index: 999;
    justify-content:center;
    align-items: center;
    background-image: url("../${config.theme}/newasset/bg.png");
    background-size: 100% 100%;
    overflow: hidden;
`;

const Logo = styled.div`
    user-select: none;
    width: 31.634%;
    height: 8.1vw;
    background-image: url("../${config.theme}/spinix_normal_Logo.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
`;

const TextWhite = styled.div`
    user-select: none;
    font-size: 2.7vw;
    color: #FFFFFF;
    text-align: center;
    line-height: 200%;
`;

const TextGray = styled.div`
    max-width: 59.52%;
    user-select: none;
    font-size: 1.95vw;
    color: #BDB9D1;
    text-align: center;
    line-height: 110%;
`;

const BrowserList = styled.div`
    user-select: none;
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 4%;
`;

const BrowserLogo = styled.div`
    user-select: none;
    display: flex;
    flex-direction: column;
    width: 10.5%;
    justify-content: center;
    margin: 0% 2% 0% 2%;
`;

const BrowserLogoImg = styled.img`
    user-select: none;
    width: 100%;
`;

const BrowserName = styled.div`
    user-select: none;
    font-size: 1.95vw;
    color: #FFFFFF;
    text-align: center;
`;

export default function BrowserSupport() {
    const browser = detect()

    function myFunction() {
        alert(browser.name)
    }

    return (
        <BG>
            <Logo />
            <TextWhite>Improve your experience!</TextWhite>
            <TextGray>You’re using a web browser we don’t support.</TextGray>
            <TextGray>Try one of this options to have better experience on Spinix.</TextGray>
            <BrowserList>
                <BrowserLogo>
                    <BrowserLogoImg src={"../"+config.theme+"/newasset/icon_safari.png"} />
                    <BrowserName>Safari</BrowserName>
                </BrowserLogo>
                <BrowserLogo>
                    <BrowserLogoImg src={"../"+config.theme+"/newasset/icon_edge.png"} />
                    <BrowserName>Edge</BrowserName>
                </BrowserLogo>
                <BrowserLogo onClick={myFunction}>
                    <BrowserLogoImg src={"../"+config.theme+"/newasset/icon_chrome.png"} />
                    <BrowserName>Chrome</BrowserName>
                </BrowserLogo>
            </BrowserList>
        </BG>
    )
}