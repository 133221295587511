import React from "react";
import styled, { css } from "styled-components";
import SubNav from "../SubNav";
import breakpoints from "../../LandingPageComponents/breakpoints";
import { config } from "../../config";

const ContentContainer = styled.div`
  user-select: none;
  /* display: flex; */
  /* justify-content: space-between; */
  justify-content: center;
  position: relative;
  /* padding: 0 2vw; */
  /* top: -4vw; */

  @media ${breakpoints.device.line} {
    top: 0;
  }
  //Portrait
  @media (orientation: portrait) {
    width: 24vw;
    height: calc(100% - 60vw);
    margin-top: 2.5vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    width: 24vw;
    height: calc(100% - 43vw);
    margin-top: 1.5vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 24vw;
          height: calc(100% - 60vw);
          margin-top: 2.5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 24vw;
          height: calc(100% - 43vw);
          margin-top: 1.5vw;
        `} */
`;

function Content(props) {
  return (
    <ContentContainer>
      <SubNav />
      {/* <div className="search">
        <input type="text" placeholder="Search.." name="search" />
      </div> */}
    </ContentContainer>
  );
}

export default Content;
