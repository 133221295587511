import React from "react";
import styled from "styled-components";
import breakpoints from "../LandingPageComponents/breakpoints";

const Box = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%;
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 9999;
  justify-content: center;
  @media ${breakpoints.device.line} {
    top: 0;
    left: 0;
    width: 100%;
  }
`;

const Load = styled.div`
  user-select: none;
  background-image: url("../images/loading.gif");
  width: 100vw;
  height: 100%;
  position: absolute;
  /* background-size: contain; */
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
`;

function Loading() {
  return (
    <Box>
      <Load />
    </Box>
  );
}
export default Loading;
