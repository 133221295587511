import { createSlice } from "@reduxjs/toolkit";

export const floatingInputSlice = createSlice({
    name: "floatingInput",
    initialState: {
        value: "",
        status: false,
        label: ""
    },
    reducers: {
        setFloatingValue: (state, action) => {
            state.value = action.payload;
        },
        setFloatingStatus: (state, action) => {
            state.status = action.payload;
        },
        setFloatingLabel: (state, action) => {
            state.label = action.payload;
        }
    },
});

export const { setFloatingValue, setFloatingStatus, setFloatingLabel } = floatingInputSlice.actions;
export const selectValue = (state) => state.floatingInput.value;
export const selectStatus = (state) => state.floatingInput.status;
export const selectLabel = (state) => state.floatingInput.label;
export default floatingInputSlice.reducer;
