import React from "react";
import styled from "styled-components";
import Button from "../Button";
import { useTranslation } from "react-i18next";
import { config } from "../../config";

const Popup = styled.div`
  position: absolute;
  background: #f87d7d;
  box-shadow: inset 0px 0px 3px 1px #ffc3c3, inset 0px 0px 20px #ffdedc;
  width: 36vw;
  height: 33vw;
  border-radius: 2vw;
`;

const ButConfirm = styled.div`
  user-select: none;
  position: relative;
  z-index: 70;
  display: flex;
  margin-top: 33vw;
`;

const Background = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  position: absolute;
  left: 0%;
  bottom: 0%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  z-index: 27;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  position: relative;
  text-align: center;
  width: 34vw;
  height: 29vw;
  margin: 0 auto;
  top: 1vw;
  background: #fffbd8;
  box-shadow: inset 0px 0px 3px 1px #ffffff,
    inset 0px 0px 20px rgba(236, 152, 106, 0.63);
  border-radius: 25px;
  padding: 1vw 0vw;
  h5 {
    margin-top: 0vw;
    font-size: 3vw;
    font-weight: 100;
    color: #ff7c7c;
  }
  img {
    width: ${(props) => (props.itemLength === 4 ? "28%" : "25%")};
    margin: 0 1vw;
  }
`;

const RewardList = styled.div`
  height: 70%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

function CollectRewardPopup(props) {
  const { t } = useTranslation();

  return (
    <Background>
      <Popup>
        <Content
          style={{ width: props.widthContent, height: props.heightContent }}
          itemLength={props.item.length}
        >
          <h5>{t("vt collect gift")}</h5>
          <RewardList>
            {props.item.map((item, index) => (
              <img
                src={
                  item.no === 1
                    ? "../" + config.theme + "/event_val/item1.png"
                    : item.no === 2
                      ? "../" + config.theme + "/event_val/item2.png"
                      : item.no === 3
                        ? "../" + config.theme + "/event_val/item3.png"
                        : item.no === 4 || item.no === 5
                          ? "../" + config.theme + "/event_val/item4.png"
                          : ""
                }
                itemType={item.no}
                key={"CollectItem: " + index}
              />
            ))}
          </RewardList>
        </Content>
      </Popup>
      <ButConfirm>
        <Button
          bClass="rbRed"
          label={t("vt try again")}
          width="13vw"
          onClick={props.closeClick}
          style={{ padding: 0 }}
        />
        <Button
          label={t("vt confirm")}
          width="13vw"
          style={{ padding: 0 }}
          onClick={props.confirmClick}
        />
      </ButConfirm>
    </Background>
  );
}
export default CollectRewardPopup;
