import { createSlice } from "@reduxjs/toolkit";
export const checkDailyDepositSlice = createSlice({
  name: "checkDailyDeposit",
  initialState: {
    results: [],
  },
  reducers: {
    actionCheckDailyDeposit: (state, action) => {
      state.results = action.payload;
    },
  },
});

export const { actionCheckDailyDeposit } = checkDailyDepositSlice.actions;
export const selectCheckDailyDeposit = (state) =>
  state.checkDailyDeposit.results;
export default checkDailyDepositSlice.reducer;
