import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, useLocation } from "react-router-dom";
import styled from "styled-components";
import "./LPApp.css";
import Footer from "./LandingPageComponents/Footer";
import Navbar from "./LandingPageComponents/Navbar/Navbar";
import Home from "./LandingPageComponents/Navbar/Home";
import WarningMessage from "./LandingPageComponents/Dialog/WarningMessage";
import NavbarMobile from "./LandingPageComponents/Navbar/NavbarMobile";
// import useWindowSize from "./LandingPageComponents/useWindowSize";
import breakpoint from "./LandingPageComponents/breakpoints";
import { useTranslation } from "react-i18next";
import { SocketKafkaContext } from "./context/socket";
import { config, Transition, getLang, getParameterByName } from "./config";
import axios from "axios";
import { actionCounterLP } from "./Reducers/counterLPSlice";
import { actionContenthow } from "./Reducers/HowtouseSlice";
import { actionContentpromo } from "./Reducers/promotionSlice";
import i18next from "i18next";
import { selectDimensionsLP, setDimensionLP } from "./Reducers/dimensionsLP";
import { selectLoading } from "./Reducers/loadingSlice";
import Loading from "./Components/Loading";
import { actionHomeContentLP } from "./Reducers/homeContentLPSlice";
// import StickyNav from "./LandingPageComponents/StickyNav";
// import SlotMinigame from "./LandingPageComponents/SlotMiniGame";
// import { actionCasinoItems, actionGameDemo } from "./Reducers/gameDemoSlice";
import { actionLoading } from "./Reducers/loadingSlice";
// import Forgotpassword from "./Components/Login/Forgotpassword"
const BG = styled.div`
  background-color: ${(props) => (props.BGcolor ? "#" + props.BGcolor : "")};
  background-image: url(${(props) => props.bgOP === null ? "../../lpimages/temp_bg.png" : ""});
  background-size: contain;
  background-repeat: no-repeat;
  min-height: ${(props) =>
    props.bgOP !== null ? "100vh" : props.menu === "games" ? "105vh" : "80vh"};
  position: relative;
  overflow: hidden;
`;

const WarningContainer = styled.div`
  user-select: none;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
`;

function App() {
  window.isLandingPage = true;
  setTimeout(() => {
    if (window.fullScreenGIF) window.fullScreenGIF();
  }, 500);
  const { t } = useTranslation();
  const location = useLocation();
  // const size = useWindowSize();
  const socketioKafka = useContext(SocketKafkaContext);
  const dispatch = useDispatch();
  const [emitError, setEmitError] = useState({ status: false, message: "" });
  const dimensionsLP = useSelector(selectDimensionsLP);
  const isLoading = useSelector(selectLoading);
  const key = getParameterByName("key");
  const BGcolor = getParameterByName("bg");
  // const lang = getParameterByName("lang");
  // const agentId = getParameterByName("a");
  // const brand = getParameterByName("b");
  // const referal = getParameterByName("r");

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    dispatch(actionLoading(false));
    // let data = JSON.stringify({
    //   page: 1,
    //   limit: 100,
    //   language: "en",
    // });

    // axios({
    //   method: "post",
    //   url: config.socketEndpoint + "getGameListDemo",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   data: data,
    // })
    //   .then(function (response) {
    //     if (
    //       typeof response.data == "undefined" ||
    //       typeof response.data.results == "undefined" ||
    //       typeof response.data.results.data == "undefined"
    //     ) {
    //     } else {
    //       dispatch(actionLoading(true));
    //       if (response.data.status == true) {
    //         if (response.data.results.data.length > 0) {
    //           //   console.log(response.data.results);
    //           dispatch(
    //             actionGameDemo(
    //               response.data.results.data.filter((a) => {
    //                 return a.categories != "casino";
    //               })
    //             )
    //           );
    //         } else {
    //           dispatch(actionGameDemo([]));
    //         }
    //       } else if (response.data.status == false) {
    //         setEmitError({ status: true, message: response.data.errorCode });
    //         // setTimeout(() => {
    //         //     setEmitError({ status: false })
    //         // }, 3000)
    //       }
    //     }
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  }, []);

  useEffect(() => {
    dispatch(actionLoading(false));
    // let data = JSON.stringify({
    //   page: 1,
    //   limit: 100,
    //   categories: ["casino"],
    //   language: "en",
    // });

    // axios({
    //   method: "post",
    //   url: config.socketEndpoint + "getGameListDemo",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   data: data,
    // })
    //   .then(function (response) {
    //     if (
    //       typeof response.data == "undefined" ||
    //       typeof response.data.results == "undefined" ||
    //       typeof response.data.results.data == "undefined"
    //     ) {
    //     } else {
    //       dispatch(actionLoading(true));
    //       if (response.data.status == true) {
    //         if (response.data.results.data.length > 0) {
    //           // console.log(response.data);
    //           dispatch(actionCasinoItems(response.data.results.data));
    //         } else {
    //           dispatch(actionCasinoItems([]));
    //         }
    //       } else if (response.data.status == false) {
    //         setEmitError({ status: true, message: response.data.errorCode });
    //         // setTimeout(() => {
    //         //     setEmitError({ status: false })
    //         // }, 3000)
    //       }
    //     }
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  }, []);

  useEffect(() => {
    try {
      i18next.changeLanguage(getLang());

      // if (window.location.pathname.toLocaleLowerCase().indexOf("forgotpassword") > -1)
      //   return;

      //api total win
      axios({
        method: "get",
        url:
          config.socketKafkaEndpoint +
          "web-backend-platform-kafka-service/totalwin",
        headers: {},
      })
        .then(function (response) {
          if (
            typeof response === "undefined" ||
            typeof response.data === "undefined" ||
            typeof response.data.data === "undefined"
          ) {
          } else {
            if (response.data.status === true) {
              dispatch(actionCounterLP({ totalWin: response.data.data }));
            } else if (response.data.status === false) {
              setEmitError({ status: true, message: response.data.errorCode });
              // setTimeout(() => {
              //   setEmitError({ status: false })
              // }, 3000);
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      // api online
      axios({
        method: "get",
        url:
          config.socketKafkaEndpoint +
          "web-backend-platform-kafka-service/online",
        headers: {},
      })
        .then(function (response) {
          if (
            typeof response === "undefined" ||
            typeof response.data === "undefined" ||
            typeof response.data.data === "undefined"
          ) {
          } else {
            if (response.data.status === true) {
              dispatch(actionCounterLP({ online: response.data.data }));
            } else if (response.data.status === false) {
              setEmitError({ status: true, message: response.data.errorCode });
              // setTimeout(() => {
              //   setEmitError({ status: false })
              // }, 3000);
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      // update
      socketioKafka.on("notification", (data) => {
        if (typeof data !== "undefined") {
          if (data.type === "totalwin") {
            dispatch(actionCounterLP({ totalWin: data.data, game: data.game }));
          } else if (data.type === "online") {
            dispatch(actionCounterLP({ online: data.data }));
          }
        }
      });

      axios({
        method: "get",
        url: config.socketEndpoint + "getHowto",
        header: {},
      })
        .then(function (response) {
          if (response !== "undefined") {
            if (response.data !== "undefined") {
              if (response.data.results !== "undefined") {
                dispatch(
                  actionContenthow({
                    contenthow: response.data.results.data,
                  })
                );
              }
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      let data = JSON.stringify({
        detail: "",
        image: "",
        name: " ",
      });

      axios({
        method: "get",
        url: config.socketEndpoint + "getPromotionLandingPage",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      })
        .then(function (response) {
          if (response !== "undefined") {
            if (response.data !== "undefined") {
              if (response.data.results !== "undefined") {
                dispatch(
                  actionContentpromo({
                    contentpromo: response.data.results.data,
                  })
                );
                // setItems(response.data.results.data.th);
              }
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      //getContent
      axios({
        method: "get",
        url: config.socketEndpoint + "getcontent",
        headers: {},
      })
        .then(function (response) {
          if (
            typeof response.data === "undefined" ||
            typeof response.data.results === "undefined"
          ) {
          } else {
            if (response.data.status === true) {
              dispatch(actionHomeContentLP(response.data.results));
            } else if (response.data.status === false) {
              setEmitError({ status: true, message: response.data.errorCode });
              setTimeout(() => {
                setEmitError({ status: false });
              }, 3000);
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      const disablePinchZoom = (e) => {
        if (e.touches.length > 1) {
          e.preventDefault();
        }
      };
      document.addEventListener("touchmove", disablePinchZoom, {
        passive: false,
      });

      window.addEventListener("resize", handleResize);
      window.addEventListener("popstate", handleResize);
      // window.addEventListener('popstate', alert("popstate"))

      return () => {
        document.removeEventListener("touchmove", disablePinchZoom);
      };
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  }, []);

  function handleResize() {
    try {
      setTimeout(() => {
        dispatch(
          setDimensionLP({
            width: window.innerWidth,
            height: window.appHeight(),
          })
        );
      }, 100);
      setTimeout(() => {
        dispatch(
          setDimensionLP({
            width: window.innerWidth,
            height: window.appHeight(),
          })
        );
      }, 400);
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  }

  return (
    <>
      <BG bgOP={key} BGcolor={BGcolor} menu={location.pathname.split("/")[1]}>
        {/* {window.innerWidth <= breakpoint.deviceSize ? <NavbarMobile /> : <Navbar />} */}

        {key === null ? (
          dimensionsLP.width <= breakpoint.deviceSize ? (
            <NavbarMobile />
          ) : (
            <Navbar />
          )
        ) : null}

        <Route exact path="/">
          <Home />
          {/* <StickyNav /> */}
        </Route>

        {/* <Route exact path="/forgotpassword">
          <Forgotpassword />
        </Route> */}

        {/* <Route exact path="/slot">
          <SlotMinigame
            style={{ margin: 0 }}
            uid={key}
            scrolling={"no"}
            agentId={agentId}
            brand={brand}
            referal={referal || ""}
            style={{
              transform: window.innerWidth <= 480 ? "" : "scale(1.5)",
              top: "22vw",
              overFlow: "hidden"
            }}
          />
        </Route> */}

        {key === null
          ? [
            <Footer key="Footer" />,
            <Transition
              showPop={emitError.status}
              type="alert"
              key="Transition"
            >
              <WarningContainer>
                <WarningMessage
                  errCode={emitError.message}
                  btnLabel={t("try again")}
                  onClick={() => {
                    setEmitError({ status: false });
                  }}
                />
              </WarningContainer>
            </Transition>,
          ]
          : null}
      </BG>

      {key === null ? isLoading === false ? <Loading /> : "" : null}
    </>
  );
}

export default App;
