import { createSlice } from "@reduxjs/toolkit";

export const inviteFriendSlice = createSlice({
    name: "inviteFriend",
    initialState: {
        selectedIndex: 0
    },
    reducers: {
        setSelectedIndex: (state, action) => {
            state.selectedIndex = action.payload;
        },
    },
});

export const { setSelectedIndex } = inviteFriendSlice.actions;
export const selectSelectedIndex = (state) => state.inviteFriend.selectedIndex;
export default inviteFriendSlice.reducer;
