import React from "react";
import styled, { keyframes, css } from "styled-components";
// import breakpoint from "../breakpoint_iphone";
import { config } from "../../config";

const gradient = keyframes`
0% {
  background-position: 0% 50%;
}
50% {
  background-position: 100% 50%;
}
100% {
  background-position: 0% 50%;
}
`;

const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
  min-width: 100%;
  min-height: 100%;
  margin: 0 auto;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
`;
const Parent = styled.div`
  position: relative;
  width: calc(100vw * 0.274 * 0.5); //14vw
  height: calc(100vw * 0.3 * 0.65); //19.5vw
  display: block;
  ${(props) =>
    props.isLoad &&
    css`
      background: linear-gradient(-45deg, #353342, #25232f, #353342, #25232f);
      animation: ${gradient} 3s ease infinite;
      background-size: 400% 400%;
      border-radius: 20px;
    `}
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 31vw;
          height: 44.5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 24vw;
          height: 31.5vw;
        `}
`;
function GameImg(props) {
  return (
    <Parent id="feature-category" isLoad={props.isLoad}>
      <Img src={props.src} id={props.imgId} />
    </Parent>
  );
}
export default GameImg;
