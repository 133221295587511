import React, { useEffect, useContext, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  selectuserProfile,
  actionClickSound,
} from "../../../../Reducers/userProfileSlice";
import { SocketContext } from "../../../../context/socket";
import Popup from "../../../Popup";
import ByTypeProListItem from "./ByTypeProListItem";
import { config } from "../../../../config";

const Box = styled.div`
  position: fixed;
  width: 100vw;
  height: 100%; //fix
  top: 0%;
  left: 0%;
  text-align: -webkit-center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 70;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BG = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 20;
  align-items: center;
  justify-content: center;
  margin: 0%;
`;

const ContentBox = styled.div`
  width: 95%;
  height: 80%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow: hidden scroll;
  ::-webkit-scrollbar {
    width: 1vw;
    height: 0vw;
    z-index: 10;
  }
  ::-webkit-scrollbar-thumb {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 10;
    position: absolute;
    width: 11px;
    height: 80px;
    background: linear-gradient(180deg, #e13b00 38.02%, #900000 100%);
    box-shadow: inset 0px 0px 4px 2px #ff814d;
    border-radius: 10px;
  }
  @media (orientation: portrait) {
    display: block;
  }
`;

const TypeBox = styled.div`
  width: 50%;
  height: 15%;
  display: flex;
  justify-content: space-between;
  margin-top: 1%;
  @media (orientation: portrait) {
    width: 95%;
    height: 8%;
    margin-top: 0%;
  }
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    height: 10%;
    margin-top: 2%;
  }
`;

const TypeItem = styled.div`
  width: 49%;
  height: 100%;
  font-size: 2vw;
  color: #fff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #17161d;
  border-radius: 1vw;
  cursor: pointer;
  img {
    width: 20%;
    object-fit: contain;
  }
  @media (orientation: portrait) {
    font-size: 4vw;
  }
`;

const ProListText = styled.div`
  width: 88%;
  color: #fff;
  font-size: 1.5vw;
  display: flex;
  @media (orientation: portrait) {
    font-size: 3vw;
    margin-top: 5%;
  }
`;

function ByTypeProList(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector(selectuserProfile);
  const socket = useContext(SocketContext);
  const [currentType, setCurrentType] = useState(1); // 1 = slot, 2 = casino

  return (
    <Box>
      <BG onClick={props.closeClick} />
      <Popup
        title={t("select pro")}
        TitleSize={
          window.appHeight() > window.innerWidth && window.innerWidth <= 480
            ? "2.67vw"
            : window.appHeight() > window.innerWidth
            ? "4vw"
            : "2vw"
        }
        bgClass="Profile"
        width={
          window.appHeight() > window.innerWidth && window.innerWidth <= 480
            ? "95vw"
            : window.appHeight() > window.innerWidth
            ? "90vw"
            : "85vw"
        }
        height={
          window.appHeight() > window.innerWidth && window.innerWidth <= 480
            ? "149vw"
            : window.appHeight() > window.innerWidth
            ? "90vw"
            : "36vw"
        }
        closebuttonRight="-2.5vw"
        closebuttonTop="-2.5vw"
        closeClick={props.closeClick}
      >
        <div
          style={{
            width: "100%",
            height: "86%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TypeBox>
            <TypeItem
              style={{ backgroundColor: currentType === 1 && "#FF681D" }}
              onClick={() => {
                setCurrentType(1);
              }}
            >
              <img src={"../" + config.theme + "/ico_promo_slot.png"} />
              {t("slot game")}
            </TypeItem>
            <TypeItem
              style={{ backgroundColor: currentType === 2 && "#FF681D" }}
              onClick={() => {
                setCurrentType(2);
              }}
            >
              <img src={"../" + config.theme + "/ico_promo_card.png"} />
              {t("casino game")}
            </TypeItem>
          </TypeBox>
          <ProListText>{t("promotion list")}</ProListText>
          <ContentBox>
            {props?.promotionInfo?.promotion
              ?.filter((n) => n.gameType === currentType)
              .map((item, i) => {
                return (
                  item.gameType === currentType && (
                    <ByTypeProListItem
                      UpdatePromotion={props.UpdatePromotion}
                      setFinalPop={props.setFinalPop}
                      setting={props?.promotionInfo?.setting}
                      promotion_id={props?.promotionInfo?.user?.promotion_id}
                      info={item}
                      key={"promotion's index: " + i}
                      closeClick={props.closeClick}
                    />
                  )
                );
              })}
          </ContentBox>
        </div>
      </Popup>
    </Box>
  );
}
export default ByTypeProList;
