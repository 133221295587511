import React from "react";
import styled, { css } from "styled-components";
import Button from "../Button";
import { useTranslation } from "react-i18next";
// import CreateAccount from "./CreateAccount";
import { config } from "../../config";
// import Alert from "../Dialog/Alert";
// import { SocketContext } from "../../context/socket";
// import { selectPopup, actionPopup } from "../../Reducers/popupSlice";
// import { useDispatch, useSelector } from "react-redux";

const BG = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 70;
  align-items: center;
  justify-content: center;
  ${() =>
    window.appHeight() > window.innerWidth &&
    css`
      /* width: 100vw;
      position: fixed;
      left: 0%;
      top: 32%;
      display: grid; */
    `}
`;

const List = styled.li`
  color: #ffffff;
  font-size: 1vw;
  line-height: 1.9vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 4vw;
          line-height: 5vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          font-size: 1.8vw;
          line-height: 2.5vw;
        `}
`;

const Item = styled.ol`
  width: 40vw;
  padding-top: 2vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 92%;
          padding-left: 5vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          width: 56vw;
          padding-left: 5vw;
        `}
`;

const Black = styled.div`
  user-select: none;
  width: 44vw;
  height: 26vw;
  background-color: black;
  border-radius: 1vw;
  display: flex;
  justify-content: center;
  align-items: baseline;
  text-align: left;
  margin-top: 1vw;

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin-top: 3vw;
          width: 85%;
          height: 84%;
          border-radius: 3vw;
          align-items: flex-start;
          padding: 4vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          margin-top: 1vw;
          width: 66vw;
          height: 41vw;
          /* height: fit-content; */
        `}
`;

// const ContainerCheckBox = styled.div`
//   display: flex;
//   margin-left: 4vw;
//   color: #fff;
//   margin-top: 2vw;
// `;
// const InputCheckBox = styled.div`
//   display: flex;
//   align-items: center;

//   /* margin-top: 19.5vw; */
//   > span {
//     font-size: 1.2vw;
//     padding-left: 0.5vw;
//     margin-top: -1vw;
//   }
//   ${() =>
//     window.appHeight() / window.innerWidth > config.verticalSizeiphone
//       ? css`
//           span {
//             font-size: 2.5vw;
//             margin-top: 1.5vw;
//           }
//         `
//       : window.appHeight() > window.innerWidth &&
//         css`
//           span {
//             font-size: 1.7vw;
//           }
//         `}
// `;

// const CheckBox = styled.div`
//   display: inline-block;
//   width: 1.9vw;
//   height: 1.9vw;
//   background-color: #797fa1;
//   border: 0.3vw solid #797fa1;
//   border-radius: 5vw;
//   position: relative;
//   cursor: pointer;
//   /* margin-top: -1vw; */
//   margin-top: -1vw;
//   ${(props) =>
//     props.isSave == true &&
//     css`
//       background: linear-gradient(
//         180deg,
//         #06d746 24.48%,
//         #4dff80 52.08%,
//         #06d746 100%
//       );
//       border: 0.3vw solid #797fa1;
//     `}
//   ${() =>
//     window.appHeight() / window.innerWidth > config.verticalSizeiphone
//       ? css`
//           width: 3.9vw;
//           height: 3.9vw;
//           /* margin-top: 1vw;
//           margin-right: 1vw; */
//           margin: 1vw 1vw 0 0;
//         `
//       : window.appHeight() > window.innerWidth &&
//         css`
//           width: 2.5vw;
//           height: 2.5vw;
//           margin-right: 1vw;
//         `}
// `;

const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1vw;
  position: relative;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin-top: 2.5vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          position: relative;
          /* margin-top: 1vw; */
          top: 1vw;
          /* margin-left: 15vw; */
        `}
`;

const Popup = styled.div`
  // background-image: url("/images/popup_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;
  // theme ==================
  //background: #192756;
  //box-shadow: inset 0vw 0vw 0.8vw 0.2vw #5DD8FF, inset 0px 0px 4vw rgba(66, 255, 232, 0.63);
  //border: solid 0.2vw #BDEFFF;
  // theme ==================
  border-radius: 1.5vw;
  position: relative;
  width: 45vw;
  height: 34.5vw;
  z-index: -1;
  display: block;
  text-align: -webkit-center;
  padding: 0vw 2vw 0vw 1vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 87vw;
          height: 78vh;
          padding-bottom: 2vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          width: 72vw;
          height: 48.5vw;
          padding-bottom: 3vw;
        `}/* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 95vw;
          height: 78vw;
          padding-bottom: 2vw;
        `
      : window.appHeight() > window.innerWidth
        ? css`
          width: 63vw;
          height: fit-content;
          padding-bottom: 3vw;
          transform: scale(1.5);
        `
        : window.innerWidth / window.appHeight() > 2
          ? css`
          transform: scale(1.1);
        `
          : window.innerWidth / window.appHeight() > 1.7
            ? css`
          transform: scale(1.25);
        `
            : css`
          transform: scale(1.5);
        `} */
`;

const Title = styled.div`
  user-select: none;
  top: 0px;
  width: max-content;
  display: flex;
  position: relative;
  color: white;
  height: 5.54vw;
  font-size: 2vw;
  line-height: 5vw;
  z-index: 0;
  font-weight: 400;
  text-align: center;
  padding: 0 5vw;
  margin: -0.2vw auto;
  background-clip: border-box, border-box, content-box;
  background-size: 8.69vw, 8.69vw, 1.07vw;
  background-position: top 0 left 0, top 0 right 0, top 0 right 0;
  background-repeat: no-repeat, no-repeat, repeat-x;
  background-image: url("../${config.theme}/popup_title_bg_left.png"),
    url("../${config.theme}/popup_title_bg_right.png"),
    url("../${config.theme}/popup_title_bg_center.png");
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          background-size: 15.69vw, 15.69vw, 1.9vw;
          padding: 0px 7vw;
          font-size: 5vw;
          line-height: 7vw;
          height: 9.5vw;
          align-items: center;
        `
      : window.appHeight() > window.innerWidth && css``}
`;

export default function Condition(props) {
  const { t } = useTranslation();
  // const [isSave, setIsSave] = useState(false);
  // const [toCreateAccout, setToCreateAccount] = useState(false);
  // const [toCondition, setToCondition] = useState(false);
  // const [showConfirm, setShowConfirm] = useState(false);
  // const socket = useContext(SocketContext);
  // const popupStatus = useSelector(selectPopup);
  // const dispatch = useDispatch();

  return (
    <>
      <BG>
        <Popup>
          <Title>{t("terms and conditions")}</Title>

          <Black>
            <Item>
              <List>{t("conditionOne")}</List>
              <List>{t("conditionTwo")}</List>
              <List>{t("conditionTree")}</List>
              <List>{t("coditionFour")}</List>
              <List>{t("coditionFive")}</List>
            </Item>
          </Black>

          <ContainerButton>
            <Button
              bClass="rbRed"
              label={t("cancel")}
              width={
                window.appHeight() / window.innerWidth >
                  config.verticalSizeiphone
                  ? "35vw"
                  : window.appHeight() > window.innerWidth
                    ? "17vw"
                    : window.innerWidth / window.appHeight() > 2.15
                      ? "13vw"
                      : "13vw"
              }
              // fontSize="1.3vw"
              fontSize={
                window.appHeight() / window.innerWidth >
                  config.verticalSizeiphone
                  ? "3.5vw"
                  : window.appHeight() > window.innerWidth
                    ? "2vw"
                    : window.innerWidth / window.appHeight() > 2.15
                      ? "1.3vw"
                      : "1.3vw"
              }
              onClick={props.closeClick}
            />
          </ContainerButton>
        </Popup>
      </BG>
    </>
  );
}
