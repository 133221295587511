import React, { useState, Suspense, lazy, useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Link, Redirect, Switch, useLocation } from "react-router-dom";
import { selectContentpromo } from "../../Reducers/promotionSlice";
// import { selectHowtouse } from "../../Reducers/HowtouseSlice";
import styled from "styled-components";
import Language from "../Language/";
import Button from "../Button";
import Content from "../Content";
import Login from "../Form/Login";
import { useTranslation } from "react-i18next";

import { selectContenthow } from "../../Reducers/HowtouseSlice";
import { getLang, Transition } from "../../config";
import { selectHomeContentLP } from "../../Reducers/homeContentLPSlice";
import Terms from "../Terms";
const Games = lazy(() => import("../Games"));
const LiveCasino = lazy(() => import("../LiveCasino"));
// const GamePlayMobile = lazy(() => import("../GameList/GamePlayMobile"));
const ContentPromotion = lazy(() => import("../ContentPromotion"));
const FAQ = lazy(() => import("../Faq/"));
const Support = lazy(() => import("../Support/"));
const ContentHowtouse = lazy(() => import("../ContentHowtouse"));

const Nav = styled.div`
  /* background-image: url("../../lpimages/nav_bg_center.png"); */
  background-image: url("../../lpimages/nav_bg.png");
  background-repeat: initial;
  background-size: 100% 100%;
  width: 100%;
  height: 5vw;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 100;
`;

const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -13vw;
  width: calc(2.5vw * 1920 / 100);
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  // position: relative;
`;

const NavLeft = styled.div`
  position: relative;
  /* background-image: url("../../lpimages/m_nav_bg.png");
  background-size: cover;
  background-repeat: no-repeat; */
  // width: calc(1vw * 1920 / 100);
  width: 21.7vw;
`;

const Logo = styled.div`
  background-image: url("../../lpimages/spinix_normal_white_1_.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 4vw;
  width: 16vw;
  /* position: absolute; */
`;

const LinkMenu = styled(Link)`
  color: white;
  align-self: center;
  text-decoration: none;
  text-decoration-line: none;
  ${(props) =>
    props.active === "true" &&
    `
  color: #FF7000;
  `}
`;

const Li = styled.div`
  justify-content: center;
  position: relative;
  display: flex;
  font-size: 1vw;
  // width: calc(1.742vw * 1920 / 100);
  width: calc(3.442vw * 2032 / 100);
`;

const Line = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 3vw;
  background-size: cover;
  width: calc(0.4vw * 1920 / 100);
  background-image: url("../../lpimages/nav_devider.png");
`;

const NavRight = styled.div`
  //background-color:green;
  /* background-size: cover;
  background-image: url("../../lpimages/nav_bg_right.png"); */
  /* height: 4vw; */
  width: calc(1vw * 1920 / 100);
`;

const LoginButton = styled.div`
  margin-top: 0.9vw;
  right: 1.2vw;
  position: absolute;
`;

export const Navbar = (props) => {
  const location = useLocation();
  const promo = useSelector(selectContentpromo);
  const howto = useSelector(selectContenthow);
  const homeContentLP = useSelector(selectHomeContentLP);
  const { t, i18n } = useTranslation();
  // const [showButton, setShowButton] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [homeContent, setHomeContent] = useState([]);

  const ScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (homeContentLP.length !== 0) {
      setHomeContent(homeContentLP[3].contents);
    }
  }, [homeContentLP]);

  return (
    <>
      <Nav>
        <NavLeft>
          <LinkMenu to="/">
            <Logo />
          </LinkMenu>
        </NavLeft>
        <NavMenu>
          <Li>
            <LinkMenu
              to="/"
              onClick={ScrollToTop}
              active={location.pathname === "/" ? "true" : "false"}
            >
              {t("home")}
            </LinkMenu>
          </Li>
          <Line />
          <Li>
            <LinkMenu
              to="/live-casino"
              onClick={ScrollToTop}
              active={
                location.pathname.split("/")[1] === "live-casino"
                  ? "true"
                  : "false"
              }
            >
              {t("Live Casino")}
            </LinkMenu>
          </Li>
          <Line />
          <Li>
            <LinkMenu
              to="#"
              onClick={() =>
                (window.location =
                  "https://provider.spxslot.xyz/?lang=" + i18n.language)
              }
              active={
                location.pathname.split("/")[1] === "games" ? "true" : "false"
              }
            >
              {t("games")}
            </LinkMenu>
          </Li>
          {/* <Line /> */}
          {/* <Li>
            <LinkMenu
              to="/ContentPromotion"
              onClick={ScrollToTop}
              active={
                location.pathname.split("/")[1] == "ContentPromotion"
                  ? "true"
                  : "false"
              }
            >
              {t("promotions")}
            </LinkMenu>
          </Li> */}
          <Line />
          <Li>
            <LinkMenu
              to="/faq"
              onClick={ScrollToTop}
              active={location.pathname === "/faq" ? "true" : "false"}
            >
              {t("faq")}
            </LinkMenu>
          </Li>
          <Line />
          <Li>
            <LinkMenu
              to="/support"
              onClick={ScrollToTop}
              active={location.pathname === "/support" ? "true" : "false"}
            >
              {t("support")}
            </LinkMenu>
          </Li>
        </NavMenu>

        <NavRight>
          <Language width="5vw" top="0.75vw" right="12vw" />
          <LoginButton>
            <Button
              bClass="rtRedLogin"
              width="9vw"
              height="3vw"
              label={t("login")}
              onClick={() => setShowMessage(true)}
            />
          </LoginButton>
        </NavRight>
      </Nav>

      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path="/ContentHowtouse">
            <ContentHowtouse />
          </Route>
          <Route
            path={"/ContentHowtouse/:id"}
            render={(props) => (
              <Content
                {...props}
                howtouse={"howtouse"}
                {...howto.contenthow[getLang()][
                  parseInt(props.match.params.id)
                ]}
              />
            )}
          ></Route>
          <Redirect exact from="/live-casino" to="/live-casino/hot" />
          <Route path="/live-casino">
            <LiveCasino />
          </Route>
          <Redirect exact from="/games" to="/games/hot" />
          <Route path="/games">
            <Games />
          </Route>
          <Route exact path="/ContentPromotion">
            <ContentPromotion />
          </Route>
          <Route
            path={"/ContentPromotion/:id"}
            render={(props) => (
              <Content
                {...props}
                promotion={"promotion"}
                {...promo.contentpromo[getLang()][
                  parseInt(props.match.params.id)
                ]}
              />
            )}
          ></Route>
          <Route path="/faq">
            <FAQ />
          </Route>
          <Route path="/support">
            <Support title={t("support")} text={t("SupportDescription")} />
          </Route>
          <Route path="/terms-and-conditions">
            <Terms />
          </Route>
          <Route
            path={"/:path"}
            render={(props) => (
              <Content
                {...props}
                article={"article"}
                {...homeContent.filter(
                  (i) => i.path === props.match.params.path
                )[0]}
              />
            )}
          ></Route>
        </Switch>
      </Suspense>

      <Transition
        in={showMessage}
        timeout={300}
        classNames="alert"
        unmountOnExit
        // onEnter={() => setShowButton(false)}
        // onExited={() => setShowButton(true)}
      >
        <Login onClose={() => setShowMessage(false)} />
      </Transition>
    </>
  );
};

export default Navbar;
