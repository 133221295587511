import { createSlice } from "@reduxjs/toolkit";
export const slotContentLPSlice = createSlice({
    name: "slotContentLP",
    initialState: {
        contents: [
            
        ],
    },
    reducers: {
        actionSlotContentLP: (state, action) => {
            state.contents = action.payload;
        },
    },
});

export const { actionSlotContentLP } = slotContentLPSlice.actions;
export const selectSlotContentLP = (state) => state.slotContentLP.contents;
export default slotContentLPSlice.reducer;