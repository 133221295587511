import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Transition } from "../../config";
import PopupFes from "../FestivalPopup/popup";
import AlertReward from "../FestivalPopup/AlertdoubleReward";
// import DetailPopup from "./Detail";
// import { SocketContext } from "../../context/socket";
import AlertMessage from "../Dialog/AlertMessage";
import { useSelector, useDispatch } from "react-redux";
import { selectuserProfile } from "../../Reducers/userProfileSlice";
import { actionPopup } from "../../Reducers/popupSlice";
import ValentineDetail from "./ValentineDetail";

const Container = styled.div`
  margin-left: 2vw;
`;

const Border = styled.div`
  height: 2.5vw;
  background-color: #ec3b3b;
  border: none;
  width: 0.5vw;
`;
const BoxContentTop = styled.div`
  display: flex;
  h1 {
    font-size: 1.5vw;
    color: #ff7c7c;
    margin-left: 0.5vw;
    font-weight: 400;
  }
`;

const ContentHeader = styled.div`
  display: flex;
  color: #000000b8;
  width: 40vw;
  h1 {
    font-size: 1.3vw;
    font-weight: 400;
    width: 32vw;
    height: 12vw;
    margin-left: 1vw;
    text-align: start;
    padding-top: 0.5vw;
  }
`;

const BoxLeft = styled.div`
  width: 40vw;
  margin-top: 1vw;
  display: flex;
  justify-content: flex-start;
  background-image: url(/images/event_val/postit.png);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  height: 14vw;
`;
const BoxRight = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;
const MissionList = styled.div`
  width: 9vw;
  height: 15.5vw;
  display: flex;
  margin: 1vw;
  position: relative;
`;

const Bottom = styled.div`
  cursor: pointer;
  width: 8vw;
  height: 3.3vw;
  font-size: 1.8vw;
  border-radius: 1vw;
  margin-top: 10.4vw;
  margin-left: 1.5vw;
  position: absolute;
  color: #ffffff;
  background: linear-gradient(180deg, #06d746 38.02%, #059604 100%);
  box-shadow: inset 0px 0px 4px 2px #4dff89;
`;
const Img = styled.img`
  margin-top: -32.3vw;
  width: 23.5vw;
  margin-left: 6.2vw;
  margin-right: -6.3vw;
  position: absolute;
`;

const Image = styled.img`
  width: 9vw;
  height: 10.5vw;
  margin-top: 1.3vw;
  margin-left: 1vw;
`;

const Diamond = styled.img`
  width: 2.9vw;
  /* height: 1.8vw; */
  margin-top: 0.5vw;
  margin-left: 0.6vw;
  display: flex;
`;

const BoxDiamond = styled.div`
  width: 7vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const Text = styled.div`
  align-self: center;
  font-size: 1.5vw;
  margin-left: 3.2vw;
  margin-top: -0.5vw;
  color: #ffffff;
`;
const ContainerListDiamond = styled.div`
  display: flex;
  flex-direction: row;
`;

const ContainerDiamond = styled.div`
  display: flex;
  flex-direction: column;
  width: 8.5vw;
  height: 3.3vw;
  border-radius: 1.5vw;
  outline: none;
  margin-top: 11vw;
  margin-left: -8.8vw;
  border: none;
  font-size: 1.8vw;
  position: absolute;
  margin-bottom: 0.5vw;
  /* background: linear-gradient(180deg, #fff6d6 -0.98%, #ffc700 100%);
  box-shadow: inset 0px 0px 4px 2px #ffc700; */
  color: #ffffff;
  background-color: #ff4c51;
`;

const EventTime = styled.div`
  color: white;
  font-size: 1.1vw;
  margin-left: 22vw;
  width: 39.5vw;
  /* float: right; */
  margin-top: 2.2vw;
  /* padding-right: 1.5vw; */
  position: absolute;
  font-weight: 400;
`;
const Textplay = styled.div`
  color: white;
  margin-top: 0.4vw;
  font-size: 1.5vw;
`;
const ContentReceived = styled.div`
  width: 9vw;
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-left: 1vw;
  font-size: 1vw;
  margin-top: -2.8vw;
  font-weight: 400;
`;

function Index(props) {
  const { t, i18n } = useTranslation();
  const [openDetail, setOpenDetail] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const profile = useSelector(selectuserProfile);
  const [emitError, setEmitError] = useState({ status: false, message: "" });
  const dispatch = useDispatch();
  // const [bonus, setBonus] = useState(0);
  const reCallMyArr = (arr = []) => {
    return arr.reduce(
      (total, currentValue) => (total = total + currentValue.c1),
      0
    );
  };

  return (
    <>
      <PopupFes
        // styled={{ tarnformScale:"0.8"}}
        title={t("valentine event")}
        width={window.innerWidth / window.appHeight() > 2.15 ? "60vw" : "60vw"}
        height={window.innerWidth / window.appHeight() > 2.15 ? "41vw" : "41vw"}
        closeClick={props.closeClick}
        heightContent="31vw"
        widthContent="56vw"
        closebuttonRight="-2vw"
        closebuttonTop="-1vw"
        imgleft="../../images/event_val/title_prop.png"
        ImgMarginLeft="10vw"
        ImgMarginTopLeft="-3vw"
        imgright="../../images/event_val/title_prop.png"
        ImgMarginRight="40vw"
        ImgMarginTopRight="-3vw"
        closeClick={props.closeClick}
      >
        <Container>
          <BoxContentTop>
            <Border />
            <h1>{t("vt topic")}</h1>
          </BoxContentTop>
          <ContentHeader>
            <h1>
              {t("vt first sentence")}
              {/* {t("vt second sentence")}&nbsp;
              {t("vt third sentence")} */}
            </h1>
          </ContentHeader>
          {/* <BorderLine> */}
          <BoxLeft>
            <MissionList>
              {profile.valentine.selected.length > 0 ? (
                <>
                  <Image src={"../../images/event_val/check.png"} />
                  <ContainerListDiamond>
                    <ContainerDiamond>
                      <BoxDiamond>
                        <Diamond src={"../../images/icon_diamond_copy.png"} />
                        <ContentReceived>{t("vt received")} </ContentReceived>
                        <Text>
                          {reCallMyArr(profile.valentine.selected[0])}
                        </Text>
                      </BoxDiamond>
                    </ContainerDiamond>
                  </ContainerListDiamond>
                </>
              ) : profile.valentine.is_select === 1 &&
                profile.valentine.day === 1 ? (
                <>
                  <Bottom
                    onClick={() => {
                      dispatch(
                        actionPopup({ festival: false, festivalGame: true })
                      );
                    }}
                  >
                    <Textplay>{t("cny play")}</Textplay>
                  </Bottom>
                </>
              ) : null}
            </MissionList>

            {/* 2 */}
            <MissionList>
              {profile.valentine.selected.length > 1 ? (
                <>
                  <Image
                    style={{ marginLeft: "2.5vw" }}
                    src={"../../images/event_val/check.png"}
                  />
                  <ContainerListDiamond style={{ marginLeft: "1vw" }}>
                    <ContainerDiamond>
                      <BoxDiamond>
                        <Diamond src={"../../images/icon_diamond_copy.png"} />
                        <ContentReceived>{t("vt received")} </ContentReceived>
                        <Text>
                          {reCallMyArr(profile.valentine.selected[1])}
                        </Text>
                      </BoxDiamond>
                    </ContainerDiamond>
                  </ContainerListDiamond>
                </>
              ) : profile.valentine.is_select === 1 &&
                profile.valentine.day === 2 ? (
                <>
                  <Bottom
                    style={{ marginLeft: "3.5vw", marginTop: "11vw" }}
                    onClick={() => {
                      dispatch(
                        actionPopup({ festival: false, festivalGame: true })
                      );
                    }}
                  >
                    <Textplay>{t("cny play")}</Textplay>
                  </Bottom>
                </>
              ) : null}
            </MissionList>

            <MissionList>
              {profile.valentine.selected.length > 2 ? (
                <>
                  <Image
                    style={{ marginLeft: "4.5vw" }}
                    src={"../../images/event_val/check.png"}
                  />
                  <ContainerListDiamond>
                    <ContainerDiamond style={{ marginLeft: "-9.5vw" }}>
                      <BoxDiamond>
                        <Diamond src={"../../images/icon_diamond_copy.png"} />
                        <ContentReceived>{t("vt received")} </ContentReceived>
                        <Text>
                          {reCallMyArr(profile.valentine.selected[2])}
                        </Text>
                      </BoxDiamond>
                    </ContainerDiamond>
                  </ContainerListDiamond>
                </>
              ) : profile.valentine.is_select === 1 &&
                profile.valentine.day === 3 ? (
                <>
                  <Bottom
                    style={{ marginLeft: "4.5vw", marginTop: "11vw" }}
                    onClick={() => {
                      dispatch(
                        actionPopup({ festival: false, festivalGame: true })
                      );
                    }}
                  >
                    <Textplay>{t("cny play")}</Textplay>
                  </Bottom>
                </>
              ) : null}
            </MissionList>
          </BoxLeft>

          <BoxRight
            onClick={() => {
              setOpenDetail(true);
            }}
          >
            {i18n.language === "th" ? (
              <Img src={"../../images/event_val/mascot.png"} />
            ) : (
              <Img src={"../../images/event_val/mascot_en.png"} />
            )}
          </BoxRight>
          <EventTime>{t("vt event period")}</EventTime>
        </Container>
        <Transition showPop={openDetail} type="sb2t">
          <ValentineDetail
            closeClick={() => {
              setOpenDetail(false);
            }}
          />
        </Transition>
        <Transition showPop={popUp} type="sb2t">
          <AlertReward
            // amount={bonus}
            closeClick={() => {
              setPopUp(false);
            }}
          />
        </Transition>
        <Transition showPop={emitError.status} type="alert">
          <AlertMessage
            message={t(emitError.message)}
            onClick={() => {
              setEmitError({ status: false });
            }}
          />
        </Transition>
      </PopupFes>
    </>
  );
}

export default Index;
