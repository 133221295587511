import { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import Popup from "../Popup";
import Button from "../Button";
import { useTranslation } from "react-i18next";
import { config } from "../../config";
import { socket } from "../../context/socket";
import ConfirmPopup from "../Dialog/ConfirmPopup";
import PinInput from "react-pin-input";
import { selectPopup, actionPopup } from "../../Reducers/popupSlice";
import Loading from "../Loading";
import { useDispatch, useSelector } from "react-redux";
import { Transition } from "../../config";
import AlertMessage from "../Dialog/AlertMessage";

const BG = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 20;
  align-items: center;
  justify-content: center;
`;

const BGpopUp = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  z-index: 76;
  align-items: center;
  justify-content: center;
`;

const ForgetPasswordContainerPincode = styled.div`
  width: max-content;
  margin: 0 auto;
  position: relative;
  z-index: 20;
  color: #fff;
  margin-top: 1.5vw;
  h4 {
    font-size: 1.25vw; //24px
    color: #fff;
  }
  p {
    font-size: 1.2vw;
    display: flex;
    justify-content: center;
  }
  h6 {
    width: 20.83vw;
    color: #797fa1;
    font-size: 0.8vw; //12px
    font-weight: normal;
    display: flex;
    margin-top: 2vw;
    margin-block-start: 0.2vw;
    margin-block-end: 1vw;
  }
`;

const StepThree = styled.div`
  user-select: none;

  p {
    user-select: none;
    color: #fff;
    font-size: 1.4vw;
    margin-top: 1.5vw;
    text-align: center;
    ${() =>
      window.appHeight() > window.innerWidth &&
      css`
        font-size: 2.4vw;
      `}
  }
`;
const ContainerPincode = styled.div`
  margin-top: ${() =>
    window.appHeight() > window.innerWidth ? "3vw" : "1.5vw"};
`;

const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2vw;
  ${() =>
    window.appHeight() > window.innerWidth &&
    css`
      justify-content: center;
      margin-top: 5vw;
      width: auto;
      margin-left: auto;
    `}
`;

const TextPopUp = styled.div`
  font-size: 1vw;
  ${() =>
    window.appHeight() > window.innerWidth &&
    css`
      font-size: 2.5vw;
    `}
`;

const PopupModal = styled.div`
  user-select: none;
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;
  border-radius: 2vw;
  z-index: 75;
  text-align: center;
  justify-content: center;
  margin: auto;
  width: 35.16vw;
  height: 19.7vw;
  position: relative;
  ${() =>
    window.appHeight() > window.innerWidth &&
    css`
      width: 74.07vw;
      height: 35.5vw;
      border-radius: 3vw;
    `}
`;

const Title = styled.div`
  user-select: none;
  width: max-content;
  margin: -0.2vw auto;
  color: white;
  font-size: 2vw;
  z-index: 0;
  font-weight: 400;
  text-align: center;
  line-height: 5vw;
  padding: 0 9vw;
  background-clip: border-box, border-box, content-box;
  background-size: 8vw, 8vw, 0.98vw;
  background-position: top 0 left 4vw, top 0 right 4vw, top -0vw right 0;
  background-repeat: no-repeat, no-repeat, repeat-x;
  background-image: url("../${config.theme}/popup_title_bg_left.png"),
    url("../${config.theme}/popup_title_bg_right.png"),
    url("../${config.theme}/popup_title_bg_center.png");

  ${() =>
    window.appHeight() > window.innerWidth &&
    css`
      transform: scale(1.5);
      background-position: top 1.2vw left 4vw, top 1.2vw right 4vw,
        top 1.2vw right 0;
      background-size: 8.1vw, 8.1vw, 0.99vw;
      height: 6vw;
      font-size: 3vw;
      padding: 1vw 11vw 1vw 11vw;
      line-height: 5.5vw;
    `}
`;

export default function SetPinCode(props) {
  const { t } = useTranslation();
  const [confirm, setConfirm] = useState("");
  const [load, setLoad] = useState(false);
  const [pinCodeNew, setPincodeNew] = useState("");
  const [pincodeLength, setPincodeLength] = useState(4);
  // const [arrayUrl, setArrayUrl] = useState("");
  const dispatch = useDispatch();
  const popupStatus = useSelector(selectPopup);

  const [emitError, setEmitError] = useState({
    status: false,
    message: "",
    errorCode: "",
  });

  // let reqExpPhone = new RegExp(/^[0-9\b]+$/);

  const onComplete = (val, idx) => {
    setPincodeNew(val);
    // console.log(val, idx);
  };

  const onChange = (val, idx) => {
    if (val.length < pincodeLength) {
      setPincodeNew("");
    }
  };

  const onSubmit = () => {
    try {
      socket.emit(
        "changepassword",
        {
          tel: props.phone,
          pincode_new: pinCodeNew,
          otp: props.dataOtp,
        },
        (body) => {
          setLoad(false);
          console.log("Pincode", body);
          if (typeof body != "undefined") {
            if (body.status == true) {
              setConfirm(true);
            } else if (body.status === false) {
              setEmitError({ status: true, message: body.errorCode });
              setTimeout(() => {
                setEmitError({ status: false });
              }, 3000);
            }
          }
        }
      );
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  };

  //   console.log("Telllll", props.phone)
  //   console.log("OTP", props.dataOtp)
  return (
    <BG>
      {load == true ? <Loading /> : ""}
      <PopupModal>
        <Title>{t("change pincode password")}</Title>
        <ForgetPasswordContainerPincode>
          <StepThree>
            <p>{t("text pincode")}</p>
          </StepThree>
          <ContainerPincode>
            <PinInput
              onFocus={() => {
                window.isEdit = true;
              }}
              onBlur={() => {
                window.isEdit = false;
              }}
              length={pincodeLength}
              initialValue=""
              onChange={onChange}
              type="numeric"
              inputMode="number"
              value={pinCodeNew}
              style={{ marginBottom: "1vw" }}
              inputStyle={{
                borderColor: "#000000",
                backgroundColor: "#000000",
                border: "1",
                borderRadius: "60%",
                width: window.appHeight() > window.innerWidth ? "8vw" : "5vw",
                height: window.appHeight() > window.innerWidth ? "8vw" : "5vw",
                borderStyle: "solid",
                color: "#FF7000",
                fontSize:
                  window.appHeight() > window.innerWidth ? "6vw" : "2vw",
              }}
              inputFocusStyle={{ borderColor: "#FF7000" }}
              onComplete={onComplete}
              autoSelect={true}
              regexCriteria={/^[0-9]*$/}
            />
          </ContainerPincode>
          <ContainerButton>
            <Button
              bClass="rbRed"
              label={t("pin back")}
              width={window.appHeight() > window.innerWidth ? "25vw" : "13vw"}
              fontSize={
                window.appHeight() > window.innerWidth ? "2.5vw" : "1.2vw"
              }
              onClick={() => {
                dispatch(actionPopup({ setPinCodePage: false }));
              }}
            />
            <Button
              bClass={pinCodeNew.length < 4 ? "rbGray" : "rtNext"}
              label={t("confirm")}
              width={window.appHeight() > window.innerWidth ? "25vw" : "13vw"}
              fontSize={
                window.appHeight() > window.innerWidth ? "2.5vw" : "1.2vw"
              }
              onClick={() => {
                if (pinCodeNew.length <= 4) {
                  onSubmit();
                  setLoad(true);
                } else {
                }
                // setLoad(true);
              }}
            />
          </ContainerButton>
        </ForgetPasswordContainerPincode>
      </PopupModal>
      {confirm === true ? (
        <BGpopUp style={{ justifyContent: "center" }}>
          <ConfirmPopup
            messageBottom={t("okay")}
            onClick={() => {
              setConfirm(false);
              props.closeClick();
            }}
          >
            <TextPopUp> {t("successfully changed")}</TextPopUp>
          </ConfirmPopup>
        </BGpopUp>
      ) : (
        ""
      )}
      <Transition showPop={emitError.status} type="alert">
        <AlertMessage
          message={t(emitError.message)}
          onClick={() => {
            setEmitError({ status: false });
          }}
        />
      </Transition>
    </BG>
  );
}
