import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import breakpoint from "../breakpoints";
import AboutSlotItem from "./AboutSlotItem";
import { useSelector } from "react-redux";
import { selectHomeContentLP } from "../../Reducers/homeContentLPSlice";

const Background = styled.div`
  background-image: url("../../lpimages/bg_content2.png");
  width: 100%;
  background-size: 102%;
  background-position-x: center;
  background-repeat: no-repeat;
  padding-bottom: 3vw;
  background-color: #000;
  @media ${breakpoint.device.mobile} {
    // height:51.21vw;
    background-image: url("../../lpimages/bg_content.png");
    background-size: 130%;
    background-position-x: center;
  }
`;

const Title = styled.div`
  // justify-content:center;
  margin-top: 2vw;
  margin-left: -6vw;
  padding-bottom: 2.5vw;
  background-color: black;
  // text-align: -webkit-center;
  // -webkit-align-items: center;
  //   -webkit-box-align: center;
  //   -ms-flex-align: center;
  //   text-align: -webkit-center;
  justify-content: center;
  display: flex;

  @media ${breakpoint.device.ipad} {
    margin-left: -11vw;
  }
  @media ${breakpoint.device.mobile} {
    margin-top: -15vw;
  }
`;

const Container = styled.div`
  width: 45%;
  // background-color:black;
  display: -webkit-box;
  /* position: relative; */
  justify-content: center;
  @media ${breakpoint.device.mobile} {
    width: 62%;
    display: inline-block;
    position: relative;
    margin-top: 18vw;
  }
`;
const Box = styled.div`
  width: 18vw;
  margin-right: 2vw;
  display: block;
  // cursor: pointer;
  position: relative;
  p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 1.3vw;
    line-height: 1.95vw;
    margin-top: 0.5vw;
    user-select: none;
  }

  @media ${breakpoint.device.ipad} {
    width: 19.1vw;
  }
  @media ${breakpoint.device.mobile} {
    width: 85vw;
    margin-right: 5vw;
    display: block;
    // cursor: pointer;
    position: relative;
    color: white;
    p {
      margin-bottom: 2vw;
      font-size: 12px;
      position: relative;
      display: flex;
      line-height: 16px;
    }
  }
`;

const Text = styled.div`
  color: white;
  position: relative;
  font-size: 1.5vw;
  @media ${breakpoint.device.mobile} {
    font-size: 4.83vw;
  }
`;

function Index(props) {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const homeContent = useSelector(selectHomeContentLP);

  useEffect(() => {
    if (homeContent.length !== 0) {
      setItems(homeContent.find((i) => i.location === "SlotContents").contents);
    }
  }, [homeContent]);

  // console.log("items", items)

  return (
    <>
      <Background>
        {items.map((items, index) => (
          <AboutSlotItem {...items} key={index} />
        ))}

        <Title>
          <Container>
            <Box>
              <img alt=""
                src="../../../lpimages/seo_content_img_1.png"
                style={{ width: "95%" }}
              />
              <Text>1.{t("title howto slot1")}</Text>
              <p>{t("DesHowtoSlot1")}</p>
            </Box>
            <Box>
              <img alt=""
                src="../../../lpimages/seo_content_img_2.png"
                style={{ width: "95%" }}
              />
              <Text>2.{t("title howto slot2")}</Text>
              <p>{t("DesHowtoSlot2")}</p>
            </Box>
            <Box>
              <img alt=""
                src="../../../lpimages/seo_content_img_3.png"
                style={{ width: "95%" }}
              />
              <Text>3.{t("title howto slot3")}</Text>
              <p>{t("DesHowtoSlot3")}</p>
            </Box>
          </Container>
        </Title>
      </Background>
    </>
  );
}
export default Index;
