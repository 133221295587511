const sizeWidth = {
  xs: "320px",
  sm: "768px",
  lg: "1200px",
  wGalaxyS5: "640px",
  wPixel2: "731px",
  wIphone5: "568px",
  wIphone6: "667px",
  wIphone6P: "736px",
  wIphoneX: "812px",
  wIphone12P: "844px",
};
const sizeHeight = {
  hGalaxyS5: "360px",
  hPixel2: "411px",
  hIphone5: "320px",
  hIphone6: "375px",
  hIphone6P: "414px",
  hIphoneX: "375px",
  hIphone12P: "390px",
};
const device = {
  xs: `(max-width: ${sizeWidth.xs})`,
  sm: `(max-width: ${sizeWidth.sm})`,
  lg: `(max-width: ${sizeWidth.lg})`,
  GalaxyS5: `(max-width: ${sizeWidth.wGalaxyS5}) and (max-height: ${sizeHeight.hGalaxyS5})`,
  Pixel2: `(max-width: ${sizeWidth.wPixel2}) and (max-height: ${sizeHeight.hPixel2})`,
  Iphone5_hor: `(max-width: ${sizeWidth.wIphone5}) and (max-height: ${sizeHeight.hIphone5})`,
  Iphone5_ver: `(max-width: ${sizeHeight.hIphone5}) and (max-height: ${sizeWidth.wIphone5})`,
  Iphone6P: `(max-width: ${sizeWidth.wIphone6P}) and (max-height: ${sizeHeight.hIphone6P})`,
};

const deviceVertical = {
  GalaxyS5: `(max-width: ${sizeHeight.hGalaxyS5}) and (max-height: ${sizeWidth.wGalaxyS5})`,
  Pixel2: `(max-width: ${sizeHeight.hPixel2}) and (max-height: ${sizeWidth.wPixel2})`,
  Iphone6P: `(max-width: ${sizeHeight.hIphone6P}) and (max-height: ${sizeWidth.wIphone6P})`,
  IphoneX: `(max-width: ${sizeHeight.hIphoneX}) and (max-height: ${sizeWidth.wIphoneX})`,
  Iphone12P: `(max-width: ${sizeHeight.hIphone12P}) and (max-height: ${sizeWidth.wIphone12P})`,
};

export default { sizeWidth, sizeHeight, device, deviceVertical };
