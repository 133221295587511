import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import breakpoint from "../breakpoints";

const BannerItemContainer = styled.div`
  width: 60vw;
  @media ${breakpoint.device.mobile} {
    width: 100%;
  }
`;

const WelcomeText = styled.div`
  width: 50%;
  display: inline-block;
  margin-top: 6.44vw;
  margin-bottom: 5.02vw;
  margin-left: 3vw;
  color: #fff;
  h4 {
    font-size: 5vw;
    line-height: 1;
  }
  h1 {
    font-size: 1.875vw;
    color: #ff7000;
    font-weight: 400;
  }
  p {
    width: 80%;
    font-size: 1.25vw;
    margin: 0.75vw 0;
    line-height: 1.25;
    font-weight: 400;
  }
  a {
    color: #ff7000;
  }
  @media ${breakpoint.device.mobile} {
    width: 90%;
    display: block;
    text-align: center;
    margin: 65.63vw auto 5.63vw auto;
    h4 {
      font-size: 23.2vw;
    }
    h1 {
      font-size: 8.7vw;
      padding-top: 10vw;
      line-height: 1.2;
    }
    p {
      width: auto;
      white-space: pre-wrap;
      // color: red;
      font-size: 1.65vh; //old value is 2.35vw
      font-weight: 400;
    }
  }
`;

const Image = styled.div`
  width: 65%;
  position: absolute;
  top: 0;
  right: 2vw;
  z-index: -1;
  > img {
    width: 100%;
  }
  @media ${breakpoint.device.mobile} {
    right: unset;
    width: 100%;
  }
`;

const BannerImage = styled.img`
  /* position: absolute;
    right: 0;
    height: 100%; */
  /* @media ${breakpoint.device.mobile} {
    width: 82.12vw;
    top: 75vw;
    right: initial;
  }    */
`;

function BannerItem(props) {
  const { i18n } = useTranslation();

  // console.log("BannerItem props:", props)

  return (
    <BannerItemContainer>
      <WelcomeText>
        <h1>{i18n.language === "th" ? props.title1_th : props.title1_en}</h1>
        <h4>{i18n.language === "th" ? props.title2_th : props.title2_en}</h4>
        <span
          dangerouslySetInnerHTML={{
            __html: i18n.language === "th" ? props.detail_th : props.detail_en,
          }}
        >
          {/* {i18n.language == "th" ? props.detail_th : props.detail_en} */}
        </span>
      </WelcomeText>
      <Image>
        <BannerImage src={props.img} alt={props.seo.alt} />
      </Image>
    </BannerItemContainer>
  );
}

export default BannerItem;
