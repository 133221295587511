import React, { useState, useEffect, useRef } from 'react';
import useOnClickOutside from "../use-onclick-outside";
import styled, { css } from 'styled-components'
import LanguageItem from './LanguageItem'
import breakpoint from "../breakpoints";
import i18next from 'i18next';

import { getNewUrlwithLang } from "../../config"

const Current = styled.div`
  display: flex;
  flex-direction: column;
  background-color:#101119;
  background-size: contain;
  background-repeat: no-repeat;
  /* width: 17.3913vw; */
  background-position: center;
  z-index: 20;
  position: absolute;
  padding:0.1vw;
  /* width: 11vw; */
  border: 2px solid #3D3D53;
  border-radius:1vw;
  ${props => css`
    width: ${props.width};
    top: ${props.top};
    right: ${props.right};
  `}

  @media ${breakpoint.device.mobile} {
    position: absolute;
    right: 8vw;
    height: 9.66vw;
    background-color:#101119;
    // border-radius: 2.42vw;
  }
`;


function Index(props) {
    const [currentLg, setCurrentLg] = useState(false);
    const [showPop, setShowPop] = useState(false);
    const [langs, setLangs] = useState('th');
    const ref = useRef();
    useOnClickOutside(ref, () => setShowPop(false));

    useEffect(() => {
        if (i18next.language === 'en') {
            setCurrentLg(true);
        }
    }, []);

    return (
        <>
            <Current onClick={() => { setShowPop(true) }}
                style={{ width: props.width, top: props.top, right: props.right }}>
                {currentLg ? <LanguageItem
                    icon="flag_us.png"
                    label="EN"
                    lang="en"
                    {...props} />
                    : <LanguageItem
                        icon="flag_th.png"
                        label="TH"
                        lang="th"
                        {...props} />
                }
            </Current>
            {showPop ?
                currentLg ?
                    <Current
                        ref={ref}
                        style={{
                            width: props.width,
                            top: props.top,
                            right: props.right,
                            // height: "9.66vw"
                            height: showPop && window.innerWidth <= breakpoint.deviceSize ? "18.66vw" : window.innerWidth <= breakpoint.deviceSize ? "9.66vw" : ""
                        }}
                    >
                        <LanguageItem
                            icon="flag_us.png"
                            label="EN"
                            lang={langs}
                            {...props}
                            onClick={() => {
                                setShowPop(false)
                                setCurrentLg(true)
                                setLangs('en')
                                i18next.changeLanguage('en');
                                getNewUrlwithLang("en")
                                // console.log("en");
                            }}
                        />
                        <LanguageItem
                            icon="flag_th.png"
                            label="TH"
                            lang={langs}
                            {...props}
                            onClick={() => {
                                setShowPop(false)
                                setCurrentLg(false)
                                setLangs('th')
                                i18next.changeLanguage('th');
                                getNewUrlwithLang("th")
                                // console.log("th");
                            }}
                        />
                    </Current>
                    :
                    <Current
                        ref={ref}
                        style={{
                            width: props.width,
                            top: props.top,
                            right: props.right,
                            height: showPop && window.innerWidth <= breakpoint.deviceSize ? "18.66vw" : window.innerWidth <= breakpoint.deviceSize ? "9.66vw" : ""
                        }}
                    >
                        <LanguageItem
                            icon="flag_th.png"
                            label="TH"
                            lang={langs}
                            {...props}
                            onClick={() => {
                                setShowPop(false)
                                setCurrentLg(false)
                                setLangs('th')
                                i18next.changeLanguage('th');
                                getNewUrlwithLang("th")
                                // console.log("th");
                            }}
                        />
                        <LanguageItem
                            icon="flag_us.png"
                            label="EN"
                            lang={langs}
                            {...props}
                            onClick={() => {
                                setShowPop(false)
                                setCurrentLg(true)
                                setLangs('en')
                                i18next.changeLanguage('en');
                                getNewUrlwithLang("en")
                                // console.log("en");
                            }}
                        />
                    </Current>
                :

                ""
            }
        </>
    );
}
export default Index;