import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { useDispatch } from "react-redux";
import {
  actionCloseSound,
  actionWinPopSound,
} from "../Reducers/userProfileSlice";
import { config } from "../config";

const PopupModal = styled.div`
  user-select: none;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position-x: center;
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;
  border-radius: 2vw;
  z-index: 50;
  text-align: center;
  justify-content: center;
  margin: auto;
  top: 0;
  width: 74vw;
  height: calc(100vw * 0.4363);
  height: 100%; //fix
  position: relative;
  h1 {
    position: absolute;
    color: white;
    text-align: center;
    font-size: 2.5vw;
    width: 100%;
    z-index: 10;
    font-weight: 700;
    top: -1vw;
  }

  ${(props) => css`
    border-radius: 3vw;
    margin-top: ${props.marginTop};
    z-index: ${props.zIndex};
  `}
`;

const Close = styled.img`
  user-select: none;
  position: absolute;
  width: 4.9vw;
  right: 2.5%;
  top: -4%;
  cursor: pointer;
  @media only screen and (max-width: 480px) and (orientation: portrait) {
    transform: scale(1);
    right: -2%;
    top: -1%;
    width: 11vw;
  }
`;

const Content = styled.div`
  user-select: none;
  position: absolute;
  top: 5vw;
  width: 100%;
  height: 100%;

  @media only screen and (max-width: 480px) and (orientation: portrait) {
    top: 2vw;
    position: relative;
  }
`;

const Title = styled.div`
  user-select: none;
  width: ${(props) => (props.header === "header" ? `null` : `max-content`)};
  margin: ${(props) =>
    props.header === "header"
      ? `0 6vw;`
      : `-0.2vw auto;
  `};
  color: white;
  font-size: 2vw;
  z-index: 0;
  font-weight: 400;
  text-align: center;
  line-height: 5vw;
  padding: 0 9vw;
  background-clip: border-box, border-box, content-box;
  background-size: ${(props) =>
    props.size === "size"
      ? `70vw`
      : ` 8vw,
      8vw,
      0.98vw;
  `};
  background-position: top 0 left 4vw, top 0 right 4vw, top -0vw right 0;

  background-repeat: no-repeat, no-repeat, repeat-x;
  // background-image:
  //     url('../${config.theme}/popup_title_bg_left.png'),
  //     url('../${config.theme}/popup_title_bg_right.png'),
  //     url('../${config.theme}/popup_title_bg_center.png');

  background-image: ${(props) =>
    props.header === "header"
      ? // `url('../${config.theme}/diamond_shop_title_bg.png')
        // `
        `none`
      : `url('../${config.theme}/popup_title_bg_left.png'),
      url('../${config.theme}/popup_title_bg_right.png'),
      url('../${config.theme}/popup_title_bg_center.png');
  `};

  //Portrait
  @media only screen and (max-width: 480px) and (orientation: portrait) {
    transform: scale(1.5);
    background-position: top 1.2vw left 4vw, top 1.2vw right 4vw,
      top 1.2vw right 0;
    background-size: 8.1vw, 8.1vw, 0.99vw;
    // background-size: 11.69vw,11.69vw,1.45vw;
    // background-position: top 7.5px left 0px,top 7.5px right 0px,top 7px right 0;
    height: 6vw;
    font-size: 3vw;
    padding: 1vw 11vw 1vw 11vw;
    line-height: 6.5vw;
  }
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 3vw;
  }
`;

const Box = styled.div`
  user-select: none;
  -moz-margin-start: 20vw;
  ${(props) => css`
    width: ${props.width};
    height: ${props.height};
  `}
`;

function Popup(props) {
  let img = "/images/popup_bg.png";
  if (props.bgClass === "image") {
    img = "/images/dailyewards_bg.png";
  }
  if (props.bgClass === "Profile") {
    img = "/images/profile_select_bg.png";
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionWinPopSound());
  }, []);

  return (
    <Box {...props}>
      <PopupModal
        style={{ width: "100%", height: "100%", marginTop: props.marginTop }}
        bgimg={img}
      >
        <Title
          header={props.header}
          size={props.size}
          TitleLeft={props.marginLeft}
          style={{
            fontSize: props.TitleSize,
          }}
        >
          {props.header === "header" ? (
            <>
              <img
                alt=""
                src={"../" + config.theme + "/diamond_shop_title_bg.png"}
                style={{
                  width: "67vw",
                  top: "-4vw",
                  marginLeft:
                    window.appHeight() > window.innerWidth &&
                    window.innerWidth <= 480
                      ? "-4vw"
                      : window.appHeight() > window.innerWidth
                      ? "-14vw"
                      : "-11vw",
                  position: "relative",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top:
                    window.appHeight() > window.innerWidth &&
                    window.innerWidth <= 480
                      ? "-2.5vw"
                      : window.appHeight() > window.innerWidth
                      ? "-1.5vw"
                      : "-2.5vw",
                  left:
                    window.appHeight() > window.innerWidth &&
                    window.innerWidth <= 480
                      ? "0vw"
                      : window.appHeight() > window.innerWidth
                      ? "0.5vw"
                      : "-0.3vw",
                  right: "0vw",
                  fontSize:
                    window.appHeight() > window.innerWidth &&
                    window.innerWidth <= 480
                      ? "2.5vw"
                      : window.appHeight() > window.innerWidth
                      ? "2.5vw"
                      : "1.8vw",
                }}
              >
                {props.title}
              </div>
            </>
          ) : (
            props.title
          )}
        </Title>
        <Content
          style={{
            display: props.contentDisplay,
            justifyContent: props.contentJustify,
            alignItems: props.contentAlignItem,
          }}
        >
          {props.children}
        </Content>

        {props?.DisableCloseButton ? (
          ""
        ) : (
          <Close
            src={"../" + config.theme + "/btn_close.png"}
            style={{
              right: props.closebuttonRight,
              top: props.closebuttonTop,
              display: props.display,
              width: props.closebuttonWidth,
              height: props.closebuttonHeight,
            }}
            onClick={() => {
              props.closeClick();
              dispatch(actionCloseSound());
            }}
          />
        )}
      </PopupModal>
    </Box>
  );
}
export default Popup;
