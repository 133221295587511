import React, { useState, useContext, useEffect } from "react";
import styled, { css } from "styled-components";
import Popup from "../../Popup";
import Pig from "../../NormalDeposit/Pig";
import "../../DecimalDeposit/style.css";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { Transition } from "../../../config";
import { SocketContext } from "../../../context/socket";
import UploadSlip from "../../DepositWithdrawal/UploadSlip";
import breakpoints from "../../../LandingPageComponents/breakpoints";
import { useDispatch } from "react-redux";
import { actionselectedBanklSlice } from "../../../Reducers/getLastBankSlice";
import { config } from "../../../config";
import moment from "moment";
import QRCode from "react-qr-code";
import { actionPopup } from "../../../Reducers/popupSlice";
import Button from "../../Button";

const Box = styled.div`
  user-select: none;
  position: fixed;
  width: 100%;
  height: 100vh; //fix
  align-items: center;
  justify-content: center;
  text-align: -webkit-center;
  display: flex;
  z-index: 50;
  background: rgba(0, 0, 0, 0.7);
  left: 0;
  top: 0;
  @media ${breakpoints.device.line} {
    width: 150vw;
    height: 130%;
    left: -42%;
    top: -6vw;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          flex-direction: column;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          /* left: 15vw; */
        `}
`;

const WarningBox = styled.div`
  position: absolute;
  width: 18%;
  height: 11.5vw;
  left: 66%;
  top: 49%;
  background: #302e3c;
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;

  border-radius: 2vw;
  @media ${breakpoints.device.line} {
    left: 91vw;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          position: relative;
          width: 47vw;
          height: fit-content;
          left: 0vw;
          top: 10vw;
          left: 0 auto;
          top: 0 auto;
          padding-bottom: 1vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 30vw;
          height: 17.5vw;
          left: -44vw;
          top: 38vw;
          position: relative;
          left: 0 auto;
          top: 0 auto;
        `}
`;

const Numberic = styled.div`
  user-select: none;
  font-size: 2.5vw;
  text-align: center;
  font-weight: 400;
  color: white;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-weight: 700;
          font-size: 5.5vw;
          margin-top: 2vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 4.5vw;
        `}
`;

const Recmoney = styled.div`
  user-select: none;
  height: 4vw;
  width: 23vw;
  background-color: #000;
  border-radius: 1vw;
  //   margin-left: 16%;
  position: relative;
  margin-top: 1%;
  margin-right: 1vw;
  color: #fff;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          height: 12vw;
          width: 58%;
          margin-top: 0;
          margin-right: 2.5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          height: 6vw;
          width: 27vw;
        `}
`;

const Money = styled.div`
  user-select: none;
  color: #ffffff;
  font-size: 1.2vw;
  margin-top: 0.5vw;
  position: relative;
  text-align: center;

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin-bottom: 3vw;
          font-size: 3.5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 2vw;
        `}
`;

const Icontimer = styled.img`
  user-select: none;
  width: 2vw;
  height: 1vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 5vw;
          height: 3vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 4vw;
          height: 2vw;
        `}
`;

const RedBtn = styled.div`
  // position: absolute;
  width: 7.5vw;
  height: 3.8vw;
  margin-top: 0.25vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #e13b00 38.02%, #900000 100%);
  box-shadow: inset 0px 0px 4px 2px #ff814d;
  border-radius: 4px;
  cursor: pointer;
  p {
    color: white;
    font-size: 1vw;
    padding: 0.4vw;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 27.5vw;
          height: 12vw;
          border-radius: 2vw;
          p {
            font-size: 3.5vw;
          }
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 12vw;
          height: 6vw;
          p {
            font-size: 1.5vw;
          }
        `}
`;

const ShowAmount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin-bottom: 4vw;
        `
      : window.appHeight() > window.innerWidth && css``}
`;

const Text = styled.div`
  color: white;
  font-size: ${(props) => (props.lang == "th" ? "0.9vw" : "0.8vw")};
  // font-weight: 400;
  width: 80%;
  margin-left: 2.8vw;
  margin-top: -0.4vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2.2vw;
          width: 0 auto;
          margin-left: 0vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 1.5vw;
          width: 88%;
          margin-left: 0vw;
        `}
`;

const ButtonWarning = styled.div`
  cursor: pointer;
  position: absolute;
  width: 68%;
  height: 27%;
  left: 23%;
  top: 68%;
  background: linear-gradient(180deg, #ffc700 38.02%, #ff691f 100%);
  box-shadow: inset 0px 0px 4px 2px #ffc700;
  border-radius: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    color: #731907;
    font-size: 1.3vw;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          left: 0vw;
          top: 0vw;
          position: relative;
          height: 6vw;
          p {
            font-size: 2vw;
          }
        `
      : window.appHeight() > window.innerWidth &&
        css`
          left: 17%;
          p {
            font-size: 1.5vw;
          }
        `}
`;

const Imgwarning = styled.img`
  width: 42%;
  margin-top: -20%;
  margin-left: 11%;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 13vw;
          margin-top: -20%;
          margin-left: 1vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 10vw;
          margin-left: 1vw;
        `}
`;

const ContentText = styled.div`
  font-size: 1.2vw;
  color: #fff;
  margin-top: 3%;
  white-space: pre-line;

  @media only screen and (max-width: 480px) and (orientation: portrait) {
    font-size: 3vw;
  }
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 1.8vw;
  }
`;

const QRContainer = styled.div`
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3%;
`;

const QRWhiteBox = styled.div`
  width: 42%;
  background: #fff;
  border-radius: 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentTextRed = styled.div`
  font-size: 1.2vw;
  color: #ff7777;
  margin-top: 3%;

  @media only screen and (max-width: 480px) and (orientation: portrait) {
    font-size: 3vw;
  }
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 1.8vw;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  position: relative;
  bottom: -3%;
  display: flex;
  justify-content: center;
`;

function QRPopup(props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [popupUploadSlip, setPopupUploadSlip] = useState(false);
  const [countDown, setCountDown] = useState({ min: 5, sec: 0 });

  // ** props
  const { bankInfo, closeClick, qrData } = props;

  let fnCountDown;
  useEffect(() => {
    let time = 300;
    if (typeof time != "undefined") {
      fnCountDown = setInterval(() => {
        if (time <= 0) {
          time = 0;
          clearInterval(fnCountDown);
        }
        let min = Math.floor(time / 60);
        let sec = Math.floor(time % 60);
        setCountDown({
          min: min,
          sec: sec,
        });
        time--;
      }, 1000);
    }
  }, []);

  return (
    <>
      <Box>
        <Popup
          width={
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "90vw"
              : window.appHeight() > window.innerWidth
              ? "55vw"
              : window.innerWidth / window.appHeight() > 2.15
              ? "38vw"
              : "38vw"
          }
          height={
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "108vw"
              : window.appHeight() > window.innerWidth
              ? "55vw"
              : window.innerWidth / window.appHeight() > 2.15
              ? "40vw"
              : "40vw"
          }
          title={t("QR Promptpay")}
          closebuttonRight="-2vw"
          closebuttonTop="-2vw"
          closeClick={() => {
            dispatch(actionPopup({ deposit: false }));
          }}
          DisableCloseButton
        >
          {/* <Pig
            top="-3vw"
            img={"../" + config.theme + "/icon_decimal_deposit.png"}
          /> */}
          <Money>
            {t("QR Timelimit")}
            <Icontimer src={"../" + config.theme + "/icon_timer.png"} />

            <font color="#FF7777">
              {countDown.sec < 10
                ? "0" + countDown.min + ":0" + countDown.sec
                : "0" + countDown.min + ":" + countDown.sec}
              &nbsp;{t("min")}
            </font>
          </Money>

          <ShowAmount>
            <Recmoney>
              <Numberic>
                <NumberFormat
                  value={qrData.appAnt}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </Numberic>
            </Recmoney>

            <RedBtn onClick={closeClick}>
              <p>{t("cancelDecimalPopup")}</p>
            </RedBtn>
          </ShowAmount>
          <ContentText>{t("QR Scan")}</ContentText>
          <QRContainer>
            <QRWhiteBox>
              <QRCode
                style={{ height: "90%", width: "90%", padding: "1vw" }}
                value={qrData.qr ?? ""}
              />
            </QRWhiteBox>
          </QRContainer>
          <ContentTextRed>{t("QR Account")}</ContentTextRed>
          <ButtonContainer>
            <Button
              label={t("QR Done")}
              bClass="rbGreen"
              width={
                window.appHeight() / window.innerWidth >
                config.verticalSizeiphone
                  ? "40.44vw"
                  : window.appHeight() > window.innerWidth
                  ? "21.44vw"
                  : window.innerWidth / window.appHeight() > 2.15
                  ? "14.44vw"
                  : "14.44vw"
              }
              fontSize={
                window.appHeight() / window.innerWidth >
                config.verticalSizeiphone
                  ? "4vw"
                  : window.appHeight() > window.innerWidth
                  ? "2vw"
                  : window.innerWidth / window.appHeight() > 2.15
                  ? "1.5vw"
                  : "1.5vw"
              }
              onClick={() => {
                dispatch(actionPopup({ deposit: false }));
              }}
            />
          </ButtonContainer>
        </Popup>

        {bankInfo.qrCodeStatus ? (
          <WarningBox>
            <Imgwarning src={"../" + config.theme + "/warning_icon.png"} />
            <Text lang={i18n.language}>{t("incase bank error")}</Text>
            <ButtonWarning
              onClick={() => {
                dispatch(actionselectedBanklSlice("floating"));
                setPopupUploadSlip(true);
              }}
            >
              <p>{t("Upload slip here")}</p>
            </ButtonWarning>
          </WarningBox>
        ) : (
          ""
        )}

        <Transition showPop={popupUploadSlip} type="alert">
          <UploadSlip closeClick={closeClick} />
        </Transition>
      </Box>
    </>
  );
}

export default QRPopup;
