import { createSlice } from "@reduxjs/toolkit";
export const providerSlice = createSlice({
  name: "provider",
  initialState: {
    providers: [],
    isProvider: true,
    setProvider: "",
  },
  reducers: {
    actionProvider: (state, action) => {
      state.providers = action.payload;
    },
    actionIsProvider: (state, action) => {
      state.isProvider = action.payload;
    },
    actionSetProvider: (state, action) => {
      state.setProvider = action.payload;
    },
  },
});

export const { actionProvider, actionIsProvider, actionSetProvider } =
  providerSlice.actions;
export const selectProvider = (state) => state.provider.providers;
export const selectIsProvider = (state) => state.provider.isProvider;
export const selectSetProvider = (state) => state.provider.setProvider;
export default providerSlice.reducer;
