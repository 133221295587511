import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { config } from "../../../config";
import Button from "../../Button";

const BG = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 30;
  align-items: center;
  justify-content: center;
`;

const Popup = styled.div`
  user-select: none;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position-x: center;
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;
  border-radius: 2vw;
  z-index: 50;
  text-align: center;
  justify-content: center;
  margin: auto;
  top: 0;
  width: 50vw;
  height: calc(100vw * 0.4363);
  height: fit-content; //fix
  position: relative;
  @media only screen and (max-width: 480px) and (orientation: portrait) {
    width: 95vw;
  }
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    width: 60vw;
  }
`;

const Title = styled.div`
  user-select: none;
  width: ${(props) => (props.header === "header" ? `null` : `max-content`)};
  margin: -0.2vw auto;
  color: white;
  font-size: 2vw;
  z-index: 0;
  font-weight: 400;
  text-align: center;
  line-height: 5vw;
  padding: 0 9vw;
  background-clip: border-box, border-box, content-box;
  background-size: 8vw, 8vw, 0.98vw;
  background-position: top 0 left 4vw, top 0 right 4vw, top -0vw right 0;
  background-repeat: no-repeat, no-repeat, repeat-x;
  background-image: url("../${config.theme}/popup_title_bg_left.png"),
    url("../${config.theme}/popup_title_bg_right.png"),
    url("../${config.theme}/popup_title_bg_center.png");

  //Portrait
  @media only screen and (max-width: 480px) and (orientation: portrait) {
    transform: scale(1.5);
    background-position: top 1.2vw left 4vw, top 1.2vw right 4vw,
      top 1.2vw right 0;
    background-size: 8.1vw, 8.1vw, 0.99vw;
    height: 6vw;
    font-size: 3vw;
    padding: 1vw 11vw 1vw 11vw;
    line-height: 6.5vw;
  }
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 3vw;
  }
`;

const Content = styled.div`
  width: 90%;
  font-size: 1.5vw;
  color: #fff;
  padding: 5% 5% 8% 5%;
  white-space: pre-line;
  span {
    color: #ff7777;
  }

  @media only screen and (max-width: 480px) and (orientation: portrait) {
    font-size: 4vw;
  }
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 2vw;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: -12%;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 480px) and (orientation: portrait) {
    bottom: -20%;
  }
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    bottom: -16%;
  }
`;

export default function Fyi(props) {
  const { t } = useTranslation();

  // ** props
  const { closeClick } = props;

  return (
    <BG>
      <Popup>
        <Title>{t("QRFYI Title")}</Title>
        <Content dangerouslySetInnerHTML={{ __html: t("QRFTI Detail") }} />
        <ButtonContainer>
          <Button
            label={t("ok")}
            bClass="rbGreen"
            width={
              window.appHeight() / window.innerWidth > config.verticalSizeiphone
                ? "40.44vw"
                : window.appHeight() > window.innerWidth
                ? "21.44vw"
                : window.innerWidth / window.appHeight() > 2.15
                ? "14.44vw"
                : "14.44vw"
            }
            fontSize={
              window.appHeight() / window.innerWidth > config.verticalSizeiphone
                ? "4vw"
                : window.appHeight() > window.innerWidth
                ? "2vw"
                : window.innerWidth / window.appHeight() > 2.15
                ? "1.8vw"
                : "1.8vw"
            }
            onClick={closeClick}
          />
        </ButtonContainer>
      </Popup>
    </BG>
  );
}
