import React, { useState, useRef } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { config, Transition } from "../../config";
import AlertMessage from "../Dialog/AlertMessage";
import Confirm from "../Dialog/Confirm";
import { redirectToLogin } from "../../config";
// import { detect } from "detect-browser";
// import { selectCheckFTUEPopup } from "../../Reducers/checkFTUEPopupSlice";
// import { SocketContext } from "../../context/socket";
import Button from "../Button";
// import Popup from "../Popup";

const BG = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 70;
  align-items: center;
  justify-content: center;
`;

const Popup = styled.div`
  user-select: none;
  // background-image: url("/images/popup_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;
  // theme ==================
  /* background: #192756;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #5DD8FF, inset 0px 0px 4vw rgba(66, 255, 232, 0.63);
  border: solid 0.2vw #BDEFFF; */
  // theme ==================
  border-radius: 1.5vw;
  position: relative;
  z-index: 20;
  text-align: -webkit-center;
  align-items: center;
  justify-content: center;

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          border-radius: 3.5vw;
        `
      : window.appHeight() > window.innerWidth && css``}
`;



//
const Title = styled.div`
  user-select: none;
  top: 0px;
  width: max-content;
  display: flex;
  margin-top: -0.2vw;
  color: white;
  height: 5.54vw;
  font-size: 2vw;
  line-height: 4vw;
  z-index: 0;
  font-weight: 400;
  text-align: center;
  padding: 0 5vw;
  background-clip: border-box, border-box, content-box;
  background-size: 6.69vw, 6.69vw, 0.81vw;
  background-position: top 0 left 19px, top 0 right 17px, top 0 right 0;
  background-repeat: no-repeat, no-repeat, repeat-x;
  background-image: url("../${config.theme}/popup_title_bg_left.png"),
    url("../${config.theme}/popup_title_bg_right.png"),
    url("../${config.theme}/popup_title_bg_center.png");

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          height: 8.54vw;
          background-size: 10.69vw, 10.69vw, 1.28vw;
          padding: 0 7vw;
          background-position: top 0 left 8px, top 0 right 8px, top 0 right 0;
          font-size: 5vw;
          line-height: 6.4vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          height: 7.54vw;
          background-size: 8.69vw, 8.69vw, 1.06vw;
          padding: 0 7vw;
          line-height: 5.5vw;
          /* margin-left: 8vw; */
        `}
`;
//

const ShowText = styled.div`
  width: 32vw;
  height: 3.5vw;
  /* margin-left: 1.5vw;
  margin-top: -3vw; */
  margin: -3vw 0 0 1.5vw;
  color: #fff;
  background-color: #000000;
  border: none;
  border-radius: 0.5vw;
  outline: transparent;
  display: flex;
  /* margin-bottom: 1.5vw; */
  /* margin-top: 7.4vw; */
  align-items: center;
  position: relative;
  font-size: 1vw;
  text-align: left;

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 71vw;
          height: 11.5vw;
          border-radius: 3vw;
          margin-top: -2vw;
          margin-bottom: 3.5vw;
          margin: -2vw 0 3.5vw 1.5vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          width: 45vw;
          height: 6.5vw;
          border-radius: 1vw;
          /* margin-top: -2vw; */
          margin: -2vw 0 0 1.5vw;
        `}//
`;
const Inputuser = styled.input`
  left: 1vw;
  font-size: 1vw;
  position: relative;
  color: #ffffff;
  opacity: 0.5;
  border: none;
  user-select: none;
  background-color: transparent;
  :focus-visible {
    outline: none;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 5vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          font-size: 2.5vw;
        `}
`;

const Text = styled.div`
  color: #fff;
  font-size: 1.3vw;
  /* margin-left: 1.5vw; */
  margin: 0 0 0 1.5vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 5vw;
          /* margin-bottom: 1.5vw; */
          margin: 0 0 1.5vw 1.5vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          font-size: 2vw;
        `}
`;
const Box = styled.div`
  position: relative;
  top: 1vw;
  text-align: start;

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin: 0 auto;
          width: 90%;
        `
      : window.appHeight() > window.innerWidth && css``}
`;



const CopyUser = styled.div`
  background-color: #ff691f;
  user-select: none;
  width: 5.5vw;
  height: 3.2vw;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  z-index: 2;
  top: 0.35vw;
  left: 27.8vw;
  margin-top: 0.3vw;
  border-radius: 0.5vw;

  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  p {
    font-size: 1vw;
    color: white;
    /* padding-top: 1vw; */
    width: 5vw;
    display: flex;
    justify-content: center;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 16.5vw;
          height: 9.5vw;
          top: 8.5vw;
          left: 54.3vw;
          margin-top: -7.823vw;
          border-radius: 1.5vw;
          p {
            width: 12vw;
            font-size: 3.5vw;
          }
        `
      : window.appHeight() > window.innerWidth &&
      css`
          width: 9.5vw;
          height: 6.5vw;
          top: 4.9vw;
          left: 36.5vw;
          margin-top: -4.823vw;
          border-radius: 1.5vw;
          p {
            font-size: 1.5vw;
            margin-left: 3.5vw;
            padding-top: 1.5vw;
            width: 8vw;
          }
        `}
`;

const CopyPass = styled.div`
  background-color: #ff691f;
  user-select: none;
  width: 5.5vw;
  height: 3.2vw;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  z-index: 2;
  top: 0.35vw;
  left: 27.8vw;
  margin-top: 0.3vw;
  border-radius: 0.5vw;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  p {
    font-size: 1vw;
    color: white;
    /* padding-top: 1vw; */
    width: 5vw;
    display: flex;
    justify-content: center;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 16.5vw;
          height: 9.5vw;
          top: 8.5vw;
          left: 54.3vw;
          margin-top: -7.823vw;
          border-radius: 1.5vw;
          p {
            width: 12vw;
            font-size: 3.5vw;
          }
        `
      : window.appHeight() > window.innerWidth &&
      css`
          width: 9.5vw;
          height: 6.5vw;
          top: 4.9vw;
          left: 36.5vw;
          margin-top: -3.823vw;
          p {
            font-size: 1.5vw;
            margin-left: 3.5vw;
            padding-top: 1.5vw;
            width: 8vw;
          }
        `}
`;

const CopyPin = styled.div`
  background-color: #ff691f;
  user-select: none;
  width: 5.5vw;
  height: 3.2vw;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  z-index: 2;
  top: 0.35vw;
  left: 27.8vw;
  margin-top: 0.3vw;
  border-radius: 0.5vw;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  p {
    font-size: 1vw;
    color: white;
    /* padding-top: 1vw; */
    width: 5vw;
    display: flex;
    justify-content: center;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 16.5vw;
          height: 9.5vw;
          top: 8.5vw;
          left: 54.3vw;
          margin-top: -7.823vw;
          border-radius: 1.5vw;
          p {
            width: 12vw;
            font-size: 3.5vw;
          }
        `
      : window.appHeight() > window.innerWidth &&
      css`
          width: 9.5vw;
          height: 6.5vw;
          top: 4.9vw;
          left: 36.5vw;
          margin-top: -3.823vw;
          p {
            font-size: 1.5vw;
            margin-left: 3.5vw;
            padding-top: 1.5vw;
            width: 8vw;
          }
        `}
`;

const HrLine = styled.hr`
  opacity: 0.1;
  border: 1px solid #ffffff;
  margin: 1.5vw 0;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin: 5vw 1vw;
        `
      : window.innerWidth / window.appHeight() > 2.15
        ? css`
          margin: 0.5vw;
        `
        : window.appHeight() > window.innerWidth && css``}
`;

const RemarkContainer = styled.p`
  color: #ff7979;
  font-size: 1vw;
  width: 90%;
  text-align: left;
  margin: 0 auto;

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 3vw;
        `
      : window.appHeight() > window.innerWidth &&
      `
        font-size: 2vw;

  `}
`;

const ButtonClose = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  top: 2.5vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          display: flex;
          justify-content: center;
          position: relative;
          top: 5vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          display: flex;
          justify-content: center;
          position: relative;
          top: 5vw;
        `}
`;

function ConfirmationRegister(props) {
  const { t, i18n } = useTranslation();
  // const dispatch = useDispatch();
  // const socket = useContext(SocketContext);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [toConfirm, setToConfirm] = useState(false);
  // const [alert, setAlert] = useState(false);
  // const browser = detect();
  const textAreaRef = useRef(null);
  const [alertCopy, setAlertCopy] = useState(false);
  // const checkFTUEPopup = useSelector(selectCheckFTUEPopup);
  const [uiHeight, setUiHeight] = useState(window.appHeight());

  // const handleResize = () => {
  //   setUiHeight(window.appHeight());
  //   // alert("Height Change to " + window.appHeight());
  // };

  function copyToClipboard(e) {
    // textAreaRef.current.select();
    var copyText;
    if (e === "username") {
      copyText = document.querySelector("#user");
    } else if (e === "pass") {
      copyText = document.querySelector("#password");
    } else if (e === "tel") {
      copyText = document.querySelector("#tel");
    } else if (e === "pin") {
      copyText = document.querySelector("#pin");
    }

    copyText.select();
    document.execCommand("copy");
    // e.target.focus();
    setAlertCopy(true);
    setTimeout(() => {
      setAlertCopy(false);
    }, 2000);
  }

  return (
    // browser.name == "ios-webview" ||
    // browser.name == "chromium-webview"
    <BG>
      <Popup
        style={{
          width:
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "80vw"
              : window.appHeight() > window.innerWidth
                ? "48vw"
                : window.innerWidth / window.appHeight() > 2.15
                  ? "35vw"
                  : "35vw",
          height: `${uiHeight} + "px"`,
          // height:
          //   window.appHeight() / window.innerWidth > config.verticalSizeiphone
          //     ? `${uiHeight} + "px"`
          //     : window.appHeight() > window.innerWidth
          //     ? "54vw"
          //     : window.innerWidth / window.appHeight() > 2.15
          //     ? "32.5vw"
          //     : "32.5vw",
        }}
      >
        {/* <CloseBtn
          src={"../" + config.theme + "/btn_close.png"}
          closeClick={props.closeClick}
          onClick={() => {
            // props.closeClick();
            // dispatch(actionCloseSound());
            setConfirmPopup(true);
          }}
        /> */}

        <Title lang={i18n.language}>{t("informationRegister")}</Title>
        <Box>
          <Text>{t("user register")}</Text>

          <CopyUser
            onClick={() => {
              copyToClipboard("username");
            }}
            // onClick={copyToClipboard}
            {...props}
          >
            <p>{t("copy")}</p>
          </CopyUser>
          <ShowText>
            <Inputuser
              value={props.username}
              ref={textAreaRef}
              readOnly
              id="user"
            />
            {/* <p>{props.username}</p> */}
          </ShowText>

          <Text>{t("password")}</Text>
          <CopyPass
            onClick={() => {
              copyToClipboard("pass");
            }}
            {...props}
          >
            <p>{t("copy")}</p>
          </CopyPass>
          <ShowText>
            <Inputuser
              value={props.password}
              ref={textAreaRef}
              readOnly
              id="password"
            />
            {/* <p>{props.password}</p> */}
          </ShowText>

          <Text>{t("mobile number")}</Text>
          <CopyPin
            onClick={() => {
              copyToClipboard("tel");
            }}
            {...props}
          >
            <p>{t("copy")}</p>
          </CopyPin>
          <ShowText>
            <Inputuser value={props.tel} ref={textAreaRef} readOnly id="tel" />
            {/* <p>{props.pin}</p> */}
          </ShowText>

          <Text>{t("Pin")}</Text>
          <CopyPin
            onClick={() => {
              copyToClipboard("pin");
            }}
            {...props}
          >
            <p>{t("copy")}</p>
          </CopyPin>
          <ShowText>
            <Inputuser value={props.pin} ref={textAreaRef} readOnly id="pin" />
            {/* <p>{props.pin}</p> */}
          </ShowText>

          <HrLine />
          <RemarkContainer>{t("remark data register")}</RemarkContainer>

          {/* <WarnningError>
            <Warnning src={"../" + config.theme + "/warning_icon.png"} />
            <Hr />
            <TextBox>
              <TextWarnnig>
                {t(
                  "Please note the register information before close this window"
                )}
              </TextWarnnig>
            </TextBox>
          </WarnningError> */}
        </Box>
        {/* {
                    browser.name == "ios" ||
                        browser.name == "ios-webview" ||
                        browser.name == "chromium-webview"
                        ?
                        "" :
                        <ContainerButton>
                            <Button
                                label={t("บันทึกรูปภาพ")}
                                bClass="rbGreen"
                                width="14.44vw"
                                fontSize="1.4vw"
                                onClick={() => {
                                    window.location = config.socketEndpoint + "img?username=" + props.username + "&pincode=" + props.pin + "&password=" + props.password
                                    setTimeout(() => {
                                        setAlert(true)
                                        setTimeout(() => {
                                            setAlert(false)
                                        }, 2000)
                                    }, 1000)
                                }}
                            // top="27.5%"

                            />

                        </ContainerButton>

                } */}
        <ButtonClose>
          <Button
            onClick={() => {
              // props.closeClick();
              // dispatch(actionCloseSound());
              setConfirmPopup(true);
            }}
            bClass="rbGreen"
            label={t("login")}
            width={
              window.appHeight() / window.innerWidth > config.verticalSizeiphone
                ? "33vw"
                : window.appHeight() > window.innerWidth
                  ? "19vw"
                  : window.innerWidth / window.appHeight() > 2.15
                    ? "13vw"
                    : "13vw"
            }
            fontSize={
              window.appHeight() / window.innerWidth > config.verticalSizeiphone
                ? "3.5vw"
                : window.appHeight() > window.innerWidth
                  ? "1.7vw"
                  : window.innerWidth / window.appHeight() > 2.15
                    ? "1.15vw"
                    : "1.15vw"
            }
          />
        </ButtonClose>
      </Popup>

      <Transition showPop={confirmPopup} type="alert">
        <Confirm
          children={t("Confirm to close the information window?")}
          message={t("Unable to reach this information again later")}
          cancelClick={() => {
            setConfirmPopup(false);
            // setCancelPop(false)
          }}
          confirmClick={() => {
            const redirect = () => {
              setConfirmPopup(false);
              setToConfirm(true);
              setTimeout(() => {
                redirectToLogin(props.jwtToken);
                setToConfirm(false);
              }, 2000);
            };
            // if (checkFTUEPopup == 1) {
            //   socket.emit("ftueRegister", {}, (body) => {
            //     // console.log("data", body.results.data);
            //     // console.log(
            //     //   "data.ftueRegister",
            //     //   body.results.data.ftueRegister
            //     // );
            //     redirect();
            //   });
            // } else {
            redirect();
            // }
            // props.closeClick()
          }}
        />
      </Transition>

      <Transition showPop={toConfirm} type="alert">
        <BG style={{ justifyContent: "center" }}>
          <AlertMessage message={t("bank account registration successfully")} />
        </BG>
      </Transition>

      {/* <Transition showPop={alert} type="alert">
        <BG style={{ justifyContent: "center" }}>
          <AlertMessage message={t("The picture has been saved")} />
        </BG>
      </Transition> */}

      <Transition showPop={alertCopy} type="alert">
        <BG style={{ justifyContent: "center" }}>
          <AlertMessage message={t("alert message copy")} />
        </BG>
      </Transition>
    </BG>
  );
}
export default ConfirmationRegister;
