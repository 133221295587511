import React from "react";
import styled from "styled-components";
import breakpoint from "../breakpoints";
import { useTranslation } from "react-i18next";
import ProvidersItem from "./ProvidersItem";

const ProvidersBG = styled.div`
  background-image: url("../../lpimages/bg_content2.png");
  width: 100%;
  background-size: 102%;
  background-position-x: center;
  background-repeat: no-repeat;
  padding-bottom: 3vw;
  background-color: #000;
  @media ${breakpoint.device.mobile} {
    background-size: 130%;
    height: 100%;
    text-align: start;
    padding-bottom: 10vw;
  }
`;

const Title = styled.div`
  color: white;
  font-size: 3vw;
  padding-top: 1vw;
  margin-left: -54vw;
  white-space: pre-wrap;
  display: flex;
  align-items: center;
  place-content: center;
  @media ${breakpoint.device.mobile} {
    width: 100%;
    font-size: 6.5vw;
    margin-top: 5vw;
    display: inline-block;
    /* width: 100%; */
    font-size: 6.5vw;
    margin-top: 5vw;
    margin-bottom: 5vw;
    padding-left: 3.5vw;
    margin: 0 auto;
    display: inline-block;
  }
`;
const Image = styled.img`
  width: 2.5vw;
  margin-top: 0.1vw;
  padding-right: 0.5vw;
  @media ${breakpoint.device.mobile} {
    /* width: 5.5vw;
    margin-top: 0.1vw;
    padding-right: 0.5vw; */
    width: 5.5vw;
    margin-top: 0.1vw;
    padding-right: 1.5vw;
  }
`;


const Box = styled.div`
  width: 41vw;
  padding: 1vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  // justify-content: space-between;
  @media ${breakpoint.device.ipad} {
    width: 55vw;
    padding: 1vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  @media ${breakpoint.device.mobile} {
    width: 98vw;
    justify-content: center;
    padding: 1vw;
  }
`;

const Detail = styled.div`
  position: relative;
  text-align: -webkit-center;

  @media ${breakpoint.device.mobile} {
    position: relative;
    text-align: -webkit-center;
  }
`;

function Providers() {
  const { t } = useTranslation();

  return (
    <ProvidersBG>
      <Title>
        <Image src="../../lpimages/bullet.png" />
        {t("providers")}

        {/* <DesText>
          <p>{t("providers description")}</p>
        </DesText> */}
      </Title>
      <Detail>
        <Box>
          <ProvidersItem img="xgaming" />
          <ProvidersItem img="PGslot" />
          <ProvidersItem img="ambpoker_logo" />
          <ProvidersItem img="jokergame" />
          <ProvidersItem img="evoplay" />
          <ProvidersItem img="playstar" />
          <ProvidersItem img="jili" />
          <ProvidersItem img="redtiger" />
          <ProvidersItem img="ygg" />
          <ProvidersItem img="Sa-gaming" />
          <ProvidersItem img="Sexy-gaming" />
          {/* <ProvidersItem img="dreamgaming" /> */}
          <ProvidersItem img="Pragmatic" />
          <ProvidersItem img="allbet" />
          <ProvidersItem img="dreamgaming" />
          <ProvidersItem img="Pretty-Gaming" />
        </Box>
      </Detail>
    </ProvidersBG>
  );
}
export default Providers;
