import styled, { css } from "styled-components";
import { useState, lazy, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectuserProfile,
  actionClickSound,
} from "../../../Reducers/userProfileSlice";
import CurrencyItem from "./CurrencyItem";
import { Transition, config, redirect2Page } from "../../../config";
// import { rubberBand } from "react-animations";
import { useTranslation } from "react-i18next";
import { SocketContext } from "../../../context/socket";
import AlertMessage from "../../Dialog/AlertMessage";
import { actionPopup, selectPopup } from "../../../Reducers/popupSlice";
import { actionSpinixShopMenu } from "../../../Reducers/spinixShopMenuSlice";

//import React, { useState, lazy} from 'react';

const DepositWithdrawal = lazy(() => import("../../DepositWithdrawal/Deposit"));
const DiamondExchange = lazy(() => import("../../DiamondExchange"));

const BankAccount = lazy(() => import("../../Register/BankAccount"));
const GuestBankAccount = lazy(() => import("../../GuestBankPopup"));

const CurrencyBox = styled.div`
  user-select: none;
  align-self: center;
  display: flex;
  flex-direction: row;
  display: grid;
  margin-left: 17vw;
  margin-top: 0.7vw;

  @media (orientation: portrait) {
    margin-left: -2vw;
    margin-top: 1.7vw;
  }

  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    margin-left: 7vw;
    margin-top: 0.7vw;
  }

  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin-left: -2vw;
          margin-top: 1.7vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          margin-left: 7vw;
          margin-top: 0.7vw;
        `} */
`;

export default function Currency(props) {
  const dispatch = useDispatch();
  const profile = useSelector(selectuserProfile);
  const popupStatus = useSelector(selectPopup);
  const [deposit, setDeposit] = useState(false);
  // const [diamondExchange, setDiamondExchange] = useState(false);
  const { t } = useTranslation();
  const socket = useContext(SocketContext);
  const [emitError, setEmitError] = useState({ status: false, message: "" });

  let guestuser = useSelector(selectuserProfile);
  const [popup, setPopup] = useState({
    depositStatus: false,
    guestTelPopup: false,
    bankAccountStatus: false,
    guestBankPopup: false,
  });

  const [bankAccount, setBankAccount] = useState(false);
  // const [guestToBankAccount, setGuestToBankAccount] = useState(false);

  return (
    <>
      <CurrencyBox>
        {guestuser.isTelGuest === true && guestuser.isguest === false ? (
          <>
            <CurrencyItem
              icon="icon_coin.png"
              amount={profile.balance}
              onClick={() => {
                try {
                  socket.emit(
                    "getbilog",
                    {
                      biname: "deposit_open",
                      action_from: "Credit",
                    },
                    (data) => {
                      console.log(data);
                    }
                  );
                } catch (error) {
                  console.log(error);
                }
                setDeposit(true);
                // dispatch(actionPopup({ depositWithdrawal: true }));
                dispatch(actionClickSound());
              }}
              isCoin={true}
            />
          </>
        ) : guestuser.isTelGuest === false && guestuser.isguest === true ? (
          <>
            <CurrencyItem
              icon="icon_coin.png"
              amount={profile.balance}
              onClick={() => {
                // setDeposit(true)
                dispatch(actionPopup({ guestDetect: true }));
                // setGuestDetect(true);
                // dispatch(actionPopup({ depositWithdrawal: true }));
                dispatch(actionClickSound());
              }}
              isCoin={true}
            />
          </>
        ) : guestuser.isTelGuest === true && guestuser.isguest === true ? (
          <>
            <CurrencyItem
              icon="icon_coin.png"
              amount={profile.balance}
              onClick={() => {
                // setDeposit(true)
                // setGuestDetect(true);
                // setBankAccount(true);
                redirect2Page("createbankaccount");
                // dispatch(actionPopup({ depositWithdrawal: true }));
                dispatch(actionClickSound());
              }}
              isCoin={true}
            />
          </>
        ) : (
          <>
            <CurrencyItem
              icon="icon_coin.png"
              amount={profile.balance}
              isCoin={true}
            />
          </>
        )}

        <CurrencyItem
          icon="icon_diamond.png"
          amount={profile.diamond}
          onClick={() => {
            try {
              socket.emit(
                "getbilog",
                {
                  biname: "shop_open",
                  open_from: "Diamond icon",
                },
                (data) => {
                  console.log(data);
                }
              );
            } catch (error) {
              console.log(error);
            }
            // setDiamondExchange(true)
            if (profile.isguest && profile.isTelGuest === false) {
              dispatch(actionPopup({ guestDetect: true }));
            } else {
              dispatch(actionClickSound());
              if (popupStatus.diamondShop === false) {
                dispatch(actionPopup({ diamondExchange: true }));
              }
            }
          }}
        ></CurrencyItem>
      </CurrencyBox>

      {/* <Transition showPop={popupStatus.depositWithdrawal} type="st2b">
                <DepositWithdrawal closeClick={() => {
                    dispatch(actionPopup({ depositWithdrawal: false }))
                    // setDeposit(false)
                }} />
            </Transition> */}
      <Transition showPop={popupStatus.diamondExchange} type="st2b">
        <DiamondExchange
          closeClick={() => {
            dispatch(actionPopup({ diamondExchange: false }));
            dispatch(
              actionSpinixShopMenu({
                freeSpin: false,
                credit: false,
                deposit: false,
                diamonds: false,
              })
            );
            // setDiamondExchange(false)
          }}
        />
      </Transition>
      <Transition showPop={emitError.status} type="alert">
        <AlertMessage
          message={t(emitError.message)}
          onClick={() => {
            setEmitError({ status: false });
          }}
        />
      </Transition>

      <Transition
        // showPop={deposit || popupStatus.depositStatus}
        showPop={deposit || popupStatus.depositWithdrawal}
        type="st2b"
      >
        <DepositWithdrawal
          closeClick={() => {
            setDeposit(false);
            // dispatch(actionPopup({ depositStatus: false }));
            dispatch(actionPopup({ depositWithdrawal: false }));
          }}
        />
      </Transition>

      <Transition
        showPop={bankAccount || popupStatus.bankAccountStatus}
        type="st2b"
      >
        <BankAccount
          closeClick={() => {
            setBankAccount(false);
            dispatch(actionPopup({ bankAccountStatus: false }));
          }}
        />
      </Transition>

      <Transition showPop={popup.guestBankPopup} type="alert">
        <GuestBankAccount
          cancelClick={() => {
            setPopup({ ...popup, guestBankPopup: false });
          }}
          // confirmClick={() => {
          //   setGuestToBankAccount(false);
          // }}
        >
          {t("guest alert invitebankaccount")}
        </GuestBankAccount>
      </Transition>

      {/* <Transition showPop={guestDetect || popupStatus.guestDetect} type="st2b">
        <GuestDetect
          closeClick={() => {
            dispatch(actionPopup({ guestDetect: false }));
          }}
        />
      </Transition> */}
    </>
  );
}
