import React from "react";
import styled, { css } from "styled-components";
import Button from "./Button";
import { useTranslation } from "react-i18next";
// import { useDispatch } from "react-redux";
// import { actionPopup } from "../Reducers/popupSlice";
import { config, redirect2Page } from "../config";
const Popup = styled.div`
  user-select: none;
  /* margin-top: 20vw; */
  display: flex;
  flex-direction: column;
  width: 43vw;
  // theme ==================
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;
  border-radius: 2vw;
  padding: 2.5vw;
  // theme ==================
  // background-color: #2f2d3b;
  // border-radius: 4vw;
  // border: solid 3vw;
  // border-image-slice: 50;
  // border-image-source: url('../${config.theme}/popup_frame_mini.png');
  justify-content: center;
  text-align: center;
  align-items: center;
  color: #bcb8d0;
  font-size: 2vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 70vw;
          padding: 4vw 5vw 4vw 5vw;
          border-radius: 2vw;
          border-image-slice: 40;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          width: 54vw;
          padding: 3vw;
        `}
`;

const ButConfirm = styled.div`
  user-select: none;
  position: relative;
  z-index: 70;
  display: flex;
  margin-top: -2vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin-top: -3vw;
        `
      : window.appHeight() > window.innerWidth && css``}
`;

const Box = styled.div`
  user-select: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  text-align: -webkit-center;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  z-index: 50;
  align-items: center;
  justify-content: center;
  p {
    color: #bdb9d1;
    text-align: center;
    font-size: 1.5vw;
    width: 100%;
    line-height: 1.4;
    -webkit-text-size-adjust: 100%;
  }
  h5 {
    font-size: 2.5vw;
    color: white;
    margin: 0;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          p {
            font-size: 3vw;
            line-height: 1.5;
          }
        `
      : window.appHeight() > window.innerWidth &&
      css`
          p {
            font-size: 2.2vw;
            line-height: 1.5;
          }
        `}
`;

function GuestBankPopup(props) {
  const { t } = useTranslation();
  // const dispatch = useDispatch();

  return (
    <Box>
      <Popup style={{ height: props.height }} {...props}>
        <p>{props.children}</p>
      </Popup>
      <ButConfirm>
        <Button
          bClass="rbRed"
          label={t("not yet")}
          width={
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "27vw"
              : window.appHeight() > window.innerWidth
                ? "16vw"
                : window.innerWidth / window.appHeight() > 2.15
                  ? "13vw"
                  : "13vw"
          }
          fontSize={
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "2.5vw"
              : window.appHeight() > window.innerWidth
                ? "1.7vw"
                : window.innerWidth / window.appHeight() > 2.15
                  ? "1.15vw"
                  : "1.15vw"
          }
          onClick={props.cancelClick}
          style={{ padding: 0 }}
        />
        <Button
          label={t("register now")}
          width={
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "27vw"
              : window.appHeight() > window.innerWidth
                ? "16vw"
                : window.innerWidth / window.appHeight() > 2.15
                  ? "13vw"
                  : "13vw"
          }
          fontSize={
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "2.5vw"
              : window.appHeight() > window.innerWidth
                ? "1.7vw"
                : window.innerWidth / window.appHeight() > 2.15
                  ? "1.15vw"
                  : "1.15vw"
          }
          style={{ padding: 0 }}
          onClick={() => {
            props.confirmClick();
            redirect2Page("createbankaccount");
            // dispatch(actionPopup({ registBankAccount: true }));
          }}
        />
      </ButConfirm>
    </Box>
  );
}
export default GuestBankPopup;
