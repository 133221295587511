import { createSlice } from "@reduxjs/toolkit";
export const gameDemoSlice = createSlice({
  name: "gameDemo",
  initialState: {
    gameItems: [],
    casinoItems: [],
  },
  reducers: {
    actionGameDemo: (state, action) => {
      state.gameItems = action.payload;
    },
    actionCasinoItems: (state, action) => {
      state.casinoItems = action.payload;
    },
  },
});

export const { actionGameDemo, actionCasinoItems } = gameDemoSlice.actions;
export const selectGameDemo = (state) => state.gameDemo.gameItems;
export const selectCasinoItems = (state) => state.gameDemo.casinoItems;
export default gameDemoSlice.reducer;
