import React, { useState } from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import { actionGamePlay } from "../Reducers/gamePlaySlice";
import { socket } from "../context/socket";
import AlertMessage from "./Dialog/AlertMessage";
import { Transition, getJWT, getFullUrl, config } from "../config";
import { selectuserProfile } from "../Reducers/userProfileSlice";
import { actionPopup } from "../Reducers/popupSlice";

const Noti = styled.div`
  user-select: none;
  display: flex;
  background-image: url("../${config.theme}/announcement_bg.png");
  background-repeat: no-repeat;
  width: 35.38vw;
  height: calc(100vw * 0.0555);
  right: 0%;
  background-size: 100% 100%;
  position: absolute;
  z-index: 20;
  justify-content: flex-end;
  align-items: end;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 91.38vw;
          height: calc(100vw * 0.132);
        `
      : window.appHeight() > window.innerWidth &&
      css`
          width: 70.38vw;
          height: calc(100vw * 0.098);
        `}
`;

const Text = styled.div`
  user-select: none;
  color: #fff;

  font-size: 1vw;

  position: absolute;
  margin: 0.7vw 0vw 0vw 13vw;
  justify-content: flex-end;
  align-items: end;
  right: 2%;
  top: 5%;
  white-space: nowrap;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 3vw;
          top: 2vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          font-size: 2.2vw;
        `}
`;

const TextTwo = styled.div`
  position: relative;
  user-select: none;
  color: #fff;
  font-size: 1vw;
  top: -1vw;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: flex-end;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 3vw;
          position: relative;
          top: -1vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          font-size: 2.2vw;
        `}
`;

const Abc = styled.div`
  user-select: none;
  padding-right: 0.65vw;
  color: #fff;
  font-size: 1vw;
  font-weight: 400;
  margin: 0vw 0vw 0vw 1vw;
  position: relative;
  top: -1vw;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: flex-end;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 3vw;
          top: -1vw;
          position: relative;
          padding-right: 1.65vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          padding-right: 1.5vw;
          font-size: 2.2vw;
        `}
`;


function Notification(props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [emitError, setEmitError] = useState({ status: false, message: "" });
  const profile = useSelector(selectuserProfile);

  const handlenotification = () => {
    try {
      socket.emit("notification", {}, (body) => {
        if (typeof body != "undefined") {
          if (body.status) {
          } else if (body.status === false) {
            setEmitError({ status: true, message: body.errorCode });
            setTimeout(() => {
              setEmitError({ status: false });
            }, 3000);
          }
        }
      });
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  };

  const handleClickGame = () => {
    try {
      socket.emit(
        "getGameUrlbyName",
        {
          gameName: props.games,
          url: /Android|webOS|iPhone|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          )
            ? window.location.protocol + "//" + window.location.host
            : window.location.protocol +
            "//" +
            window.location.host +
            "/back.html?token=" +
            getJWT(),
          platform: 0,
        },
        (body) => {
          if (typeof body != "undefined") {
            if (typeof body.results != "undefined") {
              if (typeof body.results.data != "undefined") {
                if (
                  /Android|webOS|iPhone|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                  )
                ) {
                  window.location.href = body.results.data.url;
                } else {
                  dispatch(
                    actionGamePlay({
                      isPlay: true,
                      gameUrl: body.results.data.url,
                    })
                  );
                }
              }
            }
          }
        }
      );
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  };

  return (
    <>
      <Link
        {...props}
        style={{ textDecoration: "none" }}
        to={profile.isguest ? "" : "/gameplay/?token=" + getJWT()}
        onClick={
          profile.isguest && profile.isTelGuest
            ? () => {
              dispatch(
                actionPopup({ bankCheck: true, message: "bank account game" })
              );
            }
            : profile.isguest && !profile.isTelGuest
              ? () => {
                dispatch(
                  actionPopup({
                    guestCheck: true,
                    message: "you have not yet subscribed",
                  })
                );
              }
              : handleClickGame
        }
      >
        <Noti {...props}>
          <Text>
            {t("kun")} {props.username} {t("won")}{" "}
            <span style={{ color: "#ffc700", fontWeight: "500" }}>
              <NumberFormat
                value={props.balance}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={0}
                fixedDecimalScale={true}
              ></NumberFormat>
            </span>{" "}
            {i18n.language === "cn" ? t("from") : t("credit")}
          </Text>

          {/* <Test> */}
          {i18n.language === "cn" ? null : <TextTwo>{t("from")}</TextTwo>}
          <Abc onClick={handlenotification()}>
            {props.games.charAt(0).toUpperCase() + props.games.slice(1)}
          </Abc>
          {i18n.language === "cn" ? (
            <TextTwo style={{ left: "-0.65vw" }}>{t("credit")}</TextTwo>
          ) : null}
          {/* </Test> */}
        </Noti>
      </Link>

      <Transition showPop={emitError.status} type="alert">
        <AlertMessage
          message={t(emitError.message)}
          onClick={() => {
            setEmitError({ status: false });
          }}
        />
      </Transition>
    </>
  );
}

export default Notification;
