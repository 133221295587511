import styled from "styled-components";
import { useState, useLayoutEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { Transition } from "../../config";
import breakpoint from "../breakpoints";
import "./animation.css";

// import { Link } from "react-router-dom";

const FaqItemContainer = styled.div`
  width: 43vw;
  margin-top: calc(100vw * 0.0025);
  margin-bottom: calc(100vw * 0.0025);
  margin-left: auto;
  margin-right: auto;

  @media ${breakpoint.device.ipad} {
    width: 47vw;
  }
  @media ${breakpoint.device.mobile} {
    width: 89.37vw;
  }
`;

const FaqItem = styled.div`
  height: calc(100vw * 0.03125);
  background-color: #26293a;
  border: 0.1vw solid #484e6b;
  border-radius: 0.7vw;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5vw auto;
  padding: 0 1vw;
  cursor: pointer;
  text-align: left;
  p {
    font-size: 0.94vw;
  }

  @media ${breakpoint.device.ipad} {
    height: calc(100vw * 0.045);
    background-color: #26293a;
    border: 0.1vw solid #484e6b;
    border-radius: 0.7vw;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5vw auto;
    padding: 0 1vw;
    cursor: pointer;
    text-align: left;
    p {
      font-size: 1.4vw;
    }
  }
  @media ${breakpoint.device.mobile} {
    height: calc(100vw * 0.145);
    border-radius: 2.42vw;
    padding: 0 2vw;
    margin: 1.5vw auto;
    p {
      font-size: 4.35vw;
    }
  }
`;

const FaqItemDetail = styled.div`
  width: 40vw; // 42vw - padding 2vw
  height: max-content;
  background: #000;
  color: #c5c9ec;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  align-items: center;
  margin: -0.5vw auto 0 auto;
  padding: 1vw;
  border: solid 0.2vw rgba(255, 255, 255, 0.12);
  p {
    font-size: 0.79vw;
    text-align: start;
    white-space: pre-wrap;
  }
  @media ${breakpoint.device.ipad} {
    width: 44.5vw;
    p {
      font-size: 1.2vw;
      text-align: start;
      white-space: pre-wrap;
    }
  }
  @media ${breakpoint.device.mobile} {
    width: 81.19vw;
    padding: 2vw;
    margin: -1.5vw auto 0 auto;
    p {
      font-size: 3.62vw;
    }
  }
`;

const FontIcon = styled(FontAwesomeIcon)`
  color: #ff7000;
  padding-left: 0.5vw;
`;

function FaqListItem(props) {
  const [isActive, setIsActive] = useState(false);
  const [width] = useWindowSize();

  return (
    <FaqItemContainer>
      <FaqItem onClick={() => setIsActive(!isActive)}>
        <p>{props.question_list.question}</p>
        {!isActive ? (
          <FontIcon icon={faAngleDown} />
        ) : (
          <FontIcon icon={faAngleUp} />
        )}
      </FaqItem>

      <Transition
        in={isActive}
        timeout={300}
        classNames={width <= breakpoint.deviceSize ? "st2bFaqMb" : "st2bFaq"}
        unmountOnExit
      >
        <FaqItemDetail>
          <p dangerouslySetInnerHTML={{ __html: props.question_list.answer }} />
        </FaqItemDetail>
      </Transition>
    </FaqItemContainer>
  );
}

export default FaqListItem;

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.appHeight()]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}
