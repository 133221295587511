import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectGamePlay, actionGamePlay } from "../../Reducers/gamePlaySlice";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import breakpoint from "./breakpoints";
// import { actionSetprofile } from "../../Reducers/userProfileSlice";
import { selectAlert, actionAlert } from "../../Reducers/alertSlice";
import { socket, SocketContext } from "../../context/socket";
// import { base64decode as decode } from "nodejs-base64";
import {
  Transition,
  getJWT,
  getFullUrl,
  redirectToLogin,
  config,
  // getBackUrl,
} from "../../config";
import AlertMessage from "../Dialog/AlertMessage";
import Loading from "../Loading";
import { selectDimensions, setDimension } from "../../Reducers/dimensions";
import {
  selectPopup,
  selectPopupMessage,
  actionPopup,
  // actionPopupMessage,
} from "../../Reducers/popupSlice";

const Frame = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  position: absolute;
  z-index: 50;
  background-image: url("../${config.theme}/newasset/bg.png");
  background-size: 100% 100%;
`;

const Bar = styled.div`
  user-select: none;
  background-image: url("../${config.theme}/spinix_normal_white_1.png"),
    url("../${config.theme}/gamescreen_title.png");
  background-repeat: no-repeat, repeat-x;
  background-position: center, center;
  /* background-color: #302e3c; */
  background-size: 12vw, contain;
  width: 100%;
  height: 4.5vw;
  display: flex;
  align-items: center;
  p {
    color: #bdb9d1;
    font-size: 1.5vw;
  }
  @media (orientation: portrait) {
    height: 6vw;
  }
`;

const BackButton = styled.button`
  user-select: none;
  cursor: pointer;
  // clear button style
  background-color: transparent;
  border: none;
  padding: 0;
  :focus {
    outline: none;
  }
  // end clear button style
  display: flex;
  align-items: center;
  img:nth-child(1) {
    height: calc(100vw * 0.032);
    margin: 2vw 1vw 2vw 2vw;
  }
  img:nth-child(2) {
    height: calc(100vw * 0.019);
    position: absolute;
    left: 4vw;
  }
`;
const Iframe = styled.div`
  user-select: none;
  width: 100vw;
  height: calc(100vh - 4.5vw);
  background-color: #000;
  @media (orientation: portrait) {
    height: calc(100vh - 6vw);
  }
`;

function GamePlay(props) {
  const { t } = useTranslation();
  const game = useSelector(selectGamePlay);
  const alert = useSelector(selectAlert);
  const dispatch = useDispatch();
  const history = useHistory();
  // const socketio = useContext(SocketContext);
  const [emitError, setEmitError] = useState({ status: false, message: "" });
  const [Load, setLoad] = useState(false);
  const dimensions = useSelector(selectDimensions);
  const popupStatus = useSelector(selectPopup);
  const popupMessage = useSelector(selectPopupMessage);
  const [height, setHeight] = useState(
    parseInt(dimensions.height.replace("px", "") - 100)
  );

  const fnSetHeight = (h, time) => {
    setTimeout(() => {
      let ele = document.getElementById("id-iframe");
      if (ele) {
        ele.height = h - 1;
        setTimeout(() => {
          ele.height = h + 1;
        }, 100);
      }
    }, time);
  };

  useEffect(() => {
    try {
      let h = parseInt(dimensions.height.replace("px", "") - 100);
      fnSetHeight(h, 3000);
      fnSetHeight(h, 5000);
    } catch (error) {}
  }, []);

  useEffect(() => {
    try {
      let h = parseInt(dimensions.height.replace("px", "") - 100);
      fnSetHeight(h, 200);
    } catch (error) {}
  }, [dimensions.width]);

  return (
    <Frame>
      <Bar width={window.innerWidth} height={window.appHeight()}>
        <BackButton
          id="btn-back"
          onClick={() => {
            socket.emit(
              "checkFreespin",
              {
                url: getFullUrl(),
                platform: 0,
                back_game: true,
              },
              (body) => {
                // console.log("back button", body.results)
                try {
                  // let urlLuncher = body.results.data.url;
                  // if (typeof urlLuncher !== "undefined") {
                  if (body.results.data.freespin == true) {
                    setEmitError({
                      status: true,
                      message: t("alert free spin"),
                    });
                    setTimeout(() => {
                      setEmitError({ status: false });
                      if (window.isBack === true) {
                        window.isBack = false;
                        window.location.reload();
                      }
                    }, config.popupTimeout);
                  } else {
                    redirectToLogin(getFullUrl());

                    // dispatch(actionGamePlay({ gameUrl: "", isPlay: false }));
                    // setLoad(true)
                    // socket.emit("refreshJWT", getBackUrl(), function (data) {
                    //   console.log("data", data);
                    //   if (props.isFreeSpin == true) {
                    //     redirectToLogin(getFullUrl())
                    //   } else {
                    //     history.push("/" + "?token=" + getJWT())
                    //     setLoad(false)
                    //     if (typeof data == "undefined" || typeof data.results == "undefined" || typeof data.results.data == "undefined") { }
                    //     else {
                    //       if (data.status) {
                    //         dispatch(
                    //           actionSetprofile(
                    //             data.results.data
                    //           )
                    //         );
                    //       }
                    //       else {
                    //         setEmitError({ status: true, message: data.errorCode })
                    //       }
                    //     }
                    //   }
                    // });
                  }
                } catch (error) {
                  console.log(error);
                }
              }
            );
          }}
        >
          <img src={"../" + config.theme + "/btn_nav.png"} />
          <img src={"../" + config.theme + "/icon_back.png"} />
        </BackButton>
        <p>{t("back to lobby")}</p>
      </Bar>
      <Iframe>
        <iframe
          id="id-iframe"
          allow="autoplay"
          fullscreen=""
          allowfullscreen="allowfullscreen"
          src={game.gameUrl}
          style={{ width: "100%", height: "100%" }}
          frameBorder="0"
        ></iframe>
      </Iframe>
      {useEffect(() => {
        console.log(alert.errorCode);
        if (alert.errorCode != "") {
          setEmitError({ status: true, message: alert.errorCode });
          setTimeout(() => {
            setEmitError({ status: false });
            dispatch(actionGamePlay({ gameUrl: "", isPlay: false }));
            dispatch(actionAlert({ isAlert: false, errorCode: "" }));
            history.push("/" + "?token=" + getJWT());
          }, 3000);
        }
      }, [alert])}
      <Transition showPop={emitError.status} type="alert">
        <AlertMessage
          message={t(emitError.message)}
          onClick={() => {
            if (alert.errorCode != "") {
            } else {
              setEmitError({ status: false });
            }
          }}
        />
      </Transition>
      <Transition showPop={popupStatus.alertNoti} type="alert">
        <AlertMessage
          message={popupMessage.alertNoti}
          onClick={() => {
            dispatch(actionPopup({ alertNoti: false }));
          }}
        />
      </Transition>
      {Load ? <Loading /> : ""}
    </Frame>
  );
}

export default GamePlay;
