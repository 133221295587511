import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { config } from "../../config";
import { useTranslation } from "react-i18next";

const DropDown = styled.div`
  width: 100%;
  height: 1.79vw;
  background-color: #000000;
  color: rgba(255, 255, 255, 0.4);
  position: relative;
  border-radius: 1vw;
  /* font-size: 1.2vw; */
  text-align: left;
  padding: 0.6vw;
  cursor: pointer;

  @media only screen and (max-width: 480px) and (orientation: portrait) {
    height: 6vw;
  }
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    height: 2.79vw;
  }
`;

const DropDownList = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #000000;
  // background: #302e3c;
  // box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f, inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  // border: solid 0.2vw orange;
  // theme ==================
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;
  // theme ==================
  width: 100%;
  height: 15vw;
  padding-bottom: 1vw;
  border-radius: 1vw;

  @media only screen and (max-width: 480px) and (orientation: portrait) {
    height: 28vw;
  }
`;

const DropDownButton = styled.div`
  line-height: 1.9vw;
  color: white;

  @media only screen and (max-width: 480px) and (orientation: portrait) {
    line-height: 5.9vw;
  }
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    line-height: 2.9vw;
  }
`;

const DropDownItem = styled.div`
  margin: 1vw;
  color: #fff;
`;

const FontIcon = styled(FontAwesomeIcon)`
  float: right;
  color: #fff;
  margin-top: 0.5vw;

  @media only screen and (max-width: 480px) and (orientation: portrait) {
    margin-top: 1.5vw;
  }
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    margin-top: 0.5vw;
  }
`;

function DropDownFilter(props) {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen]);

  // console.log(props.options);

  return (
    <DropDown ref={ref}>
      <DropDownButton onClick={() => setIsOpen(!isOpen)}>
        {props.topic == "" ? t("choose topic") : t(props.topic)}
        <FontIcon icon={isOpen ? faAngleUp : faAngleDown} />
      </DropDownButton>
      {isOpen && (
        <DropDownList>
          {props.options.map((option, index) => (
            <React.Fragment key={index}>
              <DropDownItem
                onClick={() => {
                  props.setTopicId(option._id);
                  if (i18n.language == "th") {
                    props.setTopic(option.complain_text);
                  } else {
                    props.setTopic(t(option.complain_text_en));
                  }
                  setIsOpen(false);
                  // props.setFilter(option);
                }}
              >
                {/* {t(`${option}`)} */}
                {i18n.language == "th"
                  ? option.complain_text
                  : t(option.complain_text_en)}
              </DropDownItem>
              {index < props.options.length - 1 ? (
                <hr
                  style={{
                    border: "1px solid #595670",
                    width: "95%",
                    margin: "0 auto",
                  }}
                />
              ) : (
                ""
              )}
            </React.Fragment>
          ))}
          {/* <DropDownItem>Provider 1</DropDownItem> */}
        </DropDownList>
      )}
    </DropDown>
  );
}

export default DropDownFilter;
