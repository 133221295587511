import { useState } from "react";
// import JackpotBox from "./JackpotBox";
import JackpotCounter from "./JackpotCounter";
import styled from "styled-components";
// import axios from 'axios';
import { Transition } from "../../config";

// import { useTranslation } from "react-i18next";
import WarningMessage from "../Dialog/WarningMessage";

const WarningContainer = styled.div`
    user-select: none;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    z-index: 50;
`

function Jackpot() {
  // const [items, setItems] = useState({
  //   date: "",
  //   list: [],
  //   monthly_jackpot: 0,
  //   online: 0,
  //   total_jackpot: 0,
  // });
  const [emitError, setEmitError] = useState({ status: false, message: "" });

  // useEffect(() => {
  //   axios({
  //     method: 'get',
  //     url: config.socketEndpoint + 'getMonthJackpot',
  //     headers: {}
  //   }).then(function (response) {
  //     // console.log(response.data);
  //     // response.data.results.data = undefined;
  //     // response.data.status = false;
  //     // response.data.errorCode = "s0008";
  //     if (typeof response.data == "undefined" || typeof response.data.results == "undefined" || typeof response.data.results.data == "undefined") { }
  //     else {
  //       if (response.data.status == true) {
  //         setItems(response.data.results.data);
  //       } else if (response.data.status == false) {
  //         setEmitError({ status: true, message: response.data.errorCode });
  //         // setTimeout(() => {
  //         //   setEmitError({ status: false })
  //         // }, 3000)
  //       }
  //     }
  //   })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }, []);

  // console.log("Jackpot items:", items.list);

  return (
    <>
      <div>
        {/* <JackpotBox {...items} /> */}
        <JackpotCounter
        // online={items.online} 
        // totalJackpot={items.total_jackpot} 
        />
      </div>

      <Transition showPop={emitError.status} type="alert">
        <WarningContainer>
          <WarningMessage errCode={emitError.message} onClick={() => {
            setEmitError({ ...emitError, status: false })
          }} />
        </WarningContainer>
      </Transition>

    </>
  );
}

export default Jackpot;
