import React from "react";
import styled, { css, keyframes } from "styled-components";
import { config } from "../../config";

const scale = keyframes`
    0%{ transform: scale(0.9); filter: brightness(100%);}
    100%{ transform: scale(1); filter: brightness(130%);}
`;
const Badge = styled.div`
  width: 100%;
  height: 4vw;
  background-color: red;
  position: absolute;
  z-index: 1;
  background: url("../${config.theme}/winrate/lobbybadge.png") center top
    no-repeat;
  background-size: contain;
  user-select: none;
  color: #572a00;
  text-shadow: 0vw -0.1vw 0.1vw #fff500, 0vw 0.1vw 0.3vw #ffffff;
  margin: 0 auto;
  pointer-events: none;
  // animation-name: ${scale};
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          height: 7vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          height: 7vw;
        `}
`;

const TextTop = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1vw;
  line-height: 1vw;
  padding-top: 0.3vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2vw;
          padding-top: 1.5vw;
          line-hegith: 2.3vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 1.8vw;
          padding-top: 1vw;
          line-height: 1.5vw;
        `}
`;
const TextBottom = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1.3vw;
  line-height: 1.3vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2.4vw;
          line-height: 2.8vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 2.3vw;
          line-height: 2.5vw;
        `}
`;

function WinBadgeBig(props) {
  return (
    <Badge>
      <TextTop>WIN RATE</TextTop>
      <TextBottom>GUARANTEE</TextBottom>
    </Badge>
  );
}
export default WinBadgeBig;
