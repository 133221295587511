import React from "react";
import styled, { css } from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { config } from "../../config";
import { actionCloseSound } from "../../Reducers/userProfileSlice";

const Popup = styled.div`
  position: absolute;
  /* background: #f87d7d; */
  background: #192756;
  /* box-shadow: inset 0px 0px 3px 1px #ffc3c3, inset 0px 0px 20px #ffdedc; */
  box-shadow: inset 0px 0px 3px 1px #5dd8ff,
    inset 0px 0px 45px rgba(66, 255, 232, 0.63);
  width: 43vw;
  height: 25vw;
  border-radius: 2vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 68vw;
          height: 40vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 50vw;
          height: 31vw;
        `}
`;

const Background = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  position: absolute;
  left: 0%;
  bottom: 0%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  z-index: 31;
  align-items: center;
  justify-content: center;
`;

const Close = styled.img`
  user-select: none;
  width: 4vw;
  position: absolute;
  z-index: 1;
  right: -4.3%;
  top: -5.5%;
  cursor: pointer;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 7vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 6vw;
        `}
`;

const Content = styled.div`
  position: relative;
  text-align: center;
  width: 34vw;
  height: 29vw;
  margin: 0 auto;
  top: 1vw;
  /* background: #fffbd8; */
  /* box-shadow: inset 0px 0px 3px 1px #ffffff,
    inset 0px 0px 20px rgba(236, 152, 106, 0.63); */
  border-radius: 25px;
  padding: 1vw 0vw;
  h5 {
    margin-top: 0vw;
    font-size: 2.5vw;
    font-weight: 100;
    color: #fff;
  }
  img {
    width: 85%;
    /* margin: 0 1vw; */
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: auto;
          height: auto;
          h5 {
            font-size: 4.5vw;
          }
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: auto;
          height: auto;
          h5 {
            font-size: 3.5vw;
          }
          img {
            /* width: 11vw;
    margin: 0vw 2vw 0vw 2vw; */
          }
        `}
`;

const RewardList = styled.div`
  height: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const Detail = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${(props) => (props.length == 4 ? "34%" : "30%")};
  p {
    color: #ffffff;
    font-size: 2.25vw;
    font-weight: 700;
    padding: 0vw 0.1vw 0vw 0.4vw;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          p {
            color: #ffffff;
            font-size: 3.5vw;
            font-weight: 700;
          }
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 28%;
        `}
`;

const DiamondBox = styled.div`
  position: relative;
  bottom: 1vw;
  /* left: 15%; */
  /* width: 6vw; */
  width: 7.5vw;
  height: 2.5vw;
  border-radius: 2vw;
  justify-content: center;
  background-color: #7f2225;
  display: flex;
  align-items: center;
  .diamond {
    width: 25%;
    /* margin: 0.5vw 0.5vw 0.5vw 1vw; */
  }
  p {
    color: #ffffff;
    font-size: 1.5vw;
    font-weight: 700;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 12.5vw;
          height: 4.5vw;
          border-radius: 3vw;
          p {
            color: #ffffff;
            font-size: 3vw;
            font-weight: 700;
            padding: 0vw 0.1vw 0vw 0.4vw;
          }
        `
      : window.appHeight() > window.innerWidth &&
        css`
          p {
            color: #ffffff;
            font-size: 1.5vw;
            font-weight: 700;
            padding: 0vw 0.1vw 0vw 0.4vw;
          }
        `}
`;

function RewardDetailsPopup(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Background>
      <Popup>
        <Close
          src={"../" + config.theme + "/btn_close.png"}
          onClick={() => {
            props.closeClick();
            dispatch(actionCloseSound());
          }}
        />
        <Content
          style={{ width: props.widthContent, height: props.heightContent }}
        >
          <h5>{t("details")}</h5>
          <RewardList>
            <Detail>
              <p>x{props.total.A}</p>
              <img
                src={"../" + config.theme + "/event_songkarn/icon_farang.png"}
              />
              <DiamondBox>
                <img
                  className="diamond"
                  src={"../" + config.theme + "/icon_diamond_copy.png"}
                />
                <p>{props.total.A * props.payout.A}</p>
              </DiamondBox>
            </Detail>
            <Detail>
              <p>x{props.total.B}</p>
              <img
                src={"../" + config.theme + "/event_songkarn/icon_girl.png"}
              />
              <DiamondBox>
                <img
                  className="diamond"
                  src={"../" + config.theme + "/icon_diamond_copy.png"}
                />
                <p>{props.total.B * props.payout.B}</p>
              </DiamondBox>
            </Detail>
            <Detail>
              <p>x{props.total.C}</p>
              <img
                src={"../" + config.theme + "/event_songkarn/icon_rambo.png"}
              />
              <DiamondBox>
                <img
                  className="diamond"
                  src={"../" + config.theme + "/icon_diamond_copy.png"}
                />
                <p>{props.total.C * props.payout.C}</p>
              </DiamondBox>
            </Detail>

            {/* {props.item.map((item, index) => (
              <Detail length={props.item.length} key={"Index: " + index}>
                <p>x{item.amount}</p>
                <img
                  src={
                    item.no === 1
                      ? "../" + config.theme + "/event_songkarn/icon_farang.png"
                      : item.no === 2
                      ? "../" + config.theme + "/event_songkarn/icon_girl.png"
                      : item.no === 3
                      ? "../" + config.theme + "/event_songkarn/icon_rambo.png"
                      : ""
                  }
                  // itemType={item.no}
                />
                <DiamondBox>
                  <img
                    className="diamond"
                    src={"../" + config.theme + "/icon_diamond_copy.png"}
                  />
                  <p>{item.c1}</p>
                </DiamondBox>
              </Detail>
            ))} */}
          </RewardList>
        </Content>
      </Popup>
    </Background>
  );
}
export default RewardDetailsPopup;
