import React from "react";
import styled from "styled-components";
import FeatureGame from "../FeatureGame";
import About from "../AboutMaxxma";
import Jackpot from "../Jackpot";
import breakpoint from "../breakpoints";
import HomeBanner from "../HomeBanner";
// import { Provider } from "react-redux";
import Providers from "../Providers"
import LiveCasinos from "../LiveCasinos";
import FaqHome from "../Faq/FaqHome";
import Articles from "../Articles";
import SlotItem from "../AboutSlot/Index"
import VidClip from "../MediaPromote/VideoClip";

// import SlotMachine from 'react-slot-machine-gen';

const BG = styled.div`
  background-image: url("../../lpimages/main_bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 5vw;
  @media ${breakpoint.device.mobile} {
    background-image: url("../../lpimages/main_bg_mobile.png");
    margin-top: 19.08vw;
  }
`;

const FeatureBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2vw;
  @media ${breakpoint.device.mobile} {
    flex-direction: column-reverse;
  }
`



function index() {

  return (
    <BG>
      <HomeBanner />
      <VidClip />
      {/* <MiniSlotGame /> */}
      <FeatureBox>
        <Jackpot />
        <FeatureGame />
      </FeatureBox>
      <LiveCasinos />
      {/* <Slide /> */}
      <About />
      <SlotItem />
      <Providers />
      {/* <Partners /> */}
      <FaqHome />
      <Articles />
    </BG>
  );
}

export default index;
