import { createSlice } from "@reduxjs/toolkit";
export const JackpotSlice = createSlice({
    name: "jackpot",
    initialState: {
        counter: {
            totalWin: 0,
            online: 0
        },
    },
    reducers: {
        actionJackpot: (state, action) => {
            state.counter = { ...state.counter, ...action.payload };
        },
    },
});

export const { actionJackpot } = JackpotSlice.actions;
export const selectJackpot = (state) => state.jackpot.counter;
export default JackpotSlice.reducer;