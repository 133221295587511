import React from "react";
import styled from "styled-components";
import breakpoint from "../breakpoints";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
// import { useSelector } from "react-redux";
import FaqList from './FaqList';

const FaqHomeBox = styled.div`
  user-select: none;
  background: #010101;
  background-image: url("../lpimages/bg_content2.png");
  width: 100%;
  background-size: 102%;
  background-repeat: no-repeat;
  padding-bottom: 3vw;
  @media ${breakpoint.device.mobile} {
    height: 100%;
    background-size: 130%;
    background-position-x: center;
    text-align: start;
  }
`;

const FaqContainer = styled.div`
  width: 60vw;
  margin: 0 auto 3vw auto;
  @media ${breakpoint.device.mobile} {
    width: 100%;
    margin: 0 auto 20vw auto;
  }
`

const Image = styled.img`
  width: 2.5vw;
  padding-right: 0.5vw;
  @media ${breakpoint.device.mobile} {
    width: 5.5vw;
    padding-right: 1.5vw;
  }
`;

const Header = styled.div`
  width: 100%;
  color: white;
  padding-top: 1vw;
  white-space: pre-wrap;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 auto;
  h1 {
    font-size: ${props => props.lang === "th" ? "2.25vw" : "3vw"};
    
  }
  p {
    color: #8a8ca3;
    font-size: 1.5vw;
    margin: 0;
    padding-left: 1vw;
    display: block;
    text-align: start;
    white-space: pre-wrap;
  }
  @media ${breakpoint.device.mobile} {
    width: initial;
    padding: 5vw 3.5vw;
    align-items: baseline;
    justify-content: space-between;
    h1{
      width: initial;
      font-size: 6.5vw;
      display: inline-block;
    }
    p {
      font-size: 3.38vw;
      padding-left: 7vw;
    }
  }
`;

const Title = styled.div`
    display: flex;
    align-items: center;
    margin-left: -13vw;
    @media ${breakpoint.device.mobile} {
      display: block;
      margin-left: 1vw;
    }
`

const More = styled.a`
    /* width: 15%; */
    /* color: #ff7000; */
    color: #8A8CA3;
    font-size: 1vw;
    cursor: pointer;
    text-decoration: underline;
    float: right;
    margin-right: 9vw;
    padding-top: 1vw;
  @media ${breakpoint.device.mobile} {
    font-size: 3.5vw;
    margin-top: 6vw;
  }
`;

function FaqHome() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  return (

    <FaqHomeBox>
      <FaqContainer>
        <Header lang={i18n.language}>
          <Title>
            <Image src="../../lpimages/bullet.png" />
            <h1>{t("faq")}</h1>
            {/* <p>{t("articles")}</p> */}
            <p>{t("FAQDescription")}</p>
          </Title>
          {/* <More
          onClick={() => {
            history.push("../faq");
            window.scrollTo({
              top: 0,
              behavior: "smooth"
            });
          }}
        >
          {t("More")}
        </More> */}
        </Header>
        <FaqList home={true} />
        <More
          onClick={() => {
            history.push("../faq");
            window.scrollTo({
              top: 0,
              behavior: "smooth"
            });
          }}
        >
          {t("More")}
        </More>
      </FaqContainer>


    </FaqHomeBox>

  );
}

export default FaqHome;