import React, { useEffect, useState, useContext } from "react";
import { Tween } from "react-gsap";
import { useSelector, useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import { Transition, config } from "../../config";
import {
  selectuserProfile,
  actionSetprofile,
  actionFesMinigameBGM,
} from "../../Reducers/userProfileSlice";
import CollectRewardPopup from "./CollectRewardPopup";
import { actionPopup } from "../../Reducers/popupSlice";
import { SocketContext } from "../../context/socket";
import RewardPopup from "../FestivalPopup/alertReward";
import { actionLoading } from "../../Reducers/loadingSlice";
import RewardDetailsPopup from "./RewardDetailsPopup";
import { Howl } from "howler";
import { useTranslation } from "react-i18next";

const BG = styled.div`
  user-select: none;
  background-color: #47abc1;
  background-size: cover;
  background-repeat: no-repeat;
  /* background-size: 150%; */
  background-position: center;
  width: 100vw;
  height: calc(100% - 8.4vw);
  /* height: 100vh; */
  overflow: hidden;
  margin: auto auto;
  position: absolute;
  bottom: 0;
  z-index: 20;
  justify-content: center;
`;

const PlayZone = styled.div`
  user-select: none;
  background-color: gray;
  background-image: url("../${config.theme}/event_val/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  /* background-size: 150%; */
  background-position: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: auto auto;
  position: relative;
  bottom: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
`;

const Box = styled.div`
  user-select: none;
  cursor: pointer;
  /* width: 7vw;
    height: 7vw; */
  width: 4.667vw;
  height: 4.667vw;
  background-image: url(${(props) => `../${config.theme}/event_val/item${props.item === 5 ? "4" : props.item}.png`});
  background-size: contain;
  position: absolute;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
  display: ${(props) => props.display};
  z-index: 23;
`;

const Basket = styled.div`
  user-select: none;
  /* width: 6.4%;
    height: 1vw; */
  width: 9.6%;
  height: 1.5vw;
  /* background-color: #F05858; */
  position: absolute;
  bottom: 2.97vw;
  left: 0;
  z-index: 22;
`;

const Girl = styled.div`
  user-select: none;
  width: 137.5%;
  height: 608.608%;
  background-image: url(${(props) => `../${config.theme}/event_val/${props.vltGet ? "basket_get" : "basket"}.png`});
  background-size: contain;
  position: relative;
  bottom: 310%;
  right: 18.75%;
`;

const ControlBox = styled.div`
  user-select: none;
  cursor: pointer;
  width: 7.47vw;
  height: 7.47vw;
  position: absolute;
  bottom: 1%;
  z-index: 23;
  background-image: url(${`../${config.theme}/event_val/gamearrow.png`});
  background-size: contain;
`;

const ControlInvisBox = styled.div`
  user-select: none;
  height: 100%;
  width: 50%;
  position: absolute;
  z-index: 22;
`;

const RewardBox = styled.div`
  user-select: none;
  width: 7.91vw;
  height: 30.597vw;
  font-size: 1.5vw;
  ${() =>
    window.innerWidth / window.appHeight() > 1.7
      ? css`
          width: 6.328vw;
          height: 24.4776vw;
          font-size: 1.2vw;
        `
      : css``}
  position: absolute;
  z-index: 22;
  /* border: solid yellowgreen 0.2vw; */
  background-color: #f87d7d;
  top: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: inset 0px 0px 3px 1px #ffc3c3, inset 0px 0px 20px #ffdedc;
  border-radius: 0px 1vw 1vw 0px;
  color: #ffffff;
`;

const RewardItem = styled.div`
  user-select: none;
  cursor: pointer;
  width: 90%;
  height: 90%;
  background-image: url(${(props) => `../${config.theme}/event_val/item${props.item === 5 ? "4" : props.item}.png`});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
`;

const RewardCover = styled.div`
  user-select: none;
  cursor: pointer;
  width: 66%;
  height: 15.837%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1vw;
  position: relative;
  font-size: 1.34vw;
  color: #fae0ba;
  ${(props) =>
    props.day < 3 && props.last
      ? css`
          border: 0.15vw dashed #fae0ba;
        `
      : (props) =>
        props.day < 2
          ? css`
                border: 0.15vw dashed #fae0ba;
              `
          : css`
                background: #fffbd8;
                box-shadow: inset 0px 0px 3px 1px #ffffff,
                  inset 0px 0px 20px rgba(236, 152, 106, 0.63);
              `}
`;

const Close = styled.div`
  user-select: none;
  background-image: url("../${config.theme}/btn_close.png");
  background-size: contain;
  height: 4vw;
  width: 4vw;
  position: absolute;
  right: 1vw;
  top: 1.5vw;
  cursor: pointer;
  background-repeat: no-repeat;
  z-index: 50;
`;

const AnnounceTab = styled.div`
  user-select: none;
  /* width: 55%; */
  height: 4.63vw;
  width: 44%;
  /* height: 3.704vw; */
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0px 0px 1vw 1vw;
  position: relative;
  top: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AnnounceText = styled.div`
  user-select: none;
  /* font-size: 1.644vw; */
  font-size: 1.3152vw;
  color: #ffffff;
  margin-top: 1%;
`;

const AnnounceArrow = styled.div`
  user-select: none;
  /* width: 3vw; */
  /* height: 3vw; */
  width: 2.4vw;
  height: 2.4vw;
  background-image: url("../${config.theme}/event_val/textarrow.png");
  background-size: contain;
  margin: 1% 1vw 0vw 1vw;
  background-repeat: no-repeat;
  
`;

const getGiftSound = new Howl({
  src: ["Sound/VLTsfx_dropped_item.mp3"],
  loop: false,
  volume: 0.5,
});

export default function Minigame(props) {
  const [xArray, setXArray] = useState([]);
  const [basketX, setBasketX] = useState(0);
  const [moveRight, setMoveRight] = useState(false);
  const [moveLeft, setMoveLeft] = useState(false);
  const [showReward, setShowReward] = useState([]);
  const profile = useSelector(selectuserProfile);
  const [array, setArray] = useState(
    JSON.parse(JSON.stringify(profile.valentine.choice))
  );
  const [getVLTItem, setGetVLTItem] = useState(true);
  const [itemPopup, setItemPopup] = useState(false);
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const [rewardPopup, setRewardPopup] = useState(false);
  const [sumResult, setSumResult] = useState(0);
  const [rewardDetailPop, setRewardDetailPop] = useState(false);
  const [rewardDetail, setRewardDetail] = useState([]);
  const [showClose, setShowClose] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    props.MainSoundBGM.volume(0);
    dispatch(actionFesMinigameBGM(true));
    dispatch(actionSetprofile({ FesBGMStatus: true }));
  }, []);

  useEffect(() => {
    // console.log(array)
    setGetVLTItem(false);
    window.rewardList = [];
  }, []);

  // useEffect(() => {
  //     setArray(profile.valentine.choice)
  // }, [])
  // var array = [
  //     { no: 1, id: 1, color: "black", display: "" },
  //     { no: 2, id: 2, color: "blue", display: "" },
  //     { no: 3, id: 3, color: "green", display: "" },
  //     { no: 4, id: 4, color: "yellow", display: "" },
  //     { no: 1, id: 5, color: "brown", display: "" },
  //     { no: 1, id: 6, color: "orange", display: "" },
  //     { no: 2, id: 7, color: "pink", display: "" },
  //     { no: 3, id: 8, color: "white", display: "" },
  //     { no: 4, id: 9, color: "aqua", display: "" },
  //     { no: 1, id: 10, color: "seagreen", display: "" },
  //     { no: 1, id: 11, color: "seagreen", display: "" },
  //     { no: 2, id: 12, color: "pink", display: "" },
  //     { no: 3, id: 13, color: "black", display: "" },
  //     { no: 4, id: 14, color: "salmon", display: "" },
  //     { no: 2, id: 15, color: "yellow", display: "" },
  // ]

  function getRandomArbitrary(min, max) {
    return parseInt(Math.floor(Math.random() * (max - min)) + min);
  }

  function shuffle(arrShuffle) {
    let currentIndex = arrShuffle.length,
      randomIndex;
    // console.log(arrShuffle)
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [arrShuffle[currentIndex], arrShuffle[randomIndex]] = [
        arrShuffle[randomIndex],
        arrShuffle[currentIndex],
      ];
    }
    return arrShuffle;
  }

  let basketInterval;

  const updateBasketX = (d, w, bw) => {
    basketInterval =
      !basketInterval &&
      setInterval(() => {
        setBasketX((prev) =>
          d === "left" && prev > 0
            ? prev - w * 0.01
            : d === "right" && prev < w - bw
              ? prev + w * 0.01
              : prev
        );
      }, 20);
  };

  const updateShowReward = (e) => {
    setShowReward(e);
  };

  useEffect(() => {
    let w = document.getElementById("playZone").clientWidth;
    let basketW = document.getElementById("basket").clientWidth;
    setBasketX(w / 2 - basketW / 2);
  }, []);

  useEffect(() => {
    let w = document.getElementById("playZone").clientWidth;
    let basketW = document.getElementById("basket").clientWidth;
    console.log(moveLeft, moveRight);
    if (moveLeft) {
      updateBasketX("left", w, basketW);
    } else if (moveRight) {
      updateBasketX("right", w, basketW);
    }
    return () => clearInterval(basketInterval);
  }, [moveLeft, moveRight]);

  const IDGetWidth = (id) => {
    try {
      return document.getElementById(id).clientWidth;
    } catch (error) {
      return null;
    }
  };

  const IDGetHeight = (id) => {
    try {
      return document.getElementById(id).clientHeight;
    } catch (error) {
      return null;
    }
  };

  window.gamePlay = () => {
    // shuffle()
    let w = IDGetWidth("playZone");
    let h = IDGetHeight("playZone");
    let boxWidth = IDGetWidth("box");
    let boxHeight = IDGetHeight("box");
    let bw = IDGetWidth("basket");
    let bh = IDGetHeight("basket");
    setXArray([]);
    let list = [];
    let i = 0;
    for (const iterator of shuffle(array)) {
      // console.log(iterator.no)
      i++;
      let randomTop =
        iterator.id < 6
          ? getRandomArbitrary(-h, (-h * 2) / 3 - boxHeight)
          : iterator.id > 10
            ? getRandomArbitrary(-h / 3, -boxHeight)
            : getRandomArbitrary((-h * 2) / 3, -h / 3 - boxHeight);
      list.push(
        <Tween
          to={{
            // duration: 7,
            duration:
              iterator.no === 1
                ? 8
                : iterator.no === 2
                  ? 7
                  : iterator.no === 3
                    ? 5
                    : 3,
            y: h * 2,
            rotation: getRandomArbitrary(-1000, 1000),
          }}
          key={iterator.id}
        >
          <Box
            id={"box" + i}
            left={
              i === 1 || i === 6 || i === 11
                ? getRandomArbitrary(0, w * 0.2 - boxWidth)
                : i === 2 || i === 7 || i === 12
                  ? getRandomArbitrary(w * 0.2, w * 0.4 - boxWidth)
                  : i === 3 || i === 8 || i === 13
                    ? getRandomArbitrary(w * 0.4, w * 0.6 - boxWidth)
                    : i === 4 || i === 9 || i === 14
                      ? getRandomArbitrary(w * 0.6, w * 0.8 - boxWidth)
                      : getRandomArbitrary(w * 0.8, w - boxWidth)
            }
            top={randomTop}
            item={iterator.no}
            display={
              window.rewardList.filter((e) => {
                return e.id === iterator.id;
              }).length > 0
                ? "none"
                : ""
            }
          />
        </Tween>
      );
    }
    // console.log(array, window.rewardList)
    // console.log(shuffle(list))
    setXArray(list);
    clearInterval(window.checkY);
    const getPosition = (boxIndex) => {
      try {
        return document
          .getElementById("box" + boxIndex)
          .getBoundingClientRect();
      } catch (error) {
        return null;
      }
    };

    const getBasketPosition = () => {
      try {
        return document.getElementById("basket").getBoundingClientRect();
      } catch (error) {
        return null;
      }
    };

    window.checkY = setInterval(() => {
      let boxesArr = [];
      for (let i = 1; i <= array.length; i++) {
        let p = getPosition(i);
        if (p !== null) {
          boxesArr.push(p);
        }
      }

      let basketPosition = getBasketPosition();

      boxesArr.map((item, i) => {
        if (
          item.x < basketPosition.x + bw &&
          item.x + boxWidth > basketPosition.x &&
          item.y < basketPosition.y + bh &&
          boxHeight + item.y > basketPosition.y
        ) {
          // console.log(window.rewardList)

          if (
            !window.rewardList.some((arr) => arr.id === array[i].id) &&
            window.rewardList.length < 2 + profile.valentine.day
          ) {
            if (profile.seteffect === "O") {
              getGiftSound.play();
            }
            // window.rewardList = [...window.rewardList, array[i]]
            window.rewardList.push(array[i]);
            updateShowReward(window.rewardList);
            setGetVLTItem(true);
            clearTimeout(window.girlStop);
            window.girlStop = setTimeout(() => {
              setGetVLTItem(false);
            }, 700);
            document.getElementById("box" + (i + 1)).style.display = "none";
            if (window.rewardList.length >= 2 + profile.valentine.day) {
              setXArray([]);
              clearInterval(window.valentineGame);
              console.log(showReward);
              setItemPopup(true);
              setShowClose(false);
              setMoveLeft(false);
              setMoveRight(false);
            }
          } else {
          }
        }
      });
    }, 100);
  };

  const resetGame = () => {
    let w = document.getElementById("playZone").clientWidth;
    let basketW = document.getElementById("basket").clientWidth;
    setBasketX(w / 2 - basketW / 2);
    setShowReward([]);
    setSumResult(0);
    window.rewardList = [];
    setItemPopup(false);
    setShowClose(true);
    clearInterval(window.valentineGame);
    window.gamePlay();
    window.valentineGame = setInterval(() => {
      window.gamePlay();
    }, 7000);
  };

  useEffect(() => {
    resetGame();
  }, []);

  document.addEventListener("keydown", (e) => {
    if (e.keyCode === 37) {
      setMoveLeft(true);
    } else if (e.keyCode === 39) {
      setMoveRight(true);
    }
  });

  document.addEventListener("keyup", (e) => {
    if (e.keyCode === 37) {
      setMoveLeft(false);
    } else if (e.keyCode === 39) {
      setMoveRight(false);
    }
  });

  // useEffect(() => {
  //     console.log(showReward)
  // }, [showReward])

  const parameter = () => {
    let result = [];
    showReward.map((item, i) => {
      result.push(item.id);
    });
    return result;
  };

  const sendValentineGift = () => {
    socket.emit(
      "getvalentinegift",
      {
        id: parameter(),
      },
      (data) => {
        console.log("getvalentinegift:", data);
        dispatch(actionLoading(true));
        if (data.status) {
          // setSumResult(data.results.vlt[0].c1 + data.results.vlt[1].c1 + data.results.vlt[2].c1)
          setSumResult((c) => {
            data.results.vlt.map((item, i) => {
              c += item.c1;
            });
            return c;
          });
          setRewardDetail(data.results.vlt);
          setRewardPopup(true);
        }
      }
    );
  };

  return (
    <BG>
      {showClose ? (
        <Close
          onClick={() => {
            dispatch(actionPopup({ festivalGame: false, festival: true }));
            props.MainSoundBGM.volume(0.5);
            dispatch(actionFesMinigameBGM(false));
            dispatch(actionSetprofile({ FesBGMStatus: false }));
          }}
        />
      ) : (
        ""
      )}
      <Box id="box" style={{ visibility: "hidden" }} />
      <RewardItem
        item={1}
        style={{ visibility: "hidden", position: "absolute" }}
      />
      <RewardItem
        item={2}
        style={{ visibility: "hidden", position: "absolute" }}
      />
      <RewardItem
        item={3}
        style={{ visibility: "hidden", position: "absolute" }}
      />
      <ControlBox
        style={{ left: "1%" }}
        onMouseUp={() => setMoveLeft(false)}
        onMouseDown={() => setMoveLeft(true)}
        onTouchStart={() => setMoveLeft(true)}
        onTouchEnd={() => setMoveLeft(false)}
      />
      <ControlBox
        style={{ right: "1%", transform: "rotate(180deg)" }}
        onMouseUp={() => setMoveRight(false)}
        onMouseDown={() => setMoveRight(true)}
        onTouchStart={() => setMoveRight(true)}
        onTouchEnd={() => setMoveRight(false)}
      />
      <ControlInvisBox
        style={{ left: "0%" }}
        onTouchStart={() => setMoveLeft(true)}
        onTouchEnd={() => setMoveLeft(false)}
      />
      <ControlInvisBox
        style={{ right: "0%" }}
        onTouchStart={() => setMoveRight(true)}
        onTouchEnd={() => setMoveRight(false)}
      />
      <RewardBox>
        {t("collected")}
        <RewardCover>
          <RewardItem item={showReward.length > 0 ? showReward[0].no : ""} />
        </RewardCover>
        <RewardCover>
          <RewardItem item={showReward.length > 1 ? showReward[1].no : ""} />
        </RewardCover>
        <RewardCover>
          <RewardItem item={showReward.length > 2 ? showReward[2].no : ""} />
        </RewardCover>
        <RewardCover day={profile.valentine.day}>
          <RewardItem item={showReward.length > 3 ? showReward[3].no : ""} />
          {profile.valentine.day < 2 ? "Day 2" : ""}
        </RewardCover>
        <RewardCover day={profile.valentine.day} last={true}>
          <RewardItem item={showReward.length > 4 ? showReward[4].no : ""} />
          {profile.valentine.day < 3 ? "Day 3" : ""}
        </RewardCover>
      </RewardBox>
      <PlayZone id="playZone">
        {/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ) ? (
          ""
        ) : (
          <AnnounceTab>
            <AnnounceText>{t("vltgame press")}</AnnounceText>
            <AnnounceArrow />
            <AnnounceText>{t("vltgame or")}</AnnounceText>
            <AnnounceArrow style={{ transform: "rotate(180deg)" }} />
            <AnnounceText>{t("vltgame move")}</AnnounceText>
          </AnnounceTab>
        )}
        <Tween to={{ x: basketX, duration: 0.1 }}>
          <Basket id="basket">
            <Girl vltGet={getVLTItem} />
          </Basket>
        </Tween>
        {xArray.map((item, i) => {
          return item;
        })}
      </PlayZone>
      <Transition showPop={itemPopup} type="alert">
        <CollectRewardPopup
          item={showReward}
          closeClick={() => resetGame()}
          confirmClick={() => {
            dispatch(actionLoading(false));
            sendValentineGift();
          }}
        />
      </Transition>
      <Transition showPop={rewardPopup} type="alert">
        <RewardPopup
          amount={sumResult}
          detailClick={() => {
            setRewardDetailPop(true);
          }}
          closeClick={() => {
            dispatch(actionPopup({ festivalGame: false, festival: true }));
            props.MainSoundBGM.volume(0.5);
            dispatch(actionFesMinigameBGM(false));
            dispatch(actionSetprofile({ FesBGMStatus: false }));
          }}
        />
      </Transition>
      <Transition showPop={rewardDetailPop} type="alert">
        <RewardDetailsPopup
          closeClick={() => setRewardDetailPop(false)}
          item={rewardDetail}
        />
      </Transition>
    </BG>
  );
}
