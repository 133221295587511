import React, { useState, useContext } from "react";
import styled, { css } from "styled-components";
import Popup from "../Popup";
import Pig from "./Pig";
import Blackbox from "./BlackBox";
import { useTranslation } from "react-i18next";
import AlertMessage from "../Dialog/AlertMessage";
import { Transition, copyToClipboardOnTemp } from "../../config";
import { SocketContext } from "../../context/socket";
import {
  selectuserProfile,
  actionClickSound,
} from "../../Reducers/userProfileSlice";
import { useSelector, useDispatch } from "react-redux";
// import { selectaccountlSliceSlice } from "../../Reducers/getLastBankSlice";
import UploadSlip from "../DepositWithdrawal/UploadSlip";
import { actionselectedBanklSlice } from "../../Reducers/getLastBankSlice";
import breakpoints from "../../LandingPageComponents/breakpoints";
import { config } from "../../config";

const BankDetail = styled.div`
  user-select: none;
  float: left;
  text-align: left;
  position: relative;
  color: white;
  /* width: 33vw; */
`;

const BankLogo = styled.img`
  user-select: none;
  float: left;
  /* width: 4.8vw; */
  width: 3vw;
  margin-right: 1vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 10vw;
          margin-right: 0vw;
          padding: 2vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          width: 9vw;
        `}
`;

const BankLogodown = styled.img`
  user-select: none;
  float: left;
  width: 4.8vw;
  /* width: 3.8vw; */
  margin-right: 1vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 10vw;
          padding: 2vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          width: 8vw;
          margin-top: 0.2vw;
        `}
`;

const BankUsername = styled.div`
  user-select: none;
  float: left;
  font-size: 1.45vw;
  width: ${(props) => (props.TotalLength < 45 ? `65%;` : `57%;`)};
  font-size: ${(props) => (props.TotalLength < 45 ? `1.45vw;` : `0.9vw;`)};
  /* background-color: red; */
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 43vw;
          font-size: 3vw;
          margin-top: 2vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          width: 35vw;
          font-size: 1.5vw;
        `}
`;

const BankNumber1 = styled.div`
  user-select: none;
  float: left;
  /* font-size: 1.8vw; */
  font-size: 1.5vw;
  font-weight: 400;
  width: 65%;
  position: relative;
  display: flex;
  white-space: nowrap;
  /* background-color:green; */
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 4vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          font-size: 2.8vw;
        `}
`;

const BankNumber = styled.div`
  user-select: none;
  float: left;
  font-size: 1.15vw;
  font-weight: 400;
  width: 65%;
  position: relative;
  display: flex;
  white-space: nowrap;
  /* background-color:green; */
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 4vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          font-size: 3vw;
        `}
`;

const CopyBtn = styled.div`
  user-select: none;
  right: -4.1vw;
  width: 6.8vw;
  height: 2.8vw;
  padding: 1vw;
  margin-right: 6.5vw;
  float: right;
  position: absolute;
  background: url("../${config.theme}/btn_copy.png") no-repeat;
  background-size: 8.5vw;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 1.3vw;
    margin-left: 35%;
    margin-top: 0%;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 17.8vw;
          height: 8.8vw;
          background-size: 18.5vw;
          right: -1.1vw;
          margin-top: 1.5vw;
          p {
            font-size: 3vw;
            margin-left: 25%;
          }
        `
      : window.appHeight() > window.innerWidth &&
      css`
          width: 12.8vw;
          height: 6.8vw;
          background-size: 14.5vw;
          right: -1.1vw;
          p {
            font-size: 2.3vw;
          }
        `}
`;

const Clear = styled.div`
  user-select: none;
  clear: both;
`;

const Box = styled.div`
  user-select: none;
  position: absolute;
  width: 100vw;
  height: 100%; //fix
  align-items: center;
  justify-content: center;
  text-align: -webkit-center;
  display: flex;
  z-index: 30;
  top: 0;
  left: 0;
  @media ${breakpoints.device.line} {
    top: -6vw;
    left: -42%;
    width: 185%;
    height: 130%;
  }
`;

const Amount = styled.div`
  user-select: none;
  color: #ff7777;
  font-size: 0.85vw;
  margin-top: -0.3vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 3.5vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          font-size: 2.3vw;
        `}
`;

// const Amounttwo = styled.div`
//   user-select: none;
//   color: #ff4c00;
//   font-size: 0.85vw;
//   margin-top: 0.1vw;
//   display: flex;
//   width: 60.5%;
//   margin-left: 7.5vw;
// `;

const Amountthree = styled.div`
  user-select: none;
  color: #ff7777;
  font-size: 0.85vw;
  margin-top: 0.1vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 90%;
          margin: 0 auto;
          font-size: 3.5vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          font-size: 2.1vw;
        `}
`;

const Input = styled.input`
  user-select: none;
  padding-left: 1vw;
  position: absolute;
  width: 45vw;
  height: 5vw;
  color: #fff;
  font-size: 1.5vw;
  top: 12.5vw;
  left: 3vw;
  background: #000000;
  border: none;
  border-radius: 01vw;

  :focus-visible {
    outline: none;
  }
`;

const BoxItem = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 30;
  align-items: center;
`;

const WarningBox = styled.div`
  position: absolute;
  width: 18%;
  height: 11.5vw;
  left: 70%;
  top: 49%;
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;
  // theme ==================
  /* background: #192756;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #5DD8FF, inset 0px 0px 4vw rgba(66, 255, 232, 0.63);
  border: solid 0.2vw #BDEFFF; */
  // theme ==================
  border-radius: 2vw;
  @media ${breakpoints.device.line} {
    left: 61%;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          position: relative;
          width: 50%;
          height: fit-content;
          left: 1vw;
          top: 9vw;
          padding-bottom: 1vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          position: relative;
          width: 35%;
          height: fit-content;
          left: 1vw;
          top: 9vw;
          padding-bottom: 1vw;
        `}
`;

const Text = styled.div`
  color: white;
  font-size: ${(props) => (props.lang === "th" ? "0.9vw" : "0.8vw")};
  // font-weight: 400;
  width: 80%;
  margin-left: 2.8vw;
  margin-top: -0.4vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2.2vw;
          margin-left: -0.2vw;
          width: 0 auto;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          width: 0 auto;
          margin-left: 0vw;
          margin-top: -0.4vw;
          font-size: 1.5vw;
        `}
`;

const ButtonWarning = styled.div`
  cursor: pointer;
  position: absolute;
  width: 68%;
  height: 27%;
  left: 23%;
  top: 68%;
  background: linear-gradient(180deg, #ffc700 38.02%, #ff691f 100%);
  box-shadow: inset 0px 0px 4px 2px #ffc700;
  border-radius: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    color: #731907;
    font-size: 1.3vw;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          position: relative;
          top: 0vw;
          height: 6vw;
          p {
            color: #731907;
            font-size: 2.2vw;
          }
          left: 0vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          position: relative;
          left: 0vw;
          left: 0vw;
          height: 5vw;
          top: 0vw;
          p {
            font-size: 1.5vw;
          }
        `}
`;

const Textheader = styled.div`
  color: #bdb9d1;
  font-size: 1.45vw;
  h3 {
    color: #bdb9d1;
    font-size: 0.8354vw;
  }
  p {
    font-size: 0.8354vw;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 4vw;
          margin-top: 0vw;
          h3 {
            font-size: 4vw;
          }
          p {
            font-size: 3vw;
          }
        `
      : window.appHeight() > window.innerWidth &&
      css`
          font-size: 2.45vw;
          h3 {
            color: #bdb9d1;
            font-size: 1.8354vw;
          }
        `}
`;

const Contianer = styled.div`
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin-top: -10vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          margin-top: -5vw;
        `}
`;

const HR = styled.hr`
  display: none;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          display: block;
          width: 90%;
          border: 1px solid rgba(255, 255, 255, 0.2);
          margin: 3vw auto;
        `
      : window.appHeight() > window.innerWidth && css``}
`;

const TransferBox = styled.div`
  img {
    display: none;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 90%;
          display: flex;
          align-items: center;
          margin: 3vw auto;
          img {
            display: inline-block;
            width: 10%;
            margin: 0 3vw;
          }
        `
      : window.appHeight() > window.innerWidth && css``}
`;

const TransferDetail = styled.div`
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          text-align: left;
        `
      : window.appHeight() > window.innerWidth && css``}
`;

function NormalDepo(props) {
  const socket = useContext(SocketContext);
  const { t, i18n } = useTranslation();
  const [alertCopy, setAlertCopy] = useState(false);
  const profile = useSelector(selectuserProfile);
  const dispatch = useDispatch();
  const [popupUploadSlip, setPopupUploadSlip] = useState(false);
  const [emitError, setEmitError] = useState({ status: false, message: "" });

  function copyToClipboard(e) {
    copyToClipboardOnTemp(e);
    dispatch(actionClickSound());
    setAlertCopy(true);
    setTimeout(() => {
      setAlertCopy(false);
    }, 2000);
  }

  // useEffect(() => {

  //   socket.emit("getBankAgent", {}, (data) => {
  //     console.log("data.results.data", data)
  //     if (typeof data != "undefined") {
  //       if (typeof data.results != "undefined") {
  //         if (data.status) {
  //           setBankInfo(data.results.data);

  //         } else if (data.status === false) {
  //           setEmitError({ status: true, message: data.errorCode });
  //           setTimeout(() => {
  //             setEmitError({ status: false })
  //             props.closeClick()
  //           }, 3000)
  //         }
  //       }
  //     }
  //   });
  // }, []);

  // console.log("bankInfo.bank_account", bankInfo.bank_account)

  return (
    <Box>
      <BoxItem>
        <Popup
          style={{ marginTop: "2vw" }}
          width={
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "90vw"
              : window.appHeight() > window.innerWidth
                ? "71vw"
                : window.innerWidth / window.appHeight() > 2.15
                  ? "45vw"
                  : "45vw"
          }
          height={
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "125vw"
              : window.appHeight() > window.innerWidth
                ? "56vw"
                : window.innerWidth / window.appHeight() > 2.15
                  ? "30vw"
                  : props.bankInfo.bank_account === ""
                    ? "30vw"
                    : "30vw"
          }
          title={t("normal deposit")}
          closebuttonRight="-2vw"
          closebuttonTop="-2vw"
          closeClick={props.closeClick}
        >
          <Pig img={"../" + config.theme + "/icon_deposit.png"} />

          <Contianer>
            <Textheader>{t("please")}</Textheader>
            <HR />
            <Textheader>
              <h3>{t("account deposit")}</h3>
            </Textheader>
            <Amount>{t("ten baht")}</Amount>
            <Blackbox width="87%">
              <BankDetail style={{ display: "flex", alignItems: "center" }}>
                <BankLogo
                  src={
                    profile.bankShortName === ""
                      ? ""
                      : "../" +
                      config.theme +
                      "/bicon_" +
                      profile.bankShortName +
                      ".png"
                  }
                />
                <BankNumber1>
                  <div style={{ textTransform: "uppercase" }}>
                    {profile.bankShortName}
                  </div>
                  &nbsp;|&nbsp;
                  {typeof profile.bankAccount !== "undefined"
                    ? profile.bankAccount.length === 10
                      ? profile.bankAccount.slice(0, 3) +
                      "-" +
                      profile.bankAccount.slice(3, 4) +
                      "-" +
                      profile.bankAccount.slice(4, 9) +
                      "-" +
                      profile.bankAccount.slice(9, 10)
                      : profile.bankAccount
                    : ""}
                </BankNumber1>
              </BankDetail>
              <Clear />
            </Blackbox>
            <TransferBox>
              <img alt="" src={"../" + config.theme + "/newasset/img_arrow.png"} />
              <TransferDetail>
                <Textheader>
                  <h3>{t("transfer")}</h3>
                </Textheader>
                <Textheader>
                  <p>{t("check")}</p>
                </Textheader>
              </TransferDetail>
            </TransferBox>

            {props.bankInfo.bank_account === "" ? (
              ""
            ) : (
              <Blackbox width="87%">
                <BankDetail>
                  <BankLogodown
                    alt=""
                    src={
                      "../images/bicon_" +
                      (props.bankInfo.short_name ||
                        props.bankInfo.bank_short_name) +
                      ".png"
                    }
                  />
                  <BankUsername
                    TotalLength={
                      typeof props.bankInfo.account_name !== "undefined"
                        ? props.bankInfo.account_name.length
                        : ""
                    }
                  >
                    {props.bankInfo.account_name}
                  </BankUsername>
                  <BankNumber>
                    <div style={{ textTransform: "uppercase" }}>
                      {props.bankInfo.bank_short_name}
                    </div>
                    <div style={{ display: "none" }}>
                      <Input value={props.bankInfo.bank_account} readOnly />
                    </div>
                    |&nbsp;
                    {typeof props.bankInfo.bank_account !== "undefined"
                      ? props.bankInfo.bank_account.length === 10
                        ? props.bankInfo.bank_account.slice(0, 3) +
                        "-" +
                        props.bankInfo.bank_account.slice(3, 4) +
                        "-" +
                        props.bankInfo.bank_account.slice(4, 9) +
                        "-" +
                        props.bankInfo.bank_account.slice(9, 10)
                        : props.bankInfo.bank_account
                      : ""}
                  </BankNumber>
                </BankDetail>
                <CopyBtn
                  id={profile.brands_id + "-btn-copy-bank"}
                  onClick={() => {
                    copyToClipboard(props.bankInfo.bank_account);
                    try {
                      socket.emit(
                        "getbilog",
                        {
                          biname: "copydepositaccount_click",
                        },
                        (data) => {
                          console.log(data);
                        }
                      );
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                >
                  <p>{t("copy")}</p>
                </CopyBtn>

                <Clear />
              </Blackbox>
            )}
          </Contianer>

          <Amount>{t("text deposit one")}</Amount>
          <Amountthree>{t("text deposit two")}</Amountthree>
        </Popup>

        {props.bankInfo.qrCodeStatus ? (
          <WarningBox>
            <img
              alt=""
              src={"../" + config.theme + "/warning_icon.png"}
              style={{
                width:
                  window.appHeight() / window.innerWidth >
                    config.verticalSizeiphone
                    ? "29%"
                    : window.appHeight() > window.innerWidth
                      ? "10vw"
                      : window.innerWidth / window.appHeight() > 2.15
                        ? "42%"
                        : "42%",
                marginTop: "-20%",
                marginLeft:
                  window.appHeight() / window.innerWidth >
                    config.verticalSizeiphone
                    ? "0vw"
                    : window.appHeight() > window.innerWidth
                      ? "0vw"
                      : window.innerWidth / window.appHeight() > 2.15
                        ? "11%"
                        : "11%",
              }}
            />
            <Text lang={i18n.language}>{t("incase bank error")}</Text>
            <ButtonWarning
              onClick={() => {
                dispatch(actionselectedBanklSlice("account"));
                setPopupUploadSlip(true);
              }}
            >
              <p>{t("Upload slip here")}</p>
            </ButtonWarning>
          </WarningBox>
        ) : (
          ""
        )}
      </BoxItem>

      <Transition showPop={alertCopy} type="alert">
        <AlertMessage
          message={t("alert message copy")}
          onClick={() => {
            setAlertCopy(false);
          }}
        />
      </Transition>
      <Transition showPop={emitError.status} type="alert">
        <AlertMessage
          message={t(emitError.message)}
          onClick={() => {
            setEmitError({ status: false });
          }}
        />
      </Transition>
      <Transition showPop={popupUploadSlip} type="alert">
        <UploadSlip
          closeClick={() => {
            setPopupUploadSlip(false);
          }}
        />
      </Transition>
    </Box>
  );
}

export default NormalDepo;
