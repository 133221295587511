import { useEffect, useContext, useState } from "react";
import styled, { css } from "styled-components";
import { config } from "../../../config";
import { useTranslation } from "react-i18next";
import { SocketContext } from "../../../context/socket";
import moment from "moment";

const BGPopup = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 30;
  align-items: center;
  justify-content: center;
`;

const Popup = styled.div`
  user-select: none;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;
  border-radius: 1.5vw;
  position: relative;
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 46vw;
  height: fit-content;
  @media only screen and (max-width: 480px) and (orientation: portrait) {
    width: 90vw;
  }
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    width: 60vw;
  }
`;

const Title = styled.div`
  user-select: none;
  top: 0px;
  width: max-content;
  display: flex;
  color: white;
  height: 5.54vw;
  font-size: 2vw;
  line-height: 5vw;
  z-index: 0;
  font-weight: 400;
  text-align: center;
  padding: 0 5vw;
  align-items: center;
  margin: -0.2vw auto;
  background-clip: border-box, border-box, content-box;
  background-size: 8.69vw, 8.69vw, 1.07vw;
  background-position: top 0 left 0, top 0 right 0, top 0 right 0;
  background-repeat: no-repeat, no-repeat, repeat-x;
  background-image: url("../${config.theme}/popup_title_bg_left.png"),
    url("../${config.theme}/popup_title_bg_right.png"),
    url("../${config.theme}/popup_title_bg_center.png");
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          background-position: top 0 left -2px, top 0 right 0px, top 0 right 0;
          height: 8.54vw;
          background-size: 11.69vw, 11.69vw, 1.43vw;
          padding: 0px 7vw;
          font-size: 3vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          background-position: top 0 left -2px, top 0 right 0px, top 0 right 0;
          height: 6.54vw;
          background-size: 9.69vw, 9.69vw, 1.2vw;
          padding: 0px 6vw;
          font-size: 2.2vw;
        `}
`;

const Content = styled.div`
  width: 90%;
  padding: 5%;
`;

const BoxContainer = styled.div`
  width: 100%;
  background-color: #0c0b0e;
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CloseButton = styled.div`
  cursor: pointer;
  width: 35%;
  height: 4vw;
  background: linear-gradient(180deg, #924524 0%, #211f29 78.12%);
  box-shadow: inset 0px 0px 12.4074px #cb5039;
  border-radius: 0.5vw;
  margin-bottom: 5%;
  font-size: 1.5vw;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 480px) and (orientation: portrait) {
    font-size: 3vw;
    height: 8vw;
  }
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 2vw;
    height: 6vw;
  }
`;

const TableHead = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const HeadText = styled.div`
  color: #fff;
  font-size: 1.5vw;
  width: ${(props) => props.width};
  @media only screen and (max-width: 480px) and (orientation: portrait) {
    font-size: 3vw;
  }
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 2vw;
  }
`;

const Divider = styled.img`
  height: 4vw;
  @media only screen and (max-width: 480px) and (orientation: portrait) {
    height: 8vw;
  }
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    height: 6vw;
  }
`;

const TableItem = styled.div`
  width: 95%;
  background: #26242f;
  border-radius: 1vw;
  display: flex;
  align-items: center;
  margin: 0.5% 0 2% 0;
`;

const ItemDate = styled.div`
  width: 59%;
  font-size: 1.5vw;
  color: #8d8a99;
  text-align: center;
  @media only screen and (max-width: 480px) and (orientation: portrait) {
    font-size: 3vw;
  }
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 2vw;
  }
`;

const ItemStatus = styled.div`
  width: 35%;
  color: ${(props) => props.color};
  font-size: 1.5vw;
  text-align: end;
  @media only screen and (max-width: 480px) and (orientation: portrait) {
    font-size: 3vw;
  }
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 2vw;
  }
`;

export default function QRHistory(props) {
  const { t } = useTranslation();
  const socket = useContext(SocketContext);

  // ** States
  const [historyData, setHistoryDate] = useState([]);

  // ** props
  const { closeClick } = props;

  useEffect(() => {
    socket.emit("a2z-deposit", {}, (data) => {
      console.log("a2z-deposit", data);
      setHistoryDate(data.data);
    });
  }, []);

  const FormatDAte = (date) => {
    const formatted = moment(date).format("DD MMM YYYY hh:mm A");
    return formatted;
  };

  const StatusText = (status) => {
    const textlist = [
      "QR Deleted",
      "QR Pending",
      "QR Distributing",
      "QR Received",
      "QR Completed",
      "QR Rejected",
      "QR Expired",
    ];
    return textlist[status];
  };

  const StatusColor = (status) => {
    const colorlist = [
      "#8D8A99",
      "#FDA034",
      "#FDA034",
      "#37D768",
      "#37D768",
      "#FF7777",
      "#8D8A99",
    ];
    return colorlist[status];
  };

  return (
    <BGPopup>
      <Popup>
        <Title>{t("QR History Title")}</Title>
        <Content>
          <BoxContainer>
            <TableHead>
              <HeadText width="50%">{t("QR Date Created")}</HeadText>
              <Divider src="/images/divide_column_top.png" />
              <HeadText width="33%" style={{ textAlign: "center" }}>
                {t("status")}
              </HeadText>
            </TableHead>
            {historyData?.map((item, index) => {
              return (
                <TableItem key={`QR History #${index}`}>
                  <ItemDate>{FormatDAte(item.createdAt)}</ItemDate>
                  <Divider src="/images/divide_column.png" />
                  <ItemStatus color={StatusColor(item.depositStatus)}>
                    {t(StatusText(item.depositStatus))}
                  </ItemStatus>
                </TableItem>
              );
            })}
          </BoxContainer>
        </Content>
        <CloseButton onClick={closeClick}>{t("close")}</CloseButton>
      </Popup>
    </BGPopup>
  );
}
