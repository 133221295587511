import { useState, lazy, useContext, useEffect, useCallback } from "react";
import styled, { css, keyframes } from "styled-components";
import { useTranslation } from "react-i18next";
import { SocketContext } from "../../context/socket";
import { Transition } from "../../config";
import AlertMessage from "../Dialog/AlertMessage";
import LoadConFirm from "../Loading";
import Loading from "./Loading";
import Button from "../Button";
import $ from "jquery";
import "./qrcode.css";
import { useDropzone } from "react-dropzone";
import { config } from "../../config";

const BoxUpload = styled.div`
  // margin:auto;
  position: relative;
  width: 32vw;
  height: 19vw;
  margin-left: 2.8vw;
  margin-top: 1vw;
  background: rgba(0, 0, 0, 0.3);
  border: 1.9px dashed #7f7b95;
  // padding-top:1vw;
  border-radius: 9px;
  // z-index:71;
  user-select: none;
  background-repeat: no-repeat;
  background-position-x: center;
  min-width: 32vw;
  min-height: 19vw;
  //Portrait
  @media (orientation: portrait) {
    min-width: 65vw;
    min-height: 28vw;
    margin-top: 5vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    min-width: 65vw;
    min-height: 33vw;
    margin-top: 3vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          min-width: 65vw;
          min-height: 28vw;
          margin-top: 5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          min-width: 65vw;
          min-height: 33vw;
          margin-top: 3vw;
        `} */
`;

const Upload = styled.img`
  height: 6.5vw;
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  width: 7vw;
  // margin-left: 15vw;
  user-select: none;
  margin-top: 4vw;
`;

const Text = styled.div`
  color: #ff691f;
  font-size: 1.5vw;
  font-weight: 100;
  display: flex;
  justify-content: center;
  user-select: none;
  //Portrait
  @media (orientation: portrait) {
    font-size: 2vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 1.5vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 1.5vw;
        `} */
`;
const Title = styled.div`
  color: white;
  font-size: 1vw;
  text-align: center;
  user-select: none;
  //Portrait
  @media (orientation: portrait) {
    font-size: 2vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 1.5vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 1.5vw;
        `} */
`;

const CheckOrange = styled.div`
  position: absolute;
  width: 32.5vw;
  height: 3vw;
  margin-left: 3vw;
  margin-top: 0.5vw;
  background: #e13b00;
  border-radius: 5px;
  user-select: none;
  //Portrait
  @media (orientation: portrait) {
    width: 65.5vw;
    height: 7vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 1.5vw;
    width: 65.5vw;
    height: 5vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 65.5vw;
          height: 7vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 1.5vw;
          width: 65.5vw;
          height: 5vw;
        `} */
`;

const CheckGreen = styled.div`
  position: absolute;
  width: 32.5vw;
  height: 3vw;
  margin-left: 3vw;
  margin-top: 0.5vw;
  background: #7bd706;
  border-radius: 5px;
  user-select: none;
  //Portrait
  @media (orientation: portrait) {
    width: 65.5vw;
    height: 7vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 1.5vw;
    width: 65.5vw;
    height: 5vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 65.5vw;
          height: 7vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 1.5vw;
          width: 65.5vw;
          height: 5vw;
        `} */
`;
const Symbol = css`
  width: 10%;
  display: flex;
  height: 3.5vw;
  z-index: 7;
  background-size: contain;
  user-select: none;
  background-repeat: no-repeat;
  //Portrait
  @media (orientation: portrait) {
    height: 6.5vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    height: 5.5vw;
  }
`;
const SymbolOrange = styled.div`
  background-image: url("../${config.theme}/dailylogin_error.png");
  ${Symbol}
`;
const SymbolGreen = styled.div`
  background-image: url("../${config.theme}/dailylogin_correct.png");
  ${Symbol}
`;
const TextBox = styled.div`
  color: white;
  font-size: 1vw;
  font-weight: 100;
  margin-top: -2.7vw;
  margin-left: -13vw;
  user-select: none;
  //Portrait
  @media (orientation: portrait) {
    font-size: 2.5vw;
    font-weight: 100;
    margin-top: -5vw;
    margin-left: -29vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 2vw;
    font-weight: 100;
    margin-top: -4.7vw;
    margin-left: -37vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2.5vw;
          font-weight: 100;
          margin-top: -5vw;
          margin-left: -29vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 2vw;
          font-weight: 100;
          margin-top: -4.7vw;
          margin-left: -37vw;
        `} */
`;

const Box = styled.div`
  text-align: -webkit-center;
  margin-top: 1vw;
  margin-bottom: 1vw;
  height: 89%;
  // position:absolute;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  /* margin-top: 4.5vw; */
  margin-top: 3.5vw;
  user-select: none;
  //Portrait
  @media (orientation: portrait) {
    margin-top: 7vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    margin-top: 6.5vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin-top: 7vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          margin-top: 6.5vw;
        `} */
`;

function UploadSlipItem(props) {
  const [qrcodeMessage, setQrcodeMessage] = useState("");
  const socket = useContext(SocketContext);
  const { t, i18n } = useTranslation();
  const [isCheck, setIsCheck] = useState(false);
  const [check, setCheck] = useState(false);
  const [upload, setUpload] = useState(true);
  const [load, setLoad] = useState(false);
  const [imgError, setImgError] = useState(false);
  const [confirmLoad, setConfirmLoad] = useState(false);
  const [confirm, setComfirm] = useState(false);
  const [emitError, setEmitError] = useState({
    status: false,
    message: "",
    errorCode: "",
    errorMessage: "",
  });
  const [styleImg, setStyleImg] = useState({
    height: "15vw",
    display: "initial",
    objectFit: "contain",
  });
  const getDropZone = () => {
    let device =
      /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    if (device) {
      return {};
    } else {
      return getRootProps();
    }
    // ? getRootProps()
    // : {};
  };

  // const [check, setCheck] = useState(false);

  // useEffect(() => {
  //     // setTimeout(() => {
  //     // console.log("test")
  //     // console.log("qrcodeMessage", qrcodeMessage)
  //     // window.qr('fileUpload', "imgreader", setQrcodeMessage, setIsCheck, setUpload, setLoad, setIsCheck)
  //     // })
  //     // setIsCheck(true)
  //     // setCheck(true)
  // }, [])

  useEffect(() => {
    setStyleImg({ ...styleImg, display: "flex" });

    if (qrcodeMessage !== "") {
      console.log("qrcodeMessage", qrcodeMessage);
      // setUpload(false)
      // setComfirm(true)
      // setLoad(true)
      // setTimeout(() => {
      //     setLoad(false)
      // }, 850)
      if (qrcodeMessage !== "error") {
        // setComfirm(true)
        setCheck(true);
      } else if (qrcodeMessage === "error") {
        setCheck(false);
        setTimeout(() => {
          setImgError(true);
          setTimeout(() => {
            setImgError(false);
          }, 1000);
        }, 800);
      }
    }
  }, [qrcodeMessage]);

  const onDrop = useCallback((acceptedFiles) => {
    // console.log("ischeck", isCheck)
    setTimeout(() => {
      window.qrdrop(
        acceptedFiles,
        "imgreader",
        setQrcodeMessage,
        setIsCheck,
        setLoad,
        setUpload,
        setIsCheck,
        setCheck
      );
      //(fileInput, imgId, setQrcodeMessage, setStatus, setLoad, setUpload, setCheck)
    }, 900);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  useEffect(() => {
    console.log("qrcodeMessage", qrcodeMessage);
  }, [load]);

  return (
    <>
      <BoxUpload
        id="reader"
        {...getDropZone()}
        onClick={() => {
          $("#fileUpload").trigger("click");

          // setLoad(true)
          // setUpload(false)
        }}
      >
        <input
          type="file"
          style={{ display: "none" }}
          id="fileUpload"
          accept="image/*"
          {...getInputProps()}
        />

        {
          upload ? (
            <>
              <Upload src={"../../" + config.theme + "/ico_upload.png"} />
              <Text>{t("Drag your file or click Select File.")}</Text>
              <Title>{t("file support")}</Title>
            </>
          ) : (
            ""
          )
          // <Loading ></Loading>
        }

        <Box>
          <img id="imgreader" style={styleImg} />
        </Box>
      </BoxUpload>
      {load ? <Loading /> : ""}
      {qrcodeMessage == "" ? (
        ""
      ) : (
        <>
          {isCheck == false ? (
            <CheckOrange>
              <SymbolOrange />
              <TextBox>{t("Invalid file format")}</TextBox>
            </CheckOrange>
          ) : (
            <CheckGreen>
              <SymbolGreen />
              <TextBox>{t("The file format is correct")}</TextBox>
            </CheckGreen>
          )}
        </>
      )}

      <ButtonBox>
        <Button
          label={t("cancel")}
          bClass="rbRed"
          width={
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "21vw"
              : window.appHeight() > window.innerWidth
              ? "16vw"
              : window.innerWidth / window.appHeight() > 2.15
              ? "13vw"
              : "13vw"
          }
          fontSize={
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "2.5vw"
              : window.appHeight() > window.innerWidth
              ? "1.7vw"
              : window.innerWidth / window.appHeight() > 2.15
              ? "1.15vw"
              : "1.15vw"
          }
          top="27.5%"
          onClick={() => {
            props.closeClick();
          }}
          style={{ userSelect: "none", padding: 0 }}
        />
        {check === false ? (
          <Button
            label={t("confirm")}
            bClass="rbGray"
            width={
              window.appHeight() / window.innerWidth > config.verticalSizeiphone
                ? "21vw"
                : window.appHeight() > window.innerWidth
                ? "16vw"
                : window.innerWidth / window.appHeight() > 2.15
                ? "13vw"
                : "13vw"
            }
            fontSize={
              window.appHeight() / window.innerWidth > config.verticalSizeiphone
                ? "2.5vw"
                : window.appHeight() > window.innerWidth
                ? "1.7vw"
                : window.innerWidth / window.appHeight() > 2.15
                ? "1.15vw"
                : "1.15vw"
            }
            top="27.5%"
            // onClick={() => {
            //   setDepoPop(true)
            //   handleConfirm(totalAmount)

            // }}
            style={{ userSelect: "none", padding: 0 }}
          />
        ) : (
          <Button
            label={t("confirm")}
            bClass="rbGreen"
            width={
              window.appHeight() / window.innerWidth > config.verticalSizeiphone
                ? "21vw"
                : window.appHeight() > window.innerWidth
                ? "16vw"
                : window.innerWidth / window.appHeight() > 2.15
                ? "13vw"
                : "13vw"
            }
            fontSize={
              window.appHeight() / window.innerWidth > config.verticalSizeiphone
                ? "2.5vw"
                : window.appHeight() > window.innerWidth
                ? "1.7vw"
                : window.innerWidth / window.appHeight() > 2.15
                ? "1.15vw"
                : "1.15vw"
            }
            top="27.5%"
            onClick={() => {
              setConfirmLoad(true);
              //   setDepoPop(true)
              //   handleConfirm(totalAmount)
              // console.log("test")
              socket.emit(
                "scanqrcode",
                {
                  qrcode: qrcodeMessage,
                  agent_banks_id: props.bankId,
                },
                (data) => {
                  console.log("bankId", data);
                  if (typeof data != "undefined") {
                    if (data.status === true) {
                      setConfirmLoad(false);
                      setComfirm(true);
                      setTimeout(() => {
                        setComfirm(false);
                        props.closeClick();
                      }, 3000);
                    } else if (data.status === false) {
                      // setTimeout(() => {
                      setConfirmLoad(false);
                      setCheck(false);
                      setEmitError({ status: true, message: data.errorCode });
                      setTimeout(() => {
                        setEmitError(false);
                      }, 3000);
                      // }, 700)

                      // console.log("setEmitError", emitError)
                    }
                  }
                }
              );
            }}
            style={{ userSelect: "none", padding: 0 }}
          />
        )}
        {confirmLoad === false ? "" : <LoadConFirm />}
      </ButtonBox>

      <Transition showPop={emitError.status} type="alert">
        <AlertMessage
          message={t(emitError.message)}
          onClick={() => {
            setEmitError({ status: false });
          }}
        />
      </Transition>

      <Transition showPop={imgError} type="alert">
        <AlertMessage
          message={t("รูปแบบไฟล์ผิด")}
          onClick={() => {
            setImgError(false);
          }}
        />
      </Transition>

      <Transition showPop={confirm} type="alert">
        <AlertMessage
          message={t("upload slip success")}
          onClick={() => {
            setImgError(false);
          }}
        />
      </Transition>
    </>
  );
}
export default UploadSlipItem;
