import { createSlice } from "@reduxjs/toolkit";
import { socket } from "../context/socket";

export const popupSlice = createSlice({
  name: "popup",
  initialState: {
    status: {
      mission: false,
      diamondExchange: false,
      diamondShop: false,
      register: false,
      changepassword: false,
      depositWithdrawal: false,
      inviteFriend: false,
      miniGame: false,
      email: false,
      guestCheck: false,
      bankCheck: false,
      registBankAccount: false,
      pinCode: false,
      search: false,
      cashback: false,
      iframe: false,
      event: false,
      festival: false,
      festivalGame: false,
      message: "",
      url: "",
      alertNoti: false,
      deposit: false,
      avatarLogo: false,
      guestUser: false,
      levelRewards: false,
      condition: false,
      selectdecimal: false,
      decimal: false,
      selectnormal: false,
      normaldeposit: false,
      withdrawaltopup: false,
      withdrawal: false,
      withdrawalError: false,
      withdrawalConfirm: false,
      confirmWithdrawal: false,
      alertCashback: false,
      forgotPassword: false,
      login: false,
      selectprofile: false,
      setOTPPage: false,
      setPinCodePage: false,
      accountOtp: false,
      confirmPopup: false,
      eventBanner: false,
      promotion: false,
      createAccount: false,
      dailyLogin: false,
    },
    popupList: [],
    message: {
      alertNoti: "",
    },
  },
  reducers: {
    actionPopup: (state, action) => {
      state.status = { ...state.status, ...action.payload };
    },
    actionPopupMessage: (state, action) => {
      state.message = { ...state.message, ...action.payload };
    },
    actionPopupList: (state, action) => {
      state.popupList = action.payload;
    },
    actionUpdatePopup: (state, action) => {
      socket.emit(
        "updatepopup",
        {
          no: 1,
        },
        (data) => {
          console.log("updatepopup", data);
        }
      );
    },
  },
});

export const {
  actionPopup,
  actionPopupMessage,
  actionPopupList,
  actionUpdatePopup,
} = popupSlice.actions;
export const selectPopup = (state) => state.popup.status;
export const selectPopupMessage = (state) => state.popup.message;
export const selectPopupList = (state) => state.popup.popupList;
export default popupSlice.reducer;
