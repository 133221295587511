import React, { useState, useContext, useEffect } from "react";
import { Transition, config } from "../../config";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import AlertMessage from "../Dialog/AlertMessage";
import { SocketContext } from "../../context/socket";
import breakpoints from "../../LandingPageComponents/breakpoints";
import {
  selectuserProfile,
  actionCloseSound,
} from "../../Reducers/userProfileSlice";
import { actionLoading } from "../../Reducers/loadingSlice";
import Button from "../Button";
import ConFirmPopUp from "../Dialog/ConfirmPopup";
import DropDownFilter from "./DropDownFilter";
import FlashNoc from "../Flash";
import {
  setFloatingValue,
  setFloatingStatus,
  setFloatingLabel,
} from "../../Reducers/floatingInputSlice";
import Popup from "../Popup";

const BGPopup = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 70;
  align-items: center;
  justify-content: center;
  @media ${breakpoints.device.line} {
    width: 145%;
    height: 620%;
    left: -22%;
  }
`;

const CloseBtn = styled.img`
  user-select: none;
  position: absolute;
  width: 4vw;
  height: 4vw;
  right: -3.5%;
  top: -1.5vw;
  cursor: pointer;

  @media only screen and (max-width: 480px) and (orientation: portrait) {
    width: 9vw;
    height: 9vw;
    right: -3.5%;
    top: -3.5vw;
  }
`;

const BgClose = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  background-color: transparent;
  z-index: 20;
  left: 0%;
  top: 0%;
  position: fixed;
`;

const Container = styled.div`
  width: 90%;
  height: 87%;
  margin: 2vw auto;
  @media only screen and (max-width: 480px) and (orientation: portrait) {
    margin: 4vw auto;
  }
`;

const SearchBox = styled.div`
  user-select: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5vw auto;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.1vw;
  @media only screen and (max-width: 480px) and (orientation: portrait) {
    font-size: 3vw;
  }
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 2vw;
  }
`;

const Title = styled.p`
  font-size: 1.1vw;
  margin-bottom: 0.5vw;
  @media only screen and (max-width: 480px) and (orientation: portrait) {
    font-size: 3vw;
  }
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 2vw;
  }
`;

const ComplaintInput = styled.textarea`
  resize: none;
  width: 96%;
  height: 60%;
  background-color: #000;
  color: rgba(255, 255, 255, 1);
  outline: none;
  border: none;
  border-radius: 1vw;
  font-size: 1.1vw;
  padding: 2%;
  @media (orientation: portrait) {
    font-size: 3vw;
  }
  @media only screen and (max-width: 480px) and (orientation: portrait) {
    height: 80%;
  }
`;

const ButtonFix = styled(Button)`
  margin: -5.5vw auto 0 auto;
  cursor: ${(props) => (props.disabled ? "initial" : "pointer")};
  @media only screen and (max-width: 480px) and (orientation: portrait) {
    margin: -4vw auto 0 auto;
  }
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    margin: -5vw auto 0 auto;
  }
`;

const RemarkContainer = styled.div`
  width: 74vw;
  height: max-content;
  text-align: left;
  display: flex;
  position: relative;
  // background: green;
  align-items: flex-start;

  @media only screen and (max-width: 480px) and (orientation: portrait) {
    width: 77vw;
    top: 1vw;
  }
`;

const Remark = styled.div`
  // margin-top: 1.25vw !important;
  margin-left: 0.5vw;
  line-height: 2vw;
  color: white;
  // background: red;
  width: 120%;
  font-size: 1.2vw;
  ${(props) =>
    props.lang == "en" &&
    css`
      font-size: 1vw;
    `}

  @media only screen and (max-width: 480px) and (orientation: portrait) {
    font-size: 3vw;
    line-height: 2.4vw;
  }
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    width: 83%;
    font-size: 1.75vw !important;
    line-height: 2.4vw !important;
  }
`;

function Complaint(props) {
  const { t } = useTranslation();
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const [emitError, setEmitError] = useState({ status: false, message: "" });
  const [showConfirm, setShowConfirm] = useState(false);
  // const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  // const optionsDropDown = [
  //   "topic system",
  //   "topic games",
  //   "topic service & support",
  //   "topic etc",
  // ];
  const [optionsDropDown, setOptionsDropDown] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState("");
  const [topicId, setTopicId] = useState("");
  const [detail, setDetail] = useState("");

  useEffect(() => {
    socket.emit("complainchoose", {}, (data) => {
      // console.log("complainchoose", data.results);
      setOptionsDropDown(data.results);
    });
  }, []);

  return (
    <BGPopup>
      <BgClose onClick={props.closeClick} />
      <Popup
        title={t("complaint")}
        closebuttonRight="-3%"
        closebuttonTop={
          window.appHeight() > window.innerWidth && window.innerWidth <= 480
            ? "-2%"
            : "-4.5%"
        }
        width={
          window.appHeight() > window.innerWidth && window.innerWidth <= 480
            ? "93.87vw"
            : window.appHeight() > window.innerWidth
            ? "62vw"
            : "50vw"
        }
        height={
          window.appHeight() > window.innerWidth && window.innerWidth <= 480
            ? "138.13vw"
            : window.appHeight() > window.innerWidth
            ? "50vw"
            : "40vw"
        }
        closeClick={() => {
          props.closeClick();
          dispatch(actionCloseSound());
        }}
      >
        <Container>
          <SearchBox>
            <DropDownFilter
              options={optionsDropDown}
              topic={selectedTopic}
              setTopic={setSelectedTopic}
              setTopicId={setTopicId}
            />
          </SearchBox>
          <Title>{t("issues here")}</Title>
          <ComplaintInput
            // placeholder={t("details") + "..."}
            maxLength="255"
            onFocus={(e) => {
              window.isEdit = true;
              dispatch(setFloatingLabel(t("issues here")));
              dispatch(setFloatingStatus(true));
              dispatch(setFloatingValue(e.target.value));
            }}
            onBlur={() => {
              window.isEdit = false;
              dispatch(setFloatingStatus(false));
            }}
            onChange={(e) => {
              setDetail(e.target.value);
              dispatch(setFloatingValue(e.target.value));
            }}
          />
          <RemarkContainer>
            <FlashNoc />
            <Remark>{t("remark complaint")}</Remark>
          </RemarkContainer>
        </Container>

        <ButtonFix
          onClick={() => {
            //   try {
            //     socket.emit(
            //       "getbilog",
            //       {
            //         biname: "line_contact",
            //       },
            //       (data) => {
            //         console.log(data);
            //       }
            //     );
            //   } catch (error) {
            //     console.log(error);
            //   }
            //   openInNewTab(lineId);

            dispatch(actionLoading(false));
            // console.log(topicId, detail);
            socket.emit(
              "complain",
              {
                complain_id: topicId,
                feedback: detail,
              },
              (data) => {
                console.log("complain", data);
                if (
                  typeof data == "undefined" ||
                  typeof data.results == "undefined"
                ) {
                } else {
                  if (data.status == true) {
                    dispatch(actionLoading(true));
                    setShowConfirm(true);
                  }
                }
              }
            );
          }}
          bClass={
            detail.length > 0 && selectedTopic != "" ? "rbGreen" : "rbGray"
          }
          disabled={detail.length > 0 && selectedTopic != "" ? false : true}
          label={t("send")}
          width={
            window.appHeight() > window.innerWidth && window.innerWidth <= 480
              ? "40vw"
              : window.appHeight() > window.innerWidth
              ? "16vw"
              : window.innerWidth / window.appHeight() > 2.15
              ? "13vw"
              : "13vw"
          }
          fontSize={
            window.appHeight() > window.innerWidth && window.innerWidth <= 480
              ? "5vw"
              : window.appHeight() > window.innerWidth
              ? "1.7vw"
              : window.innerWidth / window.appHeight() > 2.15
              ? "1.15vw"
              : "1.15vw"
          }
        />
      </Popup>

      {showConfirm ? (
        <ConFirmPopUp
          style={{ zIndex: 80, whiteSpace: "pre-wrap" }}
          messageBottom={t("btn got it")}
          cancelClick={() => {
            props.closeClick();
          }}
        >
          {t("complaint complete")}
        </ConFirmPopUp>
      ) : null}

      <Transition showPop={emitError.status} type="alert">
        <AlertMessage
          message={t(emitError.message)}
          onClick={() => {
            setEmitError({ status: false });
          }}
        />
      </Transition>
    </BGPopup>
  );
}

export default Complaint;
