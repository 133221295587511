import React, { useEffect, useContext, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  selectuserProfile,
  actionClickSound,
} from "../../../../Reducers/userProfileSlice";
import { SocketContext } from "../../../../context/socket";
import Popup from "../../../Popup";
import NormalPromotionListItem from "./NormalPromotionListItem";

const Box = styled.div`
  position: fixed;
  width: 100vw;
  height: 100%; //fix
  top: 0%;
  left: 0%;
  text-align: -webkit-center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 70;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BG = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 20;
  align-items: center;
  justify-content: center;
  margin: 0%;
`;

const ContentBox = styled.div`
  width: 95%;
  height: 80%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow: hidden scroll;
  margin-top: 2%;
  ::-webkit-scrollbar {
    width: 1vw;
    height: 0vw;
    z-index: 10;
  }
  ::-webkit-scrollbar-thumb {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 10;
    position: absolute;
    width: 11px;
    height: 80px;
    background: linear-gradient(180deg, #e13b00 38.02%, #900000 100%);
    box-shadow: inset 0px 0px 4px 2px #ff814d;
    border-radius: 10px;
  }
  @media (orientation: portrait) {
    display: block;
  }
`;

function NormalPromotionList(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector(selectuserProfile);
  const socket = useContext(SocketContext);

  return (
    <Box>
      <BG onClick={props.closeClick} />
      <Popup
        title={t("select pro")}
        TitleSize={
          window.appHeight() > window.innerWidth && window.innerWidth <= 480
            ? "2.67vw"
            : window.appHeight() > window.innerWidth
            ? "4vw"
            : "2vw"
        }
        bgClass="Profile"
        width={
          window.appHeight() > window.innerWidth && window.innerWidth <= 480
            ? "95vw"
            : window.appHeight() > window.innerWidth
            ? "90vw"
            : "85vw"
        }
        height={
          window.appHeight() > window.innerWidth && window.innerWidth <= 480
            ? "149vw"
            : window.appHeight() > window.innerWidth
            ? "90vw"
            : "36vw"
        }
        closebuttonRight="-2.5vw"
        closebuttonTop="-2.5vw"
        closeClick={props.closeClick}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ContentBox>
            {props?.promotionInfo?.promotion?.map((item, i) => {
              return (
                item.front_status === 1 && (
                  <NormalPromotionListItem
                    UpdatePromotion={props.UpdatePromotion}
                    setFinalPop={props.setFinalPop}
                    setting={props?.promotionInfo?.setting}
                    promotion_id={props?.promotionInfo?.user?.promotion_id}
                    info={item}
                    key={"promotion's index: " + i}
                    closeClick={props.closeClick}
                  />
                )
              );
            })}
          </ContentBox>
        </div>
      </Popup>
    </Box>
  );
}
export default NormalPromotionList;
