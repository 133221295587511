import React from "react";
import styled from "styled-components";
import NumberFormat from "react-number-format";
import { config } from "../../config";

const Diamonds = styled.img`
  user-select: none;
  width: 100%;
  display: flex;
  height: 100%;
  position: relative;
  left: 0vw;
  top: 20%;
  margin: auto;
  -moz-margin-start: 0.35vw;
  z-index: 20;
  ${(props) =>
    props.isMission &&
    `
  top: 12%;
  `}
`;



const Amount = styled.div`
  user-select: none;
  position: relative;
  width: 85%;
  height: 21%;
  top: 0.5vw;
  left: 0vw;
  z-index: 20;
  display: flex;
  justify-content: center;
  background-color: black;
  border-radius: 5vw;
  background-size: contain;
  margin: auto;
  -moz-margin-start: 0.8vw;
`;

const Box = styled.div`
  user-select: none;
  width: 18vw;
  height: 15vw;
  position: relative;
  text-align: -webkit-center;
  margin: 0;
  font-size: 2.5vw;
  // background: red;
  p {
    color: white;
    margin-top: -1%;
    font-weight: 750;
    position: relative;
    margin-right: -0.28vw;
  }
  top: 0;
  left: 0;
`;

function RewardResult(props) {
  // console.log("props amount :", props.amount);

  let icon = "";
  // const [showPopup, setShowPopup] = useState(false);
  if (props.amount === 0) {
    icon = "../../" + config.theme + "/dshop_10000.png";
  } else if (props.amount > 0 && props.amount <= 100) {
    icon = "../../" + config.theme + "/dshop_200.png";
  } else if (props.amount >= 100 && props.amount <= 200) {
    icon = "../../" + config.theme + "/dshop_800.png";
  } else if (props.amount >= 201 && props.amount <= 300) {
    icon = "../../" + config.theme + "/dshop_2000.png";
  } else if (props.amount >= 301 && props.amount <= 1000) {
    icon = "../../" + config.theme + "/dshop_5000.png";
  } else if (props.amount <= 9000) {
    icon = "../../" + config.theme + "/dshop_10000.png";
  }

  // if (props.itemType == "song_1") {
  //     icon = "../" + config.theme + "/CHNY_event/song_1.png";
  // } else if (props.itemType == "song_2") {
  //     icon = "../" + config.theme + "/CHNY_event/song_2.png";
  // } else if (props.itemType == "song_3") {
  //     icon = "../" + config.theme + "/CHNY_event/song_3.png";
  // } else if (props.itemType == "song_4") {
  //     icon = "../" + config.theme + "/CHNY_event/song_4.png";
  // }

  return (
    <Box {...props}>
      {props.amount > -1 ? (
        <Diamonds src={icon} isMission={props.isMission} />
      ) : (
        ""
      )}
      <Amount>
        {props.amount > 0 ? (
          <p>
            +
            <NumberFormat
              value={props.amount}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={0}
              fixedDecimalScale={true}
            />
          </p>
        ) : (
          <p>{props.isMinigame ? 0 : "???"}</p>
        )}
      </Amount>
    </Box>
  );
}
export default RewardResult;
