import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import breakpoint from "../breakpoints";
// import Popup from "../../Components/FestivalPopup/alertReward";
// import { Transition, config } from "../../config";
const FooterContainer = styled.footer`
  background-image: url("../../lpimages/bg_content2.png");
  width: 100%;
  /* background-size: 102%; */
  background-position-x: center;
  background-repeat: no-repeat;
  padding-bottom: 3vw;
  background-color: #000;
  @media ${breakpoint.device.mobile} {
    background-size: 130%;
    background-image: url("../../lpimages/bg_content.png");
    // background-size: cover;
    background-position-x: center;
  }
`;

const BoxList = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 4vw;
  p {
    font-size: 1.04vw;
    color: #ffffff;
  }
  @media ${breakpoint.device.mobile} {
    width: 90%;
    display: block;
    text-align: start;
    padding: 5%;
    p {
      font-size: 4.83vw;
    }
  }
`;

const Box1 = styled.div`
  text-align: center;
  img {
    height: 3.59vw;
  }
  @media ${breakpoint.device.mobile} {
    margin-bottom: 5vw;
    img {
      height: 18.12vw;
    }
  }
`;

const Box2 = styled.div`
  @media ${breakpoint.device.mobile} {
    margin-bottom: 5vw;
  }
`;

const Box3 = styled.div`
  /* a:nth-child(5),
  a:nth-child(6) {
    margin-left: 2vw;
  } */
  @media ${breakpoint.device.mobile} {
    margin-bottom: 5vw;
    /* a:nth-child(5),
    a:nth-child(6) {
      margin-left: 5vw;
    } */
  }
`;

const Box4 = styled.div`
  > img {
    width: 2.71vw;
    margin-left: 0.4vw;
    cursor: pointer;
  }
  @media ${breakpoint.device.mobile} {
    margin-bottom: 5vw;
    > img {
      width: 12vw;
      margin: 1vw;
    }
  }
`;

const LinkMenu = styled(Link)`
  display: block;
  font-size: 1vw;
  color: #4e5476;
  align-self: center;
  text-decoration: none;
  /* text-decoration-line:none; */
  @media ${breakpoint.device.mobile} {
    font-size: 3vw;
  }
`;

const Detail = styled.div`
  background-color: #131622;
  font-size: 1vw;
  color: #4e5476;
  line-height: 3.125;
  text-align: center;
  p {
    height: 15vw;
  }
  a {
    color: #4e5476;
  }
  @media ${breakpoint.device.mobile} {
    font-size: 3vw;
    p {
      height: 35vw;
    }
  }
`;

const Bar = styled.div`
  background-color: #202332;
  height: 6.25vw;
  > img {
    height: 60%;
    margin: 1.2vw;
  }
  @media ${breakpoint.device.mobile} {
    height: 29vw;
    // padding-bottom: 15vw;
    > img {
      height: 40%;
      margin: 9vw 2vw;
    }
  }
`;

export const Footer = (props) => {
  const ScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <FooterContainer width={window.innerWidth} height={window.appHeight()}>
        <BoxList>
          <Box1>
            <img alt="" src="../../lpimages/spinix_normal_Logo.png" />
          </Box1>
          <Box2>
            <p>Help & Information</p>
            <LinkMenu to="/faq" onClick={ScrollToTop}>
              FAQ
            </LinkMenu>
            <LinkMenu to="/support" onClick={ScrollToTop}>
              Support
            </LinkMenu>
            <LinkMenu to="/terms-and-conditions" onClick={ScrollToTop}>
              Terms and Conditions
            </LinkMenu>
          </Box2>
          <Box3>
            <p>Navigation</p>
            <LinkMenu to="/" onClick={ScrollToTop}>
              Home
            </LinkMenu>
            <LinkMenu to="/live-casino" onClick={ScrollToTop}>
              Live Casino
            </LinkMenu>
            <LinkMenu to="/games" onClick={ScrollToTop}>
              Games
            </LinkMenu>
            {/* <LinkMenu to="/games" onClick={ScrollToTop}>
              Teasure Of aztec
            </LinkMenu>
            <LinkMenu to="/games" onClick={ScrollToTop}>
              Greedy mine
            </LinkMenu> */}
            <LinkMenu to="/ContentPromotion" onClick={ScrollToTop}>
              Promotions
            </LinkMenu>
          </Box3>
          <Box4>
            <p>Social Media</p>
            <img
              alt=""
              src="../../lpimages/footer_icon_fb.png"
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  "https://www.facebook.com/spinixthailand/",
                  "_blank"
                );
              }}
            />
            <img
              alt=""
              src="../../lpimages/footer_icon_yt.png"
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  "https://youtube.com/channel/UC5EGy1CHBKYKVAkQ2Bii6sA",
                  "_blank"
                );
              }}
            />
            <img
              alt=""
              src="../../lpimages/footer_icon_twitter.png"
              onClick={(e) => {
                e.preventDefault();
                window.open("https://twitter.com/Spinix_official", "_blank");
              }}
            />
          </Box4>
        </BoxList>
        <Detail>
          <Bar>
            <img alt="" src="../../lpimages/18Gambling.png" />
            <img alt="" src="../../lpimages/GamCare.png" />
            <img alt="" src="../../lpimages/SaferGambling.png" />
          </Bar>
          <p>
            © 2021 <a href="https://test.spxslot.xyz/">spxslot.xyz</a> All
            Rights Reserved. V1.0.000
          </p>
        </Detail>

        {/* <LeftBox>
          <LogoBox>
            <img src="../../lpimages/spinix_normal_Logo.png" />
            <img src="../../lpimages/footer_icon_fb.png"
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  'https://www.facebook.com/spinixthailand/',
                  '_blank'
                );
              }}
            />
            <img src="../../lpimages/footer_icon_yt.png"
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  'https://youtube.com/channel/UC5EGy1CHBKYKVAkQ2Bii6sA',
                  '_blank'
                );
              }}
            />
          </LogoBox>

          <p>2021© Spinix™ ALL RIGHTS RESERVED v1.0.000</p>
        </LeftBox> */}
      </FooterContainer>
      {/* <Transition showPop={click} type="alert">
        <ConFirmPopUp
          imgright="../../images/CHNY_event/gold_titleprop.png"
          imgleft="../../images/CHNY_event/gold_titleprop.png"
          ImgMarginTopLeft="-1vw"
          ImgMarginTopRight="-1vw"
          ImgMarginLeft="28vw"
          ImgMarginRight="8vw"
          closebuttonRight="-3%"
          title="คุณได้รับ"
          amount="2000"
          closeClick={() => {
            // setClick(false);
            setTest(true)
          }}
        />
      </Transition>
      <Transition showPop={test} type="alert">
        <Bonus
          imgright="../../images/CHNY_event/gold_titleprop.png"
          imgleft="../../images/CHNY_event/gold_titleprop.png"
          ImgMarginTopLeft="-1vw"
          ImgMarginTopRight="-1vw"
          ImgMarginLeft="28vw"
          ImgMarginRight="8vw"
          closebuttonRight="-3%"
          title="คุณได้รับ"
          amount="2000"
          closeClick={() => {
            // setClick(false);
            setTest(false)
          }}
        />
      </Transition> */}
    </>
  );
};

export default Footer;
