import { createSlice } from "@reduxjs/toolkit";
export const getlastbank = createSlice({
    name: "lastBankSlice",
    initialState: {

        account_system: [],
        floating_system: [],
        select_Bank: "account"
    },
    reducers: {

        actionaccountlSlice: (state, action) => {
            state.account_system = action.payload;
        },
        actionfloatinglSlice: (state, action) => {
            state.floating_system = action.payload;
        },
        actionselectedBanklSlice: (state, action) => {
            state.select_Bank = action.payload;
        },
    },
});

export const { actionaccountlSlice, actionfloatinglSlice, actionselectedBanklSlice } = getlastbank.actions;
export const selectaccountlSliceSlice = (state) => state.lastBankSlice.account_system;
export const selectfloatingBankSlice = (state) => state.lastBankSlice.floating_system;
export const selectselectedBankSlice = (state) => state.lastBankSlice.select_Bank;

export default getlastbank.reducer;
