import { createSlice } from "@reduxjs/toolkit";
export const gameListSlice = createSlice({
  name: "gameList",
  initialState: {
    gameList: [],
  },
  reducers: {
    actionGameList: (state, action) => {
      state.gameList = action.payload;
    },
  },
});

export const { actionGameList } = gameListSlice.actions;
export const selectGameList = (state) => state.gameList.gameList;
export default gameListSlice.reducer;
