import "../styles.css";
import Button from "./Button";
import React from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { config } from "../config";
const BgClose = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  background-color: transparent;
  z-index: 20;
  left: 0%;
  top: 0%;
  position: fixed;
`;

const BGPopup = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  position: fixed;
  left: 7%;
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 70;
  align-items: center;
  justify-content: center;
`;

const Popupcomplete = styled.div`
  user-select: none;
  // background-image: url("/images/popup_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;
  // theme ==================
  /* background: #192756;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #5DD8FF, inset 0px 0px 4vw rgba(66, 255, 232, 0.63);
  border: solid 0.2vw #BDEFFF; */
  // theme ==================
  border-radius: 1.5vw;
  position: relative;
  z-index: 100;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  // เพิ่ม padding ด้านข้างกรณี title ทะลุ start below ========
  padding: 0 2vw;
  width: 23vw;
  height: 16vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 58vw;
          height: 34vw;
          left: 3vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 44vw;
          height: 24vw;
          left: 1vw;
        `}
`;
const Pigcomplete = styled.img`
  user-select: none;
  width: 21vw;
  margin-top: -6vw;
  margin-left: 1.8vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 27vw;
          margin-top: -11vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 27vw;
          margin-top: -11vw;
        `}
`;
const Complete = styled.div`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  color: #bdb9d1;
  font-size: 1.5vw;
  text-align: center;
  top: -2vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 3.5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 2.5vw;
        `}
`;

const Textwait = styled.div`
  user-select: none;
  position: relative;
  color: #bdb9d1;
  font-size: 0.9vw;
  top: -1.8vw;
  text-align: center;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2.5vw;
          top: 0.5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 2vw;
        `}
`;

const Box = styled.div`
  margin-top: 15vw;
  position: relative;
  z-index: 110;
  left: -20vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin-top: 33vw;
          left: -39vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          margin-top: 24vw;
          left: -31vw;
        `}
`;

function WithdrawalComplete(props) {
  const { t } = useTranslation();

  return (
    <BGPopup>
      <BgClose onClick={props.closeClick} />
      <>
        <Popupcomplete>
          <Pigcomplete
            src={"../" + config.theme + "/withdrawal_complete.png"}
          />
          <Complete>{t("complete withdrawal")}</Complete>
          <Textwait>{t("take process")}</Textwait>
        </Popupcomplete>
        <Box>
          <Button
            bClass="rbGreen"
            label={t("confirm OK")}
            width={
              window.appHeight() / window.innerWidth > config.verticalSizeiphone
                ? "21vw"
                : window.appHeight() > window.innerWidth
                ? "16vw"
                : window.innerWidth / window.appHeight() > 2.15
                ? "13vw"
                : "13vw"
            }
            fontSize={
              window.appHeight() / window.innerWidth > config.verticalSizeiphone
                ? "2.5vw"
                : window.appHeight() > window.innerWidth
                ? "1.7vw"
                : window.innerWidth / window.appHeight() > 2.15
                ? "1.15vw"
                : "1.15vw"
            }
            onClick={() => {
              props.closeClick();
            }}
          ></Button>
        </Box>
      </>
    </BGPopup>
  );
}
export default WithdrawalComplete;
