import React from "react";
import styled, { css } from "styled-components";
import { config } from "../../config";

const PigImg = styled.div`
  user-select: none;
  /* width: 15.89vw; */
  /* height:12.89vw; */
  width: 13vw;
  height: 11vw;
  top: -3vw;
  /* left: 40%; */
  position: relative;
  margin-bottom: 1vw;
  margin: 0 auto;
  margin-top: -10.5vw;
  z-index: 10;
  display: block;
  text-align: center;
  justify-content: center;
  /* left: 15vw; */
  img {
    width: 100%;
    height: 100%;
  }

  ${(props) => css`
    top: ${props.top};
    // height: ${props.height};
  `}
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? `
     width: 21vw;
    height: 19vw;
    top: -15vw;
 `
      : window.appHeight() > window.innerWidth &&
      `
 width: 21vw;
    height: 19vw;
    top: -10vw;
 `}
`;

function Pig(props) {
  return (
    <PigImg {...props}>
      <img alt="" src={props.img} />
    </PigImg>
  );
}

export default Pig;
