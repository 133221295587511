import React, { useState } from "react";
import styled from "styled-components";
import Button from "./Button";
import { useTranslation } from "react-i18next";
import { Transition } from "../config";
import { useDispatch } from "react-redux";
import { socket } from "../context/socket";
import { actionSetprofile } from "../Reducers/userProfileSlice";
import AlertMessage from "./Dialog/AlertMessage";
import { config } from "../config";
const Popup = styled.div`
  user-select: none;
  // background-image: url('/${config.theme}/popup_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;
  /* background: #192756; */
  /* box-shadow: inset 0vw 0vw 0.8vw 0.2vw #5DD8FF, inset 0px 0px 4vw rgba(66, 255, 232, 0.63); */
  /* border: solid 0.2vw #BDEFFF; */
  border-radius: 2vw;
  position: relative;
  z-index: 70;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 13vw;
  width: 42vw;
`;

const BGPopup = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%;
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 70;
  align-items: center;
  justify-content: center;
`;

const Image = styled.div`
  user-select: none;
  display: flex;
`;

const BButtom = styled.div`
  user-select: none;
  display: flex;
`;

const PopupCon = styled.div`
  user-select: none;
  display: flex;
  flex-direction: column;
  min-width: 25vw;
  // height: max-content;
  width: max-content;
  // theme ==================
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;
  border-radius: 2vw;
  padding: 2.5vw;
  // theme ==================
  // background-color: #2f2d3b;
  border-radius: 4vw;
  // border: solid 3vw;
  // border-image-slice: 50;
  // border-image-source: url("../${config.theme}/popup_frame_mini.png");
  justify-content: center;
  text-align: center;
  align-items: center;
  color: #bcb8d0;
  font-size: 2vw;
  position: absolute;
  top: 13vw;
  left: 26vw;
`;

const BConfirm = styled.div`
  user-select: none;
  position: absolute;
  z-index: 70;
  display: flex;
  margin-top: -2vw;
  top: 58%;
  left: 40vw;
`;

const AlertBox = styled.div`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100vw;
  height: 100%;
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  z-index: 70;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
`;

function FtuePopup01(props) {
  const { t } = useTranslation();
  const [confirmPopUp, setConfirmPopUp] = useState(false);
  const dispatch = useDispatch();
  const [emitError, setEmitError] = useState({ status: false, message: "" });

  return (
    <>
      <BGPopup>
        <Popup
          style={{
            width:
              window.innerWidth / window.appHeight() > 2.15 ? "62vw" : "62vw",
            height:
              window.innerWidth / window.appHeight() > 2.15 ? "35vw" : "35vw",
            top: "-2vw",
          }}
        >
          <Image>
            <img alt=""
              src={"../" + config.theme + "/FtuePopup01.jpg"}
              style={{
                position: "absolute",
                width: "59vw",
                marginTop: "-16vw",
                left: "1.5vw",
                borderRadius: "1vw",
              }}
            />
          </Image>
          <BButtom>
            <Button
              onClick={() => {
                try {
                  dispatch(actionSetprofile({ first_login: false }));
                  props.closeClick();
                  socket.emit(
                    "getFreeBonusPopup",
                    {
                      promotion: false, //true = รับโปร , false = ไม่รับโปร
                    },
                    (body) => {
                      if (typeof body != "undefined") {
                        if (body.status) {
                        } else if (body.status === false) {
                          setEmitError({
                            status: true,
                            message: body.errorCode,
                          });
                          setTimeout(() => {
                            setEmitError({ status: false });
                          }, 3000);
                        }
                      }
                    }
                  );
                } catch (error) {
                  setEmitError({ status: true, message: error.message });
                  setTimeout(() => {
                    setEmitError({ status: false });
                  }, 3000);
                }
              }}
              bClass="rbGray"
              label={t("ftue no")}
              width={
                window.appHeight() / window.innerWidth >
                  config.verticalSizeiphone
                  ? "30vw"
                  : window.appHeight() > window.innerWidth
                    ? "30vw"
                    : window.innerWidth / window.appHeight() > 2.15
                      ? "20vw"
                      : "20vw"
              }
              fontSize={
                window.appHeight() / window.innerWidth >
                  config.verticalSizeiphone
                  ? "3vw"
                  : window.appHeight() > window.innerWidth
                    ? "3vw"
                    : window.innerWidth / window.appHeight() > 2.15
                      ? "2vw"
                      : "2vw"
              }
              z-index="10"
              style={{ top: "18.8vw", paddingLeft: "1vw" }}
            />
          </BButtom>

          <BButtom>
            <Button
              onClick={() => {
                try {
                  setConfirmPopUp(true);
                  socket.emit(
                    "getFreeBonusPopup",
                    {
                      promotion: true, //true = รับโปร , false = ไม่รับโปร
                    },
                    (body) => {
                      if (typeof body != "undefined") {
                        if (body.status) {
                        } else if (body.status === false) {
                          setEmitError({
                            status: true,
                            message: body.errorCode,
                          });
                          setTimeout(() => {
                            setEmitError({ status: false });
                          }, 3000);
                        }
                      }
                    }
                  );
                } catch (error) {
                  setEmitError({ status: true, message: error.message });
                  setTimeout(() => {
                    setEmitError({ status: false });
                  }, 3000);
                }
              }}
              bClass="rbGreen"
              label={t("ftue yes")}
              width={
                window.appHeight() / window.innerWidth >
                  config.verticalSizeiphone
                  ? "30vw"
                  : window.appHeight() > window.innerWidth
                    ? "30vw"
                    : window.innerWidth / window.appHeight() > 2.15
                      ? "20vw"
                      : "20vw"
              }
              fontSize={
                window.appHeight() / window.innerWidth >
                  config.verticalSizeiphone
                  ? "3vw"
                  : window.appHeight() > window.innerWidth
                    ? "3vw"
                    : window.innerWidth / window.appHeight() > 2.15
                      ? "2vw"
                      : "2vw"
              }
              style={{ top: "18.8vw", paddingLeft: "1vw" }}
            />
          </BButtom>
        </Popup>
      </BGPopup>

      <Transition showPop={confirmPopUp} type="st2b">
        <PopupCon>
          <AlertBox>
            <Popup>
              <p>{t("ftue alert")}</p>
            </Popup>

            <BConfirm>
              <Button
                onClick={() => {
                  props.closeClick();
                  dispatch(actionSetprofile({ first_login: false }));
                }}
                bClass="rbGreen"
                label={t("ok")}
                width="20vw"
                fontSize="2vw"
                style={{ paddingLeft: "1vw" }}
              />
            </BConfirm>
          </AlertBox>
        </PopupCon>
      </Transition>

      <Transition showPop={emitError.status} type="alert">
        <AlertMessage
          message={t(emitError.message)}
          onClick={() => {
            setEmitError({ status: false });
          }}
        />
      </Transition>
    </>
  );
}

export default FtuePopup01;
