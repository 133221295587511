import React, { useState, useContext, useEffect } from "react";
import styled, { css, keyframes } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
// import { actionProvider } from "../../Reducers/providerSlice";
import { actionGamePlay } from "../../Reducers/gamePlaySlice";
import {
  actionGameCategory,
  actionIsCategory,
  // actionIsProvider,
  actionSetProviders,
} from "../../Reducers/gameCategorySlice";
import ConfirmPopup from "../Dialog/ConfirmPopup";
import { actionLoading } from "../../Reducers/loadingSlice";
import { actionAlert } from "../../Reducers/alertSlice";
import { selectuserProfile } from "../../Reducers/userProfileSlice";
import { actionPopup } from "../../Reducers/popupSlice";
import { actionConfirmPopup } from "../../Reducers/confirmPopup";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SocketContext } from "../../context/socket";
import { Transition, getFullUrl } from "../../config";
// import AlertMessage from "../Dialog/AlertMessage";
// import ConfirmPopup from "../Dialog/ConfirmPopup";
import { getJWT } from "../../config";
import { selectGameList, actionGameList } from "../../Reducers/gameListSlice";
import breakpoints from "../../LandingPageComponents/breakpoints";
import GameImg from "./GameImg";
import { config } from "../../config";
// import CashbackPopup from "../CashbackPopup";
import WinRateBadge from "./WinRateBadge";
import WinbadgeBig from "./WinbadgeBig";
import { selectWinRateSlice } from "../../Reducers/winRateSlice";
import {
  actionIsProvider,
  actionSetProvider,
} from "../../Reducers/providerSlice";
// import Alert from "../Dialog/Alert";

// const CategoryFishing = lazy(() => import("./CategoryFishing"));

const GameItemBox = styled.div`
  user-select: none;
  width: max-content !important;
  height: calc(100vw * 0.3 * 0.85);
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  margin-top: calc(100vw * 0.005);
  margin-bottom: calc(100vw * 0.005);
  position: relative;
  outline: none;
  > a img:nth-child(1) {
    height: 100%;
    ${(props) =>
      props.width / props.height > 2.15
        ? css`
            height: 65%;
          `
        : props.width / props.height > 1.7
        ? css`
            height: 85%;
          `
        : ""}
  }
  .fav {
    width: 3vw;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
  @media ${breakpoints.device.line} {
    top: 3vw;
  }

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          .fav {
            width: 6vw;
          }
          /* margin-left: auto; */
          /* margin-right: auto; */
          margin-top: auto;
          margin-bottom: auto;
          height: auto;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          height: 33vw;
          .fav {
            width: 4vw;
          }
        `}
`;
const badge = keyframes`
  0% { filter: brightness(90%); transform: scale(1);}
  100% {filter: brightness(130%); transform: scale(1.1);}
`;
const Badge = styled.div`
  user-select: none;
  > img {
    width: 6vw;
    position: absolute;
    top: 0;
    // animation-name: ${badge};
    animation-duration: 0.4s;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }
  p {
    font-size: 1.2vw;
    font-weight: 400;
    color: #fff;
    position: absolute;
    top: calc(100vw * 0.003);
    left: calc(100vw * 0.024);
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          > img {
            width: 8.5vw;
          }
          p {
            font-size: 2vw;
            text-align: center;
            width: 19%;
          }
        `
      : window.appHeight() > window.innerWidth &&
        css`
          > img {
            width: 7vw;
          }
          p {
            font-size: 1.8vw;
            line-height: 2.5vw;
            text-align: center;
            width: 16%;
          }
        `}
`;

function GameItem(props) {
  const socket = useContext(SocketContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(0);
  const history = useHistory();
  // const [isLoading, setIsLoading] = useState(false);
  const profile = useSelector(selectuserProfile);
  const [delayOpen, setDelayOpen] = useState(false);
  const [isFavorite, setIsFavorite] = useState(
    profile?.favourite?.indexOf(props.gameId) > -1
  );
  // const [sport, setSport] = useState(false);
  const [emitError, setEmitError] = useState({ status: false, message: "" });
  // const gameCategory = useSelector(selectGameCategory)
  // const [cashbackPopup, setCashbackPopup] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  let gameList = useSelector(selectGameList);
  let winRate = useSelector(selectWinRateSlice);

  const biLog = (bannerName) => {
    socket.emit(
      "getbilog",
      {
        biname: "banner_open",
        banner_name: bannerName === "topten" ? "CN New Year" : bannerName,
      },
      (data) => {
        // console.log("banner_open", data);
      }
    );
  };

  useEffect(() => {
    // console.log("profile?.favourite :", profile?.favourite);
    // console.log("props.gameId :", props.gameId);
    // console.log("props :", props);

    setIsFavorite(profile?.favourite?.indexOf(props.gameId) > -1);
  }, [props]);

  useEffect(() => {
    // if (/Android|webOS|iPhone|iPad|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    if (/Android|webOS|iPhone|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setIsMobile(1);
    } else {
      setIsMobile(0);
    }
  }, []);

  useEffect(() => {
    // console.log("props in GameItem:", props);
    if (props.partner) {
      let image = document.getElementById(props.gameId + props.partner);
      let fixTime = 500;
      if (!image.complete) {
        setDelayOpen(true);
        let TimeStart = Date.now();
        // image.parentElement.setAttribute("isLoad", true);
        setIsLoad(true);
        image.style.display = "none";
        image.addEventListener("load", (e) => {
          let DiffTime = Date.now() - TimeStart;
          if (DiffTime < fixTime) {
            setTimeout(() => {
              setDelayOpen(false);
            }, fixTime - DiffTime);
          } else {
            setDelayOpen(false);
          }
          // console.log("Load Success", props.gameId + props.partner);
          setIsLoad(false);
          image.style.display = "block";
        });
      } else {
        setTimeout(() => {
          setDelayOpen(false);
        }, fixTime);
        // console.log("Load by Cache");
      }
    }
  }, [props]);

  const setFavourite = (fav) => {
    setIsFavorite(fav);
    socket.emit(
      "setFavourite",
      {
        gameid: props.gameId,
        isFavourite: fav,
      },
      (body) => {
        // console.log("body", body);
        if (
          typeof body === "undefined" ||
          typeof body.results === "undefined" ||
          typeof body.results.data === "undefined"
        ) {
        } else {
          if (body.status === false) {
            // setEmitError({ status: true, message: body.errorCode });
            dispatch(
              actionConfirmPopup({
                status: true,
                message: body.errorCode,
              })
            );
          } else if (body.status) {
            let gl = JSON.parse(JSON.stringify(gameList)).map((i) => {
              if (i.gameId === props.gameId) {
                i.isFavorite = fav;
              }
              return i;
            });
            dispatch(actionGameList(gl));
          }
        }
      }
    );
  };

  return (
    <>
      {/* {isLoading ? <Loading /> : ""} */}
      <GameItemBox
        width={window.innerWidth}
        height={window.appHeight()}
        {...props}
      >
        {props.isGameCategory && props.isWin ? (
          <WinbadgeBig />
        ) : winRate[props.partner + "-" + props.gameName] ? (
          <WinRateBadge win={winRate[props.partner + "-" + props.gameName]} />
        ) : (
          ""
        )}
        <Link
          to="#"
          //  to={!props.isGameCategory && isMobile !== 1 ? "/gameplay?token=" + getJWT() : "?token=" + getJWT()}
          onClick={(event) => {
            event.preventDefault();
            // console.log("window.isDrag", window.isDrag);
            if (window.isDrag == true) {
              return;
            }

            if (delayOpen) return;
            // console.log("GameItem props:", props);

            if (typeof props.gameId === "undefined") {
              if (typeof props.category_name !== "undefined") {
                biLog(props.category_name);
              } else {
                biLog(props.partner);
              }
            }

            if (props.isProvider === true) {
              dispatch(actionIsProvider(false));
              dispatch(actionSetProvider(props.partner));
            }

            if (props.isGame === true && typeof props.gameId === "undefined") {
              let gameSort = gameList
                .filter((a) => {
                  return (
                    a.partner === props.partner &&
                    a.categories?.indexOf(props.type) > -1
                  );
                })
                .sort(function (a, b) {
                  return a["all"] - b["all"];
                });

              if (props.type === "casino") {
                gameSort.sort(function (a, b) {
                  return a["casino"] - b["casino"];
                });
              }

              dispatch(actionSetProviders(gameSort));
              return;
            }

            if (!props.isProvider) {
              if (profile.isTelGuest === false && profile.isguest === true) {
                dispatch(
                  actionPopup({
                    guestCheck: true,
                    message: "you have not yet subscribed",
                  })
                );
              } else if (
                profile.isTelGuest === true &&
                profile.isguest === true
              ) {
                dispatch(
                  actionPopup({
                    bankCheck: true,
                    message: "bank account game",
                  })
                );
              } else {
                dispatch(actionLoading(false));
                socket.emit(
                  "getGameUrl",
                  {
                    gameid: props.gameId,
                    platform: isMobile,
                    url: /Android|webOS|iPhone|IEMobile|Opera Mini/i.test(
                      navigator.userAgent
                    )
                      ? window.location.protocol + "//" + window.location.host
                      : window.location.protocol +
                        "//" +
                        window.location.host +
                        "/back.html?token=" +
                        getJWT(),
                  },
                  (body) => {
                    // props.setIsLoading(false);
                    // console.log("Game;", body, props);

                    const setTimeCloseLoad = (time, fn) => {
                      setTimeout(() => {
                        dispatch(actionLoading(true));
                        if (fn) return fn();
                      }, time);
                    };
                    setTimeCloseLoad(5000);

                    if (
                      typeof body === "undefined" ||
                      typeof body.results === "undefined" ||
                      typeof body.results.data === "undefined"
                    ) {
                    } else {
                      if (body.status) {
                        if (
                          (/Android|webOS|iPhone|IEMobile|Opera Mini/i.test(
                            navigator.userAgent
                          ) &&
                            props.is_exit !== 1) ||
                          // props.partner === "sbo"
                          props["sport"] > 0 ||
                          props.partner === "tvb" ||
                          props.partner === "yb"
                        ) {
                          window.location.href = body.results.data.url;
                        } else {
                          dispatch(
                            actionGamePlay({
                              isPlay: true,
                              gameUrl: body.results.data.url,
                            })
                          );

                          setTimeCloseLoad(1000, () => {});
                          history.push("/gameplay?token=" + getJWT());
                        }
                      } else if (
                        body.status === false ||
                        body.errorCode === "s0156"
                      ) {
                        console.log("alert");
                        setTimeCloseLoad(0, () => {
                          // setEmitError({
                          //   status: true,
                          //   message: body.errorCode,
                          // });
                          dispatch(
                            actionConfirmPopup({
                              status: true,
                              message: body.errorCode,
                            })
                          );
                        });
                      } else if (body.status === false) {
                        setTimeCloseLoad(0, () => {
                          dispatch(
                            actionAlert({
                              isAlert: true,
                              errorCode: body.errorCode,
                            })
                          );
                          setTimeout(() => {
                            dispatch(
                              actionGamePlay({ gameUrl: "", isPlay: false })
                            );
                            dispatch(
                              actionAlert({ isAlert: false, errorCode: "" })
                            );
                          }, 3000);
                        });
                      }
                    }
                  }
                );
              }
            } else {
              history.push("/?token=" + getJWT());
              dispatch(actionGameCategory(props.category_name));
              // check promotion - casino category
              if (
                props.category_name === "casino" &&
                profile.promotion_status === true
              ) {
                // setEmitError({ status: true, message: "alert live casino" });
              } else {
                dispatch(actionIsCategory(true));
              }
            }
          }}
        >
          <GameImg
            src={props.img}
            imgId={props.gameId + props.partner}
            isLoad={isLoad}
          />

          {/* <img src={props.img} /> */}
        </Link>

        {!props.isGameCategory && props.categories?.indexOf("new") >= 0 ? (
          <Badge>
            <img alt="" src={"../" + config.theme + "/badge_new.png"} />
            <p>{t("new")}</p>
          </Badge>
        ) : null}

        {!props.isGameCategory && typeof props.gameId !== "undefined" ? (
          isFavorite ? (
            <img
              alt=""
              className="fav"
              src={"../" + config.theme + "/icon_heart_active.png"}
              onClick={() => {
                setFavourite(false);
              }}
            />
          ) : (
            <img
              alt=""
              className="fav"
              src={"../" + config.theme + "/icon_heart_disable.png"}
              onClick={() => {
                setFavourite(true);
              }}
            />
          )
        ) : null}
      </GameItemBox>

      <Transition showPop={emitError.status} type="sb2t">
        <ConfirmPopup
          messageBottom={t("okay")}
          cancelClick={() => {
            setEmitError({ status: false });
          }}
        >
          {t(emitError.message)}
        </ConfirmPopup>
      </Transition>
    </>
  );
}

export default GameItem;
