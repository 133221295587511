import React from "react";
import styled, { css, keyframes } from "styled-components";
import { config } from "../config";

const flash = keyframes`
    0% { background: red; }
    100% { background: yellow; }
`;
const Flash = styled.div`
  width: 3vw;
  height: 1vw;
  background: red;
  border-radius: 5vw;
  // margin: auto 0;
  // margin-right: 0.5vw;
  animation: ${flash} 0.5s linear infinite reverse;
  margin-top: 0.4vw;
  // position: relative;

  @media only screen and (max-width: 480px) and (orientation: portrait) {
    width: 8vw;
    height: 2vw;
  }
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    width: 4vw;
    height: 1.5vw;
  }
`;

function FlashNoc(props) {
  return <Flash />;
}
export default FlashNoc;
