import { CSSTransition } from "react-transition-group";
import React, { Suspense } from "react";
import Loading from "./Components/Loading";
import axios from "axios";

export const config = {
  // local
  // socketEndpoint: "http://localhost:3200/",
  // lobbyEndpoint: "/",
  // socketKafkaEndpoint: "https://api-sec.spxslot.xyz/",
  /*spinix*/
  // socketKafkaEndpointPath: "/web-backend-platform-kafka-service/socket.io",
  /*kingmaker*/
  // socketKafkaEndpointPath: "/kafka-kingmaker-dev-service/socket.io",

  // prod
  // socketEndpoint: "https://backend.spxslot.xyz/",
  // socketKafkaEndpoint: "https://api-sec.spxslot.xyz/",
  // socketKafkaEndpointPath: "/web-backend-platform-kafka-service/socket.io",

  // cc
  socketEndpoint: "https://backend.spinixslot.cc/",
  socketKafkaEndpoint: "https://api.spnslot.site/",
  socketKafkaEndpointPath: "/web-backend-platform-kafka-service/socket.io",

  // test
  // socketEndpoint: "https://backend-test.spxslot.xyz/",
  // lobbyEndpoint: "/",
  // socketKafkaEndpoint: "https://api-sec.spxslot.xyz/",
  // socketKafkaEndpointPath: "/web-backend-platform-kafka-service/socket.io",

  // dev
  // socketEndpoint: "https://backend-dev.spxslot.xyz/",
  // lobbyEndpoint: "/",
  // socketKafkaEndpoint: "https://api-sec.spxslot.xyz/",
  // socketKafkaEndpointPath: "/web-backend-platform-kafka-dev-service/socket.io",

  // kingmaker
  // socketEndpoint: "https://backend-dev.kingmaker.site/",
  // lobbyEndpoint: "/",
  // socketKafkaEndpoint: "https://api-sec.spxslot.xyz/",
  // socketKafkaEndpointPath: "/kafka-kingmaker-dev-service/socket.io",

  // landingPageUrl: "https://www.spinix888.com/",

  //  apiEndpoint: 'https://backend.spxslot.xyz/dailyreward-service/ ',
  // socketKafkaEndpoint: "https://backend-kafka.x-gaming.com/",
  // socketKafkaEndpointPath: "/kafka/socket.io",
  // jwtToken: "",
  // jwtToken: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjYwMTEyMDlmNWZmNGRlMDAxODRlNDM0ZiIsInBmaWQiOiI1ZWViMWMwODJjM2IyNzU5NWM0ODBkZjUiLCJ1c2VybmFtZSI6InNhbGFkYSIsInVzZXJJZCI6IjEyMzQ1Njc4IiwibW9iaWxlIjoiYWFhYWEiLCJiYW5rQWNjb3VudCI6ImFhYWFhYWFhYSIsImVtYWlsIjoiYWFhQGFhYS5jb20iLCJ0aW1lZXZlbnQiOiIyMDIxLTAzLTIyVDIzOjU5OjU5Ljk5OVoiLCJzcGluc3VwZXJ0aWNrZXQiOjE2MSwic3BpbnRpY2tldCI6ODEsImNyeXN0YWwiOjMzMCwiYmFsYW5jZSI6MjAwMDQ3LCJleHBpcmVkQXQiOjE2MTYzOTIxNDU4Mzh9.bykx35LbfiXoM1E8Qzej2LF6BTBTLzyYVfMPp4DXw60"
  //jwtToken: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjYwYTIyZWNjYjg5OTdkMDAxMzZkZmY0NSIsInBmaWQiOiI1ZWViMWMwODJjM2IyNzU5NWM0ODBkZjUiLCJ1c2VybmFtZSI6InRlc3R0czEyMyIsInVzZXJJZCI6IktLTDQ4NjAiLCJtb2JpbGUiOiIwODEwMjY4MTIyIiwiYmFua0FjY291bnQiOiIxMTExMTExMTExIiwiZW1haWwiOiIiLCJ0aW1lZXZlbnQiOiIyMDIxLTA2LTAyVDIzOjU5OjU5Ljk5OVoiLCJ0cmVhc3VyZXRpY2tldCI6ODAsInNwaW50aWNrZXQiOjI3LCJkaWFtb25kIjoyNTQzMiwiYmFsYW5jZSI6MiwiZXhwaXJlZEF0IjoxNjIyNjE4Mjc0NTU3LCJhdmF0YXIiOjcsInNldGJvbnVzIjoiQyIsInNldHNvdW5kIjoiQyIsInNldGVmZmVjdCI6IkMiLCJsZXZlbCI6OTksImRhaWx5cmV3YXJkIjo0MDAwLCJwYXJlbnRpZCI6IiIsImdldHJld2FyZDEiOiIiLCJnZXRyZXdhcmQyIjoiIiwiZ2V0cmV3YXJkMyI6IiIsImJvbnVzaW52ZnJpZW5kIjowLCJub3RpZW1haWwiOiJUIiwibm90aXByb21vdGlvbiI6IkYiLCJub3RpcmFua2luZyI6IkYiLCJleHBlcmllbmNlIjo5MDAwMDAwMDAwMDAwMCwiZXhwbWluIjo4MDAwMDAwMDAwMDAwMSwiZXhwbWF4Ijo5MDAwMDAwMDAwMDAwMCwicmVmZXJjb2RlIjoiUk8wMHZQcE14YiIsImFnZW50X2lkIjoiNWZlNjJlNmU4ODY5ZTQwMDEyODViMGZmIiwiYWNjb3VudF9hcGkiOiJ4bW96YXJ0NDMzNzg1OTc4IiwiYnJhbmRzX2lkIjoiNWZlNjJkOGVhYmFmM2QwMD"
  popupTimeout: 3000,
  agentId: "",
  theme: "images",
  // theme: "images_valentine",
  verticalSizeiphone: 1.65,
  verticalSizeipad: 2.15,

  // theme: "images_songkran"
};

export const parseJwt = () => {
  var base64Url = getJWT().split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const back = (setShowPop, history) => {
  setTimeout(() => {
    history.push("/");
  }, 300);
  setShowPop(false);
};

export const backToSideBar = (setShowPop, history) => {
  setTimeout(() => {
    history.push("/SideBar");
  }, 300);
  setShowPop(false);
};

export const Transition = (props) => {
  return props.showPop || props.in ? (
    <Suspense fallback={<Loading />}>{props.children}</Suspense>
  ) : null;
  // <CSSTransition
  //   in={props.showPop}
  //   timeout={300}
  //   classNames={props.type}
  //   unmountOnExit
  // >
  //   <Suspense fallback={<Loading />}>{props.children}</Suspense>
  // </CSSTransition>
};

//  window.history.pushState("object or string", "Title", "/" + window.location.href.substring(window.location.href.lastIndexOf('/') + 1).split("?")[0])
export const redirectAfterLogin = (isReload) => {
  if (window.location.href.indexOf("username")) {
    window.history.pushState(
      "object or string",
      "Title",
      "/" +
        window.location.href
          .substring(window.location.href.lastIndexOf("/") + 1)
          .split("?")[0]
    );
    if (isReload) window.location.reload();
  }
};

export const localStorageSetWithExpiry = (key, value, ttl) => {
  try {
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
  } catch (error) {}
};
export const localStorageGetWithExpiry = (key) => {
  try {
    const itemStr = localStorage.getItem(key);

    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  } catch (error) {
    return null;
  }
};

export const copyToClipboardOnTemp = (value) => {
  var tempInput = document.createElement("input");
  tempInput.style = "position: absolute; left: -1000px; top: -1000px";
  tempInput.value = value;
  document.body.appendChild(tempInput);
  tempInput.select();
  document.execCommand("copy");
  document.body.removeChild(tempInput);
};

export const getFullUrl = (token) => {
  if (typeof token == "undefined") token = getJWT();
  // return window.location.protocol + "//" + window.location.host + window.location.pathname + '?token=' + token;
  return (
    window.location.protocol + "//" + window.location.host + "?token=" + token
  );
};

export const redirect2Page = (page, quickRegister) => {
  let fUrl =
    page === ""
      ? window.location.protocol +
        "//" +
        window.location.host +
        "/" +
        "?token=" +
        getJWT()
      : window.location.protocol +
        "//" +
        window.location.host +
        "/" +
        page +
        "/" +
        "?token=" +
        getJWT();
  fUrl =
    page === ""
      ? fUrl
      : fUrl + "&m=forgotpassword" + (quickRegister === "1" ? "&q=1" : "&q=0");

  if (quickRegister === "1")
    localStorageSetWithExpiry("register", "1", 8600000);
  else localStorageSetWithExpiry("register", "0", 8600000);
  // redirectAfterLoginWithToken("", fUrl);
  redirectToLogin(fUrl);
};

export const getFullUrlWipath = () => {
  return (
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname
  );
};

export const setToken = (jwtToken) => {
  // localStorageSetWithExpiry("tk", jwtToken, 86400000)
};

export const getToken = () => {
  return localStorageGetWithExpiry("tk");
};

export const removeAllSession = () => {
  try {
    localStorage.removeItem("tk");
  } catch (error) {}
  try {
    localStorage.removeItem("ltk");
  } catch (error) {}
};

export const redirectAfterLoginWithToken = (token, fullurl) => {
  var refresh = getFullUrl(token);

  if (fullurl) refresh = fullurl;

  window.history.pushState({ path: refresh }, "", refresh);
};

export const getNewUrlwithLang = (lang) => {
  // var refresh = getFullUrlWipath() + '?lang=' + lang
  // window.history.pushState({ path: refresh }, '', refresh);

  try {
    localStorage.setItem("lang", lang);
  } catch (error) {}
};

export const brandSetting = async (brand) => {
  if (typeof brand == "undefined") {
    brand = parseJwt().brands_id;
  }
  let oBrand = null,
    strBrand = null;
  try {
    strBrand = localStorageGetWithExpiry(brand);
  } catch (error) {}
  try {
    if (strBrand == null || strBrand == "undefined") {
      oBrand = (
        await axios.get(
          "https://api-sec.spxslot.xyz/prod-api-x.apinode.xyz/brands-setting/" +
            brand
        )
      ).data;
      localStorageSetWithExpiry(brand, oBrand, 300 * 1000);
    } else {
      oBrand = strBrand;
    }
  } catch (error) {}
  return oBrand;
};

export const redirectToLogin = async (url) => {
  if (typeof url == "undefined" || url == "" || url == "/" || url == null) {
    url = window.location.protocol + "//" + window.location.hostname;

    // http://dev-api-x.apinode.xyz/brands-setting/61c156590a156b001a8da689
    //   try {

    //     url = localStorage.getItem("url", url)
    //   } catch (error) {
    //     url = config.landingPageUrl;
    //   }
    let brand = "";

    try {
      if (getJWT() != null) {
        brand = parseJwt().brands_id;
      }
    } catch (error) {}
    try {
      if (brand == "" || typeof brand == "undefined") {
        brand = localStorage.getItem("brand");
      }
    } catch (error) {}

    let oBrand = await brandSetting(brand);
    if (oBrand == null) {
      window.location.replace(url);
    } else {
      window.location.replace(oBrand.setting.link_front + "?logout=true");
    }
    // let c = await axios.get("https://api-sec.spxslot.xyz/prod-api-x.apinode.xyz/brands-setting/" + brand).then(a => {
    //   window.location.replace(a.data.setting.link_front);
    // }).catch(ce => {
    //   window.location.replace(url);
    // })
  } else {
    window.location.replace(url);
  }
};

export const redirectToGuest = (uid, code) => {
  code = getParameterByName("a");
  if (typeof code == "undefined" || code == null || code == "") {
    code = config.agentId;
  }
  let exUrl = "?c=" + code + "&u=" + uid;
  let refer = getParameterByName("r");
  if (refer != null) exUrl = exUrl + "&r=" + refer;
  redirectToLogin(
    window.location.protocol + "//" + window.location.hostname + exUrl
  );
};

export const getParameterByName = (name, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  let res = decodeURIComponent(results[2].replace(/\+/g, " ")).split("?")[0];
  return res;
};

export const getLang = () => {
  // let lang = getParameterByName("lang");

  let lang = "th";
  try {
    lang = localStorage.getItem("lang") ?? "th";
  } catch (error) {}

  // if (lang !== "th" && lang !== "en") lang = "th";

  return lang;
};

export const getVersion = () => {
  let version = "prod.3.0.24.04.30.10.44";

  return version;
};

export const getJWT = () => {
  return getParameterByName("token");
};

export const getBackUrl = () => {
  let device = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
    ? 1
    : 0;
  return {
    url:
      device == 1
        ? getFullUrl()
        : window.location.protocol +
          "//" +
          window.location.host +
          "/back.html?token=" +
          getJWT(),
    // url: "",
    platform: device,
  };
};
