import { createSlice } from "@reduxjs/toolkit";
export const supportSlice = createSlice({
    name: "supports",
    initialState: {
        support: {
            username: "",
            number: "",
            email: "",
            detail: "",
        },
    },
    reducers: {
        actionSupport: (state, action) => {
            state.support = { ...state.support, ...action.payload };
       },
    },
});

export const { actionSupport } = supportSlice.actions;
export const selectSupport = (state) => state.supports.support;
export default supportSlice.reducer;