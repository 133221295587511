import { createSlice } from "@reduxjs/toolkit";
export const minigameslotSlice = createSlice({
    name: "gameslot",
    initialState: {
        slot_path: "blue",
        respinreel: 0,
    },
    reducers: {
        actionMinigameSlot: (state, action) => {
            state.slot_path = action.payload
        },
        actionRespin: (state, action) => {
            state.respinreel = action.payload;

        },
    },
})

export const { actionMinigameSlot, actionRespin } = minigameslotSlice.actions;
export const selectMinigameslot = (state) => state.gameslot.slot_path;
export const selectRespinreel = (state) => state.gameslot.respinreel;
export default minigameslotSlice.reducer;