import App from "../App";
import { useState, useEffect } from "react";
import { SocketContext, socket, SocketKafkaContext, socketKafka, reSocket } from '../context/socket';
import Loading from './Loading';
import { detect } from 'detect-browser';
import BrowserSupport from './BrowserSupport';
// import { SocketMinigameContext, socketMinigame, reSocket as reSocketMinigame } from "./SKD/socketContext";

function AppConnect() {
    const [enable, SetEnable] = useState(false);
    const ConnectSocket = async () => {
        await reSocket();
        // await reSocketMinigame();
        SetEnable(true)
    }
    const browser = detect();

    useEffect(() => {
        ConnectSocket();
    }, [])
    return ((enable == false ? <Loading /> :
        <SocketContext.Provider value={socket}>
            <SocketKafkaContext.Provider value={socketKafka}>
                <App />
                {/* <SocketMinigameContext.Provider value={socketMinigame}> */}

                {/* {
                        browser.name == "chrome" ||
                            browser.name.includes("edge") ||
                            browser.name == "safari" ||
                            browser.name == "crios" ||
                            browser.name == "ios" ||
                            browser.name == "ios-webview" ||
                            browser.name == "facebook" ||
                            browser.name == "chromium-webview"
                            ?
                            <App />
                            :
                            <BrowserSupport />
                    } */}

                {/* </SocketMinigameContext.Provider> */}

            </SocketKafkaContext.Provider>
        </SocketContext.Provider>
    ))
}

export default AppConnect;