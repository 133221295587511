import React, { lazy, useState, useContext, useEffect, useRef } from "react";
import styled, { css, keyframes } from "styled-components";
import MenuItem from "./MenuItem";
import { Transition, config } from "../../config";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  actionClickSound,
  selectuserProfile,
} from "../../Reducers/userProfileSlice";
import {
  selectGameCategory,
  actionIsCategory,
  selectIsCategory,
  actionGameCategory,
  // selectIsProvider,
  // actionIsProvider,
  actionSetProviders,
  selectProvidersItem,
  selectSetProviders,
  selectGameType,
  actionGameType,
} from "../../Reducers/gameCategorySlice";
import { selectPopup, actionPopup } from "../../Reducers/popupSlice";
import { selectDimensions } from "../../Reducers/dimensions";

import Search from "./Search";
import { SocketContext } from "../../context/socket";
import breakpoints from "../../LandingPageComponents/breakpoints";
import { selectProvider } from "../../Reducers/providerSlice";
import GameImg from "../GameList/GameImg";
import Providers from "../Providers";
import {
  selectIsProvider,
  actionIsProvider,
} from "../../Reducers/providerSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
const GameCategory = lazy(() => import("../GameList/GameCategory"));
const GameList = lazy(() => import("../GameList"));

const ScaleFrame = styled.div`
  height: 110%;
  @media ${breakpoints.device.line} {
    transform: scale(0.7);
  }

  //Portrait
  @media (orientation: portrait) {
    height: 100%;
  }
`;

const SubNavContainer = styled.div`
  user-select: none;
  display: flex;
  width: 110%;
  width: fit-content;
  margin: 0 auto;
  //Portrait
  @media (orientation: portrait) {
    width: 100%;
    height: 100%;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: space-between;
    z-index: 9;
  }
`;

const SubMenuBox = styled.div`
  user-select: none;
  width: max-content;
  height: calc(100vw * 0.048 * 0.75);
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 10vw;
  padding: 0.2vw 2vw;
  margin-top: calc(100vw * 0.0075);
  display: flex;
  justify-content: center;

  @media ${breakpoints.device.line} {
    margin-top: -0.5vw;
  }
  //Portrait
  @media (orientation: portrait) {
    width: 100%;
    height: calc(95% - 7.5vw);
    padding: 0;
    display: block;
    background-color: rgba(0, 0, 0, 0);
    margin-top: 0;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    height: calc(88% - 9vw);
    border-radius: 2vw;
    justify-content: flex-start;
    display: block;
    background-color: rgba(0, 0, 0, 0);
    margin-top: 0;
  }
`;

const ContentComponent = styled.div`
  user-select: none;
  position: absolute;
  top: ${(props) => (props.width / props.height > 2.15 ? "6.5vw" : "5vw")};

  //Portrait
  @media (orientation: portrait) {
    width: 76vw;
    z-index: -1;
    left: 24vw;
    top: 0%;
  }
`;

const SearchButton = styled.div`
  background: linear-gradient(180deg, #ff8a1f 0%, #ff4c00 100%);
  height: 3.5vw;
  width: 3.5vw;
  border-radius: 20vw;
  cursor: pointer;
  color: #fff;
  font-size: 1.5vw;
  text-align: center;
  line-height: 3.5vw;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 1vw;
  float: left;

  //Portrait
  @media (orientation: portrait) {
    height: 7.5vw;
    width: 7.5vw;
    font-size: 3.5vw;
    text-align: center;
    line-height: 7.5vw;
    top: 0;
    margin-bottom: 12%;
    position: relative;
    z-index: 10;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    line-height: 6.5vw;
    font-size: 3vw;
    height: 8vw;
    width: 8vw;
  }
`;

const VerticalTab = styled.div`
  user-select: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  //Portrait
  @media (orientation: portrait) {
    flex-direction: column;
  }
`;

const VerticalLeft = styled.div`
  user-select: none;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  //Portrait
  @media (orientation: portrait) {
    align-items: center;
  }
`;

const VerticalLeftPart2 = styled.div`
  user-select: none;
  width: 72%;
  display: flex;
  justify-content: space-evenly;
`;

const VerticalRight = styled.div`
  user-select: none;
  position: relative;

  //Portrait
  @media (orientation: portrait) {
    width: 72%;
    display: grid;
    justify-content: center;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 1vw;
      z-index: 10;
    }
    ::-webkit-scrollbar-thumb {
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 10;
      position: absolute;
      background: linear-gradient(180deg, #e13b00 38.02%, #900000 100%);
      box-shadow: inset 0px 0px 4px 2px #ff814d;
      border-radius: 10px;
    }
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    height: 80%;
    ::-webkit-scrollbar {
      height: 0vw;
    }
    ::-webkit-scrollbar-thumb {
      width: 11px;
      height: 80px;
    }
  }
`;

const NavBG = styled.div`
  user-select: none;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 1.4vw;
  position: absolute;
  z-index: 10;
  box-shadow: inset 0px 0px 3px 1px #ff691f,
    inset 0px 0px 20px rgba(255, 133, 66, 0.63);
  border-radius: 10px;
  //Portrait
  @media (orientation: portrait) {
    width: 85%;
    height: 100%;
  }

  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    width: 85%;
    height: 100%;
  }
`;

const SeparateLine = styled.div`
  width: 85%;
  height: 0.2vw;
  background-color: #ff691f;
  opacity: 0.3;
`;

const MoreBtn = styled.div`
  color: white;
  font-size: 1vw;
  line-height: 3.5vw;
  text-align: center;
  width: 10vw;
  cursor: pointer;
  background: linear-gradient(180deg, #924524 0%, #211f29 78.12%);
  box-shadow: inset 0px 0px 4.2069px 1.05172px #ff7a00;
  border-radius: 38.9138px;
`;

const MoreMenu = styled.div`
  user-select: none;
  width: max-content;
  height: calc(100vw * 0.048 * 0.75);
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 10vw;
  padding: 0.2vw 2vw;
  margin-top: calc(100vw * 0.0075);
  display: flex;
  align-items: center;
  position: absolute;
  right: 12%;
  top: 88%;
  z-index: 5;
`;

const newBadge = keyframes`
     0% {opacity: 20%}
     50% {opacity: 100%}
  100% {opacity: 20%}
`;

const NewBadge = styled.p`
  color: yellow;
  font-size: 0.8vw;
  font-style: italic;
  margin-left: 0vw 0vw 0vw -2vw;
  animation-name: ${newBadge};
  animation-duration: 0.75s;
  animation-iteration-count: infinite;
  pointer-events: none;
  //Portrait
  @media (orientation: portrait) {
    font-size: 2vw;
    text-align: right;
    margin-top: -9vw;
    margin-right: ${(props) => (props.lang === "en" ? "1vw" : "-1vw")};
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 1.8vw;
    margin: -6vw 0vw 0vw -2vw;
  }
`;

const MoreButton = styled.div`
  cursor: pointer;
  width: 85%;
  background: linear-gradient(180deg, #924524 0%, #211f29 78.12%);
  box-shadow: inset 0px 0px 5.88966px 1.47241px #ff7a00;
  position: absolute;
  z-index: 11;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  //Portrait
  @media (orientation: portrait) {
    height: 6%;
    bottom: 12vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    height: 8%;
    bottom: 13vw;
  }
  /* ${(props) =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          height: 6%;
          bottom: 12vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          height: 8%;
          bottom: 11vw;
        `} */
`;

const MoreButtonShadow = styled.div`
  pointer-events: none;
  width: 85%;
  background: linear-gradient(180deg, rgba(13, 5, 3, 0) 0%, #0d0503 78.65%);
  position: absolute;
  z-index: 11;
  //Portrait
  @media (orientation: portrait) {
    height: 6%;
    bottom: calc(6% + 12vw);
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    height: 8%;
    bottom: calc(8% + 11vw);
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          height: 6%;
          bottom: calc(6% + 12vw);
        `
      : window.appHeight() > window.innerWidth &&
        css`
          height: 8%;
          bottom: calc(8% + 11vw);
        `} */
`;

const MoreButtonText = styled.div`
  color: #ffffff;
  font-size: 2.8vw;
  font-weight: 700;
`;

const BackButton = styled.div`
  color: white;
  font-size: 1.5vw;
  line-height: 3.5vw;
  text-align: center;
  width: 5vw;
  cursor: pointer;
  /* background: linear-gradient(180deg, #924524 0%, #211f29 78.12%);
  box-shadow: inset 0px 0px 4.2069px 1.05172px #ff7a00;
  border-radius: 38.9138px; */
  :hover {
    color: #ff4c00;
  }

  //Portrait
  @media (orientation: portrait) {
    font-size: 5.5vw;
    width: 10vw;
    margin: 2vw auto;
    z-index: 20;
    :hover {
      color: white;
    }
    p {
      font-size: 2.5vw;
      color: rgba(255, 255, 255, 0.7);
    }
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    :hover {
      color: white;
    }
    p {
      font-size: 2.5vw;
      color: rgba(255, 255, 255, 0.7);
    }
  }
`;

function SubNav() {
  const socket = useContext(SocketContext);
  const { t, i18n } = useTranslation();
  const [Index, setIndex] = useState(0);
  const [previousIndex, setPreviousIndex] = useState(0);
  const dispatch = useDispatch();
  const gameCategory = useSelector(selectGameCategory);
  const isCa = useSelector(selectIsCategory);
  const isProvider = useSelector(selectIsProvider);
  const popupStatus = useSelector(selectPopup);
  const [openMore, setOpenMore] = useState(false);
  const ref = useRef();
  const dimensions = useSelector(selectDimensions);
  const [vertMore, setVertMore] = useState(false);
  const [vertContentIndex, setVertContentIndex] = useState(0);
  // const [type, setType] = useState("");
  const selectedType = useSelector(selectGameType);
  const profile = useSelector(selectuserProfile);

  useEffect(() => {
    if (window.appHeight() > window.innerWidth) {
      var div = document?.getElementById("verticalP2");
      // var hasHorizontalScrollbar = div?.scrollWidth > div?.clientWidth;
      var hasVerticalScrollbar = div?.scrollHeight > div?.clientHeight;
      // console.log("ScrollTop " + div?.scrollTop, hasVerticalScrollbar);
      if (div?.scrollTop === 0 && hasVerticalScrollbar) {
        setVertMore(true);
      } else {
        setVertMore(false);
      }
      div?.addEventListener("scroll", () => {
        if (div?.scrollTop === 0) {
          setVertMore(true);
        } else {
          setVertMore(false);
        }
      });
    }
  }, [dimensions]);

  const ScrollDownToBottom = (elementId) => {
    let div = document?.getElementById(elementId);
    div?.scrollTo(0, div.scrollHeight);
  };

  let categoryType;
  if (!isCa) {
    categoryType = "category";
  } else {
    categoryType = gameCategory;
  }

  const itemsMenu = [
    { label: "", icon: "home", type: "all" },
    { label: "", icon: "fav", type: "favourite" },
    { label: "", icon: "history", type: "history" },
    {
      label: "slot",
      icon: "slot",
      type: "slot",
    },
    {
      label: "casino",
      icon: "live",
      type: "casino",
    },
    {
      label: "sport",
      icon: "ball",
      type: "sport",
    },
    { label: "fishing", icon: "fish", type: "fishing" },
    { label: "crash games", icon: "crash", type: "crash" },
    { label: "arcade", icon: "arcade", type: "arcade" },
    { label: "table card", icon: "card", type: "table" },
    { label: "pvp", icon: "pvp", type: "pvp" },
    { label: "lotto", icon: "lotto", type: "lotto" },
  ];

  const HomeFav =
    window.appHeight() > window.innerWidth
      ? [{ label: "", icon: "home", type: "all" }, {}, {}]
      : [
          { label: "", icon: "home", type: "all" },
          { label: "", icon: "fav", type: "favourite" },
          { label: "", icon: "history", type: "history" },
        ];

  const FavHisForVertical =
    window.appHeight() > window.innerWidth
      ? [
          {},
          { label: "", icon: "fav", type: "favourite" },
          { label: "", icon: "history", type: "history" },
        ]
      : [];

  const CateGame = [
    {},
    {},
    {},
    {
      label: "slot",
      icon: "slot",
      type: "slot",
    },
    {
      label: "casino",
      icon: "live",
      type: "casino",
    },
    {
      label: "sport",
      icon: "ball",
      type: "sport",
    },
    { label: "fishing", icon: "fish", type: "fishing" },
    { label: "crash games", icon: "crash", type: "crash" },
    { label: "arcade", icon: "arcade", type: "arcade" },
    { label: "table card", icon: "card", type: "table" },
    { label: "pvp", icon: "pvp", type: "pvp" },
    { label: "lotto", icon: "lotto", type: "lotto" },
  ];

  useEffect(() => {
    // console.log("profile:", profile);
    if (
      profile.categoryDefault == "slot" ||
      profile.categoryDefault == "casino" ||
      profile.categoryDefault == "sport"
    ) {
      dispatch(actionGameType(profile.categoryDefault));
      // dispatch(actionIsProvider(true));
      const getIndex = itemsMenu
        .map((item) => item.type)
        .indexOf(profile.categoryDefault);
      setIndex(getIndex);
      setVertContentIndex(getIndex);
      // console.log("getIndex Landscape:", getIndex, profile.categoryDefault);
      // }
    } else {
      dispatch(actionGameType("all"));
    }
  }, [profile.categoryDefault]);

  const lastIndex = 9;

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (openMore && ref.current && !ref.current.contains(e.target)) {
        setOpenMore(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [openMore]);

  const clickMenu = (index, type) => {
    socket.emit(
      "getbilog",
      {
        biname: "category_open",
        banner_name: type,
      },
      (data) => {
        // console.log("category_open", data);
      }
    );

    if (Index !== index) dispatch(actionClickSound());
    setIndex(index);
    setVertContentIndex(index);
    setTimeout(() => {
      setPreviousIndex(index);
    });
    dispatch(actionIsProvider(true));
    dispatch(actionGameType(type));
  };

  return (
    <>
      <ScaleFrame>
        <SubNavContainer>
          <SubMenuBox>
            {window.appHeight() > window.innerWidth ? (
              <VerticalTab>
                <NavBG />
                <VerticalLeft>
                  {HomeFav.map((item, index) =>
                    Object.keys(item).length === 0 ? (
                      ""
                    ) : (
                      <React.Fragment key={index}>
                        <MenuItem
                          id={"SubNav-Button-" + index}
                          currentIndex={index}
                          previousIndex={previousIndex}
                          onClick={() => {
                            clickMenu(index, item.type);
                          }}
                          {...item}
                          active={
                            // isPv === false || isCa === false || index !== 0
                            //   ?
                            // Index === index ? true : false
                            // : ""
                            selectedType === item.type ? true : false
                          }
                        />
                        <Transition
                          showPop={
                            Index === index && selectedType == "all"
                              ? true
                              : false
                          }
                          type="sr2l"
                        >
                          <ContentComponent>
                            <Providers type={selectedType} />
                            {/* <Providers {...item} /> */}
                          </ContentComponent>
                        </Transition>
                      </React.Fragment>
                    )
                  )}
                  <SeparateLine />
                  <VerticalLeftPart2>
                    {FavHisForVertical.map((item, index) =>
                      Object.keys(item).length === 0 ? (
                        ""
                      ) : (
                        <React.Fragment key={index}>
                          <MenuItem
                            id={"SubNav-Button-" + index}
                            currentIndex={index}
                            previousIndex={previousIndex}
                            onClick={() => {
                              clickMenu(index, item.type);
                            }}
                            {...item}
                            active={
                              // isPv === false || isCa === false || index !== 0
                              //   ?
                              // Index === index ? true : false
                              // : ""
                              selectedType === item.type ? true : false
                            }
                          />
                          <Transition
                            showPop={
                              Index === index &&
                              (selectedType == "favourite" ||
                                selectedType == "history")
                                ? true
                                : false
                            }
                            type="sr2l"
                          >
                            <ContentComponent id={"ContentComponent-FavHis"}>
                              <Providers type={selectedType} />
                              {/* <Providers {...item} /> */}
                            </ContentComponent>
                          </Transition>
                        </React.Fragment>
                      )
                    )}
                  </VerticalLeftPart2>
                </VerticalLeft>
                <SeparateLine style={{ marginBottom: "7%" }} />
                <BackButton
                  style={{
                    display:
                      Index > 2 ||
                      (isProvider == false && selectedType === "all")
                        ? "initial"
                        : "none",
                  }}
                  onClick={() => {
                    if (isProvider == false) {
                      dispatch(actionIsProvider(true));
                    } else {
                      // dispatch(actionIsProvider(true));
                      setIndex(0);
                      dispatch(actionGameType("all"));
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                  <p>{t("back")}</p>
                </BackButton>
                <VerticalRight id="verticalP2">
                  {CateGame.map((item, index) =>
                    Object.keys(item).length === 0 ? (
                      ""
                    ) : (
                      <React.Fragment key={index}>
                        <MenuItem
                          id={"SubNav-Button-" + index}
                          currentIndex={index}
                          previousIndex={previousIndex}
                          onClick={() => {
                            // setVertContentIndex(index);
                            // setType(item.type);
                            clickMenu(index, item.type);
                          }}
                          {...item}
                          active={
                            // isPv === false || isCa === false || index !== 0
                            //   ?
                            // Index === index ? true : false
                            // : ""
                            selectedType === item.type ? true : false
                          }
                        />
                        {/* {index === 5 ? (
                          <NewBadge lang={i18n.language}>NEW</NewBadge>
                        ) : null} */}
                      </React.Fragment>
                    )
                  )}
                </VerticalRight>
                <Transition
                  showPop={
                    Index === vertContentIndex &&
                    selectedType !== "all" &&
                    selectedType !== "favourite" &&
                    selectedType !== "history"
                      ? true
                      : false
                  }
                  type="sr2l"
                >
                  <ContentComponent id={"ContentComponent-CateGame"}>
                    <Providers type={selectedType} />
                    {/* <Providers type={type} /> */}
                  </ContentComponent>
                </Transition>

                {vertMore ? (
                  <>
                    <MoreButtonShadow
                      ContentHeight={document?.getElementById("verticalP2")}
                    />
                    <MoreButton
                      ContentHeight={document?.getElementById("verticalP2")}
                      onClick={() => {
                        ScrollDownToBottom("verticalP2");
                      }}
                    >
                      <MoreButtonText>More</MoreButtonText>
                      <MoreButtonText
                        style={{
                          transform:
                            "rotate(90deg) scaleY(1.3) scaleX(0.7) translateX(-0.5vw)",
                        }}
                      >
                        {">>"}
                      </MoreButtonText>
                    </MoreButton>
                  </>
                ) : (
                  ""
                )}
              </VerticalTab>
            ) : (
              <>
                <BackButton
                  style={{
                    display:
                      Index > 2 ||
                      (isProvider == false && selectedType === "all")
                        ? "initial"
                        : "none",
                  }}
                  onClick={() => {
                    if (isProvider == false) {
                      dispatch(actionIsProvider(true));
                      dispatch(actionGameType(selectedType));
                    } else {
                      // dispatch(actionIsProvider(true));
                      setIndex(0);
                      dispatch(actionGameType("all"));
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </BackButton>
                {itemsMenu.map((item, index) =>
                  Object.keys(item).length === 0 ? (
                    ""
                  ) : (
                    <React.Fragment key={index}>
                      {index < 9 ? (
                        <MenuItem
                          id={"SubNav-Button-" + index}
                          currentIndex={index}
                          previousIndex={previousIndex}
                          onClick={() => {
                            clickMenu(index, item.type);
                          }}
                          {...item}
                          active={
                            // isPv === false || isCa === false || index !== 0
                            //   ?
                            // Index === index ? true : false
                            // : ""
                            selectedType === item.type ? true : false
                          }
                        />
                      ) : index === 9 ? (
                        <div ref={ref}>
                          <MoreBtn
                            id="game-more-btn"
                            onClick={() => setOpenMore(!openMore)}
                          >
                            <p>
                              {t("game more")} <i className="icon-ico_more" />
                            </p>
                            {openMore ? (
                              <MoreMenu>
                                {itemsMenu
                                  .filter((v, i) => {
                                    return i >= 9;
                                  })
                                  .map((item, index) =>
                                    Object.keys(item).length === 0 ? (
                                      ""
                                    ) : (
                                      <MenuItem
                                        key={index}
                                        id={
                                          "SubNav-Button-" + index + lastIndex
                                        }
                                        currentIndex={index + lastIndex}
                                        previousIndex={previousIndex}
                                        onClick={() => {
                                          clickMenu(
                                            index + lastIndex,
                                            item.type
                                          );
                                        }}
                                        {...item}
                                        active={
                                          // isPv === false ||
                                          // isCa === false ||
                                          // index + lastIndex !== 0
                                          //   ?
                                          // Index === index + lastIndex
                                          //   ? true
                                          //   : false
                                          // : ""
                                          selectedType === item.type
                                            ? true
                                            : false
                                        }
                                      />
                                    )
                                  )}
                              </MoreMenu>
                            ) : (
                              ""
                            )}
                          </MoreBtn>
                        </div>
                      ) : null}

                      {/* {index === 5 ? (
                      <NewBadge lang={i18n.language}>NEW</NewBadge>
                    ) : null} */}

                      {index === 2 ? (
                        <span
                          style={{
                            backgroundColor: "rgb(255, 76, 0)",
                            // backgroundColor: "rgb(255, 255, 255)",
                            // height: "3.5vw",
                            height:
                              window.appHeight() / window.innerWidth >
                              config.verticalSizeiphone
                                ? "5.5vw"
                                : window.appHeight() > window.innerWidth
                                ? "4.5vw"
                                : window.innerWidth / window.appHeight() > 2.15
                                ? "3.5vw"
                                : "3.5vw",
                            // width: "0.25vw",
                            width:
                              window.appHeight() / window.innerWidth >
                              config.verticalSizeiphone
                                ? "0.4vw"
                                : window.appHeight() > window.innerWidth
                                ? "0.3vw"
                                : window.innerWidth / window.appHeight() > 2.15
                                ? "0.25vw"
                                : "0.25vw",
                            marginRight: "0.5vw",
                            display: "inline-block",
                            verticalAlign: "middle",
                            top:
                              window.appHeight() / window.innerWidth >
                              config.verticalSizeiphone
                                ? `calc(${
                                    window.appHeight() * 0.98
                                  }px - 31.6vw)`
                                : window.appHeight() > window.innerWidth
                                ? `calc(${
                                    window.appHeight() * 1.05
                                  }px - 31.6vw)`
                                : "",
                            position: "relative",
                            zIndex:
                              window.appHeight() > window.innerWidth ? 10 : "",
                          }}
                        ></span>
                      ) : (
                        ""
                      )}

                      <Transition
                        showPop={Index === index ? true : false}
                        type="sr2l"
                      >
                        <ContentComponent>
                          <Providers type={selectedType} />
                          {/* <Providers {...item} /> */}
                        </ContentComponent>
                      </Transition>
                    </React.Fragment>
                  )
                )}
              </>
            )}
          </SubMenuBox>
          {window.appHeight() > window.innerWidth ? <SeparateLine /> : ""}
          <SearchButton onClick={() => dispatch(actionPopup({ search: true }))}>
            <i className="icon-ico_search" />
          </SearchButton>
        </SubNavContainer>
      </ScaleFrame>
      <Transition showPop={popupStatus.search} type="st2b">
        <Search
          closeClick={() => {
            dispatch(actionPopup({ search: false }));
          }}
        />
      </Transition>
    </>
  );
}

export default SubNav;
