import React from "react";
import styled, { css, keyframes } from "styled-components";
import { useTranslation } from "react-i18next";
import { config } from "../../config";
const Box = styled.div`
  user-select: none;
  width: 32vw;
  height: 19vw;
  margin-left: 3vw;
  // border-radius: 0.5vw;
  position: absolute;
  // display:flex;
  // left: 0%;
  // top: 0%;
  background: rgba(0, 0, 0, 0.8);
  // display: flex;
  z-index: 999;
  justify-content: center;
  margin-top: -19.2vw;
  border-radius: 7px;

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          min-width: 65vw;
          min-height: 28vw;
          margin-top: -28.2vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          min-width: 65vw;
          min-height: 33vw;
          margin-top: -33.2vw;
        `}
`;
const load = keyframes`
    0% {transform: rotate(0deg);}
    50% {transform: rotate(180deg);}
    100% {transform: rotate(360deg);}
    
`;
const Load = styled.div`
  animation-name: ${load};
  animation-duration: 2s;
  animation-direction: normal;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  user-select: none;
  background-image: url("../${config.theme}/ico_loading.png");
  background-size: 5vw;
  width: 32vw;
  height: 100%;
  // position: fixed ;
  /* background-size: contain; */
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  background-size: 4vw;
  margin-top: -3%;
  object-fit: "contain";
  user-select: none;
  //Portrait
  @media (orientation: portrait) {
    width: auto;
    display: flex;
    align-items: center;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: auto;
          display: flex;
          align-items: center;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: auto;
          display: flex;
          align-items: center;
        `} */
`;

const Text = styled.div`
  color: #ff691f;
  font-size: 1.5vw;
  font-weight: 100;
  display: flex;
  justify-content: center;
  margin-top: -5vw;
  position: fixed;
  // margin-left: 15vw;
  width: 32%;
  user-select: none;
  //Portrait
  @media (orientation: portrait) {
    left: 33vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    width: 65vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          left: 33vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 65vw;
        `} */
`;

function Loading() {
  const { t, i18n } = useTranslation();
  return (
    <Box>
      <Load />
      <Text>{t("upload")}</Text>
    </Box>
  );
}
export default Loading;
