import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import breakpoint from "../breakpoints";
import { selectHomeContentLP } from "../../Reducers/homeContentLPSlice";
import BannerItem from "./BannerItem";

import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper core and required modules
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";
// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation]);

const HomeBannerContainer = styled.div`
  user-select: none;
  width: 60vw;
  // margin: 5vw auto 0vw auto;
  margin: 0vw auto 0vw auto;
  position: relative;
  @media ${breakpoint.device.mobile} {
    width: 96%;
    margin: 10vw 2%;
  }
`;



const NavigationButton = styled.div`
  width: 60vw;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  z-index: 10;
  .swiper-button-disabled {
    opacity: 0.75;
  }
  @media ${breakpoint.device.mobile} {
    width: 100%;
  }
`;

const SwiperPrevButton = styled.img`
  width: 2vw;
  cursor: pointer;
  @media ${breakpoint.device.mobile} {
    width: 6vw;
  }
`;

const SwiperNextButton = styled.img`
  width: 2vw;
  cursor: pointer;
  transform: scaleX(-1);
  @media ${breakpoint.device.mobile} {
    width: 6vw;
  }
`;

const SwiperFix = styled(Swiper)`
  overflow: hidden;
  .swiper-wrapper {
    width: max-content;
    /* height: 30vw; */
    display: flex;
    justify-content: center;
  }
  .swiper-slide {
    position: relative;
  }
`;

const Bullets = styled.div`
  width: max-content;
  display: flex;
  position: absolute;
  bottom: 3.5vw;
  left: 45%;
  z-index: 10;
  .swiper-pagination-bullet {
    background: rgba(255, 112, 0, 0.3);
    width: 0.75vw;
    height: 0.75vw;
    border-radius: 10vw;
    margin: 0.3vw;
    cursor: pointer;
  }
  .swiper-pagination-bullet-active {
    background: #ff7000;
  }
  @media ${breakpoint.device.mobile} {
    bottom: -5vw;
    left: 33%;
    .swiper-pagination-bullet {
      width: 3.2vw;
      height: 3.2vw;
      margin: 2vw;
    }
  }
`;

function HomeBanner() {
  // let { path, url } = useRouteMatch();
  // const { t, i18n } = useTranslation();
  // const dimensionsLP = useSelector(selectDimensionsLP);
  // const [emitError, setEmitError] = useState({ status: false, message: "" });
  const homeContent = useSelector(selectHomeContentLP);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (homeContent.length !== 0) {
      setItems(homeContent.find((i) => i.location === "top").contents);
    }
  }, [homeContent]);

  return (
    <>
      <HomeBannerContainer>
        <NavigationButton>
          <SwiperPrevButton
            className="swiper-button-prev"
            src="../../lpimages/promo_slide_arrow.png"
          />
          <SwiperNextButton
            className="swiper-button-next"
            src="../../lpimages/promo_slide_arrow.png"
          />
        </NavigationButton>

        <SwiperFix
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          pagination={{
            clickable: true,
            el: ".bullets",
            renderBullet: function (index, className) {
              return '<div class="' + className + '"></div>';
            },
          }}
          spaceBetween={10}
        // autoplay={{
        //     delay: 5000,
        //     disableOnInteraction: false,
        // }}
        >
          {items.map((item, index) => (
            <SwiperSlide key={"home-banner-" + index}>
              <BannerItem {...item} />
            </SwiperSlide>
          ))}

          <Bullets className="bullets"></Bullets>
        </SwiperFix>
      </HomeBannerContainer>
    </>
  );
}

export default HomeBanner;
