import socketio from "socket.io-client";
import { createContext } from "react";
import { config, getJWT } from "../config"
import { _colorStringFilter } from "gsap/gsap-core";
export let socket = socketio.connect(config.socketEndpoint, {
    path: config.socketEndpointPath,
    pingInterval: 10000,
    pingTimeout: 5000,
    transports: ['websocket'],
    query: { jwtToken: getJWT() }
});


export const reSocket = async (jwtToken) => {
    if (typeof jwtToken == "undefined")
        jwtToken = getJWT()
    socket = await socketio.connect(config.socketEndpoint, {
        path: config.socketEndpointPath,
        pingInterval: 10000,
        pingTimeout: 5000,
        transports: ['websocket'],
        query: { jwtToken: jwtToken }
    })
}
export const SocketContext = createContext();

export const socketKafka = socketio.connect(config.socketKafkaEndpoint, {
    path: config.socketKafkaEndpointPath,
    pingInterval: 10000,
    pingTimeout: 5000,
    transports: ['websocket'],
})
export const SocketKafkaContext = createContext();