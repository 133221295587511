import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { config } from "../../config";
import { actionCloseSound } from "../../Reducers/userProfileSlice";

const Popup = styled.div`
  position: absolute;
  /* background: #f87d7d; */
  background: #192756;
  /* box-shadow: inset 0px 0px 3px 1px #ffc3c3, inset 0px 0px 20px #ffdedc; */
  box-shadow: inset 0px 0px 3px 1px #5dd8ff,
    inset 0px 0px 45px rgba(66, 255, 232, 0.63);
  width: 43vw;
  height: 25vw;
  border-radius: 2vw;
`;

const Background = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  position: absolute;
  left: 0%;
  bottom: 0%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  z-index: 31;
  align-items: center;
  justify-content: center;
`;

const Close = styled.img`
  user-select: none;
  width: 4vw;
  position: absolute;
  z-index: 1;
  right: -4.3%;
  top: -5.5%;
  cursor: pointer;
`;

const Content = styled.div`
  position: relative;
  text-align: center;
  width: 34vw;
  height: 29vw;
  margin: 0 auto;
  top: 1vw;
  /* background: #fffbd8; */
  /* box-shadow: inset 0px 0px 3px 1px #ffffff,
    inset 0px 0px 20px rgba(236, 152, 106, 0.63); */
  border-radius: 25px;
  padding: 1vw 0vw;
  h5 {
    margin-top: 0vw;
    font-size: 2.5vw;
    font-weight: 100;
    color: #fff;
  }
  img {
    width: 80%;
    margin: 0 1vw;
  }
`;

const RewardList = styled.div`
  height: 75%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const Detail = styled.div`
  position: relative;
  width: ${(props) => (props.length === 4 ? "34%" : "30%")};
`;

const DiamondBox = styled.div`
  position: absolute;
  bottom: 0;
  left: 20%;
  /* width: 6vw; */
  width: 8.5vw;
  height: 2.5vw;
  border-radius: 2vw;
  /* background-color: #ff4d51; */
  background-color: #7f2225;
  display: flex;
  align-items: center;
  .diamond {
    width: 35%;
    margin: 0.5vw;
  }
  p {
    color: #ffffff;
    font-size: 1.25vw;
    font-weight: 700;
  }
`;

function RewardDetailsPopup(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Background>
      <Popup>
        <Close
          alt=""
          src={"../" + config.theme + "/btn_close.png"}
          onClick={() => {
            props.closeClick();
            dispatch(actionCloseSound());
          }}
        />
        <Content
          style={{ width: props.widthContent, height: props.heightContent }}
        >
          <h5>{t("details")}</h5>
          <RewardList>
            {props.item.map((item, index) => (
              <Detail length={props.item.length} key={"Index: " + index}>
                <img
                  alt=""
                  src={
                    item.no === 1
                      ? "../" + config.theme + "/event_val/item1.png"
                      : item.no === 2
                        ? "../" + config.theme + "/event_val/item2.png"
                        : item.no === 3
                          ? "../" + config.theme + "/event_val/item3.png"
                          : item.no === 4 || item.no === 5
                            ? "../" + config.theme + "/event_val/item4.png"
                            : ""
                  }
                // itemType={item.no}
                />
                <DiamondBox>
                  <img
                    className="diamond"
                    src={"../" + config.theme + "/icon_diamond_copy.png"}
                  />
                  <p>{item.c1}</p>
                </DiamondBox>
              </Detail>
            ))}
          </RewardList>
        </Content>
      </Popup>
    </Background>
  );
}
export default RewardDetailsPopup;
