import React from 'react'
import styled from 'styled-components';
import breakpoint from "../breakpoints";

const Box = styled.div`
    width:8vw;
    display:block;
    // cursor: pointer;
    position:relative;
    > img {
        width: 6vw;
    }
    @media ${breakpoint.device.ipad} {
        width:11vw;
        display:block;
        // cursor: pointer;
        position:relative;
        img {
            width: 8vw;
        }
    }
    @media ${breakpoint.device.mobile} {
        width: 30%; 
        display: flex;
        justify-content: center;
        > img {
            width: initial;
            height: 8.74vw;
            margin: 2vw;
        }
    }

    
`;


function ProvidersItem(props) {
    let img = ""


    if (props.img === "xgaming") {
        img = "../../lpimages/partner_logo/spinix_normal_white.png";

    }
    else if (props.img === "PGslot") {
        img = "../../lpimages/../../lpimages/partner_logo/PGSOFT_Logo-Reversed.png";

    }
    else if (props.img === "ambpoker_logo") {
        img = "../../lpimages/partner_logo/ambpoker_logo.png";

    }
    else if (props.img === "jokergame") {
        img = "../../lpimages/partner_logo/jokergame.png";

    }
    else if (props.img === "evoplay") {
        img = "../../lpimages/partner_logo/evoplay.png";

    }
    else if (props.img === "playstar") {
        img = "../../lpimages/partner_logo/playstar.png";

    }
    else if (props.img === "jili") {
        img = "../../lpimages/partner_logo/jili.png";

    }
    else if (props.img === "redtiger") {
        img = "../../lpimages/partner_logo/redtiger.png";

    }
    else if (props.img === "ygg") {
        img = "../../lpimages/partner_logo/ygg.png";

    }
    else if (props.img === "Sa-gaming") {
        img = "../../lpimages/partner_logo/Sa-gaming.png";

    }
    else if (props.img === "Sexy-gaming") {
        img = "../../lpimages/partner_logo/Sexy-gaming.png";

    }
    else if (props.img === "Pragmatic") {
        img = "../../lpimages/partner_logo/Pragmatic.png";

    }
    else if (props.img === "allbet") {
        img = "../../lpimages/partner_logo/allbet.png";

    }
    else if (props.img === "dreamgaming") {
        img = "../../lpimages/partner_logo/dreamgaming.png";

    }
    else if (props.img === "Pretty-Gaming") {
        img = "../../lpimages/partner_logo/Pretty-Gaming.png";

    }


    return (
        <Box>

            <img src={img} alt="" />
        </Box>
    )
}

export default ProvidersItem;