import React from "react";
import breakpoint from "./breakpoints";
import styled, { css, keyframes } from "styled-components";
import { config } from "../config"
const ButtonAll = styled.button`
  border: 0;
  border: transparent;
  background-color: transparent;
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 0%;
  justify-content: center;
  &:focus {
    outline: none;
  }

  div {
    font-size: 1vw;
    color: white;
    right: 0vw;
    left: 0vw;
    align-items: center;
  }
  img {
    width: 100%;
    height: 100%;
  }
`;

const RedSquare = styled.button`
  /* border-radius: 0.5vw;
  border: transparent;
  background-image: linear-gradient(to top, #900000, #e13b00); */
  border: none;
  background: transparent;
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 0%;
  justify-content: center;
  // width: 30vw;
  // height: 4vw;
    // pumpchi added below vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv

    border-radius: 0.5vw;
    overflow: show;
    box-shadow: inset 0px 0px 4px 2px #FF814D;
    background: rgb(144,0,0);
    background: linear-gradient(0deg, rgba(144,0,0,1) 20%, rgba(255,76,0,1) 100%, rgba(255,76,0,1) 80%, rgba(144,0,0,1) 99%);
    @media ${breakpoint.device.mobile} {
      border-radius: 2.5vw;
    }
    // end of pumpchi added ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
  &:focus {
    outline: none;
    
  }

  div {
    width: 100%;
    height: 100%;
    // background: linear-gradient(180deg, #e13b00 38.02%, #900000 100%);
    // box-shadow: inset 0px 0px 4px 2px #ff814d;
    border-radius: 0.5vw;
    font-size: 1vw;
    color: white;
    position: absolute;
    top: 0;
    right: 0vw;
    left: 0vw;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  ${(props) => css`
    width: ${props.width};
    height: ${props.height};
  `}
  @media ${breakpoint.device.mobile} {
    div {
      border-radius: 2.5vw;
    }
  }
`;
const RedSquareM = styled.button`
  /* border-radius: 0.5vw;
  border: transparent;
  background-image: linear-gradient(to top, #900000, #e13b00); */
  border: none;
  background: transparent;
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 0%;
  justify-content: center;
  // width: 30vw;
  // height: 4vw;
    // pumpchi added below vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv

    border-radius: 0.5vw;
    overflow: show;
    box-shadow: inset 0px 0px 4px 2px #FF814D;
    background: rgb(144,0,0);
    background: linear-gradient(0deg, rgba(144,0,0,1) 20%, rgba(255,76,0,1) 100%, rgba(255,76,0,1) 80%, rgba(144,0,0,1) 99%);
    @media ${breakpoint.device.mobile} {
      border-radius: 2.5vw;
    }
    // end of pumpchi added ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
  &:focus {
    outline: none;
    
  }

  div {
    width: 100%;
    height: 100%;
    // background: linear-gradient(180deg, #e13b00 38.02%, #900000 100%);
    // box-shadow: inset 0px 0px 4px 2px #ff814d;
    border-radius: 0.5vw;
    font-size: 4vw;
    color: white;
    position: absolute;
    top: 0;
    right: 0vw;
    left: 0vw;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  ${(props) => css`
    width: ${props.width};
    height: ${props.height};
  `}
  @media ${breakpoint.device.mobile} {
    div {
      border-radius: 2.5vw;
    }
  }
`;
const reflect = keyframes`
  0% {background-position: 0 0;}
  50% {background-position: 20.1vw 0;}
  100% {background-position: 20.1vw 0;}
`
const Reflect = styled.div`
  width: 19.1vw!important;
  border-radius: 10px;
  color: white;
  background: linear-gradient(119deg, rgba(255,76,0,0) 63%, rgba(255,129,77,1) 82%, rgba(255,157,77,1) 85%, rgba(255,76,0,0) 90%);
  animation: ${reflect} 1.8s ease-in-out infinite;
  z-index:0;
  position: realative;

`;
const reflectm = keyframes`
  0% {background-position: 0 0;}
  50% {background-position: 60.1vw 0;}
  100% {background-position: 60.1vw 0;}
`
const ReflectM = styled.div`
  width: 60.1vw!important;
  border-radius: 10px;
  color: white;
  background: linear-gradient(119deg, rgba(255,76,0,0) 63%, rgba(255,129,77,1) 82%, rgba(255,157,77,1) 85%, rgba(255,76,0,0) 90%);
  animation: ${reflectm} 1.8s ease-in-out infinite;
  z-index:0;
  position: realative;

`;

// const RedSquare = styled.button`
//   border-radius:0.5vw;
//   border: transparent;
//   background-image: linear-gradient(to top ,#900000, #e13b00);
//   position: relative;
//   cursor: pointer;
//   display:flex;
//   padding: 0%;
//   justify-content: center;
//   width:20vw;
//   height:4vw;
//   &:focus {
//     outline: none;
// }
//   div{
//     font-size:1vw;
//     color: white;
//     right: 0vw;
//     left: 0vw;
//     align-items: center;
//   }
//   ${props => css`
//     width: ${props.width};
//     height: ${props.height};
//   `}
// `;

const OrangeBottom = styled.button`
  border-radius: 0.5vw;
  border: transparent;
  /* background-image: linear-gradient(to top, #192040, #4d5372); */
  background: linear-gradient(180deg, #ff691f 38.02%, #ff691f 100%);
  box-shadow: inset 0px 0px 4px 2px #ff691f;
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 0%;
  justify-content: center;
  width: 8vw;
  height: 3.8vw;
  &:focus {
    outline: none;
  }

  div {
    font-size: 1vw;
    color: white;
    top: 0;
    right: 0vw;
    left: 0vw;
    align-items: center;
  }

  @media ${breakpoint.device.mobile} {
    border-radius: 2.5vw;
  }
`;
const GraySquare = styled.button`
  border-radius: 0.5vw;
  border: transparent;
  /* background-image: linear-gradient(to top, #192040, #4d5372); */
  background: linear-gradient(180deg, #797fa2 38.02%, #4e5476 100%);
  box-shadow: inset 0px 0px 4px 2px #c5c9ec;
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 0%;
  justify-content: center;
  width: 8vw;
  height: 3.8vw;
  &:focus {
    outline: none;
  }

  div {
    font-size: 1vw;
    color: white;
    top: 0;
    right: 0vw;
    left: 0vw;
    align-items: center;
  }

  @media ${breakpoint.device.mobile} {
    border-radius: 2.5vw;
  }
`;

const RequetOTP = styled.button`
  border-radius: 0.5vw;
  border: transparent;
  /* background-image: linear-gradient(to top,#192040,#4d5372); */
  background-color: #484e6b;
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 0%;
  justify-content: center;
  width: 8vw;
  height: 3.8vw;
  &:focus {
    outline: none;
  }

  div {
    font-size: 1vw;
    color: white;
    top: 0;
    right: 0vw;
    left: 0vw;
    align-items: center;
  }
`;

const Next = styled.button`
  border-radius: 0.5vw;
  border: transparent;
  /* background-color: #06d746; */
  background: linear-gradient(180deg, #06d746 38.02%, #059604 100%);
  box-shadow: inset 0px 0px 4px 2px #4dff80;
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 0%;
  justify-content: center;
  width: 8vw;
  height: 3.8vw;
  &:focus {
    outline: none;
  }

  div {
    font-size: 1vw;
    color: white;
    top: 0;
    right: 0vw;
    left: 0vw;
    align-items: center;
  }
  @media ${breakpoint.device.mobile} {
    border-radius: 2.5vw;
  }
`;

const Back = styled.button`
  border-radius: 0.5vw;
  border: transparent;
  background-color: #717799;
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 0%;
  justify-content: center;
  width: 8vw;
  height: 3.8vw;
  &:focus {
    outline: none;
  }

  div {
    font-size: 1vw;
    color: white;
    right: 0vw;
    left: 0vw;
    align-items: center;
  }
`;

const Orange = styled.button`
  border-radius: 0.5vw;
  border: transparent;
  background: linear-gradient(180deg, #ffc700 38.02%, #ff691f 100%);
  box-shadow: inset 0px 0px 4px 2px #ffc700;
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 0%;
  justify-content: center;
  width: 8vw;
  height: 3.8vw;
  text-shadow: 0 0 1.6vw #833502;
  &:focus {
    outline: none;
  }

  div {
    font-size: 1vw;
    color: white;
    top: 0;
    right: 0vw;
    left: 0vw;
    align-items: center;
  }
  @media ${breakpoint.device.mobile} {
    border-radius: 2.5vw;
  }
`;

function Button(props) {
  let img = "../../lpimages/btn_register.png";

  if (props.bClass === "rbGray") {
    img = "../../lpimages/btn_login.png";
  }
  if (props.bClass === "rbGreen") {
    img = "../../" + config.theme + "/btn_green.png";
  }
  if (props.bClass === "rbRed") {
    img = "../../lpimages/btn_register.png";
  }
  if (props.bClass === "rbGraySquare") {
    img = "../../lpimages/btn_gray.png";
  } else if (props.bClass === "rtGreen") {
    img = "../../lpimages/btn_popup_login.png";
  }


  return (
    <>
      {props.bClass === "rtRedLoginM" ? (
        <RedSquareM
          style={{ width: props.width, height: props.height }}
          {...props}
        >

          <div
          // style={{
          //   display: "flex",
          //   width: "100%",
          //   height: "100%",
          //   position: "absolute",
          //   zIndex: 1,
          //   justifyContent: "center",
          //   borderRadius: "0.5vw",
          //   background:
          //     "linear-gradient(180deg, #E13B00 38.02%, #900000 100%)",
          //   boxShadow: "inset 0px 0px 4px 2px #ff814d",
          // }}
          >
            <ReflectM></ReflectM>
            <div style={({ fontSize: props.fontSize })}>
              {props.label}
            </div>
          </div>
        </RedSquareM>
      ) : props.bClass === "rtRedLogin" ? (
        <RedSquare
          style={{ width: props.width, height: props.height }}
          {...props}
        >

          <div
          // style={{
          //   display: "flex",
          //   width: "100%",
          //   height: "100%",
          //   position: "absolute",
          //   zIndex: 1,
          //   justifyContent: "center",
          //   borderRadius: "0.5vw",
          //   background:
          //     "linear-gradient(180deg, #E13B00 38.02%, #900000 100%)",
          //   boxShadow: "inset 0px 0px 4px 2px #ff814d",
          // }}
          >
            <Reflect></Reflect>
            <div style={({ fontSize: props.fontSize })}>
              {props.label}
            </div>
          </div>
        </RedSquare>
      ) : props.bClass === "rtRed" ? (
        <RedSquare
          style={{ width: props.width, height: props.height }}
          {...props}
        >

          <div
          // style={{
          //   display: "flex",
          //   width: "100%",
          //   height: "100%",
          //   position: "absolute",
          //   zIndex: 1,
          //   justifyContent: "center",
          //   borderRadius: "0.5vw",
          //   background:
          //     "linear-gradient(180deg, #E13B00 38.02%, #900000 100%)",
          //   boxShadow: "inset 0px 0px 4px 2px #ff814d",
          // }}
          >
            <div style={({ fontSize: props.fontSize })}>
              {props.label}
            </div>
          </div>
        </RedSquare>
      ) : props.bClass === "rtGray" ? (
        <GraySquare
          style={{
            width: props.width,
            height: props.height,
            cursor: props.cursor,
          }}
          {...props}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: 10,
              justifyContent: "center",
            }}
          >
            <div style={({ fontSize: props.fontSize })}>
              {props.label}
            </div>
          </div>
        </GraySquare>
      ) : props.bClass === "btOrange" ? (
        <OrangeBottom
          style={{
            width: props.width,
            height: props.height,
            cursor: props.cursor,
          }}
          {...props}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: 10,
              justifyContent: "center",
            }}
          >
            <div style={({ fontSize: props.fontSize })}>
              {props.label}
            </div>
          </div>
        </OrangeBottom>
      ) : props.bClass === "rtRequest" ? (
        <RequetOTP
          style={{
            width: props.width,
            height: props.height,
            marginTop: props.requestTop,
            marginLeft: props.requestLeft,
          }}
          {...props}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: 10,
              justifyContent: "center",
            }}
          >
            <div style={({ fontSize: props.fontSize })}>
              {props.label}
            </div>
          </div>
        </RequetOTP>
      ) : props.bClass === "rtNext" ? (
        <Next
          style={{
            width: props.width,
            height: props.height,
            marginTop: props.requestTop,
            marginLeft: props.requestLeft,
          }}
          {...props}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: 10,
              justifyContent: "center",
            }}
          >
            <div style={({ fontSize: props.fontSize })}>
              {props.label}
            </div>
          </div>
        </Next>
      ) : props.bClass === "rtBack" ? (
        <Back
          style={{
            width: props.width,
            height: props.height,
            marginTop: props.requestTop,
            marginLeft: props.requestLeft,
          }}
          {...props}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: 10,
              justifyContent: "center",
            }}
          >
            <div style={({ fontSize: props.fontSize })}>
              {props.label}
            </div>
          </div>
        </Back>
      ) : props.bClass === "rtOrange" ? (
        <Orange
          style={{
            width: props.width,
            height: props.height,
            cursor: props.cursor,
          }}
          {...props}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: 10,
              justifyContent: "center",
            }}
          >
            <div style={({ fontSize: props.fontSize })}>
              {props.label}
            </div>
          </div>
        </Orange>
      ) : (
        <ButtonAll
          style={{ width: props.width, height: props.height }}
          {...props}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: 10,
              justifyContent: "center",
            }}
          >
            <div style={({ fontSize: props.fontSize })}>
              {props.label}
            </div>
          </div>
          <img alt="" src={img} />
        </ButtonAll>
      )}
    </>
  );
}
export default Button;
