import "../styles.css";
import { useDispatch, useSelector } from "react-redux";
import Button from "./Button";
import NumberFormat from "react-number-format";
import React, { useContext, useState } from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import {
  actionClickSound,
  selectuserProfile,
} from "../Reducers/userProfileSlice";
import { Transition, config } from "../config";
import WithdrawalComplete from "./WithdrwalComplete";
import { SocketContext } from "../context/socket";
import AlertMessage from "./Dialog/AlertMessage";
import WithdrawalFail from "./WithdrawalFail";
import WithdrawalFailerror from "./WithdrawalFailerror";
import { selectPopup, actionPopup } from "../Reducers/popupSlice";

const BG = styled.div`
  user-select: none;
  align-self: center;
`;

const Textone = styled.div`
  user-select: none;
  font-size: 1.2vw;
  width: 100%;
  text-align: center;
  top: 1.5vw;
  color: #bdb9d1;
  font-size: 1.5vw;
  position: relative;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 3vw;
          top: 3.5vw;
          position: relative;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          position: relative;
          font-size: 2.5vw;
          top: 2.5vw;
        `}
`;

const Texttwo = styled.div`
  user-select: none;
  color: #bdb9d1;
  font-size: 1.2vw;
  position: relative;
  margin-top: 8vw;
  /* left: 10vw; */
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          color: #bdb9d1;
          font-size: 2.95vw;
          position: relative;
          left: 0vw;
          margin-top: 12vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          position: relative;
          left: 0vw;
          margin-top: 10vw;
          font-size: 2vw;
        `}
`;

const Popupconfirm = styled.div`
  user-select: none;
  // background-image: url("/images/popup_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;
  border-radius: 2vw;
  // theme ==================
  /* background: #192756;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #5DD8FF, inset 0px 0px 4vw rgba(66, 255, 232, 0.63);
  border: solid 0.2vw #BDEFFF; */
  // theme ==================
  position: relative;
  z-index: 70;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  // เพิ่ม padding ด้านข้างกรณี title ทะลุ start below ========
  padding: 0 2vw;
  width: 27vw;
  height: 25vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 59vw;
          height: fit-content;
          padding: 4vw 2vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 44vw;
          height: 33vw;
        `}
`;

const CloseBtn = styled.img`
  user-select: none;
  position: absolute;
  width: 3vw;
  height: 3vw;
  right: -4%;
  top: -1.35vw;
  cursor: pointer;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 7vw;
          height: 7vw;
          top: -3vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 5vw;
          height: 5vw;
        `}
`;

const BgClose = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  background-color: transparent;
  z-index: 20;
  left: 0%;
  top: 0%;
  position: fixed;
`;

const BGPopup = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 70;
  align-items: center;
  justify-content: center;
`;

const Bgblack = styled.div`
  user-select: none;
  height: 4vw;
  width: 20vw;
  background-color: #000;
  border-radius: 0.5vw;
  position: relative;
  margin-top: -6.5vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          height: 7vw;
          right: 0vw;
          width: 31vw;
          border-radius: 1.5vw;
          margin-top: -12vw;
          position: relative;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          position: relative;
          right: 0vw;
          border-radius: 1vw;
          height: 5vw;
          width: 28vw;
          margin-top: -9.5vw;
        `}
`;

const Bgbank = styled.div`
  user-select: none;
  height: 4vw;
  width: 25vw;
  background-color: #000;
  border-radius: 0.5vw;
  position: relative;
  margin-top: 3vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          color: #bdb9d1;
          font-size: 2.95vw;
          position: relative;
          height: 8vw;
          width: 48vw;
          background-color: #000;
          border-radius: 1.5vw;
          left: 0vw;
          margin-top: 6vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          position: relative;
          left: 0vw;
          height: 6vw;
          width: 38vw;
          margin-top: 5vw;
        `}
`;

const BankNumber = styled.div`
  user-select: none;
  font-size: 2vw;
  color: #ffffff;
  margin-top: 0.8vw;
  margin-left: 6vw;
  position: absolute;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 4.3vw;
          margin-left: 9vw;
          align-items: center;
          /* margin-left: vw; */
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 3vw;
          margin-left: 7vw;
          margin-top: 1vw;
        `}
`;

const Textthree = styled.div`
  user-select: none;
  color: #ff4c00;
  font-size: 1.2vw;
  margin-top: 1vw;
  text-align: center;
  position: relative;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2.5vw;
          margin-top: 2vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 1.5vw;
          margin-top: 1vw;
          position: relative;
        `}
`;

const Bank = styled.img`
  user-select: none;
  width: 3.3vw;
  margin-left: -21vw;
  margin-top: 0.35vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 7.3vw;
          margin-left: -40vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 5.3vw;
          margin-left: -32vw;
        `}
`;

const BoxMoney = styled.div`
  color: #fff;
  font-size: 1.8vw;
  margin-top: 4%;
  margin-left: 7.5vw;
  position: absolute;
  align-items: center;
  /* font-weight: 400; */
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          position: relative;
          margin-left: 0vw;
          margin-top: 0;
          padding-top: 1vw;
          font-size: 3.8vw;
          align-items: center;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          position: relative;
          margin-left: 0vw;
          font-size: 3.5vw;
        `}
`;

const BoxButton = styled.div`
  left: 8.5vw;
  margin-top: 3vw;
  position: absolute;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          left: 19.5vw;
          margin-top: 1vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          left: 15.5vw;
          margin-top: 2vw;
        `}
`;

function WithdrawalConfirm(props) {
  const { t } = useTranslation();
  const profile = useSelector(selectuserProfile);
  const [message, setMessage] = useState("");
  const [complete, setComplete] = useState(false);
  const [fail, setFail] = useState(false);
  const dispatch = useDispatch();
  const popupStatus = useSelector(selectPopup);
  const socket = useContext(SocketContext);
  const [emitError, setEmitError] = useState({ status: false, message: "" });
  const [popupLess, setPopupLess] = useState(false);
  const [turnover, setTurnover] = useState("");
  const [showTurnover, setShowTurnover] = useState(false);

  return (
    <BGPopup>
      <BgClose onClick={props.closeClick} />
      <Popupconfirm closeClick={props.closeClick}>
        <CloseBtn
          src={"../" + config.theme + "/btn_close.png"}
          onClick={() => {
            props.setToConfirm();
            dispatch(actionClickSound());
            dispatch(actionPopup({ withdrawal: false }));
          }}
        />
        <Textone>{t("confirm withdrawal")}</Textone>

        <Texttwo>{t("confirm transfer")}</Texttwo>
        <Bgblack>
          <BoxMoney>
            <NumberFormat
              value={profile.balance}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
            ></NumberFormat>
          </BoxMoney>
        </Bgblack>

        <Bgbank>
          <BankNumber>
            {typeof profile.bankAccount !== "undefined"
              ? profile.bankAccount.length === 10
                ? profile.bankAccount.slice(0, 3) +
                  "-" +
                  profile.bankAccount.slice(3, 4) +
                  "-" +
                  profile.bankAccount.slice(4, 9) +
                  "-" +
                  profile.bankAccount.slice(9, 10)
                : profile.bankAccount
              : ""}
          </BankNumber>
          <Bank
            // src={"../"+config.theme+"/" + BankBanner(profile.bankShortName) + ".png"}
            src={
              profile.bankShortName === ""
                ? ""
                : "../" +
                  config.theme +
                  "/bicon_" +
                  profile.bankShortName +
                  ".png"
            }
          />
        </Bgbank>

        <Textthree>{t("confirm check")}</Textthree>
        <BoxButton>
          <Button
            bClass="rbGreen"
            label={t("confirm")}
            width={
              window.appHeight() / window.innerWidth > config.verticalSizeiphone
                ? "20vw"
                : window.appHeight() > window.innerWidth
                ? "17vw"
                : window.innerWidth / window.appHeight() > 2.15
                ? "13vw"
                : "13vw"
            }
            fontSize={
              window.appHeight() / window.innerWidth > config.verticalSizeiphone
                ? "3vw"
                : window.appHeight() > window.innerWidth
                ? "2vw"
                : window.innerWidth / window.appHeight() > 2.15
                ? "1.5vw"
                : "1.5vw"
            }
            // fontSize="1.5vw"
            top="unset"
            onClick={() => {
              socket.emit("withdraw", {}, (body) => {
                console.log("withdraw", body);
                if (body.errorCode === "432") {
                  setShowTurnover(true);
                }
                if (
                  typeof body === "undefined" ||
                  typeof body.results === "undefined" ||
                  typeof body.results.data === "undefined"
                ) {
                } else if (body.status === true) {
                  setComplete({ status: true });
                } else if (
                  body.errorCode === "s0110" ||
                  body.errorCode === "s0111" ||
                  body.errorCode === "s0112" ||
                  body.errorCode === "s0113" ||
                  body.errorCode === "s0114" ||
                  body.errorCode === "s0115" ||
                  body.errorCode === "s0043"

                  // body.errorCode == "s0113"
                  // body.errorCode == "400" ||
                  // body.errorMessage === "can_not_withdraw_less_than_1_bath"
                ) {
                  // setFailerror(true);
                  dispatch(actionPopup({ withdrawalError: true }));

                  if (body.errorCode === "400") {
                    // console.log("400", body);
                    setMessage(t(body.errorMessage));
                  } else {
                    setMessage(body.errorMessage + ": " + t(body.errorCode));
                  }

                  // setEmitError({
                  //   status: true,
                  //   message:
                  //     body.errorCode +
                  //     ": " +
                  //     t(body.errorCode).replace("{0}", body.errorMessage),
                  // });
                } else if (body.errorCode === "s0117") {
                  setMessage(body.errorMessage);
                } else {
                  // setFailerror(true);
                  dispatch(actionPopup({ withdrawalError: true }));

                  if (body.errorCode === "432") {
                    setMessage(t(body.errorCode));
                    setTurnover(body.errorMessage);
                  } else if (body.errorCode == "400") {
                    setMessage(body.errorCode + ": " + t(body.errorMessage));
                  } else if (body.errorCode === "s0173") {
                    setMessage(body.errorMessage + ": " + t(body.errorCode));
                  } else if (body.errorCode === "433") {
                    setMessage(body.errorCode + ": " + t(body.errorCode));
                  } else {
                    setMessage(
                      body.errorCode +
                        ": " +
                        t(body.errorCode).replace("{0}", body.errorMessage)
                    );
                  }
                }
              });
            }}
          ></Button>
        </BoxButton>
      </Popupconfirm>

      <Transition showPop={popupLess} type="alert">
        <AlertMessage
          message={t("can_not_withdraw_less_than_1_bath")}
          onClick={() => {
            setPopupLess(false);
          }}
        />
      </Transition>

      <Transition showPop={complete.status} type="alert">
        {/* <Transition showPop={true} type="alert"> */}
        <BG style={{ justifyContent: "center" }}>
          <WithdrawalComplete
            setComplete={() => setComplete({ status: false })}
            closeClick={props.closeClick}
          />
        </BG>
      </Transition>

      <Transition showPop={fail} type="alert">
        <BG style={{ justifyContent: "center" }}>
          <WithdrawalFail
            setFail={() => setFail({ status: false })}
            closeClick={props.closeClick}
          />
        </BG>
      </Transition>

      <Transition showPop={popupStatus.withdrawalError} type="alert">
        {/* <BG style={{ justifyContent: "center" }}> */}
        <WithdrawalFailerror
          message={message}
          turnover={turnover}
          showTurnover={showTurnover}
          setToConfirm={props.setToConfirm}
          setFailerror={() =>
            dispatch(
              actionPopup({ withdrawalError: false }),
              dispatch(actionPopup({ withdrawaltopup: false }))
            )
          }
          closeClick={props.closeClick}
        />
        {/* </BG> */}
      </Transition>

      <Transition showPop={emitError.status} type="alert">
        <AlertMessage
          message={emitError.message}
          onClick={() => {
            setEmitError({ status: false });
          }}
        />
      </Transition>
    </BGPopup>
  );
}
export default WithdrawalConfirm;
