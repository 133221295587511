import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import breakpoint from "../breakpoints";
// import Swiper core and required modules
import SwiperCore, { Navigation } from "swiper/core";
// install Swiper modules
SwiperCore.use([Navigation]);

const BG = styled.div`
  color: white;
  display: block;
  width: fit-content;
  margin: 0 auto;
  position: relative;
  height: fit-content;
  margin-bottom: -0.5vw;
  margin-top: -2vw;
  border-radius: 2vw;
  overflow: hidden;
  z-index: 0;
  background-image: url("../../lpimages/theseries/bg_cinema.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 70vw;
  height: 53vw;
  video {
    width: 48vw;
    border-radius: 2vw;
  }

  @media ${breakpoint.device.mobile} {
    background-image: url("../../lpimages/theseries/bg_cinema_m.png");
    background-size: contain;
    background-position-y: bottom;
    width: 84vw;
    height: 145vw;
    video {
      width: 100%;
    }
  }
`;

const Title = styled.div`
  text-align: center;
  margin: 3vw auto;
  img {
    width: 60%;
  }
  h1 {
    font-size: 1.875vw;
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.75);
    margin-top: -6vw;
  }
  @media ${breakpoint.device.mobile} {
    margin: 0vw auto 5vw auto;
    img {
      width: 100%;
    }
    h1 {
      font-size: 4vw;
      margin-top: -12.5vw;
    }
  }
`;

const VideoContainer = styled.div`
  text-align: center;
`;

const VideoShow = styled.div`
  height: 28vw;
  background-color: transparent;

  @media ${breakpoint.device.mobile} {
    height: 50vw;
  }
`;

const VideoList = styled.div`
  img {
    width: 20%;
    margin: 0.25vw;
    cursor: pointer;
  }
  @media ${breakpoint.device.mobile} {
    img {
      width: 48%;
    }
  }
`;

const NavigationButton = styled.div`
  width: 81%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 45%;
  left: 9%;
  z-index: 10;
  .swiper-button-disabled {
    opacity: 0.75;
  }
  @media ${breakpoint.device.mobile} {
    display: none;
  }
`;

const SwiperPrevButton = styled.img`
  width: 2vw;
  cursor: pointer;
  @media ${breakpoint.device.mobile} {
    width: 6vw;
  }
`;

const SwiperNextButton = styled.img`
  width: 2vw;
  cursor: pointer;
  transform: scaleX(-1);
  @media ${breakpoint.device.mobile} {
    width: 6vw;
  }
`;

function VidClip() {
  // const [videoItem, setvideoItem] = useState(0);
  const [videoSelected, setvideoSelected] = useState(0);
  const [video, setVideo] = useState("");
  const videoRef = useRef();

  const videoImage = (i) => {
    if (i === videoSelected) {
      return (
        <img alt=""
          key={"video" + i}
          src={"../../lpimages/theseries/ep" + (i + 1) + "_playing.png"}
          onClick={() => {
            setvideoSelected(i);
          }}
        />
      );
    } else {
      return (
        <img alt=""
          key={"video" + i}
          src={"../../lpimages/theseries/ep" + (i + 1) + "_idle.png"}
          onClick={() => {
            setvideoSelected(i);
          }}
        />
      );
    }
  };

  useEffect(() => {
    // console.log("videoSelected", videoSelected);
    if (videoSelected === 0) {
      setVideo("../../lpimages/video_promote/ep1.mp4");
    } else if (videoSelected === 1) {
      setVideo("../../lpimages/video_promote/ep2.mp4");
    } else if (videoSelected === 2) {
      setVideo("../../lpimages/video_promote/ep3.mp4");
    } else {
    }
    videoRef.current?.load();
  }, [videoSelected]);

  return (
    <BG>
      <Title>
        <img alt="" src="../../lpimages/theseries/img_ep_header.png" />
        <h1>Spinix The Series EP : {videoSelected + 1}</h1>
      </Title>
      <NavigationButton>
        <SwiperPrevButton
          className="swiper-button-prev"
          src="../../lpimages/promo_slide_arrow.png"
          onClick={() => {
            if (videoSelected > 0) {
              setvideoSelected(videoSelected - 1);
            } else {
              setvideoSelected(2);
            }
          }}
        />
        <SwiperNextButton
          className="swiper-button-next"
          src="../../lpimages/promo_slide_arrow.png"
          onClick={() => {
            if (videoSelected < 2) {
              setvideoSelected(videoSelected + 1);
            } else {
              setvideoSelected(0);
            }
          }}
        />
      </NavigationButton>

      <VideoContainer>
        <VideoShow>
          <video ref={videoRef} autoPlay loop muted controls>
            <source src={video} type="video/mp4" />
          </video>
        </VideoShow>
        <VideoList>
          {Array(3)
            .fill(null)
            .map((item, index) => videoImage(index))}
          <img alt="" src="../../lpimages/theseries/ep_soon.png" />
        </VideoList>
      </VideoContainer>
    </BG>
  );
}

export default VidClip;
