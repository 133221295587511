import React, { Fragment, useEffect } from "react";
import styled, { css } from "styled-components";
import { config } from "../../config";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";

const MainBG = styled.div`
  user-select: none;
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 1.5vw;

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 97%;
          height: 98%;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 90%;
          height: 90%;
        `}
`;

const PercentageTextKey = styled.div`
  width: 9%;
  height: ${() => (window.appHeight() > window.innerWidth ? "5%" : "5.5%")};
  font-size: ${() =>
    window.appHeight() > window.innerWidth ? "2vw" : "1.5vw"};
  color: #ffc700;
  /* background-color: rgb(0, 0, 255, 0.5); */
  position: absolute;
  text-align: center;
  ${(props) =>
    props.imageKey === 2
      ? css`
          font-size: ${() =>
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "2vw"
              : window.appHeight() > window.innerWidth
              ? "1.5vw"
              : "1.5vw"};
          bottom: ${() =>
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "19%"
              : window.appHeight() > window.innerWidth
              ? "19%"
              : "18.7%"};
          right: ${() =>
            window.appHeight() > window.innerWidth ? "45%" : "44.8%"};
        `
      : css`
          display: none;
        `}
`;

const BigPercentageTextKey = styled.div`
  width: 42%;
  height: 25.74%;
  font-size: ${() =>
    window.appHeight() > window.innerWidth ? "10vw" : "8.5vw"};
  font-weight: 800;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.imageKey === 1
      ? css`
          font-size: ${() =>
            window.appHeight() > window.innerWidth ? "10vw" : "5.5vw"};
          color: #fff;
          top: ${() =>
            window.appHeight() > window.innerWidth ? "24%" : "25%"};
          p {
            text-shadow: 0px 0px 1vw #f95e01, 0px 0px 1vw #f95e01,
              0px 0px 1vw #f95e01, 0px 0px 1vw #f95e01;
          }
        `
      : props.imageKey === 2
      ? css`
          color: #550000;
          top: ${() =>
            window.appHeight() > window.innerWidth ? "45%" : "45%"};
          p {
            text-shadow: 0px 0px 1vw #ffc700, 0px 0px 1vw #ffc700,
              0px 0px 1vw #ffc700, 0px 0px 1vw #ffc700;
          }
        `
      : props.imageKey === 5
      ? css`
          font-size: ${() =>
            window.appHeight() > window.innerWidth ? "8vw" : "4vw"};
          color: #fff;

          p {
            text-shadow: 0px 0px 1vw #ffc700, 0px 0px 1vw #ffc700,
              0px 0px 1vw #ffc700, 0px 0px 1vw #ffc700;
          }
          ${(props) =>
            props.id === 101
              ? css`
                  top: ${() =>
                    window.appHeight() > window.innerWidth ? "72%" : "72%"};
                  left: ${() =>
                    window.appHeight() > window.innerWidth ? "46%" : "46%"};
                `
              : css`
                  top: ${() =>
                    window.appHeight() > window.innerWidth ? "40%" : "40%"};
                  left: ${() =>
                    window.appHeight() > window.innerWidth ? "46%" : "46%"};
                `}
        `
      : props.imageKey === 4 || props.imageKey === 6
      ? css`
          color: #fff;
          top: ${() =>
            window.appHeight() > window.innerWidth ? "45%" : "45%"};
          ${(props) =>
            props.id === 101
              ? css`
                  p {
                    text-shadow: 0px 0px 1vw #5dd8ff, 0px 0px 1vw #5dd8ff,
                      0px 0px 1vw #5dd8ff, 0px 0px 1vw #5dd8ff;
                  }
                `
              : css`
                  p {
                    text-shadow: 0px 0px 1vw #f95e01, 0px 0px 1vw #f95e01,
                      0px 0px 1vw #f95e01, 0px 0px 1vw #f95e01;
                  }
                `}
        `
      : css`
          display: none;
        `}
`;

const CommissionBox = styled.div`
  width: 83.5%;
  height: ${() => (window.appHeight() > window.innerWidth ? "44%" : "49%")};
  position: absolute;
  top: ${() => (window.appHeight() > window.innerWidth ? "38%" : "36.5%")};
  overflow-x: hidden;
  overflow-y: scroll;
  ::-webkit-scrollbar-track {
    margin-right: 1vw;
  }
  ::-webkit-scrollbar {
    width: 0.7vw;
    z-index: 10;
  }
  ::-webkit-scrollbar-thumb {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 10;
    background: linear-gradient(180deg, #ff8a00 38.02%, #900000 100%);
    box-shadow: inset 0px 0px 4px 2px #ff814d;
    border-radius: 10px;
  }
`;

const CommissionType = styled.div`
  width: 98%;
  margin: 1% 0% 0% 1%;
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 2%.5;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 4vw;
        `
      : css``}
`;
const CommissionHeader = styled.div`
  width: 98%;
  border-radius: 0.5vw;
  margin: 0% 0% 0% 1%;
  display: flex;
  justify-content: space-around;
  color: #ffc700;
`;

const CommissionItem = styled.div`
  width: 98%;
  height: 23%;
  border-radius: 0.5vw;
  background-color: #5a120a;
  margin: 1% 0% 0% 1%;
  display: flex;
  justify-content: space-around;
  color: #fff;
`;

const CommissionProvider = styled.div`
  width: 50%;
  height: 100%;
  font-size: 1.5vw;
  font-weight: 700;
  display: flex;
  align-items: center;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2.5vw;
        `
      : css``}
`;

const CommissionText = styled.div`
  width: 25%;
  height: 100%;
  color: #cfb0ad;
  font-size: 1.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CommissionPercentage = styled.div`
  width: 20%;
  height: 100%;
  color: #ffc700;
  font-size: 1.5vw;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2.5vw;
        `
      : css``}
`;

const ReferContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ReferHead = styled.div`
  color: #fff;
  text-align: center;
  text-shadow: 0px 0px 20px #0094ff;
  font-size: ${() =>
    window.appHeight() > window.innerWidth ? "4.25vw" : "3vw"};
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-top: 1%;
`;

const ReferSubHead = styled.div`
  color: #fff;
  text-align: center;
  text-shadow: 0px 0px 20px #0094ff;
  font-size: ${() =>
    window.appHeight() > window.innerWidth ? "4.25vw" : "3vw"};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 1%;
`;

const ReferNumber = styled.div`
  color: #fff;
  text-align: center;
  text-shadow: 0px 0px 20px #0094ff;
  font-family: Prompt;
  font-size: ${() =>
    window.appHeight() > window.innerWidth ? "9vw" : "5.75vw"};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: ${() => (window.appHeight() > window.innerWidth ? "11%" : "7%")};
  /* line-height: 1; */
`;

const ReferCurrency = styled.div`
  color: #fff;
  text-align: center;
  text-shadow: 0px 0px 20px #0094ff;
  font-family: Prompt;
  font-size: ${() =>
    window.appHeight() > window.innerWidth ? "4vw" : "3.25vw"};
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  line-height: 1;
`;

const ReferEndText = styled.div`
  color: #fff;
  text-align: center;
  text-shadow: 0px 0px 20px #0094ff;
  font-family: Prompt;
  font-size: ${() => (window.appHeight() > window.innerWidth ? "3vw" : "2vw")};
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  line-height: 1;
`;

const Refer2Head = styled.div`
  color: #fff;
  text-align: center;
  text-shadow: 0px 0px 20px #0094ff;
  font-size: ${() =>
    window.appHeight() > window.innerWidth ? "4vw" : "2.4vw"};
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-top: 7%;
  white-space: nowrap;
`;

const Refer2DetailBox = styled.div`
  color: #ffd600;
  text-align: center;
  width: 60%;
  height: 27%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Prompt;
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 7%;
  margin-left: 34.5%;
  //Portrait
  @media (orientation: portrait) {
    font-size: 2.5vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 2vw;
  }
`;

const Refer2Number = styled.div`
  color: #fff;
  text-align: center;
  text-shadow: 0px 0px 6px #ffd600;
  font-family: Prompt;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  line-height: 1;
  @media (orientation: portrait) {
    font-size: 4vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 3vw;
  }
`;

export default function PromotionPic(props) {
  const { t } = useTranslation();

  // console.log("props", props);

  // 1 = affiliate, 2 = cashback, 3 = commission, 4 = affiliate flat, 5 = 2in1 affiliate
  return (
    <MainBG
      style={{
        backgroundImage:
          props.imageKey === 5
            ? "url(../images/promotion_banner_new/banner_referral_com.jpg)"
            : // : props.imageKey === 6
              // ? "url(../images/promotion_banner_new/banner_referral.jpg)"
              `url(${props.image})`,
      }}
      {...props}
    >
      {props.imageKey === 3 ? (
        <CommissionBox>
          {props?.commissionType?.map((item, i) => {
            return (
              <Fragment key={`comminssion#${i}`}>
                <CommissionType>{t(item)}</CommissionType>
                <CommissionHeader>
                  <CommissionProvider>{t("providers")}</CommissionProvider>
                  <CommissionPercentage>{t("commission")}</CommissionPercentage>
                </CommissionHeader>
                {props?.data
                  ?.filter((e) => e.typeGame === item)
                  ?.map((data, i) => {
                    return (
                      <CommissionItem key={"index: " + i}>
                        <CommissionProvider>
                          {data.providerName}
                        </CommissionProvider>
                        <CommissionPercentage>
                          {Number.parseFloat(data.percent).toFixed(2)}%
                        </CommissionPercentage>
                      </CommissionItem>
                    );
                  })}
              </Fragment>
            );
          })}
        </CommissionBox>
      ) : props.imageKey === 4 ? (
        <BigPercentageTextKey imageKey={props.imageKey} id={props._id}>
          <p>{props.value}</p>
        </BigPercentageTextKey>
      ) : props.imageKey === 5 ? (
        <ReferContainer>
          <Refer2Head>
            {t("refer head", {
              currency: t("cashpoint bonus"),
            })}
          </Refer2Head>
          <Refer2DetailBox
            style={{ marginTop: window.innerWidth < 480 && "10%" }}
          >
            {typeof props.inviteFriendInfo?.find((e) => e._id === 141) !==
            "undefined" ? (
              <>
                <p>{t("refer2 register")}</p>
                <p>{t("refer sub head")}</p>
                <Refer2Number>
                  <NumberFormat
                    value={
                      props.inviteFriendInfo?.find((e) => e._id === 141)?.value
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                    fixedDecimalScale={true}
                  />
                </Refer2Number>
                <p>
                  {props.inviteFriendInfo?.find((e) => e._id === 141)
                    ?.type_topup === 1
                    ? t("Diamond")
                    : t("credit")}
                </p>
              </>
            ) : (
              <p>{t("refer no regis")}</p>
            )}
          </Refer2DetailBox>
          <Refer2DetailBox style={{ marginTop: "4%" }}>
            {typeof props.inviteFriendInfo?.find((e) => e._id === 101) !==
            "undefined" ? (
              <>
                <p>{t("refer2 deposit")}</p>
                <p>{t("refer sub head")}</p>
                <Refer2Number>
                  <NumberFormat
                    value={
                      props.inviteFriendInfo?.find((e) => e._id === 101)?.value
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                    fixedDecimalScale={true}
                  />
                </Refer2Number>
                <p>
                  {props.inviteFriendInfo?.find((e) => e._id === 101)
                    ?.type_credit === 2
                    ? t("Diamond")
                    : t("credit")}
                </p>
              </>
            ) : (
              <p>{t("refer no depo")}</p>
            )}
          </Refer2DetailBox>
        </ReferContainer>
      ) : typeof props.value === "undefined" || props.value === 0 ? (
        ""
      ) : props.imageKey === 6 ? (
        <ReferContainer>
          <ReferHead>
            {t("refer head", {
              currency: props.type_credit === 2 ? t("Diamond") : t("credit"),
            })}
          </ReferHead>
          <ReferSubHead>{t("refer sub head")}</ReferSubHead>
          <ReferNumber>
            <NumberFormat
              value={props.value}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={0}
              fixedDecimalScale={true}
            />
          </ReferNumber>
          <ReferCurrency>
            {props.type_credit === 2 ? t("Diamond") : t("credit")}
          </ReferCurrency>
          <ReferEndText>{t("refer end text")}</ReferEndText>
        </ReferContainer>
      ) : (
        <>
          {/* <PercentageTextKey imageKey={props.imageKey}>
            {props.value + "%"}
          </PercentageTextKey>
          <BigPercentageTextKey imageKey={props.imageKey} id={props._id}>
            <p>{props.imageKey === 4 ? props.value : props.value + "%"}</p>
          </BigPercentageTextKey> */}
        </>
      )}
    </MainBG>
  );
}
