import "../styles.css";
import Button from "./Button";
import React from "react";
import { useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { config } from "../config";
// import { selectuserProfile } from "../Reducers/userProfileSlice";
// import { SocketContext } from "../context/socket";
// import NumberFormat from "react-number-format";
import { actionPopup } from "../Reducers/popupSlice";

// const BgClose = styled.div`
//   user-select: none;
//   width: 100vw;
//   height: 100%; //fix
//   background-color: transparent;
//   z-index: 20;
//   left: 0%;
//   top: 0%;
//   position: fixed;
// `;

const BGPopup = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  position: fixed;
  /* left: 7%; */
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 70;
  align-items: center;
  justify-content: center;
`;

const Popupcomplete = styled.div`
  user-select: none;
  // background-image: url("/images/popup_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;
  border-radius: 2vw;

  // theme ==================
  /* background: #192756;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #5DD8FF, inset 0px 0px 4vw rgba(66, 255, 232, 0.63);
  border: solid 0.2vw #BDEFFF; */
  // theme ==================
  position: relative;
  z-index: 100;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  // เพิ่ม padding ด้านข้างกรณี title ทะลุ start below ========
  padding: 0 1vw 5vw 1vw;
  width: 33vw;
  height: fit-content;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 55vw;
          padding: 0 1vw 6vw 1vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          width: 48vw;
          height: fit-content;
          padding: 0 1vw 6vw 1vw;
        `}
`;

const Pigcomplete = styled.img`
  user-select: none;
  width: 11vw;
  margin-top: -5vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 20vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          width: 18vw;
        `}
`;

const Complete = styled.div`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  color: #bdb9d1;
  font-size: 2.5vw;
  text-align: center;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 4.5vw;
          line-height: 4.5vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          font-size: 3.5vw;
          line-height: 3.5vw;
        `}
`;

const TextError = styled.div`
  user-select: none;
  position: relative;
  color: #bdb9d1;
  font-size: ${(props) => (props.lang === "th" ? "1.6vw" : "1.5vw")};
  top: 1.2vw;
  text-align: center;
  width: 27vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2.6vw;
          top: 1.2vw;
          text-align: center;
          width: 41vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          font-size: 2vw;
          top: 1.2vw;
          text-align: center;
          width: 48vw;
          /* height: 16vw; */
        `}
`;

const TextErrorcode = styled.div`
  user-select: none;
`;

const BoxButton = styled.div`
  left: 11vw;
  margin-top: 3vw;
  position: absolute;
  z-index: 110;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          position: absolute;
          z-index: 110;
          margin-top: 2.5vw;
          left: 18vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          left: 16vw;
          margin-top: 3.5vw;
        `}
`;

const Box = styled.div`
  width: 31vw;
  height: 5vw;
  background-color: black;
  border-radius: 1vw;
  position: relative;
  top: 1vw;
  display: flex;
  justify-content: center;
  span {
    color: yellow;
    font-size: 3vw;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 36vw;
          height: 7vw;
          span {
            color: yellow;
            font-size: 4vw;
            align-self: center;
          }
        `
      : window.appHeight() > window.innerWidth &&
      css`
          span {
            font-size: 3.5vw;
          }
        `}
`;

function WithdrawalFailerror(props) {
  const { t, i18n } = useTranslation();
  // const profile = useSelector(selectuserProfile);
  // const [bonus, setBonus] = useState({});
  // const [emitError, setEmitError] = useState({ status: false, message: "" });
  // const socket = useContext(SocketContext);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   try {
  //     socket.emit("getTurnProWithDraw", {}, (data) => {
  //       console.log("getTurnProWithDraw", data);
  //       if (typeof data != "undefined") {
  //         if (typeof data.results != "undefined") {
  //           if (data.status) {
  //             setBonus(data.results.data);
  //           } else if (data.status === false) {
  //             setEmitError({ status: true, message: data.errorCode });
  //             setTimeout(() => {
  //               setEmitError({ status: false });
  //             }, 3000);
  //           }
  //         }
  //       }
  //     });
  //   } catch (error) {
  //     setEmitError({ status: true, message: error.message });
  //     setTimeout(() => {
  //       setEmitError({ status: false });
  //     }, 3000);
  //   }
  // }, []);

  return (
    <BGPopup>
      <>
        <Popupcomplete
        // style={{
        //   width:
        //     window.innerWidth / window.appHeight() > 2.15 ? "33vw" : "33vw",
        //   height:
        //     window.innerWidth / window.appHeight() > 2.15 ? "19vw" : "19vw",
        // }}
        >
          <Pigcomplete src={"../" + config.theme + "/icon_deposit_fail.png"} />
          <Complete>{t("Failed Withdrawal")}</Complete>
          <TextErrorcode>
            <TextError lang={i18n.language}>{props.message}</TextError>
            {props.showTurnover === true ? (
              <Box lang={i18n.language}>
                <span>{props.turnover}</span>
              </Box>
            ) : (
              ""
            )}
          </TextErrorcode>
          <BoxButton>
            <Button
              bClass="rbGreen"
              label={t("got it! withdrawal")}
              width={
                window.appHeight() / window.innerWidth >
                  config.verticalSizeiphone
                  ? "21vw"
                  : window.appHeight() > window.innerWidth
                    ? "16vw"
                    : window.innerWidth / window.appHeight() > 2.15
                      ? "13vw"
                      : "13vw"
              }
              fontSize={
                window.appHeight() / window.innerWidth >
                  config.verticalSizeiphone
                  ? "2.5vw"
                  : window.appHeight() > window.innerWidth
                    ? "1.7vw"
                    : window.innerWidth / window.appHeight() > 2.15
                      ? "1.3vw"
                      : "1.3vw"
              }
              onClick={() => {
                dispatch(actionPopup({ withdrawaltopup: false }));
                dispatch(actionPopup({ withdrawalError: false }));
                props.setToConfirm();
                // dispatch(actionPopup({ withdrawal: false }));
              }}
            />
          </BoxButton>
        </Popupcomplete>
      </>
    </BGPopup>
  );
}
export default WithdrawalFailerror;
