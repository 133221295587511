import { createSlice } from "@reduxjs/toolkit";
export const rankingSlice = createSlice({
  name: "ranking",
  initialState: {
    rank: {
      isEnd: false,
      rewardAmount: 0,
      seasonCode: 0,
      rankNoWin: 0,
      rankNoLose: 0,
      bannerState: {
        date: "",
        status: 0,
      },
    },
  },
  reducers: {
    actionRanking: (state, action) => {
      state.rank = { ...state.rank, ...action.payload };
    },
  },
});

export const { actionRanking } = rankingSlice.actions;
export const selectRanking = (state) => state.ranking.rank;
export default rankingSlice.reducer;
