import { createSlice } from "@reduxjs/toolkit";
export const checkFTUEPopupSlice = createSlice({
  name: "checkFTUEPopup",
  initialState: {
    isFTUEPopup: 0,
  },
  reducers: {
    actionCheckFTUEPopup: (state, action) => {
      state.isFTUEPopup = action.payload;
    },
  },
});

export const { actionCheckFTUEPopup } = checkFTUEPopupSlice.actions;
export const selectCheckFTUEPopup = (state) => state.checkFTUEPopup.isFTUEPopup;
export default checkFTUEPopupSlice.reducer;
