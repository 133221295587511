import styled, { css } from "styled-components";
import Popup from "../../Popup";
import Button from "../../Button";
import DecimalDepositButton from "../../DecimalDeposit/DecimalDepositButton";
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Transition, config } from "../../../config";
import { SocketContext } from "../../../context/socket";
import AlertMessage from "../../Dialog/AlertMessage";
import { selectTopupSlice } from "../../../Reducers/TopupSlice";
import { useSelector } from "react-redux";
import Fyi from "./fyi";
import QRPopup from "./qrPopup";

const Piggy = styled.img`
  user-select: none;
  position: absolute;
  top: -13.5%;
  right: 32%;
  width: 13.3268vw;
  user-select: "none";
  z-index: 50;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          top: -59.5%;
          right: 35%;
          width: 25.3268vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          top: -29vw;
          right: 32%;
          width: 17.3268vw;
        `}
`;

const Content = styled.div`
  user-select: none;
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  margin-top: 3%;
`;

const Box = styled.div`
  user-select: none;
  position: absolute;
  width: 100%;
  height: 100%; //fix
  align-items: center;
  justify-content: center;
  text-align: -webkit-center;
  display: flex;
  z-index: 30;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          top: 11vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          top: 6vw;
        `}
`;

const Input = styled.input`
  outline: transparent;
  background-color: #000000;
  width: 61.2864%;
  height: 4vw;
  align-self: center;
  font-weight: 400;
  color: #ffffff;
  font-size: 3.5vw;
  text-align: end;
  padding-right: 3%;
  border-radius: 1vw;
  border: 1px;
  margin: 0.3vw 0;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 88.2864%;
          height: 14vw;
          font-size: 6.5vw;
          margin: 3vw 0;
          border-radius: 2vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 82.2864%;
          height: 6vw;
          font-size: 4.5vw;
        `}
`;

const RedText = styled.div`
  color: #ff7777;
  font-size: 1vw;
  white-space: pre-line;
  // font-weight: 600;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 4vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 1.8vw;
        `}
`;

const TextDes = styled.div`
  font-size: 1.5vw;
  color: #bdb9d1;
  font-weight: bold;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 4vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 2vw;
        `}
`;

const ContianerButton = styled.div`
  display: flex;
  position: absolute;
  bottom: 9%;
  left: 12.5%;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          bottom: 7%;
          left: 6.5%;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          position: relative;
          bottom: auto;
          left: auto;
          justify-content: center;
          top: 6vw;
          /* width: 23%; */
        `}
`;

export default function QRDeposit(props) {
  // ** States
  const [totalAmount, setTotalAmount] = useState("");
  const [depoPop, setDepoPop] = useState(false);
  const [emitError, setEmitError] = useState({ status: false, message: "" });
  const [alertPop, setAlertPop] = useState(false);
  const [qrPop, setQrPop] = useState(false);
  const [qrData, setQrData] = useState({});

  // ** props
  const { closeClick, bankInfo } = props;

  const { t } = useTranslation();
  const socket = useContext(SocketContext);
  const amount = useSelector(selectTopupSlice);
  let reqPhone = new RegExp(/^[0-9\b]+$/);

  useEffect(() => {
    if (amount > 0) {
      console.log("amount change", amount);
      setDepoPop(false);
    }
  }, [amount]);

  const handleConfirm = (amount) => {
    console.log("amount", amount);
    setAlertPop(true);
    socket.emit(
      "a2z-payment",
      {
        price: amount,
      },
      (data) => {
        console.log("a2z-payment", data);
        setQrData(data);
      }
    );
  };

  //close//
  return (
    <Box>
      <div style={{ position: "absolute", alignSelf: "center", zIndex: 20 }}>
        <Popup
          style={{
            marginTop:
              window.appHeight() / window.innerWidth > config.verticalSizeiphone
                ? "-22vw"
                : window.appHeight() > window.innerWidth
                ? "-17vw"
                : window.innerWidth / window.appHeight() > 2.15
                ? "4vw"
                : "4vw",
          }}
          bgClass="Profile"
          title={t("QR Promptpay")}
          width={
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "92vw"
              : window.appHeight() > window.innerWidth
              ? "50vw"
              : window.innerWidth / window.appHeight() > 2.15
              ? "38.98vw"
              : "38.98vw"
          }
          height={
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "90vw"
              : window.appHeight() > window.innerWidth
              ? "44vw"
              : window.innerWidth / window.appHeight() > 2.15
              ? "31vw"
              : "31vw"
          }
          // width={window.innerWidth / window.appHeight() > 2.15 ? "70vw" : "38.98vw"}
          closebuttonRight="-3.5%"
          closebuttonTop="-3%"
          // TitleSize="1.75vw"
          TitleSize={
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "3vw"
              : window.appHeight() > window.innerWidth
              ? "2.8vw"
              : window.innerWidth / window.appHeight() > 2.15 && "1.75vw"
          }
          closeClick={closeClick}
        >
          <Content>
            <TextDes>{t("input number")}</TextDes>
            <RedText>{t("QR Minimum")}</RedText>
            <Input
              type="text"
              onkeypress="return isNumberKey(event)"
              value={totalAmount}
              placeholder="0"
              maxLength="8"
              onFocus={() => (window.onKeyBoard = true)}
              onBlur={() => (window.onKeyBoard = false)}
              onChange={(e) => {
                if (e.target.value === "" || reqPhone.test(e.target.value)) {
                  if (parseInt(e.target.value) > 99999999) {
                    setTotalAmount("99999999");
                  } else {
                    setTotalAmount(e.target.value);
                  }
                }
              }}
            />
            <div style={{ display: "flex", alignSelf: "center" }}>
              <DecimalDepositButton
                onClick={() => {
                  if (parseInt(totalAmount) + 1 > 99999999) {
                    setTotalAmount("99999999");
                  } else if (totalAmount != "") {
                    setTotalAmount((parseInt(totalAmount) + 1).toString());
                  } else setTotalAmount(1);
                }}
                value="+1"
              />
              <DecimalDepositButton
                onClick={() => {
                  if (parseInt(totalAmount) + 10 > 99999999) {
                    setTotalAmount("99999999");
                  } else if (totalAmount != "") {
                    setTotalAmount((parseInt(totalAmount) + 10).toString());
                  } else setTotalAmount(10);
                }}
                value="+10"
              />
              <DecimalDepositButton
                onClick={() => {
                  if (parseInt(totalAmount) + 100 > 99999999) {
                    setTotalAmount("99999999");
                  } else if (totalAmount != "") {
                    setTotalAmount((parseInt(totalAmount) + 100).toString());
                  } else setTotalAmount(100);
                }}
                value="+100"
              />
            </div>
            <div style={{ display: "flex", alignSelf: "center" }}>
              <DecimalDepositButton
                onClick={() => {
                  if (parseInt(totalAmount) + 500 > 99999999) {
                    setTotalAmount("99999999");
                  } else if (totalAmount != "") {
                    setTotalAmount((parseInt(totalAmount) + 500).toString());
                  } else setTotalAmount(500);
                }}
                value="+500"
              />
              <DecimalDepositButton
                onClick={() => {
                  if (parseInt(totalAmount) + 5000 > 99999999) {
                    setTotalAmount("99999999");
                  } else if (totalAmount != "") {
                    setTotalAmount((parseInt(totalAmount) + 5000).toString());
                  } else setTotalAmount(5000);
                }}
                value="+5,000"
              />
              <DecimalDepositButton
                onClick={() => {
                  setTotalAmount("");
                }}
                value="C"
              />
            </div>
          </Content>

          <ContianerButton>
            <Button
              label={t("cancel")}
              bClass="rbRed"
              width={
                window.appHeight() / window.innerWidth >
                config.verticalSizeiphone
                  ? "40.44vw"
                  : window.appHeight() > window.innerWidth
                  ? "21.44vw"
                  : window.innerWidth / window.appHeight() > 2.15
                  ? "14.44vw"
                  : "14.44vw"
              }
              fontSize={
                window.appHeight() / window.innerWidth >
                config.verticalSizeiphone
                  ? "4vw"
                  : window.appHeight() > window.innerWidth
                  ? "2vw"
                  : window.innerWidth / window.appHeight() > 2.15
                  ? "1.8vw"
                  : "1.8vw"
              }
              onClick={closeClick}
              style={{ userSelect: "none", padding: 0 }}
            />
            {totalAmount < 100 || totalAmount > 300000 ? (
              <Button
                label={t("confirm")}
                bClass="rbGray"
                width={
                  window.appHeight() / window.innerWidth >
                  config.verticalSizeiphone
                    ? "40.44vw"
                    : window.appHeight() > window.innerWidth
                    ? "21.44vw"
                    : window.innerWidth / window.appHeight() > 2.15
                    ? "14.44vw"
                    : "14.44vw"
                }
                fontSize={
                  window.appHeight() / window.innerWidth >
                  config.verticalSizeiphone
                    ? "4vw"
                    : window.appHeight() > window.innerWidth
                    ? "2vw"
                    : window.innerWidth / window.appHeight() > 2.15
                    ? "1.8vw"
                    : "1.8vw"
                }
                style={{ userSelect: "none", padding: 0 }}
              />
            ) : (
              <Button
                label={t("confirm")}
                bClass="rbGreen"
                width={
                  window.appHeight() / window.innerWidth >
                  config.verticalSizeiphone
                    ? "40.44vw"
                    : window.appHeight() > window.innerWidth
                    ? "21.44vw"
                    : window.innerWidth / window.appHeight() > 2.15
                    ? "14.44vw"
                    : "14.44vw"
                }
                fontSize={
                  window.appHeight() / window.innerWidth >
                  config.verticalSizeiphone
                    ? "4vw"
                    : window.appHeight() > window.innerWidth
                    ? "2vw"
                    : window.innerWidth / window.appHeight() > 2.15
                    ? "1.8vw"
                    : "1.8vw"
                }
                onClick={() => {
                  handleConfirm(totalAmount);
                }}
                style={{ userSelect: "none", padding: 0 }}
              />
            )}
          </ContianerButton>
        </Popup>
        <Piggy src={"../" + config.theme + "/icon_decimal_deposit.png"} />

        <Transition showPop={emitError.status} type="alert">
          <AlertMessage
            message={t(emitError.message)}
            onClick={() => {
              setEmitError({ status: false });
            }}
          />
        </Transition>
      </div>
      {alertPop && (
        <Fyi
          closeClick={() => {
            setAlertPop(false);
            setQrPop(true);
          }}
        />
      )}
      {qrPop && (
        <QRPopup
          bankInfo={bankInfo}
          closeClick={() => setQrPop(false)}
          qrData={qrData}
        />
      )}
    </Box>
  );
}
