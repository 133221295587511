import React, { useState, useContext } from "react";
import styled, { css } from "styled-components";
import Button from "./Button";
import { useTranslation } from "react-i18next";
import { Transition, config, redirect2Page } from "../config";
import AlertMessage from "./Dialog/AlertMessage";
import {
  selectuserProfile,
} from "../Reducers/userProfileSlice";
import { useSelector, useDispatch } from "react-redux";
import { actionPopup } from "../Reducers/popupSlice";
import { SocketContext } from "../context/socket";
import { actionCheckFTUEPopup } from "../Reducers/checkFTUEPopupSlice";

const Popup = styled.div`
  user-select: none;
  // background-image: url("/${config.theme}/popup_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  // background: #302e3c;
  // box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f, inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  // border: solid 0.2vw orange;
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;

  border-radius: 2vw;
  position: relative;
  z-index: 70;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 90vw;
        `
      : window.appHeight() > window.innerWidth &&
      css`
          width: 80vw;
        `}
`;

const BGPopup = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%;
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 70;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  user-select: none;
  display: flex;
  position: relative;
  border-radius: 1.5vw;
  margin: 3% 0 8% 0;
  width: 95%;
`;

const ButtonPanel = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: -8%;
`;

function FtuePopup(props) {
  const { t } = useTranslation();
  const [emitError, setEmitError] = useState({ status: false, message: "" });
  const profile = useSelector(selectuserProfile);
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);

  return (
    <>
      <BGPopup>
        <Popup>
          <Image
            src={`../${config.theme}/promotion_banner_new/${props.banner}.jpg`}
          />

          <ButtonPanel>
            <Button
              onClick={
                profile.isTelGuest
                  ? () => {
                    try {
                      props.setFtuePop(false);
                      socket.emit(
                        "getfirstlogin",
                        {
                          status: true,
                        },
                        (data) => {
                          console.log(data);
                        }
                      );
                    } catch (error) {
                      setEmitError({ status: true, message: error.message });
                      setTimeout(() => {
                        setEmitError({ status: false });
                      }, 3000);
                    }
                  }
                  : () => {
                    try {
                      props.setFtuePop(false);
                      if (!profile.isTelGuest) {
                        dispatch(actionPopup({ register: true }));
                        dispatch(actionCheckFTUEPopup(1));
                      }
                    } catch (error) {
                      setEmitError({ status: true, message: error.message });
                      setTimeout(() => {
                        setEmitError({ status: false });
                      }, 3000);
                    }
                  }
              }
              bClass={profile.isTelGuest ? "rbRed" : "rbGreen"}
              // label={t("deposit now")}
              label={profile.isTelGuest ? t("not now") : t("deposit now")}
              // width="20vw"
              // fontSize="2vw"
              width={
                window.appHeight() / window.innerWidth >
                  config.verticalSizeiphone
                  ? "30vw"
                  : window.appHeight() > window.innerWidth
                    ? "30vw"
                    : window.innerWidth / window.appHeight() > 2.15
                      ? "20vw"
                      : "20vw"
              }
              fontSize={
                window.appHeight() / window.innerWidth >
                  config.verticalSizeiphone
                  ? "3vw"
                  : window.appHeight() > window.innerWidth
                    ? "3vw"
                    : window.innerWidth / window.appHeight() > 2.15
                      ? "2vw"
                      : "2vw"
              }
            />
            <Button
              onClick={
                profile.isTelGuest
                  ? () => {
                    try {
                      props.setFtuePop(false);
                      profile.isguest
                        ? // dispatch(actionPopup({ registBankAccount: true }))
                        redirect2Page("createbankaccount")
                        : dispatch(actionPopup({ depositWithdrawal: true }));
                      socket.emit(
                        "getfirstlogin",
                        {
                          status: true,
                        },
                        (data) => {
                          console.log(data);
                        }
                      );
                    } catch (error) {
                      setEmitError({ status: true, message: error.message });
                      setTimeout(() => {
                        setEmitError({ status: false });
                      }, 3000);
                    }
                  }
                  : () => {
                    try {
                      props.setFtuePop(false);
                      dispatch(actionPopup({ mission: true }));
                    } catch (error) {
                      setEmitError({ status: true, message: error.message });
                      setTimeout(() => {
                        setEmitError({ status: false });
                      }, 3000);
                    }
                  }
              }
              bClass="rbGreen"
              label={
                profile.isTelGuest ? t("get bonus now") : t("go to mission")
              }
              width={
                window.appHeight() / window.innerWidth >
                  config.verticalSizeiphone
                  ? "30vw"
                  : window.appHeight() > window.innerWidth
                    ? "30vw"
                    : window.innerWidth / window.appHeight() > 2.15
                      ? "20vw"
                      : "20vw"
              }
              fontSize={
                window.appHeight() / window.innerWidth >
                  config.verticalSizeiphone
                  ? "3vw"
                  : window.appHeight() > window.innerWidth
                    ? "3vw"
                    : window.innerWidth / window.appHeight() > 2.15
                      ? "2vw"
                      : "2vw"
              }
            />
          </ButtonPanel>
        </Popup>
      </BGPopup>

      <Transition showPop={emitError.status} type="alert">
        <AlertMessage
          message={t(emitError.message)}
          onClick={() => {
            setEmitError({ status: false });
          }}
        />
      </Transition>
    </>
  );
}

export default FtuePopup;
