import styled from "styled-components";
import breakpoint from "../breakpoints";

const AlertContainer = styled.div`
    background-color: #222535;
    width: 20.31vw;
    height: max-content;
    box-shadow: 0 0.3vw 0 0 #d24403 inset,0 0 5vw 2vw rgba(0, 0, 0, .25);
    border-radius: 1.5vw;
    color: #E5E5E5;
    padding: 3vw 2vw 3vw 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    p {
        font-size: 1vw;
    }
    @media ${breakpoint.device.mobile} {
        width: 80.2vw;
        //height: 15.71vw;
        box-shadow: 0 1.2vw 0 0 #d24403 inset,0 0 5vw 2vw rgba(0, 0, 0, .25);
        padding: 8vw 3vw;
        border-radius: 4.83vw;
        display: block;
        p {
            font-size: 3.86vw;
        }
    }
`

function AlertMessage(props) {
    return (
        <AlertContainer>
            <p>{props.message || props.children}</p>
        </AlertContainer>
    )
}

export default AlertMessage
