import React from "react";
// import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled, { css, keyframes } from "styled-components";
import { tada } from "react-animations";
import { config } from "../../config";
import { actionPopup, selectPopupList } from "../../Reducers/popupSlice";
import { selectuserProfile } from "../../Reducers/userProfileSlice";
import { selectMission } from "../../Reducers/missionSlice";
import { actionFestivalBanner } from "../../Reducers/festivalBannerSlice";

const BannerContainer = styled.div`
  width: 7vw;
  position: relative;
  z-index: 20;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 20vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 12vw;
        `}
`;

const NoticeAnimation = keyframes`${tada}`;

const Dot = styled.img`
  user-select: none;
  width: 2.5vw;
  position: absolute;
  top: 10%;
  z-index: 2;
  animation: ${NoticeAnimation} 1.5s linear infinite;
  pointer-events: none;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 5vw;
          top: 20%;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 3.5vw;
        `}
`;

const IMG = styled.img`
  width: 100%;
  /* transform: rotate(20deg); */
  cursor: pointer;
`;

function FestivalBanner(props) {
  const dispatch = useDispatch();
  const mission = useSelector(selectMission);
  const popupList = useSelector(selectPopupList);
  const profile = useSelector(selectuserProfile);

  // let checkMission = mission.map((item) => item.no).indexOf(6120);
  // console.log(
  //   "missionIcon",
  //   profile.missionIcon.filter((icon) => icon.img)
  // );

  let checkMission = mission.filter((item) => item.mission_group == 6);

  let icon;
  if (props.type === "banner") {
    icon = "../" + config.theme + "/SpinixUpdateIcon.png";
  } else if (props.type === "missionEvent") {
    icon =
      "../" +
      config.theme +
      "/events/fifa2022/mission_" +
      props.mission.no +
      ".png";
  }

  // let skfStatus = profile.skf.stage.filter(
  //   (stage) => stage.status == 1 || stage.status == 2
  //   (stage) => stage.status == 3
  // );

  return (
    <BannerContainer>
      {/* {profile.tutorial >= 16 && profile.notievent ? (
        // && skfStatus.length !== profile.skf.stage.length ?
        <Dot src={"../" + config.theme + "/notice_dot.png"} />
      ) : (
        ""
      )} */}

      {/* survey event */}
      {(checkMission.length > 0 && props.type === "missionEvent") ||
      (props.type === "banner" &&
        popupList.filter((a) => a.notification === 1).length > 0) ||
      popupList.filter((a) => a.no === 2).length > 0 ? (
        // && skfStatus.length !== profile.skf.stage.length ?
        <Dot src={"../" + config.theme + "/notice_dot.png"} />
      ) : (
        ""
      )}

      {/* close for survey event */}
      {/* {props.type == "missionEvent" ? (
        <EventTag>
          <span>{t("event noti")}</span>
        </EventTag>
      ) : (
        ""
      )} */}

      <IMG
        // src={"../" + config.theme + "/idle_shiba_1.png"}
        src={icon}
        onClick={() => {
          // dispatch(actionPopup({ festival: true }));

          // survey event
          // dispatch(actionFestivalBanner(99));
          // dispatch(actionPopup({ mission: true }));

          if (props.type === "banner") {
            dispatch(actionPopup({ eventBanner: true }));
          } else if (props.type === "missionEvent") {
            dispatch(actionPopup({ mission: true }));
            dispatch(actionFestivalBanner(99));
          }
        }}
      />
    </BannerContainer>
  );
}

export default FestivalBanner;
