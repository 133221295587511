import { createSlice } from "@reduxjs/toolkit";

export const HowtouseSlice = createSlice({
    name: "howtouse",
    initialState: {
        howto: {
            title: "",
            description: "",
            header:"",
            image_url:"",
            order:"",
        },

        contenthow:{contenthow:{en:[],th:[]}},
        number:{},
        howtouses:[],
    },
    reducers: {
        actionHowtouse: (state, action) => {
            state.howto = action.payload;
        },
        actionContenthow: (state, action) => {
            state.contenthow = action.payload;
            
        },
        actionNumber: (state, action) => {
            state.number = action.payload;
        },
        actionHowtouses: (state, action) => {
            state.howtouses = action.payload;
        },

    },
});

export const { actionHowtouse,actionContenthow,actionNumber,actionHowtouses } = HowtouseSlice.actions;
export const selectHowtouse = (state) => state.howtouse.howto;
export const selectContenthow = (state) => state.howtouse.contenthow;
export const selectNumber = (state) => state.howtouse.number;
export const selectHowtouses = (state) => state.howtouse.howtouses;
export default HowtouseSlice.reducer;