import React, { useContext } from "react";
import styled, { css } from "styled-components";
import { config } from "../../../config";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { SocketContext } from "../../../context/socket";

const Bg = styled.div`
  position: fixed;
  width: 100vw;
  height: 100%; //fix
  top: 0%;
  left: 0%;
  text-align: -webkit-center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 70;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Popup = styled.div`
  user-select: none;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 43.48vw;
  height: max-content;
  min-height: 22.45vw;
  background-color: #2f2d3b;
  border-radius: 4vw;
  border: solid 3vw;
  border-image-slice: 50;
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;
  border-radius: 2vw;
  padding: 2.5vw;
  // theme ==================
  padding: 3vw;
  align-items: center;
  color: #bcb8d0;
  font-size: 2vw;

  @media (orientation: portrait) {
    width: 90vw;
  }
`;

const Title = styled.div`
  user-select: none;
  top: -3px;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: white;
  height: 6vw;
  font-size: 2vw;
  text-align: center;
  padding: 0 10%;
  background-clip: border-box, border-box, content-box;
  background-size: 8.69vw, 8.69vw, 1.07vw;
  background-position: top 0 left 0, top 0 right 0, top 0 right 0;
  background-repeat: no-repeat, no-repeat, repeat-x;
  background-image: url("../${config.theme}/popup_title_bg_left.png"),
    url("../${config.theme}/popup_title_bg_right.png"),
    url("../${config.theme}/popup_title_bg_center.png");

  @media (orientation: portrait) {
    top: 0%;
    height: 12vw;
    background-size: 18vw, 18vw, 2.22vw;
    padding: 0 15%;
    font-size: 4vw;
  }
`;

const Detail = styled.div`
  width: 95%;
  min-height: 10vw;
  color: #fff;
  font-size: 2vw;
  margin-top: 10%;
  position: relative;
  white-space: pre-line;
  span {
    color: #f77;
  }
  @media (orientation: portrait) {
    font-size: 4vw;
    margin-top: 12%;
  }
`;

const ButtonContainer = styled.div`
  width: 95%;
  height: 7vw;
  display: flex;
  justify-content: space-between;
  position: relative;
  @media (orientation: portrait) {
    height: 12vw;
  }
`;

const CancelButton = styled.div`
  width: 42.02%;
  height: 100%;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #e13b00 38.02%, #900000 100%);
  box-shadow: inset 0vw 0vw 0.6vw 0.2vw #ff814d;
  border-radius: 1vw;
  @media (orientation: portrait) {
    font-size: 4vw;
  }
`;

const ConfirmButton = styled.div`
  width: 55%;
  height: 100%;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #62d706 38.02%, #3b9604 100%);
  box-shadow: inset 0px 0px 0.6vw 0.2vw #4dff80;
  border-radius: 1vw;
  @media (orientation: portrait) {
    font-size: 4vw;
  }
`;

const CheckboxContainer = styled.div`
  width: 95%;
  min-height: 5vw;
  color: #fff;
  font-size: 2vw;
  position: relative;
  white-space: pre-line;
  display: flex;
  align-items: center;
  @media (orientation: portrait) {
    font-size: 4vw;
    min-height: 10vw;
    margin-top: 2%;
  }
`;

const Checkbox = styled.div`
  width: 3vw;
  height: 3vw;
  background-color: #000;
  border-radius: 0.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media (orientation: portrait) {
    width: 4vw;
    height: 4vw;
  }
`;

const CheckboxDot = styled.div`
  width: 60%;
  height: 60%;
  background-color: #ff691f;
  border-radius: 0.3vw;
`;

export default function FinalNoticePop(props) {
  const { t } = useTranslation();
  const [checkStatus, setCheckStatus] = useState(false);
  const socket = useContext(SocketContext);
  return (
    <Bg>
      <Popup>
        <Title>{t("importance notice")}</Title>
        <Detail
          dangerouslySetInnerHTML={{ __html: t("select pro alert text") }}
        />
        <CheckboxContainer>
          <Checkbox
            onClick={() => {
              setCheckStatus((n) => !n);
            }}
          >
            {checkStatus ? <CheckboxDot /> : ""}
          </Checkbox>
          &ensp;{t("checkbox text")}
        </CheckboxContainer>
        <ButtonContainer>
          <CancelButton
            onClick={() => {
              props.closeClick();
            }}
          >
            {t("cancel")}
          </CancelButton>
          <ConfirmButton
            onClick={() => {
              props.confirmClick();
              if (checkStatus) {
                socket.emit(
                  "checkboxdeposit",
                  {
                    type: "updatePro", //"auto", "manual", "updatePro"
                  },
                  (data) => {
                    console.log("checkboxdeposit: updatePro", data);
                  }
                );
              }
            }}
          >
            {t("choose this pro")}
          </ConfirmButton>
        </ButtonContainer>
      </Popup>
      ;
    </Bg>
  );
}
