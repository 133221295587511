import { createSlice } from "@reduxjs/toolkit";
export const shopSlice = createSlice({
  name: "shop",
  initialState: {
    data: {
      credit: [],
      freeSpin: [],
      freeSpinGroup: -1,
    },
  },
  reducers: {
    actionShop: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
  },
});

export const { actionShop } = shopSlice.actions;
export const selectShop = (state) => state.shop.data;
export default shopSlice.reducer;
