import React, { useEffect, useContext, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import PromotionDetail from "../PromotionDetail";
import { selectuserProfile } from "../../../../Reducers/userProfileSlice";
import { useTranslation } from "react-i18next";
import FinalNoticePop from "../FinalNoticePop";
import { config } from "../../../../config";

const Box = styled.div`
  width: 45%;
  height: 40%;
  background: #17161d;
  border-radius: 1vw;
  margin: 0 0 2% 3.33%;
  display: flex;
  justify-content: space-evenly;
  .detailImg {
    width: 28.85%;
    object-fit: contain;
  }

  @media (orientation: portrait) {
    width: 100%;
    height: 30%;
    margin-left: 0%;
  }
  @media only screen and (max-width: 480px) and (orientation: portrait) {
    height: 25%;
  }
`;

const Detail = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  white-space: pre-line;
  color: white;
  font-size: 1.5vw;
  @media (orientation: portrait) {
    font-size: 4vw;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  height: 33.33%;
  display: flex;
  justify-content: space-between;
`;

const DetailButton = styled.div`
  width: 42.02%;
  height: 100%;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #924524 0%, #211f29 78.12%);
  box-shadow: inset 0vw 0vw 1vw 0.2vw #ff7a00;
  border-radius: 1vw;
  @media (orientation: portrait) {
    font-size: 4vw;
    border-radius: 2vw;
  }
`;

const SelectButton = styled.div`
  width: 55%;
  height: 100%;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #62d706 38.02%, #3b9604 100%);
  box-shadow: inset 0px 0px 0.6vw 0.2vw #4dff80;
  border-radius: 1vw;
  @media (orientation: portrait) {
    font-size: 4vw;
    border-radius: 2vw;
  }
`;

const ReceivedBox = styled.div`
  width: 100%;
  height: 100%;
  color: #8f8ba7;
  background-color: #2e2c3a;
  border-radius: 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 100%;
`;

const AlreadySelected = styled.div`
  width: 55%;
  height: 100%;
  color: #4dff80;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2e2c3a;
  border-radius: 1vw;
  img {
    width: 25%;
    object-fit: contain;
  }
  @media (orientation: portrait) {
    font-size: 4vw;
    border-radius: 2vw;
  }
`;

export default function NormalPromotionListItem(props) {
  const [proDetailPop, setProDetailPop] = useState(false);
  const profile = useSelector(selectuserProfile);
  const { t } = useTranslation();
  const [finalPop, setFinalPop] = useState(false);

  return (
    <>
      <Box>
        <img
          className="detailImg"
          src={
            profile.language === "TH" ? props.info.image : props.info.image_en
          }
        />
        <Detail>
          {profile.language === "TH" ? props.info.name : props.info.name_en}
          <ButtonContainer>
            {props?.info?.enable ? (
              <>
                <DetailButton
                  onClick={() => {
                    setProDetailPop(true);
                  }}
                >
                  {t("details")}
                </DetailButton>
                {props.promotion_id === props.info.id ? (
                  <AlreadySelected>
                    <img src={"../" + config.theme + "/ico_promo_check.png"} />
                    {t("activated")}
                  </AlreadySelected>
                ) : (
                  <SelectButton
                    onClick={() => {
                      if (props?.setting?.updatePro) {
                        props.UpdatePromotion("manual", props.info);
                      } else {
                        setFinalPop(true);
                      }
                    }}
                  >
                    {t("choose")}
                  </SelectButton>
                )}
              </>
            ) : (
              <ReceivedBox>{t("pro received")}</ReceivedBox>
            )}
          </ButtonContainer>
        </Detail>
      </Box>
      {proDetailPop ? (
        <PromotionDetail
          setFinalPop={props.setFinalPop}
          closeClick={() => setProDetailPop(false)}
          info={props.info}
          setting={props?.setting}
          selected={props.promotion_id === props.info.id}
          confirmClick={() => {
            props.UpdatePromotion("manual", props.info);
          }}
        />
      ) : (
        ""
      )}
      {finalPop ? (
        <FinalNoticePop
          closeClick={() => {
            setFinalPop(false);
          }}
          confirmClick={() => {
            props.UpdatePromotion("manual", props.info);
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}
