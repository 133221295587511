import React, { useState, useEffect, useContext } from "react";
import { SocketContext } from "../../context/socket";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { config } from "../../config";
// import "./style.css";
// import "./style.css";
import "react-slideshow-image/dist/styles.css";
import { selectuserProfile } from "../../Reducers/userProfileSlice";
import PromotionPic from "../Promotion/PromotionPic";
import SwiperCore, { Pagination, Virtual } from "swiper";
SwiperCore.use([Pagination, Virtual]);

const BGPromotion = styled.div`
  user-select: none;
  /* background-color: #26242f; */
  width: 40vw;
  height: 28vw;
  border-radius: 1.5vw;
  pointer-events: auto;
  //Portrait
  @media (orientation: portrait) {
    width: 87.5vw;
    height: 58vw;
    border-radius: 1.5vw;
    position: relative;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    width: 50.85vw;
    height: 33.3vw;
    /* position: relative; */
    background-color: transparent;
  }
`;

const SwiperFix = styled(Swiper)`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  overflow: hidden;
  height: 100%;
  .swiper-wrapper {
    width: max-content;
    display: flex;
    justify-content: center;
    height: 100%;
  }
  .swiper-slide {
    position: relative;
    display: flex;
    justify-content: center;
  }
`;

const Bullets = styled.div`
  width: max-content;
  display: flex;
  position: relative;
  align-self: center;
  /* top: 28.3vw; */
  /* left: 18.5vw; */
  z-index: 10;
  .swiper-pagination-bullet {
    background: rgba(255, 255, 255, 0.3);
    width: 0.75vw;
    height: 0.75vw;
    border-radius: 10vw;
    margin: -1vw 0.3vw 0.3vw 0.3vw;

    cursor: pointer;
  }
  .swiper-pagination-bullet-active {
    background: #fff;
  }
  //Portrait
  @media (orientation: portrait) {
    margin: -4vw 1vw 1vw 1vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    margin: -0.5vw 0.5vw 0.5vw 0.5vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin: -4vw 1vw 1vw 1vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          margin: -0.5vw 0.5vw 0.5vw 0.5vw;
        `} */
`;

const Promotionimg = styled.img`
  user-select: none;
  width: 100%;
  height: 100%;
  border-radius: 1.5vw;
`;

const PromotionBox = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 1.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  //Portrait
  @media (orientation: portrait) {
    width: 85vw;
    height: 54.7vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    width: 50.85vw;
    height: 33.3vw;
    position: relative;
  }
`;

function Slideshow(props) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { t } = useTranslation();
  const socket = useContext(SocketContext);
  const [emitError, setEmitError] = useState({ status: false, message: "" });
  const [getPromotion, setGetPromotion] = useState([]);
  const dispatch = useDispatch();
  const [promotionCase, setPromotionCase] = useState([]);
  const profile = useSelector(selectuserProfile);
  const [swiperRef, setSwiperRef] = useState(null);

  const slideTo = (index) => {
    swiperRef.slideTo(index, 0);
  };

  useEffect(() => {
    try {
      socket.emit("getpromotesv2", { limit: 5 }, (data) => {
        // console.log("deposit getpromote", data);
        if (typeof data != "undefined") {
          if (typeof data.results != "undefined") {
            if (data.status) {
              setGetPromotion(data.results);
            } else if (data.status === false) {
              setEmitError({ status: true, message: data.errorCode });
              setTimeout(() => {
                setEmitError({ status: false });
              }, 3000);
            }
          }
        }
      });
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  }, []);

  useEffect(() => {
    setPromotionCase([]);
    for (let i in getPromotion) {
      if (getPromotion[i]._id == "0" || getPromotion[i]._id == "126") {
        //เลือกโปรที่จะแสดง
        setPromotionCase((promotionCase) => [
          ...promotionCase,
          getPromotion[i],
        ]);
      }
    }
    // console.log(promotionCase, getPromotion, profile.setbonus, profile.level);
  }, [getPromotion, profile.setbonus]);

  useEffect(() => {
    if (swiperRef != null) {
      if (profile.setbonus === "O") {
        slideTo(0); // เด้งไปตำแหน่งของโปรที่รับโปร
      } else if (profile.setbonus === "C") {
        if (promotionCase.filter((n) => n._id === 126).length > 0) {
          slideTo(promotionCase.map((item) => item._id).indexOf(126)); // เด้งไปตำแหน่งของโปรที่ไม่รับโปร
        } else {
          slideTo(0);
        }
      }
    }
  }, [promotionCase]);

  return (
    <BGPromotion>
      <SwiperFix
        onSwiper={setSwiperRef}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        pagination={{
          clickable: true,
          el: ".bullets",
          renderBullet: function (index, className) {
            return '<div class="' + className + '"></div>';
          },
        }}
        spaceBetween={0}
        onLoad={setSwiperRef}
        virtual
      >
        {promotionCase.map((img, idx) => (
          <SwiperSlide key={idx} virtualIndex={idx}>
            <PromotionBox>
              {img._id === 0 ? (
                <Promotionimg src={img.image} alt={img} />
              ) : (
                <PromotionPic {...img} imageKey={2} />
              )}
            </PromotionBox>
          </SwiperSlide>
        ))}

        <Bullets className="bullets"></Bullets>
      </SwiperFix>

      {/* <SwiperFix
        onSwiper={setSwiperRef}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }}
        pagination={{
          "clickable": true,
          "el": ".bullets",
          "renderBullet": function (index, className) {
            return '<div class="' + className + '"></div>';
          },
        }}
        spaceBetween={0}
        onLoad={setSwiperRef}
        virtual
      >

        {getPromotion.map((img, idx) => (
          <SwiperSlide key={idx} virtualIndex={idx}>
            <Promotionimg src={img.image} alt={img} />
          </SwiperSlide>
        ))}

        <Bullets className="bullets"></Bullets>
      </SwiperFix> */}
    </BGPromotion>
  );
}

export default Slideshow;
