import Navbar from "./Components/Navbar";
import { SocketContext, socket, SocketKafkaContext } from "./context/socket";
import React, { useState, useEffect, useContext, lazy, Suspense } from "react";
import Footer from "./Components/footer";
import Content from "./Components/Content";
import styled, { keyframes, css } from "styled-components";
import "./webfont/css/spinix_ico.css";
import BigJackpot from "./Components/Jackpot/BigJackpot";
import CreateAccount from "./Components/Register/CreateAccount";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectuserProfile,
  actionSetprofile,
} from "./Reducers/userProfileSlice";
import GamePlay from "./Components/GameList/GamePlay";
import Loading from "./Components/Loading";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from "react-router-dom";
import {
  changeTimer,
  changeJackpot,
  countDownStamp,
} from "./Reducers/countDown";
import FtuePopup01 from "./Components/FtuePopup01";
import FtuePopup02 from "./Components/FtuePopup02";
import * as moment from "moment";
import { Howl } from "howler";
import { selectDimensions, setDimension } from "./Reducers/dimensions";
import i18next from "i18next";
import { selectGamePlay } from "./Reducers/gamePlaySlice";
import AlertMessage from "./Components/Dialog/AlertMessage";
import {
  redirectToLogin,
  getJWT,
  getFullUrl,
  getBackUrl,
  localStorageGetWithExpiry,
  localStorageSetWithExpiry,
  setToken,
  removeAllSession,
  getParameterByName,
  getNewUrlwithLang,
} from "./config";
import { selectLoading, actionLoading } from "./Reducers/loadingSlice";
import { selectAlert } from "./Reducers/alertSlice";
import { actionGamePlay } from "./Reducers/gamePlaySlice";
import { getVersion } from "./config";
import Notification from "./Components/Notification";
import { actionCounterLP } from "./Reducers/counterLPSlice";
// import Logout from "./Components/Logout";
import { config } from "./config";
import FtuePopup from "./Components/FtuePopup";
import GuestPopup from "./Components/GuestTelPopup";
import BankPopup from "./Components/GuestBankPopup";
import {
  selectPopup,
  selectPopupMessage,
  actionPopup,
  actionPopupMessage,
  actionPopupList,
  selectPopupList,
} from "./Reducers/popupSlice";
import { Transition } from "./config";
import { actionMission, selectMission } from "./Reducers/missionSlice";
import { selectIsRefresh } from "./Reducers/afterLogin";
import {
  selectConfirmPopup,
  actionConfirmPopup,
} from "./Reducers/confirmPopup";
import ConfirmPopup from "./Components/Dialog/ConfirmPopup";
import { actionTopup } from "./Reducers/TopupSlice";
import { transformSync } from "@babel/core";
import {
  selectValue,
  selectStatus,
  selectLabel,
} from "./Reducers/floatingInputSlice";
import CashbackPopup from "./Components/CashbackPopup";
import { actionGameList } from "./Reducers/gameListSlice";
import PopupIframe from "./Components/PopupIframe";
import { faCommentsDollar } from "@fortawesome/free-solid-svg-icons";
import EventPopup from "./Components/EventPopup";
import { Tween } from "react-gsap";
import FestivalBanner from "./Components/FestivalPopup/FestivalBanner";
import Valentine from "./Components/Valentine";
import VLTMinigame from "./Components/Valentine/Minigame";
// import SKDMinigame from "./Components/SKD";
// import SKD from "./Components/SKD";
// import SKDMinigame from "./Components/SKD/connect";
import DepositWithdrawal from "./Components/DepositWithdrawal/Deposit";
import { actionCheckDailyDeposit } from "./Reducers/checkDailyDepositSlice";
import LevelupPopup from "./Components/LevelupPopup/levelup";
import NewUserPopup from "./Components/NewUserPopup";
import { actionWinRate } from "./Reducers/winRateSlice";
import RewardDetailsPopup from "./Components/SKD/RewardDetailsPopup";
import AlertReward from "./Components/FestivalPopup/alertReward";
import JackpotBanner from "./Components/Jackpot/JackpotBanner";
import EventPopupBanner from "./Components/EventPopupBanner";
import FlashDeal from "./Components/FlashDeal";
import FlashDealPop from "./Components/FlashDeal/FlashDealBanner";
import { actionCountDownTime } from "./Reducers/flashDealSlice";
import TimeLimitPromotion from "./Components/TimeLimitPromotion";
import TimeLimitPromotionPop from "./Components/TimeLimitPromotion/TimeLimitPromotionBanner";
import { actionCountDownTimeLimitPro } from "./Reducers/timeLimitProSlice";
import { actionProvider } from "./Reducers/providerSlice";
import { actionProvidersItem } from "./Reducers/gameCategorySlice";
import MysteryBoxBanner from "./Components/MysteryBoxNew/MysteryBoxBanner";
import { actionShop } from "./Reducers/shopSlice";
import DailyLogin from "./Components/DailyLogin";

// import Forgotpassword from "./Components/Login/Forgotpassword"
// Forgotpassword
const BG = styled.div`
  user-select: none;
  background-image: url("../${config.theme}/newasset/bg.png");
  background-size: 100% 100%;
  width: ${(props) => props.width};
  height: 100%; //fix
  /* height: ${(props) => props.height}; */
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 0;
  overflow: hidden;
`;

const MainSoundBGM = new Howl({
  src: ["Sound/MainBGM.mp3"],
  loop: true,
  volume: 0.5,
});

const WelcomeSound = new Howl({
  src: ["Sound/1_Welcome_Message.mp3"],
  loop: false,
  volume: 0.5,
});

const TopupSound = new Howl({
  src: ["Sound/4_Successful_Deposit.mp3"],
  loop: false,
  volume: 0.3,
});

const LevelUpSound = new Howl({
  src: ["Sound/3_Level_Up.mp3"],
  loop: false,
  volume: 0.5,
});

const LandScapeAlert = styled.div`
  background: rgba(0, 0, 0, 0.7);
  background-image: url("../${config.theme}/landscape.png");
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  z-index: 9999;
`;
const FloatingInputBg = styled.div`
  width: 100%;
  height: 120%;
  position: fixed;
  z-index: 998;
  background: rgba(0, 0, 0, 0.7);
  top: -20%;
`;
const FloatingElement = styled.div`
  width: max-content;
  // background: red;
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  top: 20%;
  display: flex;
`;

const FloatingInput = styled.input`
  user-select: none;
  // position: fixed;
  // left: 30%;
  // left: 50%;
  // translate: transform(-50%);
  // top: 20%;
  color: black;
  font-size: 2vw;
  width: max-content;
  height: max-content;
  z-index: 999;
  border-radius: 20px;
  padding: 1vw;
  border: 0;
  // box-shadow: 0 0 2vw 5vw black;
  border: solid 3px orange;
  margin-right: 1vw;
`;
const FloatingInputLabel = styled.div`
  text-align: right;
  padding-right: 1vw;
  color: white;
  font-size: 2.8vw;
  line-height: 5.2vw;
`;
const FloatingInputDoneBtn = styled.div`
  width: 10vw;
  // height: 5vw;
  // background: red;
  border-radius: 1vw;
  // position: fixed;
  // top: 20.5%;
  // right: 30%;
  margin-left: 0.5vw;
  text-align: center;
  line-height: 5.2vw;
  font-size: 2vw;
  color: white;
  margin: auto 0;
  background: linear-gradient(180deg, #06d746 38.02%, #059604 100%);
  box-shadow: inset 0px 0px 0.8vw 0.4vw #4dff80;
`;

const diamond = keyframes`
    0% {opacity: 0;}
    50% {opacity: 1;}
    100% {opacity: 0;}
`;

const DiamondAlert = styled.div`
  position: absolute;
  z-index: 999;
  top: 10%;
  left: 32%;
  animation-name: ${diamond};
  animation-duration: 1.2s;
  animation-timing-function: ease;
  animation-iteration-count: inherit;
  p.outer {
    display: inline-block;
    font-size: 2.5vw;
    font-weight: 400;
    -webkit-text-stroke-width: 0.5vw;
    -webkit-text-stroke-color: #000;
    margin-left: 0.25vw;
    position: absolute;
    z-index: 980;
  }
  p.inner {
    display: inline-block;
    font-size: 2.5vw;
    font-weight: 400;
    color: #07f0ff;
    margin-left: 0.25vw;
    position: absolute;
    z-index: 990;
  }
  img {
    display: inline-block;
    width: 2.2vw;
    margin-top: 0.6vw;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          top: 10vw;
          left: 18vw;
          p.outer {
            font-size: 3.5vw;
            margin-left: 1vw;
          }
          p.inner {
            font-size: 3.5vw;
            margin-left: 1vw;
          }
          img {
            width: 4.2vw;
            margin-top: 0vw;
          }
        `
      : window.appHeight() > window.innerWidth &&
        css`
          top: 7vw;
          left: 23vw;
        `}
`;

const IconBox = styled.div`
  user-select: none;
  width: max-content;
  height: max-content;
  position: fixed;
  right: 1%;
  top: ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? "26vw"
      : // ? "36vw"
      window.appHeight() > window.innerWidth
      ? "27vw"
      : "15vw"};
  z-index: 20;
`;

function App() {
  window.isLandingPage = false;
  setTimeout(() => {
    if (window.fullScreenGIF) window.fullScreenGIF();
  }, 500);
  const { t } = useTranslation();
  const [notiPop, setNotiPop] = useState(false);
  const [notification, setNotification] = useState({
    name: "",
    amount: "",
    game: "",
  });
  const socketio = useContext(SocketContext);
  const socketioKafka = useContext(SocketKafkaContext);
  const dispatch = useDispatch();
  const profile = useSelector(selectuserProfile);
  const dimensions = useSelector(selectDimensions);
  const gameStatus = useSelector(selectGamePlay);
  const [alertNoti, setAlertNoti] = useState({ status: false, message: "" });
  const [emitError, setEmitError] = useState({ status: false, message: "" });
  const isLoading = useSelector(selectLoading);
  const alertMessageSelect = useSelector(selectAlert);
  const [isFreeSpin, setIsFreeSpin] = useState({ status: false, url: "" });
  const [disconnect, setDisconnect] = useState(false);
  const [ftuePop, setFtuePop] = useState(true);
  const [eventPopup, setEventPopup] = useState([]);
  const [eventPopupBanner, setEventPopupBanner] = useState([]);
  const popupStatus = useSelector(selectPopup);
  const popupMessage = useSelector(selectPopupMessage);
  const isRefresh = useSelector(selectIsRefresh);
  const [bgmController, setbgmController] = useState(true);
  const confirmPopup = useSelector(selectConfirmPopup);
  const [count, setCount] = useState(0);
  const [checkCredit, setCheckCredit] = useState(false);
  const floatingValue = useSelector(selectValue);
  const floatingStatus = useSelector(selectStatus);
  const floatingLabel = useSelector(selectLabel);
  const [showLandScape, setShowLandScape] = useState(false);
  const [balance, setBalance] = useState(profile.balance);
  const [loginTimeout, setLoginTimeout] = useState(0);
  const [sessionTimeout, setSessionTimeout] = useState(false);
  const [diamondAlert, setDiamondAlert] = useState(0);
  const [balanceAlert, setBalanceAlert] = useState(0);
  const [showDiamond, setShowDiamond] = useState(true);
  const [showBalance, setShowBalance] = useState(false);
  const [oldDiamond, setOldDiamond] = useState(profile.diamond);
  const [oldBalance, setOldBalance] = useState(profile.credit);
  const [popupCNY, setPopupCNY] = useState(true);
  const [playfishing, setPlayfishing] = useState(false);
  const [welcomeState, setWelcomeState] = useState(true);
  const [levelUpPopup, setLevelUpPopup] = useState(false);
  const [noTopup, setNoTopup] = useState(false); // false = เติมไปแล้ว
  const [showJackpot, setShowJackpot] = useState(false);
  const mission = useSelector(selectMission);
  const jackpotCountDownStamp = useSelector(countDownStamp);
  const [showBalanceDelay, setShowBalanceDelay] = useState(false);
  const [jackpotReady, setJackpotReady] = useState(true);
  const [jackpotRequest, setJackpotRequest] = useState(false);
  const popupList = useSelector(selectPopupList);
  const [flashDealPop, setFlashDealPop] = useState(false);
  const [timeLimitProPop, setTimeLimitProPop] = useState({
    status: false,
    selectIndex: 0,
  });
  const [jackpotCounter, setJackpotCounter] = useState(0);
  const [mysteryBoxStatus, setMysteryBoxStatus] = useState(false);

  useEffect(() => {
    //SpecialOffer & FlashDeal Count Down Time
    let currentTime = profile.currentTime;
    let allData = profile.specialOffer;
    clearInterval(window.countDownFlash);
    let setTime = (c) => {
      var now = moment(c) + 7 * 60 * 60 * 1000;
      var distance =
        allData.endDateNumber -
        allData.startDateNumber -
        (now - allData.startDateNumber);
      var hours = Math.floor(distance / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      var barW = Math.floor(
        (distance / (allData.endDateNumber - allData.startDateNumber)) * 100
      );
      if (hours <= 0 && minutes <= 0 && seconds <= 0) {
        console.log("Time Out!");
        clearInterval(window.countDownFlash);
        dispatch(actionSetprofile({ specialOffer: {} }));
      }
      return { hour: hours, minute: minutes, second: seconds, barWidth: barW };
    };
    if (Object.keys(allData).length !== 0) {
      dispatch(actionCountDownTime(setTime(currentTime)));
      window.countDownFlash = setInterval(function () {
        currentTime = currentTime + 1000;
        dispatch(actionCountDownTime(setTime(currentTime)));
      }, 1000);
    }
  }, [profile.specialOffer]);

  useEffect(() => {
    //LimitPromotion Count Down Time
    let currentTime = profile.currentTime;
    let allData = profile.timeLimit[0];
    clearInterval(window.countDownTimeLimit);
    let setTime = (c) => {
      var now = moment(c) + 7 * 60 * 60 * 1000;
      var distance =
        allData.endDateNumber -
        allData.startDateNumber -
        (now - allData.startDateNumber);
      var hours = Math.floor(distance / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      var barW = Math.floor(
        (distance / (allData.endDateNumber - allData.startDateNumber)) * 100
      );
      if (hours <= 0 && minutes <= 0 && seconds <= 0) {
        console.log("Time Out!");
        clearInterval(window.countDownTimeLimit);
        dispatch(actionSetprofile({ timeLimit: [{}] }));
      }
      return { hour: hours, minute: minutes, second: seconds, barWidth: barW };
    };
    if (Object.keys(allData).length !== 0) {
      dispatch(actionCountDownTimeLimitPro(setTime(currentTime)));
      window.countDownTimeLimit = setInterval(function () {
        currentTime = currentTime + 1000;
        dispatch(actionCountDownTimeLimitPro(setTime(currentTime)));
      }, 1000);
    }
  }, [profile.timeLimit]);

  useEffect(() => {
    setTimeout(() => {
      if (jackpotRequest && jackpotReady) {
        socket.emit("checkjackpot", {}, (data) => {
          console.log("checkjackpot", data);
          setShowJackpot(true);
          setJackpotRequest(false);
          setJackpotReady(false);
          setTimeout(() => {
            setJackpotReady(true);
          }, 10000);
        });
      }
    }, 11000);
  }, [jackpotRequest, jackpotReady]);

  useEffect(() => {
    setShowDiamond(false);
    clearInterval(window.getWinRate);
    const getWinRates = () => {
      socket.emit("getWinRates", {}, (data) => {
        // console.log("getWinRates", data);
        dispatch(actionWinRate(data));
      });
    };
    getWinRates();
    window.getWinRate = setInterval(() => {
      getWinRates();
    }, 15000);

    socket.emit("get-season-mysterybox", {}, (body) => {
      console.log("get-season-mysterybox", body);
      if (body.status) {
        if (body?.results?.isOpen) {
          setMysteryBoxStatus(true);
        }
      }
    });
  }, []);

  useEffect(() => {
    setJackpotCounter(profile.jackpot.length);
    if (profile.jackpot.length > 0) {
      setShowJackpot(true);
    }
  }, [profile.jackpot]);

  useEffect(() => {
    if (profile.seteffect === "O" && welcomeState && profile.levelup != 1) {
      WelcomeSound.play();
      setWelcomeState(false);
    }
  }, [profile.seteffect]);

  useEffect(() => {
    if (profile.levelup === 1 && profile.level > 1) {
      setLevelUpPopup(true);
      if (profile.seteffect === "O") {
        LevelUpSound.play();
      }
    } else {
      setLevelUpPopup(false);
    }
  }, [profile.levelup]);

  // useEffect(() => {
  //   if (profile.ftueRegister == 1) {
  //     if (!popupStatus.depositWithdrawal)
  //       dispatch(actionPopup({ depositWithdrawal: true }));
  //   }
  // }, [profile.ftueRegister]);

  let timeoutDaily = null;
  // console.log("popupStatus", popupStatus);

  let device = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
    ? 1
    : 0;
  try {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
    } else {
      document.body.style.overflow = "hidden";
    }
  } catch (error) {
    setEmitError({ status: true, message: error.message });
    setTimeout(() => {
      setEmitError({ status: false });
    }, 3000);
  }

  useEffect(() => {
    if (profile.popup.filter((a) => a.no == 1).length > 0) {
      setFlashDealPop(true);
      setTimeLimitProPop({ status: true, selectIndex: 0 });
    }
    // console.log("profile.popup", profile.popup);
    const eventPopupStatus = []; //profile.popup.length;
    for (let i = 0; i < profile.popup.length; i++) {
      eventPopupStatus.push(true);
    }
    setEventPopup(eventPopupStatus);
    // console.log("eventPopupStatus", eventPopupStatus);
    const sortedPopupList = JSON.parse(JSON.stringify(profile.popup))
      .filter((a) => a.no != 1)
      .sort(function (a, b) {
        return a.sort - b.sort;
      });
    const mysteryIndex = sortedPopupList.findIndex(
      (e) => e.no === 2340 && mysteryBoxStatus === false
    );
    if (mysteryIndex > -1) {
      // sortedPopupList.splice(mysteryIndex, 1);
    }
    dispatch(actionPopupList(sortedPopupList));
  }, [profile.popup]);

  useEffect(() => {
    var delayDisconnect;
    socket.on("connect", function () {
      console.log("connect");
      clearTimeout(delayDisconnect);
      if (disconnect) {
        redirectToLogin(getFullUrl());
        setDisconnect(false);
      }
    });
    socket.on("disconnect", () => {
      console.log("disconnect");
      delayDisconnect = setTimeout(() => {
        setDisconnect(true);
      }, 3000);
    });
  }, [disconnect]);

  useEffect(() => {
    let urlLuncher = profile.freeSpinUrl;
    if (urlLuncher != "") {
      try {
        if (typeof urlLuncher !== "undefined") {
          setIsFreeSpin({ status: true, url: urlLuncher });
          if (device === 1) {
            redirectToLogin(urlLuncher);
          } else {
            window.history.pushState("", "", getFullUrl());
            dispatch(
              actionGamePlay({
                isPlay: true,
                gameUrl: urlLuncher,
              })
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [profile.freeSpinUrl]);

  const refJWT = (isReload) => {
    // setTimeout(
    //   () => {

    // setIsLoading2(false);
    socket.emit("refreshJWT", getBackUrl(), function (data) {
      setTimeout(() => {
        setShowBalanceDelay(true);
      }, 5000);
      // console.log("jwt", data);
      // console.log(data.results.data);
      // alert(true)
      // setIsLoading2(true);
      if (
        typeof data == "undefined" ||
        typeof data.results == "undefined" ||
        typeof data.results.data == "undefined"
      ) {
      } else {
        if (data.status) {
          // localStorageSetWithExpiry("user", data.results.data, 86400 * 1000)
          setToken(data.results.jwtToken);
          dispatch(actionSetprofile(data.results.data));
        } else if (data.status === false) {
          setEmitError({ status: true, message: data.errorCode });
          removeAllSession();
          setTimeout(() => {
            setEmitError(false);
            redirectToLogin();
          }, 3000);
        }
      }
    });

    //   },
    //   isReload === true ? 1000 : 0
    // );
  };

  const refJWTReal = () => {
    try {
      socket.emit("refreshJWT", {}, function (data) {
        if (
          typeof data == "undefined" ||
          typeof data.results == "undefined" ||
          typeof data.results.data == "undefined"
        ) {
        } else {
          if (data.status) {
            dispatch(actionSetprofile(data.results.data));
          }
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    try {
      const checkfreespin = () => {
        // console.log("check Free spin")
        // socket.emit("checkFreespin", {
        //   "url": (
        //   device == 1 ?
        //      getFullUrl() : window.location.protocol + "//" + window.location.host + "/back.html?token=" + getJWT()
        //   ),
        //   "platform": device
        // }, (body) => {
        //   try {
        //     let urlLuncher = body.results.data.url;
        //     if (typeof urlLuncher !== "undefined") {
        //       setIsFreeSpin({ status: true, url: urlLuncher })
        //       // redirectToLogin(urlLuncher);
        //       if (device === 1) {
        //         redirectToLogin(urlLuncher);
        //       }
        //       else {
        //         // redirectToLogin(urlLuncher);
        //         // history.push("/gameplay?token=" + getJWT())
        //         // console.log("urlLuncher", urlLuncher)
        //         window.history.pushState("", "", getFullUrl());
        //         dispatch(
        //           actionGamePlay({
        //             isPlay: true,
        //             gameUrl: urlLuncher,
        //           })
        //         );
        //       }
        //     }
        //   } catch (error) {
        //     console.log(error)
        //   }
        // });
      };

      const disablePinchZoom = (e) => {
        if (e.touches.length > 1) {
          e.preventDefault();
        }
      };

      document.addEventListener("touchmove", disablePinchZoom, {
        passive: false,
      });
      window.addEventListener("resize", handleResize);
      window.addEventListener("popstate", () => {
        // alert("Hello Spinix");
        // handleResize();
        // refJWT(true);
        // setTimeout(() => {
        //   checkfreespin();
        // }, 1000);
        window.location.reload();
      });

      handleResize();
      if (window.location.pathname != "/")
        window.location.href = window.location.href.split(
          window.location.pathname
        )[0];

      console.log(getVersion());
      if (profile.username == "") {
        refJWT();
      } else {
        if (profile.isguest === false) checkfreespin();
        setTimeout(() => {
          setShowBalanceDelay(true);
        }, 5000);
        // console.log("Login done!", profile);
        dispatch(actionLoading(true));
        setTimeLimitProPop({ status: true, selectIndex: 0 });
      }

      // socket.on("updateInfo", (data) => {
      //   // console.log("updateInfo", data, profile.diamond);
      //   dispatch(actionSetprofile(data));
      // });

      return () => {
        document.removeEventListener("touchmove", disablePinchZoom);
      };
    } catch (error) {
      // alert(error.message )
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  }, []);

  // useEffect(() => {
  //   // console.log("diamond", profile.diamond);
  //   socket.once("updateInfo", (data) => {
  //     console.log("updateInfo", data, profile.diamond);
  //     // dispatch(actionSetprofile(data));
  //   });
  // }, [profile.diamond]);

  const refreshDailyReward = () => {
    try {
      socket.emit("refreshDailyReward", {}, (data) => {
        if (typeof data == "undefined" || typeof data.results == "undefined") {
        } else {
          if (data.status) {
            dispatch(actionSetprofile(data.results));
          } else if (data.status === false) {
            setEmitError({ status: true, message: data.errorCode });
            setTimeout(() => {
              setEmitError({ status: false });
            }, 3000);
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    try {
      let ltk = getParameterByName("token");
      if (ltk != null) localStorage.setItem("ltk", getParameterByName("token"));
    } catch (error) {}

    let currentTime = profile.currentTime;
    var countDownDate = moment(currentTime).endOf("days").valueOf();
    clearInterval(timeoutDaily);
    timeoutDaily = setInterval(function () {
      currentTime = currentTime + 1000;
      var now = moment(currentTime);
      var distance = countDownDate - now - 60000;
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (hours >= 16) {
        dispatch(
          changeTimer(
            `${String(hours - 16).padStart(2, "0")}:${String(minutes).padStart(
              2,
              "0"
            )}:${String(seconds).padStart(2, "0")}`
          )
        );
      } else {
        dispatch(
          changeTimer(
            `${String(hours + 8).padStart(2, "0")}:${String(minutes).padStart(
              2,
              "0"
            )}:${String(seconds).padStart(2, "0")}`
          )
        );
      }
      if (distance < 1000 || (hours == 16 && minutes == 0 && seconds == 0)) {
        countDownDate = moment(currentTime).endOf("days").valueOf();

        setTimeout(() => {
          refreshDailyReward();
        }, 1000);
        setTimeout(() => {
          countDownDate = moment(currentTime).endOf("days").valueOf();
          refreshDailyReward();
        }, 5000);
      }
    }, 1000);
  }, []);

  useEffect(() => {
    let currentTime = profile.currentTime;
    let jackpotCD = jackpotCountDownStamp;
    clearInterval(window.timeoutJackpot);
    let jackpotFunc = () => {
      var now = moment(currentTime);

      var jpcd = moment(jackpotCD);
      var jpDistance = jpcd - now;

      var jpDays = Math.floor(jpDistance / (1000 * 60 * 60 * 24));
      var jpHours = Math.floor(
        (jpDistance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var jpMinutes = Math.floor((jpDistance % (1000 * 60 * 60)) / (1000 * 60));
      var jpSeconds = Math.floor((jpDistance % (1000 * 60)) / 1000);
      dispatch(
        changeJackpot({
          days: String(jpDays).padStart(2, "0"),
          hours: String(jpHours).padStart(2, "0"),
          mins: String(jpMinutes).padStart(2, "0"),
          secs: String(jpSeconds).padStart(2, "0"),
        })
      );
    };
    if (jackpotCountDownStamp != 0) {
      jackpotFunc();
      window.timeoutJackpot = setInterval(function () {
        currentTime = currentTime + 1000;

        jackpotFunc();
      }, 1000);
    }
  }, [jackpotCountDownStamp]);

  function handleResize() {
    // alert("Event Resize Trigger: " + window.appHeight());
    try {
      window.checkScreenResize();
      if (
        window.appHeight() / window.innerWidth > 1 &&
        /Android|webOS|iPhone|IEMobile|Opera Mini/i.test(navigator.userAgent)
      ) {
        setShowLandScape(true);
      } else {
        setShowLandScape(false);
      }
      if (getJWT() == null) {
        redirectToLogin();
      }

      setTimeout(() => {
        dispatch(
          setDimension({
            width: window.innerWidth + "px",
            height: window.appHeight() + "px",
          })
        );
      }, 100);
      setTimeout(() => {
        dispatch(
          setDimension({
            width: window.innerWidth + "px",
            height: window.appHeight() + "px",
          })
        );
      }, 400);
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  }

  useEffect(() => {
    try {
      if (gameStatus.isPlay) {
        MainSoundBGM.volume(0);
      } else MainSoundBGM.volume(0.5);
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  }, [gameStatus]);

  const handleAppStatePause = () => {
    MainSoundBGM.volume(0);
    // console.log("PAUSE");
    setLoginTimeout(Date.now());
  };

  const refreshCredit = () => {
    socket.emit("refreshCredit", {}, function (data) {
      // console.log(data);
      // let totalCount = count + 1
      // setCount(totalCount)
      window.count = parseInt(window.count) + 1;
      // console.log("refreshCredit", data);
      if (typeof data == "undefined" || typeof data.results == "undefined") {
      } else {
        if (data.status) {
          // console.log("balance :", parseFloat(data.results.balance), parseFloat(window.b))
          if (parseFloat(data.results.balance) != parseFloat(window.b)) {
            window.b = data.results.balance;
            // refJWTReal();
            try {
              socket.emit("getuserpromotionnow", {}, (data) => {
                // console.log("getuserpromotionnow", data)
                dispatch(
                  actionSetprofile({
                    promotion_name: data.pro_name,
                    promotion_status: data.pro_status,
                  })
                );
              });
            } catch (error) {}

            setCheckCredit(true);
            dispatch(actionSetprofile(data.results));
          }
        }
      }
    });
  };

  const refreshInbox = () => {
    socket.emit("refreshInbox", {}, function (data) {
      if (typeof data == "undefined" || typeof data.results == "undefined") {
      } else {
        if (data.status) {
          dispatch(actionSetprofile(data.results));
        } else if (data.status === false) {
          setEmitError({
            status: true,
            message: data.errorCode,
          });
          setTimeout(() => {
            setEmitError({ status: false });
          }, 3000);
        }
      }
    });
  };

  const handleAppStateResume = () => {
    if (loginTimeout > 0) {
      let backTime = Date.now();
      // console.log(loginTimeout, backTime, (backTime - loginTimeout) / 1000)
      if ((backTime - loginTimeout) / 1000 > 86400) {
        // console.log("Suspend more than 15 minutes")
        setSessionTimeout(true);
        // try {
        //   setTimeout(() => {
        //     socket.emit("setLogout", {}, (body) => {
        //       if (typeof body != "undefined") {
        //         if (body.status) {
        //           removeAllSession();
        //           redirectToLogin(body.results.url);
        //         }
        //         else if (body.status === false) {
        //           setEmitError({ status: true, message: body.errorCode })
        //           setTimeout(() => {
        //             setEmitError({ status: false })
        //           }, 3000)
        //         }
        //       }
        //     });
        //   }, 3000)
        // } catch (error) {
        //   setEmitError({ status: true, message: error.message })
        //   setTimeout(() => {
        //     setEmitError({ status: false })
        //   }, 3000)
        // }
      } else {
        // console.log("Suspend less than 15 minutes")
        setLoginTimeout(0);
      }
    }
    // console.log(
    //   profile.wheelBGMStatus,
    //   profile.treasureBGMStatus,
    //   profile.diamondBGMStatus
    // );
    if (profile.wheelBGMStatus) {
    } else if (profile.treasureBGMStatus) {
    } else if (profile.diamondBGMStatus) {
    } else if (profile.FesBGMStatus) {
    } else if (profile.minislotBGMStatus) {
    } else if (profile.minislotSpecialBGMStatus) {
    } else {
      MainSoundBGM.volume(0.5);
    }
    // console.log("RESUME");

    window.count = 0;
    setCheckCredit(false);

    try {
      clearInterval(window.refreshCreditInterval);
    } catch (err) {
      console.log(err);
    }
    if (profile.isguest === false) {
      window.refreshCreditInterval = setInterval(() => {
        // console.log("TEST Inteval", window.count)
        refreshCredit();

        if (window.count >= 5 || checkCredit) {
          clearInterval(window.refreshCreditInterval);
        }
      }, 50000);
    }
  };

  useEffect(() => {
    setInterval(() => {
      // console.log(document.visibilityState, bgmController)
      if (document.visibilityState == "visible") {
        setbgmController(true);
      } else if (document.visibilityState == "hidden") {
        setbgmController(false);
      }
    }, 1000);
  }, []);

  useEffect(() => {
    // console.log("bgmController", bgmController);
    if (bgmController === true) {
      handleAppStateResume();
    } else if (bgmController === false) {
      handleAppStatePause();
    }
  }, [bgmController]);

  useEffect(() => {
    // if (profile.tutorial >= 14 && profile.valentine.is_select === 1) {
    //   // setPopupCNY(true);
    //   dispatch(actionPopup({ festival: true }));
    // }

    if (profile.playfishing === 1) setPlayfishing(true);

    if (profile.account_api != "") {
      try {
        socket.emit("init", {}, () => {});

        let keyProviderGameList = "Provider-GameList";
        let lProviderGameList = localStorageGetWithExpiry(keyProviderGameList);

        if (lProviderGameList != null) {
          dispatch(actionProvider(lProviderGameList));
          dispatch(actionLoading(true));
        } else {
          socket.emit("getprovidergamelist", {}, (data) => {
            console.log("getprovidergamelist", data);
            if (data.status) {
              localStorageSetWithExpiry(
                keyProviderGameList,
                data.results,
                300 * 1000
              );
              dispatch(actionProvider(data.results));
              dispatch(actionLoading(true));
            }
          });
        }

        socket.emit("getMissionList", {}, (data) => {
          if (typeof data == "undefined") {
          } else {
            if (data.status == true) {
              setNoTopup(
                data.results.filter((e) => {
                  return e.no === 3020;
                }).length > 0
                  ? data.results.filter((e) => {
                      return e.no === 3020;
                    })[0].score === 0
                    ? true
                    : false
                  : false
              );
              dispatch(actionMission(data.results));
            }
          }
        });

        socket.emit("getBuyCreditList", {}, (data) => {
          console.log("getBuyCreditList", data.results.data);
          if (
            typeof data == "undefined" ||
            typeof data.results == "undefined" ||
            typeof data.results.data == "undefined"
          ) {
          } else {
            if (data.status) {
              dispatch(actionShop({ credit: data.results.data }));
            } else if (data.status === false) {
              setEmitError({ status: true, message: data.errorCode });
              setTimeout(() => {
                setEmitError({ status: false });
              }, 3000);
            }
          }
        });

        socket.emit("getBuySpinList", {}, (data) => {
          console.log("getBuySpinList", data.results.data);
          if (
            typeof data == "undefined" ||
            typeof data.results == "undefined" ||
            typeof data.results.data == "undefined"
          ) {
          } else {
            if (data.status == true) {
              dispatch(
                actionShop({ freeSpin: data.results.data, freeSpinGroup: 0 })
              );
            } else if (data.status == false) {
              setEmitError({ status: true, message: data.errorCode });
              setTimeout(() => {
                setEmitError({ status: false });
              }, 3000);
            }
          }
        });

        const Logout = (txt) => {
          console.log("location: ", txt);
          //
          // alert(txt);

          try {
            socketioKafka.disconnect();
            socket.disconnect();
            removeAllSession();
          } catch {}
          dispatch(actionPopup({ alertNoti: true }));
          dispatch(
            actionPopupMessage({ alertNoti: t("loginbyanotheraccount") })
          );
          redirectToLogin(
            String(profile.cmurl).trim() == ""
              ? undefined
              : profile.cmurl + "?logout=true"
          );

          // if (window.isLogout != true) {
          //   window.isLogout = true;
          //   socket.emit("setLogout", {}, (body) => {
          //     console.log()
          //     if (typeof body != "undefined") {
          //       if (body.status) {
          //         var input = body.results.url + "?logout=true";
          //         redirectToLogin(input);
          //         removeAllSession();
          //         try {
          //           socketioKafka.disconnect();
          //           socket.disconnect();
          //         } catch (error) { }
          //       }
          //     }
          //   });
          // }
        };
        // profile.account_api
        // localStorageSetWithExpiry ,localStorageGetWithExpiry
        const getGameList = (fn, account_api) => {
          if (String(account_api).trim() == "") return fn([]);

          let keyGame = "game-" + account_api;
          let listOfGame = localStorageGetWithExpiry(keyGame);
          if (listOfGame != null) {
            dispatch(actionGameList(listOfGame));
            return fn(listOfGame);
          }

          socket.emit(
            "getGameList",
            {
              page: 1,
              limit: 9999,
              //   provider: props.pvid,
              //   categories: gameCategory
            },
            (data) => {
              console.log("getGameList", data.results);
              if (
                typeof data == "undefined" ||
                typeof data.results == "undefined" ||
                typeof data.results.data == "undefined"
              ) {
              } else {
                if (data.status == true) {
                  localStorageSetWithExpiry(
                    keyGame,
                    data.results.data,
                    300 * 1000
                  );
                  dispatch(actionGameList(data.results.data));
                  return fn(data.results.data);
                }
              }
              return fn([]);
            }
          );
        };

        getGameList((d) => {
          if (d.length == 0) {
            getGameList(() => {}, profile.account_api);
          }
        }, profile.account_api);

        socketioKafka.emit(
          "register",
          { account_api: profile.account_api },
          (body) => {
            if (body.isLogin > 0) {
              //  Logout("register"); // fix not logout
            } else {
              setTimeout(() => {
                socketioKafka.on("notification", (data) => {
                  // console.log(data)
                  if (data.type === "inbox") {
                    // console.log("refreshInbox no API", data);
                    refreshInbox();
                  } else if (data.type === "win") {
                    if (
                      data.data.game.type.toLowerCase() === "pvp" ||
                      data.data.game.type.toLowerCase() === "table"
                    ) {
                    } else {
                      setNotification({
                        name: data.data.name,
                        amount: data.data.amount,
                        game: data.data.game.name,
                      });
                      setNotiPop(true);

                      try {
                        clearTimeout(window.setCLose);
                      } catch (error) {}
                      window.setCLose = setTimeout(() => {
                        setNotiPop(false);
                      }, 5000);
                    }
                  } else if (data.type === "totalwin") {
                    dispatch(actionCounterLP({ totalWin: data.data }));
                  }
                  // else if (data.type === "online") {
                  //   dispatch(actionCounterLP({ online: data.data }));
                  // }
                });

                socketioKafka.on(
                  "notification" + profile.account_api,
                  (data) => {
                    console.log("data notification", data.data);
                    let notificationData = data;
                    if (data.type === "logout") {
                      Logout("notification"); // fix not logout
                    } else if (
                      data.type === "topup" ||
                      data.type === "withdraw"
                    ) {
                      console.log(data);
                      if (
                        data.data.status === "foul" &&
                        data.type === "withdraw"
                      ) {
                        setEmitError({
                          status: true,
                          message: t("no credit back"),
                        });
                        setTimeout(() => {
                          setEmitError({ status: false });
                        }, 3000);
                      } else if (data.data.amount != 0) {
                        socket.emit("getMissionList", {}, (data) => {
                          if (
                            typeof data == "undefined" ||
                            typeof data.results == "undefined"
                          ) {
                          } else {
                            if (data.status == true) {
                              dispatch(actionMission(data.results));
                            }
                          }
                        });

                        let amount = data.data.total_amount || data.data.amount;

                        console.log("amount", amount);
                        let amountDecimal = (
                          Math.round(amount * 100) / 100
                        ).toFixed(2);

                        dispatch(actionTopup(amount));

                        socket.emit("cancelpromptpay", {}, (data) => {
                          console.log("cancelpromptpay", data);
                        });

                        socket.emit("checkdepositdayconfig", {}, (body) => {
                          // console.log("checkdepositdayconfig", body);
                          if (
                            typeof body == "undefined" ||
                            typeof body.results == "undefined"
                          ) {
                          } else {
                            if (body.status) {
                              dispatch(actionCheckDailyDeposit(body.results));
                            }
                          }
                        });

                        if (data.data.status === "refun") {
                          // setAlertNoti({
                          //   status: true,
                          //   message:
                          //     t("refund") +
                          //     ": " +
                          //     amountDecimal
                          //       .toString()
                          //       .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                          //     " " +
                          //     t("success"),
                          // });
                          dispatch(actionPopup({ alertNoti: true }));
                          dispatch(
                            actionPopupMessage({
                              alertNoti:
                                t("refund") +
                                ": " +
                                amountDecimal
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                                " " +
                                t("success"),
                            })
                          );
                        } else {
                          //dispatch ตัวแปลใหม่ amount

                          // setAlertNoti({
                          //   status: true,
                          //   message:
                          //     t(data.type) +
                          //     ": " +
                          //     amountDecimal
                          //       .toString()
                          //       .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                          //     " " +
                          //     t("success"),
                          // });
                          dispatch(actionPopup({ alertNoti: true }));
                          dispatch(
                            actionPopupMessage({
                              alertNoti:
                                t(data.type) +
                                ": " +
                                amountDecimal
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                                " " +
                                t("success"),
                            })
                          );
                        }

                        // socket.emit("refreshCredit", {}, function (data) {
                        //   if (
                        //     typeof data == "undefined" ||
                        //     typeof data.results == "undefined"
                        //   ) {
                        //   } else {
                        //     if (data.status) {
                        //       dispatch(actionSetprofile(data.results));
                        //     } else if (data.status === false) {
                        //       setEmitError({
                        //         status: true,
                        //         message: data.errorCode,
                        //       });
                        //       setTimeout(() => {
                        //         setEmitError({ status: false });
                        //       }, 3000);
                        //     }
                        //   }

                        // });
                        socket.emit("checkcashback", {}, (data) => {
                          if (typeof data == "undefined") {
                          } else {
                            if (data.status) {
                              dispatch(actionSetprofile(data.results.data));
                            } else if (data.status === false) {
                              // setEmitError({
                              //   status: true,
                              //   message: data.errorCode,
                              // });
                              // setTimeout(() => {
                              //   setEmitError({ status: false });
                              // }, 3000);
                            }
                          }
                        });
                        if (notificationData.type === "topup") {
                          // refJWTReal();
                          if (data.data.amount >= 100) {
                            dispatch(actionSetprofile({ specialOffer: {} }));
                          }
                          if (profile.seteffect === "O") {
                            TopupSound.play();
                          }
                          refreshCredit();
                        }
                      }
                      setTimeout(() => {
                        // setAlertNoti({ status: false });
                        dispatch(actionPopup({ alertNoti: false }));
                      }, 3000);
                    } else if (data.type === "inbox") {
                      // console.log("refreshInbox with API", data);
                      refreshInbox();
                    } else if (data.type === "refreshInviteFriendBonus") {
                      socket.emit(
                        "refreshInviteFriendBonus",
                        {},
                        function (data) {
                          console.log("refreshInviteFriendBonus", data);
                          if (
                            typeof data == "undefined" ||
                            typeof data.results == "undefined" ||
                            typeof data.results.data == "undefined"
                          ) {
                          } else {
                            if (data.status) {
                              dispatch(actionSetprofile(data.results.data));
                            }
                          }
                        }
                      );
                    } else if (data.type === "mission") {
                      socket.emit("getMissionList", {}, (data) => {
                        if (
                          typeof data == "undefined" ||
                          typeof data.results == "undefined"
                        ) {
                        } else {
                          if (data.status == true) {
                            dispatch(actionMission(data.results));
                          }
                        }
                      });
                    } else if (data.type === "n-jackpot") {
                      //do bigjackpot here
                      console.log("Test Jackpot");
                      setJackpotRequest(true);
                    } else if (data.type === "wallet") {
                      refreshCredit();
                      socket.emit("getwallet", {}, (data) => {
                        console.log(data);
                      });
                    }
                  }
                );
              }, 500);
            }
          }
        );
      } catch (error) {
        setEmitError({ status: true, message: error.message });
        setTimeout(() => {
          setEmitError({ status: false });
        }, 3000);
      }
    }
  }, [profile.account_api]);

  function numberWithCommas(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  useEffect(() => {
    socket.removeEventListener("updateInfo");
    setOldDiamond(profile.diamond);
    setOldBalance(profile.balance);
    // console.log("profile.diamond", profile.diamond);
    socket.on("updateInfo", (data) => {
      if (data.diamond && oldDiamond > 0 && data.diamond != oldDiamond) {
        let totalDiamond = Math.floor(data.diamond - oldDiamond).toFixed(0);
        setDiamondAlert(
          totalDiamond > 0
            ? "+" + numberWithCommas(totalDiamond)
            : numberWithCommas(totalDiamond)
        );
        setShowDiamond(true);
        setTimeout(() => {
          setShowDiamond(false);
          setOldDiamond(data.diamond);
        }, 1200);
      }

      if (
        !isNaN(data.balance) &&
        !isNaN(profile.balance) &&
        oldBalance >= 0 &&
        data.balance != oldBalance
      ) {
        if (showBalanceDelay) {
          let totalBalance = (data.balance - oldBalance).toFixed(2);
          setBalanceAlert(
            totalBalance > 0
              ? "+" + numberWithCommas(totalBalance)
              : numberWithCommas(totalBalance)
          );
          setShowBalance(true);
          setTimeout(() => {
            setShowBalance(false);
            setOldBalance(data.credit);
          }, 1200);
        }
      }

      dispatch(actionSetprofile(data));
    });
  }, [profile.account_api, oldDiamond, oldBalance, showBalanceDelay]);

  const handleSound = () => {
    try {
      if (profile.setsound === "O" && MainSoundBGM.playing() === false) {
        MainSoundBGM.stop();
        setTimeout(() => {
          MainSoundBGM.play();
        }, 100);
      }
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  };

  useEffect(() => {
    try {
      i18next.changeLanguage(profile.language.toLowerCase());
      getNewUrlwithLang(profile.language.toLowerCase());
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  }, [profile.language]);

  useEffect(() => {
    try {
      if (profile.setsound === "O") {
        MainSoundBGM.play();
      } else {
        MainSoundBGM.stop();
      }
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  }, [profile.setsound]);

  let checkChoice = false;
  if (
    typeof profile.skf !== "undefined" &&
    typeof profile.skf.stage !== "undefined" &&
    profile.skf.stage.length > 0
  ) {
    // console.log("profile.cny", profile.cny);
    checkChoice = true;
  }

  // let checkMission = mission.map((item) => item.no).indexOf(6130);
  let checkMission = mission.filter((item) => item.mission_group == 6);
  // console.log("profile popup", checkMission);

  return (
    <div
      style={{
        userSelect: "none",
        height: window.appHeight(),
        overflow: "hidden",
      }}
      onClick={handleSound}
    >
      <BG {...dimensions}>
        {isLoading == false ? <Loading /> : ""}
        {isFreeSpin.status == true ? (
          <GamePlay isFreeSpin={true} />
        ) : (
          <Router>
            <Route path="/gameplay">
              <GamePlay />
            </Route>

            <Route exact path="/">
              {(profile.popup.filter((a) => a.no == 1).length > 0 ||
                popupStatus.eventBanner) &&
              !profile.isguest ? (
                <EventPopupBanner />
              ) : (
                ""
              )}

              {popupStatus.dailyLogin ? <DailyLogin /> : ""}
              {levelUpPopup ? (
                <LevelupPopup setLevelUpPopup={setLevelUpPopup} />
              ) : (
                ""
              )}
              {showJackpot
                ? profile.jackpot.map((item, i) => {
                    return item.type === 1 &&
                      profile.jackpot.length - i <= jackpotCounter ? (
                      <BigJackpot
                        key={"index " + i}
                        style={{ zIndex: 100 }}
                        type={1}
                        jackpotCounter={jackpotCounter}
                        setShowJackpot={setShowJackpot}
                        setJackpotCounter={setJackpotCounter}
                        setJackpotRequest={setJackpotRequest}
                      />
                    ) : item.type === 2 &&
                      profile.jackpot.length - i <= jackpotCounter ? (
                      <BigJackpot
                        key={"index " + i}
                        type={2}
                        jackpotCounter={jackpotCounter}
                        setShowJackpot={setShowJackpot}
                        setJackpotCounter={setJackpotCounter}
                        setJackpotRequest={setJackpotRequest}
                      />
                    ) : (
                      ""
                    );
                  })
                : ""}
              {showDiamond ? (
                <Tween to={{ y: -50, duration: 1.2 }}>
                  <DiamondAlert>
                    <img src="../images/icon_diamond.png" />
                    <p className="inner">{diamondAlert}</p>
                    <p className="outer">{diamondAlert}</p>
                  </DiamondAlert>
                </Tween>
              ) : (
                ""
              )}
              {showBalance ? (
                <Tween to={{ y: -50, duration: 1.2 }}>
                  <DiamondAlert style={{ top: "5%" }}>
                    <img src="../images/icon_coin.png" />
                    <p className="inner">{balanceAlert}</p>
                    <p className="outer">{balanceAlert}</p>
                  </DiamondAlert>
                </Tween>
              ) : (
                ""
              )}

              {/* {profile.isguest == false && popupList.length > 0
                ? popupList.map((item, index) =>
                    eventPopup[index] == true &&
                    profile.tutorial >= parseInt(item.ftue) ? (
                      <EventPopup
                        key={index}
                        index={index}
                        eventPopup={eventPopup}
                        setEventPopup={setEventPopup}
                        item={item}
                      />
                    ) : null
                  )
                : ""} */}
              {profile.banner_pro_popup ? (
                <FtuePopup02 closeClick={() => {}} />
              ) : (
                ""
              )}
              {/* {profile.first_login ? <FtuePopup01 closeClick={() => { }} /> : ""} */}
              {
                // !profile.isguest &&
                // ftuePop &&
                // // !isRefresh &&
                // profile.first_login &&
                // profile.tutorial > 0 &&
                // noTopup ? (
                //   <FtuePopup
                //     setFtuePop={setFtuePop}
                //     banner="new_banner2_100bonus"
                //   />
                // ) :
                !profile.isTelGuest &&
                ftuePop &&
                !isRefresh &&
                profile.tutorial > 0 ? (
                  // <FtuePopup
                  //   setFtuePop={setFtuePop}
                  //   banner="new_player_misssion_reward_560x300"
                  // />
                  <NewUserPopup setFtuePop={setFtuePop} />
                ) : (
                  ""
                )
              }

              <>
                {/* {showLandScape ? <LandScapeAlert /> : ""} */}
                {/* <Testpop/> */}
                <Navbar />
                {/* {profile.tutorial >= 16 && checkChoice ? (
                  <FestivalBanner />
                ) : (
                  ""
                )} */}
                {/* survey event */}
                <IconBox>
                  <MysteryBoxBanner />
                  {/* <FestivalBanner type="banner" /> */}
                  {/* {profile.popup.length > 0 && !profile.isguest ? (
                    <FestivalBanner type="banner" />
                  ) : (
                    ""
                  )}
                  {checkMission.length > 0 && !profile.isguest
                    ? checkMission.map((mission, index) => (
                        <FestivalBanner
                          key={"festivalBanner-" + index}
                          type="missionEvent"
                          mission={mission}
                        />
                      ))
                    : ""}
                  {profile.jackpot.length > 0 ? (
                    <JackpotBanner setShowJackpot={setShowJackpot} />
                  ) : (
                    ""
                  )}
                  {Object.keys(profile.specialOffer).length !== 0 ? (
                    <FlashDealPop setFlashDealPop={setFlashDealPop} />
                  ) : (
                    ""
                  )}
                  {profile.timeLimit.map((item, i) => {
                    return Object.keys(item).length > 0 ? (
                      <TimeLimitPromotionPop
                        index={i}
                        setTimeLimitProPop={setTimeLimitProPop}
                        key={"index" + i}
                      />
                    ) : (
                      ""
                    );
                  })} */}
                </IconBox>

                <Transition
                  in={notiPop}
                  timeout={300}
                  classNames="sr2l"
                  unmountOnExit
                >
                  <Notification
                    username={notification.name}
                    balance={notification.amount}
                    games={notification.game}
                    style={{ marginTop: "6vw", zIndex: 20 }}
                  />
                </Transition>
                <Transition
                  in={popupStatus.alertNoti}
                  timeout={300}
                  classNames="alert"
                  unmountOnExit
                >
                  <AlertMessage
                    message={popupMessage.alertNoti}
                    onClick={() => {
                      // setAlertNoti({ status: false });
                      dispatch(actionPopup({ alertNoti: false }));
                    }}
                  />
                </Transition>
                <Transition
                  in={emitError.status}
                  timeout={300}
                  classNames="alert"
                  unmountOnExit
                >
                  <AlertMessage
                    message={t(emitError.message)}
                    onClick={() => {
                      setEmitError({ status: false });
                    }}
                  />
                </Transition>

                <Transition
                  in={alertMessageSelect.isAlert}
                  timeout={300}
                  classNames="alert"
                  unmountOnExit
                >
                  <AlertMessage message={t(alertMessageSelect.errorCode)} />
                </Transition>

                <Transition
                  in={disconnect}
                  timeout={300}
                  classNames="alert"
                  unmountOnExit
                >
                  <AlertMessage message={t("cannot connect the internet")} />
                </Transition>
                <Transition
                  in={sessionTimeout}
                  timeout={300}
                  classNames="alert"
                  unmountOnExit
                >
                  <ConfirmPopup
                    messageBottom={t("confirm")}
                    cancelClick={() => {
                      try {
                        socket.emit("setLogout", {}, (body) => {
                          if (typeof body != "undefined") {
                            if (body.status) {
                              removeAllSession();
                              redirectToLogin(body.results.url);
                            } else if (body.status === false) {
                              setEmitError({
                                status: true,
                                message: body.errorCode,
                              });
                              setTimeout(() => {
                                setEmitError({ status: false });
                              }, 3000);
                            }
                          }
                        });
                      } catch (error) {
                        setEmitError({ status: true, message: error.message });
                        setTimeout(() => {
                          setEmitError({ status: false });
                        }, 3000);
                      }
                    }}
                  >
                    {t("s0051")}
                  </ConfirmPopup>
                </Transition>
                <Transition showPop={popupStatus.guestCheck} type="alert">
                  <GuestPopup
                    cancelClick={() => {
                      dispatch(actionPopup({ guestCheck: false }));
                    }}
                    confirmClick={() => {
                      dispatch(
                        actionPopup({
                          mission: false,
                          diamondExchange: false,
                          diamondShop: false,
                          guestCheck: false,
                          search: false,
                        })
                      );
                    }}
                  >
                    {t(popupStatus.message)}
                  </GuestPopup>
                </Transition>
                <Transition showPop={popupStatus.bankCheck} type="alert">
                  <BankPopup
                    cancelClick={() => {
                      dispatch(actionPopup({ bankCheck: false }));
                    }}
                    confirmClick={() => {
                      dispatch(
                        actionPopup({
                          mission: false,
                          diamondExchange: false,
                          diamondShop: false,
                          bankCheck: false,
                          search: false,
                        })
                      );
                    }}
                  >
                    {t(popupStatus.message)}
                  </BankPopup>
                </Transition>
                <Transition showPop={popupStatus.cashback} type="alert">
                  <CashbackPopup
                    cancelClick={() => {
                      // setCashbackPopup(false);
                      dispatch(actionPopup({ cashback: false }));
                    }}
                    confirmClick={() => {
                      // setCashbackPopup(false);
                      dispatch(actionPopup({ cashback: false, search: false }));
                    }}
                  >
                    {/* {t("alert casino cashback")} */}
                  </CashbackPopup>
                </Transition>
                <Transition showPop={popupStatus.iframe} type="alert">
                  <PopupIframe
                    url={popupStatus.url}
                    closeClick={() => {
                      dispatch(actionPopup({ iframe: false }));
                    }}
                  />
                </Transition>
                <Transition showPop={confirmPopup.status} type="alert">
                  <ConfirmPopup
                    messageBottom={t("okay")}
                    cancelClick={() => {
                      dispatch(actionConfirmPopup({ status: false }));
                    }}
                  >
                    {t(confirmPopup.message)}
                  </ConfirmPopup>
                </Transition>
                <Transition showPop={popupStatus.createAccount} type="alert">
                  <CreateAccount
                    closeClick={() => {
                      dispatch(actionPopup({ createAccount: false }));
                    }}
                  />
                </Transition>

                {/* <Transition showPop={popupStatus.festival} type="alert">
                  <Valentine
                    MainSoundBGM={MainSoundBGM}
                    // messageBottom={t("okay")}
                    closeClick={() => {
                      dispatch(actionPopup({ festival: false }));
                    }}
                  />
                </Transition> */}

                {/* <Transition showPop={popupStatus.festival} type="alert">
                  <SKD
                    MainSoundBGM={MainSoundBGM}
                    // messageBottom={t("okay")}
                    closeClick={() => {
                      dispatch(actionPopup({ festival: false }));
                    }}
                  />
                </Transition> */}

                {/* <Transition showPop={popupCNY} type="sb2t">
                  <CNYGame
                    closeClick={() => {
                      setPopupCNY(false);
                    }}
                  />
                </Transition> */}
                {/* {popupStatus.festivalGame ? (
                  <SKDMinigame
                    MainSoundBGM={MainSoundBGM}
                    bgmController={bgmController}
                    disconnect={disconnect}
                  />
                ) : (
                  ""
                )} */}

                {/* <AlertReward amount={57} /> */}
                <Transition showPop={playfishing} type="sb2t">
                  <ConfirmPopup
                    messageBottom={t("okay")}
                    cancelClick={() => {
                      setPlayfishing(false);
                    }}
                  >
                    {t("fishing alert popup")}
                  </ConfirmPopup>
                </Transition>
                {/* <Transition showPop={popupStatus.depositWithdrawal} type="st2b">
                  <DepositWithdrawal
                    closeClick={() => {
                      dispatch(actionPopup({ depositWithdrawal: false }));
                    }}
                  />
                </Transition> */}

                {popupStatus.depositWithdrawal ? (
                  <DepositWithdrawal
                    closeClick={() => {
                      dispatch(actionPopup({ depositWithdrawal: false }));
                    }}
                  />
                ) : null}

                {/* <Transition
                  in={playfishing}
                  timeout={300}
                  classNames="alert"
                  unmountOnExit
                >
                  <AlertMessage message={t("โปรดรอ 1-2 นาทีเพื่อทำการอัพเดทยอดเครดิตของคุณ")} />
                </Transition> */}

                {Object.keys(profile.specialOffer).length !== 0 &&
                flashDealPop ? (
                  <FlashDeal setFlashDealPop={setFlashDealPop} />
                ) : (
                  ""
                )}

                {profile.timeLimit.filter(
                  (item) => Object.keys(item).length > 0
                ).length > 0 &&
                timeLimitProPop.status &&
                (flashDealPop === false ||
                  Object.keys(profile.specialOffer).length === 0) &&
                popupStatus.depositWithdrawal === false ? (
                  <TimeLimitPromotion
                    timeLimitProPop={timeLimitProPop}
                    setTimeLimitProPop={setTimeLimitProPop}
                  />
                ) : (
                  ""
                )}

                <Footer MainSoundBGM={MainSoundBGM} />
                <Content />
                {/* {floatingStatus &&
                  /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                  ) ? (
                  <FloatingInputBg>
                    <FloatingElement>
                      <FloatingInputLabel>{floatingLabel}</FloatingInputLabel>
                      <FloatingInput
                        type="text"
                        readOnly
                        value={floatingValue}
                        style={{
                          direction:
                            floatingLabel === t("issues here") ? "rtl" : "",
                        }}
                      />
                      <FloatingInputDoneBtn>Done</FloatingInputDoneBtn>
                    </FloatingElement>
                  </FloatingInputBg>
                ) : (
                  ""
                )} */}
              </>
            </Route>
          </Router>
        )}
      </BG>
    </div>
  );
}

export default App;
