import React, { useState, Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import { Route, Link, Redirect, Switch, useLocation } from "react-router-dom";
import { selectContentpromo } from "../../Reducers/promotionSlice";
import { useTranslation } from "react-i18next";

import styled from "styled-components";
import Language from "../Language/";
import Button from "../Button";
import Login from "../Form/Login";
import Content from "../Content";
import { selectContenthow } from "../../Reducers/HowtouseSlice";
import { getLang, Transition } from "../../config";
import { selectHomeContentLP } from "../../Reducers/homeContentLPSlice";
import Terms from "../Terms";

const Games = lazy(() => import("../Games"));
const LiveCasino = lazy(() => import("../LiveCasino"));
// const GamePlayMobile = lazy(() => import("../GameList/GamePlayMobile"));
const ContentPromotion = lazy(() => import("../ContentPromotion"));
const FAQ = lazy(() => import("../Faq/"));
const Support = lazy(() => import("../Support/"));
const ContentHowtouse = lazy(() => import("../ContentHowtouse"));

const Nav = styled.div`
  background-image: url("../../lpimages/nav_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  height: 19.08vw;
  position: fixed;
  top: 0;
  z-index: 100;
`;

const BackgroundNavMenu = styled.div`
  width: 100vw;
  height: 100vh;
  margin-top: -19vw;
  background-color: rgb(0 0 0 / 0.5);
`;

const NavMenu = styled.div`
  width: 86.2vw;
  height: 123.19vw;
  background-color: #222535;
  box-shadow: inset 0vw -1.2vw 0vw #d24403;
  border-radius: 0vw 0vw 0vw 2.42vw;
  padding: 0 4vw;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
`;

const NavLeft = styled.div`
  width: 100vw;
  background-image: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > img {
    height: 9.66vw;
    margin-right: 2vw;
  }
`;

const Logo = styled.div`
  background-image: url("../../lpimages/spinix_normal_white_1_.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 48.55vw;
  height: 14.98vw;
  margin: 2vw 0;
`;

const Header = styled.div`
  width: 86.2vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4.5vw;
  > p {
    color: #4e5476;
    font-size: 3.62vw;
  }
  > img {
    height: 7.25vw;
  }
`;

const LinkMenu = styled(Link)`
  color: white;
  margin-left: 1.5vw;
  text-decoration: none;
  ${(props) =>
    props.active === "true" &&
    `
  color: #FF7000;
  `}
`;

const Li = styled.div`
  font-size: 4.83vw;
  height: 12.08vw;
  display: table-cell;
  vertical-align: middle;
`;

const Line = styled.div`
  background-image: url("../../lpimages/m_nav_seperate.png");
  width: 95%;
  height: 0.5vw;
  transform: scaleX(-1);
`;

const LanguageBox = styled.div`
  display: flex;
  margin: 2vw 0 7.5vw 0;
  > p {
    color: #fff;
    font-size: 3.62vw;
    margin-right: 4vw;
    margin-top: 2.5vw;
  }
`;

const LoginButton = styled.div`
  position: initial;
  display: inline-block;
  margin-right: -2vw;
  margin-left: 1vw;
`;

export const NavbarMobile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const promo = useSelector(selectContentpromo);
  const howto = useSelector(selectContenthow);
  const homeContentLP = useSelector(selectHomeContentLP);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  // const [homeContent, setHomeContent] = useState([]);

  const ScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // useEffect(() => {
  //   if (homeContentLP.length !== 0) {
  //     setHomeContent(homeContentLP[3].contents);
  //   }
  // }, [homeContentLP]);

  return (
    <>
      <Nav>
        <NavLeft>
          <LinkMenu to="/">
            <Logo />
          </LinkMenu>
          <LoginButton>
            <Button
              bClass="rtRedLoginM"
              width="30vw"
              height="13vw"
              label={t("login")}
              onClick={() => setShowMessage(true)}
            />
          </LoginButton>
          <img
            alt=""
            src="../../lpimages/m_nav_menu.png"
            onClick={() => setIsOpen(true)}
          />
        </NavLeft>
        <Transition in={isOpen} timeout={300} classNames="fade" unmountOnExit>
          <BackgroundNavMenu />
        </Transition>

        <Transition in={isOpen} timeout={300} classNames="sr2l" unmountOnExit>
          <NavMenu>
            <Header>
              <p>// Navigation Menu</p>
              <img
                alt=""
                src="../../lpimages/m_btn_closenav.png"
                onClick={() => setIsOpen(false)}
              />
            </Header>
            <Li>
              <LinkMenu
                to="/"
                onClick={() => {
                  setIsOpen(false);
                  ScrollToTop();
                }}
                active={location.pathname === "/" ? "true" : "false"}
              >
                {" "}
                {/* boolean to string(fixed Warning error) */}
                {t("home")}
              </LinkMenu>
            </Li>
            <Line />
            <Li>
              <LinkMenu
                to="/live-casino"
                onClick={() => {
                  setIsOpen(false);
                  ScrollToTop();
                }}
                active={
                  location.pathname.split("/")[1] === "live-casino"
                    ? "true"
                    : "false"
                }
              >
                {t("Live Casino")}
              </LinkMenu>
            </Li>
            <Line />
            <Li>
              <LinkMenu
                to="#"
                onClick={() => {
                  setIsOpen(false);
                  window.location =
                    "https://provider.spxslot.xyz/?lang=" + i18n.language;
                }}
                active={
                  location.pathname.split("/")[1] === "games" ? "true" : "false"
                }
              >
                {t("games")}
              </LinkMenu>
            </Li>
            {/* <Line />
            <Li>
              <LinkMenu
                to="/ContentPromotion"
                onClick={() => {
                  setIsOpen(false);
                  ScrollToTop();
                }}
                active={
                  location.pathname.split("/")[1] == "ContentPromotion"
                    ? "true"
                    : "false"
                }
              >
                {t("promotions")}
              </LinkMenu>
            </Li> */}
            <Line />
            <Li>
              <LinkMenu
                to="/faq"
                onClick={() => {
                  setIsOpen(false);
                  ScrollToTop();
                }}
                active={location.pathname === "/faq" ? "true" : "false"}
              >
                {t("faq")}
              </LinkMenu>
            </Li>
            <Line />
            <Li>
              <LinkMenu
                to="/support"
                onClick={() => {
                  setIsOpen(false);
                  ScrollToTop();
                }}
                active={location.pathname === "/support" ? "true" : false}
              >
                {t("support")}
              </LinkMenu>
            </Li>
            <Line />
            <LanguageBox>
              <p>{t("language")}</p>
              <Language width="61.35vw" />
            </LanguageBox>
            <LoginButton>
              <Button
                bClass="rtRed"
                label={t("login")}
                width="82.55vw"
                height="14.50vw"
                fontSize="4.83vw"
                onClick={() => setShowMessage(true)}
              />
            </LoginButton>
          </NavMenu>
        </Transition>
      </Nav>

      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path="/ContentHowtouse">
            <ContentHowtouse />
          </Route>
          <Route
            path={"/ContentHowtouse/:id"}
            render={(props) => (
              <Content
                {...props}
                howtouse={"howtouse"}
                {...howto.contenthow[getLang()][
                  parseInt(props.match.params.id)
                ]}
              />
            )}
          ></Route>
          <Redirect exact from="/live-casino" to="/live-casino/hot" />
          <Route path="/live-casino">
            <LiveCasino />
          </Route>
          <Redirect exact from="/games" to="/games/hot" />
          <Route path="/games">
            <Games />
          </Route>
          <Route exact path="/ContentPromotion">
            <ContentPromotion />
          </Route>
          <Route
            path={"/ContentPromotion/:id"}
            render={(props) => (
              <Content
                {...props}
                promotion={"promotion"}
                {...promo.contentpromo[getLang()][
                  parseInt(props.match.params.id)
                ]}
              />
            )}
          ></Route>
          <Route path="/faq">
            <FAQ />
          </Route>
          <Route path="/support">
            <Support title={t("support")} text={t("SupportDescription")} />
          </Route>
          <Route path="/terms-and-conditions">
            <Terms />
          </Route>
          <Route
            path={"/:path"}
            render={(props) => (
              <Content
                {...props}
                article={"article"}
                {...homeContentLP[3].contents.filter(
                  (i) => i.path === props.match.params.path
                )[0]}
              />
            )}
          ></Route>
        </Switch>
      </Suspense>

      <Transition
        in={showMessage}
        timeout={300}
        classNames="alert"
        unmountOnExit
      >
        <Login onClose={() => setShowMessage(false)} />
      </Transition>
    </>
  );
};

export default NavbarMobile;
