import { useState, useEffect } from 'react';
import Loading from './Loading';
import App from "./AppConnect";
import axios from "axios";
import { config, getParameterByName, redirectAfterLoginWithToken, getJWT, getBackUrl, localStorageGetWithExpiry, localStorageSetWithExpiry, setToken, getFullUrl, redirectToLogin, removeAllSession } from "../config";
import LPApp from './LPAppConnect';
import { BrowserRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectAfterLogin } from "../Reducers/afterLogin";
import { actionSetprofile } from "../Reducers/userProfileSlice";
import { actionSetIslogin, actionSetIsRefresh } from "../Reducers/afterLogin";
import { detect } from 'detect-browser';
import BrowserSupport from './BrowserSupport';
import EMApp from "../EMApp"
import all from 'gsap/all';

function Auth() {
    const [isBusy, setBusy] = useState(true)
    const isLoginPage = useSelector(selectAfterLogin);
    const dispatch = useDispatch();
    const browser = detect();
    const username = getParameterByName("username");
    const agent_id = getParameterByName("a");
    // const uid = getParameterByName("u");
    let key = getParameterByName("key");
    const brand = getParameterByName("b");




    let backendToken = getParameterByName("uid");
    let backendAccountApi = getParameterByName("account_api");
    let url = getParameterByName("url");

    let urlPage = window.location.href;
    let urlexit = urlPage.indexOf("uaccountapi") > -1;  // uaccountapi

    useEffect(() => {
        let token = null;

        try {
            token = localStorage.getItem("ltk");
        } catch (error) {

        }

        if (brand != null) {
            try {
                localStorage.setItem("brand", brand)
            } catch (error) {
            }
        }
        let isNotSlot = window.location.href.indexOf("/slot") == -1;
        let isNoForgotpassword = window.location.href.indexOf("forgotpassword") == -1;

        if (!isNoForgotpassword) {
            key = "forgotpassword"
            // key = Math.floor(Math.random() * 1000000).toString(36) + Date.now().toString(36) + Math.floor(Math.random() * 1000000).toString(36)
        }
        if (urlexit) {
            let spBackendToken = urlPage.split("/")[3].split("?")[0].split("uaccountapi");

            if (spBackendToken.length > 1) {
                backendAccountApi = spBackendToken[1];
                backendToken = spBackendToken[0];
            }
            else if (backendAccountApi == null)
                redirectToLogin(getFullUrl(window.location.protocol + "//" + window.location.host))


            axios.get(config.socketEndpoint + "refreshJWTByRedis?uid=" + backendToken + "&account_api=" + backendAccountApi, {
            }).then(o => {
                if (o.data.status === true) {
                    redirectToLogin(getFullUrl(o.data.results.jwtToken))
                }
                else {

                }
            }).catch(function (error) {

            });
            return;
        }

        if (getParameterByName("token") !== null) {
            axios.get(config.socketEndpoint + "checkToken?token=" + getParameterByName("token"), {
            }).then(o => {
                // console.log("check token", o)
                if (o.data.status === true) {
                    // redirectAfterLoginWithToken(token)
                }
                else {
                    removeAllSession()
                    redirectToLogin()
                }
            }).catch(function (error) {

            });
        }

        if (
            username != null
        ) {

            axios.post(config.socketEndpoint + "getloginagent", {
                "account_api": username,
                "password": getParameterByName("password"),
                "uid": key,
                ...getBackUrl()
            })
                .then(data => {
                    if (data.data.status === true) {
                        window.location.replace(getFullUrl(data.data.results.jwtToken))
                    }
                    else {
                        alert("Username or Password is invalid")
                    }
                }).catch(function (error) {
                    alert("Can't Connect Backend Server")
                    console.log(error);
                });

        }
        else if (brand != null && isNotSlot) {
            // /slot
            const brands_id = getParameterByName("b");
            const refercodeby = getParameterByName("r");
            let oGuest = {
                "agent_id": agent_id,
                "refercodeby": refercodeby || "",
                "brands_id": brands_id || "",
                "uid": key
            };

            if (refercodeby != null) {
                localStorageSetWithExpiry("refercodeby",refercodeby,8600000)
            }

            axios.post(config.socketEndpoint + "createguestuser", oGuest)
                .then(data => {

                    if (data.data.status === true) {
                        redirectAfterLoginWithToken(data.data.results.jwtToken + (isNoForgotpassword ? "" : "&m=forgotpassword"))
                        dispatch(
                            actionSetprofile(
                                data.data.results.data
                            )
                        );
                        dispatch(
                            actionSetIslogin(true)
                        );
                        dispatch(
                            actionSetIsRefresh(false)
                        );
                    }
                    else {
                        alert("Username or Password is invalid")
                    }
                }).catch(function (error) {
                    alert("Can't Connect Backend Server")
                    console.log(error);
                });
        }
        else if (getJWT() !== null) {
            let fUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + "?token=" + getJWT()
            if (!isNoForgotpassword) {
                fUrl = fUrl + "&m=forgotpassword"
            }

            redirectAfterLoginWithToken("", fUrl);
            dispatch(
                actionSetIslogin(true)
            );
            dispatch(
                actionSetIsRefresh(true)
            );
        }
        else if (token != null && token != "" && token != "null" && getParameterByName("token") == null && isNotSlot && isNoForgotpassword) {
            // redirectAfterLoginWithToken(token)
            redirectToLogin(getFullUrl(token))
        }

        else {
            setBusy(false);
        }
    }, [])

    return (
        // isLoginPage == false ?
        //     (isBusy ?
        //         <Loading /> :
        //         (getParameterByName("token") != null ?
        //             <App /> :
        //             (window.location.href.toLocaleLowerCase().indexOf("forgotpassword") > -1 ? <BrowserRouter> <EMApp /> </BrowserRouter> : <BrowserRouter> <LPApp /> </BrowserRouter>)
        //         )
        //     )
        //     : <App />

        isLoginPage == false ?
            (isBusy ?
                <Loading /> :
                (getParameterByName("token") != null ?
                    <App /> :
                    (window.location.href.toLocaleLowerCase().indexOf("forgotpassword") > -1 ? <BrowserRouter> <EMApp /> </BrowserRouter> : <BrowserRouter> <LPApp /> </BrowserRouter>)
                )
            )
            : (window.location.href.toLocaleLowerCase().indexOf("forgotpassword") > -1 ? <BrowserRouter> <EMApp /> </BrowserRouter> : <App />)

    );
}
export default Auth;