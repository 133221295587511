import { createSlice } from "@reduxjs/toolkit";
export const dimensionSlice = createSlice({
  name: "dimensions",
  initialState: {
    dimension: {
      width: window.innerWidth + "px",
      height: window.innerHeight + "px",
    },
  },
  reducers: {
    setDimension: (state, action) => {
      state.dimension = action.payload;
    },
  },
});

export const { setDimension } = dimensionSlice.actions;
export const selectDimensions = (state) => state.dimensions.dimension;
export default dimensionSlice.reducer;
