import { createSlice } from "@reduxjs/toolkit";
export const gameCategorySlice = createSlice({
  name: "gameCategory",
  initialState: {
    categoryName: "",
    providers: [],
    isCategory: false,
    isProvider: false,
    providersItem: [],
    gameType: "all",
  },
  reducers: {
    actionGameCategory: (state, action) => {
      state.categoryName = action.payload;
    },
    actionSetProviders: (state, action) => {
      state.providers = action.payload;
    },
    actionIsCategory: (state, action) => {
      state.isCategory = action.payload;
    },
    actionIsProvider: (state, action) => {
      state.isProvider = action.payload;
    },
    actionProvidersItem: (state, action) => {
      state.providersItem = action.payload;
    },
    actionGameType: (state, action) => {
      state.gameType = action.payload;
    },
  },
});

export const {
  actionGameCategory,
  actionIsCategory,
  actionIsProvider,
  actionSetProviders,
  actionProvidersItem,
  actionGameType,
} = gameCategorySlice.actions;
export const selectGameCategory = (state) => state.gameCategory.categoryName;
export const selectSetProviders = (state) => state.gameCategory.providers;
export const selectIsCategory = (state) => state.gameCategory.isCategory;
export const selectIsProvider = (state) => state.gameCategory.isProvider;
export const selectProvidersItem = (state) => state.gameCategory.providersItem;
export const selectGameType = (state) => state.gameCategory.gameType;
export default gameCategorySlice.reducer;
