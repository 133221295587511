import { useContext } from "react";
import styled, { css, keyframes } from "styled-components";
import Logo from "./logo";
import Currency from "./Currency";
import Popup from "./PopupMenu";
// import Login from "../Login/index";
import { Transition, config, redirect2Page } from "../../config";
import {
  selectuserProfile,
  actionClickSound,
} from "../../Reducers/userProfileSlice";
// import HeaderBG from "./headerBG";
import { useTranslation } from "react-i18next";
import { SocketContext } from "../../context/socket";
import JackpotCount from "../Jackpot/jackpotCounter";
// import axios from "axios";
// import { actionCounterLP } from "../../Reducers/counterLPSlice";
import { selectPopup, actionPopup } from "../../Reducers/popupSlice";
import { useDispatch, useSelector } from "react-redux";
import { pulse, flash } from "react-animations";
// import GuestDetect from "../Register/GuestDetect";
import BankAccount from "../Register/BankAccount";
import breakpoints from "../../LandingPageComponents/breakpoints";
import DepoWithdra from "./DepoWithdra";

// const UserProfile = lazy(() => import("../UserProfile"));
// const CurrencyPop = lazy(() => import("./Currency"));
// const DepositWithdrawal = lazy(() => import("../DepositWithdrawal/Deposit"));

// const TutorialAnimation = keyframes`${rubberBand}`;
const RegisterAnimation = keyframes`${pulse}`;
const RegisterAnimation2 = keyframes`${flash}`;

const Nav = styled.div`
  user-select: none;
  width: 100vw;
  height: 8.4vw; // FixHeight
  position: relative;
  z-index: 30;
  @media (orientation: portrait) {
    height: 100%;
  }
  /* ${() =>
    window.appHeight() > window.innerWidth &&
    css`
      height: 100%;
    `} */
`;

const Bar = styled.div`
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  min-height: 0px;
  padding-left: 0.45%;
  padding-right: 0px;
`;

const LeftItem = styled.div`
  user-select: none;
  height: 100%;
  width: max-content;
`;

const HeaderAnims = styled.div`
  // background-color: rgba(100,100,100,0.7);
  width: 100%;
  // height: 21.8vh;
  height: 9vw;
  // position: absolute;
  position: relative;
  top: 0;
  z-index: 30;
  color: white;
  background-image: url(../../${config.theme}/newasset/top_panel_bg.png);
  background-size: contain;
  background-repeat: repeat-x;
  background-position: center top;
  @media ${breakpoints.device.line} {
    background-size: 70%;
    height: 18.8vh;
  }
  //Portrait
  @media (orientation: portrait) {
    height: 16vw;
    background-size: 190%;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    background-size: 145%;
    height: 12vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          height: 16vw;
          background-size: 190%;
          // z-index:-1;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          background-size: 145%;
          height: 12vw;
          //  z-index:-1;
        `} */
`;
const HeaderFrame = styled.div`
  width: fit-content;
  height: fit-content;
  margin: 0 auto;
  position: absolute;
  //Portrait
  @media (orientation: portrait) {
    width: 100%;
    height: 100%;
  }
  /* ${() =>
    window.appHeight() > window.innerWidth &&
    css`
      width: 100%;
      height: 100%;
    `} */
`;

const piganims = keyframes`
  0% {transform: scaleX(1.2) scaleY(0.9);}
  20% {transform: scaleX(1);}
  80% {transform: scaleX(1);}
  100% {transform: scaleX(1.2) scaleY(0.9);}
`;

const PigContainer = styled.div`
  transform-origin: center bottom;
  position: absolute;
  width: 14vw;
  height: 14vw;
  display: flex;
  justify-content: center;
  /* animation-name: ${piganims}; */
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-direction: normal;
  animation-iteration-count: infinite;
  z-index: 1;
`;

const DepoPig = styled.div`
  background-image: url(../../${config.theme}/newasset/pig.png);
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  width: 14vw;
  height: 14vw;
  position: absolute;
  z-index: 1;
`;

const DepoPigmask = styled.div`
  background-image: url(../../${config.theme}/newasset/pig-mask.png);
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  width: 14vw;
  height: 14vw;
  position: absolute;
  z-index: 3;
`;

const coinfly = keyframes`
  0% {top: 0.5vw;}
  15% {top: -1vw;}
  40% {top: -2.3vw; transform: rotateX(0deg) rotateZ(0deg);}
  50% {top: -2vw; transform: rotateX(180deg) rotateZ(180deg);}
  60% {top: -2.3vw; transform: rotateX(0deg) rotateZ(0deg);}
  85% {top: -1vw;}
  100% {top: 0.5vw;}
`;

const DepoCoinFly = styled.div`
  background-image: url(../../${config.theme}/newasset/coin.png);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 3.4vw;
  height: 3.4vw;
  z-index: 2;
  position: absolute;
  /* animation-name: ${coinfly}; */
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
`;

const coinprop = keyframes`
  0% {top: -0.5vw;}
  100% {top: 0.5vw;}
`;

const PigCoinProp = styled.div`
  background-image: url(../../${config.theme}/newasset/pigcoin_prop.png);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 22vw;
  height: 16vw;
  left: -3.5vw;
  z-index: 0;
  position: absolute;
  /* animation-name: ${coinprop}; */
  animation-duration: 0.7s;
  // animation-timing-function: cubic-bezier(.73,-0.33,.39,1.29);
  animation-timing-function: linear;
  animation-direction: alternate-reverse;
  animation-iteration-count: infinite;
`;

const WinkContainer = styled.div`
  width: fit-content;
  height: fit-content;
  position: absolute;
  margin: 0 auto;
  left: 33vw;
  mix-blend-mode: overlay;
  //Portrait
  @media (orientation: portrait) {
    transform: scale(2);
    left: 20vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    transform: scale(1.4);
    left: 27vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          transform: scale(2);
          left: 20vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          transform: scale(1.4);
          left: 27vw;
        `} */
`;

const wink = keyframes`
    0% {transform: scale(0) rotate(0deg);opacity: 1;}
    50% {transform: scale(1) rotate(45deg); opacity: 1;}
    100% {transform: scale(2) rotate(90deg); opacity: 0;}
`;

const winkimg = css`
  background-image: url(../../${config.theme}/newasset/d_wink.png);
  background-size: contain;
  background-position: center center;
  width: 8vw;
  height: 8vw;
  position: absolute;
  z-index: 5;
  mix-blend-mode: screen;
  animation-direction: normal;
  animation-iteration-count: infinite;
  /* animation-name: ${wink}; */
  animation-timing-function: normal;
  transform-origin: center center;
  pointer-events: none;
`;

const Wink1 = styled.div`
  ${winkimg}
  animation-duration: 1s;
  top: -1vw;
  left: 3vw;
`;

const Wink2 = styled.div`
  ${winkimg}
  animation-duration: 1.2s;
  top: 2vw;
  left: 20vw;
`;

const Wink3 = styled.div`
  ${winkimg}
  animation-duration: 1.9s;
  top: -1.3vw;
  left: 25vw;
`;

const Wink4 = styled.div`
  ${winkimg}
  animation-duration: 1.5s;
  top: 2.9vw;
  left: 9vw;
`;

const Wink5 = styled.div`
  ${winkimg}
  animation-duration: 1.2s;
  top: -3.5vw;
  left: 8vw;
`;

const Wink6 = styled.div`
  ${winkimg}
  animation-duration: 1s;
  top: -2.7vw;
  left: 20.7vw;
`;

const Wink7 = styled.div`
  ${winkimg}
  animation-duration: 0.9s;
  top: 1vw;
  left: 1vw;
`;

const ProfileDeposit = styled.div`
  width: 100%;
  height: 15.27vw;
  /* background-color: red; */
  background-image: url(../../${config.theme}/newasset/img_top_nav_secodary.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  //Portrait
  @media only screen and (max-width: 480px) and (orientation: portrait) {
    height: 20.36vw;
  }
`;

const ProfileBox = styled.div`
  width: 50%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  background-image: ${(props) =>
    props.isTelGuest
      ? `url(../${config.theme}/newasset/img_panel_yellow.png)`
      : `url(../${config.theme}/newasset/img_panel_brown.png)`};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: ${(props) => (props.isTelGuest ? "pointer" : "auto")};
`;

const DepositBox = styled.div`
  width: 50%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  background-image: url(../${config.theme}/newasset/img_panel_green.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
`;

const DepositText = styled.div`
  width: 58%;
  text-align: center;
  font-size: 2.75vw;
  color: #fff;
  position: absolute;
  left: 32%;
  line-height: 100%;
`;

const RegisterProp1 = styled.div`
  width: 35%;
  height: 100%;
  background-image: url(../${config.theme}/newasset/ico_register.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  z-index: 1;
  animation: ${RegisterAnimation} 0.75s linear infinite;
`;

const RegisterProp2 = styled.div`
  width: 35%;
  height: 100%;
  background-image: url(../${config.theme}/newasset/img_light_particle.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  animation: ${RegisterAnimation2} 4s linear infinite;
`;

const RedeemBox = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 20vw;
  height: 4vw;
  background: linear-gradient(180deg, #fffe37 0%, #dc7a13 100%);
  border: 0.8px solid #ffc700;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5), inset 0px 0px 3px #ffff00;
  border-radius: 2vw;
  top: 8%;
  right: 5%;
  z-index: 5;
  font-size: 2vw;
  font-weight: 600;
  color: #230000;
  animation: ${RegisterAnimation} 0.75s linear infinite;
`;

const RedeemBoxIcon = styled.div`
  width: 17%;
  height: 85%;
  background-image: url(../../${config.theme}/icon_gift_box.png);
  background-size: contain;
`;

export default function NavbarAnim(props) {
  // const [userProfile, setUserProfile] = useState(false);
  // const [currency, setCurrency] = useState(false);
  // const [popup, setPopup] = useState(false);
  // const [deposit, setDeposit] = useState(false);
  // const [guestDetect, setGuestDetect] = useState(false);
  // const [login, setLogin] = useState(false);
  const profile = useSelector(selectuserProfile);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  let guestuser = useSelector(selectuserProfile);
  const popupStatus = useSelector(selectPopup);

  // const [items, setItems] = useState({
  //   date: "",
  //   list: [],
  //   monthly_jackpot: 0,
  //   online: 0,
  //   total_jackpot: 0,
  // });

  return (
    <>
      <HeaderAnims {...props}>
        <HeaderFrame>
          <WinkContainer>
            <Wink1 />
            <Wink2 />
            <Wink3 />
            <Wink4 />
            <Wink5 />
            <Wink6 />
            <Wink7 />
          </WinkContainer>
          <Nav>
            {/* <HeaderBG /> */}
            <Bar>
              <LeftItem>
                {/* <LogoSpinix>
                <img src="../"+config.theme+"/spinix_normal_Logo.png" style={{ width: "12vw", height: "3vw", position: "absolute" }} />
              </LogoSpinix> */}
                {window.appHeight() > window.innerWidth ? (
                  ""
                ) : (
                  <Logo
                    brand={profile.username}
                    style={{ fontSize: "1.5vw" }}
                    level={profile.level}
                  />
                )}

                <Currency />
              </LeftItem>

              <JackpotCount />

              {window.appHeight() > window.innerWidth ? "" : <DepoWithdra />}
              <Popup />
              {/* <DepositAnims onClick={() => {
                if (guestuser.isTelGuest === true && guestuser.isguest == true) {
                  dispatch(actionClickSound());
                  dispatch(actionPopup({ registBankAccount: true }));
                } else if (guestuser.isTelGuest === false &&
                  guestuser.isguest == true) {
                  dispatch(actionClickSound());
                  setGuestDetect(true);
                }
                else {
                  try {
                    socket.emit(
                      "getbilog",
                      {
                        biname: "deposit_open",
                        action_from: "Deposit",
                      },
                      (data) => {
                        console.log(data);
                      }
                    );
                  } catch (error) {
                    console.log(error);
                  }
                  dispatch(actionClickSound());
                  setDeposit(true);
                }
              }}>
                <DepoBase>
                  {profile.promotion_status ? <TidBubble /> : ""}

                  <Detail>
                    <DetailList>{t("deposit")}</DetailList>
                    <Bardeposit />
                    <DetailList>{t("withdrawal")}</DetailList>
                  </Detail>
                  <PigContainer>
                    <DepoPigmask />
                    <DepoCoinFly />
                    <DepoPig />
                  </PigContainer>
                  <PigCoinProp />
                </DepoBase>
              </DepositAnims> */}
              {/* {profile.promotion_status ? (
                <TidPro>

                  <TidBadge>
                    <p>{t("tidpro")}</p>
                  </TidBadge>
                </TidPro>
              ) : (
                ""
              )} */}
            </Bar>
          </Nav>
        </HeaderFrame>
      </HeaderAnims>
      {window.appHeight() > window.innerWidth ? (
        <ProfileDeposit>
          <ProfileBox
            onClick={() => {
              if (
                guestuser.isguest === true &&
                guestuser.isTelGuest === false
              ) {
                dispatch(actionPopup({ login: true }));
              } else {
                try {
                  socket.emit(
                    "getbilog",
                    {
                      biname: "profile_open",
                    },
                    (data) => {
                      console.log(data);
                    }
                  );
                } catch (error) {
                  console.log(error);
                }
                // setUserProfile(true);
                dispatch(actionPopup({ avatarLogo: true }));
                dispatch(actionClickSound());
              }
            }}
            isTelGuest={
              guestuser.isguest === true && guestuser.isTelGuest === false
            }
          >
            {guestuser.isguest === true && guestuser.isTelGuest === false ? (
              <>
                <RegisterProp1 />
                <RegisterProp2 />
                <DepositText style={{ left: "30%" }}>
                  {t("guest register") + " / " + t("login")}
                </DepositText>
              </>
            ) : (
              <Logo
                brand={profile.username}
                style={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
                level={profile.level}
              />
            )}
          </ProfileBox>
          <DepositBox
            onClick={() => {
              if (guestuser.isTelGuest === true && guestuser.isguest === true) {
                dispatch(actionClickSound());
                // dispatch(actionPopup({ registBankAccount: true }));
                redirect2Page("createbankaccount");
              } else if (
                guestuser.isTelGuest === false &&
                guestuser.isguest === true
              ) {
                dispatch(actionClickSound());
                dispatch(actionPopup({ guestDetect: true }));
              } else {
                try {
                  socket.emit(
                    "getbilog",
                    {
                      biname: "deposit_open",
                      action_from: "Deposit",
                    },
                    (data) => {
                      console.log(data);
                    }
                  );
                } catch (error) {
                  console.log(error);
                }
                dispatch(actionClickSound());
                dispatch(actionPopup({ deposit: true }));
                // setDeposit(true);
              }
            }}
          >
            {profile?.proRedeem === 1 ? (
              <RedeemBox>
                <RedeemBoxIcon />
                {t("Redeem Code")}
              </RedeemBox>
            ) : (
              ""
            )}
            <PigContainer>
              <DepoPigmask />
              <DepoCoinFly />
              <DepoPig />
            </PigContainer>
            <PigCoinProp />
            <DepositText>{t("deposit") + " / " + t("withdrawal")}</DepositText>
          </DepositBox>
        </ProfileDeposit>
      ) : (
        ""
      )}

      <Transition showPop={popupStatus.registBankAccount} type="st2b">
        <BankAccount
          closeClick={() => {
            dispatch(actionPopup({ registBankAccount: false }));
          }}
        />
      </Transition>
      {/* <Transition showPop={userProfile} type="st2b">
        <UserProfile
          closeClick={() => {
            setUserProfile(false);
          }}
        />
      </Transition> */}

      {/* <Transition showPop={deposit} type="st2b">
        <DepositWithdrawal
          closeClick={() => {
            setDeposit(false);
            dispatch(actionPopup({ deposit: true }));
          }}
        />
      </Transition> */}

      {/* <Transition showPop={guestDetect || popupStatus.guestDetect} type="st2b">
        <GuestDetect
          closeClick={() => {
            setGuestDetect(false);
            dispatch(actionPopup({ guestDetect: false }));
          }}
        />
      </Transition> */}

      {/* <Transition showPop={login || popupStatus.register} type="st2b">
        <Login
          closeClick={() => {
            setLogin(false)
          }} />
      </Transition> */}

      {/* <Transition showPop={currency} type="st2b">
        <CurrencyPop
          closeClick={() => {
            setCurrency(false);
          }}
        />
      </Transition>
    
      <Transition showPop={popup} type="st2b">
        <CurrencyPop
          closeClick={() => {
            setPopup(false);
          }}
        />
      </Transition> */}
    </>
  );
}
