import React, { useState, useEffect } from "react";
import styled, { css, keyframes } from "styled-components";
import { useTranslation } from "react-i18next";
import RewardResult from "./RewardResult";
import { useDispatch } from "react-redux";
import {
  actionClickSound,
  actionGetRewardSound,
} from "../../Reducers/userProfileSlice";
import { Transition } from "../../config";
import AlertMessage from "../Dialog/AlertMessage";
import breakpoints from "../../LandingPageComponents/breakpoints";
import { config } from "../../config";

const popup = keyframes`
     from {box-shadow: inset 0px 0px 0.3vw 0.1vw #FF691F, inset 0px 0px 2vw rgba(255, 133, 66, 1);}
     to {box-shadow: inset 0px 0px 0.3vw 0.3vw #FFC700, inset 0px 0px 2vw rgba(255, 133, 66, 1);}
    /* from {box-shadow: inset 0px 0px 0.8vw 0.2vw #5DD8FF, inset 0px 0px 4vw rgba(66, 255, 232, 1);}
    to {box-shadow: inset 0px 0px 0.3vw 0.3vw #5DD8FF, inset 0px 0px 2vw rgba(66, 255, 232, 1);} */
`;
const Popup = styled.div`
  // box-shadow: 0 0 3vw 3vw rgba(255,105,31,1)!important;
  animation-name: ${popup};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  user-select: none;
  margin: 0 auto;
  background: #302e3c;
  box-shadow: inset 0px 0px 0.3vw 0.1vw #ff691f,
    inset 0px 0px 2vw rgba(255, 133, 66, 0.63);
  // theme ==================
  /* background: #192756; */
  /* box-shadow: inset 0vw 0vw 0.8vw 0.2vw #5DD8FF, inset 0px 0px 4vw rgba(66, 255, 232, 0.63); */
  border: solid 0.2vw orange;
  // theme ==================
  border-radius: 1.3vw;
  // background-image:url('../${config.theme}/popup_bg.png');
  // background-size:100% 100%;
  // background-repeat:no-repeat;
  // background-position: center;
  min-width: 34.8306vw;
  width: max-content;
  min-height: 19.887vw;
  height: max-content;
  display: flex;
  z-index: 60;
  flex-direction: column;
  align-items: center;
  h2 {
    color: white;
    font-weight: 400;
    font-size: ${(props) => (props.fontSize ? props.fontSize : "2vw")};
    padding-top: 0vw;
    margin: auto;
    margin-top: 4vw;
    margin-bottom: -3vw;
    /* -moz-margin-start: 11vw; */
    white-space: pre;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          min-width: 60.8306vw;
          min-height: 45.887vw;
          h2 {
            font-size: 3.5vw;
            margin-top: 8vw;
          }
        `
      : window.appHeight() > window.innerWidth &&
        css`
          min-width: 44.8306vw;
          min-height: 30.887vw;
          h2 {
            margin-top: 5vw;
            font-size: 2.5vw;
          }
        `}
`;

const Submit = styled.div`
  user-select: none;
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 3vw;
  z-index: 70;
  h4 {
    color: white;
    font-size: 1.2vw;
    z-index: 70;
    margin-top: -0.4vw;
    font-weight: 400;
    position: absolute;
    width: 10vw;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          top: 14vw;
          margin-left: -2vw;
          h4 {
            font-size: 2vw;
            margin-top: 0.2vw;
            font-weight: 400;
            position: absolute;
            width: 10vw;
          }
        `
      : window.appHeight() > window.innerWidth &&
        css`
          top: 5vw;
          margin-left: -2vw;
          h4 {
            font-size: 2vw;
            margin-top: -0.6vw;
            font-weight: 400;
            position: absolute;
            width: 10vw;
          }
        `}
`;

const Box = styled.div`
  user-select: none;
  position: fixed;
  width: 100vw;
  height: 100%; //fix
  top: 0%;
  left: 0%;
  text-align: -webkit-center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 60;
  display: flex;
  align-items: center;
  @media ${breakpoints.device.line} {
    left: -42vw;
    width: 245%;
    height: 140%;
    top: -7vw;
  }
`;

const Frame = styled.div`
  user-select: none;
  position: relative;
  top: -2.5vw;
  text-align: -moz-center;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          transform: scale(1.8);
        `
      : window.appHeight() > window.innerWidth &&
        css`
          transform: scale(1.4);
        `}
`;

const rwdimage = css`
  background: url("../${config.theme}/rewardanimation/pop-ani-diamond.png")
    no-repeat;
  // background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  position: absolute;
`;
const rwdanimprop = css`
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
`;
const winkanimprop = css`
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-timing-function: linear;
`;
const RewardAnimation = styled.div`
  color: white;
  // background: red;
  width: 40vw;
  height: 19vw;
  position: absolute;
  z-index: 61;
  left: 50%;
  // top: 50%;
  transform: translate(-50%);
  margin-top: -3vw;
  animation-iteration-count: infinite;
  animation-direction: alternate;
`;
const centertext = keyframes`
    0% { top: 0.4vw; transform: scale(1); }
    50% { top: -0.2vw; transform: scale(1.05); }
    100% { top: 0vw; transform: scale(1); }
`;
const CenterText = styled.div`
  background-image: url("../${config.theme}/rewardanimation/pop-ani-text.png");
  background-size: 29vw;
  background-repeat: no-repeat;
  background-position: center top;
  width: 100%;
  height: 9.5vw;
  margin-top: -0.5vw;
  z-index: 69;
  position: absolute;
  // background: blue;
  animation-name: ${centertext};
  animation-duration: 1s;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? `
  height: 15.5vw;
  background-size: 47vw;
    width: 48vw;
    margin-top: -2.5vw;
    margin-left: -4vw;
  `
      : window.appHeight() > window.innerWidth &&
        css`
          background-size: 35vw;
          margin-top: -1.5vw;
        `}
  ${rwdanimprop}
`;
const RwDiamondLeft = styled.div`
  width: 100%;
  height: 2vw;
  position: absolute;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin-left: -2vw;
          transform: scale(1.5);
        `
      : window.appHeight() > window.innerWidth &&
        css`
          transform: scale(1.2);
        `}
`;
const RwDiamondRight = styled.div`
  width: 100%;
  height: 2vw;
  position: absolute;
  transform: scaleX(-1);
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin-left: 11vw;
          transform: scale(1.5) scaleX(-1);
          margin-left: 3vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          transform: scale(1.2) scaleX(-1);
          margin-left: 1vw;
        `}
`;
const rwdiamond1 = keyframes`
    from { transform: rotate(20deg) scale(1); top: 0.8vw;}
    to { transform: rotate(-10deg) scale(0.95); top: 1.3vw;}
`;
const RwDiamond1 = styled.div`
  ${rwdimage}
  ${rwdanimprop}
    width: 4vw;
  height: 4vw;
  top: 0.8vw;
  left: 1vw;
  z-index: 62;
  transform: rotate(20deg);
  animation-name: ${rwdiamond1};
  animation-duration: 2s;
`;
const rwdiamond2 = keyframes`
    from { transform: rotate(-20deg) scale(1); top: -0.6vw;}
    to { transform: rotate(10deg) scale(0.95); top: -1.3vw;}
`;
const RwDiamond2 = styled.div`
  ${rwdimage}
  ${rwdanimprop}
    width: 5.5vw;
  height: 5.5vw;
  top: -0.6vw;
  left: 4vw;
  z-index: 62;
  transform: rotate(-20deg);
  animation-name: ${rwdiamond2};
  animation-duration: 1.5s;
`;
const rwdiamond3 = keyframes`
    from { transform: rotate(10deg) scale(1); top: 2.5vw;}
    to { transform: rotate(-10deg) scale(1.2); top: 2vw;}
`;
const RwDiamond3 = styled.div`
  ${rwdimage}
  ${rwdanimprop}
    width: 7vw;
  height: 7vw;
  top: 2.5vw;
  left: 1.6vw;
  z-index: 65;
  transform: rotate(10deg);
  animation-name: ${rwdiamond3};
  animation-duration: 2s;
`;
const rwdiamond4 = keyframes`
    from { transform: rotate(-25deg) scale(1); top: 6vw;}
    to { transform: rotate(20deg) scale(1.2); top: 5.7vw;}
`;
const RwDiamond4 = styled.div`
  ${rwdimage}
  ${rwdanimprop}
    width: 3vw;
  height: 3vw;
  top: 6vw;
  left: 0.6vw;
  z-index: 65;
  transform: rotate(-25deg);
  animation-name: ${rwdiamond4};
  animation-duration: 2s;
`;
const winkimg = css`
  background: url("../${config.theme}/rewardanimation/pop-ani-wink.png")
    no-repeat;
  // background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  position: absolute;
  z-index: 70;
  mix-blend-mode: overlay;
`;
const WinkContainer = styled.div`
  width: 100%;
  height: 2vw;
  position: absolute;
  top: -0.2vw;
  // background: red;
`;
const wink1 = keyframes`
    0% { transform: scale(0); opacity: 1; }
    50% { transform: scale(1); opacity: 1;}
    100% { transform: scale(1.5); opacity: 0;}
`;
const Wink1 = styled.div`
  ${winkimg}
  width: 1.5vw;
  height: 2.5vw;
  top: -0.5vw;
  left: 10vw;
  ${winkanimprop}
  animation-name: ${wink1};
  animation-duration: 0.9s;
`;
const wink2 = keyframes`
    0% { transform: scale(0); opacity: 1; }
    50% { transform: scale(1); opacity: 1;}
    100% { transform: scale(1.5); opacity: 0;}
`;
const Wink2 = styled.div`
  ${winkimg}
  width: 1vw;
  height: 2vw;
  top: 2vw;
  right: 8vw;
  ${winkanimprop}
  animation-name: ${wink2};
  animation-duration: 1.3s;
`;
const wink3 = keyframes`
    0% { transform: scale(0); opacity: 1; }
    50% { transform: scale(1); opacity: 1;}
    100% { transform: scale(1.5); opacity: 0;}
`;
const Wink3 = styled.div`
  ${winkimg}
  width: 1vw;
  height: 2vw;
  top: 5.5vw;
  left: 7vw;
  ${winkanimprop}
  animation-name: ${wink3};
  animation-duration: 1.9s;
`;
const wink4 = keyframes`
    0% { transform: scale(0); opacity: 1; }
    50% { transform: scale(1); opacity: 1;}
    100% { transform: scale(1.5); opacity: 0;}
`;
const Wink4 = styled.div`
  ${winkimg}
  width: 1.5vw;
  height: 2.5vw;
  top: 0vw;
  right: 17vw;
  ${winkanimprop}
  animation-name: ${wink4};
  animation-duration: 2s;
`;
const wink5 = keyframes`
    0% { transform: scale(0); opacity: 1; }
    50% { transform: scale(1); opacity: 1;}
    100% { transform: scale(1.5); opacity: 0;}
`;
const Wink5 = styled.div`
  ${winkimg}
  width: 1vw;
  height: 2vw;
  top: 3vw;
  right: 12vw;
  ${winkanimprop}
  animation-name: ${wink5};
  animation-duration: 0.7s;
`;
const wink6 = keyframes`
    0% { transform: scale(0); opacity: 1; }
    50% { transform: scale(1); opacity: 1;}
    100% { transform: scale(1.5); opacity: 0;}
`;
const Wink6 = styled.div`
  ${winkimg}
  width: 1vw;
  height: 2vw;
  top: 2vw;
  left: 18vw;
  ${winkanimprop}
  animation-name: ${wink6};
  animation-duration: 1.5s;
`;
const wink7 = keyframes`
    0% { transform: scale(0); opacity: 1; }
    50% { transform: scale(1); opacity: 1;}
    100% { transform: scale(1.5); opacity: 0;}
`;
const Wink7 = styled.div`
  ${winkimg}
  width: 1.5vw;
  height: 2.5vw;
  top: 4vw;
  left: 12.5vw;
  ${winkanimprop}
  animation-name: ${wink7};
  animation-duration: 1s;
`;
const FxContainer = styled.div`
  width: 34.5vw;
  height: 21.8vw;
  left: 50%;
  overflow: hidden;
  border-radius: 1vw;
  transform: translate(-50%);
  // z-index: 21!important;
  margin-top: -2vw;
  position: absolute;
  // background: red;
  mix-blend-mode: overlay;
  opacity: 1;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin-top: 10vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          margin-top: 4vw;
        `}
`;
const itemglow = keyframes`
    0% { opacity: 0; transform: scale(0);}
    50% { opacity: 1; transform: scale(1);}
    80% { opacity: 0; transform: scale(1.5);}
    100% { opacity: 0; transform: scale(1.9);}
`;
const ItemGlow = styled.div`
  width: 18vw;
  height: 18vw;
  border-radius: 100vw;
  position: absolute;
  background: rgb(0, 212, 255);
  // background: radial-gradient(circle, rgba(0,212,255,1) 0%, rgba(0,189,255,0) 70%);
  // background: radial-gradient(circle, rgba(255,218,0,1) 0%, rgba(255,76,0,0) 70%);
  background: radial-gradient(
    circle,
    rgba(255, 196, 0, 1) 0%,
    rgba(255, 76, 0, 0) 70%
  );
  // background: radial-gradient(circle, rgba(255,76,0,0) 30%, rgba(255,218,0,1) 70%);
  top: 3.5vw;
  left: 8vw;
  z-index: 19;
  mix-blend-mode: overlay;
  animation-name: ${itemglow};
  animation-duration: 2.6s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-timing-function: linear;
`;
const ItemGlow2 = styled.div`
  width: 18vw;
  height: 18vw;
  border-radius: 100vw;
  position: absolute;
  background: rgb(0, 212, 255);
  background: radial-gradient(
    circle,
    rgba(0, 212, 255, 1) 0%,
    rgba(0, 189, 255, 0) 70%
  );
  // background: radial-gradient(circle, rgba(255,239,0,1) 0%, rgba(255,76,0,0) 70%);
  top: 3.5vw;
  left: 8vw;
  z-index: 21;
  mix-blend-mode: overlay;
  animation-name: ${itemglow};
  animation-duration: 2.1s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-timing-function: linear;
`;
const ItemGlow3 = styled.div`
  width: 18vw;
  height: 18vw;
  border-radius: 100vw;
  position: absolute;
  background: rgb(0, 212, 255);
  // background: radial-gradient(circle, rgba(0,212,255,1) 0%, rgba(0,189,255,0) 70%);
  background: radial-gradient(
    circle,
    rgba(255, 196, 0, 1) 0%,
    rgba(255, 76, 0, 0) 70%
  );
  top: 3.5vw;
  left: 8vw;
  z-index: 21;
  mix-blend-mode: overlay;
  animation-name: ${itemglow};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-timing-function: linear;
`;
const WinkitemContainer = styled.div`
  width: fit-content;
  height: fit-content;
  position: absolute;
  margin: auto;
  background: red;
  left: 8vw;
  z-index: 70;
`;
const winkitem1 = keyframes`
    0% {  opacity: 1; top: 10.5vw; transform: scale(0) rotate(0deg);}
    50% { opacity: 1; top: 9.5vw; transform: scale(1) rotate(90deg);}
    100% { opacity: 0; top: 8.5vw; transform: scale(1) rotate(180deg);}
`;
const WinkItem1 = styled.div`
  ${winkimg}
  width: 1vw;
  height: 2vw;
  top: 10vw;
  left: 10.5vw;
  ${winkanimprop}
  animation-name: ${winkitem1};
  animation-duration: 1s;
`;
const winkitem2 = keyframes`
    0% {  opacity: 1; top: calc(11vw - 0vw); transform: scale(0) rotate(0deg);}
    50% { opacity: 1; top: calc(11vw - 1vw); transform: scale(1) rotate(90deg);}
    100% { opacity: 0; top: calc(11vw - 2vw); transform: scale(1) rotate(180deg);}
`;
const WinkItem2 = styled.div`
  ${winkimg}
  width: 1vw;
  height: 2vw;
  top: 10vw;
  left: 6.5vw;
  ${winkanimprop}
  animation-name: ${winkitem2};
  animation-duration: 1.5s;
`;
const winkitem3 = keyframes`
    0% {  opacity: 1; top: calc(13vw - 0vw); transform: scale(0) rotate(0deg);}
    50% { opacity: 1; top: calc(13vw - 1vw); transform: scale(1) rotate(90deg);}
    100% { opacity: 0; top: calc(13vw - 2vw); transform: scale(1) rotate(180deg);}
`;
const WinkItem3 = styled.div`
  ${winkimg}
  width: 1vw;
  height: 2vw;
  left: 3.5vw;
  ${winkanimprop}
  animation-name: ${winkitem3};
  animation-duration: 1.4s;
`;
const winkitem4 = keyframes`
    0% {  opacity: 1; top: calc(13vw - 0vw); transform: scale(0) rotate(0deg);}
    50% { opacity: 1; top: calc(13vw - 1vw); transform: scale(1) rotate(90deg);}
    100% { opacity: 0; top: calc(13vw - 2vw); transform: scale(1) rotate(180deg);}
`;
const WinkItem4 = styled.div`
  ${winkimg}
  width: 1vw;
  height: 2vw;
  left: 13.5vw;
  ${winkanimprop}
  animation-name: ${winkitem4};
  animation-duration: 1.2s;
`;
const winkitem5 = keyframes`
    0% {  opacity: 1; top: calc(9.2vw - 0vw); transform: scale(0) rotate(0deg);}
    50% { opacity: 1; top: calc(9.2vw - 1vw); transform: scale(1) rotate(90deg);}
    100% { opacity: 0; top: calc(9.2vw - 2vw); transform: scale(1) rotate(180deg);}
`;
const WinkItem5 = styled.div`
  ${winkimg}
  width: 1vw;
  height: 2vw;
  left: 5vw;
  ${winkanimprop}
  animation-name: ${winkitem5};
  animation-duration: 1.2s;
`;
const winkitem6 = keyframes`
    0% {  opacity: 1; top: calc(9.2vw - 0vw); transform: scale(0) rotate(0deg);}
    50% { opacity: 1; top: calc(9.2vw - 1vw); transform: scale(1) rotate(90deg);}
    100% { opacity: 0; top: calc(9.2vw - 2vw); transform: scale(1) rotate(180deg);}
`;
const WinkItem6 = styled.div`
  ${winkimg}
  width: 1vw;
  height: 2vw;
  left: 12.3vw;
  ${winkanimprop}
  animation-name: ${winkitem6};
  animation-duration: 1.34s;
`;
const winkitem7 = keyframes`
    0% {  opacity: 1; top: calc(9.2vw - 0vw); transform: scale(0) rotate(0deg);}
    50% { opacity: 1; top: calc(9.2vw - 1vw); transform: scale(1) rotate(90deg);}
    100% { opacity: 0; top: calc(9.2vw - 2vw); transform: scale(1) rotate(180deg);}
`;
const WinkItem7 = styled.div`
  ${winkimg}
  width: 1vw;
  height: 2vw;
  left: 9.3vw;
  ${winkanimprop}
  animation-name: ${winkitem7};
  animation-duration: 1.34s;
`;

function SpinWheelResult(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [emitError, setEmitError] = useState({ status: false, message: "" });
  // let amount = "../"+config.theme+"/" + props.amount
  const data = {
    ...props,
    itemType: props.itemType === "c3" ? "T" : props.itemType,
  };

  useEffect(() => {
    try {
      dispatch(actionGetRewardSound());
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  }, []);

  return (
    <Box>
      <Popup
        style={{ marginTop: props.marginTopPopup }}
        fontSize={props.fontSize}
      >
        <RewardAnimation>
          <WinkContainer>
            <Wink1 />
            <Wink2 />
            <Wink3 />
            <Wink4 />
            <Wink5 />
            <Wink6 />
            <Wink7 />
          </WinkContainer>
          <CenterText />
          <RwDiamondLeft>
            <RwDiamond1></RwDiamond1>
            <RwDiamond2></RwDiamond2>
            <RwDiamond3></RwDiamond3>
            <RwDiamond4></RwDiamond4>
          </RwDiamondLeft>
          <RwDiamondRight>
            <RwDiamond1></RwDiamond1>
            <RwDiamond2></RwDiamond2>
            <RwDiamond3></RwDiamond3>
            <RwDiamond4></RwDiamond4>
          </RwDiamondRight>
        </RewardAnimation>
        <h2>{t(props.label)}</h2>
        <div style={{ display: "flex" }}>
          {Array.isArray(props.amount) ? (
            props.amount.map((item, i) => {
              return (
                <Frame key={"Item_" + i}>
                  <RewardResult
                    key={"Item_" + i}
                    amount={item.amount}
                    itemType={
                      item.currency === "c2"
                        ? "S"
                        : item.currency === "c3"
                        ? "T"
                        : ""
                    }
                    style={{
                      top:
                        item.currency === "c2" || item.currency === "c3"
                          ? "6.2%"
                          : "",
                    }}
                  />
                </Frame>
              );
            })
          ) : (
            <Frame>
              <RewardResult {...data} />
            </Frame>
          )}
        </div>
        <Submit
          onClick={() => {
            dispatch(actionClickSound());
            props.closeClick();
          }}
          style={{ cursor: "pointer" }}
        >
          <img
            src={"../" + config.theme + "/btn_green.png"}
            style={{
              position: "absolute",
              width:
                window.appHeight() / window.innerWidth >
                config.verticalSizeiphone
                  ? "21vw"
                  : window.appHeight() > window.innerWidth
                  ? "16vw"
                  : window.innerWidth / window.appHeight() > 2.15
                  ? "13vw"
                  : "13vw",
              marginTop: "-1.6vw",
            }}
          />
          <h4>{t("got it!")}</h4>
          {/* <Button width="11vw" label={t("got it!")} top="1vw" style={{ cursor: 'pointer' }} onClick={props.closeClick} disab /> */}
        </Submit>
        <FxContainer>
          <WinkitemContainer>
            <WinkItem1 />
            <WinkItem2 />
            <WinkItem3 />
            <WinkItem4 />
            <WinkItem5 />
            <WinkItem6 />
            <WinkItem7 />
          </WinkitemContainer>
          <ItemGlow />
          <ItemGlow2 />
          <ItemGlow3 />
        </FxContainer>
      </Popup>
      <Transition showPop={emitError.status} type="alert">
        <AlertMessage
          message={t(emitError.message)}
          onClick={() => {
            setEmitError({ status: false });
            // setDisWheel(true);
          }}
        />
      </Transition>
    </Box>
  );
}

export default SpinWheelResult;
