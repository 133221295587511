import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectDimensionsLP } from "../../Reducers/dimensionsLP";
import { selectHomeContentLP } from "../../Reducers/homeContentLPSlice";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import "./animation.css";
import Button from "../Button";
import breakpoint from "../breakpoints";
import { selectCounterLP } from "../../Reducers/counterLPSlice";
import CountUp from "react-countup";

const FeatureGameContainer = styled.div`
  /* width: 65%; */
  color: #fff;
  text-align: end;
  position: relative;
  margin-left: -2vw;
  h1,
  h2,
  h4,
  p {
    margin-block-start: 0;
    margin-block-end: 0;
    font-weight: 400;
    line-height: 1;
  }
  @media ${breakpoint.device.mobile} {
    width: 100%;
    margin-bottom: 3vw;
  }
`;

const GameBox = styled.div`
  width: 30vw;
  display: grid;
  grid-template-areas:
    "main main main main"
    "sub1 sub2 sub3 sub4";
  grid-gap: 0.5vw;
  @media ${breakpoint.device.mobile} {
    width: 100%;
    grid-gap: 3vw;
  }
`;

const MainGame = styled.div`
  grid-area: main;
  display: flex;
  justify-content: end;
  align-items: end;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 59.37%,
      rgba(0, 0, 0, 0.85) 99.48%
    ),
    url(${(props) => props.img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 12.8vw;
  padding: 0.5vw;
  border-radius: 1vw;
  @media ${breakpoint.device.mobile} {
    height: 44.56vw;
    padding: 2vw;
    border-radius: 3vw;
    margin-top: 10vw;
  }
`;

const MainText = styled.div`
  margin-right: 0.5vw;
  p {
    font-size: 0.94vw;
    margin-bottom: 0.5vw;
  }
  @media ${breakpoint.device.mobile} {
    margin-right: 2vw;
    p {
      font-size: 4vw;
      margin-bottom: 1vw;
    }
  }
`;

const SubGame = styled.div`
  grid-area: ${(props) => props.className};
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0) 27.6%
    ),
    url(${(props) => props.img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  width: 6.5vw;
  height: 8.93vw;
  border-radius: 1vw;
  padding: 0.5vw;
  position: relative;
  text-align: center;
  > p {
    font-size: 0.67vw;
  }
  @media ${breakpoint.device.mobile} {
    width: 95%;
    height: 30.44vw;
    border-radius: 3vw;
    padding:1vw 1vw 0vw 0vw ;
    > p {
      font-size: 2vw;
      line-height: 1.5;
    }
  }
`;

const PlayDemo = styled.div`
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > img {
    width: 3vw;
    margin: 1vw;
  }
  > p {
    font-size: 1vw;
    color: #fff;
  }
  @media ${breakpoint.device.mobile} {
    > img {
      width: 10vw;
    }
    > p {
      font-size: 3.38vw;
      color: #fff;
      line-height: 1.5;
    }
  }
  :hover {
    opacity: 1;
    transition: 0.5s ease;
  }
`;

function FeatureGame() {
  const { t } = useTranslation();
  const dimensionsLP = useSelector(selectDimensionsLP);
  const homeContent = useSelector(selectHomeContentLP);
  const [items, setItems] = useState([]);
  const counter = useSelector(selectCounterLP);
  const [counterStart, setCounterStart] = useState("");

  // console.log("game", counter.game);
  // console.log("items", items);

  useEffect(() => {
    if (homeContent.length !== 0) {
      setItems(
        homeContent.find((i) => i.location === "game total win").contents
      );
    }
    return () => {
      setCounterStart("");
    };
  }, [homeContent]);

  setTimeout(() => {
    // setCounterStart(counter.totalWin);
    if (items[0]) {
      setCounterStart(counter.game["Wetn Wild"]);
    } else if (items[1]) {
      setCounterStart(counter.game["Caishen Wins"]);
    } else if (items[2]) {
      setCounterStart(counter.game["Treasures of Aztec"]);
    } else if (items[3]) {
      setCounterStart(counter.game["Greedy Mine"]);
    } else if (items[4]) {
      setCounterStart(counter.game["Dragon Fury"]);
    }
  }, 5000);

  const redirectPage = (gameName) => {
    if (gameName === "Greedy Mine") {
      window.location =
        "https://play.x-gaming.com/game/player/demo/?url=https://play.x-gaming.com/game/crash/greedymine/testing/?lang=th&pfid=6226d5ce0022c42410b15070&burl=https://demo.x-gaming.com/games/crash/greedymine/v1/socket.io&eurl=https://play.x-gaming.com/game/menu/dev/";
    } else if (gameName === "Dragon Fury") {
      window.location =
        "https://play.x-gaming.com/game/player/demo/?url=https://play.x-gaming.com/game/slots/dragonsfury/v2/testing/?lang=th&pfid=6226d5ce0022c42410b15070&burl=https://demo.x-gaming.com/games/slots/gen1/v1/socket.io&eurl=https://play.x-gaming.com/game/menu/dev/";
    } else {
      window.location =
        "https://play.x-gaming.com/game/player/demo/?url=https://play.x-gaming.com/game/slots/wetnwild/testing/?lang=th&pfid=6226d5ce0022c42410b15070&burl=https://demo.x-gaming.com/games/slots/gen2/v1/socket.io&eurl=https://play.x-gaming.com/game/menu/dev/";
    }
  };

  return (
    <>
      <FeatureGameContainer>
        <GameBox>
          {items.map((item, index) =>
            index === 0 ? (
              <MainGame key={"feature_" + index} img={item.img}>
                <MainText>
                  <p>Total Win</p>
                  <p>
                    <CountUp
                      start={parseInt(counterStart)} // add parseInt(fixed Warning error)
                      end={parseInt(counter.game[item.gameName])} // add parseInt(fixed Warning error)
                      duration={1}
                      separator=","
                    />{" "}
                    THB
                  </p>
                </MainText>
                <Link
                  // to={`games/all/${item.gameId}`}
                  onClick={redirectPage}
                  style={{ display: "flex", width: "max-content" }}
                >
                  <Button
                    bClass="rtRed"
                    width={
                      dimensionsLP.width <= breakpoint.deviceSize
                        ? "25.22vw"
                        : "7.81vw"
                    }
                    height={
                      dimensionsLP.width <= breakpoint.deviceSize
                        ? "10.1vw"
                        : "3.13vw"
                    }
                    label={t("play demo")}
                    fontSize={
                      dimensionsLP.width <= breakpoint.deviceSize
                        ? "4vw"
                        : "0.94vw"
                    } // 30px
                  />
                </Link>
              </MainGame>
            ) : (
              <Link
                // to={item.gameId != "" ? `games/all/${item.gameId}` : ""}
                onClick={() => {
                  if (item.gameId !== "") {
                    redirectPage(item.gameName);
                  }
                }}
                style={{
                  color: "#fff",
                  cursor: item.gameId !== "" ? "pointer" : "default",
                }}
                key={"feature_" + index}
              >
                <SubGame className={"sub" + index} img={item.img}>
                  {/* <Image src={props.demoImg} /> */}
                  {item.gameId !== "" ? (
                    <PlayDemo>
                      <img alt="" src="../../lpimages/btn_playdemo.png" />
                      <p>{t("play demo")}</p>
                    </PlayDemo>
                  ) : (
                    ""
                  )}
                  <p> Total Win</p>
                  <p>
                    <CountUp
                      start={parseInt(counterStart)} // add parseInt(fixed Warning error)
                      end={parseInt(counter.game[item.gameName])} // add parseInt(fixed Warning error)
                      duration={1}
                      separator=","
                    />{" "}
                    THB
                  </p>
                </SubGame>
              </Link>
            )
          )}
        </GameBox>
      </FeatureGameContainer>
    </>
  );
}

export default FeatureGame;
