import styled, { css } from "styled-components";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectuserProfile } from "../../Reducers/userProfileSlice";
import { config } from "../../config";
const AvatarBox = styled.div`
  user-select: none;

  /* height: calc(100vw * 0.075); 
    width: 7.1964vw; */
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Frame = styled.img`
  user-select: none;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;
`;

const Icon = styled.img`
  user-select: none;
  /* width: 77%; */
  width: 68%;
  position: absolute;
`;

const RedDot = styled.img`
  user-select: none;
  width: 2.5vw;
  position: absolute;
  top: 7.5%;
  z-index: 20;
  right: 0%;
  //Portrait
  @media (orientation: portrait){
    width: 3.5vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 3.5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 3.5vw;
        `} */
`;

export default function Avatar(props) {
  let profile = useSelector(selectuserProfile);
  const [icon, setIcon] = useState("");
  useEffect(() => {
    setIcon(
      typeof props.avatar === "undefined" || props.avatar === null
        ? profile.avatar + 1
        : props.avatar + 1
    );
  }, [profile.avatar]);
  let frameImg = "frame_rank_1";
  if (props.level >= 7 && props.level <= 19) {
    frameImg = "frame_rank_2";
  } else if (props.level >= 20 && props.level <= 49) {
    frameImg = "frame_rank_3";
  } else if (props.level >= 50 && props.level <= 89) {
    frameImg = "frame_rank_4";
  } else if (props.level >= 90) {
    frameImg = "frame_rank_5";
  }

  //  profileIndex
  return (
    <AvatarBox {...props}>
      <Frame src={`../${config.theme}/${frameImg}.png`} />
      <Icon
        src={icon === "" ? "" : `../${config.theme}/profile_imgtemp${icon}.png`}
      />
      {props.active === true ? (
        <RedDot src={"../" + config.theme + "/notice_dot.png"} />
      ) : (
        ""
      )}
    </AvatarBox>
  );
}
