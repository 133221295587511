import { createSlice } from "@reduxjs/toolkit";
export const homeContentLPSlice = createSlice({
    name: "homeContentLP",
    initialState: {
        content: [],
    },
    reducers: {
        actionHomeContentLP: (state, action) => {
            state.content = action.payload;
        },
    },
});

export const { actionHomeContentLP } = homeContentLPSlice.actions;
export const selectHomeContentLP = (state) => state.homeContentLP.content;
export default homeContentLPSlice.reducer;