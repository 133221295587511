import React, { useEffect, useState } from "react";
import { config } from "../../config";
import styled, { css, keyframes } from "styled-components";
import { socket } from "../../context/socket";
import { useSelector, useDispatch } from "react-redux";
import { selectDimensions } from "../../Reducers/dimensions";
import {
  selectuserProfile,
  actionSetprofile,
} from "../../Reducers/userProfileSlice";
import { useTranslation } from "react-i18next";
import { actionAlert } from "../../Reducers/alertSlice";
import { actionLoading } from "../../Reducers/loadingSlice";

const Bg = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div`
  position: absolute;
  width: fit-content;
  height: fit-content;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  // background: red;
`;

const HeroContainer = styled.div`
  width: 60vw;
  height: fit-content;
  z-index: 2;
  position: relative;
  display: flex;
  justify-content: center;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone &&
    css`
      transform: scale(1.5);
    `}
`;
// const NumberText = styled.div`
//   color: white;
//   font-size: 9vw;
//   position: absolute;
//   width: 100%;
//   text-align: center;
//   z-index: 3;
//   top: 3vw;
//   background: linear-gradient(
//     180deg,
//     #ffffff 49.24%,
//     #fcd4b7 51.85%,
//     #ffffff 100%
//   );
//   -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
//   background-clip: text;
// `;
// const CurrencyText = styled.div`
//   color: white;
//   font-size: 3vw;
//   position: absolute;
//   text-align: center;
//   width: 100%;
//   z-index: 3;
//   bottom: -0.5vw;
// `;
const jackpot = keyframes`
    0%{ transform:  scale(0.9);}
    100%{ transform:  scale(1);}
    /* 0%{ transform: translate(-50%) scale(0.9);}
    100%{ transform: translate(-50%) scale(1);} */
`;
const BoxTextJackpot = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: -14vw;
`;
const TextJackpot = styled.div`
  background-image: ${(props) =>
    props.lang === "TH"
      ? "url(../../images/jackpot_animate/text_jackpot.png)"
      : "url(../../images/jackpot_animate/text_jackpot_en.png)"};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  animation-name: ${jackpot};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.7, 0.29, 0.37, 0.81);
`;

const Base = styled.div`
  background-image: url(../../images/jackpot_animate/base.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 18vw;
`;
const coinprop = css`
  background-position: center top;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
`;
const coin = keyframes`
    0% { top: 50%; transform: translateX(-50%) translateY(-55%) scale(1);}
    100% { top: 52%; transform: translateX(-50%) translateY(-55%) scale(1.05);}
`;
const coin2 = keyframes`
    0% { top: 50%; transform: translateX(-50%) translateY(-55%) scale(1.5);}
    100% { top: 52%; transform: translateX(-50%) translateY(-55%) scale(1.55);}
`;
const CoinContainer = styled.div`
  width: 100vw;
  height: 40vw;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  // background: rgba(234,234,234,0.6);
  background-image: url(../../images/jackpot_animate/coinpack.png);
  ${coinprop}
  transform-origin: center;
  animation-name: ${coin};
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.7, 0.29, 0.37, 0.81);
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone &&
    css`
      animation-name: ${coin2};
      z-index: -1;
    `}
`;
const flarespin = keyframes`
    0% { transform: rotate(0deg) scale(0); opacity: 0;}
    50% { transform: rotate(180deg); opacity: 1;}
    100% { transform: rotate(360deg) scale(1); opacity: 0;}
`;
const flarespin2 = keyframes`
    0% { transform: rotate(0deg) scale(0); opacity: 0;}
    50% { transform: rotate(180deg); opacity: 1;}
    100% { transform: rotate(360deg) scale(1.5); opacity: 0;}
`;
const flarespin3 = keyframes`
    0% { transform: rotate(0deg) scale(0); opacity: 0;}
    50% { transform: rotate(180deg); opacity: 1;}
    100% { transform: rotate(360deg) scale(1.4); opacity: 0;}
`;
const spin1 = css`
  animation-name: ${flarespin};
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-timing-function: linear;
`;
const spin2 = css`
  animation-name: ${flarespin2};
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-timing-function: linear;
`;
const spin3 = css`
  animation-name: ${flarespin3};
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-timing-function: linear;
`;

const flares = css`
  background-image: url(../../images/jackpot_animate/flare_s.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
  position: absolute;
  mix-blend-mode: screen;
`;
const FlareS1 = styled.div`
  ${flares}
  width: 10vw;
  height: 10vw;
  left: 14.5vw;
  ${spin1}
  animation-duration: 2s;
`;
const FlareS2 = styled.div`
  ${flares}
  width: 10vw;
  height: 10vw;
  left: 25vw;
  top: -4vw;
  ${spin2}
  animation-duration: 1.5s;
`;
const FlareS3 = styled.div`
  ${flares}
  width: 10vw;
  height: 10vw;
  left: 70vw;
  top: -3vw;
  ${spin3}
  animation-duration: 3s;
`;
const FlareS4 = styled.div`
  ${flares}
  width: 10vw;
  height: 10vw;
  left: 82vw;
  top: 3vw;
  ${spin1}
  animation-duration: 1s;
`;
const FlareS5 = styled.div`
  ${flares}
  width: 10vw;
  height: 10vw;
  left: 60vw;
  top: 28vw;
  ${spin2}
  animation-duration: 2.4s;
`;
const FlareS6 = styled.div`
  ${flares}
  width: 10vw;
  height: 10vw;
  left: 50vw;
  top: 34vw;
  ${spin3}
  animation-duration: 1.8s;
`;
const FlareS7 = styled.div`
  ${flares}
  width: 10vw;
  height: 10vw;
  left: 30vw;
  top: 26vw;
  ${spin1}
  animation-duration: 1.3s;
`;
const FlareS8 = styled.div`
  ${flares}
  width: 10vw;
  height: 10vw;
  left: 12vw;
  top: 14vw;
  ${spin2}
  animation-duration: 2.2s;
`;
const FlareS9 = styled.div`
  ${flares}
  width: 13vw;
  height: 13vw;
  left: -2vw;
  top: 9vw;
  ${spin2}
  animation-duration: 1.8s;
`;
const FlareS10 = styled.div`
  ${flares}
  width: 15vw;
  height: 15vw;
  left: 52vw;
  top: 4vw;
  ${spin2}
  animation-duration: 2.7s;
`;
const FlareS11 = styled.div`
  ${flares}
  width: 15vw;
  height: 15vw;
  left: -4vw;
  top: -2vw;
  ${spin1}
  animation-duration: 2s;
`;
const FlareS12 = styled.div`
  ${flares}
  width: 15vw;
  height: 15vw;
  left: 5vw;
  top: -6vw;
  ${spin2}
  animation-duration: 2.3s;
`;
const FlareS13 = styled.div`
  ${flares}
  width: 15vw;
  height: 15vw;
  left: 17vw;
  top: 0.5vw;
  ${spin3}
  animation-duration: 2s;
`;
const FlareS14 = styled.div`
  ${flares}
  width: 15vw;
  height: 15vw;
  left: 40vw;
  top: -2vw;
  ${spin1}
  animation-duration: 2.2s;
`;
// const FlareS15 = styled.div`
//   ${flares}
//   width: 11vw;
//   height: 11vw;
//   left: 35vw;
//   top: -2vw;
//   ${spin2}
//   animation-duration: 2.7s;
// `;
// const FlareS16 = styled.div`
//   ${flares}
//   width: 10vw;
//   height: 10vw;
//   left: 21vw;
//   top: -4vw;
//   ${spin3}
//   animation-duration: 2s;
// `;
const flare = keyframes`
    0% {transform:  rotate(0deg);}
    100% {transform:  rotate(360deg);}
    /* 0% {transform: translateX(-50%) translateY(-50%) rotate(0deg);}
    100% {transform: translateX(-50%) translateY(-50%) rotate(360deg);} */
`;
const BoxFlare = styled.div`
  width: 130vw;
  height: 130vw;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone &&
    css`
      width: 200vw;
      height: 200vw;
    `}
`;
const Flare = styled.div`
  background-image: url(../../images/jackpot_animate/flare_l.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
  /* width: 130vw;
  height: 130vw;
  left: 50%;
  top: 50%; */
  width: 100%;
  height: 100%;
  // transform: translateX(-50%) translateY(-50%);
  position: absolute;
  // background-color: red;
  z-index: -1;
  mix-blend-mode: screen;
  opacity: 0.7;
  // animation: ${flare} 2s linear infinite;
  animation-name: ${flare};
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
`;
const ButtonGreen = styled.div`
  color: white;
  cursor: pointer;
  // background: green;
  padding: 0.5vw 1.5vw;
  width: fit-content;
  position: absolute;
  z-index: 10;
  left: 50%;
  top: ${() => (window.appHeight() > window.innerWidth ? "48%" : "45%")};
  transform: translate(-50%);
  // bottom: -6vw;
  background: linear-gradient(180deg, #06d746 38.02%, #059604 100%);
  box-shadow: inset 0px 0px 1vw 0.5vw #4dff80;
  border-radius: 2vw;
  font-size: 3vw;
  margin-top: 10vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone &&
    css`
      margin-top: 16vw;
      font-size: 4vw;
    `}
`;
// const Yougot = styled.div`
//   color: white;
//   font-size: 3vw;
//   width: 100%;
//   text-align: center;
//   position: absolute;
//   top: 2.2vw;
//   z-index: 4;
// `;
const TextCongrat = styled.div`
  user-select: none;
  font-style: normal;
  font-size: 1.5vw;
  position: absolute;
  color: white;
  top: 7%;
  width: 100%;
  text-align: center;
  line-height: 2vw;
`;
const Jackpot2Banner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 0, 0.5);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;
const Jackpot2Button = styled.div`
  width: 24%;
  height: 9%;
  cursor: pointer;
  position: absolute;
  background-image: url("../images/jackpot_animate/btnContact.gif");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 28vw 15vw 0 0;
`;
const Jackpot2UserName = styled.div`
  color: #fff;
  position: absolute;
  font-size: 4vw;
  margin: 1.5vw 30vw 0 0;
  width: 40%;
  height: 8.8%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function BigJackpot(props) {
  // const [svgValue, setSvgValue] = useState("");
  const [curvePath, setCurvePath] = useState("M0,0 C0,0 0,0 0,0");
  const dimension = useSelector(selectDimensions);
  const profile = useSelector(selectuserProfile);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [lineId, setLineId] = useState("");

  function separateComma(val) {
    // remove sign if negative
    var sign = 1;
    if (val < 0) {
      sign = -1;
      val = -val;
    }
    // trim the number decimal point if it exists
    let num = val.toString().includes(".")
      ? val.toString().split(".")[0]
      : val.toString();
    let len = num.toString().length;
    let result = "";
    let count = 1;

    for (let i = len - 1; i >= 0; i--) {
      result = num.toString()[i] + result;
      if (count % 3 === 0 && count !== 0 && i !== 0) {
        result = "," + result;
      }
      count++;
    }

    // add number after decimal point
    if (val.toString().includes(".")) {
      result = result + "." + val.toString().split(".")[1];
    }
    // return result with - sign if negative
    return sign < 0 ? "-" + result : result;
  }

  useEffect(() => {
    try {
      // dispatch(actionWinPopSound());
      socket.emit("getLineID", {}, (body) => {
        // console.log("getLineID", body);
        if (typeof body != "undefined") {
          if (typeof body.results != "undefined") {
            if (typeof body.results.data != "undefined") {
              if (body.status) {
                // console.log(body.results.data.line_id);
                if (
                  body.results.data.line_id &&
                  typeof body.results.data.line_id != undefined
                ) {
                  setLineId(body.results.data.line_id);
                }
              }
            }
          }
        }
      });
    } catch (error) { }
  }, []);

  const openInNewTab = (url) => {
    try {
      const newWindow = window.open(url, "_blank");
      if (newWindow) newWindow.opener = null;
    } catch (error) { }
  };

  useEffect(() => {
    if (props.type === 1) {
      let svgValue = document
        .getElementById("curveSvg")
        .getBoundingClientRect();
      setCurvePath(
        "M0," +
        svgValue.height +
        " C" +
        svgValue.width * 0.15 +
        "," +
        svgValue.height * 0.6 +
        " " +
        svgValue.width * 0.85 +
        "," +
        svgValue.height * 0.6 +
        " " +
        svgValue.width +
        "," +
        svgValue.height
      );
    }
  }, [dimension]);

  return (
    <Bg {...props}>
      {props.type === 2 ? (
        <Jackpot2Banner
          style={{
            backgroundImage: `url(${profile.jackpot.filter((data) => data.type === 2)[0]?.detail
              .img === ""
              ? "../images/jackpot_animate/jackpot_honda.png"
              : profile.jackpot.filter((data) => data.type === 2)[0]?.detail
                .img
              })`,
          }}
        >
          <Jackpot2Button
            onClick={() => {
              dispatch(actionLoading(false));
              if (props.jackpotCounter === 1) {
                props.setJackpotCounter(props.jackpotCounter - 1);
                socket.emit("receivejackpot", {}, (data) => {
                  console.log("receivejackpot", data);
                  dispatch(actionLoading(true));
                  if (data.status === true) {
                    dispatch(actionSetprofile({ jackpot: [] }));
                    props.setJackpotRequest(true);
                    openInNewTab(lineId);
                    props.setShowJackpot(false);
                  } else if (data.status === false) {
                    props.setShowJackpot(false);
                    dispatch(
                      actionAlert({ isAlert: true, errorCode: data.errorCode })
                    );
                    setTimeout(() => {
                      dispatch(actionAlert({ isAlert: false }));
                    }, 3000);
                  }
                });
              } else {
                props.setJackpotCounter(props.jackpotCounter - 1);
                dispatch(actionLoading(true));
                openInNewTab(lineId);
              }
            }}
          />
          <Jackpot2UserName>{"คุณ " + profile.username}</Jackpot2UserName>
        </Jackpot2Banner>
      ) : (
        <>
          <ButtonGreen
            onClick={() => {
              dispatch(actionLoading(false));
              if (props.jackpotCounter === 1) {
                socket.emit("receivejackpot", {}, (data) => {
                  console.log("receivejackpot", data);
                  dispatch(actionLoading(true));
                  if (data.status === true) {
                    dispatch(actionSetprofile({ jackpot: [] }));
                    props.setJackpotRequest(true);
                    props.setShowJackpot(false);
                  } else if (data.status === false) {
                    props.setShowJackpot(false);
                    dispatch(
                      actionAlert({ isAlert: true, errorCode: data.errorCode })
                    );
                    setTimeout(() => {
                      dispatch(actionAlert({ isAlert: false }));
                    }, 3000);
                  }
                });
              } else {
                props.setJackpotCounter(props.jackpotCounter - 1);
                dispatch(actionLoading(true));
              }
            }}
          >
            {t("collect")}
          </ButtonGreen>
          <Container>
            <HeroContainer>
              <FlareS9 />
              <FlareS10 />
              <FlareS11 />
              {/* <NumberText>3,490,000</NumberText> */}
              <TextCongrat>
                {t("Big Jackpot Congrat", { Username: profile.username })}{" "}
                <br />
                {t("Big Jackpot Congrat2")}
              </TextCongrat>
              <svg
                id="curveSvg"
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  transform:
                    window.appHeight() / window.innerWidth >
                      config.verticalSizeiphone
                      ? "scale(0.67)"
                      : "scale(1)",
                }}
              >
                <defs>
                  <linearGradient
                    id="rainbow"
                    x1="0"
                    x2="0"
                    y1="0"
                    y2="100%"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FFFFFF" offset="49.24%" />
                    <stop stopColor="#fcd4b7" offset="51.85%" />
                    <stop stopColor="#ffffff" offset="100%" />
                  </linearGradient>
                </defs>
                <path id="curve" fill="transparent" d={curvePath} />
                <text
                  style={{
                    fill: "white",
                    // fill: "url(#rainbow)",
                    position: "relative",
                  }}
                >
                  <textPath
                    xlinkHref="#curve"
                    textAnchor="middle"
                    startOffset="50%"
                    style={{
                      fontSize:
                        window.appHeight() / window.innerWidth >
                          config.verticalSizeiphone
                          ? "9.167vw"
                          : "7.5vw",
                      position: "absolute",
                      width: "100%",
                      textAlign: "center",
                      zIndex: 3,
                      top: "3vw",
                      background:
                        "linear-gradient(180deg, #ffffff 49.24%,#fcd4b7 51.85%,#ffffff 100%)",
                      backgroundClip: "text",
                    }}
                  >
                    {/* {separateComma(10000)} */}
                    {separateComma(
                      profile.jackpot.filter((data) => data.type === 1)[0]
                        ?.credit
                    )}
                  </textPath>
                </text>
              </svg>
              <BoxTextJackpot>
                <TextJackpot lang={profile.language}>
                  <FlareS12 />
                  <FlareS13 />
                  <FlareS14 />
                </TextJackpot>
              </BoxTextJackpot>
              {/* <TextSpinix>
            <FlareS15 />
            <FlareS16 />
          </TextSpinix> */}
              <Base />
            </HeroContainer>
          </Container>
          <CoinContainer>
            <FlareS1 />
            <FlareS2 />
            <FlareS3 />
            <FlareS4 />
            <FlareS5 />
            <FlareS6 />
            <FlareS7 />
            <FlareS8 />
            {/* <Coin1 />
                <Coin2 />
                <Coin3 />
                <Coin4 />
                <Coin5 />
                <Coin6 />
                <Coin7 />
                <Coin8 /> */}
          </CoinContainer>
          <BoxFlare>
            <Flare style={{ width: "90%", height: "90%" }} />
            <Flare />
          </BoxFlare>
        </>
      )}
    </Bg>
  );
}
export default BigJackpot;
