import { createSlice } from "@reduxjs/toolkit";

export const festivalBannerSlice = createSlice({
  name: "festivalBanner",
  initialState: {
    status: 0,
  },
  reducers: {
    actionFestivalBanner: (state, action) => {
      state.status = action.payload;
    },
  },
});

export const { actionFestivalBanner } = festivalBannerSlice.actions;
export const selectFestivalBanner = (state) => state.festivalBanner.status;
export default festivalBannerSlice.reducer;
