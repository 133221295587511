import styled, { css } from "styled-components";
import { config } from "../../config";
const ActionButton = styled.div`
  user-select: none;
  width: 8.7vw;
  height: 5.4vw;
  margin: 1%;
  // background-image: url('/${config.theme}/btn_number.png');
  background: #423f54;
  box-shadow: 0px 0.6vw 1vw rgba(0, 0, 0, 0.3), inset 0px -0.5vw 0.4vw #545267;
  border-radius: 1vw;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  cursor: pointer;
  :active {
    background: #ff691f;
    box-shadow: 0px 0.6vw 1vw rgba(255, 105, 31, 0.44),
      inset 0px -0.5vw 0.4vw #ffc700;
  }
  //Portrait
  @media (orientation: portrait) {
    width: 28.7vw;
    height: 14.4vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    width: 12.7vw;
    height: 6.4vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 28.7vw;
          height: 14.4vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 12.7vw;
          height: 6.4vw;
        `} */
`;

const Value = styled.div`
  user-select: none;
  margin-top: 15%;
  color: #ffffff;
  font-size: 2vw;
  cursor: pointer;
  //Portrait
  @media (orientation: portrait) {
    margin-top: 6%;
    color: #ffffff;
    font-size: 7vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 3vw;
    margin-top: 10%;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin-top: 6%;
          color: #ffffff;
          font-size: 7vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 3vw;
          margin-top: 10%;
        `} */
`;

export default function DecimalDepositButton(props) {
  return (
    <ActionButton {...props}>
      <Value>{props.value}</Value>
    </ActionButton>
  );
}
