import { useState, useEffect } from "react";
import styled from "styled-components";
import FaqListItem from "./FaqListItem";
import WarningMessage from "../Dialog/WarningMessage";
import { useTranslation } from "react-i18next";
import { config, Transition } from "../../config";
import { useDispatch } from "react-redux";
import { actionLoading } from "../../Reducers/loadingSlice";
import axios from "axios";

const WarningContainer = styled.div`
  user-select: none;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
`;

function FaqList(props) {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  // const [items, setItems] = useState([]);
  const [items, setItems] = useState({ en: [], th: [] });
  const [emitError, setEmitError] = useState({ status: false, message: "" });

  useEffect(() => {
    dispatch(actionLoading(false));
    axios({
      method: "get",
      url: config.socketEndpoint + "getFAQ",
      headers: {},
    })
      .then(function (response) {
        dispatch(actionLoading(true));
        // console.log(response.data.results.data);
        // response.data = undefined;
        // response.data.status = false;
        // response.data.errorCode = "s0008";
        if (
          typeof response.data === "undefined" ||
          typeof response.data.results === "undefined" ||
          typeof response.data.results.data === "undefined"
        ) {
        } else {
          if (response.data.status === true) {
            setItems(response.data.results.data);
            // if (i18n.language == "th") {
            //     setItems(response.data.results.data.th);
            // } else {
            //     setItems(response.data.results.data.en);
            // }
          } else if (response.data.status === false) {
            setEmitError({ status: true, message: response.data.errorCode });
            // setTimeout(() => {
            //     setEmitError({ status: false })
            // }, 3000)
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <>
      {props.home
        ? items[i18n.language]?.map((item, index) => {
            if (index < 5) {
              return <FaqListItem key={`FAQ_index_${index}`} {...item} />;
            }
          })
        : items[i18n.language].map((item, index) => (
            <FaqListItem key={`FAQ_index_${index}`} {...item} />
          ))}

      {/* {items[i18n.language].map((item, index) => (
                <FaqListItem key={`FAQ_index_${index}`} {...item} />
            ))} */}

      <Transition showPop={emitError.status} type="alert">
        <WarningContainer>
          <WarningMessage
            errCode={emitError.message}
            onClick={() => {
              setEmitError({ ...emitError, status: false });
            }}
          />
        </WarningContainer>
      </Transition>
    </>
  );
}

export default FaqList;
