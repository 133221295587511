import { createSlice } from "@reduxjs/toolkit";
// import ReactMomentCountDown from "react-moment-countdown";
// import * as moment from "moment";

export const countDown = createSlice({
  name: "countDown",
  initialState: {
    // timer: ReactMomentCountDown({ toDate: moment().endOf("days") })
    timer: "00:00:00",
    jackpot: {
      days: "00",
      hours: "00",
      mins: "00",
      secs: "00",
    },
    countDownStamp: 0,
  },
  reducers: {
    changeTimer: (state, action) => {
      // state.timer = ReactMomentCountDown({ toDate: moment().endOf("days") })
      if (action.payload == "-1:-1:-1") {
        state.timer = "00:00:00";
      } else {
        state.timer = action.payload;
      }
    },
    changeJackpot: (state, action) => {
      state.jackpot = action.payload;
    },
    changeCountDownStamp: (state, action) => {
      state.countDownStamp = action.payload;
    },
  },
});

export const { changeTimer, changeJackpot, changeCountDownStamp } =
  countDown.actions;

export const timer = (state) => state.countDown.timer;
export const jackpotCountDown = (state) => state.countDown.jackpot;
export const countDownStamp = (state) => state.countDown.countDownStamp;

export default countDown.reducer;
