import { createSlice } from "@reduxjs/toolkit";
export const alertSlice = createSlice({
    name: "alert",
    initialState: {
        alert: {
            isAlert: false,
            errorCode: ""
        },
    },

    reducers: {
        actionAlert: (state, action) => {
            state.alert = { ...state.alert, ...action.payload };
        },
    },
});

export const { actionAlert } = alertSlice.actions;
export const selectAlert = (state) => state.alert.alert;
export default alertSlice.reducer;