import { createSlice } from "@reduxjs/toolkit";

export const promotionSlice = createSlice({
  name: "promotion",
  initialState: {
    promo: {
      name: "",
      title: "",
      detail: "",
      image: "",
    },
    contentpromo: { contentpromo: { en: [], th: [] } },
    numberpromo: {},
    selectedIndex: 0,
    promoRedirect: false,
  },
  reducers: {
    actionPromotion: (state, action) => {
      state.promo = action.payload;
    },
    actionContentpromo: (state, action) => {
      state.contentpromo = action.payload;
    },
    actionNumberpromo: (state, action) => {
      state.numberpromo = action.payload;
    },
    actionSelectedIndex: (state, action) => {
      state.selectedIndex = action.payload;
    },
    actionPromoRedirect: (state, action) => {
      state.promoRedirect = action.payload;
    },
  },
});

export const {
  actionPromotion,
  actionContentpromo,
  actionNumberpromo,
  actionSelectedIndex,
  actionPromoRedirect,
} = promotionSlice.actions;
export const selectPromotion = (state) => state.promotion.promo;
export const selectContentpromo = (state) => state.promotion.contentpromo;
export const selectNumberpromo = (state) => state.promotion.numberpromo;
export const SelectedIndex = (state) => state.promotion.selectedIndex;
export const selectPromoRedirect = (state) => state.promotion.promoRedirect;
export default promotionSlice.reducer;
