import React, { useState, useContext } from "react";
import styled from "styled-components";
import Button from "./Button";
import { useTranslation } from "react-i18next";
import { SocketContext } from "../context/socket";
import { useDispatch } from "react-redux";
import { actionSetprofile } from "../Reducers/userProfileSlice";
import AlertMessage from "./Dialog/AlertMessage";
import { Transition, config } from "../config";

const Popup = styled.div`
  user-select: none;
  // background-image: url('/${config.theme}/popup_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  // background: #302e3c;
  // box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f, inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  // border: solid 0.2vw orange;
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;
  border-radius: 1.5vw;
  position: relative;
  z-index: 70;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BGPopup = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%;
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 70;
  align-items: center;
  justify-content: center;
`;

const Image = styled.div`
  user-select: none;
  display: flex;
`;

const BButtom = styled.div`
  user-select: none;
  display: flex;
`;

function FtuePopup02(props) {
  const { t } = useTranslation();
  const socket = useContext(SocketContext);
  const [bonus, setbonus] = useState(false);
  const dispatch = useDispatch();
  const [emitError, setEmitError] = useState({ status: false, message: "" });

  const getBonus = () => {
    try {
      dispatch(actionSetprofile({ banner_pro_popup: false }));
      socket.emit(
        "getBannerPopup",
        {
          bonus: "O",
        },
        (body) => {
          if (typeof body != "undefined") {
            if (typeof body.results != "undefined") {
              if (body.status === false) {
                setEmitError({ status: true, message: body.errorCode });
                setTimeout(() => {
                  setEmitError({ status: false });
                }, 3000);
              } else if (body.status) {
                setbonus(body.results.data);
              }
            }
          }
        }
      );
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  };

  const notGetBonus = () => {
    try {
      dispatch(actionSetprofile({ banner_pro_popup: false }));
      socket.emit(
        "getBannerPopup",
        {
          bonus: "C",
        },
        (body) => {
          if (typeof body != "undefined") {
            if (typeof body.results != "undefined") {
              if (body.status === false) {
                setEmitError({ status: true, message: body.errorCode });
                setTimeout(() => {
                  setEmitError({ status: false });
                }, 3000);
              } else if (body.status) {
                setbonus(body.results.data);
              }
            }
          }
        }
      );
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  };

  return (
    <>
      <BGPopup>
        <Popup
          style={{
            width:
              window.innerWidth / window.appHeight() > 2.15 ? "62vw" : "62vw",
            height:
              window.innerWidth / window.appHeight() > 2.15 ? "35vw" : "35vw",
            top: "-2vw",
          }}
        >
          <Image>
            <img alt=""
              src={"../" + config.theme + "/FtuePopup02.jpg"}
              style={{
                position: "absolute",
                width: "59vw",
                marginTop: "-16vw",
                left: "1.5vw",
                borderRadius: "1vw",
              }}
            />
          </Image>

          <BButtom>
            <Button
              onClick={() => {
                setTimeout(() => {
                  props.closeClick();
                }, 300);
                notGetBonus(bonus);
              }}
              bClass="rbGray"
              label={t("ftue no")}
              width={
                window.appHeight() / window.innerWidth >
                  config.verticalSizeiphone
                  ? "30vw"
                  : window.appHeight() > window.innerWidth
                    ? "30vw"
                    : window.innerWidth / window.appHeight() > 2.15
                      ? "20vw"
                      : "20vw"
              }
              fontSize={
                window.appHeight() / window.innerWidth >
                  config.verticalSizeiphone
                  ? "3vw"
                  : window.appHeight() > window.innerWidth
                    ? "3vw"
                    : window.innerWidth / window.appHeight() > 2.15
                      ? "2vw"
                      : "2vw"
              }
              z-index="10"
              style={{ top: "18.8vw", paddingLeft: "1vw" }}
            />
          </BButtom>

          <BButtom>
            <Button
              onClick={() => {
                setTimeout(() => {
                  props.closeClick();
                }, 300);
                getBonus(bonus);
              }}
              bClass="rbGreen"
              label={t("ftue yes")}
              width={
                window.appHeight() / window.innerWidth >
                  config.verticalSizeiphone
                  ? "30vw"
                  : window.appHeight() > window.innerWidth
                    ? "30vw"
                    : window.innerWidth / window.appHeight() > 2.15
                      ? "20vw"
                      : "20vw"
              }
              fontSize={
                window.appHeight() / window.innerWidth >
                  config.verticalSizeiphone
                  ? "3vw"
                  : window.appHeight() > window.innerWidth
                    ? "3vw"
                    : window.innerWidth / window.appHeight() > 2.15
                      ? "2vw"
                      : "2vw"
              }
              style={{ top: "18.8vw", paddingLeft: "1vw" }}
            />
          </BButtom>
        </Popup>
      </BGPopup>
      <Transition showPop={emitError.status} type="alert">
        <AlertMessage
          message={t(emitError.message)}
          onClick={() => {
            setEmitError({ status: false });
          }}
        />
      </Transition>
    </>
  );
}

export default FtuePopup02;
