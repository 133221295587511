import React from 'react';
import AlertMessage from './AlertMessage';
import Button from '../Button';
import styled from "styled-components";
import breakpoint from "../breakpoints";
// import useWindowSize from "../useWindowSize";
import { useTranslation } from "react-i18next";

const WarningMessageContainer = styled.div`
    width: max-content;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 20;
    > img {
        width: 6.77vw;
        margin-bottom: -2.25vw ;
    }
    @media ${breakpoint.device.mobile} {
        > img {
            width: 26.4vw;
            margin-bottom: -9vw ;
        }
    }
`

const Btn = styled(Button)`
    margin: -2vw auto 0 auto;
    @media ${breakpoint.device.mobile} {
        margin: -6vw auto 0 auto;
    }
`

function WarningMessage(props) {
    // const size = useWindowSize();
    const { t } = useTranslation();
    return (
        <WarningMessageContainer>
            <img alt="" src="../../lpimages/warning_icon.png" />
            <AlertMessage>
                {t(`${props.errCode}`)}
            </AlertMessage>
            <Btn
                bClass="rtRed"
                // label={t("ok")}
                label={props.btnLabel || t("ok")}
                width={window.innerWidth <= breakpoint.deviceSize ? "40.48vw" : "9.4vw"}
                height={window.innerWidth <= breakpoint.deviceSize ? "12.5vw" : "3.13vw"}
                fontSize={window.innerWidth <= breakpoint.deviceSize ? "4.83vw" : ""}
                onClick={props.onClick}
            />
        </WarningMessageContainer>
    )
}

export default WarningMessage;
