import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { config } from "../../config";
import { Transition } from "../../config";
import AlertMessage from "../Dialog/AlertMessage";
import {
  selectuserProfile,
  actionClickSound,
} from "../../Reducers/userProfileSlice";
import { useSelector, useDispatch } from "react-redux";
import Button from "../Button";
import {
  SocketContext,
  socket,
  SocketKafkaContext,
} from "../../context/socket";
import SpinWheelResult from "../LuckyWheel/SpinWheelResult";
const Box = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 56vw;
  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          top: 90vw;
          left: auto;
          z-index: 1;
        `
      : window.innerHeight > window.innerWidth &&
        css`
          left: auto;
          top: 57vw;
          z-index: 1;
        `}
`;

const JackpotButton = styled.div`
  position: relative;
  background: linear-gradient(180deg, #e7ce5c 0%, #734f17 100%);
  box-shadow: inset 0px 0px 8px 1px #ffec95;
  border-radius: 1vw;
  width: 16vw;
  height: 25vw;
  display: flex;
  flex-direction: column;
  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          border-radius: 2vw;
          width: 85vw;
          height: 26vw;
          flex-direction: row;
        `
      : window.innerHeight > window.innerWidth &&
        css`
          width: 55.1vw;
          border-radius: 2vw;
          height: 15vw;
          flex-direction: row;
        `}
`;

const Img = styled.img`
  user-select: none;
  position: relative;
  height: 25.52vw;
  top: -11vw;
  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          height: 28.52vw;
          top: -3vw;
          left: 4vw;
        `
      : window.innerHeight > window.innerWidth &&
        css`
          height: 15.52vw;
          top: -1vw;
          left: 3vw;
        `}
`;

const Key = styled.img`
  position: relative;
  width: 3vw;
  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 6vw;
        `
      : window.innerHeight > window.innerWidth && css``}
`;

const TextLevel = styled.div`
  position: relative;
  color: white;
  font-size: 1vw;
  font-weight: 700;
  padding: 0vw 0vw 0vw 0.5vw;
  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 3vw;
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          text-align: center;
        `
      : window.innerHeight > window.innerWidth &&
        css`
          text-align: center;
          font-size: 1.5vw;
          display: flex;
          width: 100%;
          flex-wrap: wrap;
        `}
`;

const Content = styled.div`
  background: linear-gradient(
    180deg,
    rgba(128, 99, 27, 0.9) 0%,
    rgba(62, 24, 3, 0.9) 100%
  );
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.6), inset 0px 0px 8px 1px #ffec95;
  top: -20vw;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  height: 5vw;
  padding: 1vw;
  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          top: 0vw;
          height: 24vw;
          left: -14vw;
          width: 14vw;
          flex-direction: column;
        `
      : window.innerHeight > window.innerWidth &&
        css`
          top: 0vw;
          height: 13vw;
          flex-direction: column;
          left: -7vw;
          width: 7vw;
        `}
`;

const Text1 = styled.div`
  color: #181400;
  text-shadow: 0px 0px 5px rgba(231, 206, 92, 0.5);
  font-size: 1.3vw;
  position: relative;
  font-weight: 700;
  /* top: -17vw; */
  display: flex;
  align-self: center;
  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 3.3vw;
        `
      : window.innerHeight > window.innerWidth &&
        css`
          font-size: 1.8vw;
        `}
`;
const Text2 = styled.div`
  color: white;
  text-shadow: 0px 0px 5px rgba(231, 206, 92, 0.5);
  font-size: 1.3vw;
  position: relative;
  font-weight: 700;
  width: 8vw;
  display: flex;
  align-self: center;
  text-align: center;
  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2.8vw;
          width: 30vw;
        `
      : window.innerHeight > window.innerWidth &&
        css`
          font-size: 1.8vw;
          width: 14vw;
        `}
`;

const Day = styled.div`
  color: rgba(255, 255, 255, 0.6);
  text-shadow: 0px 0px 5px rgba(231, 206, 92, 0.5);
  font-size: 1.3vw;
  position: relative;
  font-weight: 700;
  display: flex;
  align-self: center;
  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 3.3vw;
        `
      : window.innerHeight > window.innerWidth &&
        css`
          font-size: 2.3vw;
        `}
`;
const ContentText = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 14vw;
  align-items: center;
  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          top: 2vw;
          left: 49vw;
        `
      : window.innerHeight > window.innerWidth &&
        css`
          top: 1vw;
          left: 34vw;
        `}
`;

const ImageReceived = styled.img`
  width: 8vw;
  position: absolute;
  top: 6.1vw;
  left: 60vw;
  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          left: 23vw;
          width: 17vw;
          top: 84vw;
          padding: 4.9vw 13.2vw 2.6vw 12.2vw;
        `
      : window.innerHeight > window.innerWidth &&
        css`
          left: 20vw;
          width: 8vw;
          top: 53vw;
          padding: 3.9vw 8.2vw 2.2vw 8.2vw;
        `}
`;

const TextReceived = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  width: 16vw;
  left: 56vw;
  top: 15vw;
  text-align: center;

  h1 {
    position: relative;
    font-size: 1.5vw;
    color: white;
    display: flex;
    align-content: center;
    justify-content: center;
  }
  span {
    position: relative;
    display: flex;
    font-size: 1.2vw;
    color: white;
    align-content: center;
    justify-content: center;
    line-height: 2vw;
  }
  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 70vw;
          left: auto;
          top: 104vw;
          h1 {
            font-size: 3.5vw;
          }
          span {
            font-size: 3vw;
            line-height: 4vw;
          }
        `
      : window.innerHeight > window.innerWidth &&
        css`
          width: 43vw;
          left: 10vw;
          top: 64vw;
          h1 {
            font-size: 2vw;
          }
          span {
            font-size: 1.8vw;
          }
        `}
`;

const BoxButton = styled.div`
  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          position: relative;
          top: 1vw;
        `
      : window.innerHeight > window.innerWidth && css``}
`;

export default function JackpotItem(props) {
  const { t } = useTranslation();
  const [emitError, setEmitError] = useState({
    status: false,
    message: "",
    errorCode: "",
    errorMessage: "",
  });
  const profile = useSelector(selectuserProfile);
  const dispatch = useDispatch();
  const [getReward, setGetReward] = useState(false);
  const [reward, setReward] = useState(0);

  useEffect(() => {
    // console.log("props levellimit:", props.levellimit);
    // console.log("profile level:", profile.level);
    console.log("props items:", props.items);
  }, []);

  return (
    <>
      {props.status === 0 ? (
        <Box>
          <JackpotButton>
            {window.innerHeight > window.innerWidth ? (
              <Img
                src={
                  "../" + config.theme + "/dailyLogin/img_jackpot_mobile.png"
                }
              />
            ) : (
              <Img
                src={"../" + config.theme + "/dailyLogin/img_jackpot_pc.png"}
              />
            )}
            {profile.level >= props.levellimit ? (
              ""
            ) : (
              <Content>
                <Key src={"../" + config.theme + "/dailytopup/lock.png"} />
                <TextLevel>{t("Required")}</TextLevel>
              </Content>
            )}
          </JackpotButton>
          <ContentText>
            <Text1>{t("super mystery box")}</Text1>
            <Text2>{t("Win up")}</Text2>
            <Day>
              {t("day dailyLogin")} {props.day}
            </Day>
          </ContentText>
        </Box>
      ) : props.status === 1 ? (
        <Box>
          <JackpotButton>
            {window.innerHeight > window.innerWidth ? (
              <Img
                src={
                  "../" + config.theme + "/dailyLogin/img_jackpot_mobile.png"
                }
              />
            ) : (
              <Img
                src={"../" + config.theme + "/dailyLogin/img_jackpot_pc.png"}
              />
            )}

            {profile.level >= props.levellimit ? (
              ""
            ) : (
              <Content>
                <Key src={"../" + config.theme + "/dailytopup/lock.png"} />
                <TextLevel>{t("Required")}</TextLevel>
              </Content>
            )}
          </JackpotButton>
          <ContentText>
            <Text1>{t("super mystery box")}</Text1>
            <Text2>{t("Win up")}</Text2>
            <BoxButton>
              {profile.level >= props.levellimit ? (
                <Button
                  label={t("get")}
                  width={
                    window.innerHeight / window.innerWidth >
                    config.verticalSizeiphone
                      ? "21vw"
                      : window.innerHeight > window.innerWidth
                      ? "16vw"
                      : window.innerWidth / window.innerHeight > 2.15
                      ? "9vw"
                      : "9vw"
                  }
                  fontSize={
                    window.innerHeight / window.innerWidth >
                    config.verticalSizeiphone
                      ? "2.5vw"
                      : window.innerHeight > window.innerWidth
                      ? "1.7vw"
                      : window.innerWidth / window.innerHeight > 2.15
                      ? "1.15vw"
                      : "1.15vw"
                  }
                  style={{ padding: 0 }}
                  onClick={() => {
                    dispatch(actionClickSound());
                    // setLoad(true);
                    socket.emit(
                      "getrewarddailylogin",
                      {
                        day: props.day, //วันที่
                      },
                      (data) => {
                        if (typeof data != "undefined") {
                          if (data.status == true) {
                            console.log("getrewarddailylogin", data);
                            setReward(data.results.reward_max);
                            props.items.filter(
                              (item) => item.day == props.day
                            )[0].status = 2;
                          } else if (data.status === false) {
                            console.log("status", data);
                            setEmitError({
                              status: true,
                              message: data.errorCode,
                            });
                            setTimeout(() => {
                              setEmitError({ status: false });
                            }, 3000);
                          }
                        }
                        // setLoad(false);
                        setGetReward(true);
                      }
                    );
                  }}
                />
              ) : (
                <Button
                  label={t("get")}
                  bClass="rbGray"
                  width={
                    window.innerHeight / window.innerWidth >
                    config.verticalSizeiphone
                      ? "21vw"
                      : window.innerHeight > window.innerWidth
                      ? "16vw"
                      : window.innerWidth / window.innerHeight > 2.15
                      ? "9vw"
                      : "9vw"
                  }
                  fontSize={
                    window.innerHeight / window.innerWidth >
                    config.verticalSizeiphone
                      ? "2.5vw"
                      : window.innerHeight > window.innerWidth
                      ? "1.7vw"
                      : window.innerWidth / window.innerHeight > 2.15
                      ? "1.15vw"
                      : "1.15vw"
                  }
                  style={{ padding: 0 }}
                />
              )}
            </BoxButton>
          </ContentText>
        </Box>
      ) : (
        <>
          <ImageReceived
            src={"../" + config.theme + "/dailyLogin/img_check.png"}
          />
          <TextReceived>
            <h1>{t("received reward")}</h1>
            <span>{t("daily launch tomorrow")}</span>
          </TextReceived>
          <Box style={{ opacity: "10%" }}>
            <JackpotButton>
              {window.innerHeight > window.innerWidth ? (
                <Img
                  src={
                    "../" +
                    config.theme +
                    "/dailyLogin/daily_jackpot_mobile.png"
                  }
                />
              ) : (
                <Img
                  src={"../" + config.theme + "/dailyLogin/img_jackpot_pc.png"}
                />
              )}

              {/* <Content>
                                    <Key src={"../" + config.theme + "/dailytopup/lock.png"} />
                                    <TextLevel>
                                        {t("Required")}
                                    </TextLevel>

                                </Content> */}
            </JackpotButton>
            <ContentText>
              <Text1>{t("super mystery box")}</Text1>
              <Text2>{t("Win up")}</Text2>
              <Day>
                {" "}
                {t("day dailyLogin")} {props.day}
              </Day>
            </ContentText>
          </Box>
        </>
      )}

      <Transition showPop={emitError.status} type="alert">
        <AlertMessage
          // messageBottom={t("okay")}
          message={t(emitError.message)}
          onClick={() => {
            setEmitError({ status: false });
          }}
        />
      </Transition>

      <Transition showPop={getReward} type="alert">
        <SpinWheelResult
          label={t("got a reward")}
          amount={reward}
          closeClick={() => {
            setGetReward(false);
            // setLoad(true)
          }}
        />
      </Transition>
    </>
  );
}
