import { createSlice } from "@reduxjs/toolkit";
export const counterLPSlice = createSlice({
    name: "counterLP",
    initialState: {
        counter: {
            totalWin: 0,
            online: 0,
            game: {},
        },
    },
    reducers: {
        actionCounterLP: (state, action) => {
            state.counter = { ...state.counter, ...action.payload };
        },
    },
});

export const { actionCounterLP } = counterLPSlice.actions;
export const selectCounterLP = (state) => state.counterLP.counter;
export default counterLPSlice.reducer;