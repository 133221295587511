import { createSlice } from "@reduxjs/toolkit";
export const miniGameSlice = createSlice({
  name: "minigame",
  initialState: {
    status: { 
      stageMiniGame:0,
    },
     
  },
  reducers: {
    actionMinigame: (state, action) => {
      state.status = { ...state.status, ...action.payload };
    }, 
  },
});

export const { actionMinigame } = miniGameSlice.actions;
export const selectMiniGame = (state) => state.minigame.status; 
export default miniGameSlice.reducer;