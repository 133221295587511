import React, { useEffect } from "react";
import styled, { css } from "styled-components";
// import Popup from "../FestivalPopup/popup";
import { useTranslation } from "react-i18next";
import { config } from "../../config";
import { useDispatch } from "react-redux";
import {
  actionCloseSound,
  actionWinPopSound,
} from "../../Reducers/userProfileSlice";
const Background = styled.div`
  user-select: none;
  position: fixed;
    width: 126vw;
    height: 100vw;
    top: -27vw;
    left: -33.5vw;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  z-index: 10;
  margin-top: 1.7vw;
  align-items: center;
  justify-content: center;
  z-index: 1200;

`;
const TitleaccessLeft = styled.div` 
${(props) => css`
    background-image: url(${props.imgleft});
   
  `}
  background-size: contain;
    width: 10vw;
    height: 8vw;
    background-repeat: no-repeat;
    position: absolute;
    right:${props => props.lang === "th" ? "36vw" : "38vw"} ;
    top: -3vw;
`;


const TitleaccessRight = styled.div`
position: relative;
${(props) => css`
    background-image: url(${props.imgright});
  `}
  background-size: contain;
    width: 10vw;
    height: 8vw;
    background-repeat: no-repeat;
    position: absolute;
transform: matrix(-1, 0, 0, 1, 0, 0);
top: -3vw;
    left:${props => props.lang === "th" ? "36vw" : "38vw"} ;
`;

const Chinesedetail = styled.img`
  width: 46vw;
  height: 28.5vw;
  margin-top: 0.5vw;
`;

const Popup = styled.div`
position: absolute;
background: #F87D7D;
box-shadow: inset 0px 0px 3px 1px #FFC3C3, inset 0px 0px 20px #FFDEDC;
border-radius: 2vw;
z-index: 999;
width: 52vw;
    height: 38.5vw;
    margin-top: -5vw;
`;

const Title = styled.div`
  user-select: none;
  width: ${(props) =>
    props.header === "header"
      ? `null`
      : ` max-content;
  `};
  margin: ${(props) =>
    props.header === "header"
      ? `0 6vw;`
      : ` 0 auto;
  `};
  color: white;
  font-size: 2vw;
  z-index: 0;
  font-weight: 400;
  text-align: center;
  line-height: 5.1vw;
  padding: 0 9vw;
  background-clip: border-box, border-box, content-box;
  background-size: ${(props) =>
    props.size === "size"
      ? `70vw`
      : ` 8.05vw,
      8.15vw,
      1.52vw;
  `};
  background-position: top 0 left 4vw, top 0 right 4vw, top -0vw right 0;
  background-repeat: no-repeat, no-repeat, repeat-x;
  // background-image:
  //     url('../${config.theme}/popup_title_bg_left.png'),
  //     url('../${config.theme}/popup_title_bg_right.png'),
  //     url('../${config.theme}/popup_title_bg_center.png');

  background-image: ${(props) =>
    props.header === "header"
      ? // `url('../${config.theme}/diamond_shop_title_bg.png')
      // `
      `none`
      : `url('../images/event_val/popup_title_bg_left.png'),
      url('../images/event_val/popup_title_bg_right.png'),
      url('../../images/event_val/popup_title_bg_center.png');
  `};
`;

const Content = styled.div`
position: relative;
text-align: center;
    margin: 0 auto;
top:1vw;

width: 50vw;
    height: 29.5vw;

background: #FFFBD8;
// box-shadow: inset 0px 0px 3px 1px #FF691F, inset 0px 0px 20px rgba(255, 133, 66, 0.63);
box-shadow: inset 0px 0px 3px 4px #FF7C7C, inset 0px 0px 35px #E44444;
border-radius: 2vw;
padding: 1vw 0vw;
`;

const Close = styled.img`
  user-select: none;
  position: absolute;
  width: 4.9vw;
  right: -3.5%;
  top: -4%;
  cursor: pointer;
`;

function Detail(props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionWinPopSound());
  }, []);

  return (
    <>
      <Background>
        <Popup
          lang={i18n.language}
          tranform={1}
          title={t("valentine event")}
          closebuttonRight="-2.3vw"
          closebuttonTop="-2vw"
          // ImgMarginLeft="40.15vw"
          // ImgMarginTopLeft="-2.3vw"
          // ImgMarginRight="-vw"
          // ImgMarginTopRight="-2.3vw"
          closeClick={props.closeClick}
        >
          <TitleaccessRight lang={i18n.language} imgright="../../images/event_val/title_prop.png" />
          <Title>{t("valentine event")}</Title>
          <TitleaccessLeft lang={i18n.language} imgleft="../../images/event_val/title_prop.png" />
          <Close
            src={"../" + config.theme + "/btn_close.png"}
            onClick={() => {
              props.closeClick();
              dispatch(actionCloseSound());
            }}
          ></Close>
          <Content>


            {i18n.language === "th" ? (
              <Chinesedetail src="../../../images/event_val/info_th.png" />
            ) : (
              <Chinesedetail src="../../../images/event_val/info_en.png" />
            )}

          </Content>
        </Popup>

      </Background>

    </>
  );
}

export default Detail;
