import React from "react";
import styled, { css } from "styled-components";
import LanguageItem from "./LanguageItem";
import { useDispatch } from "react-redux";
import { actionClickSound } from "../../Reducers/userProfileSlice";
import { config } from "../../config";

const BG = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 20;
  align-items: center;
  justify-content: center;
  margin: 0%;
`;

const PopMenu = styled.div`
  user-select: none;
  display: flex;
  flex-direction: column;
  // background-image: url("../${config.theme}/popup_frame_mini.png");
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;

  padding: 0vw 2vw 1vw 1vw;

  // theme ==================
  /* background: #192756;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #5DD8FF, inset 0px 0px 4vw rgba(66, 255, 232, 0.63);
  border: solid 0.2vw #BDEFFF; */
  /* padding: 1vw; */
  // theme ==================
  border-radius: 1.5vw;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* width: 17.3913vw; */
  width: 16.5vw;
  background-position: center;
  position: absolute;
  // top: calc(100vw * 0.065);
  justify-content: center;
  /* top: 69%; */
  /* right: -0.5%; */
  z-index: 50;
  padding-top: 0.8vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 37.5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 23.5vw;
          /* height: 40%;
          left: 55%; */
          justify-content: center;
        `}
`;

const Close = styled.img`
  user-select: none;
  position: absolute;
  width: 3.9vw;
  z-index: 51;
  top: -1.5vw;
  right: -1.5vw;
  cursor: pointer;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 6.9vw;
        `
      : window.appHeight() > window.innerWidth && css``}
`;

function Language(props) {
  const dispatch = useDispatch();

  return (
    <BG>
      {/* <BGPopup
        
      ></BGPopup> */}
      <PopMenu active={props.status}>
        <Close
          src={"../" + config.theme + "/btn_close.png"}
          onClick={() => {
            props.setShowLanguagePop(false);
            dispatch(actionClickSound());
          }}
        />
        <LanguageItem
          icon="flag_icon_fit.png"
          label="English"
          lang="en"
          {...props}
        />
        <LanguageItem
          icon="flag_icon_th_fit.png"
          label="ไทย"
          lang="th"
          {...props}
        />
        <LanguageItem
          icon="flag_icon_cn_fit.png"
          label="中国"
          lang="cn"
          {...props}
        />
        <LanguageItem
          icon="flag_icon_id_fit.png"
          label="Bahasa Indonesia"
          lang="id"
          {...props}
        />
        <LanguageItem
          icon="flag_icon_vn_fit.png"
          label="Vietnam"
          lang="vn"
          {...props}
        />
        {/* <LanguageItem
          icon="flag_icon_my_fit.png"
          label="มาเลเซีย"
          lang="mal"
          {...props}
        /> */}
      </PopMenu>
    </BG>
  );
}

export default Language;
