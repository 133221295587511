import React from "react";
import { useTranslation } from "react-i18next";
// import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { CSSTransition } from "react-transition-group";
import "./animation.css";
import { config } from "../../config";

const MenuItemBox = styled.button`
  // float: left;
  user-select: none;
  // start clear defualt button style
  border: none;
  background-color: transparent;
  :focus {
    outline: none;
  }
  // end clear defualt button style
  cursor: pointer;
  // /* font-weight: 400; */
  text-align: center;
  font-family: "Prompt", sans-serif;
  padding: 0 0.75vw;
  position: relative;
  // background: rgba(255,128,230,0.4);
  h1 {
    display: flex;
    align-items: center;
    font-size: ${(props) => (props.lang === "en" ? "1.3vw" : "1vw")};
    font-weight: 400;
    /* white-space: nowrap; */
    text-align: left;
    line-height: 1;
    color: rgba(255, 255, 255, 0.7);
    > i {
      font-size: 2vw;
      padding-right: 1vw;
      color: white;
    }
    > i::before {
      width: max-content;
      margin-right: 0;
      margin-left: 0;
      color: white;
    }
  }
  ${(props) =>
    props.active
      ? css`
          h1 {
            color: #ff4c00;
            > i::before {
              color: #ff4c00;
            }
          }
        `
      : css`
          color: white;
        `}
  //Portrait
  @media (orientation: portrait) {
    z-index: 10;
    height: 14vw;
    h1 {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: ${(props) => (props.lang === "en" ? "2.65vw" : "2.5vw")};
      font-weight: 400;
      white-space: unset;
      line-height: 3vw;
      // padding-right: 1vw;
      > i {
        font-size: 5.14vw;
        padding-right: 0vw;
        color: white;
      }
    }
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    z-index: 10;
    height: 10vw;
    h1 {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: ${(props) => (props.lang === "en" ? "2vw" : "1.5vw")};
      font-weight: 400;
      white-space: unset;
      line-height: 2vw;
      > i {
        font-size: 5vw;
        padding-right: 0vw;
        color: white;
      }
    }
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          z-index: 10;
          height: 14vw;
          h1 {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: ${(props) => (props.lang === "en" ? "2.65vw" : "2.5vw")};
            font-weight: 400;
            white-space: unset;
            line-height: 3vw;
            > i {
              font-size: 5.14vw;
              padding-right: 0vw;
              color: white;
            }
          }
        `
      : window.appHeight() > window.innerWidth &&
        css`
          height: 10vw;
          h1 {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: ${(props) => (props.lang === "en" ? "2vw" : "1.5vw")};
            font-weight: 400;
            white-space: unset;
            line-height: 2vw;
            > i {
              font-size: 5vw;
              padding-right: 0vw;
              color: white;
            }
          }
          z-index: 10;
        `} */
`;

const Text = styled.div`
  width: ${(props) => (props.lang === "id" ? "min-content" : null)} !important;
  line-break: ${(props) => (props.lang === "id" ? "strict" : null)} !important;
  white-space: ${(props) => (props.lang === "id" ? "none" : null)} !important;
`;

function MenuItem(props) {
  const { t, i18n } = useTranslation();
  return (
    <>
      <CSSTransition in={props.active} timeout={1000} classNames="fadeSubNav">
        <MenuItemBox
          {...props}
          active={props.active}
          // style={{
          //   top:
          //     window.appHeight() / window.innerWidth > config.verticalSizeiphone
          //       ? `calc(${window.appHeight() * 0.98}px - 31.6vw)`
          //       : window.appHeight() > window.innerWidth
          //         ? `calc(${window.appHeight() * 1.05}px - 31.6vw)`
          //         : "",
          // }}
          lang={i18n.language}
        >
          <h1>
            <i className={"icon-ico_" + props.icon} />
            <Text lang={i18n.language}>{t(props.label)}</Text>
          </h1>
        </MenuItemBox>
      </CSSTransition>
    </>
  );
}

export default MenuItem;
