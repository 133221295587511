import styled, { css, keyframes } from "styled-components";
import PopupMenuItem from "./PopupMenuItem";
import LanguagePop from "../../Language";
import { useState, lazy, useContext } from "react";
import GuestBankPopup from "../../GuestBankPopup";
import { useTranslation } from "react-i18next";
import {
  selectuserProfile,
  actionClickSound,
  actionSetprofile,
} from "../../../Reducers/userProfileSlice";
import { useSelector, useDispatch } from "react-redux";

import { actionPopup, selectPopup } from "../../../Reducers/popupSlice";
import { rubberBand } from "react-animations";
import { SocketContext } from "../../../context/socket"; //socket (1)
// import GuestDetect from "../../Register/GuestDetect";
import { config, Transition } from "../../../config";
import Complaint from "../../ComplaintForm";
import AlertMessage from "../../Dialog/AlertMessage";

const TutorialAnimation = keyframes`${rubberBand}`;
const Transaction = lazy(() => import("../../TransactionHistory"));
const SideBar = lazy(() => import("../../SideBar"));
const GuestTelPopup = lazy(() => import("../../GuestTelPopup"));
const ChangePinCode = lazy(() => import("../../ChangePinCode.js"));
const ChangePassword = lazy(() => import("../../ChangePassword"));
const ChangePasswordPopUp = lazy(() => import("../../GuestChangePassword"));
const ChangePinCodePopUp = lazy(() => import("../../GuestChangePassword"));
const Logout = lazy(() => import("../../Logout"));
const Setting = lazy(() => import("../../Setting"));
const InviteFriend = lazy(() => import("../../InviteFriend"));
const BankAccount = lazy(() => import("../../Register/BankAccount"));
const LossesBonus = lazy(() => import("../../LossesReturn/index"));
const Support = lazy(() => import("../../support"));
// const ForgotPassWord = lazy(() =>
//   import("../../Login/Forgotpassword")
// );

const PopupBox = styled.div`
  user-select: none;
  align-self: center;
  display: flex;
  flex-direction: row;
  position: relative;

  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    left: -10vw;
  }

  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css``
      : window.appHeight() > window.innerWidth &&
        css`
          left: -10vw;
        `} */
`;

const TutorialArrow = styled.img`
  position: absolute;
  width: 7vw;
  top: 96%;
  z-index: 10;
  animation: ${TutorialAnimation} 1.5s linear infinite;

  @media (orientation: portrait) {
    width: 12vw;
    top: 97%;
  }

  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    width: 9vw;
  }

  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 12vw;
          top: 97%;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 9vw;
        `} */
`;

const TutorialBox = styled.div`
  pointer-events: none;
  width: 123%;
  height: 100%;
  bottom: -9%;
  position: absolute;

  border: 0.5vw solid;
  resize: both;
  box-shadow: 0px 0px 50px 20px rgba(0, 0, 0, 0.8);
  //background: #ffd73e33;
  border-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cstyle%3Epath%7Banimation:stroke 1.5s infinite linear%3B%7D%40keyframes stroke%7Bto%7Bstroke-dashoffset:776%3B%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='180%25' y1='0%25' x2='80%25' y2='100%25'%3E%3Cstop offset='0%25' stop-color='%23d02d07' /%3E%3Cstop offset='25%25' stop-color='%23ff5400' /%3E%3Cstop offset='50%25' stop-color='%23ff7e00' /%3E%3Cstop offset='100%25' stop-color='%23ffea00' /%3E%3C/linearGradient%3E %3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='20' stroke-dasharray='193.6'/%3E %3C/svg%3E")
    1;

  @media only screen and (max-width: 480px) and (orientation: portrait) {
    width: 123%;
    height: 100%;
    bottom: -9%;
  }

  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 123%;
          height: 100%;
          bottom: -9%;
        `
      : window.appHeight() > window.innerWidth && css``} */
`;

const tutorialinfo = keyframes`
    0% { border-left: solid 0.4vw #FF691F; }
    100% {border-left: solid 0.4vw yellow;}
`;
const tutorialinfomobile = keyframes`
    0% { border-left: solid 1vw #FF691F; }
    100% {border-left: solid 1vw yellow;}
`;
const TutorialInfo = styled.div`
  background-color: red;
  // max-width: 15vw;
  // min-width: 4vw;
  max-width: 200%;
  min-width: 110%;
  width: max-content;
  height: max-content;
  color: white;
  border-radius: 1vw;
  position: absolute;
  padding: 1vw 1.6vw;
  line-height: 1.4vw;
  top: 161%;
  z-index: 1;
  text-align: left;
  // box-shadow: 0px 0px 50px 20px rgba(0, 0, 0, 0.8);
  // background: #302e3c;
  // box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  // border: solid 0.2vw orange;

  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;

  h1 {
    font-size: 1.5vw;
    font-weight: 400;
    border-left: solid 0.4vw #ff691f;
    padding-left: 0.5vw;
    margin-bottom: 0.5vw;
    line-height: 1.9vw;
    animation-name: ${tutorialinfo};
    animation-duration: 0.2s;
    animation-timing-function: linear;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    // background: red;
  }
  p {
    font-size: 1.2vw;
    color: rgba(255, 255, 255, 0.7);
    margin-left: 1vw;
  }

  @media (orientation: portrait) {
    max-width: 270%;
    padding: 2.5vw 1.6vw;
    top: 171%;

    h1 {
      font-size: 3vw;
      line-height: 3vw;
      animation-name: ${tutorialinfomobile};
    }
    p {
      line-height: 2.5vw;
      font-size: 2.5vw;
    }
  }

  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    max-width: 310%;
    top: 176%;
    h1 {
      font-size: 3vw;
      line-height: 3vw;
      animation-name: ${tutorialinfomobile};
    }
    p {
      line-height: 2.5vw;
      font-size: 1.8vw;
    }
  }

  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          max-width: 270%;
          padding: 2.5vw 1.6vw;
          top: 171%;

          h1 {
            font-size: 3vw;
            line-height: 3vw;
            animation-name: ${tutorialinfomobile};
          }
          p {
            line-height: 2.5vw;
            font-size: 2.5vw;
          }
        `
      : window.appHeight() > window.innerWidth &&
        css`
          max-width: 310%;
          top: 176%;
          h1 {
            font-size: 3vw;
            line-height: 3vw;
            animation-name: ${tutorialinfomobile};
          }
          p {
            line-height: 2.5vw;
            font-size: 1.8vw;
          }
        `} */
`;

const Box = styled.div`
  position: relative;
  width: 6.6vw;

  @media (orientation: portrait) {
    width: 8.5vw;
  }

  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    width: 6vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          // width: 17.0063vw;
          width: 8.5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          // width: 11.0063vw;
          width: 6vw;
        `} */
`;

export default function PopupMenu(props) {
  const { t } = useTranslation();
  const popupStatus = useSelector(selectPopup);
  const [showLanguagePop, setShowLanguagePop] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [popup, setPopup] = useState({
    tranStatus: false,
    settingStatus: false,
    changeStatus: false,
    changePassword: false,
    changeGuestStatus: false,
    logoutStatus: false,
    supportStatus: false,
    guestTelPopup: false,
    forgotPassWord: false,
    bankAccountStatus: false,
    guestBankPopup: false,
    complaintPopup: false,
  });
  // const [tranStatus, setTranStatus] = useState(false);
  const dispatch = useDispatch();
  const profileIndex = useSelector(selectuserProfile);
  const [emitError, setEmitError] = useState({ status: false, message: "" });
  const socket = useContext(SocketContext); //ใช้ socket (2)
  // const [guestPopup, setGuestPopup] = useState(false);
  let guestuser = useSelector(selectuserProfile);
  // const [guestDetect, setGuestDetect] = useState(false);
  const [bankAccount, setBankAccount] = useState(false);
  // const [guestToBankAccount, setGuestToBankAccount] = useState(false);
  const [lossesBonus, setLossesBonus] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(true);
  // const [load, setLoad] = useState(false);
  const [transacFromLoss, setTransacFromLoss] = useState(false);
  const [transacFromInv, setTransacFromInv] = useState(false);

  return (
    <>
      <Transition
        in={showLanguagePop}
        timeout={300}
        classNames="st2b"
        unmountOnExit
      >
        <LanguagePop setShowLanguagePop={setShowLanguagePop} />
      </Transition>

      <PopupBox>
        {profileIndex.proInvFriend === 1 || profileIndex.proAffiliate === 1 ? (
          guestuser.isTelGuest === true && guestuser.isguest === false ? (
            <PopupMenuItem
              navbar
              icon="icon_inviteF.png"
              label="referral bonus icon"
              onClick={() => {
                dispatch(actionPopup({ inviteFriend: true }));
                dispatch(actionClickSound());
              }}
              active={
                (profileIndex.bonusinvfriend > 0 &&
                  profileIndex.proInvFriend === 1) ||
                (profileIndex.affiliate > 0 && profileIndex.proInvFriend === 1)
                // profileIndex.cashback > 0
              }
            >
              {profileIndex.tutorial === 12 ? (
                <>
                  <TutorialArrow
                    src={"../" + config.theme + "/ftue_arrow_up.png"}
                  />
                  <TutorialBox />
                  <TutorialInfo>
                    <h1>{t("FTUE invite friend title")}</h1>
                    <p>{t("FTUE invite friend info")}</p>
                  </TutorialInfo>
                </>
              ) : (
                ""
              )}
            </PopupMenuItem>
          ) : guestuser.isTelGuest === false && guestuser.isguest === true ? (
            <PopupMenuItem
              navbar
              icon="icon_inviteF.png"
              label="referral bonus icon"
              onClick={() => {
                // setInVite(true)
                dispatch(actionPopup({ guestDetect: true }));
                dispatch(actionClickSound());
              }}
              active={
                (profileIndex.bonusinvfriend > 0 &&
                  profileIndex.proInvFriend === 1) ||
                (profileIndex.affiliate > 0 && profileIndex.proInvFriend === 1)
                // profileIndex.cashback > 0
              }
            >
              {profileIndex.tutorial === 12 ? (
                <>
                  <TutorialArrow
                    src={"../" + config.theme + "/ftue_arrow_up.png"}
                  />
                  <TutorialBox />
                  <TutorialInfo>
                    <h1>{t("FTUE invite friend title")}</h1>
                    <p>{t("FTUE invite friend info")}</p>
                  </TutorialInfo>
                </>
              ) : (
                ""
              )}
            </PopupMenuItem>
          ) : guestuser.isTelGuest === true && guestuser.isguest === true ? (
            <PopupMenuItem
              navbar
              icon="icon_inviteF.png"
              label="referral bonus icon"
              onClick={() => {
                // setInVite(true)
                // setBankAccount(true);
                setPopup({ ...popup, guestBankPopup: true });
                dispatch(actionClickSound());
              }}
              active={
                (profileIndex.bonusinvfriend > 0 &&
                  profileIndex.proInvFriend === 1) ||
                (profileIndex.affiliate > 0 && profileIndex.proInvFriend === 1)
                // profileIndex.cashback > 0
              }
            >
              {profileIndex.tutorial === 12 ? (
                <>
                  <TutorialArrow
                    src={"../" + config.theme + "/ftue_arrow_up.png"}
                  />
                  <TutorialBox />
                  <TutorialInfo>
                    <h1>{t("FTUE invite friend title")}</h1>
                    <p>{t("FTUE invite friend info")}</p>
                  </TutorialInfo>
                </>
              ) : (
                ""
              )}
            </PopupMenuItem>
          ) : (
            <PopupMenuItem
              navbar
              icon="icon_inviteF.png"
              label="referral bonus icon"
              active={
                (profileIndex.bonusinvfriend > 0 &&
                  profileIndex.proInvFriend === 1) ||
                (profileIndex.affiliate > 0 && profileIndex.proInvFriend === 1)
                // profileIndex.cashback > 0
              }
            >
              {profileIndex.tutorial === 12 ? (
                <>
                  <TutorialArrow
                    src={"../" + config.theme + "/ftue_arrow_up.png"}
                  />
                  <TutorialBox />
                  <TutorialInfo>
                    <h1>{t("FTUE invite friend title")}</h1>
                    <p>{t("FTUE invite friend info")}</p>
                  </TutorialInfo>
                </>
              ) : (
                ""
              )}
            </PopupMenuItem>
          )
        ) : (
          ""
        )}

        {profileIndex.proCashback === 1 ||
        profileIndex.proCommission === 1 ||
        profileIndex.proCashPoint === 1 ? (
          guestuser.isTelGuest === true && guestuser.isguest === false ? (
            <PopupMenuItem
              navbar
              icon="icon_getmoney.png"
              label="cash back icon"
              onClick={() => {
                setLossesBonus(true);
                dispatch(actionClickSound());
                setRefreshTrigger(!refreshTrigger);
                // setLoad(true);
                socket.emit("checkcashback", {}, function (data) {
                  // setLoad(false);
                  // setTimeout(() => {
                  //   setLoad(false);
                  // }, 2000);
                  console.log("checkcashback", data);
                  if (
                    typeof data === "undefined" ||
                    typeof data.results === "undefined" ||
                    typeof data.results.data === "undefined"
                  ) {
                  } else {
                    if (data.status) {
                      dispatch(
                        actionSetprofile(
                          data.results.data
                          // JSON.parse(decode(data.results.data.jwtToken.split(".")[1]))
                        )
                      );
                    } else if (data.status === false) {
                      setEmitError({ status: true, message: data.errorCode });
                      setTimeout(() => {
                        setEmitError({ status: false });
                      }, 3000);
                    }
                  }
                });
              }}
              active={
                // profileIndex.cashback > 0 || profileIndex.cashback > 0
                (profileIndex.cashback > 0 && profileIndex.proCashback === 1) ||
                (profileIndex.commission > 0 &&
                  profileIndex.proCommission === 1) ||
                (profileIndex.cashpoint > 0 && profileIndex.proCashPoint === 1)
                // profileIndex.cashback > 0
              }
            ></PopupMenuItem>
          ) : guestuser.isTelGuest === false && guestuser.isguest === true ? (
            <PopupMenuItem
              navbar
              icon="icon_getmoney.png"
              label="cash back icon"
              onClick={() => {
                // setInVite(true)
                dispatch(actionPopup({ guestDetect: true }));
                dispatch(actionClickSound());
              }}
              active={
                // profileIndex.cashback > 0 || profileIndex.cashback > 0
                (profileIndex.cashback > 0 && profileIndex.proCashback === 1) ||
                (profileIndex.commission > 0 &&
                  profileIndex.proCommission === 1) ||
                (profileIndex.cashpoint > 0 && profileIndex.proCashPoint === 1)
                // profileIndex.cashback > 0
              }
            ></PopupMenuItem>
          ) : guestuser.isTelGuest === true && guestuser.isguest === true ? (
            <PopupMenuItem
              navbar
              icon="icon_getmoney.png"
              label="cash back icon"
              onClick={() => {
                // setInVite(true)
                // setBankAccount(true);
                setPopup({ ...popup, guestBankPopup: true });
                dispatch(actionClickSound());
              }}
              active={
                // profileIndex.cashback > 0 || profileIndex.cashback > 0
                (profileIndex.cashback > 0 && profileIndex.proCashback === 1) ||
                (profileIndex.commission > 0 &&
                  profileIndex.proCommission === 1) ||
                (profileIndex.cashpoint > 0 && profileIndex.proCashPoint === 1)
                // profileIndex.cashback > 0
              }
            ></PopupMenuItem>
          ) : (
            <PopupMenuItem
              navbar
              icon="icon_getmoney.png"
              label="cash back icon"
              active={
                // profileIndex.cashback > 0 || profileIndex.cashback > 0
                (profileIndex.cashback > 0 && profileIndex.proCashback === 1) ||
                (profileIndex.commission > 0 &&
                  profileIndex.proCommission === 1) ||
                (profileIndex.cashpoint > 0 && profileIndex.proCashPoint === 1)
                // profileIndex.cashback > 0
              }
            ></PopupMenuItem>
          )
        ) : (
          <Box />
        )}

        {/* <PopupMenuItem
          onClick={() => {
            setLossesBonus(true);
            dispatch(actionClickSound());
          }}
          icon="icon_getmoney.png"
          label="cash back"
          active={profileIndex.cashback > 0}
        // style={{ visibility: "hidden" }}
        /> */}

        <PopupMenuItem
          navbar
          icon="icon_menu_second.png"
          onClick={() => {
            setSidebar(true);
            dispatch(actionClickSound());
          }}
          // active={
          //   profileIndex.bonusinvfriend > 0 || profileIndex.affiliate > 0
          //   // profileIndex.cashback > 0
          // }
        />
      </PopupBox>

      {/* <Transition showPop={logoutStatus} type="st2b">
                <Logout closeClick={() => { setLogoutStatus(false) }} />
            </Transition> */}

      <Transition showPop={sidebar} type="sr2l">
        <SideBar
          popup={popup}
          setPopup={setPopup}
          closeClick={() => {
            setSidebar(false);
          }}
        />
      </Transition>

      <Transition showPop={popup.tranStatus} type="st2b">
        <Transaction
          transacFromLoss={transacFromLoss}
          setTransacFromLoss={setTransacFromLoss}
          transacFromInv={transacFromInv}
          setTransacFromInv={setTransacFromInv}
          closeClick={() => {
            setPopup({ ...popup, tranStatus: false });
          }}
        />
      </Transition>

      <Transition showPop={popupStatus.inviteFriend} type="st2b">
        <InviteFriend
          setTransacFromInv={setTransacFromInv}
          setPopup={setPopup}
          closeClick={() => {
            dispatch(actionPopup({ inviteFriend: false }));
          }}
        />
      </Transition>

      <Transition showPop={lossesBonus} type="st2b">
        <LossesBonus
          setTransacFromLoss={setTransacFromLoss}
          setPopup={setPopup}
          closeClick={() => {
            setLossesBonus(false);
          }}
        />
      </Transition>

      <Transition showPop={popup.settingStatus} type="st2b">
        <Setting
          closeClick={() => {
            setPopup({ ...popup, settingStatus: false });
          }}
        />
      </Transition>

      {/* <Transition showPop={popup.settingStatus} type="st2b">
        <Setting
          closeClick={() => {
            setPopup({ ...popup, settingStatus: false });
          }}
        />
      </Transition> */}

      <Transition showPop={popup.guestTelPopup} type="alert">
        <GuestTelPopup
          cancelClick={() => {
            setPopup({ ...popup, guestTelPopup: false });
          }}
          // confirmClick={() => {
          //   setGuestPopup(false);
          //   setPopup({ ...popup, guestTelPopup: false });
          // }}
        >
          {t("you have not yet subscribed")}
        </GuestTelPopup>
      </Transition>

      <Transition showPop={popup.changeGuestPassword} type="alert">
        <ChangePasswordPopUp
          cancelClick={() => {
            setPopup({ ...popup, changeGuestPassword: false });
          }}
          // confirmClick={() => {
          //   setGuestPopup(false);
          // }}
        >
          {t("registration is required for accessing a password change")}
        </ChangePasswordPopUp>
      </Transition>

      <Transition showPop={popup.changeGuestStatus} type="alert">
        <ChangePinCodePopUp
          cancelClick={() => {
            setPopup({ ...popup, changeGuestStatus: false });
          }}
          // confirmClick={() => {
          //   setGuestPopup(false);
          // }}
        >
          {t("registration is required for accessing a PIN code change")}
        </ChangePinCodePopUp>
      </Transition>

      {/* ChangePinCode */}
      <Transition showPop={popup.changeStatus} type="st2b">
        {/* <ChangePinCode
          closeClick={() => {
            setPopup({ ...popup, changeStatus: false });
          }}
        /> */}
        <Support
          closeClick={() => {
            setPopup({ ...popup, changeStatus: false });
          }}
          pincode={true}
        />
      </Transition>
      {/* ChangePassword */}
      <Transition showPop={popup.changePassword} type="st2b">
        <ChangePassword
          closeClick={() => {
            setPopup({ ...popup, changePassword: false });
          }}
        />
      </Transition>

      <Transition showPop={popup.logoutStatus} type="st2b">
        <Logout
          closeClick={() => {
            setPopup({ ...popup, logoutStatus: false });
          }}
        />
      </Transition>

      <Transition showPop={popup.logoutStatus} type="st2b">
        <Logout
          closeClick={() => {
            setPopup({ ...popup, logoutStatus: false });
          }}
        />
      </Transition>

      <Transition showPop={popup.supportStatus} type="st2b">
        <Support
          closeClick={() => {
            setPopup({ ...popup, supportStatus: false });
          }}
        />
      </Transition>

      <Transition showPop={popup.complaintPopup} type="st2b">
        <Complaint
          closeClick={() => {
            setPopup({ ...popup, complaintPopup: false });
          }}
        />
      </Transition>

      {/* <Transition showPop={guestDetect || popupStatus.guestDetect} type="st2b">
        <GuestDetect
          closeClick={() => {
            setGuestDetect(false);
            dispatch(actionPopup({ guestDetect: false }));
          }}
        />
      </Transition> */}
      {/* 
       <Transition showPop={popup.guestBankPopup} type="alert">
        <GuestBankAccount
          cancelClick={() => {
            setPopup({ ...popup, guestBankPopup: false });
          }}
          confirmClick={() => {
            setGuestToBankAccount(false);
          }}
        >
          {t("guest alert invitebankaccount")}
        </GuestBankAccount>
      </Transition>   */}

      <Transition showPop={popup.guestBankPopup} type="alert">
        <GuestBankPopup
          cancelClick={() => {
            setPopup({ ...popup, guestBankPopup: false });
          }}
          // confirmClick={() => {
          //   setGuestPopup(false);
          //   setPopup({ ...popup, guestBankPopup: false });
          // }}
        >
          {t("guest alert invitebankaccount")}
        </GuestBankPopup>
      </Transition>

      {/* <Transition showPop={guestToBankAccount || popupStatus.guestToBankAccount} type="st2b">
        <GuestBankAccount
          closeClick={() => {
            setGuestToBankAccount(false);
            dispatch(actionPopup({ guestToBankAccount: false }));
          }}
        />
      </Transition> */}
      <Transition
        showPop={bankAccount || popupStatus.bankAccountStatus}
        type="st2b"
      >
        <BankAccount
          closeClick={() => {
            setBankAccount(false);
            dispatch(actionPopup({ bankAccountStatus: false }));
          }}
        />
      </Transition>

      <Transition showPop={emitError.status} type="alert">
        <AlertMessage
          message={t(emitError.message)}
          onClick={() => {
            setEmitError({ status: false });
          }}
        />
      </Transition>
      {/* <Transition showPop={guestBankAccount || popupStatus.guestBankAccount} type="st2b">
        <GuestBankAccount
          closeClick={() => {
            setBankAccount(false);
            dispatch(actionPopup({ guestBankAccount: false }));
          }}
        />
      </Transition> */}
    </>
  );
}
