import React, { useState, useEffect, useContext } from "react";
import styled, { css } from "styled-components";
import { config } from "../../config";
import {
  selectuserProfile,
  actionSetprofile,
  actionClickSound,
} from "../../Reducers/userProfileSlice";
import { useTranslation } from "react-i18next";
import {
  SocketContext,
  socket,
  SocketKafkaContext,
} from "../../context/socket";
import { useDispatch, useSelector } from "react-redux";
import DailyItem from "./DailyItem";
import JackpotItem from "./JackpotItem";
import CountDownNextDay from "../CountDownNextDay";
import { timer } from "../../Reducers/countDown";
import {
  actionPopup,
  actionUpdatePopup,
  selectPopupList,
} from "../../Reducers/popupSlice";
import Loading from "../Loading";
import { Transition } from "../../config";
import AlertMessage from "../Dialog/AlertMessage";
const BG = styled.div`
  user-select: none;
  position: fixed;
  width: 100%;
  height: 100%; //fix
  top: 0%;
  left: 0%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 70;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css``
      : window.innerHeight > window.innerWidth && css``}
`;

const Popup = styled.div`
  user-select: none;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background: #040b17;
  border: 3.5px solid #e7ce5c;
  box-shadow: inset 0px 0px 15px #e7ce5c;
  border-radius: 2vw;
  position: relative;
  z-index: 20;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  padding: 0 2vw;
  width: 72vw;
  height: 40vw;

  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 88vw;
          height: 152vw;
          border-radius: 4vw;
        `
      : window.innerHeight > window.innerWidth &&
        css`
          width: 65vw;
          height: 95vw;
          display: flow-root;
        `}
`;

const Position = styled.div`
  position: relative;
  top: -5vw;
`;

const Title = styled.img`
  user-select: none;
  position: relative;
  width: 70vw;
  top: -4vw;
  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 104vw;
          top: -6vw;
          left: -8vw;
        `
      : window.innerHeight > window.innerWidth &&
        css`
          width: 81vw;
          top: -4vw;
          left: -8vw;
        `}
`;

const TextTitle = styled.div`
  position: absolute;
  font-size: 1.85vw;
  color: white;
  width: 93%;
  justify-content: center;
  align-items: center;
  display: flex;
  top: -1vw;
  font-weight: 600;
  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 96%;
          font-size: 2.5vw;
          top: -2vw;
        `
      : window.innerHeight > window.innerWidth &&
        css`
          width: 94%;
          font-size: 2vw;
          top: -1vw;
        `}
`;

const Close = styled.img`
  user-select: none;
  position: absolute;
  width: 4vw;
  height: 4vw;
  right: -2.5%;
  top: -1.5vw;
  cursor: pointer;
  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 8.5vw;
          height: 8.5vw;
          top: -4vw;
        `
      : window.innerHeight > window.innerWidth &&
        css`
          width: 6vw;
          height: 6vw;
          right: -3.5%;
          top: -2.5vw;
        `}
`;

const TextHeader = styled.div`
  position: relative;
  color: #ffeb8d;
  text-shadow: 0px 0px 4px rgba(255, 235, 141, 0.5);
  display: flex;
  font-size: 1.5vw;
  font-style: italic;
  font-weight: 700;
  width: 36vw;

  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          justify-content: center;
          font-size: 3vw;
          width: 80vw;
          top: -3vw;
        `
      : window.innerHeight > window.innerWidth &&
        css`
          width: 45vw;
          justify-content: center;
          height: 7vw;
          line-height: 2.4vw;
          font-size: 2vw;
          text-align: center;
        `}
`;

const Box = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-wrap: wrap;
  /* width: 81%; */
  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          justify-content: center;
        `
      : window.innerHeight > window.innerWidth &&
        css`
          justify-content: center;
          top: -1vw;
        `}
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  top: 2vw;
  width: 66vw;
  /* padding: 0vw 1vw 0vw 1vw; */
  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          flex-direction: column;
          top: 0vw;
          width: auto;
        `
      : window.innerHeight > window.innerWidth &&
        css`
          flex-direction: column;
          top: 0vw;
          width: auto;
        `}
`;

const Time = styled.div`
  position: relative;
  background: #010306;
  border: 2.8px solid #18253c;
  border-radius: 5vw;
  width: 35vw;
  height: 3vw;
  display: flex;
  align-items: center;
  h2 {
    font-size: 1.4vw;
    position: relative;
    color: rgba(127, 123, 149, 1);
    padding: 0vw 0vw 0vw 2vw;
    font-weight: lighter;
  }
  span {
    color: white;
    font-size: 1.5vw;
    padding: 0vw 0vw 0vw 1vw;
    position: relative;
  }
  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          border-radius: 4vw;
          width: 85vw;
          height: 8vw;
          justify-content: center;
          top: 1vw;
          h2 {
            font-size: 3.2vw;
          }
          span {
            font-size: 3vw;
          }
        `
      : window.innerHeight > window.innerWidth &&
        css`
          width: 53vw;
          height: 5vw;
          justify-content: center;
          h2 {
            font-size: 2.2vw;
          }
          span {
            font-size: 2vw;
          }
        `}
`;

const Content = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          flex-direction: column;
          align-items: center;
        `
      : window.innerHeight > window.innerWidth &&
        css`
          flex-direction: column;
          align-items: center;
        `}
`;

const Noti = styled.div`
  display: flex;
  position: relative;
  top: 3vw;
  padding-left: 2vw;
  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          top: 32vw;
        `
      : window.innerHeight > window.innerWidth &&
        css`
          padding-left: 3vw;
          top: 18vw;
        `}
`;

const SymbolNoti = styled.div`
  position: relative;
  background-color: rgba(255, 105, 31, 1);
  width: 3vw;
  height: 1vw;
  border-radius: 3vw;
  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 6vw;
          height: 2.5vw;
        `
      : window.innerHeight > window.innerWidth &&
        css`
          width: 4vw;
          height: 1.5vw;
        `}
`;

const TextNoti = styled.div`
  position: relative;
  color: rgba(127, 123, 149, 1);
  font-weight: 100;
  font-size: 1.2vw;
  display: flex;
  text-align: start;
  width: 90%;
  line-height: 1.5vw;
  padding-left: 1vw;
  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2.7vw;
          line-height: 3.2vw;
        `
      : window.innerHeight > window.innerWidth &&
        css`
          line-height: 2vw;
          font-size: 1.7vw;
          width: 86%;
        `}
`;

export default function Index(props) {
  const { t } = useTranslation();
  const [emitError, setEmitError] = useState({ status: false, message: "" });
  const countDown = useSelector(timer);
  const popupList = useSelector(selectPopupList);
  const profile = useSelector(selectuserProfile);
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    setLoad(true);
    socket.emit("getdailylogin", {}, (data) => {
      console.log("getdailylogin", data);
      if (data.status === true) {
        console.log("getdailylogin status true:", data);
        setLoad(false);
        setItems(data.results.daily_login);
      } else if (data.status === false) {
        setEmitError({ status: true, message: data.errorCode });
      }
    });
  }, []);

  const setDailyLogin = () => {
    // if (profile.popup.filter((a) => a.no == 1).length > 0) {
    //     dispatch(actionUpdatePopup(""));
    // }
    //dispatch(actionSetprofile({ popup: popupList }));
    dispatch(actionPopup({ dailyLogin: false }));
  };

  // console.log("props", items)

  // console.log("load", load)/

  return (
    <>
      <BG>
        <Popup>
          <Title src={"../" + config.theme + "/diamond_shop_title_bg.png"} />
          <TextTitle>{t("mission daily")}</TextTitle>
          <Close
            src={"../" + config.theme + "/btn_close.png"}
            onClick={() => {
              setDailyLogin();
              console.log("setDailyLogin");
            }}
          />
          <Position>
            <Content>
              <TextHeader>{t("mission daily des")}</TextHeader>
              {window.innerHeight > window.innerWidth ? (
                ""
              ) : (
                <Time>
                  <h2>{t("time remaining")}</h2>
                  <span>
                    <CountDownNextDay />
                  </span>
                </Time>
              )}
            </Content>
            <Container>
              <Box>
                {/* {items.length != 0 ? items.map((item, index) => (
                                <DailyItem
                                    {...item}
                                // amount={item}
                                // received
                                // // active="T"
                                // onClick={() => {

                                // }}
                                />
                            )) : ""} */}
                {items.length != 0
                  ? items.map((item, index) => {
                      if (index < 6) {
                        return (
                          <DailyItem
                            items={items}
                            {...item}
                            // onClick={GetReward()}
                          />
                        );
                      } else {
                        return (
                          <JackpotItem
                            items={items}
                            {...item}
                            // received
                          />
                        );
                      }
                    })
                  : ""}
              </Box>
              {window.innerHeight > window.innerWidth ? (
                <Time>
                  <h2>{t("time remaining")}</h2>
                  <span>
                    <CountDownNextDay />
                  </span>
                </Time>
              ) : (
                ""
              )}
            </Container>
            {/* <></> */}
            <Noti>
              <SymbolNoti />
              <TextNoti>{t("noti dailyLogin")}</TextNoti>
            </Noti>
          </Position>
        </Popup>
        {load ? <Loading /> : ""}
      </BG>
      <Transition showPop={emitError.status} type="alert">
        <AlertMessage
          message={t(emitError.message)}
          onClick={() => {
            setEmitError({ status: false });
          }}
        />
      </Transition>
    </>
  );
}
