import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { selectCountDownTime } from "../../Reducers/flashDealSlice";
import { selectuserProfile } from "../../Reducers/userProfileSlice";
// import { config } from "../../config";

const Bg = styled.div`
  user-select: none;
  /* background-color: blue; */
  width: 46%;
  height: 7.98%;
  position: absolute;
  left: 27.08%;
  top: 81.9%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Bar = styled.img`
  height: 100%;
  object-fit: cover;
  object-position: 0% 0;
  position: absolute;
  left: 0%;
`;

const TextBox = styled.div`
  color: white;
  font-size: ${() =>
    // window.appHeight() / window.innerWidth > config.verticalSizeiphone
    window.appHeight() > window.innerWidth ? "3.6vw" : "2.4vw"};
  z-index: 1;
`;

export default function ProgressBar(props) {
  const profile = useSelector(selectuserProfile);
  const countDownTime = useSelector(selectCountDownTime);
  return (
    <Bg>
      <Bar
        style={{ width: countDownTime.barWidth + "%" }}
        src="../images/promotion_banner_new/flashdeal_progressbar.png"
      />
      <TextBox>
        {profile.language === "TH"
          ? (isNaN(countDownTime.hour) ? "0" : countDownTime.hour) +
          "ชม. " +
          (isNaN(countDownTime.minute) ? "0" : countDownTime.minute) +
          "น. " +
          (isNaN(countDownTime.second) ? "0" : countDownTime.second) +
          "วิ."
          : (isNaN(countDownTime.hour) ? "0" : countDownTime.hour) +
          "h " +
          (isNaN(countDownTime.minute) ? "0" : countDownTime.minute) +
          "m " +
          (isNaN(countDownTime.second) ? "0" : countDownTime.second) +
          "s"}
      </TextBox>
    </Bg>
  );
}
