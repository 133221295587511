import React from "react";
import styled, { css } from "styled-components";
import breakpoints from "../../LandingPageComponents/breakpoints";
import { config } from "../../config";
const Message = styled.div`
  user-select: none;
  // background-image: url("../${config.theme}/popup_msg.png");
  // box-shadow: 0px 0px 50px 20px rgba(0, 0, 0, 0.8);
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;
  border-radius: 2vw;
  // theme ==================
  /* background: #192756;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #5DD8FF, inset 0px 0px 4vw rgba(66, 255, 232, 0.63);
  border: solid 0.2vw #BDEFFF;
  border-radius: 2vw; */
  // theme ==================
  /* background-size: contain; */
  background-size: 100% 100%;
  min-width: 38.8306vw;
  width: max-content;
  /* height: calc(100vw * 0.07646); */
  height: max-content;
  background-repeat: no-repeat;
  background-position-x: center;
  max-width: 50vw;
  z-index: 999;
  /* width: 35vw; */
  p {
    color: #bdb9d1;
    text-align: center;
    font-size: 1.5vw;
    margin: 0vw;
    padding: 3vw;
    line-height: 1.3;
    display: flex;
    justify-content: center;
    /* line-break: anywhere; */
    white-space: pre-line;
  }
  //Portrait
  @media (orientation: portrait) {
    transform: scale(1.5);
    padding: 1vw 4vw 3vw 5vw;
    white-space: pre-line;
    width: 45vw;
    p {
      padding: 3vw 0vw 1vw 0vw;
      font-size: 2.5vw;
      display: flex;
      justify-content: center;
      white-space: pre-line;
    }
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    transform: scale(1.5);
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          transform: scale(1.5);
          padding: 1vw 4vw 3vw 5vw;
          white-space: pre-line;
          width: 45vw;
          p {
            padding: 3vw 0vw 1vw 0vw;
            font-size: 2.5vw;
            display: flex;
            justify-content: center;
            white-space: pre-line;
          }
        `
      : window.appHeight() > window.innerWidth &&
        css`
          transform: scale(1.5);
        `} */
`;

const Box = styled.div`
  user-select: none;
  position: fixed;
  width: 100vw;
  height: 100%; //fix
  top: 0%;
  left: 0%;
  /* text-align: -webkit-center; */
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${breakpoints.device.line} {
    width: 145vw;
    height: 620%;
    left: -22%;
  }
`;

function AlertMessage(props) {
  return (
    <Box {...props}>
      <Message>
        <p>{props.message || props.children}</p>
      </Message>
    </Box>
  );
}
export default AlertMessage;
