import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import GameItem from "../GameList/GameItem";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Transition, config } from "../../config";
import AlertMessage from "../Dialog/AlertMessage";
import breakpoint from "../GameList/breakpoints";
import breakpoints from "../../LandingPageComponents/breakpoints";
import {
  actionIsProvider,
  selectIsProvider,
  selectProvider,
  selectSetProvider,
} from "../../Reducers/providerSlice";
import { selectGameList } from "../../Reducers/gameListSlice";
import { selectuserProfile } from "../../Reducers/userProfileSlice";

SwiperCore.use([Autoplay, Pagination, Navigation]);

const SwiperFix = styled(Swiper)`
  width: 100vw;
  overflow: visible;
  @media ${breakpoints.device.line} {
    width: 100%;
    background-color: red;
    left: 0;
    position: absolute;
  }
  .swiper-wrapper {
    display: flex;
    height: 56vh;
    margin: auto;
    align-items: center;
    justify-content: ${(props) => props.iscenter};
    @media ${breakpoints.device.line} {
      width: 100%;
      margin-left: -22%;
    }
  }

  @media ${breakpoint.device.hGalaxyS5} {
    transform: scale(1.25);
    margin-top: 2vw;
    .swiper-slide {
      margin-right: 0vw !important;
    }
  }
  @media ${breakpoint.device.hPixel2} {
    transform: scale(1.25);
    margin-top: 2vw;
    .swiper-slide {
      margin-right: 0vw !important;
    }
  }
  @media ${breakpoint.device.hIphone6} {
    transform: scale(0.9);
    margin-top: -1vw;
    .swiper-slide {
      margin-right: 2.5vw !important;
    }
  }
`;

const MainContent = styled.div`
  display: flex;
  width: 98vw;
  height: 56vh;
  margin: auto;
  padding-left: 3%;
  align-items: center;
  justify-content: ${(props) => props.iscenter};
  cursor: grab;
  overflow-x: scroll;
  overflow-y: hidden;
  /* scroll-behavior: smooth; */

  ::-webkit-scrollbar {
    display: none;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-content: start;
  flex-wrap: wrap;
  overflow-y: scroll;
  height: 62vw;
  ::-webkit-scrollbar {
    display: none;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          // height: 133.5vw;
          height: 100vh;
          padding-bottom: 10vh;
          place-content: start;
          display: flex;
          justify-content: center;
          position: relative;
          /* left: 2vw; */
          /* background:#E6E6FA; */
        `
      : window.appHeight() > window.innerWidth &&
        css`
          height: 100vw;
          width: 100%;
          display: flex;
          position: relative;
          justify-content: center;
          /* left: 14vw; */
        `}
`;

const Push = styled.div`
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          height: 50vh;
          width: 100vw;
          // background: red;
          position: relative;
          bottom: 0;
          display: block;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          height: 64vh;
          margin-top: 18vw;
        `}
`;

const SoonBox = styled.div`
  /* width: 100vw; */
  height: calc(100vh * 0.5);
  /* position: absolute; */
  /* z-index: 2; */
  display: block;
  //Portrait
  @media (orientation: portrait) {
    width: 100vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
  }
`;

const SoonText = styled.div`
  user-select: none;
  height: 5.7vw;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 1vw;
  padding: 0.5vw 2vw;
  color: #fff;
  font-size: 2.8vw;
  text-align: center;
  display: flex !important;
  vertical-align: middle;
  position: relative;
  line-height: 5.7vw;
  justify-content: center;
  align-items: center;

  top: 50%;
  transform: translate(0%, -50%);
  width: fit-content;
  //Portrait
  @media (orientation: portrait) {
    font-size: 5.8vw;
    height: 11.7vw;
    border-radius: 2vw;
    width: 85%;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 3.5vw;
    height: 11.7vw;
    border-radius: 2vw;
    left: 36%;
    width: 70%;
  }
`;

function Providers(props) {
  const dispatch = useDispatch();
  const providers = useSelector(selectProvider);
  const selectPV = useSelector(selectSetProvider);
  const gameList = useSelector(selectGameList);
  const [emitError, setEmitError] = useState({ status: false, message: "" });
  const { t } = useTranslation();

  const [filterProvider, setFilterProvider] = useState(providers);
  const [filterGameList, setFilterGameList] = useState([]);
  const isProvider = useSelector(selectIsProvider);
  const profile = useSelector(selectuserProfile);
  const [isLoad, setIsLoad] = useState(false);

  //filterProvider
  useEffect(() => {
    if (isProvider == true && providers) {
      let filter = providers
        ?.filter((i) => i[props.type] > 0)
        ?.sort(function (a, b) {
          return a[props.type] - b[props.type];
        });
      setFilterProvider(filter);
      // console.log("filterProvider", filter);
    }
    // console.log("filterProvider", filterProvider, isProvider);
  }, [props.type, providers, isProvider]);

  //filterGame
  useEffect(() => {
    if (
      props.type !== "" &&
      (isProvider == false ||
        props.type === "favourite" ||
        props.type === "history")
    ) {
      let filterGame = gameList
        .filter((i) => {
          if (props.type === "favourite") {
            dispatch(actionIsProvider(false));
            return profile?.favourite?.indexOf(i.gameId) > -1;
          } else if (props.type === "history") {
            dispatch(actionIsProvider(false));
            return profile?.latestGame.indexOf(i.gameId) > -1;
          } else {
            return i[props.type] > 0 && i.partner === selectPV;
          }
        })
        .sort(function (a, b) {
          return a[props.type] - b[props.type];
        });
      // history sort
      if (props.type === "history") {
        let recent = [];
        for (const key in profile.latestGame) {
          let recentFilter = filterGame.filter(
            (a) => profile.latestGame[key] === a.gameId
          )[0];
          if (recentFilter !== undefined) {
            recent.push(recentFilter);
          }
        }
        setFilterGameList(recent);
      } else {
        setFilterGameList(filterGame);
      }
      // setFilterGameList(filterGame);
      setIsLoad(true);
      // console.log("filterGame", filterGame);
    }
    // console.log("filterGame", filterGameList, isProvider);
  }, [props.type, isProvider]);

  let isCenter = "";
  if (isProvider) {
    if (filterProvider?.length <= 5) {
      isCenter = "center";
    } else if (
      window.innerWidth / window.appHeight() > 2.15 &&
      filterProvider?.length <= 6
    ) {
      isCenter = "center";
    }
  } else {
    if (filterGameList.length <= 5) {
      isCenter = "center";
    } else if (
      window.innerWidth / window.appHeight() > 2.15 &&
      filterGameList.length <= 6
    ) {
      isCenter = "center";
    }
  }

  // useEffect(() => {
  //   console.log("window.isDrag false :", window.isDrag);
  //   setTimeout(() => {
  //     window.isDrag = false;
  //   }, 2000);
  // }, [window.isDrag]);

  useEffect(() => {
    const slider = document.querySelector(".items");
    if (slider) {
      let isDown = false;
      let startX;
      let scrollLeft;

      slider.addEventListener("mousedown", (e) => {
        // console.log("mousedown");
        isDown = true;
        slider.classList.add("active");
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener("mouseleave", () => {
        // console.log("mouseleave");
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mouseup", () => {
        // console.log("mouseup");

        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mousemove", (e) => {
        // console.log("mousemove");
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 0.75; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
      });
    }
  }, []);

  useEffect(() => {
    let el = document.getElementById("Swiper-Providers");
    if (el !== null) {
      el.scrollTo(0, 0);
    }

    // let currentScroll = 0;
    // clearInterval(window.intervalSwiper);
    // window.intervalSwiper = setInterval(() => {
    //   let scroll = (el.scrollWidth * 10) / 100;
    //   currentScroll = currentScroll + scroll;
    //   if (currentScroll > el.scrollWidth) {
    //     currentScroll = 0;
    //   }
    //   el.scrollTo(currentScroll, 0);
    //   console.log("currentScroll", currentScroll);
    // }, 1000);
  }, [props.type, isProvider]);

  const SoonTextComponent = () => {
    // console.log("soon", isProvider);
    return (
      <SoonBox>
        <SoonText width={window.innerWidth} height={window.appHeight()}>
          <p>
            {props.type === "favourite"
              ? t("no favorite")
              : props.type === "history"
              ? t("no recent")
              : t("soon")}
          </p>
        </SoonText>
      </SoonBox>
    );
  };

  return (
    <>
      {window.innerWidth > window.appHeight() ? (
        <MainContent
          id={"Swiper-Providers"}
          className="items"
          iscenter={isCenter}
          onScroll={() => {
            // console.log("onScroll");
            window.isDrag = true;
            clearTimeout(window.dragTimeout);
            window.dragTimeout = setTimeout(() => {
              // console.log("mouseup timeout");
              window.isDrag = false;
            }, 700);
          }}
        >
          {isProvider
            ? filterProvider?.map((item, index) => (
                <GameItem
                  key={"providers_" + index}
                  {...item}
                  isProvider={true}
                />
              ))
            : isLoad && filterGameList.length == 0
            ? SoonTextComponent()
            : filterGameList.map((item, index) => (
                <SwiperSlide
                  id={"Swiper-Slide-Game"}
                  key={"items_category" + index}
                >
                  <GameItem
                    key={"providers_" + index}
                    {...item}
                    isGame={true}
                  />
                </SwiperSlide>
              ))}
        </MainContent>
      ) : filterProvider?.length > 0 || filterGameList.length > 0 ? (
        <Content id={"Swiper-Providers"}>
          {isProvider
            ? filterProvider?.map((item, index) => (
                <GameItem
                  key={"providers_" + index}
                  {...item}
                  isProvider={true}
                />
              ))
            : // check game in providers
            isLoad && filterGameList.length == 0
            ? SoonTextComponent()
            : filterGameList.map((item, index) => (
                <GameItem key={"providers_" + index} {...item} isGame={true} />
              ))}
          <Push />
        </Content>
      ) : // check game in fav and history
      isLoad && filterGameList.length == 0 ? (
        SoonTextComponent()
      ) : (
        ""
      )}
      <Transition showPop={emitError.status} type="alert">
        <AlertMessage
          message={t(emitError.message)}
          onClick={() => {
            setEmitError({ status: false });
          }}
        />
      </Transition>
    </>
  );
}

export default Providers;
