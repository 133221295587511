import React, { useState } from "react";
import styled, { css } from "styled-components";
import i18next from "i18next";
import { socket } from "../../context/socket";
import { Transition } from "../../config";
import AlertMessage from "../Dialog/AlertMessage";
import { useTranslation } from "react-i18next";
import { config } from "../../config";
import { useDispatch } from "react-redux";
import { actionSetprofile } from "../../Reducers/userProfileSlice";

const BG = styled.button`
  user-select: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  /* justify-content: space-between; */
  text-decoration: none;
  min-width: 0px;
  min-height: 0px;
  padding: 0px;
  color: white;
  text-transform: none;
  font-size: 1.8vw;
  transition: 750ms;
  text-align: left;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 3.7vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 2.15vw;
        `}
`;

const Icon = styled.img`
  user-select: none;
  width: 39.1%;
  height: 50%;
  margin-left: 7.5%;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 45%;
          height: 100%;
          bottom: 0vw;
          left: 41%;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 40%;
          height: 100%;
          bottom: 0vw;
          left: 41%;
        `}
`;

export default function LanguageButton(props) {
  const [emitError, setEmitError] = useState({ status: false, message: "" });
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const Setlanguage = (lang) => {
    dispatch(actionSetprofile({ language: lang.toUpperCase() }));
    localStorage.setItem("lang", lang);
    try {
      socket.emit(
        "setLanguage",
        {
          language: lang.toUpperCase(), //TH, EN
        },
        (body) => {
          if (typeof body != "undefined") {
            if (body.status) {
            } else if (body.status === false) {
              setEmitError({ status: true, message: body.errorCode });
              setTimeout(() => {
                setEmitError({ status: false });
              }, 3000);
            }
          }
        }
      );
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  };

  return (
    <>
      <BG
        onClick={() => {
          Setlanguage(props.lang);
          i18next.changeLanguage(props.lang);
          props.setShowLanguagePop(false);
        }}
      >
        <Icon src={"../" + config.theme + "/" + props.icon} />
        {props.label}
      </BG>
      <Transition showPop={emitError.status} type="alert">
        <AlertMessage
          message={t(emitError.message)}
          onClick={() => {
            setEmitError({ status: false });
          }}
        />
      </Transition>
    </>
  );
}
