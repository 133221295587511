import styled from "styled-components";
import breakpoints from "../LandingPageComponents/breakpoints";

const ScaleFrame = styled.div`
  
  @media ${breakpoints.device.line} {
    transform: scale(0.7);
  }
  @media ${breakpoints.device.vertic}{
    /* transform: scale(1.8); */
  }
`;

export default ScaleFrame;