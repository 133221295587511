import { createSlice } from "@reduxjs/toolkit";
export const confirmPopupSlice = createSlice({
  name: "confirmPopup",
  initialState: {
    popup: {
      status: false,
      message: "",
    },
  },

  reducers: {
    actionConfirmPopup: (state, action) => {
      state.popup = { ...state.popup, ...action.payload };
    },
  },
});

export const { actionConfirmPopup } = confirmPopupSlice.actions;
export const selectConfirmPopup = (state) => state.confirmPopup.popup;
export default confirmPopupSlice.reducer;
