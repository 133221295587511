import { createSlice } from "@reduxjs/toolkit";

export const missionSlice = createSlice({
  name: "mission",
  initialState: {
    mission: [],
    mission_no: 0,
  },
  reducers: {
    actionMission: (state, action) => {
      state.mission = action.payload;
    },
    actionMissionNo: (state, action) => {
      state.mission_no = action.payload;
    },
  },
});

export const { actionMission, actionMissionNo } = missionSlice.actions;
export const selectMission = (state) => state.mission.mission;
export const selectMissionNo = (state) => state.mission.mission_no;
export default missionSlice.reducer;
