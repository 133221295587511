import React from "react";
import styled, { css } from "styled-components";
import Button from "../Button";
import breakpoints from "../../LandingPageComponents/breakpoints";
import { useTranslation } from "react-i18next";
import { config } from "../../config";
const Popup = styled.div`
  user-select: none;
  /* margin-top: 20vw; */
  display: flex;
  flex-direction: column;
  /* margin: 0 auto; */
  /* min-width: 25vw; */
  /* height: max-content;
  width: max-content; */
  // theme ==================
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;
  border-radius: 2vw;
  padding: 3.5vw 9vw 4vw 9vw;
  width: 30vw;
  // theme ==================
  // background-color: #2f2d3b;
  // border-radius: 4vw;
  // border: solid 3vw;
  // border-image-slice: 50;
  // border-image-source: url('../${config.theme}/popup_frame_mini.png');
  justify-content: center;
  text-align: center;
  align-items: center;
  color: #bcb8d0;
  font-size: 2vw;
  /* width: 55vw; */
  white-space: pre-line;
  //Portrait
  @media (orientation: portrait) {
    transform: scale(1.5);
    width: 59vw;
    padding: 3vw 2vw 5vw 2vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    min-width: 40vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          transform: scale(1.5);
          width: 59vw;
          padding: 3vw 2vw 5vw 2vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          min-width: 40vw;
        `} */
`;

const ButConfirm = styled.div`
  user-select: none;
  position: relative;
  z-index: 70;
  display: flex;
  margin-top: -2vw;
  //Portrait
  @media (orientation: portrait) {
    transform: scale(1.2);
    margin-top: 0vw;
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          transform: scale(1.2);
          margin-top: 0vw;
        `
      : window.appHeight() > window.innerWidth && css``} */
`;

const Box = styled.div`
  user-select: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  text-align: -webkit-center;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  z-index: 50;
  align-items: center;
  justify-content: center;
  p {
    color: #bdb9d1;
    text-align: center;
    font-size: 1.5vw;
    width: 100%;
    line-height: 1;

    display: flex;
    word-break: break-all;
    justify-content: center;
  }
  h5 {
    font-size: 2.5vw;
    color: white;
    margin: 0;
    display: flex;
    word-break: break-all;
    justify-content: center;
  }
  @media ${breakpoints.device.line} {
    width: 145%;
    height: 620%;
    left: -22%;
  }
  //Portrait
  @media (orientation: portrait) {
    p {
      color: #bdb9d1;
      text-align: center;
      font-size: 2.5vw;
      width: 100%;
      line-height: 2;
      -webkit-text-size-adjust: 100%;
      display: flex;
      word-break: break-all;
      justify-content: center;
    }
    h5 {
      color: #bdb9d1;
      text-align: center;
      font-size: 2.5vw;
      width: 100%;
      line-height: 2;
      -webkit-text-size-adjust: 100%;
      display: flex;
      word-break: break-all;
      justify-content: center;
    }
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    p {
      color: #bdb9d1;
      text-align: center;
      font-size: 2vw;
      width: 100%;
      line-height: 2;
      -webkit-text-size-adjust: 100%;
    }
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          p {
            color: #bdb9d1;
            text-align: center;
            font-size: 2.5vw;
            width: 100%;
            line-height: 2;
            -webkit-text-size-adjust: 100%;
            display: flex;
            word-break: break-all;
            justify-content: center;
          }
          h5 {
            color: #bdb9d1;
            text-align: center;
            font-size: 2.5vw;
            width: 100%;
            line-height: 2;
            -webkit-text-size-adjust: 100%;
            display: flex;
            word-break: break-all;
            justify-content: center;
          }
        `
      : window.appHeight() > window.innerWidth &&
        css`
          p {
            color: #bdb9d1;
            text-align: center;
            font-size: 2vw;
            width: 100%;
            line-height: 2;
            -webkit-text-size-adjust: 100%;
          }
        `} */
`;

function Alert(props) {
  const { t } = useTranslation();

  return (
    <Box {...props}>
      <Popup style={{ height: props.height }}>
        <h5>{props.title}</h5>
        <p>{props.children}</p>
      </Popup>
      <ButConfirm>
        <Button
          bClass="rbRed"
          label={props.messageLeft ? t(props.messageLeft) : t("cancel")}
          width={
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "21vw"
              : window.appHeight() > window.innerWidth
              ? "16vw"
              : window.innerWidth / window.appHeight() > 2.15
              ? "13vw"
              : "13vw"
          }
          fontSize={
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "2.5vw"
              : window.appHeight() > window.innerWidth
              ? "1.7vw"
              : window.innerWidth / window.appHeight() > 2.15
              ? "1.15vw"
              : "1.15vw"
          }
          onClick={props.cancelClick}
          style={{ padding: 0 }}
        />
        <Button
          label={props.messageBottom}
          width={
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "21vw"
              : window.appHeight() > window.innerWidth
              ? "16vw"
              : window.innerWidth / window.appHeight() > 2.15
              ? "13vw"
              : "13vw"
          }
          fontSize={
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "2.5vw"
              : window.appHeight() > window.innerWidth
              ? "1.7vw"
              : window.innerWidth / window.appHeight() > 2.15
              ? "1.15vw"
              : "1.15vw"
          }
          style={{ padding: 0 }}
          onClick={props.confirmClick}
        />
      </ButConfirm>
    </Box>
  );
}
export default Alert;
