import React, { useState, useEffect } from "react";
import styled from "styled-components";
import breakpoint from "./breakpoints";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectHomeContentLP } from "../Reducers/homeContentLPSlice";
import MoreButton from "./MoreButton";

const LiveCasinosBox = styled.div`
  user-select: none;
  background-image: url("../lpimages/bg_content2.png");
  width: 100%;
  height: 20vw;
  background-size: 102%;
  background-repeat: no-repeat;
  padding-bottom: 3vw;
  @media ${breakpoint.device.mobile} {
    height: 100%;
    background-size: 120%;
    background-position-x: center;
    text-align: start;
  }
`;

const Image = styled.img`
  width: 2.5vw;
  padding-right: 0.5vw;
  @media ${breakpoint.device.mobile} {
    width: 5.5vw;
    padding-right: 1.5vw;
  }
`;

const Header = styled.div`
  margin-left: 13vw;
  width: 65%;
  color: white;
  padding: 1.5vw;
  white-space: pre-wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // margin: 0 auto;
  h1 {
    font-size: 3vw;
    width: auto;
    position: relative;
  }
  p {
    color: #8a8ca3;
    font-size: 1.5vw;
    margin: 0;
    padding-left: 1vw;
    display: block;
    text-align: start;
    white-space: pre-wrap;
  }
  @media ${breakpoint.device.mobile} {
    padding: 5vw 3.5vw;
    align-items: baseline;
    justify-content: space-between;
    width: 95%;
    margin-left: 1vw;
    h1 {
      font-size: 6.5vw;
      display: inline-block;
      width: auto;
    }
    p {
      font-size: 3.38vw;
      padding-left: 5vw;
    }
  }
`;

const Title = styled.div`
  width: 38vw;
  display: -webkit-box;
  position: relative;
  -webkit-box-align: center;
  align-items: center;
  a {
    color: #ff7000;
  }
  /* margin-left: -6vw; */
  @media ${breakpoint.device.mobile} {
    width: unset;
    display: block;
  }
`;

const CasinoList = styled.div`
  width: 60%;
  margin: 0vw auto;
  display: flex;
  justify-content: space-between;
  @media ${breakpoint.device.mobile} {
    width: 95%;
    flex-wrap: wrap;
  }
`;

const CasinoItemBox = styled.div`
  position: relative;
  @media ${breakpoint.device.mobile} {
  }
`;

const CasinoItem = styled.img`
  /* background-image: url(${(props) => props.img}); */
  /* background-size: cover; */
  display: inline-block;
  width: 9vw;
  height: 12vw;
  border-radius: 1vw;
  @media ${breakpoint.device.mobile} {
    width: 28.71vw;
    height: 39.86vw;
    margin-bottom: 2vw;
  }
`;

const Live = styled.div`
  background-color: rgba(0, 0, 0, 0.75);
  position: absolute;
  top: 0.5vw;
  left: 0.5vw;
  font-size: 0.83vw;
  color: #fff;
  padding: 0.2vw 0.4vw;
  border-radius: 0.4vw;
  > span {
    display: inline-block;
    width: 0.625vw;
    height: 0.625vw;
    margin-right: 0.25vw;
    border-radius: 1vw;
    background-color: #ee2525;
  }
  @media ${breakpoint.device.mobile} {
    font-size: 3.86vw;
    border-radius: 1.5vw;
    padding: 0.5vw 1.5vw;
    > span {
      width: 2.9vw;
      height: 2.9vw;
      border-radius: 3vw;
      margin-right: 1vw;
    }
  }
`;

function LiveCasinos() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const homeContent = useSelector(selectHomeContentLP);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (homeContent.length !== 0) {
      setItems(homeContent.find((i) => i.location === "live-casino").contents);
    }
  }, [homeContent]);

  return (
    <>
      <LiveCasinosBox>
        <Header lang={i18n.language}>
          <Title>
            <Image src="../../lpimages/bullet.png" />
            <h1>{t("Live Casino")}</h1>
            {/* <p>{t("articles")}</p> */}
            <p dangerouslySetInnerHTML={{ __html: t("CasinoShort") }}></p>
            {/* <a href="https://test.spxslot.xyz/live-casino/hot">{t("Live Casino")}</a>{t("CasinoDescription")} */}
          </Title>
          {/* <More
            onClick={() => {
              history.push("../live-casino/all");
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
            }}
          >
            {t("More")}
          </More> */}
          <MoreButton
            label=""
            onClick={() => {
              history.push("../live-casino/hot");
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          />
        </Header>
        <CasinoList>
          {items.map((item, index) => (
            <CasinoItemBox key={"casino-item-" + index}>
              <CasinoItem src={item.img} alt={item.seo.alt} />
              <Live>
                <span></span>
                Live
              </Live>
            </CasinoItemBox>
          ))}
        </CasinoList>
      </LiveCasinosBox>
    </>
  );
}

export default LiveCasinos;
