import { createSlice } from "@reduxjs/toolkit";
export const spinixShopMenuSlice = createSlice({
  name: "spinixShopMenu",
  initialState: {
    menu: {
      freeSpin: false,
      credit: false,
      deposit: false,
      diamonds: false,
    },
  },
  reducers: {
    actionSpinixShopMenu: (state, action) => {
      state.menu = { ...state.menu, ...action.payload };
    },
  },
});

export const { actionSpinixShopMenu } = spinixShopMenuSlice.actions;
export const selectSpinixShopMenu = (state) => state.spinixShopMenu.menu;
export default spinixShopMenuSlice.reducer;
