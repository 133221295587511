import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router";
import breakpoint from "../breakpoints";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { selectDimensionsLP } from "../../Reducers/dimensionsLP";
import { actionLoading } from "../../Reducers/loadingSlice";
import { selectHomeContentLP } from "../../Reducers/homeContentLPSlice";

const TermsContainer = styled.div`
  width: 70vw;
  margin: 5vw auto 10vw auto;
  text-align: center;
  padding: 2vw 0;
  > p {
    font-size: 0.94vw;
    color: #c5c9ec;
    text-align: start;
    white-space: break-spaces;
  }
  a {
    color: #ff7000;
  }
  @media ${breakpoint.device.mobile} {
    width: 92vw;
    margin: 19vw auto 10vw auto;
    padding: 4vw 2vw 2vw 2vw;
    > p {
      font-size: 3.38vw;
      white-space: break-spaces;
    }
    > hr {
      display: none;
    }
  }
`;

const Header = styled.div`
  > h1 {
    font-size: 1.56vw;
    color: #ffffff;
    text-align: center;
    margin: 1vw auto 2vw auto;
  }
  @media ${breakpoint.device.mobile} {
    > h1 {
      font-size: 4.2vw;
    }
  }
`;

const FontIcon = styled(FontAwesomeIcon)`
  color: #fff;
  padding-right: 0.5vw;
  @media ${breakpoint.device.mobile} {
    padding-right: 1vw;
  }
`;

const Details = styled.div`
  width: 49.14vw;
  height: 28.85vw;
  background: #151a30;
  border-radius: 0.4vw;
  color: #ffffff;
  margin: 0 auto;
  padding: 2vw 1vw 2vw 2vw;
  text-align: left;
  /* overflow-y: scroll; */
  h4 {
    margin: 1vw auto;
  }
  p {
    font-size: 0.83vw;
    margin: 0.5vw auto;
    color: #8f9bb3;
    text-indent: 2vw;
  }
  p.indent {
    text-indent: 4vw;
  }

  @media ${breakpoint.device.mobile} {
    width: 92%;
    height: 154vw;
    border-radius: 1.9vw;
    padding: 4vw;
    h4 {
      font-size: 4.58vw;
      margin: 3vw auto;
    }
    p {
      font-size: 3.86vw;
      margin: 1.5vw auto;
    }
  }
`;

const Content = styled.div`
  height: 28.85vw;
  padding-right: 1vw;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0.5vw;
  }
  /* Scroll Track */
  ::-webkit-scrollbar-track {
    background: #8f9bb3;
    border-radius: 10vw;
  }
  /* Scroll Handle */
  ::-webkit-scrollbar-thumb {
    background: #08367a;
    border-radius: 10vw;
  }
  /* Scroll Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #08367a;
  }

  @media ${breakpoint.device.mobile} {
    width: 100%;
    height: 154vw;
    padding-right: 2vw;
    ::-webkit-scrollbar {
      width: 2vw;
    }
    /* Scroll Track */
    ::-webkit-scrollbar-track {
      border-radius: 5vw;
    }
    /* Scroll Handle */
    ::-webkit-scrollbar-thumb {
      border-radius: 5vw;
    }
  }
`;

function Terms() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const dimensionsLP = useSelector(selectDimensionsLP);
  const homeContent = useSelector(selectHomeContentLP);

  // console.log("homeContent", homeContent[5].contents);

  const ScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  };

  useEffect(() => {
    dispatch(actionLoading(true));
    ScrollToTop();
  }, []);

  useEffect(() => {
    if (homeContent.length !== 0) {
      setItems(homeContent.find((i) => i.location === "terms").contents);
    }
  }, [homeContent]);

  return (
    <TermsContainer>
      <Header>
        <Button
          bClass="rtRed"
          label={[
            <FontIcon key="fontIcon" icon={faArrowLeft} />,
            <p key="label">{t("Back")}</p>,
          ]}
          width={
            dimensionsLP.width <= breakpoint.deviceSize ? "24.15vw" : "6.8vw"
          }
          height={
            dimensionsLP.width <= breakpoint.deviceSize ? "9.66vw" : "2.6vw"
          }
          fontSize={
            dimensionsLP.width <= breakpoint.deviceSize ? "4.83vw" : "1vw"
          }
          onClick={() => history.goBack()}
        />
        <h1>{t("terms")}</h1>
      </Header>
      <Details>
        {items.map((item, index) => (
          <Content
            key={"terms" + index}
            dangerouslySetInnerHTML={{
              __html: i18n.language === "th" ? item.detail_th : item.detail_en,
            }}
          ></Content>
        ))}
      </Details>
    </TermsContainer>
  );
}

export default Terms;
