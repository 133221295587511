import App from "../LPApp";
import { SocketKafkaContext, socketKafka } from '../context/socket';
function LPAppConnect() {
    return (
        <SocketKafkaContext.Provider value={socketKafka}>
            <App />
        </SocketKafkaContext.Provider>
    )
}

export default LPAppConnect;