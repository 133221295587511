import { createSlice } from '@reduxjs/toolkit';

export const dailyReward = createSlice({
    name: 'dailyReward',
    initialState: {
        dailyTurnover: 0,
        active1: {
            value: 100,
            status: "",
            text: "diamonds"
        },
        active2: {
            value: 200,
            status: "",
            text: "lucky ticket"
        },
        maxDailyTurnOver: {
            value: 300,
            status: "",
            text: "treasure ticket"
        },
    },
    reducers: {
        changeDailyAmount: (state, action) => {
            state.dailyTurnover = action.payload
        },
        changeActive1: (state, action) => {
            state.active1 = { ...state.active1, ...action.payload }
        },
        changeActive2: (state, action) => {
            state.active2 = { ...state.active2, ...action.payload }
        },
        changeMax: (state, action) => {
            state.maxDailyTurnOver = { ...state.maxDailyTurnOver, ...action.payload }
        },
    }
})

export const { changeDailyAmount, changeActive1, changeActive2, changeMax } = dailyReward.actions;

export const dailyTurnover = state => state.dailyReward.dailyTurnover;
export const active1 = state => state.dailyReward.active1;
export const active2 = state => state.dailyReward.active2;
export const maxDailyTurnOver = state => state.dailyReward.maxDailyTurnOver;

export default dailyReward.reducer;