import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/en.json";
import translationTH from "./locales/th.json";
import translationCN from "./locales/cn.json";
import translationID from "./locales/id.json";
import translationMAL from "./locales/Mal.json";
import translationVN from "./locales/vn.json";
import { getLang } from "./config";
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: translationEN,
  },
  th: {
    translation: translationTH,
  },
  cn: {
    translation: translationCN,
  },
  id: {
    translation: translationID,
  },
  mal: {
    translation: translationMAL,
  },
  vn: {
    translation: translationVN,
  },
};
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getLang(),

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
