import React, { useEffect, useContext, useState } from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  selectuserProfile,
  actionClickSound,
} from "../../../../Reducers/userProfileSlice";
import { SocketContext } from "../../../../context/socket";
import Popup from "../../../Popup";
import { config } from "../../../../config";
import ConfirmPromotionPopup from "../ConfirmPromotionPopup";
import AutoPro from "./AutoPro";
import ByTypeProList from "./ByTypeProList";
import NoticeAuto from "./NoticeAuto";
import NoticeManual from "./NoticeManual";
import AlertMessage from "../../../Dialog/AlertMessage";

const Box = styled.div`
  position: fixed;
  width: 100vw;
  height: 100%; //fix
  top: 0%;
  left: 0%;
  text-align: -webkit-center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 70;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BG = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 20;
  align-items: center;
  justify-content: center;
  margin: 0%;
`;

const ContentBox = styled.div`
  width: 100%;
  height: 87%;
  /* background-color: red; */
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
`;

const ChoiceItem = styled.div`
  width: 90%;
  height: 22%;
  cursor: pointer;
  background: #17161d;
  margin-top: 2%;
  border-radius: 1vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  img {
    width: 10%;
    object-fit: contain;
  }
`;

const TextChoiceDetail = styled.div`
  width: 70%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: start;
  color: #fff;
  font-size: 2vw;
  white-space: pre-line;
  span {
    font-size: 1.5vw;
  }
  @media (orientation: portrait) {
    font-size: 4vw;
    span {
      font-size: 3vw;
    }
  }
`;

function SelectByType(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector(selectuserProfile);
  const socket = useContext(SocketContext);
  const [confirmPop, setConfirmPop] = useState({ status: false, type: 0 }); // 0 = no pro, 1 = auto pro
  const [autoPop, setAutoPop] = useState(false);
  const [proListPop, setProListPop] = useState(false);
  const [noticeAutoPop, setNoticeAutoPop] = useState(false);
  const [noticeManualPop, setNoticeMunalPop] = useState(false);
  const [finishAlert, setFinishAlert] = useState(false);

  const UpdatePromotion = (n, inf) => {
    console.log("Check para", n, inf);
    if (n === "nopro") {
      socket.emit("cancelpromotionplayer", {}, (data) => {
        console.log("cancelpromotionplayer", data);
        setFinishAlert(true);
        setTimeout(() => {
          setFinishAlert(false);
          props.closeClick();
        }, 3000);
      });
    } else {
      socket.emit(
        "choosepromotionplayer",
        {
          promotion_id: n === "auto" ? null : inf?.id, //null = auto //จาก getpromotionplayer
          promotion_name: n === "auto" ? null : inf?.name, //null = auto //จาก getpromotionplayer
          gameType: n === "auto" ? inf : inf?.gameType, //0 all, 1 slot, 2 casino //จาก getpromotionplayer
        },
        (data) => {
          console.log("choosepromotionplayer", data);
          setFinishAlert(true);
          setTimeout(() => {
            setFinishAlert(false);
            props.closeClick();
          }, 3000);
        }
      );
    }
    props.setProStatusChanged((n) => !n);
  };

  return (
    <Box>
      <BG onClick={props.closeClick} />
      <Popup
        title={t("receive promotion status")}
        TitleSize={
          window.appHeight() > window.innerWidth && window.innerWidth <= 480
            ? "2.67vw"
            : window.appHeight() > window.innerWidth
            ? "4vw"
            : "2vw"
        }
        bgClass="Profile"
        width={
          window.appHeight() > window.innerWidth && window.innerWidth <= 480
            ? "95vw"
            : window.appHeight() > window.innerWidth
            ? "90vw"
            : "43.48vw"
        }
        height={
          window.appHeight() > window.innerWidth && window.innerWidth <= 480
            ? "80vw"
            : window.appHeight() > window.innerWidth
            ? "60vw"
            : "37.78vw"
        }
        closebuttonRight="-2.5vw"
        closebuttonTop="-2.5vw"
        closeClick={props.closeClick}
      >
        <ContentBox>
          <ChoiceItem
            onClick={() => {
              if (props.promotionInfo?.setting?.auto) {
                setAutoPop(true);
              } else {
                setNoticeAutoPop(true);
              }
            }}
          >
            <img src={"../" + config.theme + "/ico_promo_auto.png"} />
            <TextChoiceDetail>{t("auto pro")}</TextChoiceDetail>
            <img
              style={{
                visibility:
                  props.promotionInfo?.user?.promotion_id === null ||
                  props.promotionInfo?.promotion?.filter(
                    (n) =>
                      n.id === props.promotionInfo?.user?.promotion_id &&
                      n.enable === false
                  ).length > 0 ||
                  (props?.promotionInfo?.lastPromotion
                    ?.player_promotion_status === true &&
                    typeof props.promotionInfo?.user?.gameType ===
                      "undefined" &&
                    typeof props.promotionInfo?.user?.promotion_id ===
                      "undefined")
                    ? "visible"
                    : "hidden",
              }}
              src={"../" + config.theme + "/ico_promo_check.png"}
            />
          </ChoiceItem>
          <ChoiceItem
            onClick={() => {
              if (props.promotionInfo?.setting?.manual) {
                setProListPop(true);
              } else {
                setNoticeMunalPop(true);
              }
            }}
          >
            <img src={"../" + config.theme + "/ico_promo_coin.png"} />
            <TextChoiceDetail>
              {t("select pro")}
              <br /> <span>{props.promotionInfo?.user?.promotion_name}</span>
            </TextChoiceDetail>
            <img
              style={{
                visibility:
                  props.promotionInfo?.user?.promotion_id &&
                  props.promotionInfo?.promotion?.filter(
                    (n) =>
                      n.id === props.promotionInfo?.user?.promotion_id &&
                      n.enable === false
                  ).length === 0
                    ? "visible"
                    : "hidden",
              }}
              src={"../" + config.theme + "/ico_promo_check.png"}
            />
          </ChoiceItem>
          <ChoiceItem
            onClick={() => {
              setConfirmPop({ status: true, type: 0 });
            }}
          >
            <img src={"../" + config.theme + "/ico_promo_nopro.png"} />
            <TextChoiceDetail>{t("deactivate pro")}</TextChoiceDetail>
            <img
              style={{
                visibility:
                  typeof props.promotionInfo?.user?.gameType === "undefined" &&
                  typeof props.promotionInfo?.user?.promotion_id ===
                    "undefined" &&
                  props?.promotionInfo?.lastPromotion
                    ?.player_promotion_status === false
                    ? "visible"
                    : "hidden",
              }}
              src={"../" + config.theme + "/ico_promo_check.png"}
            />
          </ChoiceItem>
        </ContentBox>
      </Popup>
      {autoPop ? (
        <AutoPro
          promotionInfo={props.promotionInfo}
          UpdatePromotion={UpdatePromotion}
          setConfirmPop={setConfirmPop}
          closeClick={() => {
            setAutoPop(false);
          }}
        />
      ) : (
        ""
      )}
      {confirmPop.status ? (
        <ConfirmPromotionPopup
          type={confirmPop.type}
          setting={props.promotionInfo?.setting}
          UpdatePromotion={UpdatePromotion}
          closeClick={() => {
            setConfirmPop({ status: false });
          }}
        />
      ) : (
        ""
      )}
      {proListPop ? (
        <ByTypeProList
          closeClick={() => {
            setProListPop(false);
          }}
          promotionInfo={props.promotionInfo}
          UpdatePromotion={UpdatePromotion}
        />
      ) : (
        ""
      )}
      {noticeAutoPop ? (
        <NoticeAuto
          closeClick={() => {
            setNoticeAutoPop(false);
          }}
          confirmClick={() => {
            setAutoPop(true);
            props.setProStatusChanged((n) => !n);
          }}
        />
      ) : (
        ""
      )}
      {noticeManualPop ? (
        <NoticeManual
          closeClick={() => {
            setNoticeMunalPop(false);
          }}
          confirmClick={() => {
            setProListPop(true);
            props.setProStatusChanged((n) => !n);
          }}
        />
      ) : (
        ""
      )}
      {finishAlert ? (
        <AlertMessage message={t("select pro finish alert")} />
      ) : (
        ""
      )}
    </Box>
  );
}
export default SelectByType;
