import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import styled from "styled-components";
import "./LPApp.css";
import {
  SocketContext,
  socket,
  reSocket,
} from "./context/socket";
import {
  Transition,
  redirectToLogin,
} from "./config";
import Loading from "./Components/Loading";
import Forgotpassword from "./Components/Login/Forgotpassword";
import Login from "./Components/Login";
import CreateAccountPage from "./Components/Register/CreateAccountPage";
import BankAccountPage from "./Components/Register/BankAccountPage_land";

// import Footer from "./LandingPageComponents/Footer";
// import Navbar from "./LandingPageComponents/Navbar/Navbar";
// import Home from "./LandingPageComponents/Navbar/Home";
// import WarningMessage from "./LandingPageComponents/Dialog/WarningMessage";
// import NavbarMobile from "./LandingPageComponents/Navbar/NavbarMobile";
// import useWindowSize from "./LandingPageComponents/useWindowSize";
// import breakpoint from "./LandingPageComponents/breakpoints";
// import axios from "axios";
// import { actionCounterLP } from "./Reducers/counterLPSlice";
// import { actionContenthow } from "./Reducers/HowtouseSlice";
// import { actionContentpromo } from "./Reducers/promotionSlice";
// import i18next from "i18next";
// import { selectDimensionsLP, setDimensionLP } from "./Reducers/dimensionsLP";
// import { actionHomeContentLP } from "./Reducers/homeContentLPSlice";
// import StickyNav from "./LandingPageComponents/StickyNav";
// import SlotMinigame from "./LandingPageComponents/SlotMiniGame";
// import { actionCasinoItems, actionGameDemo } from "./Reducers/gameDemoSlice";
// import { actionLoading } from "./Reducers/loadingSlice";

const BG = styled.div`
  background-color: ${(props) => (props.BGcolor ? "#" + props.BGcolor : "")};
  background-image: url("../../images/newasset/bg.png");
  background-repeat: no-repeat;
  min-height: 100vh;
  overflow: hidden;
`;


function EMApp() {
  const [isLogin, SetIsLogin] = useState(false);
  const [isForgotPassword, SetIsForgotPassword] = useState(true);

  window.isLandingPage = true;
  setTimeout(() => {
    if (window.fullScreenGIF) window.fullScreenGIF();
  }, 500);

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  const [enable, SetEnable] = useState(false);
  const ConnectSocket = async () => {
    await reSocket();
    SetEnable(true);
  };
  // const browser = detect();
  useEffect(() => {
    ConnectSocket();
  }, []);

  return (
    <BG>
      {enable === false ? (
        <Loading />
      ) : (
        <>
          <SocketContext.Provider value={socket}>
            <Route exact path="/">
              <Transition showPop={isForgotPassword} type="st2b">
                <Forgotpassword
                  hideRegister={true}
                  closeClick={(e) => {
                    if (e === "Button Cancel") {
                      redirectToLogin();
                    } else {
                      SetIsForgotPassword(false);
                      SetIsLogin(true);
                    }
                  }}
                />
              </Transition>
            </Route>

            <Route exact path="/register">
              <CreateAccountPage />
            </Route>

            <Route exact path="/createbankaccount">
              <BankAccountPage />
            </Route>
          </SocketContext.Provider>
        </>
      )}
      <Transition showPop={isLogin} type="st2b">
        <Login
          hideRegister
          closeClick={(e) => {
            if (e === "cancel") {
              redirectToLogin();
            } else {
              SetIsLogin(false);
            }
            // dispatch(actionPopup({ login: false }));
          }}
        />
      </Transition>
    </BG>
  );
}

export default EMApp;
