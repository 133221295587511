import "../styles.css";
import { useSelector } from "react-redux";
import Button from "./Button";
import NumberFormat from "react-number-format";
import Popup from "./Popup";
import React, { useContext, useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import AlertMessage from "../Components/Dialog/AlertMessage";
import { Transition, config } from "../config";
import WithdrawalConfirm from "./WithdrawalConfirm";
import { SocketContext } from "../context/socket";
import { selectuserProfile } from "../Reducers/userProfileSlice";
import breakpoints from "../LandingPageComponents/breakpoints";
import Confirm from "./Dialog/Confirm";

const BG = styled.div`
  user-select: none;
  align-self: center;
  -moz-margin-end: 18vw;
`;

const Imgpiggy = styled.img`
  user-select: none;
  width: 10.5vw;
  top: -11.8vw;
  position: absolute;
  left: 36%;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 28vw;
          /* top: -38.5vw; */
          top: -32vw;
          left: 30.5%;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 20vw;
          top: -19vw;
          left: 36%;
        `}
`;

// const Popup = styled.div`
//   user-select: none;
//   background-image: url("/images/popup_bg.png");
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   box-shadow: inset 0px 0px 3px 1px #ff691f,
//   inset 0px 0px 20px rgba(255, 133, 66, 0.63);
//   border-radius: 1.5vw;
//   position: relative;
//   z-index: 20;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

const RectanglePig = styled.div`
  user-select: none;
  height: 4vw;
  width: 32vw;
  background-color: #000;
  border-radius: 0.5vw;
  margin-left: 9%;
  position: relative;
  margin-top: 4%;
  display: flex;
  justify-content: space-between;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          height: 10vw;
          width: 69vw;
          border-radius: 1.5vw;
          margin-top: 3%;
          left: -2vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          height: 10vw;
          width: 69vw;
          border-radius: 1.5vw;
          margin-top: 10%;
          left: -2vw;
        `}
`;

const Textbalance = styled.div`
  user-select: none;
  align-self: center;
  color: #ff9900;
  font-size: 1.8vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          align-self: center;
          font-size: 4vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          align-self: center;
          font-size: 5vw;
        `}
`;

const Money = styled.div`
  user-select: none;
  color: #bdb9d1;
  font-size: 1.2vw;
  margin-top: 0.5vw;
  position: relative;
  margin-left: 4vw;
  text-align: start;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2.7vw;
          margin-left: 5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 2.7vw;
          margin-left: 5vw;
        `}
`;

const Recmoney = styled.div`
  user-select: none;
  height: 3.5vw;
  width: 32vw;
  background-color: #000;
  border-radius: 0.5vw;
  margin-left: 9%;
  position: relative;
  margin-top: 0.5%;
  color: #fff;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          height: 8.5vw;
          width: 69vw;
          border-radius: 1.5vw;
          left: -2vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          height: 8.5vw;
          width: 69vw;
          border-radius: 1.5vw;
          left: -2vw;
        `}
`;

// const Minimum = styled.div`
//   user-select: none;
//   color: #ff0000;
//   font-size: 0.8vw;
//   margin-top: 0.2vw;
//   position: absolute;
//   margin-left: 3.5vw;
//   ${() =>
//     window.appHeight() / window.innerWidth > config.verticalSizeiphone
//       ? css`
//           margin-left: 6.5vw;
//           font-size: 2.2vw;
//         `
//       : window.appHeight() > window.innerWidth &&
//         css`
//           margin-left: 5vw;
//           margin-top: 0.05vw;
//           font-size: 1.5vw;
//         `}
// `;

const Text3 = styled.div`
  user-select: none;
  color: #bdb9d1;
  margin-top: 0.7vw;
  font-size: 1vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin-top: 2.5vw;
          font-size: 2.3vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          margin-top: 2vw;
          font-size: 2.3vw;
        `}
`;

const Rectanglesmall = styled.div`
  user-select: none;
  height: 3.3vw;
  width: 23vw;
  background-color: #000;
  border-radius: 0.5vw;
  margin-left: 21%;
  margin-top: 0.8%;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          height: 8.3vw;
          width: 50vw;
          border-radius: 1.5vw;
          margin-left: 15vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          height: 8.3vw;
          width: 48vw;
          border-radius: 1.5vw;
          margin-left: 15vw;
        `}
`;

const Bank = styled.img`
  user-select: none;
  position: absolute;
  right: 27.8vw;
  width: 2.7vw;
  margin-top: 0.32vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          left: 15.5vw;
          width: 7.5vw;
          margin-top: 0.32vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          left: 15.5vw;
          width: 7.5vw;
          margin-top: 0.32vw;
        `};
`;

const Credit = styled.div`
  user-select: none;
  color: #bdb9d1;
  font-size: 1vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2.5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 2.5vw;
        `}
`;

const THB = styled.div`
  user-select: none;
  color: #bdb9d1;
  font-size: 1.3vw;
  align-self: center;
  margin-right: 2vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2.5vw;
          margin-right: 2vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 2.5vw;
          margin-right: 2vw;
        `}
`;

const Greybar = styled.div`
  user-select: none;
  position: relative;
  background-color: #595670;
  width: 32vw;
  height: 0.15vw;
  margin-top: 0.5vw;
  margin-left: 3.5vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 69vw;
          height: 0.2vw;
          margin-top: 1.5vw;
          margin-left: 5.5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 69vw;
          height: 0.2vw;
          margin-top: 1.5vw;
          margin-left: 5vw;
        `}
`;

const UnderGreybar = styled.div`
  user-select: none;
  position: relative;
  background-color: #595670;
  width: 32vw;
  height: 0.15vw;
  margin-left: 3.5vw;
  margin-top: 0.7vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 69vw;
          height: 0.2vw;
          margin-left: 5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 69vw;
          height: 0.2vw;
          margin-left: 5vw;
        `}
`;

const Current = styled.div`
  user-select: none;
  color: #bdb9d1;
  font-size: 1vw;
  text-align: end;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2.5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 2.5vw;
        `}
`;

const Promotion = styled.div`
  user-select: none;
  color: #bdb9d1;
  font-size: 1vw;
  text-align: end;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2.5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 2.5vw;
        `}
`;

const Bonus = styled.div`
  user-select: none;
  color: #bdb9d1;
  font-size: 1vw;
  font-weight: 400;
  text-align: start;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2.2vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 2.5vw;
        `}
`;

const Turn = styled.div`
  user-select: none;
  color: #bdb9d1;
  font-size: 0.85vw;
  text-align: start;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 2vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 1.8vw;
        `}
`;

const Num = styled.div`
  user-select: none;
  color: #bdb9d1;
  font-size: 0.85vw;
  text-align: start;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 1.6vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 1.6vw;
        `}
`;

const Box = styled.div`
  user-select: none;
  position: absolute;
  width: 100%;
  height: 100%; //fix
  align-items: center;
  justify-content: center;
  text-align: -webkit-center;
  display: flex;
  z-index: 20;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  @media ${breakpoints.device.line} {
    width: 180%;
    height: 140%;
    top: -10vw;
    left: -32vw;
  }
`;

// const Title = styled.div`
//   user-select: none;
//   top: -5vw;
//   width: 9vw;
//   display: flex;
//   left: 10vw;
//   position: absolute;
//   color: white;
//   height: 5.5vw;
//   font-size: 2vw;
//   line-height: 5vw;
//   z-index: 0;
//   font-weight: 400;
//   text-align: center;
//   padding: 0 5vw;
//   background-clip: border-box, border-box, content-box;
//   background-size: 6.75vw, 6.75vw, 0.83vw;
//   background-position: top 0 left 0, top 0 right 0, top 0 right 0;
//   background-repeat: no-repeat, no-repeat, repeat-x;
//   background-image: url("../${config.theme}/popup_title_bg_left.png"),
//     url("../${config.theme}/popup_title_bg_right.png"),
//     url("../${config.theme}/popup_title_bg_center.png");
//   p {
//     flex-direction: column;
//     justify-content: center;
//     display: flex;
//     align-items: center;
//     position: relative;
//     color: #fff;
//     font-size: 1.7vw;
//     text-align: right;
//     width: 15vw;
//     margin: 0.5vw 0 0 0.5vw;
//     top: -0.5vw;
//   }

//   ${() =>
//     window.appHeight() / window.innerWidth > config.verticalSizeiphone
//       ? css`
//           background-size: 13vw, 13vw, 1.6vw;
//           background-position: top 0px left 0px, top 0 right 0px, top 0 right 0;
//           left: 22vw;
//           height: 8.54vw;
//           font-size: 4vw;
//           padding: 0px 8vw;
//           align-items: center;
//           width: 18vw;
//           top: -18vw;
//           p {
//             flex-direction: column;
//             justify-content: center;
//             display: flex;
//             align-items: center;
//             position: relative;
//             color: #fff;
//             font-size: 3vw;
//             text-align: right;
//             width: 15vw;
//             margin: 0.5vw 0 0 0.5vw;
//             top: -0.5vw;
//           }
//         `
//       : window.appHeight() > window.innerWidth &&
//         css`
//           position: absolute;
//           left: 23vw;
//           top: -5vw;
//         `}/* font-size: 3vw; */
// `;

const ContainerContent = styled.div`
  width: 100%;
`;

const Test = styled.div`
  color: red;
  margin-left: 4vw;
  text-align: left;
  font-size: 1vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          text-align: left;
          font-size: 2.3vw;
          margin-left: 5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          text-align: left;
          font-size: 1.8vw;
          margin-left: 5vw;
        `}
`;

const List = styled.li`
  color: red;
  font-size: 0.75vw;
  width: 35vw;
  text-align: left;
  margin-left: 4vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 1.8vw;
          width: 70vw;
          text-align: left;
          margin-left: 5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 1.5vw;
          width: 60vw;
          text-align: left;
          margin-left: 5vw;
        `}
`;

const CurrentProBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 0.7vw;
`;

const CurrentProBoxLeft = styled.div`
  width: 22%;
  display: flex;
  flex-direction: column;
`;

const CurrentProBoxRight = styled.div`
  width: 72%;
  display: flex;
  flex-direction: column;
`;

function Withdrawal(props) {
  const { t, i18n } = useTranslation();
  // const dispatch = useDispatch();
  const profile = useSelector(selectuserProfile);
  const [toconfirm, setToConfirm] = useState(false);
  const [alertcashback, setAlertCashback] = useState(false);
  const [bonus, setBonus] = useState({});
  const [emitError, setEmitError] = useState({ status: false, message: "" });
  const socket = useContext(SocketContext);
  const [minimumWithdraw, setMinimumWithdraw] = useState(0);
  // const popupStatus = useSelector(selectPopup);
  // console.log("profile.cashback", profile.cashback);
  // console.log("bonus.turn_status", bonus.turn_status);

  useEffect(() => {
    try {
      socket.emit("getTurnProWithDraw", {}, (data) => {
        if (typeof data != "undefined") {
          if (typeof data.results != "undefined") {
            if (data.status) {
              setBonus(data.results.data);
            } else if (data.status === false) {
              setEmitError({ status: true, message: data.errorCode });

              setTimeout(() => {
                setEmitError({ status: false });
              }, 3000);
            }
          }
        }
      });
      socket.emit("get-brand-setting", {}, (data) => {
        // console.log("get-brand-setting", data);
        if (data.status) {
          setMinimumWithdraw(data?.results[0]?.withdraw);
        }
      });
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  }, []);

  return (
    <Box>
      <BG>
        <Popup
          style={{ marginTop: "3.5vw" }}
          title={t("withdrawal")}
          // TitleSize="2vw"
          TitleSize={
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "3vw"
              : window.appHeight() > window.innerWidth
              ? "3vw"
              : window.innerWidth / window.appHeight() > 2.15 && "2vw"
          }
          bgClass="Profile"
          width={
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "78.98vw"
              : window.appHeight() > window.innerWidth
              ? "79vw"
              : window.innerWidth / window.appHeight() > 2.15
              ? "38.98vw"
              : "38.98vw"
          }
          height={
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "80vw"
              : window.appHeight() > window.innerWidth
              ? "76vw"
              : window.innerWidth / window.appHeight() > 2.15
              ? "34vw"
              : "34vw"
          }
          closebuttonRight="-2vw"
          closebuttonTop="-2vw"
          // closeClick={props.setWithdrawal}
          closeClick={props.closeClick}
        >
          <Imgpiggy src={"../" + config.theme + "/icon_withdrawal.png"} />

          <RectanglePig>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Credit style={{ marginLeft: "0.5vw" }}>{t("credit")}</Credit>
              <Credit
                style={{
                  whiteSpace: "nowrap",
                  marginLeft: "10%",
                  marginLeft: "1vw",
                }}
              >
                {t("balance")}
              </Credit>
            </div>

            <Textbalance>
              <NumberFormat
                value={profile.balance}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
              ></NumberFormat>
            </Textbalance>
            <THB>{t("thb")}</THB>
          </RectanglePig>

          <Money>{t("money withdrawal")}</Money>
          <Recmoney>
            <div
              style={{
                color: "white",
                fontSize:
                  window.appHeight() / window.innerWidth >
                  config.verticalSizeiphone
                    ? "5vw"
                    : window.appHeight() > window.innerWidth
                    ? "5vw"
                    : window.innerWidth / window.appHeight() > 2.15
                    ? "1.5vw"
                    : "1.5vw",
                paddingTop:
                  window.appHeight() / window.innerWidth >
                  config.verticalSizeiphone
                    ? "1%"
                    : window.appHeight() > window.innerWidth
                    ? "0vw"
                    : window.innerWidth / window.appHeight() > 2.15
                    ? "2%"
                    : "2%",
                textAlign: "end",
                marginRight: "2%",
              }}
            >
              <NumberFormat
                value={profile.balance}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
              ></NumberFormat>
            </div>
          </Recmoney>

          <ContainerContent>
            <Test> {t("notice")}</Test>
            {bonus.turn_status === 0 || bonus.turn_status === null ? (
              <List>{t("Minimum withdrawal", { value: minimumWithdraw })}</List>
            ) : null}

            <List>{t("text notice")}</List>
          </ContainerContent>

          {/* <Minimum style={{ color: "FF0000" }}>
            {bonus.turn_status == 0 || bonus.turn_status == null
              ? t("Minimum withdrawal")
              : ""}
          </Minimum> */}
          <Greybar />
          <CurrentProBox>
            <CurrentProBoxLeft>
              <Current>{t("currentwithdraw")}</Current>
              <Promotion>{t("current promotion")}</Promotion>
            </CurrentProBoxLeft>
            <CurrentProBoxRight>
              {bonus.turn_status === 1 ? (
                <>
                  {i18n.language === "th" ? (
                    <Bonus>{bonus.promotion_name}</Bonus>
                  ) : (
                    <Bonus>{bonus.promotion_name_en}</Bonus>
                  )}

                  <Turn>{t("turn amount")}</Turn>
                  <Num>
                    <NumberFormat
                      value={bonus.credit}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                    &nbsp;/&nbsp;
                    <NumberFormat
                      value={bonus.turnSlot_target}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </Num>
                </>
              ) : (
                ""
              )}
            </CurrentProBoxRight>
          </CurrentProBox>
          <UnderGreybar />

          <Text3>{t("transfer bank")}</Text3>

          <Rectanglesmall>
            <Bank
              src={
                profile.bankShortName === ""
                  ? ""
                  : "../" +
                    config.theme +
                    "/bicon_" +
                    profile.bankShortName +
                    ".png"
              }
            />
            <div
              style={{
                color: "white",
                fontSize:
                  window.appHeight() / window.innerWidth >
                  config.verticalSizeiphone
                    ? "3.2vw"
                    : window.appHeight() > window.innerWidth
                    ? "3.5vw"
                    : window.innerWidth / window.appHeight() > 2.15
                    ? "1.5vw"
                    : "1.5vw",
                fontWeight: "bold",
                top: "18%",
                position: "relative",
                left: "5%",
                textAlign: "center",
              }}
            >
              {typeof profile.bankAccount !== "undefined"
                ? profile.bankAccount.length === 10
                  ? profile.bankAccount.slice(0, 3) +
                    "-" +
                    profile.bankAccount.slice(3, 4) +
                    "-" +
                    profile.bankAccount.slice(4, 9) +
                    "-" +
                    profile.bankAccount.slice(9, 10)
                  : profile.bankAccount
                : ""}
            </div>
          </Rectanglesmall>

          {profile.cashback > 0 ? (
            <Button
              bClass="rbGreen"
              label={t("confirm")}
              width={
                window.appHeight() / window.innerWidth >
                config.verticalSizeiphone
                  ? "21vw"
                  : window.appHeight() > window.innerWidth
                  ? "25vw"
                  : window.innerWidth / window.appHeight() > 2.15
                  ? "15vw"
                  : "15vw"
              }
              fontSize={
                window.appHeight() / window.innerWidth >
                config.verticalSizeiphone
                  ? "3vw"
                  : window.appHeight() > window.innerWidth
                  ? "2.5vw"
                  : window.innerWidth / window.appHeight() > 2.15
                  ? "1.8vw"
                  : "1.8vw"
              }
              top="unset"
              style={{
                marginLeft:
                  window.appHeight() / window.innerWidth >
                  config.verticalSizeiphone
                    ? "28vw"
                    : window.appHeight() > window.innerWidth
                    ? "28vw"
                    : window.innerWidth / window.appHeight() > 2.15
                    ? "12vw"
                    : "12vw",
                top:
                  window.appHeight() / window.innerWidth >
                  config.verticalSizeiphone
                    ? "64vw"
                    : window.appHeight() > window.innerWidth
                    ? "68vw"
                    : window.innerWidth / window.appHeight() > 2.15
                    ? "27vw"
                    : "27vw",
                position: "absolute",
              }}
              onClick={() => {
                setAlertCashback(true);
              }}
            />
          ) : profile.balance < 5 ||
            (bonus.turn_status === 1 && bonus.turnSlot_remain > 0) ||
            bonus.turn_status === null ||
            bonus.turnSlot_remain === null ? (
            <Button
              bClass="rbGreen"
              label={t("confirm")}
              width={
                window.appHeight() / window.innerWidth >
                config.verticalSizeiphone
                  ? "21vw"
                  : window.appHeight() > window.innerWidth
                  ? "25vw"
                  : window.innerWidth / window.appHeight() > 2.15
                  ? "15vw"
                  : "15vw"
              }
              fontSize={
                window.appHeight() / window.innerWidth >
                config.verticalSizeiphone
                  ? "3vw"
                  : window.appHeight() > window.innerWidth
                  ? "2.5vw"
                  : window.innerWidth / window.appHeight() > 2.15
                  ? "1.8vw"
                  : "1.8vw"
              }
              top="unset"
              style={{
                marginLeft:
                  window.appHeight() / window.innerWidth >
                  config.verticalSizeiphone
                    ? "28vw"
                    : window.appHeight() > window.innerWidth
                    ? "28vw"
                    : window.innerWidth / window.appHeight() > 2.15
                    ? "12vw"
                    : "12vw",
                top:
                  window.appHeight() / window.innerWidth >
                  config.verticalSizeiphone
                    ? "64vw"
                    : window.appHeight() > window.innerWidth
                    ? "68vw"
                    : window.innerWidth / window.appHeight() > 2.15
                    ? "27vw"
                    : "27vw",
                position: "absolute",
              }}
              onClick={() => {
                setToConfirm(true);
              }}
            />
          ) : (
            <Button
              {...props}
              bClass="rbGreen"
              label={t("confirm")}
              width={
                window.appHeight() / window.innerWidth >
                config.verticalSizeiphone
                  ? "21vw"
                  : window.appHeight() > window.innerWidth
                  ? "25vw"
                  : window.innerWidth / window.appHeight() > 2.15
                  ? "15vw"
                  : "15vw"
              }
              fontSize={
                window.appHeight() / window.innerWidth >
                config.verticalSizeiphone
                  ? "3vw"
                  : window.appHeight() > window.innerWidth
                  ? "2.5vw"
                  : window.innerWidth / window.appHeight() > 2.15
                  ? "1.8vw"
                  : "1.8vw"
              }
              top="unset"
              style={{
                marginLeft:
                  window.appHeight() / window.innerWidth >
                  config.verticalSizeiphone
                    ? "28vw"
                    : window.appHeight() > window.innerWidth
                    ? "28vw"
                    : window.innerWidth / window.appHeight() > 2.15
                    ? "12vw"
                    : "12vw",
                top:
                  window.appHeight() / window.innerWidth >
                  config.verticalSizeiphone
                    ? "64vw"
                    : window.appHeight() > window.innerWidth
                    ? "68vw"
                    : window.innerWidth / window.appHeight() > 2.15
                    ? "27vw"
                    : "27vw",
                position: "absolute",
              }}
              onClick={() => {
                setToConfirm(true);
              }}
            ></Button>
          )}
        </Popup>

        <Transition showPop={toconfirm}>
          <BG style={{ justifyContent: "center" }}>
            <WithdrawalConfirm
              setToConfirm={() => setToConfirm(false)}
              closeClick={props.closeClick}
            />
          </BG>
        </Transition>

        <Transition showPop={alertcashback}>
          <BG style={{ justifyContent: "center" }}>
            <Confirm
              message={t("text notice")}
              setAlertCashback={() => setAlertCashback(false)}
              cancelClick={() => {
                props.closeClick();
              }}
              confirmClick={() => {
                setToConfirm(true);
              }}
            />
          </BG>
        </Transition>

        <Transition showPop={emitError.status} type="alert">
          <AlertMessage
            message={t(emitError.message)}
            onClick={() => {
              setEmitError({ status: false });
            }}
          />
        </Transition>
      </BG>
    </Box>
  );
}

export default Withdrawal;
