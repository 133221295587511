import React from "react";
import styled, { css } from "styled-components";
import Button from "./Button";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { actionPopup } from "../Reducers/popupSlice";
import { setSelectedIndex } from "../Reducers/inviteFriendSlice";
import { config } from "../config";

const Popup = styled.div`
  user-select: none;
  /* margin-top: 20vw; */
  display: flex;
  flex-direction: column;
  /* margin: 0 auto; */
  min-width: 25vw;
  max-width: 45vw;
  height: max-content;
  width: max-content;
  // background-color: #2f2d3b;
  border-radius: 4vw;
  // border: solid 3vw;
  // border-image-slice: 50;
  // border-image-source: url("../${config.theme}/popup_frame_mini.png");
  // theme ==================
  background: #192756;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #5dd8ff,
    inset 0px 0px 4vw rgba(66, 255, 232, 0.63);
  border: solid 0.2vw #bdefff;
  border-radius: 2vw;
  padding: 2.5vw;
  // theme ==================
  justify-content: center;
  text-align: center;
  align-items: center;
  color: #bcb8d0;
  font-size: 2vw;
`;

const ButConfirm = styled.div`
  user-select: none;
  position: relative;
  z-index: 70;
  display: flex;
  margin-top: -2vw;
`;

const Box = styled.div`
  user-select: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  text-align: -webkit-center;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  z-index: 50;
  align-items: center;
  justify-content: center;
  p {
    color: #bdb9d1;
    text-align: center;
    font-size: 1.4vw;
    width: 100%;
    line-height: 2vw;
    -webkit-text-size-adjust: 100%;
  }
  h5 {
    font-size: 2.5vw;
    color: white;
    margin: 0;
    font-weight: 400;
  }
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          p {
            font-size: 3vw;
            line-height: 4vw;
          }
          h5 {
            font-size: 3vw;
          }
        `
      : window.appHeight() > window.innerWidth &&
        css`
          p {
            font-size: 2vw;
            line-height: 3vw;
          }
          h5 {
            font-size: 2vw;
          }
        `}
`;

function CashbackPopup(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Box>
      <Popup style={{ height: props.height }} {...props}>
        {/* <p>{props.children}</p> */}
        <p>{t("alert casino cashback")}</p>
      </Popup>
      <ButConfirm>
        <Button
          bClass="rbRed"
          label={t("not yet")}
          // width="13vw"
          width={
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "25vw"
              : window.appHeight() > window.innerWidth
              ? "20vw"
              : window.innerWidth / window.appHeight() > 2.15
              ? "13vw"
              : "13vw"
          }
          fontSize={
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "3vw"
              : window.appHeight() > window.innerWidth
              ? "2vw"
              : window.innerWidth / window.appHeight() > 2.15
              ? "1.5vw"
              : "1.5vw"
          }
          onClick={props.cancelClick}
          style={{ padding: 0 }}
        />
        <Button
          label={t("claim now")}
          // width="13vw"
          width={
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "25vw"
              : window.appHeight() > window.innerWidth
              ? "20vw"
              : window.innerWidth / window.appHeight() > 2.15
              ? "13vw"
              : "13vw"
          }
          fontSize={
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "3vw"
              : window.appHeight() > window.innerWidth
              ? "2vw"
              : window.innerWidth / window.appHeight() > 2.15
              ? "1.5vw"
              : "1.5vw"
          }
          style={{ padding: 0 }}
          onClick={() => {
            props.confirmClick();
            dispatch(actionPopup({ inviteFriend: true }));
            // setTimeout(() => {
            //   window.document.getElementById("Invite-Menu-2").click();
            // }, 100);
            dispatch(setSelectedIndex(2));
          }}
        />
      </ButConfirm>
    </Box>
  );
}
export default CashbackPopup;
