import styled from "styled-components";
import breakpoint from "./breakpoints";
import { config } from "../config";
const PopupContainer = styled.div`
  background-color: #222535;
  width: max-content;
  height: max-content;
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;
  // theme ==================
  /* background: #192756;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #5DD8FF, inset 0px 0px 4vw rgba(66, 255, 232, 0.63);
  border: solid 0.2vw #BDEFFF; */
  // theme ==================
  // box-shadow: inset 0px 0px 3px 4px #FF7C7C, inset 0px 0px 35px #E44444;
  border-radius: 1.7vw;
  padding: 1.5vw;
  margin: 0 auto;
  position: relative;
  @media ${breakpoint.device.mobile} {
    padding: 3.5vw;
    // box-shadow: inset 0px 0px 3px 4px #FF7C7C, inset 0px 0px 35px #E44444;
    //       background: #302e3c;
    // box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f, inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
    // border: solid 0.2vw orange;
    // theme ==================
    background: #302e3c;
    box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
      inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
    border: solid 0.2vw orange;
    // theme ==================
  }
`;

const Close = styled.img`
  width: 2.5vw;
  cursor: pointer;
  position: absolute;
  top: -1vw;
  right: -1vw;
  @media ${breakpoint.device.mobile} {
    width: 7.5vw;
    top: -3vw;
    right: -3vw;
  }
`;

const Title = styled.div`
  user-select: none;
  /* top: -50vw;  */
  /* left: auto;
    display: inline-block; */
  width: ${(props) =>
    props.header === "header"
      ? `null`
      : ` max-content;
  `};
  /* display: inline-block; */
  /* position: absolute; */
  margin: ${(props) =>
    props.header === "header"
      ? `0 6vw;`
      : ` 0 auto;
  `};
  color: white;
  height: 6vw;
  margin-top: -1.5vw;
  font-size: 2vw;
  z-index: 0;
  font-weight: 400;
  text-align: center;
  line-height: 5vw;
  padding: 0 9vw;
  background-clip: border-box, border-box, content-box;
  background-size: ${(props) =>
    props.size === "size"
      ? `70vw`
      : ` 9vw,
      9vw,
      1.11vw;
  `};
  background-position: top 0 left 4vw, top 0 right 4vw, top 0 right 0;
  background-repeat: no-repeat, no-repeat, repeat-x;
  // background-image:
  //     url('../${config.theme}/popup_title_bg_left.png'),
  //     url('../${config.theme}/popup_title_bg_right.png'),
  //     url('../${config.theme}/popup_title_bg_center.png');

  background-image: ${(props) =>
    props.header === "header"
      ? // `url('../${config.theme}/diamond_shop_title_bg.png')
      // `
      `none`
      : `url('../${config.theme}/popup_title_bg_left.png'),
      url('../${config.theme}/popup_title_bg_right.png'),
      url('../${config.theme}/popup_title_bg_center.png');
  `};
`;
function Popup(props) {
  return (
    <PopupContainer>
      <center>
        <Title
          header={props.header}
          size={props.size}
          style={{
            fontSize: props.TitleSize,
            display: props.display,
          }}
        >
          {props.header === "header" ? (
            <>
              <img alt=""
                src={"../" + config.theme + "/diamond_shop_title_bg.png"}
                style={{
                  width: "67vw",
                  top: "-4vw",
                  marginLeft:
                    window.innerWidth / window.appHeight() > 2.15
                      ? "-8vw"
                      : // : "-9.7vw",
                      "-11.25vw",
                  position: "relative",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "-2.5vw",
                  // left: "2.8vw",
                  left: "-0.3vw",
                  right: "0vw",
                  fontSize: "1.8vw",
                }}
              >
                {props.title}
              </div>
            </>
          ) : (
            props.title
          )}
        </Title>
      </center>
      {props.children}
      {!props.forget ? (
        <Close
          src="../../lpimages/btn_popup_close.png"
          style={{ right: props.closebuttonRight, top: props.closebuttonTop }}
          onClick={props.closeClick}
        />
      ) : (
        ""
      )}
    </PopupContainer>
  );
}

export default Popup;
