import React, { useState, useEffect, useContext, useRef } from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { redirect2Page, Transition, getParameterByName } from "../../config";
import AlertMessage from "../Dialog/AlertMessage";
import PinInput from "react-pin-input";
import { SocketContext } from "../../context/socket";
import Loading from "../Loading";
import Alert from "../Dialog/Alert";
// import { getFullUrl, redirectToLogin } from "../../config";
import { config, localStorageGetWithExpiry } from "../../config";
import {
  selectuserProfile,
  actionSetprofile,
} from "../../Reducers/userProfileSlice";
import {
  setFloatingValue,
  setFloatingStatus,
  setFloatingLabel,
} from "../../Reducers/floatingInputSlice";
import { selectCheckFTUEPopup } from "../../Reducers/checkFTUEPopupSlice";
import ReCAPTCHA from "react-google-recaptcha";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { RGCaptcha, reset, verify } from "react-geetest-captcha";
import Language from "../Language";
import i18next from "i18next";

const BG = styled.div`
  user-select: none;
  width: 100vw;
  height: ${(props) => props.uiHeight + "px"};
  left: 0%;
  top: 0%;
  background-color: #302e3c;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0 auto;
`;

const Content = styled.div`
  font-size: min(5vw, 30px);
  color: #fff;
  display: flex;
  margin-top: 5%;
  text-align: start;
`;

const InputReferCode = styled.div`
  width: 96.7%;
  height: 100%;
  color: #fff;
  font-size: min(4vw, 24px);
  background-color: #000000;
  border: none;
  border-radius: min(3vw, 18px);
  outline: transparent;
  display: flex;
  padding-left: 5%;
  p {
    display: flex;
    font-size: min(4vw, 24px);
    align-items: center;
  }
`;

const InputRefer = styled.input`
  width: 96.7%;
  height: 100%;
  color: #fff;
  font-size: min(4vw, 24px);
  background-color: #000000;
  border: none;
  border-radius: min(3vw, 18px);
  outline: transparent;
  display: flex;
  padding-left: 5%;
  ::placeholder {
    color: #333333;
    font-size: min(4vw, 24px);
    align-items: center;
  }
`;

const InputOtp = styled.input`
  width: 96.7%;
  height: 100%;
  color: #fff;
  font-size: min(5vw, 30px);
  background-color: #000000;
  border-radius: min(3vw, 18px);
  border: none;
  outline: transparent;
  padding-left: 5%;

  ${(props) =>
    props.errorStatus === true &&
    css`
      border: 2px solid #ff7979;
    `}

  ::placeholder {
    color: #333333;
    font-size: min(5vw, 30px);
    align-items: center;
  }
`;

const NoneInputOtp = styled.input`
  width: 96.7%;
  height: 100%;
  color: #fff;
  font-size: min(5vw, 30px);
  background-color: #000000;
  border: none;
  border-radius: min(3vw, 18px);
  outline: transparent;
  padding-left: 5%;
  ::placeholder {
    color: #333333;
    font-size: min(5vw, 30px);
    align-items: center;
  }
`;

const InputPhone = styled.input`
  width: 96.7%;
  height: 100%;
  color: #fff;
  font-size: min(5vw, 30px);
  background-color: #000000;
  border: none;
  border-radius: min(3vw, 18px);
  outline: transparent;
  padding-left: 5%;

  ::placeholder {
    color: #333333;
    font-size: min(5vw, 30px);
    align-items: center;
  }

  ${(props) =>
    props.errorStatus === true &&
    css`
      border: 2px solid #ff7979;
    `}
`;

const OtpButton = styled.button`
  width: 36.73%;
  height: 83.33%;
  font-size: min(4vw, 24px);
  border-radius: min(3vw, 18px);
  right: 1.5%;
  border: none;
  background: #ff691f;
  color: #fff;
  white-space: normal;
  cursor: pointer;
  position: absolute;
`;

const OtpButtonGray = styled.button`
  width: 36.73%;
  height: 83.33%;
  font-size: min(4vw, 24px);
  border-radius: min(3vw, 18px);
  right: 1.5%;
  border: none;
  background: #686678;
  color: #fff;
  white-space: normal;
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContainerPhone = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: min(12vw, 72px);
`;

const ContainerOTPInput = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: min(12vw, 72px);
`;

const ContainerDropDown = styled.div`
  position: relative;
  flex-direction: column;
  width: 98%;
  height: min(12vw, 72px);
  display: block;
`;

const ContainerError = styled.div`
  display: flex;
  flex-direction: row;
`;

const ContentPhone = styled.div`
  font-size: min(5vw, 30px);
  width: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5%;
`;

const Text = styled.div`
  user-select: none;
  color: #fff;
  font-size: min(5vw, 30px);
  display: flex;
  align-items: center;
  text-align: left;
`;

const ContainerButtonStep = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin-top: min(5vw, 30px);
`;

const CorrectInput = styled.div`
  position: absolute;
  width: 14%;
  right: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
  }
`;

const WarningMessage = styled.div`
  display: flex;
  color: #e13b00;
  font-size: min(4.5vw, 27px);
  margin-top: 2%;
`;

const WarningKnowUs = styled.div`
  display: flex;
  color: #e13b00;
  font-size: min(4vw, 24px);
`;

const ContainerPincode = styled.div`
  width: 100%;
  height: min(30vw, 180px);
  margin-top: 5%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Textsupport = styled.div`
  user-select: none;
  width: 100%;
  height: min(15vw, 90px);
  font-size: min(5vw, 30px);
  margin-top: 2%;
  color: #ffffff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: left;
  line-height: min(6vw, 36px);
  white-space: normal;
`;

const Spinixtext = styled.div`
  user-select: none;
  width: 40%;
  height: 80%;
  color: #fff;
  font-size: min(4vw, 24px);
  padding-left: 10%;
  cursor: pointer;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../${config.theme}/newasset/btnLineContact.png");
`;

const Greybar = styled.div`
  user-select: none;
  width: 100%;
  height: 0.08vw;
  background-color: #474761;
  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 100%;
          height: 0.16vw;
          margin-top: 2%;
        `
      : window.innerHeight > window.innerWidth &&
        css`
          width: 100%;
          height: 0.16vw;
          margin-top: 2%;
        `}
`;

const Textotp = styled.div`
  user-select: none;
  color: #e13b00;
  display: flex;
  font-size: min(4.5vw, 27px);
`;

const Timer = styled.div`
  user-select: none;
  color: #e13b00;
  font-size: min(4.5vw, 27px);
  width: min(12vw, 72px);
  position: relative;
`;

const Mintext = styled.div`
  user-select: none;
  color: #e13b00;
  font-size: min(4.5vw, 27px);
  position: relative;
  text-align: left;
`;

const ContainerOTP = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 2%;
`;

const ReCAPTCHACustom = styled(ReCAPTCHA)`
  margin: 1vw auto;
  width: 100%;
  > div {
    display: flex;
    justify-content: center;
  }
`;

const DropDown = styled.div`
  width: 97%;
  height: 100%;
  color: #fff;
  font-size: min(4vw, 24px);
  padding-left: 5%;
  text-align: left;
  background-color: #000000;
  border: none;
  border-radius: min(3vw, 18px);
  outline: transparent;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const FontIcon = styled(FontAwesomeIcon)`
  font-size: min(4vw, 24px);
  color: #fff;
  position: absolute;
  right: 2%;
`;

const DropDownContainer = styled.div`
  user-select: none;
  width: 94%;
  font-size: min(2.5vw, 15px);
  color: #ffffff;
  background-color: #000000;
  border-radius: min(3vw, 18px);
  padding: 4%;
  display: grid;
  grid-template-rows: repeat(5, auto);
  grid-gap: 0;
  grid-auto-flow: column;
  position: absolute;
  z-index: 25;
  bottom: 100%;
`;

const SelectBox = styled.div`
  width: 100%;
  font-size: min(5vw, 30px);
  text-align: left;
  display: flex;
  align-items: center;
  margin: min(1.5vw, 9px);
  cursor: pointer;
  position: relative;
  .checkbox {
    width: min(2.5vw, 15px);
    height: min(2.5vw, 15px);
    position: absolute;
    z-index: -1;
  }
  p {
    margin-left: 5%;
  }
`;

const Box = styled.div`
  width: min(4vw, 24px);
  height: min(4vw, 24px);
  background: #302e3c;
  border: min(1.2vw, 7.2px) solid #302e3c;
  border-radius: min(1vw, 6px);
  ${(props) =>
    props.selectBox == true &&
    css`
      background-color: #ff7000;
    `}
`;

const PopupBox = styled.div`
  width: 93%;
  max-width: 600px;
  height: 100%;
  display: block;
  flex-direction: column;
  position: relative;
`;

const Header = styled.div`
  width: 100%;
  height: min(12vw, 72px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: min(6.5vw, 39px);
  color: #fff;
  white-space: nowrap;
  /* background-color: #ff691f; */
`;

const RedButton = styled.div`
  /* width: min(36vw, 216px); */
  width: 37.5%;
  height: min(12vw, 72px);
  background: linear-gradient(180deg, #e13b00 38.02%, #900000 100%);
  box-shadow: inset 0px 0px 3.44311px 1.72155px #ff814d;
  border-radius: min(3vw, 18px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: min(5vw, 30px);
  cursor: pointer;
`;

const GreenButton = styled.div`
  /* width: min(42vw, 252px); */
  width: 59.43%;
  height: min(12vw, 72px);
  background: ${(props) =>
    props.color === "gray"
      ? "linear-gradient(180deg, #B3B3B3 38.02%, #565656 100%)"
      : "linear-gradient(180deg, #62d706 38.02%, #3b9604 100%)"};
  box-shadow: ${(props) =>
    props.color === "gray"
      ? "inset 0px 0px 3.44311px 1.72155px #BCBCBC"
      : "inset 0px 0px 3.44311px 1.72155px #4dff80"};
  border-radius: min(3vw, 18px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: min(5vw, 30px);
  cursor: ${(props) => (props.color === "gray" ? "" : "pointer")};
`;

const ScrollerBox = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  ::-webkit-scrollbar {
    width: 0vw;
    height: 0vw;
    z-index: 10;
  }
  ::-webkit-scrollbar-thumb {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 10;
    position: absolute;
    width: 11px;
    height: 80px;
    background: linear-gradient(180deg, #e13b00 38.02%, #900000 100%);
    box-shadow: inset 0px 0px 4px 2px #ff814d;
    border-radius: 10px;
  }
`;

const LangBox = styled.div`
  width: min(15vw, 90px);
  height: min(15vw, 90px);
  position: absolute;
  /* top: 5%; */
  right: 5%;
  cursor: pointer;
  background-image: ${(props) => `url(../${config.theme}/${props.icon})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

const BrandLogo = styled.div`
  width: 100%;
  height: min(11vw, 66px);
  background-image: url("../${config.theme}/spinix_normal_white_1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 5%;
`;

function CreateAccount(props) {
  const [referCodeBy, setReferCodeBy] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const { t } = useTranslation();
  const [isInputError, setInputError] = useState(false);
  const [complete, setComplete] = useState(false);
  const [brands, setBrands] = useState(selectuserProfile.brands_id);
  const profile = useSelector(selectuserProfile);
  const socket = useContext(SocketContext);
  const [step, setStep] = useState(1);
  const [showConfirm, setShowConfirm] = useState(false);
  const [toCreateAccount, setToCreateAccount] = useState(false);
  const [pinCode, setPincode] = useState("");
  const [pincodeLength, setPincodeLength] = useState(4);
  const [emitError, setEmitError] = useState({ status: false, message: "" });
  const [reqNewOtp, setReqNewOtp] = useState(false);
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  const [errorRefer, setShowErrorRefer] = useState(false);
  const [errorReferLink, setShowErrorReferLink] = useState(false);
  const [errorOtp, setErrorOtp] = useState(false);
  const [notDuplicateTel, setNotDuplicateTel] = useState(false);
  const [countDownTime, setCountDownTime] = useState({ min: 0, sec: 0 });
  const [timeleftCount, setTimeleftCount] = useState(0);
  const [countDown, setCountDown] = useState({ min: 0, sec: 0 });
  const [timeleft, setTimeleft] = useState(0);
  const [showClosePopup, setShowClosePopup] = useState(false);
  const [refer, setRefer] = useState(false);
  // const refercode = profile.refercodeby;
  const [refercode, setRefercode] = useState(
    localStorageGetWithExpiry("refercodeby") || ""
  );
  const [refercodeEdit, setRefercodeEdit] = useState(false);
  const [lineId, setLineId] = useState([{}]);
  const [arrayUrl, setArrayUrl] = useState("");
  const checkFTUEPopup = useSelector(selectCheckFTUEPopup);
  const [captcha, setCaptcha] = useState(null);
  const [optionWarning, setOptionWarning] = useState(false);
  const [correctOTP, setCorrectOTP] = useState(false);
  const [wrongOTPAlert, setWrongOTPAlert] = useState(false);
  const [reCaptchaState, setReCaptchaState] = useState({
    status: 0,
    captcha_type: 0,
    site_key: "",
  });
  const [changePhoneNumber, setChangePhoneNumber] = useState(false);
  const [doneChangePhone, setDoneChangePhone] = useState(false);
  const [uiHeight, setUiHeight] = useState(window.innerHeight);
  const ref = useRef();
  const [showLanguagePop, setShowLanguagePop] = useState(false);

  document.body.style.overflow = "hidden";

  useEffect(() => {
    try {
      socket.emit("getbrandcaptcha", {}, (data) => {
        console.log("Recaptcha", data);
        setReCaptchaState(data.results);
      });
    } catch (error) {}
  }, []);

  // Captcha
  const CAPNAME = "captchaRegister";
  const [token, setToken] = useState(null);

  useEffect(() => {
    if (reCaptchaState?.captcha_type == 1 && reCaptchaState?.status == 1) {
      socket.emit("getGeetestToken", {}, (body) => {
        console.log("getGeetestToken", body);
        setToken(body);
      });
    }
  }, [reCaptchaState]);

  // ----------------------start dropdown --------------------------- //
  const [openDropDown, setOpenDropDown] = useState(false);
  const DropDownOption = [
    "Facebook",
    "Tiktok",
    "Twitter",
    "Line",
    "Youtube",
    "SMS",
    "word of mouth",
    "Google",
    "other",
  ];
  // const DropDownOption = research.answers;

  const [checkedState, setCheckedState] = useState({
    option: new Array(DropDownOption.length).fill(false),
  });
  const [selectedOption, setSelectedOption] = useState({ option: "" });

  const handleOption = (position) => {
    const updatedCheckedOption = checkedState.option.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState({ ...checkedState, option: updatedCheckedOption });

    const selectOption = updatedCheckedOption.map((itemState, index) => {
      if (itemState == true) {
        return DropDownOption[index];
      }
    });
    setSelectedOption({
      ...selectedOption,
      option: selectOption.filter((option) => option != undefined),
    });
  };
  // console.log("selectedOption", selectedOption.option.length);

  // ---------------------- end dropdown  --------------------------- //

  let reqPhone = new RegExp(/^[0-9\b]+$/);
  let reqSpace = new RegExp(/^[_]+$/);
  let reqBlankSpace = new RegExp(/(^\s)/); // start and end >> /(^\s)|(\s$)/

  const onChange = (val, idx) => {
    if (val.length < pincodeLength) {
      setPincode(val);
    }
  };

  const onComplete = (val, idx) => {
    setPincode(val);
  };

  function validTelPhone(tel = "", debug = false) {
    if (typeof tel != "string") {
      if (debug) {
        // console.log("Type of MissMatch String");
      }
      return false;
    }
    if (tel.length < 0) {
      if (debug) {
        // console.log("String is Zero lenght!");
      }
      return false;
    }

    let cleaned_tel = ("" + tel).replace(/\D/g, "");
    if (debug) {
      // console.log(cleaned_tel);
    }
    let tmp_obj = {
      v1: "",
      v2: "",
      vCheck1: ["0"],
      vCheck2: ["6", "8", "9"],
      result: [false, false],
      sumResult: true,
    };
    tmp_obj.v1 = cleaned_tel.substring(0, 1);
    tmp_obj.v2 = cleaned_tel.substring(1, 2);
    tmp_obj.result[0] = tmp_obj.vCheck1.includes(tmp_obj.v1);
    tmp_obj.result[1] = tmp_obj.vCheck2.includes(tmp_obj.v2);
    for (const value of tmp_obj.result) {
      if (debug) {
        // console.log(value);
      }
      if (value == false) {
        tmp_obj.sumResult = value;
      }
    }
    if (debug) {
      // console.log("result =>", tmp_obj);
    }
    return tmp_obj.sumResult;
  }

  const onSubmit = (data) => {
    try {
      const phone = phoneNumber;
      if (phone.length > 0) {
        let red_valid = validTelPhone(phone, false);
        if (red_valid) {
          // console.log("Ok!");
          socket.emit(
            "getOTPRegister",
            {
              brands_id: brands,
              tel: phoneNumber,
              token: captcha,
              ...data,
            },
            (body) => {
              setLoad(false);
              // console.log("getOTPRegister", body);
              setTimeleft(0);
              setCountDown({ min: 0, sec: 0 });
              if (typeof body != "undefined") {
                if (body.status == true) {
                  setTimeleft(180);
                  setComplete(true);
                  setDoneChangePhone(false);
                  setTimeout(() => {
                    setComplete(false);
                    setStep(2);
                  }, 3000);

                  //  alert("True!!");
                } else if (body.status === false) {
                  if (body.errorCode === "s0032") {
                    setTimeleft(parseInt(body.results.timeleft));
                    setStep(2);
                    setDoneChangePhone(false);
                    setEmitError({ status: true, message: body.errorCode });
                    setTimeout(() => {
                      setEmitError({ status: false });
                    }, 3000);
                  } else if (body.errorCode === "s0056") {
                  } else {
                    setEmitError({ status: true, message: body.errorCode });
                    setTimeout(() => {
                      setEmitError({ status: false });
                    }, 3000);
                  }
                }
              }
            }
          );
          return;
        }
      }
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  };

  const handleOTP = () => {
    if (reqBlankSpace.test(referCodeBy)) {
      // console.log("check with reqBlankSpace");
      setShowErrorRefer(true);
      return;
    }

    try {
      socket.emit(
        // "checkOTPRegister",
        "checkOTP",
        {
          brands_id: brands,
          tel: phoneNumber,
          otp: otp,
          // refercodeby: referCodeBy,
        },
        (body) => {
          // console.log("checkOTPRegister", body);

          if (typeof body != "undefined") {
            if (body.status == true) {
              //   setLoad(true);
              setTimeout(() => {
                // setLoad(false);
                setCorrectOTP(true);
                setWrongOTPAlert(false);
                setStep(3);
              }, 1000);
            } else if (body.status === false) {
              setWrongOTPAlert(true);
              setCorrectOTP(false);
              // alert("false!!");
              if (body.errorCode == "s0031") {
                setErrorOtp(true);
                // setEmitError({ status: true, message: body.errorCode });
                // setTimeout(() => {
                //   setEmitError({ status: false });
                // }, 3000);
              } else if (body.errorCode == "s0075") {
                setShowErrorRefer(true);
              } else {
                setEmitError({ status: true, message: body.errorCode });
                setTimeout(() => {
                  setEmitError({ status: false });
                }, 3000);
              }
            }
          }
        }
      );
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  };

  const handleOtpRefer = () => {
    try {
      socket.emit(
        "checkOTP",
        {
          brands_id: brands,
          tel: phoneNumber,
          otp: otp,
        },
        (body) => {
          // console.log("checkOTP", body);
          if (typeof body != "undefined") {
            if (body.status == true) {
              //   setLoad(true);
              setTimeout(() => {
                // setLoad(false);
                setStep(3);
              }, 1000);
            } else if (body.status === false) {
              // alert("false!!");

              setEmitError({ status: true, message: body.errorCode });
              setTimeout(() => {
                setEmitError({ status: false });
              }, 3000);
            }
          }
        }
      );
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  };

  const reset = () => {
    if (timeleftCount != 0) {
      let time = timeleftCount;
      clearInterval(window.ChangeCountdownTime);
      setCountDownTime(fnTimeleftCount(time));
      window.ChangeCountdownTime = setInterval(() => {
        setCountDownTime(fnTimeleftCount(time));
        if (time <= 0) {
          time = 0;
          clearInterval(window.ChangeCountdownTime);
          // handleTel();
        }

        time = time - 1;
      }, 1000);
    }
    setReqNewOtp(false);
  };

  const fnTimeleftCount = (time) => {
    let min = Math.floor(time / 60);
    let sec = Math.floor(time % 60);
    return {
      min: min,
      sec: sec,
    };
  };

  // useEffect(() => {
  //   if (timeleftCount != 0) {
  //     let time = timeleftCount;
  //     clearInterval(window.ChangeCountdownTime);
  //     setCountDownTime(fnTimeleftCount(time));
  //     window.ChangeCountdownTime = setInterval(() => {
  //       setCountDownTime(fnTimeleftCount(time));
  //       if (time <= 0) {
  //         time = 0;
  //         clearInterval(window.ChangeCountdownTime);
  //         // handleTel();
  //       }

  //       time = time - 1;
  //     }, 1000);
  //   }
  // }, [timeleftCount]);

  //Data Form Backend

  const fnTimeleft = (time) => {
    let min = Math.floor(time / 60);
    let sec = Math.floor(time % 60);
    return {
      min: min,
      sec: sec,
    };
  };

  useEffect(() => {
    if (timeleft != 0) {
      let time = timeleft;
      clearInterval(window.ChangeCountdown);
      setCountDown(fnTimeleft(time));
      window.ChangeCountdown = setInterval(() => {
        if (time <= 0) {
          time = 0;
          clearInterval(window.ChangeCountdown);
          // handleTel();
        }
        setCountDown(fnTimeleft(time));
        time = time - 1;
      }, 1000);
    }
  }, [timeleft]);

  useEffect(() => {
    try {
      // dispatch(actionWinPopSound());
      socket.emit("getLineID", {}, (body) => {
        if (typeof body != "undefined") {
          if (typeof body.results != "undefined") {
            if (typeof body.results.data != "undefined") {
              if (body.status) {
                // console.log(body.results.data.line_id);
                if (
                  body.results.data.line_id &&
                  typeof body.results.data.line_id != undefined
                ) {
                  setLineId(body.results.data.line_id);
                  let arrayLine = body.results.data.line_id.split("/");
                  // console.log(arrayLine,"arrayLine")
                  setArrayUrl(arrayLine[arrayLine.length - 1]);
                  //  console.log(arrayUrl);
                }
              } else if (body.status === false) {
                setEmitError({ status: true, message: body.errorCode });
                setTimeout(() => {
                  setEmitError({ status: false });
                }, 3000);
              }
            }
          }
        }
      });

      // socket.emit("getresearch", {}, (data) => {
      //   console.log("getresearch", data);
      // });
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  }, []);

  useEffect(() => {
    if (otp.length === 6 && step !== 1) {
      handleOTP();
    }
  }, [otp]);

  useEffect(() => {
    if (phoneNumber.length < 10 && step !== 1) {
      setChangePhoneNumber(true);
    } else if (phoneNumber.length === 10 && step !== 1) {
      setChangePhoneNumber(false);
      setDoneChangePhone(true);
    }
  }, [phoneNumber]);

  const openInNewTab = (url) => {
    try {
      const newWindow = window.open(url, "_blank");
      if (newWindow) newWindow.opener = null;
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  };

  const checkReferralCode = () => {
    socket.emit(
      "checkuserreferal",
      {
        referalcode: refercode ? refercode : referCodeBy,
      },
      (body) => {
        // alert("checkuserreferal", body, refercode);
        if (typeof body != "undefined") {
          if (body.status == true) {
            // handleOTP();
            createPincode();
          } else if (body.status === false) {
            // setRefer(true);
            if ((body.errorCode = "s0087")) {
              // setShowErrorRefer(true);
              setShowErrorReferLink(true);
            } else {
              // console.log(body.errorCode);
              setEmitError({ status: true, message: body.errorCode });
              setTimeout(() => {
                setEmitError({ status: false });
              }, 3000);
            }
          }
        }
      }
    );
  };

  const createPincode = () => {
    try {
      socket.emit(
        "createnewuser",
        {
          brands_id: brands,
          tel: phoneNumber,
          otp: otp,
          pincode: pinCode,
          refercode_by: refercode ? refercode : referCodeBy,
          know_us: selectedOption.option,
        },
        (body) => {
          setLoad(false);
          // console.log("createnewuser", body);
          // setLoad(true);
          if (typeof body != "undefined") {
            if (body.status == true) {
              setShowConfirm(true);
              setTimeout(() => {
                setShowConfirm(false);
                if (localStorageGetWithExpiry("register") === "1") {
                  //   dispatch(actionPopup({ registBankAccount: true }));
                  redirect2Page("createbankaccount");
                } else {
                  redirect2Page("");
                }
              }, 3000);

              // redirectToLogin(getFullUrl(body.results.jwtToken))
              dispatch(actionSetprofile({ isTelGuest: true, isguest: true }));
              // console.log(guestuser)
              // console.log(body.results.jwtToken)
              socket.emit(
                "updateTutorial",
                {
                  no: 2,
                },
                (body) => {
                  // console.log(body);
                  if (typeof body != "undefined") {
                    if (body.status) {
                    } else if (body.status === false) {
                      setEmitError({ status: true, message: body.errorCode });
                      setTimeout(() => {
                        setEmitError({ status: false });
                      }, 3000);
                    }
                  }
                }
              );
            } else if (body.status === false) {
              setEmitError({ status: true, message: body.errorCode });
              setTimeout(() => {
                setEmitError({ status: false });
                setLoad(false);
              }, 3000);
            }
          }
        }
      );
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  };

  const CheckDuplicateTel = () => {
    // console.log("out", phoneNumber);
    if (phoneNumber.length == 10) {
      socket.emit(
        "checkduplicatetel",
        {
          tel: phoneNumber,
        },
        (body) => {
          // console.log("IN", body);
          if (typeof body != "undefined") {
            if (body.status == true) {
              setNotDuplicateTel(true);
            } else if (body.status === false) {
              setNotDuplicateTel(false);
            } else {
              setEmitError({ status: true, message: body.errorCode });
              setTimeout(() => {
                setEmitError({ status: false });
              }, 3000);
            }
          }
        }
      );
    }
  };

  const onChangeCaptcha = (value) => {
    console.log("Captcha value:", value);
    setCaptcha(value);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (openDropDown && ref.current && !ref.current.contains(e.target)) {
        setOpenDropDown(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [openDropDown]);

  // console.log("profile:", profile);

  const sendOTP = (data) => {
    // setLoad(true);
    onSubmit(data);
    // reset();
  };

  const getOTPBtn = () => {
    // console.log("reCaptchaState?.status", reCaptchaState?.status);
    if (reCaptchaState?.captcha_type == 1 && reCaptchaState?.status == 1) {
      verify(CAPNAME);
    } else {
      sendOTP();
    }
  };

  const handleResize = () => {
    setUiHeight(window.innerHeight);
    // alert("Height Change to " + window.innerHeight);
  };

  var currentLang = localStorage.getItem("language");
  useEffect(() => {
    try {
      i18next.changeLanguage(
        currentLang != null ? currentLang.toLowerCase() : "th"
      );
    } catch (error) {
      setEmitError({ status: true, message: error.message });
      setTimeout(() => {
        setEmitError({ status: false });
      }, 3000);
    }
  }, [currentLang]);

  window.addEventListener("resize", handleResize);

  return (
    <BG uiHeight={uiHeight}>
      {/* <ReCAPTCHA
        sitekey="6Lcf__UfAAAAAGx-fW0WXVCrPUl84ciNCHRxxlqt"
        onChange={onChangeCaptcha}
      /> */}
      {load == true ? <Loading /> : ""}
      <ScrollerBox>
        <PopupBox>
          <BrandLogo />
          <Header>
            {t("pin register")}
            <LangBox
              onClick={() => {
                setShowLanguagePop(!showLanguagePop);
              }}
              icon={t("flag")}
            />
          </Header>

          {reCaptchaState?.status == 1 &&
          reCaptchaState?.captcha_type == 0 &&
          typeof reCaptchaState?.site_key != "undefined" ? (
            <ReCAPTCHACustom
              sitekey={reCaptchaState?.site_key || "ABC"}
              onChange={onChangeCaptcha}
              theme="dark"
            />
          ) : (
            ""
          )}

          {token ? (
            <RGCaptcha
              name={CAPNAME}
              product="bind"
              lang="en"
              onSuccess={(oSuccess) => {
                sendOTP(oSuccess);
                // console.log(oSuccess);
                //send otp here
              }}
              data={token}
            />
          ) : null}

          <ContentPhone>{t("phone number")}</ContentPhone>
          <ContainerPhone>
            <InputPhone
              type="text"
              name="phone"
              placeholder="0XXXXXXXXX"
              value={phoneNumber}
              onKeyUp={CheckDuplicateTel}
              maxLength={10}
              errorStatus={
                (phoneNumber.length == 10 && !validTelPhone(phoneNumber)) ||
                (phoneNumber.length == 10 && notDuplicateTel == false)
              }
              onFocus={(e) => {
                window.isEdit = true;
                dispatch(setFloatingLabel(t("phone number")));
                dispatch(setFloatingStatus(true));
                dispatch(setFloatingValue(e.target.value));
              }}
              onBlur={() => {
                window.isEdit = false;
                dispatch(setFloatingStatus(false));
              }}
              onChange={(v) => {
                if (v.target.value === "" || reqPhone.test(v.target.value)) {
                  setPhoneNumber(v.target.value);
                  setInputError(validTelPhone(v.target.value));
                  dispatch(setFloatingValue(v.target.value));
                }
              }}
            />
            {phoneNumber.length == 10 && notDuplicateTel == false ? (
              <></>
            ) : phoneNumber.length == 10 && validTelPhone(phoneNumber) ? (
              <CorrectInput>
                <img
                  src={"../" + config.theme + "/dailylogin_correct.png"}
                  alt="Correct"
                />
              </CorrectInput>
            ) : null}
            {step !== 1 ? (
              ((!changePhoneNumber && doneChangePhone) ||
                (countDownTime.min === 0 &&
                  countDownTime.sec === 0 &&
                  countDown.min === 0 &&
                  countDown.sec === 0)) &&
              reCaptchaState?.status == 1 &&
              reCaptchaState?.captcha_type === 0 &&
              captcha != null &&
              phoneNumber.length == 10 &&
              validTelPhone(phoneNumber) &&
              phoneNumber.length == 10 &&
              notDuplicateTel == true ? (
                <OtpButton
                  onClick={() => {
                    getOTPBtn();
                  }}
                >
                  {t("request new otp")}
                </OtpButton>
              ) : ((!changePhoneNumber && doneChangePhone) ||
                  (countDownTime.min === 0 &&
                    countDownTime.sec === 0 &&
                    countDown.min === 0 &&
                    countDown.sec === 0)) &&
                reCaptchaState != 1 &&
                phoneNumber.length == 10 &&
                validTelPhone(phoneNumber) &&
                phoneNumber.length == 10 &&
                notDuplicateTel == true ? (
                <OtpButton
                  onClick={() => {
                    getOTPBtn();
                  }}
                >
                  {t("request new otp")}
                </OtpButton>
              ) : (
                <OtpButtonGray id={profile.brands_id + "-btn-send-otp"}>
                  {t("request new otp")}
                </OtpButtonGray>
              )
            ) : (phoneNumber.length == 10 &&
                validTelPhone(phoneNumber) &&
                notDuplicateTel == true &&
                reCaptchaState?.status == 1 &&
                reCaptchaState?.captcha_type == 0 &&
                captcha != null) ||
              (phoneNumber.length == 10 &&
                validTelPhone(phoneNumber) &&
                notDuplicateTel == true &&
                reCaptchaState?.status === 1 &&
                reCaptchaState?.captcha_type == 1) ||
              (phoneNumber.length == 10 &&
                validTelPhone(phoneNumber) &&
                notDuplicateTel == true &&
                reCaptchaState?.status === 0) ? (
              <OtpButton
                id={profile.brands_id + "-btn-send-otp"}
                onClick={() => {
                  getOTPBtn();
                }}
              >
                {t("request otp")}
              </OtpButton>
            ) : (
              <OtpButtonGray id={profile.brands_id + "-btn-send-otp"}>
                {t("request otp")}
              </OtpButtonGray>
            )}
          </ContainerPhone>
          {step !== 1 ? (
            (countDown.min != 0 || countDown.sec != 0) &&
            !changePhoneNumber &&
            !doneChangePhone ? (
              <>
                <ContainerOTP>
                  <Textotp>{t("otp expired")}</Textotp>
                  <Timer>
                    {countDown.sec < 10
                      ? "0" + countDown.min + ":0" + countDown.sec
                      : "0" + countDown.min + ":" + countDown.sec}
                  </Timer>
                  <Mintext>{t("min")}</Mintext>
                </ContainerOTP>
              </>
            ) : (countDownTime.min != 0 || countDownTime.sec != 0) &&
              !changePhoneNumber &&
              !doneChangePhone ? (
              <>
                <ContainerOTP>
                  <Textotp>{t("otp expired")}</Textotp>
                  <Timer>
                    {countDownTime.sec < 10
                      ? "0" + countDownTime.min + ":0" + countDownTime.sec
                      : "0" + countDownTime.min + ":" + countDownTime.sec}
                  </Timer>
                  <Mintext>{t("min")}</Mintext>
                </ContainerOTP>
              </>
            ) : phoneNumber.length == 10 && !validTelPhone(phoneNumber) ? (
              <>
                <WarningMessage>{t("invalid phone number")}</WarningMessage>
              </>
            ) : phoneNumber.length == 10 && notDuplicateTel == false ? (
              <WarningMessage>
                {t("this phone number is already taken")}
              </WarningMessage>
            ) : null
          ) : phoneNumber.length == 10 && !validTelPhone(phoneNumber) ? (
            <>
              <WarningMessage>{t("invalid phone number")}</WarningMessage>
            </>
          ) : phoneNumber.length == 10 && notDuplicateTel == false ? (
            <WarningMessage>
              {t("this phone number is already taken")}
            </WarningMessage>
          ) : null}
          <ContentPhone>
            <div>OTP</div>
          </ContentPhone>
          <ContainerOTPInput>
            {step === 1 ? (
              <NoneInputOtp placeholder={t("inputOTP")} disabled />
            ) : (
              <InputOtp
                type="tel"
                name="otp"
                value={otp}
                placeholder={t("inputOTP")}
                maxLength={6}
                errorStatus={wrongOTPAlert}
                onFocus={(e) => {
                  window.isEdit = true;
                  dispatch(setFloatingLabel(t("inputOTP")));
                  dispatch(setFloatingStatus(true));
                  dispatch(setFloatingValue(e.target.value));
                }}
                onBlur={() => {
                  window.isEdit = false;
                  dispatch(setFloatingStatus(false));
                }}
                onChange={(v) => {
                  if (v.target.value === "" || reqPhone.test(v.target.value)) {
                    setOtp(v.target.value);
                    dispatch(setFloatingValue(v.target.value));
                  }
                }}
              />
            )}

            {otp.length === 6 && correctOTP ? (
              <CorrectInput style={{ right: "2%" }}>
                <img
                  src={"../" + config.theme + "/dailylogin_correct.png"}
                  alt="Correct"
                />
              </CorrectInput>
            ) : (
              ""
            )}
          </ContainerOTPInput>
          {wrongOTPAlert ? <WarningMessage>{t("s0031")}</WarningMessage> : ""}

          {correctOTP ? (
            <ContainerPincode>
              <Text>{t("text pincode")}</Text>
              <PinInput
                onFocus={() => {
                  window.isEdit = true;
                }}
                onBlur={() => {
                  window.isEdit = false;
                }}
                length={pincodeLength}
                initialValue=""
                // secret
                onChange={onChange}
                type="numeric"
                inputMode="number"
                value={pinCode}
                style={{ display: "flex", justifyContent: "space-between" }}
                inputStyle={{
                  borderColor: "#000000",
                  backgroundColor: "#000000",
                  border: "1",
                  borderRadius: "60%",
                  width: "min(15vw,90px)",
                  height: "min(15vw,90px)",
                  borderStyle: "solid",
                  color: "#FF7000",
                  fontSize: "min(5vw,30px)",
                }}
                inputFocusStyle={{ borderColor: "#FF7000" }}
                onComplete={onComplete}
                autoSelect={true}
                regexCriteria={/^[0-9]*$/}
              />
            </ContainerPincode>
          ) : arrayUrl == "" ? (
            <></>
          ) : (
            <Textsupport>
              {t("not get otp")}

              <Spinixtext
                onClick={() => {
                  try {
                    socket.emit(
                      "getbilog",
                      {
                        biname: "line_contact",
                      },
                      (data) => {
                        console.log(data);
                      }
                    );
                  } catch (error) {
                    console.log(error);
                  }
                  openInNewTab(lineId);
                }}
              >
                {t("contact us")}
              </Spinixtext>
            </Textsupport>
          )}

          <Greybar />

          <Content>
            {t("know us question")}
            {/* {research.question} */}
          </Content>

          <ContainerDropDown ref={ref}>
            <DropDown
              openDropDown={openDropDown}
              onClick={() => {
                setOpenDropDown(!openDropDown);
              }}
            >
              {selectedOption.option.length < 1
                ? t("know us please select")
                : selectedOption.option.length == 1
                ? t(selectedOption.option[0])
                : t("selected") + " ( " + selectedOption.option.length + " )"}
              <FontIcon
                key="fontIcon"
                icon={openDropDown ? faAngleUp : faAngleDown}
              />
            </DropDown>
            {openDropDown ? (
              <DropDownContainer>
                {DropDownOption.map((item, index) => (
                  <SelectBox key={index} onClick={() => handleOption(index)}>
                    <input
                      className="checkbox"
                      type="checkbox"
                      id={item}
                      name={item}
                      value={item}
                      checked={checkedState.option[index]}
                      onChange={() => handleOption(index)}
                    />
                    <Box selectBox={checkedState.option[index]} />
                    <p>{t(item)}</p>
                  </SelectBox>
                ))}
              </DropDownContainer>
            ) : (
              ""
            )}
          </ContainerDropDown>

          <ContainerError>
            {optionWarning && selectedOption.option.length < 1 ? (
              <WarningKnowUs>{t("field required")}</WarningKnowUs>
            ) : null}
          </ContainerError>
          <Content>{t("refer friends code")} </Content>
          {refercode === "" || refer === true ? (
            <ContainerDropDown>
              <InputRefer
                type="text"
                name="refer friends code"
                value={referCodeBy}
                placeholder={t("input referral code")}
                onFocus={(e) => {
                  window.isEdit = true;
                  dispatch(setFloatingLabel(t("input referral code")));
                  dispatch(setFloatingStatus(true));
                  dispatch(setFloatingValue(e.target.value));
                }}
                onBlur={() => {
                  window.isEdit = false;
                  dispatch(setFloatingStatus(false));
                }}
                onChange={(v) => {
                  setReferCodeBy(v.target.value);
                  reqSpace.test(v.target.value);
                  dispatch(setFloatingValue(v.target.value));
                }}
              ></InputRefer>
            </ContainerDropDown>
          ) : refercodeEdit ? (
            <ContainerDropDown>
              <InputRefer
                type="text"
                name="refer friends code"
                value={refercode}
                placeholder={t("input referral code")}
                onFocus={(e) => {
                  window.isEdit = true;
                  dispatch(setFloatingLabel(t("input referral code")));
                  dispatch(setFloatingStatus(true));
                  dispatch(setFloatingValue(e.target.value));
                }}
                onBlur={() => {
                  window.isEdit = false;
                  dispatch(setFloatingStatus(false));
                }}
                onChange={(v) => {
                  setRefercode(v.target.value);
                  reqSpace.test(v.target.value);
                  dispatch(setFloatingValue(v.target.value));
                }}
              ></InputRefer>
            </ContainerDropDown>
          ) : (
            <ContainerDropDown>
              <InputReferCode>
                <p>{refercode}</p>
              </InputReferCode>
            </ContainerDropDown>
          )}

          <ContainerButtonStep>
            <RedButton
              className="button"
              onClick={() => {
                setShowClosePopup(true);
              }}
            >
              {t("cancel")}
            </RedButton>

            <GreenButton
              id={profile.brands_id + "btn-confirm-otp"}
              className="button"
              color={!correctOTP || pinCode.length <= 3 ? "gray" : "green"}
              disabled={!correctOTP || pinCode.length <= 3 ? true : false}
              onClick={() => {
                if (!correctOTP || pinCode.length <= 3) {
                } else {
                  if (selectedOption.option.length < 1) {
                    setOptionWarning(true);
                    setLoad(false);
                    return;
                  } else {
                    setOptionWarning(false);
                    if (refercode != "" || referCodeBy != "") {
                      checkReferralCode();
                      setLoad(false);
                    } else {
                      createPincode();
                    }
                  }
                }
              }}
            >
              {t("otp next")}
            </GreenButton>
          </ContainerButtonStep>
        </PopupBox>
      </ScrollerBox>

      {complete === true ? <AlertMessage message={t("complete otp")} /> : ""}

      <Transition showPop={showConfirm}>
        <AlertMessage message={t("alert pincode")} />
      </Transition>

      <Transition showPop={toCreateAccount}>
        <CreateAccount
          setToCreateAccount={() => setToCreateAccount(false)}
          //   closeClick={redirect2Page("")}
        />
      </Transition>

      <Transition showPop={emitError.status} type="alert">
        <AlertMessage
          message={t(emitError.message)}
          onClick={() => {
            setEmitError({ status: false });
          }}
        />
      </Transition>

      <Transition showPop={errorRefer} type="alert">
        <BG style={{ justifyContent: "center" }}>
          <Alert
            showPop={showConfirm}
            messageBottom={t("continue")}
            setShowConfirm={() => setShowErrorRefer(false)}
            cancelClick={() => {
              setShowErrorRefer(false);
              setRefer(true);
              dispatch(actionSetprofile({ refercodeby: "" }));
            }}
            confirmClick={() => {
              // setReferCodeBy("");
              createPincode();
              // setStep(3);
              setShowErrorRefer(false);
            }}
          >
            {t("referral code is invalid")}
          </Alert>
        </BG>
      </Transition>

      <Transition showPop={errorReferLink} type="alert">
        <BG style={{ justifyContent: "center" }}>
          <Alert
            showPop={showConfirm}
            messageLeft={t("edit")}
            messageBottom={t("continue")}
            setShowConfirm={() => setShowErrorReferLink(false)}
            cancelClick={() => {
              setShowErrorReferLink(false);
              setRefercodeEdit(true);
              // dispatch(actionSetprofile({ refercodeby: refercode }));
            }}
            confirmClick={() => {
              createPincode();
              setStep(3);
              setShowErrorReferLink(false);
            }}
          >
            {t("s0087")}
          </Alert>
        </BG>
      </Transition>

      <Transition showPop={showClosePopup} type="alert">
        <BG style={{ justifyContent: "center" }}>
          <Alert
            showPop={showClosePopup}
            messageBottom={t("continue")}
            setShowConfirm={() => setShowClosePopup(false)}
            cancelClick={() => {
              redirect2Page("");
            }}
            confirmClick={() => {
              setShowClosePopup(false);
            }}
          >
            {t("cancel a registration")}
          </Alert>
        </BG>
      </Transition>
      {showLanguagePop ? (
        <Language setShowLanguagePop={setShowLanguagePop} />
      ) : (
        ""
      )}
    </BG>
  );
}

export default CreateAccount;
