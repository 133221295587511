import { createSlice } from "@reduxjs/toolkit";


export const afterLoginSilce = createSlice({
    name: "afterLogin",
    initialState: {
        isLogin: false,
        isRefresh: false
    },
    reducers: {
        actionSetIslogin: (state, action) => {
            state.isLogin = action.payload;
        },
        actionSetIsRefresh: (state, action) => {
            state.isRefresh = action.payload;
        },
    },
});
export const { actionSetIslogin, actionSetIsRefresh } = afterLoginSilce.actions;
export const selectAfterLogin = (state) => state.afterLogin.isLogin;
export const selectIsRefresh = (state) => state.afterLogin.isRefresh;

export default afterLoginSilce.reducer;
