import React, { useState, useContext, Profiler, useEffect } from "react";
import styled, { css, keyframes } from "styled-components";
import Button from "./Button";
import { useTranslation } from "react-i18next";
import { Transition, config, brandSetting } from "../config";
import AlertMessage from "./Dialog/AlertMessage";
import {
  actionClickSound,
  selectuserProfile,
} from "../Reducers/userProfileSlice";
import { useSelector, useDispatch } from "react-redux";
import { actionPopup } from "../Reducers/popupSlice";
import { SocketContext } from "../context/socket";
import { actionSpinixShopMenu } from "../Reducers/spinixShopMenuSlice";
import { actionFestivalBanner } from "../Reducers/festivalBannerSlice";

const Popup = styled.div`
  user-select: none;
  /* background-image: url("/${config.theme}/popup_bg.png"); */
  /* background-repeat: no-repeat; */
  /* background-size: 100% 100%; */
  background: ${(props) => (props.currentEvent ? "" : "#302e3c")};
  box-shadow: ${(props) =>
    props.currentEvent
      ? ""
      : "inset 0vw 0vw 0.8vw 0.2vw #ff691f,inset 0px 0px 4vw rgba(255, 133, 66, 0.63)"};
  border: ${(props) => (props.currentEvent ? "" : "solid 0.2vw orange")};

  /* background: ${(props) => (props.currentEvent ? "" : "#192756")};
  box-shadow: ${(props) =>
    props.currentEvent
      ? ""
      : "inset 0vw 0vw 0.8vw 0.2vw #5DD8FF,inset 0px 0px 4vw rgba(66, 255, 232, 0.63)"};
  border: ${(props) => (props.currentEvent ? "" : "solid 0.2vw #BDEFFF")}; */

  border-radius: 1.5vw;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50vw;
  height: 38vw;

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 90vw;
          height: 70vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 80vw;
          height: 65vw;
        `}
`;

const BGPopup = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%;
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 35;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  user-select: none;
  display: flex;
  position: relative;
  border-radius: 1.5vw;
  margin: 10% 0 2% 0;
  width: 95%;
`;

const ButtonPanel = styled.div`
  display: flex;
  flex-direction: row;
  /* margin-top: 2vw; */
  position: relative;
  width: 100%;
  justify-content: center;
`;

const Close = styled.img`
  user-select: none;
  width: 4.9vw;
  position: absolute;
  z-index: 1;
  right: -5%;
  top: -6%;
  cursor: pointer;
`;

function EventPopup(props) {
  const { t } = useTranslation();
  const [emitError, setEmitError] = useState({ status: false, message: "" });
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const profile = useSelector(selectuserProfile);
  const [eventBanner, setEventBanner] = useState(props.item.img);

  const setEventPopup = (status) => {
    let eventPopup = JSON.parse(JSON.stringify(props.eventPopup));
    eventPopup[props.index] = status;
    props.setEventPopup(eventPopup);
    socket.emit(
      "updatepopup",
      {
        no: props.item.no,
      },
      (data) => {
        // console.log("updatepopup", data);
      }
    );
  };

  useEffect(() => {
    if (profile.language === "EN") {
      setEventBanner(props.item.img_en);
    } else setEventBanner(props.item.img);
  }, [profile.language]);

  // console.log("EventPopup props", typeof props.item.mission_group);

  return (
    <>
      <BGPopup>
        <Popup currentEvent={props.item.displayType === "S" ? true : false}>
          {props.item.displayType == "F" ? (
            <Close
              src={"../" + config.theme + "/btn_close.png"}
              onClick={() => {
                setEventPopup(false);
                // dispatch(actionCloseSound());
              }}
            />
          ) : (
            ""
          )}
          <Image src={eventBanner} />
          <ButtonPanel>
            {props.item.displayType == "N" ? (
              <Button
                onClick={() => {
                  setEventPopup(false);
                }}
                bClass="rbGreen"
                label={t("ok")}
                // width="20vw"
                // fontSize="2vw"
                width={
                  window.appHeight() / window.innerWidth >
                  config.verticalSizeiphone
                    ? "30vw"
                    : window.appHeight() > window.innerWidth
                    ? "30vw"
                    : window.innerWidth / window.appHeight() > 2.15
                    ? "15vw"
                    : "15vw"
                }
                fontSize={
                  window.appHeight() / window.innerWidth >
                  config.verticalSizeiphone
                    ? "3vw"
                    : window.appHeight() > window.innerWidth
                    ? "3vw"
                    : window.innerWidth / window.appHeight() > 2.15
                    ? "2vw"
                    : "2vw"
                }
              />
            ) : props.item.displayType == "F" ? (
              <Button
                onClick={async () => {
                  setEventPopup(false);

                  let oBrandSetting = await brandSetting();
                  // console.log(
                  //   "oBrandSetting",
                  //   oBrandSetting,
                  //   props.item.link,
                  //   oBrandSetting["setting"]["facebookURL"],
                  //   oBrandSetting[props.item.link]
                  // );
                  window.open(
                    oBrandSetting["setting"]["facebookURL"],
                    "_blank"
                  );
                }}
                bClass="rbGreen"
                label={t("go to facebook")}
                width="20vw"
                fontSize="2vw"
              />
            ) : props.item.displayType == "S" ? (
              <>
                <Button
                  onClick={() => {
                    setEventPopup(false);
                  }}
                  bClass="rbRed"
                  label={t("not now")}
                  width={
                    window.appHeight() / window.innerWidth >
                    config.verticalSizeiphone
                      ? "40vw"
                      : window.appHeight() > window.innerWidth
                      ? "35vw"
                      : "20vw"
                  }
                  fontSize={
                    window.appHeight() / window.innerWidth >
                    config.verticalSizeiphone
                      ? "4vw"
                      : window.appHeight() > window.innerWidth
                      ? "3.5vw"
                      : "2vw"
                  }
                />
                <Button
                  onClick={() => {
                    if (props.item.ref == "0") {
                      setEventPopup(false);
                      dispatch(actionPopup({ diamondExchange: true }));
                      dispatch(
                        actionSpinixShopMenu({
                          freeSpin: true,
                          credit: false,
                          deposit: false,
                        })
                      );
                    }
                  }}
                  bClass="rbGreen"
                  label={t("Buy Free Spins")}
                  width={
                    window.appHeight() / window.innerWidth >
                    config.verticalSizeiphone
                      ? "40vw"
                      : window.appHeight() > window.innerWidth
                      ? "35vw"
                      : "20vw"
                  }
                  fontSize={
                    window.appHeight() / window.innerWidth >
                    config.verticalSizeiphone
                      ? "4vw"
                      : window.appHeight() > window.innerWidth
                      ? "3.5vw"
                      : "2vw"
                  }
                />
              </>
            ) : (
              <>
                <Button
                  onClick={() => setEventPopup(false)}
                  bClass="rbRed"
                  label={t("not now")}
                  width="20vw"
                  fontSize="2vw"
                />
                <Button
                  onClick={() => {
                    // if (props.item.ref == "0") {
                    //   setEventPopup(false);
                    //   dispatch(actionPopup({ diamondExchange: true }));
                    // }
                    if (props.item.displayType == "M") {
                      setEventPopup(false);
                      dispatch(actionPopup({ mission: true }));
                      if (typeof props.item.mission_group != "undefined") {
                        dispatch(
                          actionFestivalBanner(props.item.mission_group)
                        );
                      }
                    } else if (props.item.displayType == "B") {
                      setEventPopup(false);
                      dispatch(actionPopup({ depositWithdrawal: true }));
                    }
                  }}
                  bClass="rbGreen"
                  label={
                    props.item.displayType === "M"
                      ? t("go to mission")
                      : t("get bonus now")
                  }
                  width="20vw"
                  fontSize="2vw"
                />
              </>
            )}
          </ButtonPanel>
        </Popup>

        {/* <Popup>
          <Image src={profile.popup[0].img} />
          <ButtonPanel>
            {profile.popup[0].displayType == "N" ? (
              <Button
                onClick={() => props.setEventPopup(false)}
                bClass="rbGreen"
                label={t("ok")}
                width="20vw"
                fontSize="2vw"
              />
            ) : (
              <>
                <Button
                  onClick={() => props.setEventPopup(false)}
                  bClass="rbRed"
                  label={t("not now")}
                  width="20vw"
                  fontSize="2vw"
                />
                <Button
                  onClick={() => {
                    if (profile.popup[0].ref == "0") {
                      props.setEventPopup(false);
                      dispatch(actionPopup({ diamondExchange: true }));
                    }
                  }}
                  bClass="rbGreen"
                  label={t("get bonus now")}
                  width="20vw"
                  fontSize="2vw"
                />
              </>
            )}
          </ButtonPanel>
        </Popup> */}
      </BGPopup>

      <Transition showPop={emitError.status} type="alert">
        <AlertMessage
          message={t(emitError.message)}
          onClick={() => {
            setEmitError({ status: false });
          }}
        />
      </Transition>
    </>
  );
}

export default EventPopup;
