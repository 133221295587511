import React from "react";
import styled, { css } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { actionClickSound } from "../Reducers/userProfileSlice";
import {
  selectMinigameslot,
  actionMinigameSlot,
} from "../Reducers/minigameslotSlice";
import { config } from "../config";
const BSave = styled.button`
  user-select: none;
  border: 0;
  border: transparent;
  background-color: transparent;
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 0%;
  justify-content: center;
  &:focus {
    outline: none;
  }
  div {
    /* font-size:1.3vw; */
    font-size: 1.5vw;
    color: white;
    right: 0vw;
    left: 0vw;
    /* left: -0.3vw; */
    /* top: 0.2vw; */
    align-items: center;
  }
`;

const Label = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.discount &&
    css`
      font-size: 1.4vw !important;
      line-height: 1.1;
      // margin-right: 0.75vw;
      > span:nth-child(1) {
        text-decoration: line-through;
        text-decoration-color: red;
      }
    `}

  ${(props) =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone &&
    props.discount
      ? css`
          font-size: 2vw !important;
        `
      : css``}
`;

function Button(props) {
  const themeSlot = useSelector(selectMinigameslot);
  let img = "../" + config.theme + "/btn_green.png";

  if (props.bClass === "rbBlue") {
    img = "../" + config.theme + "/btn_facebook.png";
  } else if (props.bClass === "rbRed") {
    img = "../" + config.theme + "/btn_red2.png";
  } else if (props.bClass === "rbGray") {
    img = "../" + config.theme + "/btn_gray.png";
  } else if (props.bClass === "rbLine") {
    img = "../" + config.theme + "/btn_line.png";
  } else if (props.bClass === "rbGreen") {
    img = "../" + config.theme + "/btn_green.png";
  } else if (props.bClass === "rbNormal") {
    img = "../" + config.theme + "/btn_normal_green.png";
  } else if (props.bClass === "luckywheel") {
    img = "../" + config.theme + "/icon_luckywheel.png";
  } else if (props.bClass === "treasurebox") {
    img = "../" + config.theme + "/icon_treasurebox.png";
  } else if (props.bClass === "editGray") {
    img = "../" + config.theme + "/btn_edit_disable.png";
  } else if (props.bClass === "editGreen") {
    img = "../" + config.theme + "/btn_edit_l.png";
  } else if (props.bClass === "btnCopy") {
    img = "../" + config.theme + "/btn_copy.png";
  } else if (props.bClass === "Respin") {
    img = "../" + config.theme + "/minislot/" + themeSlot + "/respin_btn.png";
  }
  let icon = "";
  let styleImages = {};
  let font = {};

  if (props.icon === "coin") {
    icon = "../" + config.theme + "/icon_coin.png";
    styleImages = { width: "10%", display: "flex" };
    font = { fontWeight: "bold", fontSize: "2vw" };
  } else if (props.icon === "diamond") {
    // icon = "../"+config.theme+"/icon_diamond copy.png";
    icon = "../" + config.theme + "/icon_diamond_copy.png";
    styleImages = { width: "14%", marginRight: "0.5vw", marginTop: "-0.25vw" };
    font = { fontWeight: "bold", fontSize: "2vw" };
  }

  let discount = false;
  if (props.labelDiscount) {
    discount = true;
  }

  const dispatch = useDispatch();

  return (
    <>
      <BSave
        {...props}
        onClick={() => {
          dispatch(actionClickSound());
          if (typeof props.onClick === "function") {
            props.onClick();
          }
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: 10,
            left: discount ? "-1.25vw" : "",
            paddingLeft: props.mingames
              ? window.appHeight() / window.innerWidth >
                config.verticalSizeiphone
                ? "3vw"
                : window.appHeight() > window.innerWidth
                ? "1vw"
                : "1vw"
              : "",
          }}
        >
          <img src={icon} alt="" style={styleImages} />
          <Label
            style={{ fontSize: props.fontSize, marginLeft: props.marginLeft }}
            discount={discount}
          >
            {props.label}
            {props.labelDiscount}
          </Label>
        </div>
        <img
          src={img}
          alt=""
          style={{ width: props.width, alignSelf: "center" }}
        />
      </BSave>
    </>
  );
}
export default Button;
