import React from "react";
import styled, { css } from "styled-components";
import Button from "../Button";
import { useTranslation } from "react-i18next";
import { config } from "../../config";
import breakpoints from "../../LandingPageComponents/breakpoints";

const Popup = styled.div`
  user-select: none;
  /* margin-top: 20vw; */
  display: flex;
  flex-direction: column;
  /* margin: 0 auto; */
  min-width: 28vw;
  height: max-content;
  width: max-content;
  // theme ==================
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;
  border-radius: 2vw;
  padding: 2.5vw;
  // theme ==================
  // background-color: #2f2d3b;
  // border-radius: 4vw;
  // border: solid 3vw;
  // border-image-slice: 50;
  // border-image-source: url("../${config.theme}/popup_frame_mini.png");
  justify-content: center;
  text-align: center;
  align-items: center;
  color: #bcb8d0;
  font-size: 2vw;
  /* width: 55vw; */
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 84vw;
          height: 17vw;
          padding: 6vw 3vw 7vw 3vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          width: 55vw;
          height: 12vw;
          padding: 3vw 3vw 4vw 3vw;
        `}
`;

const ButConfirm = styled.div`
  user-select: none;
  position: relative;
  z-index: 70;
  display: flex;
  margin-top: -2vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin-top: -4vw;
        `
      : window.appHeight() > window.innerWidth && css``}
`;

const Box = styled.div`
  user-select: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  text-align: -webkit-center;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  z-index: 50;
  align-items: center;
  justify-content: center;
  p {
    color: #bdb9d1;
    text-align: center;
    font-size: 1.3vw;
    width: 100%;
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    /* display: flex; */

    /* flex-direction: row; */
  }
  h5 {
    font-size: 2.5vw;
    color: white;
    margin: 0;
  }
  @media ${breakpoints.device.line} {
    width: 145%;
    height: 620%;
    left: -22%;
  }
  //Portrait
  @media (orientation: portrait) {
    p {
      color: #bdb9d1;
      text-align: center;
      font-size: 3.5vw;
      width: 100%;
      line-height: 1.5;
      -webkit-text-size-adjust: 100%;
      justify-content: center;
    }
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    p {
      color: #bdb9d1;
      text-align: center;
      font-size: 2.511vw;
      width: 100%;
      line-height: 1.5;
      -webkit-text-size-adjust: 100%;
      justify-content: center;
    }
  }
  /* ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          p {
            color: #bdb9d1;
            text-align: center;
            font-size: 3.5vw;
            width: 100%;
            line-height: 1.5;
            -webkit-text-size-adjust: 100%;
            justify-content: center;
          }
        `
      : window.appHeight() > window.innerWidth &&
        css`
          p {
            color: #bdb9d1;
            text-align: center;
            font-size: 2.511vw;
            width: 100%;
            line-height: 1.5;
            -webkit-text-size-adjust: 100%;
            justify-content: center;
          }
        `} */
`;

function Confirm(props) {
  const { t } = useTranslation();

  return (
    <Box {...props}>
      <Popup style={{ height: props.height, width: props.width }}>
        <h5>{props.title}</h5>
        <p>{props.children}</p>
        <p>{props.message}</p>
      </Popup>
      <ButConfirm>
        <Button
          bClass="rbRed"
          label={props.btnLabel1 ? props.btnLabel1 : t("cancel")}
          width={
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "25vw"
              : window.appHeight() > window.innerWidth
              ? "21vw"
              : window.innerWidth / window.appHeight() > 2.15
              ? "13vw"
              : "13vw"
          }
          fontSize={
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "3vw"
              : window.appHeight() > window.innerWidth
              ? "2.5vw"
              : window.innerWidth / window.appHeight() > 2.15
              ? "1.3vw"
              : "1.3vw"
          }
          onClick={props.cancelClick}
          style={{ padding: 0 }}
        />
        <Button
          label={props.btnLabel2 ? props.btnLabel2 : t("confirm")}
          width={
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "25vw"
              : window.appHeight() > window.innerWidth
              ? "21vw"
              : window.innerWidth / window.appHeight() > 2.15
              ? "13vw"
              : "13vw"
          }
          fontSize={
            window.appHeight() / window.innerWidth > config.verticalSizeiphone
              ? "3vw"
              : window.appHeight() > window.innerWidth
              ? "2.5vw"
              : window.innerWidth / window.appHeight() > 2.15
              ? "1.3vw"
              : "1.3vw"
          }
          style={{ padding: 0 }}
          onClick={props.confirmClick}
        />
      </ButConfirm>
    </Box>
  );
}
export default Confirm;
