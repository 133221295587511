import React, { useState } from "react";
import styled, { css } from "styled-components";
import { selectuserProfile } from "../../Reducers/userProfileSlice";
import { useSelector, useDispatch } from "react-redux";
import { config } from "../../config";
import ProgressBar from "./ProgressBar";
import Button from "../Button";
import { useTranslation } from "react-i18next";
import { actionPopup } from "../../Reducers/popupSlice";
import ConfirmPopup from "../Dialog/ConfirmPopup";

const Bg = styled.div`
  user-select: none;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Box = styled.div`
  user-select: none;
  width: 64.62vw;
  height: 51.21vw;
  position: relative;
  margin-bottom: -3%;
  ${(props) =>
    props.lang === "TH"
      ? css`
          background-image: url("../images/promotion_banner_new/flashdeal_th.png");
        `
      : css`
          background-image: url("../images/promotion_banner_new/flashdeal_en.png");
        `}
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  ${() =>
    // window.appHeight() / window.innerWidth > config.verticalSizeiphone &&
    window.appHeight() > window.innerWidth &&
    css`
      width: 96.93vw;
      height: 76.815vw;
    `}
`;

const Close = styled.div`
  user-select: none;
  background-image: url("../${config.theme}/btn_close.png");
  background-size: contain;
  height: 4.5vw;
  width: 4.5vw;
  position: absolute;
  background-repeat: no-repeat;
  right: 1%;
  top: 1%;
  float: right;
  cursor: pointer;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          height: 8vw;
          width: 8vw;
          top: -9%;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          height: 6vw;
          width: 6vw;
          top: -4%;
        `}
`;

export default function FlashDeal(props) {
  const profile = useSelector(selectuserProfile);
  const dispatch = useDispatch();
  const [closePop, setClosePop] = useState(false);
  const { t } = useTranslation();

  return (
    <Bg>
      {window.appHeight() > window.innerWidth ? (
        ""
      ) : (
        <Close
          onClick={() => {
            setClosePop(true);
          }}
        />
      )}
      <Box lang={profile.language}>
        {window.appHeight() > window.innerWidth ? (
          <Close
            onClick={() => {
              setClosePop(true);
            }}
          />
        ) : (
          ""
        )}
        <ProgressBar />
      </Box>
      <Button
        onClick={() => {
          props.setFlashDealPop(false);
          dispatch(actionPopup({ depositWithdrawal: true }));
        }}
        style={{
          bottom: window.innerWidth / window.appHeight() > 1.8 ? "0.5vw" : "",
        }}
        label={t("Flash Deal Deposit Now")}
        fontSize={
          // window.appHeight() / window.innerWidth > config.verticalSizeiphone
          window.appHeight() > window.innerWidth ? "2.7vw" : "1.8vw"
        }
        width={
          // window.appHeight() / window.innerWidth > config.verticalSizeiphone
          window.appHeight() > window.innerWidth ? "27.66vw" : "18.44vw"
        }
      />
      {closePop ? (
        <ConfirmPopup
          cancelClick={() => {
            props.setFlashDealPop(false);
          }}
          messageBottom={t("ok")}
        >
          <div style={{ color: "yellow" }}>{t("Flash Deal Close Text")}</div>
          <div>{t("Flash Deal Close Text 2")}</div>
        </ConfirmPopup>
      ) : (
        ""
      )}
    </Bg>
  );
}
