import styled, { css } from "styled-components";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { actionSwitchSound } from "../Reducers/userProfileSlice";
import { config } from "../config";
import { useTranslation } from "react-i18next";

const BG = styled.div`
  user-select: none;
  /* width: 10vw; */
  height: 4.5vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;

  background-image: url("/${config.theme}/toggle_bg_on.png");
  ${(props) =>
    props.active === false &&
    css`
      background-image: url("/${config.theme}/toggle_bg_off.png");
    `}
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          height: 10.5vw;
          width: 21vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          height: 7.5vw;
          width: 13.5vw;
        `}
`;

const ToggleCircle = styled.div`
  user-select: none;
  width: 4.65vw;
  height: 4.65vw;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/${config.theme}/toggle_btn.png");
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          height: 9.5vw;
          width: 9vw;

          margin-top: 0.5vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          height: 7.65vw;

          width: 6vw;
        `}
`;

const ToggleButton = styled.div`
  user-select: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${(props) =>
    props.active === false &&
    css`
      flex-direction: row-reverse;
    `}
`;

const Text = styled.div`
  user-select: none;
  color: #ffffff;
  font-size: 1.5vw;
  align-self: center;
  padding-left: 0.95vw;
  padding-right: 1vw;
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          padding-right: 3vw;
          width: 7vw;
          font-size: 3vw;
        `
      : window.appHeight() > window.innerWidth &&
        css`
          font-size: 1.6vw;
        `}
`;

export default function Switch(props) {
  const { t } = useTranslation();
  const InitialValue = props.value === true ? props.onLabel : props.offLabel;
  const [textValue, setTextValue] = useState(InitialValue);
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      setTextValue(
        props.value === true || props.value === "O"
          ? props.onLabel
          : props.offLabel
      );
    } catch (error) {}
  }, [props.value]);

  return (
    <BG
      active={props.value}
      onClick={() => {
        props.click();
        dispatch(actionSwitchSound());
      }}
    >
      <ToggleButton active={props.value}>
        <Text>{t(textValue)}</Text>
        <ToggleCircle />
      </ToggleButton>
    </BG>
  );
}
