import React from "react";
import styled from "styled-components";
import breakpoint from "../breakpoints";
import { useTranslation } from "react-i18next";
// import { Route, Link } from "react-router-dom";

const Detail = styled.div`
  // display:flex;
  // font-size: 0.9vw;
  position: relative;
  justify-content: center;
  padding-top: 1vw;
  width: 53%;
  margin-left: 25vw;
  // color:white;
  p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 1vw;
    line-height: 2vw;
    position: relative;
  }
  a {
    color: #ff7000;
  }
  h2 {
    font-size: 18px;
    color: #ff7000;
  }
  h1 {
    font-size: 24px;
    color: #ff7000;
  }
  h3 {
    font-size: 14px;
    color: #ff7000;
  }
  @media ${breakpoint.device.ipad} {
    width: 62%;
    margin-left: 19vw;
    p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 1.3vw;
    line-height: 2.5vw;
    position: relative;
    user-select: none;
  }
  a {
    color: #ff7000;
  }
  h2 {
    font-size: 18px;
    color: #ff7000;
  }
  h1 {
    font-size: 24px;
    color: #ff7000;
  }
  h3 {
    font-size: 14px;
    color: #ff7000;
  }
  }
  @media ${breakpoint.device.mobile} {
    // display:flex;
    position: relative;
    justify-content: center;
    padding-top: 4vw;
    width: 80%;
    margin-left: 10vw;
    color: white;
    p {
      // color:white;
      color: rgba(255, 255, 255, 0.7);
      font-size: 12px;
      line-height: 16px;
      position: relative;
      user-select: none;
      // display:flex;
    }
  }
  
`;


const Text = styled.div`
  color: #ff7000;
  font-size: 1.5vw; //old value 24px
  line-height: 2.8vw;
  a {
    color: #ff7000;
  }
  @media ${breakpoint.device.mobile} {
    color: #ff7000;
    font-size: 4vw;
    line-height: 5.5vw;
  }
`;


function AboutSlotItem(props) {
  const { i18n } = useTranslation();
  let styleText = {};

  if (props.text === "description1") {
    styleText = { fontSize: "24px" };
  } else if (props.text === "description2") {
    styleText = { fontSize: "24px" };
  }

  return (
    <>
      {/* <Detail>
            <Text style={styleText}>{props.title}</Text>
            <p>{props.description}</p>
        </Detail> */}

      <Detail>
        <Text
          style={styleText}
          dangerouslySetInnerHTML={{
            __html:
              i18n.language === "th" ? props.titleslot_th : props.titleslot_en,
          }}
        ></Text>
        <p
          dangerouslySetInnerHTML={{
            __html:
              i18n.language === "th" ? props.detailslot_th : props.detailslot_en,
          }}
        ></p>
      </Detail>
    </>
  );
}
export default AboutSlotItem;
