const device = {
  mobile: `(max-width: 480px)`,
  ipad: `(max-width: 1640px)`,
  // line: `(max-height: 260px)`,
  // line: `(max-height: 320px)`,
  line: `(max-height: 280px)`,
  vertic: `(max-width: 575px)`,
};

const deviceSize = "480";



export default { device, deviceSize };
