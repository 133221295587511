import React from "react";
import styled, { css } from "styled-components";
import { config } from "../../config";

const Menu = styled.button`
  user-select: none;
  width: 92.5%;
  height: 4.5vw;
  background: #211f29;
  border-radius: 1vw;
  position: relative;
  display: block;
  /* margin-top: 1.2vw; */
  margin-bottom: 0.7vw;
  cursor: pointer;
  p {
    font-size: 1.2vw;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
    white-space: pre-line;
    line-height: 1.1;
    text-align: start;
    padding: 0 1vw 0 1vw;
  }
  // start clear defualt button style
  border: none;
  &:focus {
    outline: none;
  }
  // end clear

  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin: 0.5vw;
          width: 48.5%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 10vw;
          border-radius: 1.5vw;
          p {
            font-size: 2.8vw;
            padding: 0;
            text-align: center;
          }
        `
      : window.appHeight() > window.innerWidth &&
        css`
          height: 21.63%;
          p {
            font-size: 1.8vw;
            padding: 0;
            text-align: center;
          }
        `}
`;

const MenuActive = styled.button`
  user-select: none;
  width: 92.5%;
  height: 4.5vw;
  background: #ff8300;
  box-shadow: inset 0px 0px 10px 4px rgba(250, 255, 0, 0.9);
  border-radius: 1vw;
  position: relative;
  display: block;
  /* margin-top: 1.2vw; */
  margin-bottom: 0.7vw;
  cursor: pointer;
  p {
    font-size: 1.2vw;
    font-weight: 600;
    color: #340e00;
    white-space: pre-line;
    line-height: 1.1;
    text-align: start;
    padding: 0 1vw 0 1vw;
  }
  // start clear defualt button style
  border: none;
  &:focus {
    outline: none;
  }
  // end clear
  ${() =>
    window.appHeight() / window.innerWidth > config.verticalSizeiphone
      ? css`
          margin: 0.5vw;
          width: 48.5%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 10vw;
          border-radius: 1.5vw;
          p {
            font-size: 2.8vw;
            padding: 0;
            text-align: center;
          }
        `
      : window.appHeight() > window.innerWidth &&
        css`
          height: 21.63%;
          p {
            font-size: 1.8vw;
            padding: 0;
            text-align: center;
          }
        `}
`;

function MenuItem(props) {
  // console.log("props.noti", props.noti);
  function iOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }
  return (
    <>
      {props.selectedIndex === props.i ? (
        <MenuActive onClick={props.onClick} noti={props.noti} id={props.id}>
          {props.children}
          <p style={{ fontWeight: iOS() ? "400" : "600" }}>{props.label}</p>
        </MenuActive>
      ) : (
        <Menu onClick={props.onClick} noti={props.noti} id={props.id}>
          {props.children}
          <p>{props.label}</p>
        </Menu>
      )}
    </>
  );
}

export default MenuItem;
