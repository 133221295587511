import Avatar from "./avatar";
import styled, { css, keyframes } from "styled-components";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { actionPopup } from "../../Reducers/popupSlice";
import NumberFormat from "react-number-format";
import { SocketContext } from "../../context/socket";
import {
  selectuserProfile,
  actionClickSound,
} from "../../Reducers/userProfileSlice";
import { rubberBand } from "react-animations";
import { config } from "../../config";

const TutorialAnimation = keyframes`${rubberBand}`;

const AvatarButton = styled.button`
  user-select: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: absolute;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 20vw;
  left: 52.1vw;
  min-width: 0px;
  min-height: 0px;
  padding: 0px;
  color: white;
  font-size: 2vw;
  text-transform: none;
  vertical-align: top;
  //Portrait
  @media (orientation: portrait) {
    width: 30%;
    left: auto;
    top: auto;
    position: relative;
  }
`;

const AvataeLV = styled.div`
  width: 10.5vw;
  height: 80%;
  position: absolute;
  left: 8.5vw;
  top: 15%;
  min-width: 0px;
  min-height: 0px;
  padding: 0px;
  color: white;
  font-size: 1.6vw;
  text-transform: none;
  vertical-align: top;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  //Portrait
  @media (orientation: portrait) {
    left: auto;
    top: auto;
    width: 62%;
    height: 44%;
    position: relative;
    align-items: center;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    height: 54%;
  }
`;

const btn = css`
  height: 5vw;
  border-radius: 0.8vw;
  outline: none;
  border: none;
  font-size: 1.25vw;
  cursor: pointer;
  margin-top: 0.1vw;
  position: absolute;
  width: 10vw;
  //Portrait
  @media (orientation: portrait) {
    font-size: 2vw;
    border-radius: 1.4vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    border-radius: 1.2vw;
  }
`;
const BtnLetsPlay = styled.button`
  ${btn}
  background: linear-gradient(180deg, #5fde1a 0%, #569705 100%);
  box-shadow: inset 0px 0px 4px 4px #80e138;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //Portrait
  @media (orientation: portrait) {
    box-shadow: inset 0vw 0vw 0.5vw 0.5vw #80e138;
    width: 18vw;
    height: 8vw;
    top: 1.5vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    width: 14vw;
    height: 6.5vw;
    top: 1.5vw;
  }
`;

const Level = styled.div`
  user-select: none;
  font-size: 1vw;
  background-color: black;
  border-radius: 1vw;
  width: 30.43%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  //Portrait
  @media (orientation: portrait) {
    font-size: 2.5vw;
    background-color: transparent;
  }
`;

const AvatarBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  position: absolute;
  width: 8.2vw;
  right: 63.5vw;
  top: -0.1vw;
  //Portrait
  @media (orientation: portrait) {
    width: 100%;
    right: auto;
    top: auto;
  }
`;

const BrandName = styled.div`
  font-size: 1.1vw;
  /* width: max-content; */
  max-width: 12vw;
  overflow: hidden;
  text-overflow: ellipsis;
  //Portrait
  @media (orientation: portrait) {
    font-size: 2.5vw;
    max-width: none;
  }
`;

const Guesttext = styled.div`
  user-select: none;
  font-size: 1vw;
  width: max-content;
  margin-top: -1.7vw;
  //Portrait
  @media (orientation: portrait) {
    font-size: 2vw;
    margin-top: -5vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 1.8vw;
    margin-top: -4vw;
  }
`;

const Textregister = styled.div`
  user-select: none;
  color: #fff;
  font-size: 1.15vw;
  margin-top: -0.3vw;
  text-shadow: 0 0 0.8vw black;
  //Portrait
  @media (orientation: portrait) {
    font-size: 2.2vw;
    line-height: 2vw;
    margin-top: -0.5vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 1.8vw;
    line-height: 2.3vw;
    margin-top: 0.2vw;
  }
`;

const Barlogin = styled.div`
  user-select: none;
  position: relative;
  background-color: #fcf062;
  width: 87%;
  height: 0.1vw;
  margin-top: 0.1vw;
  margin-left: 0.5vw;
  //Portrait
  @media (orientation: portrait) {
    height: 0.2vw;
    width: 95%;
    margin-top: 0.3vw;
    margin-bottom: 0.3vw;
  }

  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    margin-top: 1vw;
  }
`;

const Textlogin = styled.div`
  user-select: none;
  color: #fff;
  font-size: 1.15vw;
  margin-top: 0.3vw;
  text-shadow: 0 0 0.8vw black;
  //Portrait
  @media (orientation: portrait) {
    font-size: 2.2vw;
    line-height: 2vw;
    margin-top: 0.5vw;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 1.8vw;
    line-height: 2.3vw;
    margin-top: -0.1vw;
  }
`;

const TutorialArrow = styled.img`
  position: absolute;
  width: 7vw;
  top: 3.2vw;
  z-index: 10;
  animation: ${TutorialAnimation} 1.5s linear infinite;

  @media (orientation: portrait) {
    width: 12vw;
    top: 71%;
    left: 40%;
  }

  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    top: 75%;
    left: 35%;
  }
`;

const TutorialBox = styled.div`
  pointer-events: none;
  width: 90.5%;
  height: 82%;
  position: absolute;
  font-size: 24px;
  border: 0.5vw solid;
  resize: both;
  box-shadow: 0px 0px 50px 20px rgba(0, 0, 0, 0.8);
  border-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cstyle%3Epath%7Banimation:stroke 1.5s infinite linear%3B%7D%40keyframes stroke%7Bto%7Bstroke-dashoffset:776%3B%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='180%25' y1='0%25' x2='80%25' y2='100%25'%3E%3Cstop offset='0%25' stop-color='%23d02d07' /%3E%3Cstop offset='25%25' stop-color='%23ff5400' /%3E%3Cstop offset='50%25' stop-color='%23ff7e00' /%3E%3Cstop offset='100%25' stop-color='%23ffea00' /%3E%3C/linearGradient%3E %3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='20' stroke-dasharray='193.6'/%3E %3C/svg%3E")
    1;

  @media (orientation: portrait) {
    width: 95.5%;
    height: 60%;
    left: 0vw;
    border: 0.7vw solid;
  }

  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    height: 70%;
  }
`;

const tutorialinfo = keyframes`
    0% { border-left: solid 0.4vw #FF691F; }
    100% {border-left: solid 0.4vw yellow;}
`;
const tutorialinfomobile = keyframes`
    0% { border-left: solid 1vw #FF691F; }
    100% {border-left: solid 1vw yellow;}
`;
const TutorialInfo = styled.div`
  background-color: red;
  min-width: 165%;
  height: max-content;
  color: white;
  border-radius: 1vw;
  position: absolute;
  padding: 1vw 1.6vw;
  line-height: 1.4vw;
  bottom: -11vw;
  z-index: 1;
  text-align: left;
  box-shadow: 0px 0px 50px 20px rgba(0, 0, 0, 0.8);
  background: #302e3c;
  box-shadow: inset 0vw 0vw 0.8vw 0.2vw #ff691f,
    inset 0px 0px 4vw rgba(255, 133, 66, 0.63);
  border: solid 0.2vw orange;

  h1 {
    font-size: 1.5vw;
    font-weight: 400;
    border-left: solid 0.4vw #ff691f;
    padding-left: 0.5vw;
    margin-bottom: 0.5vw;
    line-height: 1.9vw;
    animation-name: ${tutorialinfo};
    animation-duration: 0.2s;
    animation-timing-function: linear;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }
  p {
    font-size: 1.2vw;
    color: rgba(255, 255, 255, 0.7);
    margin-left: 1vw;
  }

  @media (orientation: portrait) {
    z-index: 10;
    min-width: 100%;
    padding: 2vw 1.6vw;
    top: 108%;
    h1 {
      font-size: 3vw;
      line-height: 4vw;
      animation-name: ${tutorialinfomobile};
    }
    p {
      line-height: 3vw;
      font-size: 3vw;
    }
  }

  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    top: 120%;
    h1 {
      line-height: 3vw;
    }
    p {
      line-height: 2.5vw;
      font-size: 2.3vw;
    }
  }
`;

const LevelBarBG = styled.div`
  user-select: none;
  background-color: black;
  position: relative;
  border-radius: 1vw;
  width: 100%;
  height: 32%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (orientation: portrait) {
    width: 100%;
    height: 80%;
    border-radius: none;
    background-color: transparent;
  }
`;

const LevelBox = styled.div`
  user-select: none;
  width: 100%;
  height: 23%;
  display: flex;
  justify-content: flex-start;

  @media (orientation: portrait) {
    height: 50%;
    justify-content: flex-start;
  }
`;

const BarLevel = styled.div`
  user-select: none;
  cursor: pointer;
  border-radius: 50px;
  position: absolute;
  left: 2%;
  height: 82%;
  background: linear-gradient(90deg, #ff4c00 0%, #ffc700 100%);
  //Portrait
  @media (orientation: portrait) {
    left: 0%;
    height: 65%;
  }
  //iPad
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    left: 0%;
    height: 82%;
  }
`;

const ExpText = styled.div`
  position: absolute;
  color: #fff;
  font-size: 1vw;
  text-shadow: 0px 0px 1.5px #000000;
  z-index: 1;

  @media (orientation: portrait) {
    font-size: 2.5vw;
  }
`;

export default function Logo(props) {
  let guestuser = useSelector(selectuserProfile);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const profile = useSelector(selectuserProfile);

  return (
    <div {...props}>
      {guestuser.isguest === true &&
      guestuser.isTelGuest === false &&
      window.appHeight() > window.innerWidth ? (
        ""
      ) : (
        <AvatarButton
          onClick={() => {
            try {
              socket.emit(
                "getbilog",
                {
                  biname: "profile_open",
                },
                (data) => {
                  console.log(data);
                }
              );
            } catch (error) {
              console.log(error);
            }
            // setUserProfile(true);
            dispatch(actionPopup({ avatarLogo: true }));
            dispatch(actionClickSound());
          }}
        >
          <AvatarBox>
            <Avatar level={props.level} />
            {profile.tutorial === 14 ? (
              <>
                <TutorialArrow
                  style={{
                    top:
                      window.appHeight() > window.innerWidth &&
                      window.innerWidth < "481px"
                        ? "70%"
                        : window.appHeight() > window.innerWidth
                        ? "70%"
                        : "90%",
                    left:
                      window.appHeight() > window.innerWidth &&
                      window.innerWidth < "481px"
                        ? "140%"
                        : window.appHeight() > window.innerWidth
                        ? "130%"
                        : "90%",
                  }}
                  src={
                    window.appHeight() > window.innerWidth
                      ? "../" + config.theme + "/ftue_arrow_up.png"
                      : "../" + config.theme + "/ftue_arrow_up.png"
                  }
                />
                <TutorialBox
                  style={{
                    width:
                      window.appHeight() > window.innerWidth &&
                      window.innerWidth < "481px"
                        ? "318%"
                        : window.appHeight() > window.innerWidth
                        ? "318%"
                        : "221%",
                    left: "0%",
                  }}
                />
                <TutorialInfo
                  style={{
                    width:
                      window.appHeight() > window.innerWidth &&
                      window.innerWidth < "481px"
                        ? "318%"
                        : window.appHeight() > window.innerWidth
                        ? "318%"
                        : "160%",
                    top:
                      window.appHeight() > window.innerWidth &&
                      window.innerWidth < "481px"
                        ? "120%"
                        : window.appHeight() > window.innerWidth
                        ? "120%"
                        : "140%",
                    left:
                      window.appHeight() > window.innerWidth &&
                      window.innerWidth < "481px"
                        ? "0%"
                        : window.appHeight() > window.innerWidth
                        ? "0%"
                        : "30%",
                  }}
                >
                  <h1>{t("FTUE level title")}</h1>
                  <p>{t("FTUE level info")}</p>
                </TutorialInfo>
              </>
            ) : (
              ""
            )}
          </AvatarBox>
        </AvatarButton>
      )}

      {guestuser.isguest === true && guestuser.isTelGuest === false ? (
        window.appHeight() > window.innerWidth ? (
          profile.tutorial === 2 ? (
            <>
              <TutorialArrow
                src={"../" + config.theme + "/ftue_arrow_up.png"}
                style={{}}
              />
              <TutorialBox />
              <TutorialInfo>
                <h1>{t("FTUE register title")}</h1>
                <p>{t("FTUE register info")}</p>
              </TutorialInfo>
            </>
          ) : (
            ""
          )
        ) : (
          <>
            <AvataeLV
              onClick={() => {
                // setLogin(true);
                dispatch(actionPopup({ login: true }));
              }}
            >
              <Guesttext>{t("guest user")}</Guesttext>
              <BtnLetsPlay>
                <Textregister>{t("guest register")}</Textregister>
                <Barlogin />
                <Textlogin>{t("login")}</Textlogin>
                {profile.tutorial === 2 ? (
                  <>
                    <TutorialArrow
                      src={"../" + config.theme + "/ftue_arrow_up.png"}
                    />
                    <TutorialBox />
                    <TutorialInfo>
                      <h1>{t("FTUE register title")}</h1>
                      <p>{t("FTUE register info")}</p>
                    </TutorialInfo>
                  </>
                ) : (
                  ""
                )}
              </BtnLetsPlay>
            </AvataeLV>
          </>
        )
      ) : (
        <>
          <AvataeLV
            onClick={() => {
              try {
                socket.emit(
                  "getbilog",
                  {
                    biname: "profile_open",
                  },
                  (data) => {
                    console.log(data);
                  }
                );
              } catch (error) {
                console.log(error);
              }
              // setUserProfile(true);
              dispatch(actionPopup({ avatarLogo: true }));
              dispatch(actionClickSound());
            }}
          >
            <LevelBox>
              <Level>Lv.{profile.level}</Level>
              <BrandName>{profile.username}</BrandName>
            </LevelBox>
            <LevelBarBG>
              <ExpText>
                {profile.level === 99 ? (
                  "MAX"
                ) : (
                  <>
                    <NumberFormat
                      value={
                        profile.experience > 999 && profile.experience < 1000000
                          ? Math.floor(profile.experience / 10) / 100
                          : profile.experience > 1000000
                          ? Math.floor(profile.experience / 10000) / 100
                          : profile.experience
                      }
                      suffix={
                        profile.experience > 999 && profile.experience < 1000000
                          ? "K"
                          : profile.experience > 1000000
                          ? "M"
                          : null
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                    /
                    <NumberFormat
                      value={
                        profile.nextLevelExp > 999 &&
                        profile.nextLevelExp < 1000000
                          ? Math.floor(profile.nextLevelExp / 10) / 100
                          : profile.nextLevelExp > 1000000
                          ? Math.floor(profile.nextLevelExp / 10000) / 100
                          : profile.nextLevelExp
                      }
                      suffix={
                        profile.nextLevelExp > 999 &&
                        profile.nextLevelExp < 1000000
                          ? "K"
                          : profile.nextLevelExp > 1000000
                          ? "M"
                          : null
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                    &nbsp;Bet
                  </>
                )}
              </ExpText>
              {profile.experience >= profile.nextLevelExp ? (
                <BarLevel
                  style={{
                    width:
                      window.appHeight() > window.innerWidth ? "100%" : "96%",
                  }}
                />
              ) : profile.level === 99 ? (
                <BarLevel
                  style={{
                    width:
                      window.appHeight() > window.innerWidth ? "100%" : "96%",
                  }}
                />
              ) : profile.experience === 0 ? (
                <BarLevel />
              ) : (
                <BarLevel
                  style={{
                    width:
                      window.appHeight() > window.innerWidth
                        ? (profile.experience / profile.nextLevelExp) * 100 +
                          "%"
                        : (profile.experience / profile.nextLevelExp) * 96 +
                          "%",
                  }}
                />
              )}
            </LevelBarBG>
          </AvataeLV>
        </>
      )}

      {/* <Transition
        showPop={
          popupStatus.login ||
          popupStatus.register ||
          popupStatus.forgotPassword
        }
        type="st2b"
      >
        <Login
          closeClick={() => {
            // setLogin(false);
            dispatch(actionPopup({ login: false }));
          }}
        />
      </Transition> */}
    </div>
  );
}
