import React, { useEffect, useContext, useState } from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  selectuserProfile,
  actionClickSound,
} from "../../../../Reducers/userProfileSlice";
import { SocketContext } from "../../../../context/socket";
import Popup from "../../../Popup";
import { config } from "../../../../config";
import Switch from "../../../Switch";
import FinalNoticePop from "../FinalNoticePop";

const Box = styled.div`
  position: fixed;
  width: 100vw;
  height: 100%; //fix
  top: 0%;
  left: 0%;
  text-align: -webkit-center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 70;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BG = styled.div`
  user-select: none;
  width: 100vw;
  height: 100%; //fix
  position: fixed;
  left: 0%;
  top: 0%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  z-index: 20;
  align-items: center;
  justify-content: center;
  margin: 0%;
`;

const ContentBox = styled.div`
  width: 100%;
  height: 87%;
  /* background-color: red; */
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
  .or {
    color: #fff;
    font-size: 2vw;
  }
  @media (orientation: portrait) {
    .or {
      font-size: 4vw;
    }
  }
`;

const ChoiceItem = styled.div`
  width: 90%;
  height: 22%;
  background: #17161d;
  margin-top: 2%;
  border-radius: 1vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  img {
    width: 10%;
    object-fit: contain;
  }
`;

const TextChoiceDetail = styled.div`
  width: 50%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: start;
  color: #fff;
  font-size: 2vw;
  white-space: pre-line;
  span {
    font-size: 1.5vw;
  }
  @media (orientation: portrait) {
    font-size: 4vw;
  }
`;

const RemarkText = styled.div`
  width: 70%;
  color: #f77;
  font-size: 2vw;
  text-align: center;
  margin-top: 4%;
  @media (orientation: portrait) {
    font-size: 4vw;
  }
`;

function SelectByType(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector(selectuserProfile);
  const socket = useContext(SocketContext);
  const [slotSwitch, setSlotSwitch] = useState(false);
  const [casinoSwitch, setCasinoSwitch] = useState(false);
  const [finalPop, setFinalPop] = useState({ status: false, type: 1 });

  useEffect(() => {
    if (props?.promotionInfo?.user?.promotion_id === null) {
      if (props?.promotionInfo?.user?.gameType === 1) {
        setSlotSwitch(true);
      } else if (props?.promotionInfo?.user?.gameType === 2) {
        setCasinoSwitch(true);
      }
    }
  }, []);

  return (
    <Box>
      <BG onClick={props.closeClick} />
      <Popup
        title={t("auto pro")}
        TitleSize={
          window.appHeight() > window.innerWidth && window.innerWidth <= 480
            ? "2.67vw"
            : window.appHeight() > window.innerWidth
            ? "4vw"
            : "2vw"
        }
        bgClass="Profile"
        width={
          window.appHeight() > window.innerWidth && window.innerWidth <= 480
            ? "95vw"
            : window.appHeight() > window.innerWidth
            ? "90vw"
            : "43.48vw"
        }
        height={
          window.appHeight() > window.innerWidth && window.innerWidth <= 480
            ? "80vw"
            : window.appHeight() > window.innerWidth
            ? "60vw"
            : "37.78vw"
        }
        closebuttonRight="-2.5vw"
        closebuttonTop="-2.5vw"
        closeClick={props.closeClick}
      >
        <ContentBox>
          <ChoiceItem>
            <img src={"../" + config.theme + "/ico_promo_slot.png"} />
            <TextChoiceDetail>{t("bytype auto slot")}</TextChoiceDetail>
            <Switch
              onLabel={t("on")}
              offLabel={t("off")}
              value={slotSwitch}
              click={() => {
                if (slotSwitch) {
                  if (props?.promotionInfo?.setting?.updatePro) {
                    props.UpdatePromotion("auto", 2);
                    setSlotSwitch((n) => !n);
                    if (!casinoSwitch) {
                      setCasinoSwitch((n) => !n);
                    }
                  } else {
                    setFinalPop({ status: true, type: 2 });
                  }
                } else {
                  if (props?.promotionInfo?.setting?.updatePro) {
                    props.UpdatePromotion("auto", 1);
                    setSlotSwitch((n) => !n);
                    if (casinoSwitch) {
                      setCasinoSwitch((n) => !n);
                    }
                  } else {
                    setFinalPop({ status: true, type: 1 });
                  }
                }
              }}
            />
          </ChoiceItem>
          <div className="or">{t("vltgame or")}</div>
          <ChoiceItem>
            <img src={"../" + config.theme + "/ico_promo_card.png"} />
            <TextChoiceDetail>{t("bytype auto casino")}</TextChoiceDetail>
            <Switch
              onLabel={t("on")}
              offLabel={t("off")}
              value={casinoSwitch}
              click={() => {
                if (casinoSwitch) {
                  if (props?.promotionInfo?.setting?.updatePro) {
                    props.UpdatePromotion("auto", 1);
                    setCasinoSwitch((n) => !n);
                    if (!slotSwitch) {
                      setSlotSwitch((n) => !n);
                    }
                  } else {
                    setFinalPop({ status: true, type: 1 });
                  }
                } else {
                  if (props?.promotionInfo?.setting?.updatePro) {
                    props.UpdatePromotion("auto", 2);
                    setCasinoSwitch((n) => !n);
                    if (slotSwitch) {
                      setSlotSwitch((n) => !n);
                    }
                  } else {
                    setFinalPop({ status: true, type: 2 });
                  }
                }
              }}
            />
          </ChoiceItem>
          <RemarkText>{t("bytype auto remark")}</RemarkText>
        </ContentBox>
      </Popup>
      {finalPop.status ? (
        <FinalNoticePop
          closeClick={() => {
            setFinalPop({ status: false });
          }}
          confirmClick={() => {
            props.UpdatePromotion("auto", finalPop.type);
          }}
        />
      ) : (
        ""
      )}
    </Box>
  );
}
export default SelectByType;
