import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import {
  selectuserProfile,
  actionClickSound,
} from "../../Reducers/userProfileSlice";
import { useSelector, useDispatch } from "react-redux";
import { config, Transition } from "../../config";
import Button from "../Button";
import {
  SocketContext,
  socket,
  SocketKafkaContext,
} from "../../context/socket";
import AlertMessage from "../Dialog/AlertMessage";
import SpinWheelResult from "../LuckyWheel/SpinWheelResult";
import Loading from "../Loading";
import { timer } from "../../Reducers/countDown";
import CountDownNextDay from "../CountDownNextDay";

const BG = styled.div`
  user-select: none;
  width: 17vw;
  height: 11vw;
  text-align: center;
  background: #040b17;
  border: 2px solid #e7ce5c;
  box-shadow: inset 0px 0px 15px #e7ce5c;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1.5vw;
  margin: 0vw 0.5vw 1vw 0.5vw;

  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 45%;
          height: 24vw;
          border: 1.5px solid #e7ce5c;
          border-radius: 2.5vw;
        `
      : window.innerHeight > window.innerWidth &&
        css`
          width: 41%;
          height: 15vw;
        `}
`;

const BGGray = styled.div`
  user-select: none;
  width: 17vw;
  height: 11vw;
  text-align: center;
  background: #040b17;
  border: 2px solid #3d5071;
  box-shadow: inset 0px 0px 20px rgba(79, 104, 147, 0.75);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1.5vw;
  margin: 0vw 0.5vw 1vw 0.5vw;

  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 45%;
          height: 24vw;
          border: 1.5px solid #3d5071;
          border-radius: 2.5vw;
        `
      : window.innerHeight > window.innerWidth &&
        css`
          width: 41%;
          height: 15vw;
        `}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -1vw;
  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          top: 0vw;
          flex-direction: row;
        `
      : window.innerHeight > window.innerWidth &&
        css`
          top: 0vw;
          flex-direction: row;
        `}
`;

const IconDiamond = styled.img`
  user-select: none;
  width: 8vw;
  display: flex;
  ${(props) =>
    props.active === "T" &&
    css`
      filter: grayscale(100%);
    `}

  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 18vw;
        `
      : window.innerHeight > window.innerWidth &&
        css`
          width: 11vw;
        `}
`;
const Text = styled.div`
  position: relative;
  font-size: 1vw;
  font-weight: 700;
  color: white;
  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          width: 30%;
          font-size: 3vw;
          white-space: pre-wrap:
        `
      : window.innerHeight > window.innerWidth &&
        css`
          font-size: 2vw;
          font-weight: 700;
          color: white;
          display: flex;
          width: 10vw;
          justify-content: center;
        `}
`;

const Day = styled.div`
  position: relative;
  font-size: 1vw;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 0.5vw;
  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          font-size: 3vw;
        `
      : window.innerHeight > window.innerWidth &&
        css`
          font-size: 2vw;
        `}
`;

const ImageReceived = styled.img`
  /* background-color: rebeccapurple; */
  padding: 2.9vw 5.7vw 2.9vw 5.7vw;
  background-color: rgba(4, 11, 23, 0.8);
  width: 7vw;
  position: absolute;
  top: 0.3vw;
  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          /* background-color: rebeccapurple; */
          width: 17vw;
          top: -0.3vw;
          padding: 5.2vw 13.2vw 2.6vw 12.2vw;
        `
      : window.innerHeight > window.innerWidth &&
        css`
          width: 10vw;
          top: -0.5vw;
          padding: 3.9vw 9.2vw 2.2vw 9.2vw;
        `}
`;
const BoxButton = styled.div`
  position: relative;
  ${() =>
    window.innerHeight / window.innerWidth > config.verticalSizeiphone
      ? css`
          position: relative;
          top: 1vw;
        `
      : window.innerHeight > window.innerWidth &&
        css`
          position: relative;
        `}
`;

export default function DailyItem(props) {
  const { t } = useTranslation();
  const profile = useSelector(selectuserProfile);
  const [emitError, setEmitError] = useState({ status: false, message: "" });
  const [getReward, setGetReward] = useState(false);
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();
  const countDown = useSelector(timer);
  return (
    <>
      {props.status === 1 ? (
        <BG
        // onClick={() => {
        //   dispatch(actionClickSound());
        //   // setLoad(true);
        //   socket.emit(
        //     "getrewarddailylogin",
        //     {
        //       day: props.day, //วันที่
        //     },
        //     (data) => {
        //       // setLoad(false);
        //       setGetReward(true);
        //       console.log("getrewarddailylogin", data);

        //       props.items.filter(
        //         (item) => item.day == props.day
        //       )[0].status = 2;

        //       if (data.status === false) {
        //         setEmitError(true);
        //       }
        //     }
        //   );
        // }}
        >
          {window.innerHeight > window.innerWidth ? (
            <>
              <Container>
                <IconDiamond src={"../" + config.theme + "/dshop_800.png"} />
                <Text>
                  {props.reward_max} {t("diamonds")}
                </Text>
              </Container>
              <BoxButton>
                <Button
                  label={t("get dailyLogin")}
                  width={
                    window.innerHeight / window.innerWidth >
                    config.verticalSizeiphone
                      ? "21vw"
                      : window.innerHeight > window.innerWidth
                      ? "16vw"
                      : window.innerWidth / window.innerHeight > 2.15
                      ? "9vw"
                      : "9vw"
                  }
                  fontSize={
                    window.innerHeight / window.innerWidth >
                    config.verticalSizeiphone
                      ? "2.5vw"
                      : window.innerHeight > window.innerWidth
                      ? "1.7vw"
                      : window.innerWidth / window.innerHeight > 2.15
                      ? "1.15vw"
                      : "1.15vw"
                  }
                  style={{ padding: 0 }}
                  onClick={() => {
                    dispatch(actionClickSound());
                    // setLoad(true);
                    socket.emit(
                      "getrewarddailylogin",
                      {
                        day: props.day, //วันที่
                        remain_time: countDown,
                      },
                      (data) => {
                        if (typeof data != "undefined") {
                          if (data.status == true) {
                            setGetReward(true);
                            console.log("getrewarddailylogin", data);

                            props.items.filter(
                              (item) => item.day == props.day
                            )[0].status = 2;
                          }
                        } else if (data.status === false) {
                          setEmitError({
                            status: true,
                            message: data.errorCode,
                          });
                          setTimeout(() => {
                            setEmitError({ status: false });
                          }, 3000);
                        }
                      }
                    );
                  }}
                />
              </BoxButton>
            </>
          ) : (
            <Container>
              <IconDiamond src={"../" + config.theme + "/dshop_800.png"} />
              <Text>
                {props.reward_max} {t("diamonds")}
              </Text>

              <Button
                label={t("get dailyLogin")}
                width={
                  window.innerHeight / window.innerWidth >
                  config.verticalSizeiphone
                    ? "21vw"
                    : window.innerHeight > window.innerWidth
                    ? "16vw"
                    : window.innerWidth / window.innerHeight > 2.15
                    ? "9vw"
                    : "9vw"
                }
                fontSize={
                  window.innerHeight / window.innerWidth >
                  config.verticalSizeiphone
                    ? "2.5vw"
                    : window.innerHeight > window.innerWidth
                    ? "1.7vw"
                    : window.innerWidth / window.innerHeight > 2.15
                    ? "1.15vw"
                    : "1.15vw"
                }
                style={{
                  padding: 0,
                  top:
                    window.innerHeight / window.innerWidth >
                    config.verticalSizeiphone
                      ? "2.5vw"
                      : window.innerHeight > window.innerWidth
                      ? "1vw"
                      : window.innerWidth / window.innerHeight > 2.15
                      ? "0"
                      : "0",
                }}
                onClick={() => {
                  dispatch(actionClickSound());
                  // setLoad(true);
                  socket.emit(
                    "getrewarddailylogin",
                    {
                      day: props.day, //วันที่
                      remain_time: countDown,
                    },
                    (data) => {
                      if (typeof data != "undefined") {
                        if (data.status == true) {
                          setGetReward(true);
                          console.log("getrewarddailylogin", data);

                          props.items.filter(
                            (item) => item.day == props.day
                          )[0].status = 2;
                        }
                      } else if (data.status === false) {
                        setEmitError({ status: true, message: data.errorCode });
                        setTimeout(() => {
                          setEmitError({ status: false });
                        }, 3000);
                      }
                    }
                  );
                }}
              />
            </Container>
          )}
        </BG>
      ) : props.status === 0 ? (
        <BGGray status={props.status}>
          {window.innerHeight > window.innerWidth ? (
            <>
              <Container>
                <IconDiamond src={"../" + config.theme + "/dshop_800.png"} />
                <Text>
                  {props.reward_max} {t("diamonds")}
                </Text>
              </Container>
              <Day>
                {t("day dailyLogin")} {props.day}
              </Day>
              {/* <ImageReceived src={"../" + config.theme + "/img_check.png"} /> */}
            </>
          ) : (
            <Container>
              <IconDiamond src={"../" + config.theme + "/dshop_800.png"} />
              <Text>
                {props.reward_max} {t("diamonds")}
              </Text>
              <Day>
                {t("day dailyLogin")} {props.day}
              </Day>
              {/* <ImageReceived src={"../" + config.theme + "/img_check.png"} /> */}
            </Container>
          )}
        </BGGray>
      ) : (
        <BGGray>
          {window.innerHeight > window.innerWidth ? (
            <>
              <Container>
                <IconDiamond src={"../" + config.theme + "/dshop_800.png"} />
                <Text>
                  {props.reward_max} {t("diamonds")}
                </Text>
              </Container>
              <Day>
                {t("day dailyLogin")} {props.day}
              </Day>
              <ImageReceived
                src={"../" + config.theme + "/dailyLogin/img_check.png"}
              />
            </>
          ) : (
            <Container>
              <IconDiamond src={"../" + config.theme + "/dshop_800.png"} />
              <Text>
                {props.reward_max} {t("diamonds")}
              </Text>
              <Day>
                {t("day dailyLogin")} {props.day}
              </Day>
              <ImageReceived
                src={"../" + config.theme + "/dailyLogin/img_check.png"}
              />
            </Container>
          )}
        </BGGray>
      )}
      {load ? <Loading /> : ""}
      <Transition showPop={emitError.status} type="alert">
        <AlertMessage
          message={t(emitError.message)}
          onClick={() => {
            setEmitError({ status: false });
          }}
        />
      </Transition>

      <Transition showPop={getReward} type="alert">
        <SpinWheelResult
          label={t("got a reward")}
          amount={props.reward_max}
          closeClick={() => {
            setGetReward(false);
            // setLoad(true)
          }}
        />
      </Transition>
    </>
  );
}
